/**
 * @param name
 * @returns {*}
 */
export const formatCourseName = name => {
  const splitted = name.split(" - ");
  if (splitted.length > 1) {
    return splitted[1];
  }

  return name;
};

export const getCleanCourseName = (name, includeCourseCode = false) => {
  try {
    const split = name.split(" - ");
    if (split.length === 1) {
      return split[0];
    }
    const cleanName = split[1].split(",")[0].split("(")[0];
    if (includeCourseCode) {
      return split[0] + " - " + cleanName;
    }
    return cleanName;
  } catch (e) {
    return name;
  }
};
