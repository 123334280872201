import React from "react";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import useGetCurrentUserCouponUsages, {
  GET_CURRENT_USER_COUPON_USAGES,
} from "API/queries/coupon/useGetCurrentUserCouponUsages";
import useRedeemCreditCouponMutation from "API/mutations/user/useRedeemCreditCouponMutation";

const COUPON_NAME = "welcome_coupon";
const COUPON_ID = 9030;

const useEarnCreditsWelcomeGift = () => {
  const [user, userLoading] = useGetCurrentUser();

  const [couponUsages, couponUsagesLoading] = useGetCurrentUserCouponUsages({
    couponId: COUPON_ID,
  });

  const [
    redeemCoupon,
    redeemCouponLoading,
  ] = useRedeemCreditCouponMutation(() => {}, [
    {
      query: GET_CURRENT_USER_COUPON_USAGES,
      variables: {
        couponId: COUPON_ID,
      },
    },
  ]);

  // Find transaction for the coupon, if it exists
  const completed = couponUsages.length > 0;

  const handleRedeem = () => {
    redeemCoupon({
      variables: {
        name: COUPON_NAME,
      },
    });
  };

  const loading = userLoading || couponUsagesLoading;

  return {
    user,
    completed,
    handleRedeem,
    loading,
    handleRedeemLoading: redeemCouponLoading,
  };
};

export default useEarnCreditsWelcomeGift;
