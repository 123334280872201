import React from "react";
import {Button, Icon} from "Components";
import Trans from "../../Trans";

const NumberInput = ({name, value, onChange, min, max, error}) => {
  const decrement = e => {
    e.preventDefault();
    if (value !== min) {
      onChange({target: {name, value: value - 1}});
    }
  };

  const increment = e => {
    e.preventDefault();
    if (value !== max) {
      onChange({target: {name, value: value + 1}});
    }
  };

  return (
    <>
      <div className="flex w-full justify-center text-white gap-1">
        <Button
          type="secondary"
          className="px-4 py-2 cursor-pointer"
          onClick={e => {
            decrement(e);
          }}
        >
          <Icon icon="fas fa-minus" color="white" />
        </Button>
        <div className="w-16 py-2 text-center text-gray-500 border-1 border-gray-300 rounded-lg">
          {value}
        </div>
        <Button
          type="secondary"
          className="px-4 py-2 cursor-pointer"
          onClick={e => {
            increment(e);
          }}
        >
          <Icon icon="fas fa-plus" color="white" />
        </Button>
      </div>
      <div className="text-xs text-gray-500 text-center h-4">
        {error && (
          <Trans color="text-error-700">Choose 1 or more exercises</Trans>
        )}
      </div>
    </>
  );
};

export default NumberInput;
