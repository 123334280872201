import { getPriceAfterDiscount } from "Components/ProductPrice/ProductPriceFunctions";
import React from "react";
import { Typography } from "Components";

const ProductCardPrice = ({ price }) => {
  if (!price) return null;

  const { discountPercentage, priceCents, discountedPrice } = price;

  // If there is any discount
  if (discountPercentage > 0) {
    const discountedPrice = getPriceAfterDiscount(
      priceCents,
      discountPercentage
    );

    return (
      <div key={discountedPrice} className="flex items-center gap-1">
        <Typography
          type="tsm"
          weight="md"
          color="text-gray-light"
          className="line-through whitespace-nowrap"
        >
          {priceCents / 100}
        </Typography>
        <Typography type="txl" weight="md" className=" whitespace-nowrap">
          {discountedPrice / 100}
        </Typography>
        <Typography type="tsm" weight="md" color="text-gray-light">
          SEK
        </Typography>
      </div>
    );
  }

  return (
    <div key={priceCents} className="flex items-center gap-1">
      <Typography type="txl" weight="md" className="whitespace-nowrap">
        {priceCents / 100}
      </Typography>
      <Typography type="tsm" weight="md" color="text-gray-light">
        SEK
      </Typography>
    </div>
  );
};

export default ProductCardPrice;
