import React from "react";
import PropTypes from "prop-types";

const useKeyboardShortcuts = (
  shortcuts,
  dependencies = [],
  requireBodyEvent = true
) => {
  const handle = React.useCallback(event => {
    event.stopPropagation();

    for (const shortcut of shortcuts) {
      if (shortcut.key === event.key) {
        // Ensure that the modifiers are pressed, they have higher precedence than target.nodeName
        let modifiers = false;
        let eventIsFromBody = isEventFromBody(event);

        // If the shortcut has modifiers set to true, we need to check if they are pressed
        if (shortcut.ctrl || shortcut.meta || shortcut.alt || shortcut.shift) {
          modifiers = true;
        }

        // If the shortcut has modifiers set to true, we need to check if are pressed
        if (modifiers) {
          if (
            (shortcut.ctrl && !(event.ctrlKey || event.metaKey)) ||
            (shortcut.alt && !event.altKey) ||
            (shortcut.shift && !event.shiftKey)
          ) {
            // If the modifiers are set to true and they are not pressed, we don't want to trigger the shortcut
            continue;
          }
        }

        // If the shortcut has modifiers set to false, we need to check that they are not pressed
        if (!modifiers) {
          if (
            event.ctrlKey ||
            event.metaKey ||
            event.altKey ||
            event.shiftKey
          ) {
            continue;
          }
        }

        // If the shortcut has modifiers set to false, it should only trigger if the event is from the body
        if (
          modifiers === false &&
          eventIsFromBody === false &&
          requireBodyEvent
        ) {
          continue;
        }

        // If the event already has a default action, we don't want to trigger the shortcut

        // Call the callback
        shortcut.action(event);
      }
    }
  });

  // Add keydown event listener
  React.useEffect(() => {
    document.addEventListener("keydown", handle);
    return () => document.removeEventListener("keydown", handle);
  }, [...dependencies]);
};

export const isEventFromBody = event => {
  return (
    event.target.nodeName === "BODY" ||
    event.target.nodeName === "A" ||
    event.target.nodeName === "BUTTON"
  );
};

useKeyboardShortcuts.PropTypes = {
  /**
   * A list of keyboard shortcuts to listen to with an action for each
   */
  shortcuts: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The key to listen for
       */
      key: PropTypes.string.isRequired,
      /**
       * The action to perform when the key is pressed
       */
      action: PropTypes.func.isRequired,
      /**
       * Whether the ctrl key must be pressed
       */
      ctrl: PropTypes.bool,
      /**
       * Whether the alt key must be pressed
       */
      alt: PropTypes.bool,
      /**
       *  Whether the shift key must be pressed
       */
      shift: PropTypes.bool,
    })
  ).isRequired,
  /**
   * An array of dependencies to pass to the action, if any
   * This will reset the event listener when the dependencies change
   *
   */
  dependencies: PropTypes.arrayOf(PropTypes.any),
};

export default useKeyboardShortcuts;
