import React from "react";
import SupportMailForm from "Components/SupportMailForm";
import Logout from "routes/Auth/Logout";
import ForgotPassword from "routes/Auth/ForgotPassword/ForgotPassword";
import RestPasswordUrlToken from "routes/Auth/ForgotPassword/RestPasswordUrlToken";
import Login from "routes/Auth/Login/Login";
import SignUp from "routes/Auth/SignUp/SignUp";
import {AdminRoute, AuthRoute, NonAuthRoute} from "./CustomRoutes";
import Auth from "routes/Auth/Auth";
import {Trans} from "Components";
import SubscriptionSuccess from "routes/Course/Subscription/SubscriptionSuccess";
import SubscriptionFailed from "routes/Course/Subscription/SubscriptionFailed";
import Landing from "routes/Landing/Landing";
import Course from "routes/Course/CourseContextProvider";
import Account from "routes/Account/Account";
import useGroupRouteTransformer from "Providers/GroupProvider/useGroupRouteTransformer";
import GroupInvitation from "routes/Group/GroupInvitation/GroupInvitation";

// Lazy load routes that don't need to be loaded on initial page load
// prettier-ignore
const DashboardRouter = React.lazy(() =>  import("routes/Dashboard/DashboardRouter"));
// prettier-ignore
const ExamsCalendar = React.lazy(() =>  import("routes/ExamsCalendar/ExamsCalendar"));
// prettier-ignore
const Onboarding = React.lazy(() => import("routes/Onboarding/Onboarding"));
// prettier-ignore
const StaticRouter = React.lazy(() => import("routes/Static/StaticRouter"));
// prettier-ignore
const SubscriptionTrial = React.lazy(() =>  import("routes/Trial/SubscriptionTrial/SubscriptionTrial"));
// prettier-ignore
const Checkout = React.lazy(() => import("./routes/Checkout/Checkout"));
// prettier-ignore
const Campaign = React.lazy(() => import("routes/Campaign/Campaign"));
// prettier-ignore
const GoldenTicket = React.lazy(() =>  import("routes/Campaign/GoldenTicket/GoldenTicket"));
// prettier-ignore
// const Course = React.lazy(() =>  import("./routes/Course/CourseContextProvider"));
// prettier-ignore
const Pricing = React.lazy(() => import("routes/Pricing/Pricing"));
// prettier-ignore
const Courses = React.lazy(() => import("routes/Courses/Courses"));
// prettier-ignore
const Sektionskampen = React.lazy(() =>  import("routes/Campaign/Sektionskampen/Sektionskampen"));

const authRoutes = {
  "/auth": {
    path: "/auth",
    Component: Auth,
    type: NonAuthRoute,
    exact: true,
    group: "auth",
  },
  "/login": {
    path: "/login",
    Component: Login,
    type: NonAuthRoute,
    group: "auth",
  },
  "/logout": {
    path: "/logout",
    Component: Logout,
    type: AuthRoute,
    group: "auth",
  },
  "/signup": {
    path: "/signup",
    Component: SignUp,
    type: NonAuthRoute,
    group: "auth",
  },
  "/forgot-password": {
    path: "/forgot-password",
    Component: ForgotPassword,
    type: NonAuthRoute,
    group: "auth",
  },
  "/resetpassword/:token": {
    path: "/resetpassword/:token",
    Component: RestPasswordUrlToken,
    group: "auth",
  }, // can be accessed logged in or not

  "/group-invite/:token": {
    path: "/group-invite/:token",
    Component: GroupInvitation,
    group: "auth",
  },
  "/onboarding": {
    path: "/onboarding",
    Component: Onboarding,
    type: AuthRoute,
    group: "auth",
  },
};

const userRoutes = {
  "/trial": {
    path: "/trial",
    Component: SubscriptionTrial,
    type: AuthRoute,
    hideSidebar: true,
  },

  "/account": {path: "/account", Component: Account, type: AuthRoute},

  "/subscription_success": {
    path: "/subscription_success",
    Component: SubscriptionSuccess,
    type: AuthRoute,
  },
  "/subscription_fail": {
    path: "/subscription_fail",
    Component: SubscriptionFailed,
    type: AuthRoute,
  },
};

const adminRoutes = {
  "/calendar": {
    path: "/calendar",
    Component: ExamsCalendar,
    type: AdminRoute,
  },
};

const marketingRoutes = {
  "/campaign/:programKey": {path: "/campaign/:programKey", Component: Campaign},
  "/campaign": {path: "/campaign", Component: Campaign},
  "/guldbiljett/:ticketName?": {
    path: "/guldbiljett/:ticketName?",
    Component: GoldenTicket,
    componentProps: {type: "gold"},
  },
  "/silverticket/:ticketName?": {
    path: "/silverticket/:ticketName?",
    Component: GoldenTicket,
    componentProps: {type: "silver"},
  },
  "/sektionskampen": {path: "/sektionskampen", Component: Sektionskampen},
};

export const staticRoutes = {
  "/pricing": {
    path: "/pricing",
    Component: Pricing,
    text: <Trans>Pricing</Trans>,
  },
  "/frontpage": {
    path: "/frontpage",
    Component: Landing,
    text: <Trans>Frontpage</Trans>,
    hideInNavigation: true,
  },
  "/courses": {path: "/courses", Component: Courses},
  "/static": {path: "/static", Component: StaticRouter, hideInNavigation: true},
  "/contact": {path: "/contact", Component: SupportMailForm},
  "/checkout": {path: "/checkout", Component: Checkout, hideInNavigation: true},
  "/home": {
    path: "/home",
    Component: DashboardRouter,
    type: AuthRoute,
    exact: true,
  },
};

const courseRoutes = {
  "/course/:courseId": {path: "/course/:courseId", Component: Course},
};

const allRoutes = {
  ...authRoutes,
  ...userRoutes,
  ...adminRoutes,
  ...marketingRoutes,
  ...staticRoutes,
  ...courseRoutes,
  "/": {
    path: "/",
    Component: Landing,
    exact: true,
  },
};

const useRoutes = () => {
  const {user} = React.useContext(UserContext);
  const [routes, setRoutes] = React.useState(allRoutes);

  React.useEffect(() => {
    setRoutes(prev => {
      const isLoggedIn = !!user?.id;

      const newRoutes = {...prev};

      if (isLoggedIn) {
        routes["/"].Component = DashboardRouter;
      } else {
        routes["/"].Component = Landing;
      }

      return newRoutes;
    });
  }, [user?.id]);

  const transformedRoutes = useGroupRouteTransformer(routes);

  return Object.values(transformedRoutes);
};

export default useRoutes;
