import React from "react";
import useGetGroup from "API/queries/groups/useGetGroup";
import {Button, Section, Tabs, Trans, Typography} from "Components";
import GroupMembersList from "./GroupMembersList";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {useHistory} from "react-router-dom";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";

const GroupMembers = () => {
  const history = useHistory();
  const {id: groupId, name, currentUserIsAdmin} = useGroupContext();

  const sm = useBreakPointSM();
  const [group, membersLoading] = useGetGroup({
    id: groupId,
    includeSubGroups: true,
  });

  let subGroups = group?.subGroups || []; // If no subgroups, use the group itself as the only subgroup

  subGroups = [group, ...subGroups];

  return (
    <Section
      title={<Typography type="dsm">Medlemmar</Typography>}
      subTitle={
        <Typography>
          Medlemmarna är indelade i olika undergrupper baserat på program eller
          inriktning.
        </Typography>
      }
      action={
        currentUserIsAdmin ? (
          <Button
            type="primary"
            className="mt-2"
            size="sm"
            onClick={() => history.push(`/upload`)}
          >
            <Trans>Invite new members</Trans>
          </Button>
        ) : (
          <Typography />
        )
      }
    >
      {subGroups.length === 1 ? (
        <GroupMembersList groupId={subGroups[0]?.id} />
      ) : (
        <Tabs
          type="button"
          orientation={sm("horizontal", "vertical")}
          // defaultSelectedTabKey={subGroups[0]?.id}
          persistCurrentTabInUrl={true}
        >
          {subGroups.map((subGroup, index) => {
            return (
              <Tabs.Pane
                key={subGroup.id}
                title={
                  subGroup.id === groupId ? (
                    <Trans name={name}>Hela {{name}}</Trans>
                  ) : (
                    <Typography>{subGroup.name}</Typography>
                  )
                }
              >
                <GroupMembersList
                  loading={membersLoading}
                  groupId={subGroup.id}
                />
              </Tabs.Pane>
            );
          })}
        </Tabs>
      )}
    </Section>
  );
};

export default GroupMembers;
