import React from "react";
import {ExamUploadContext} from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContext";
import {Alert, Icon, Trans, Typography} from "Components";
import i18n from "i18n";
import {useHistory} from "react-router-dom";

/**
 * Shows large clickable tabs with the following options:
 * 1. New exams
 * 2. Exams to be corrected (unknown type)
 * 3. All exams
 * 4. Files without date documents.unknown_date
 * @returns {JSX.Element|null}
 * @constructor
 */

const Stats = [
  {
    label: i18n.t("Files"),
    key: "totalFiles",
    icon: "fa-regular fa-cloud-arrow-up",
  },
  {
    label: i18n.t("New files"),
    key: "newFiles",
    icon: "fa-solid fa-sparkles",
  },
  {
    label: i18n.t("Existing file"),
    key: "existingFiles",
    icon: "fa-solid fa-folder-open",
  },
  // {
  //   label: i18n.t("Mixed"),
  //   key: "mixed",
  //   icon: "fa-solid fa-shuffle",
  // },
  // {
  //   label: i18n.t("Existing"),
  //   key: "existingFiles",
  //   icon: "fa-regular fa-circle-xmark",
  // },
];

const ExamUploadDocumentsOverview = () => {
  const {id} = React.useContext(window.CourseContext);
  const history = useHistory();
  const {documentsOverview, setFilesAdded} = React.useContext(
    ExamUploadContext
  );

  if (documentsOverview["newFiles"] == 0) {
    return (
      <Alert
        title={
          <Trans>All the uploaded files already exist in this course.</Trans>
        }
        type="primary"
        subTitle={<Trans>You can upload new files though!</Trans>}
        primaryAction={{
          label: <Trans>Go to exams</Trans>,
          onClick: () => {
            history.push(`/course/${id}/exams`);
          },
        }}
        secondaryAction={{
          label: <Trans>Upload more files</Trans>,
          onClick: () => {
            location.reload();
          },
        }}
      />
    );
  }

  return null;

  return (
    <div className="surface rounded-xl p-4 flex justify-between items-center">
      <div className="grid grid-flow-col auto-cols-fr place-items-center divide-x-2 divide-primary-50 w-full">
        {Stats.map(stat => {
          if (!documentsOverview[stat.key]) return null;

          return (
            <div
              key={stat.key}
              className="px-4 w-full flex flex-col items-center"
            >
              <Typography type="tsm" color="text-gray-light">
                {stat.label}
              </Typography>
              <div className="flex items-center gap-2 mt-2">
                <Typography type="dsm" color="text-gray">
                  {documentsOverview[stat.key]}
                </Typography>
                <Icon icon={stat.icon} size="lg" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const renderBannerTitle = n => {
  return <Trans n={n}>{{n}} Files to review</Trans>;
};

export default ExamUploadDocumentsOverview;
