import React from "react";
import useGetCourseModules from "API/queries/module/useGetCourseModules";
import { Carousel } from "Components";
import CourseLandingTopicsModule from "routes/Course/CourseLanding/CourseLandingTopics/CourseLandingTopicsModule";
import { CourseLandingContext } from "routes/Course/CourseLanding/useCourseLandingContext";
import LandingPageSection from "Components/LandingPageSection/LandingPageSection";

const CourseLandingTopics = () => {
  const { course, landingPageContent } = React.useContext(CourseLandingContext);
  const landingPageSectionId = React.useMemo(
    () =>
      landingPageContent?.find(content => content.title === "modules")?.sys?.id,
    []
  );

  const [modules, loading] = useGetCourseModules(course.id, true, true, true);

  if (loading) return null;

  const tagListElementHeight = 3;
  const nTagsToShow = modules[0]?.tags?.length || 5;

  // TODO: Handle the case when there is only one module

  return (
    <div className="mt-8">
      <LandingPageSection sectionId={landingPageSectionId} />

      <div
        style={{
          height: tagListElementHeight * nTagsToShow + 12 + "rem",
        }}
      >
        <Carousel loop showDots>
          {modules.map((topic, index) => {
            return (
              <CourseLandingTopicsModule
                topic={topic}
                key={index}
                nTagsToShow={nTagsToShow}
              />
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default CourseLandingTopics;
