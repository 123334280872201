import React from "react";

import {Button, Link, Trans} from "Components";
import AuthLayout from "routes/Auth/AuthLayout";
import OTP from "Widgets/User/OTP/OTP";
import ResetPassword from "./ResetPassword/ResetPassword";
import {useHistory} from "react-router-dom";

const LoginContainer = ({
  wrapper = AuthLayout,
  onBackClick = null,
  showTitle = true,
  onComplete = () => {},
  transparentInput = false,
}) => {
  const [resetToken, setResetToken] = React.useState(null);
  const history = useHistory();

  const Wrapper = wrapper || "div";

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
      return;
    }
    history.push("/login");
  };
  return (
    <Wrapper>
      <Button type="link" iconLeft="arrow-left" onClick={handleBackClick}>
        <Trans inline>Back to login page</Trans>
      </Button>

      <div className="mt-8">
        {resetToken ? (
          <ResetPassword token={resetToken} onComplete={null} />
        ) : (
          <OTP
            transparent={transparentInput}
            title={showTitle ? <Trans>Reset password</Trans> : null}
            subTitle={
              <Trans>
                Enter your email address and we will send you a link for
                resetting your password
              </Trans>
            }
            inlineForm={false}
            showModal={false}
            inputPlaceholder="example@mail.com"
            action="PASSWORD_RESET"
            onVerificationComplete={setResetToken}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default LoginContainer;
