import React from "react";
import useCreateGroupMemberInvite from "API/mutations/groups/useCreateGroupMemberInvite";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";

const useGroupInvitesCreate = ({email, triggerMutation, triggerDelay = 0}) => {
  const {groupId} = useGroupContext();

  const {
    response,
    loading,
    createGroupMemberInvite,
  } = useCreateGroupMemberInvite({
    groupId,
  });

  const handleInviteCreate = async () => {
    await createGroupMemberInvite({
      variables: {
        email,
        groupId,
        verified: true, // TODO: Change verified to manual input
      },
    });
  };

  React.useEffect(() => {
    if (triggerMutation && !response) {
      setTimeout(() => {
        handleInviteCreate();
      }, triggerDelay);
    }
  }, [triggerMutation]);

  return {
    response,
    loading,
    handleInviteCreate,
  };
};

export default useGroupInvitesCreate;
