import React from "react";
import nprogress from "nprogress";
import "nprogress/nprogress.css";

const PageLoader = props => {
  React.useEffect(() => {
    nprogress.start();
    return () => {
      nprogress.done();
    };
  });
  return <div />;
};

PageLoader.propTypes = {
};

export default PageLoader;
