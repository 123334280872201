import React from "react";
import useCourseSidebarState from "Components/Sidebar/CourseSidebar/useCourseSidebarState";
import {AnimateSharedLayout, motion} from "framer-motion";
import classnames from "classnames";
import UserMenu from "Components/Navbars/UserMenu/UserMenu";
import CourseSidebarHeader from "Components/Sidebar/CourseSidebar/CourseSidebarHeader/CourseSidebarHeader";
import CourseSidebarRoute from "Components/Sidebar/CourseSidebar/CourseSidebarRoute";
import {isMobileOnly} from "react-device-detect";
import CourseSidebarMobile from "Components/Sidebar/CourseSidebar/CourseSidebarMobile";
import CourseSidebarFooter from "./CourseSidebarFooter/CourseSidebarFooter";
import CourseSidebarChatGptButton from "Components/Sidebar/CourseSidebar/CourseSidebarChatGPTButton";
import CourseSidebarToggle from "Components/Sidebar/CourseSidebar/CourseSidebarToggle";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";

const CourseSidebar = ({routes, disableSidebar, children, url}) => {
  const {previewMode} = React.useContext(window.PreviewSidebarContext);
  const isInsideGroup = useGroupContext()?.id;

  const {
    currentPath,
    collapsed,
    toggleCollapsed,
    currentRoute,
  } = useCourseSidebarState(routes);

  if (previewMode) return children;

  if (disableSidebar) {
    return (
      <div key="course-main-panel" id="course-main-panel">
        <UserMenu />
        <div className="min-h-screen">{children}</div>
      </div>
    );
  }

  // Mobile view only
  if (isMobileOnly) {
    return (
      <div>
        {currentRoute?.hideUserMenu ? null : <UserMenu />}

        <CourseSidebarMobile
          url={url}
          routes={routes}
          currentPath={currentPath}
          currentRoute={currentRoute}
        />

        <div className="px-2 sm:p-4 min-h-screen" id="course-main-panel">
          <div>{children}</div>
        </div>
      </div>
    );
  }

  return (
    <AnimateSharedLayout layoutId="course-sidebar">
      <div
        id="course-sidebar"
        className={classnames(
          "grid",
          collapsed
            ? "grid-cols-[4rem_minmax(0,1fr)]"
            : "grid-cols-[200px_minmax(0,1fr)] lg:grid-cols-[250px_minmax(0,1fr)]"
        )}
      >
        <motion.div
          className={classnames(
            // "sticky top-0", // position
            // "fixed h-screen", // height
            "background", // color
            "border-r-1 border-color",
            "group/sidebar relative z-10"
          )}
          // layout="position"
          key="sidebar"
        >
          <div
            className={classnames(
              "fixed flex flex-col justify-between  h-screen ",
              "px-2 pt-4",
              collapsed ? "w-16" : "w-[200px] lg:w-[250px]"
            )}
          >
            <div>
              <CourseSidebarHeader collapsed={collapsed} />
              <div className="border-1 border-color my-2" />

              <div className="my-4" />

              {routes.map((route, i) => (
                <CourseSidebarRoute
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                  expandOnHover={collapsed && !isMobileOnly}
                  key={i}
                  url={url}
                  {...route}
                  currentPath={currentPath}
                />
              ))}
              <CourseSidebarChatGptButton collapsed={collapsed} />
            </div>
            {/* Footer section */}
            {!isInsideGroup && (
              <div>
                <CourseSidebarFooter
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                />
                <CourseSidebarToggle
                  toggleCollapsed={toggleCollapsed}
                  collapsed={collapsed}
                />
              </div>
            )}
          </div>
        </motion.div>
        <motion.div
          layout="position"
          key="course-main-panel"
          id="course-main-panel"
          className={classnames(
            "grid min-h-screen p-4",
            "grid-flow-row md:grid-flow-col",
            collapsed
              ? "auto-cols[1fr_1fr] md:auto-cols-[minmax(50%,_100%)_30%] lg:auto-cols-[minmax(50%,_100%)_35%]"
              : "auto-cols[1fr_1fr] md:auto-cols-[minmax(50%,_100%)_25%] lg:auto-cols-[minmax(50%,_100%)_30%]" // a little bit smaller when sidebar is expanded
          )}
        >
          <div className="w-full">{children}</div>
        </motion.div>
      </div>
    </AnimateSharedLayout>
  );
};

export default CourseSidebar;
