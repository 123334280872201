import useGetTag from "API/queries/tag/useGetTag";
import { CHAPTER_SORT_ORDER } from "utilities/constants/CHAPTERS_CONSTANTS";
import customSort from "utilities/customSort";
import { avgScore } from "utilities/exerciseUtilities/exerciseListOperations";

const useCourseLandingTopicDetails = (tag) => {
    const [tagData, loading] = useGetTag(tag?.id, false, true);

    const exerciseAvgScore = avgScore(tag?.exercises)

    let tagChapterContent = [];

    if (tagData?.chapters && tagData.chapters?.length > 0) {
        const tagsSorted = customSort({
            data: tagData.chapters,
            sortOrder: CHAPTER_SORT_ORDER,
            sortField: "chapter_type"
        });

        tagChapterContent = tagsSorted.map((tagData) => tagData.chapter_type)
    }

    return {
        loading,
        tagChapterContent,
        exerciseAvgScore
    }
}

export default useCourseLandingTopicDetails