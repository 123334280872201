import React from "react";
import {Button, Icon, SafeText, Trans} from "Components";

const OTPText = ({title, subTitle, showModal, requestSuccessful, email}) => {
  // When request is successful and the verification method is not modal, show the code input prompt instead of the default text

  if (requestSuccessful && !showModal) {
    return (
      <div>
        <div className="flex gap-2 items-center">
          <OTPTextPromptTitle />
          <Icon icon="spinner-third" size="md" spin />
        </div>
        <OTPTextPromptSubTitle email={email} />
      </div>
    );
  }

  return (
    <div>
      <SafeText type="dsm" weight="md" color="text-gray">
        {title}
      </SafeText>

      <div>
        <SafeText
          type="tmd"
          weight="md"
          color="text-gray-light"
          className="mt-2"
        >
          {subTitle}
        </SafeText>
      </div>
    </div>
  );
};

export const OTPTextPromptTitle = () => (
  <Trans type="dsm" weight="md" color="text-gray">
    Please check your inbox
  </Trans>
);

export const OTPTextPromptSubTitle = ({email}) => (
  <Trans
    type="tmd"
    weight="md"
    color="text-gray-light"
    className="mt-2"
    email={email}
  >
    We have sent the verification code to {{email}}.
  </Trans>
);

export default OTPText;
