import React, {useState, useEffect} from "react";
import {Trans} from "Components";
import client from "client";
import {GET_CURRENT_USER_PAYMENT_METHODS} from "API/queries/paymentMethods/useGetCurrentUserPaymentMethods";

const useCreateStripeIntentFormResponseState = ({
  stripe,
  onComplete = () => {},
  setErrorMessage,
}) => {
  const [message, setMessage] = useState(null);

  // Retrieve the "setup_intent_client_secret" query parameter appended to
  // your return_url by Stripe.js
  const clientSecretFromUrl = new URLSearchParams(window.location.search).get(
    "setup_intent_client_secret"
  );

  const [clientSecret, setClientSecret] = useState(clientSecretFromUrl);

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(({setupIntent}) => {
      // Inspect the SetupIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (setupIntent.status) {
        case "succeeded":
          setMessage(
            <Trans>Success! Your payment method has been saved.</Trans>
          );
          onComplete(setupIntent.payment_method); // Call the onComplete callback
          refetchPaymentMethods();
          break;

        case "processing":
          setMessage(
            <Trans>
              Processing payment details. We'll update you when processing is
              complete.
            </Trans>
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setErrorMessage(
            <Trans>
              Failed to process payment details. Please try another payment
              method.
            </Trans>
          );
          break;
      }
    });
  }, [stripe, clientSecret]);

  return {
    message,
    setClientSecret,
    setMessage,
  };
};

const refetchPaymentMethods = () => {
  client.query({
    query: GET_CURRENT_USER_PAYMENT_METHODS,
    fetchPolicy: "network-only",
  });
};

export default useCreateStripeIntentFormResponseState;
