import React from "react";
import classnames from "classnames";
import {Icon, Typography, Input} from "Components/_Elements/index";
import {sizesMap, textTypeMapping} from "./Select.constants";

const SelectInput = ({
  transparent,
  borderless,
  disabled,
  placeholder,
  size,
  selectedItem,
  inverted,
  focused,
  handleFocus,
  handleBlur,
  showSearch,
  fluid,
  handleSearch,
  searchTerm,
}) => {
  if (showSearch) {
    return (
      <Input
        type="text"
        placeholder={focused ? selectedItem?.text || placeholder : placeholder}
        onFocus={handleFocus}
        onChange={handleSearch}
        focused={focused}
        value={focused ? searchTerm : selectedItem?.text}
        icon="search"
        iconRight={focused ? "chevron-up" : "chevron-down"}
        size={size}
        fluid={fluid}
        inverted={inverted}
        transparent={transparent}
        id="select-input"
      />
    );
  }

  return (
    <div
      // ref={selectRef}
      className={classnames(
        "background border-2 border-gray w-full flex justify-between items-center cursor-pointer",
        sizesMap[size], // size of the select
        focused && "border-primary-300 shadow-xs-primary",
        disabled &&
          "surface border-gray-300 text-gray-300 select-none outline-0",
        transparent ? "bg-transparent" : "background",
        borderless && "border-transparent"
      )}
      placeholder={placeholder}
      disabled={disabled}
      onClick={() => {
        focused ? handleBlur() : handleFocus();
      }}
      onFocus={handleFocus}
    >
      <Typography
        type={textTypeMapping[size]}
        className={classnames(
          "w-full overflow-hidden whitespace-nowrap text-ellipsis",
          inverted ? "text-primary-25" : "text-gray"
        )}
      >
        {selectedItem?.text || placeholder}
      </Typography>

      <span className="ml-2">
        <Icon
          size={size}
          icon="chevron-down"
          color={inverted ? "text-primary-25" : "text-gray"}
        />
      </span>
    </div>
  );
};

export default SelectInput;
