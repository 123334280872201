import React from "react";
import CommentAuthor from "Components/Comment/CommentAuthor";
import {Reactions, Trans} from "Components";

const ChapterMetaInfo = ({chapter}) => {
  return (
    <div className="w-full p-4 rounded-lg surface flex flex-col gap-8  items-start ">
      <div className="w-full ">
        <Trans weight="sb" color="text-gray-600">
          Authors
        </Trans>
        <div className="mt-2">
          <CommentAuthor
            size="md"
            showRole={false}
            author={chapter.author}
            createdAt={chapter.createdAt}
          />
        </div>
      </div>
      <div className="w-full">
        <Reactions
          parentType="Chapter"
          parentId={chapter.id}
          size={16}
          bottom={true}
        />
      </div>
    </div>
  );
};

export default ChapterMetaInfo;
