export const snakeToCamel = string =>
  string.replace(/([_][a-z])/g, group => group.toUpperCase().replace("_", ""));

export const stringToENUM = string =>
  string
    .replace(/[A-Z]/g, (group, offset) => (offset > 0 ? "_" + group : group))
    .toUpperCase();

export const truncateText = (text, maxLength = 100) => {
  if (!text) return text;

  if (text.length >= maxLength) {
    return text.substring(0, maxLength).split(/<(\/p|br.\/)>/)[0] + "...";
  }
  return text;
};

export const kebabCaseSplitter = string => {
  var res = string.split(" - ");
  const courseCode = res[0];
  const cleanCourseName = res[1];
  return [courseCode, cleanCourseName];
};

export const PascalCaseSplitter = string => {
  // Split into array of words based on capitlization
  return string.split(/(?=[A-Z])/);
  //
};

export const removeEscapedText = string => {
  try {
    return string.replace(/\\./g, "");
  } catch {
    return string;
  }
};

export const padString = (original, padding) => {
  if (!original) return "";
  let chars = original;

  if (typeof original === "number") {
    chars = original.toString();
  }

  chars = chars.split("");

  for (let i = chars.length; i < padding; i++) {
    chars.push("_");
  }

  return chars.join("");
};

export const animateSplit = e => {
  const React = require("react");
  const t = e.split(" ");
  const c = [];
  t.forEach((str, i) => {
    c.push(<span key={`${str}-${i}`}>{str}</span>);
    if (i < t.length - 1) {
      c.push(<span key={` -${i}`}> </span>);
    }
  });
  return c;
};

export const randomString = length => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const maybeString = value => {
  if (typeof value === "string") {
    return value;
  }
  return "";
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
