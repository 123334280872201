import React from "react";
import {Icon, SafeText} from "Components";

const ModalHeader = ({
  title = null,
  description = null,
  icon,
  size,
  type,
  handleClose,
  closable,
  showCloseIcon,
}) => {
  if (!title && !description && !icon) return null;

  return (
    <div className="flex items-start justify-between gap-4 w-full mb-4 sm:mb-8">
      <div className="flex gap-3 items-start flex-row  sm:gap-4 sm:items-center grow">
        <ModalHeaderIcon icon={icon} size={size} type={type} />
        <div>
          <SafeText type="tlg sm:dxs" weight="md" color="text-gray">
            {title}
          </SafeText>

          <SafeText
            type="tsm"
            weight="normal"
            color="text-gray-light"
            className="mt-2"
          >
            {description}
          </SafeText>
        </div>
      </div>
      {closable && showCloseIcon && (
        <div className="p-1 aspect-square rounded-full flex justify-center items-center surface-primary">
          <Icon
            icon="times"
            size="lg"
            onClick={handleClose}
            className="cursor-pointer"
            fixedWidth
          />
        </div>
      )}
    </div>
  );
};

const ModalHeaderIcon = ({icon, size, type}) => {
  if (!icon) return null;

  if (typeof icon === "string") {
    return (
      <Icon fixedWidth icon={icon} size="md" filled outlined fillType={type} />
    );
  }

  return icon;
};

export default ModalHeader;
