import React from "react";
import {Alert, Button, Input, Skeleton, Trans} from "Components";
import {useTranslation} from "react-i18next";
import useResetPasswordState from "./useResetPasswordState";
import {AuthLayoutTitleProps} from "routes/Auth/AuthLayout";

const PasswordStrengthBar = React.lazy(() =>
  import("react-password-strength-bar")
);

const ResetPassword = ({token, onComplete = null}) => {
  const {t: trans} = useTranslation();

  const {handleChange, values, errors, submit, loading} = useResetPasswordState(
    token,
    onComplete
  );

  return (
    <div>
      <Trans {...AuthLayoutTitleProps}>Choose a new password</Trans>

      <form className="mt-4">
        <Input
          label={trans("New password")}
          type="password"
          name="password"
          placeholder={trans("New password")}
          value={values.password}
          error={errors.password || null}
          onChange={handleChange}
          fluid
        />

        <div className="mt-2 mb-4">
          <React.Suspense fallback={<Skeleton />}>
            <PasswordStrengthBar
              password={values.password}
              minLength={6}
              shortScoreWord={trans("Too short")}
              scoreWords={[
                trans("Weak"),
                trans("Weak"),
                trans("Okay"),
                trans("Good"),
                trans("Strong"),
              ]}
              barColors={["#ddd", "#F04438", "#F79009", "#6172F3", "#12B76A"]}
            />
          </React.Suspense>
        </div>

        {errors.token && <Alert title={errors.token} type="error" />}

        <Button
          className="mt-4"
          type="primary"
          name="submit"
          fluid
          loading={loading}
          onClick={event => {
            event.preventDefault();
            submit();
          }}
          error={Object.entries(errors).length}
        >
          {trans("Save")}
        </Button>
      </form>
    </div>
  );
};

export default ResetPassword;
