import React from "react";
import {useHistory} from "react-router-dom";
import {Card, Trans, Button, Typography} from "Components";
import useGetCourseExams from "API/queries/exam/useGetCourseExams";

const CourseStatsExamsUploaded = () => {
  const {user} = React.useContext(window.UserContext);
  const {courseId, getCourseLink} = React.useContext(window.CourseContext);
  const uploadLink = getCourseLink("upload");
  const history = useHistory();
  const [exams, loading] = useGetCourseExams(courseId, false);
  const examCount = exams?.length;

  if (loading) return null;

  return (
    <Card
      title={<Trans>Exams uploaded</Trans>}
      action={
        <Button
          disabled={!user?.id}
          type="link"
          size="xs"
          onClick={() => history.push(uploadLink)}
        >
          <Trans>Upload more exams</Trans>
        </Button>
      }
    >
      <Typography className="p-2" type="dmd" weight="bold">
        {examCount}
      </Typography>
    </Card>
  );
};

export default CourseStatsExamsUploaded;
