import React from "react";
import classnames from "classnames";
import {Button, Input, Link, Trans} from "Components";
import useLoginFormState from "./useLoginFormState";

const LoginForm = ({
  handleAuthResponse,
  showForgotPasswordLink = true,
  grid = false,

  transparentInput = true,
}) => {
  const {values, handleChange, errors, loading, submit} = useLoginFormState({
    handleAuthResponse,
  });

  return (
    <form
      onSubmit={e => e.preventDefault()}
      className={classnames(
        "mt-4",
        grid ? "grid grid-cols-1 md:grid-cols-2 gap-4" : "flex flex-col gap-2"
      )}
    >
      {/* Old Component in use, please find the Bit.Dev Input component in the coments */}

      <Input
        id={"email"}
        name={"email"}
        type={"email"}
        required={true}
        label={<Trans>Email</Trans>}
        value={values.email}
        error={errors.email}
        onChange={handleChange}
        transparent={transparentInput}
      />

      <Input
        id={"password"}
        name={"password"}
        type={"password"}
        autoComplete="current-password"
        required={true}
        label={<Trans>Password</Trans>}
        value={values.password}
        error={errors.password}
        onChange={handleChange}
        transparent={transparentInput}
      />

      <div
        className={classnames(
          "flex flex-col gap-2 my-2",
          grid ? "md:col-span-2" : "w-full"
        )}
      >
        {showForgotPasswordLink && (
          <Link
            to={{
              pathname: "/forgot-password" + window.location.search,
            }}
            basic
          >
            <u>
              <Trans>Did you forget your password?</Trans>
            </u>
          </Link>
        )}

        <Button
          fluid
          loading={loading}
          type="submit"
          name="submit"
          onClick={e => {
            e.preventDefault();
            window.analytics.track("login_submit_clicked");
            submit();
          }}
          error={Object.entries(errors).length}
        >
          <Trans>Login</Trans>
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
