import React from "react";
import Summer2022CampaignOnBoardingEnrollment from "./Summer2022CampaignOnBoardingEnrollment/Summer2022CampaignOnBoardingEnrollment";
import Summer2022CampaignOnBoardingSignUp from "./Summer2022CampaignOnBoardingSignUp";
import useSummer2022CampaignOnBoardingState from "./useSummer2022CampaignOnBoardingState";
import { Summer2022CampaignAuthContainer } from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignAuth";

const Summer2022CampaignOnBoarding = ({ setRecurringUser }) => {
  const {
    currentStep,
    onComplete,
    coupon,
    loading,
  } = useSummer2022CampaignOnBoardingState();

  if (currentStep === 1) {
    // Return school and program selection form
    return (
      <Summer2022CampaignAuthContainer title="Stämmer följande information?">
        <Summer2022CampaignOnBoardingEnrollment
          school={coupon?.school}
          program={coupon?.program}
        />
      </Summer2022CampaignAuthContainer>
    );
  }

  if (loading) {
    return (
      <Summer2022CampaignAuthContainer title="Ett ögonblick, vi hämtar din personliga kupongkod..." />
    );
  }

  return (
    <Summer2022CampaignAuthContainer title="Vi börjar med att skapa ett konto.">
      <Summer2022CampaignOnBoardingSignUp
        onComplete={onComplete}
        couponId={coupon?.id}
        setRecurringUser={setRecurringUser}
      />
    </Summer2022CampaignAuthContainer>
  );
};

export default Summer2022CampaignOnBoarding;
