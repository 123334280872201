import React from "react";
import EXAM_IMAGES_URL from "../../../utilities/constants/EXAM_IMAGES_URL";
import ExerciseViewImage from "./ExerciseViewImage/ExerciseViewImage";
import useGetExerciseContent from "API/queries/exercise/useGetExerciseContent";
import {ExerciseViewContext} from "Components/Workboard/ExerciseView/ExerciseView";
import {TextDataDisplay, Trans} from "Components";
import useLocalStorageState from "utilities/hooks/useLocalStorageState";
import {isMobile} from "react-device-detect";
import workboard, {WorkboardContext} from "Components/Workboard/Workboard";

const ExerciseViewExerciseDisplay = ({exerciseId, imageSize}) => {
  const workboardContext = React.useContext(WorkboardContext);
  const exerciseViewContext = React.useContext(ExerciseViewContext);

  const [currentExerciseImage, setCurrentExerciseImage] = React.useState(null);

  const [exerciseContent, loading] = useGetExerciseContent(exerciseId);

  // Set the current image:
  React.useEffect(() => {
    if (!loading && exerciseContent) {
      const imageUrl = getLatestRevisionImage(exerciseContent);
      setCurrentExerciseImage(imageUrl);
    }
  }, [loading, exerciseContent]);

  if (loading && currentExerciseImage === null)
    return <Trans>Loading... </Trans>;

  if (!loading && !currentExerciseImage) {
    return <i>Uppgiften hittades inte, var god försök igen</i>;
  }

  if (workboardContext?.textMode) {
    return (
      <TextDataDisplay
        parentType="exercise"
        parentId={exerciseId}
        onFail={exerciseViewContext?.disableTextMode}
      />
    );
  }

  return (
    <ExerciseViewImage
      loading={loading}
      imageSize={imageSize}
      url={EXAM_IMAGES_URL + currentExerciseImage}
      initialWidth={exerciseContent?.w}
    />
  );
};

export const getLatestRevisionImage = exerciseContent => {
  if (!exerciseContent) return null;

  const {segments} = exerciseContent;

  if (!segments || segments.length === 1) return exerciseContent.imageUrl;

  // Find the latest revision
  const latestRevision = segments.reduce((latest, segment) => {
    if (segment.revision > latest.revision) return segment;
    return latest;
  }, segments[0]);

  return latestRevision.imageUrl;
};

export default ExerciseViewExerciseDisplay;
