import React from "react";
import {motion} from "framer-motion";
import {Avatar, Loader, Typography} from "Components";
import CourseChatGPTResultsMessageTimestamp from "Widgets/Course/CourseChatGPT/CourseChatGPTResultsMessage/CourseChatGPTResultsMessageTimestamp";
import CourseChatGptResultsMessageAvatar from "Widgets/Course/CourseChatGPT/CourseChatGPTResultsMessage/CourseChatGPTResultsMessageAvatar";
import CourseChatGptResultsMessageContent from "Widgets/Course/CourseChatGPT/CourseChatGPTResultsMessage/CourseChatGPTResultsMessageContent";

const CourseChatGPTResultsMessage = ({
  content,
  hidden,
  role,
  sender,
  timestamp,
  loading,
  inverted,
  showAvatar = true,
}) => {
  const {showAsModal} = React.useContext(window.GPTContext);

  if (hidden) {
    return null;
  }

  let avatarSize = showAsModal ? "sm" : "xs";

  return (
    <div className="w-full">
      <motion.div
        className="flex gap-3 items-center w-full"
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
      >
        <CourseChatGptResultsMessageAvatar
          showAvatar={showAvatar}
          role={role}
          sender={sender}
          avatarSize={avatarSize}
        />

        <div className="w-full grow">
          <div className="flex gap-2 items-start justify-between">
            <div className="flex gap-2 items-center">
              <Typography
                type="tsm"
                weight="sb"
                color={
                  inverted
                    ? "text-gray-200"
                    : showAvatar
                    ? "text-gray-light"
                    : "text-primary"
                }
              >
                {sender.name}
              </Typography>
              {loading && (
                <Loader
                  type="Beat"
                  size={8}
                  color={inverted ? "#FCFCFD" : "#586FB5"}
                />
              )}
            </div>
            <CourseChatGPTResultsMessageTimestamp timestamp={timestamp} />
          </div>
        </div>
      </motion.div>
      <div className="flex gap-3 items-start w-full mt-2">
        {//  Add a hidden span with the avatar to push the content to the right when not in compact mode

        showAsModal && (
          <span className="opacity-0">
            <Avatar user={sender} size={avatarSize} />
          </span>
        )}

        <div>
          <CourseChatGptResultsMessageContent
            content={content}
            inverted={inverted}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseChatGPTResultsMessage;
