import React from "react";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {GET_COURSE_THREADS} from "API/queries/thread/useGetCourseThreads";
import client from "client";
import useGroupHome from "../../../routes/Group/GroupHome/useGroupHome";

const useGroupHomeLatestQuestions = () => {
  const group = useGroupContext();

  const programIds = React.useMemo(() => {
    if (group?.subGroups?.length > 0) {
      if (group?.program?.id) {
        return [
          group.program.id,
          ...group.subGroups.map(group => group?.program?.id),
        ];
      }
      return group.subGroups.map(group => group?.program?.id);
    }
    return [group.program?.id];
  }, [group]);

  const {getGroupCourses} = useGroupHome();
  const programCourses = getGroupCourses();

  const [fetchCompleted, setFetchCompleted] = React.useState(false);
  const [allThreads, setAllThreads] = React.useState([]);

  const getThreadsForCourse = async courseId => {
    // Can't run a hook inside a function: use client.query instead
    const {data, loading, error} = await client.query({
      query: GET_COURSE_THREADS,
      variables: {
        courseId,
        includeExercise: true,
        includeReplies: true,
        limit: 10,
        offset: 0,
      },
      fetchPolicy: "network-only",
      errorPolicy: "all",
      context: {
        batch: true,
      },
    });

    if (data?.getCourseThreads) {
      // Filter
      const programThreads = data.getCourseThreads.filter(thread => {
        return programIds.includes(thread.comments?.asker?.program?.id);
      });

      setAllThreads(prevThreads => [...prevThreads, ...programThreads]);
    }
  };

  React.useEffect(() => {
    // To avoid infinite loop we check if we have any threads already
    if (programCourses.length === 0) {
      console.log("No courses");
      return;
    }

    if (allThreads.length > 0 || fetchCompleted) return;

    programCourses.forEach(course => {
      if (course) {
        getThreadsForCourse(course);
      }
    });

    setFetchCompleted(true);
  }, [programCourses, group]);

  const threads = React.useMemo(() => {
    const sortedThread = [...new Set(allThreads)].sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    // Take 5 latest threads
    return sortedThread.slice(0, 10);
  }, [allThreads]);

  // Get all threads
  return {
    threads,
  };
};

export default useGroupHomeLatestQuestions;
