import React from 'react';

const useExamUploadDocumentsRowFiles = ({updatePreviewResource, updateResource}) => {
    const getPossibleTypes = (file, document) => {
        let possibleTypes;

        if (document.files.length === 1) possibleTypes = ["mixed", "exam"]; // Only one file, so it must be either mixed or exam
        if (document.files.length === 2) possibleTypes = ["exam", "exam_solutions"]; // Two files, so it must be either exam or solution

        // Filter out the current type
        possibleTypes = possibleTypes.filter(t => t !== file.type);
        return possibleTypes;
    };

    const handleClick = (sortedFiles, file, t) => {
        const originalType = file.type;
        updatePreviewResource({
            ...file,
            type: t,
        });
        if (sortedFiles.length > 1) {
            handleFileTypeChangeConflict(file, originalType, t, sortedFiles, updateResource);
        }
    }


    const handleFileTypeChangeConflict = (changedFile, originalType, newType, files, updateResource) => {
        const otherFile = files.find(f => f.id !== changedFile.id);
        if (otherFile?.type == newType) {
            updateResource({
                ...otherFile,
                type: originalType,
            });
        }}

    return {
        getPossibleTypes,
        handleClick,
    }
};

export default useExamUploadDocumentsRowFiles;