import React from "react";

const useCoursesSearchState = ({courses, loading}) => {
  const [searchValue, setSearchValue] = React.useState("");

  const handleSearchValueChange = e => {
    setSearchValue(e.target.value);
  };

  const searchHidden = React.useMemo(() => {
    return courses.length === 0 || loading;
  }, [courses]);

  const filteredCourses = React.useMemo(() => {
    if (!searchValue || searchValue.trim() === "") return courses;

    const searchList = courses.map(course => {
      const matchesName = course.name
        .toLowerCase()
        .includes(searchValue.toLowerCase())
        ? 1
        : 0;

      const matchesCode = course.code
        .toLowerCase()
        .includes(searchValue.toLowerCase())
        ? 1
        : 0;

      return {
        ...course,
        sort: matchesName + matchesCode,
        hidden: matchesName + matchesCode === 0,
      };
    });
    return searchList.sort((a, b) => b.sort - a.sort);
  }, [courses, searchValue]);

  return {
    searchValue,
    handleSearchValueChange,
    searchHidden,
    filteredCourses,
  };
};

export default useCoursesSearchState;
