import React from "react";
import EXAM_IMAGES_URL from "utilities/constants/EXAM_IMAGES_URL";
import {ExerciseViewContext} from "./ExerciseView";
import ExerciseViewImage from "./ExerciseViewImage/ExerciseViewImage";
import useGetExerciseSolution from "API/queries/solution/useGetExerciseSolution";
import {EmptyState, Loader, TextDataDisplay, Trans} from "Components";
import {WorkboardContext} from "Components/Workboard/Workboard";
import {getLatestRevisionImage} from "Components/Workboard/ExerciseView/ExerciseViewExerciseDisplay";

const ExerciseViewSolution = () => {
  const workboardContext = React.useContext(WorkboardContext);
  const {solutionVisible, id} = React.useContext(ExerciseViewContext);

  const [solution, loading] = useGetExerciseSolution(id);

  if (loading) return <Loader />;

  let imageUrl = getLatestRevisionImage(solution);

  if (!solution || !imageUrl) {
    if (solutionVisible) {
      return (
        <div className="p-2">
          <EmptyState
            type="gray"
            illustration="cloud"
            title={<Trans>No solution available for this exercise</Trans>}
          />
        </div>
      );
    }
  }

  if (workboardContext?.textMode) {
    return (
      <div>
        <TextDataDisplay
          loading={loading}
          parentType="solution"
          parentId={solution.id}
        />
      </div>
    );
  }

  return (
    <ExerciseViewImage
      key="solution"
      url={EXAM_IMAGES_URL + imageUrl}
      initialWidth={solution.w}
    />
  );
};

export default ExerciseViewSolution;
