import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import DifficultyChip from "Components/DifficultyChip/DifficultyChip";
import ExerciseCheckMark from "../ExerciseCheckMark/ExerciseCheckMark";
import "./ExerciseCard.scss";
import {Icon} from "Components/_Elements";
import useExerciseCardScrollIntoView from "Components/ExerciseCard/useExerciseCardScrollIntoView";
import {Badge, Typography} from "../_Elements";

const ExerciseCard = ({exercise, selectedExercise, autoCollapse = true}) => {
  const {t: trans} = useTranslation();

  const examDate = new Date(exercise.date.substring(0, 10));
  const examDateFormatted = examDate.toLocaleDateString("sv-SE", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const active = exercise.id == selectedExercise;

  const ref = useExerciseCardScrollIntoView(active);

  let author = trans("Unknown author");
  let authors = [];

  if (exercise.author) {
    authors = exercise.author.split(",");
    // get first 10 characters of author name
    author = authors[0].substring(0, 20);
  }

  let faded = true;

  if (active || selectedExercise == null) {
    faded = false;
  }

  return (
    <div
      ref={ref}
      className={classnames(
        "exercise-card background border-2 cursor-pointer hover:border-primary-300 overflow-hidden",
        faded && autoCollapse ? "opacity-50 h-14" : "opacity-100",
        !selectedExercise
          ? "w-96 m-2"
          : "mb-4 w-max rounded-lg group-hover:opacity-100 transition-[height] group-hover:h-32 duration-300 ease-in-out delay-300",
        active ? "border-primary-500" : "border-color",
        active && autoCollapse ? "h-14" : "",
        !autoCollapse && "h-32",
        {
          collapsed: selectedExercise,
          active,
        }
      )}
    >
      <div className="exercise-card-metadata text-primary gap-3 p-3">
        <div className="flex justify-between items-center w-full gap-4">
          <DifficultyChip score={exercise.score} height={36} filled />
          {exercise.stats?.total > 0 && (
            <Typography type="tsm" className="text-gray-500">
              {exercise.stats?.total || 0}
              <Icon icon="fa-solid fa-fire-flame-curved" className="mr-1" />
            </Typography>
          )}
        </div>
        <div>
          <Typography color="text-gray-500">
            {examDateFormatted} #{exercise.number}
          </Typography>
          {!selectedExercise && (
            <div className="flex gap-2 items-end">
              <Typography type="tmd" color="text-gray-500">
                {author}
              </Typography>
              {authors.length > 1 && (
                <Badge type="gray" border={true} size="xs">
                  + {authors.length - 1}
                </Badge>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full h-8 justify-between border-t-2 border-gray-200 rounded-b-lg p-1">
        <ExerciseCheckMark
          exercise={exercise}
          showIfUnrated={true}
          key={exercise.self_evaluation?.score}
        />
        <div>
          <Icon
            color={exercise.bookmarked ? "text-primary-400" : "text-gray-300"}
            icon={
              exercise.bookmarked ? "fas fa-bookmark" : "fa-regular fa-bookmark"
            }
            className="ml-1"
          />
          <Icon
            color={
              exercise.notes?.length > 0 ? "text-primary-400" : "text-gray-300"
            }
            icon={
              exercise.notes?.length > 0 ? "fas fa-note" : "fa-regular fa-note"
            }
            className="ml-1"
          />
        </div>
      </div>
    </div>
  );
};

ExerciseCard.propTypes = {
  exercise: PropTypes.object.isRequired,
  selectedExercise: PropTypes.number,
};

export default ExerciseCard;
