import React from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {GET_GROUP_MEMBER_INVITES} from "API/queries/groups/useGetGroupMemberInvites";
import {GET_GROUP} from "API/queries/groups/useGetGroup";

const CREATE_GROUP_MEMBER_INVITE = gql`
  mutation acceptGroupMemberInvite($token: String!) {
    acceptGroupMemberInvite(token: $token) {
      ok
      error {
        message
        code
      }
    }
  }
`;

const useAcceptGroupMemberInvite = ({token, refetchQueries = [], groupId}) => {
  const [acceptGroupMemberInvite, {data, loading, error}] = useMutation(
    CREATE_GROUP_MEMBER_INVITE,
    {
      variables: {
        token,
      },
      errorPolicy: "all",
      refetchQueries: [
        {
          query: GET_GROUP,
          variables: {
            id: groupId,
            includeSubGroups: true,
            includeMembers: true,
          },
          errorPolicy: "all",
        },
        ...refetchQueries,
      ],
    }
  );

  if (error) {
    console.log(`Error in useAcceptGroupMemberInvite: ${error}`);
  }

  const response = data?.acceptGroupMemberInvite;

  return {
    acceptGroupMemberInvite,
    response,
    loading,
  };
};

export default useAcceptGroupMemberInvite;
