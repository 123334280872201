import React from "react";

const useExamUploadDocumentsPreviewState = () => {
  const {
    previewResource: resource,
    togglePreview: closePreview,
    updatePreviewResource,
  } = React.useContext(ExamUploadContext);

  const container = React.useRef(null);

  const [expanded, setExpanded] = React.useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const onTypeChange = type => {
    updatePreviewResource({
      ...resource,
      type,
    });
  };

  const onDateChange = (dateObj, date) => {
    updatePreviewResource({
      ...resource,
      date,
      dates: [date],
    });
  };

  const onClose = () => closePreview(resource);

  return {
    container,
    expanded,
    toggleExpanded,
    resource,

    onDateChange,
    onTypeChange,
    onClose,
  };
};

export default useExamUploadDocumentsPreviewState;
