import React from "react";
import classnames from "classnames";
const CheckoutSection = ({
  title,
  subTitle = null,
  children = null,
  action = null,
  id,
  surface,
  className,
}) => {
  return (
    <div
      className={classnames(
        surface
          ? "mt-4 p-4 surface-primary border-2 border-primary rounded-lg"
          : "mt-8",
        className
      )}
      id={id}
    >
      <div className="flex gap-4 md:flex-row justify-between pb-3 border-b-1 border-gray">
        <div className="grow">
          {React.cloneElement(title, {
            type: title.props.type || "tlg",
            weight: "md",
          })}

          {subTitle &&
            React.cloneElement(subTitle, {
              type: "tsm",
              color: "text-gray-light",
              className: "mt-2",
            })}
        </div>
        {action}
      </div>

      <div className="mt-4">{children}</div>
    </div>
  );
};

export default CheckoutSection;
