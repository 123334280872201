import React from "react";
import LandingPageSection from "Components/LandingPageSection/LandingPageSection";
import { CourseLandingContext } from "routes/Course/CourseLanding/useCourseLandingContext";

const CourseLandingFeatures = () => {
  const { landingPageContent } = React.useContext(CourseLandingContext);

  const sectionId = React.useMemo(
    () =>
      landingPageContent?.find(content => content.title === "clp_features")?.sys
        ?.id,
    []
  );

  return (
    <div>
      <LandingPageSection sectionId={sectionId} />
    </div>
  );
};

CourseLandingFeatures.propTypes = {};

export default CourseLandingFeatures;
