import {
  difficultyColors,
  difficultyColorsLight,
} from "utilities/constants/COLORS";
import i18n from "i18next";

export const scoreToDifficultyIndex = score => {
  if (score == null) {
    return 0;
  }
  let diff = 1;
  if (score > 0.99) {
    diff = 1;
  }
  if (score > 1.69) {
    diff = 2;
  }
  if (score > 2.69) {
    diff = 3;
  }
  if (score > 3.29) {
    diff = 4;
  }

  return diff;
};

export const scoreToDifficultyColor = (score, light = false) => {
  const index = scoreToDifficultyIndex(score);
  return light ? difficultyColorsLight[index] : difficultyColors[index];
};

export const scoreToLabel = score => {
  if (score === null || score === undefined || score === 0) {
    return i18n.t("Not rated");
  }
  if (score <= 1) {
    return i18n.t("Easy");
  }
  if (score <= 2) {
    return i18n.t("Medium");
  }
  if (score <= 3) {
    return i18n.t("Hard");
  }
  return i18n.t("Nightmare");
};
