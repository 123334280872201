import React from "react";
import {Alert, Loader, Section, Trans, Typography} from "Components";
import formatExamDate from "utilities/formatExamDate";
import {useTranslation} from "react-i18next";
import useCourseExamView from "routes/Course/CourseExamView/useCourseExamView";
import CourseExamViewSolutionsToggle from "routes/Course/CourseExamView/CourseExamViewSolutionsToggle";
import ExamViewDocument from "routes/Course/CourseExamView/ExamViewDocument/ExamViewDocument";
import {useHistory} from "react-router-dom";
import useCourseLink from "../useCourseLink";

const CourseExamView = ({examId, showProgress = true}) => {
  const {
    exam,
    loading,
    pdfUrl,
    pdfExists,
    pdfLoading,
    showSolution,
    setShowSolution,
    setSolutionSource,
  } = useCourseExamView(examId);

  const {t: trans} = useTranslation();

  if (loading || pdfLoading) {
    return <Loader />;
  }

  const date = formatExamDate(exam.date);
  const author = exam.author || trans("Unknown author");
  const history = useHistory();
  const uploadLink = useCourseLink("upload");

  return (
    <Section
      title={<Typography>{date}</Typography>}
      subTitle={<Typography>{author}</Typography>}
      action={
        <CourseExamViewSolutionsToggle
          showSolution={showSolution}
          setShowSolution={setShowSolution}
          exam={exam}
        />
      }
    >
      {(!pdfExists ||
        pdfUrl === null ||
        (showSolution && !exam.exam_solutions_src)) && (
        <Alert
          title={<Trans>PDF is missing</Trans>}
          type="warning"
          subTitle={
            showSolution ? (
              <Trans>
                The PDF file for the solutions sheet is missing. Have you
                checked if the solutions are in the exam sheet?
              </Trans>
            ) : (
              <Trans>
                The PDF file for this exam sheet is missing. Please upload a PDF
                file before creating exercises.
              </Trans>
            )
          }
          primaryAction={{
            label: <Trans>Upload file</Trans>,
            onClick: () => history.push(uploadLink),
          }}
          secondaryAction={
            showSolution
              ? {
                  label: <Trans>The solutions are in the exercise sheet</Trans>,
                  onClick: event => {
                    setSolutionSource(pdfUrl);
                  },
                }
              : null
          }
        />
      )}
      <ExamViewDocument
        pdfLoading={pdfLoading}
        exam={exam}
        showProgress={showProgress}
        pdfUrl={pdfUrl}
      />
    </Section>
  );
};

export default CourseExamView;
