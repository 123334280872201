import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {CourseContext} from "routes/Course/CourseContextProvider";
import formatExercise from "utilities/exerciseUtilities/formatExercise";

export const GET_EXERCISE = gql`
  query getExercise($id: ID!, $withModule: Boolean!, $courseId: ID!) {
    getExercise(id: $id) {
      id
      number
      score
      imageUrl
      tags {
        id
        name
        module(course_id: $courseId) @include(if: $withModule) {
          id
          name
        }
      }
      stats {
        easy
        medium
        hard
        nightmare
        total
      }
      self_evaluation {
        id
        score
        date
      }
      source {
        id
        date
        author
      }
    }
  }
`;

const useGetExercise = (id, withContent, withModule, courseId) => {
  const courseContext = React.useContext(CourseContext);

  let exercise = null;

  const {data, loading, error} = useQuery(GET_EXERCISE, {
    variables: {
      id,
      withContent: withContent ? withContent : false,
      withModule: withModule ? withModule : false,
      courseId: courseContext ? courseContext?.courseId : courseId,
    },
    fetchPolicy: "cache-first",
    context: {
      batch: true,
    },
  });

  if (error) {
    console.log("Error in useGetExercise, ", error);
  }

  if (data && data.getExercise) {
    exercise = formatExercise(data.getExercise);
  }

  return [exercise, loading];
};

export default useGetExercise;
