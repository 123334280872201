import ExerciseDiscussion from "Components/Workboard/ExerciseDiscussion/ExerciseDiscussion";
import { ExerciseViewContext } from "Components/Workboard/ExerciseView/ExerciseView";
import React from "react";
import "./ExerciseViewForum.scss";

const ExerciseViewForum = ({ exerciseId }) => {
  const { forumVisible } = React.useContext(ExerciseViewContext);

  if (!forumVisible || !exerciseId) {
    return null;
  }
  return (
    <div className="exercise-view-forum">
      <ExerciseDiscussion exerciseId={exerciseId} />
    </div>
  );
};

export default ExerciseViewForum;
