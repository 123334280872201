import React from "react";
import useKeyboardShortcuts from "utilities/hooks/useKeyboardShortcuts";
import useEventTracking from "utilities/hooks/useEventTracking";

const useExerciseViewSolutionState = ({ initSolutionVisibility }) => {
  const { logExerciseEvent } = useEventTracking();

  const [solutionVisible, setSolutionVisible] = React.useState(
    initSolutionVisibility
  );

  const toggleSolutionVisible = () => {
    let logUserAction = solutionVisible
      ? "hide_solution_clicked"
      : "show_solution_clicked";

    logExerciseEvent(logUserAction);
    setSolutionVisible(!solutionVisible);
  };

  useKeyboardShortcuts(
    [{ key: "s", action: () => setSolutionVisible(!solutionVisible) }],
    [solutionVisible]
  );

  return {
    solutionVisible,
    toggleSolutionVisible,
    setSolutionVisible,
  };
};

export default useExerciseViewSolutionState;
