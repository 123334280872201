import {Button, Alert, Modal, Loader, Trans} from "Components";

import React from "react";
import CDN from "utilities/constants/CDN";
import usePaymentMethodSwishStatusModalState from "./usePaymentMethodSwishStatusModalState";
import {motion} from "framer-motion";
import classnames from "classnames";

const PaymentMethodSwishStatusModal = ({
  paymentRequestId,
  modal = true,
  inline = false,
  onSuccess = null,
  onCancel,
}) => {
  let Wrapper = modal ? Modal : "div";

  const {modalVisible, handleClose} = usePaymentMethodSwishStatusModalState(
    paymentRequestId,
    onSuccess
  );

  const handleCancel = () => {
    handleClose();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Wrapper
      defaultOpen={modalVisible}
      title={<Trans>Waiting for payment</Trans>}
      footer={null}
      onClose={handleClose}
      closable={false}
    >
      <div className={classnames("flex flex-col items-center gap-4 w-full")}>
        <img
          src={CDN + "/swish-logo-primary.svg"}
          className={classnames("w-16", inline && "hidden")}
          alt="swish-homescreen"
        />

        <Loader
          type="BarLoader"
          width="100%"
          height={inline ? "0.25em" : "0.5em"}
        />
        <div
          className={classnames(
            "flex gap-2",
            inline ? "flex-row items-center" : "flex-col items-center"
          )}
        >
          <Trans type={inline ? "tsm" : "tlg"}>
            Open the swish app to verify your payment.
          </Trans>

          <Button
            onClick={handleCancel}
            fluid={!inline}
            type="destructive"
            size="sm"
          >
            <Trans>Cancel</Trans>
          </Button>
        </div>
        {!inline && (
          <motion.div
            initial={{opacity: 0, display: "none"}}
            animate={{opacity: 1, display: "block"}}
            transition={{delay: 15}}
            className="w-full"
          >
            <Alert
              fluid
              orientation="horizontal"
              type="primary"
              title={<Trans>Is the page not updating after payment?</Trans>}
              primaryAction={{
                label: <Trans>Reload page</Trans>,
                onClick: window.location.reload,
              }}
            />
          </motion.div>
        )}
      </div>
    </Wrapper>
  );
};

export default PaymentMethodSwishStatusModal;
