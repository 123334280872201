import React from "react";
import {OnboardingContext} from "routes/Onboarding/Onboarding";
import useGetSchoolPrograms from "API/queries/program/useGetSchoolPrograms";
import Trans from "Components/Trans";

export const ProgramGroups = {
  civilEngineering: {
    patterns: ["civilingenjör"],
    title: <Trans>Civilingenjör</Trans>,
  },
  highSchoolEngineering: {
    patterns: ["högskoleingenjör"],
    title: <Trans>Högskoleingenjör</Trans>,
  },
  bachelor: {
    patterns: ["kandidatprogram"],
    title: <Trans>Kandidatprogram</Trans>,
  },
  master: {
    patterns: ["masterprogram", "magister"],
    title: <Trans>Masterprogram</Trans>,
  },
  teacher: {
    patterns: ["ämneslärare", "lärare", "lärarprogram", "lärarutbildning"],
    title: <Trans>Lärarprogram</Trans>,
  },
  base: {patterns: ["basår"], title: <Trans>Basår</Trans>},
  other: {patterns: [], title: <Trans>Other programs</Trans>},
};

const filterPatterns = ["senare del", "Senare del, "];

const replacePatterns = [
  /(Civil|Högskole)ingenjörs?(utbildning|program|programmet)(\si)?\s/,
  /Civilingenjör i /,
  / \(Civilingenjör\)/,
  /, civilingenjör/,
  /, högskoleingenjör/,
  /Kandidatprogram, /,
];

const useOnboardingProgram = () => {
  const {state, onNextStepClick} = React.useContext(OnboardingContext);

  const {values, setFormValue} = state;

  const [searchTerm, setSearchTerm] = React.useState("");

  const [programs, loading] = useGetSchoolPrograms(values.schoolId);

  const handleProgramSelect = programId => {
    setFormValue({programId});
  };

  const currentProgramId = values.programId;

  const filteredPrograms = React.useMemo(() => {
    if (!searchTerm) {
      return programs;
    }

    return programs.filter(program =>
      program.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [programs, searchTerm]);

  const groupedPrograms = React.useMemo(() => {
    return getProgramGroups(filteredPrograms);
  }, [programs, filteredPrograms]);

  React.useEffect(() => {
    // If there is no programs in this school, go to the next step
    if (!loading && !programs.length) {
      onNextStepClick();
    }
  }, [programs, loading]);

  return {
    programs,
    loading,
    currentProgramId,
    handleProgramSelect,
    groupedPrograms,
    searchTerm,
    setSearchTerm,
  };
};

const getProgramGroups = programs => {
  const groups = Object.keys(ProgramGroups).reduce((acc, group) => {
    acc[group] = [];
    return acc;
  }, {});

  programs.forEach(program => {
    // If the program name contains any of the filter patterns, skip it
    if (filterPatterns.some(pattern => program.name.includes(pattern))) {
      return;
    }

    // Find the group that the program belongs to
    const selectedGroup = Object.keys(ProgramGroups).find(group => {
      // Get all the patterns of the group
      const patterns = ProgramGroups[group].patterns;
      // Return true if the program name contains any of the patterns
      return patterns.some(pattern =>
        program.name.toLowerCase().includes(pattern)
      );
    });

    const renamedProgram = {
      ...program,
      name: replacePatterns.reduce((acc, pattern) => {
        return acc.replace(pattern, "");
      }, program.name),
    };

    if (selectedGroup) {
      groups[selectedGroup].push(renamedProgram);
    } else {
      groups.other.push(renamedProgram);
    }
  });

  // Remove empty groups
  Object.keys(groups).forEach(group => {
    if (groups[group].length === 0) {
      delete groups[group];
    }
  });

  // Sort all the programs in each group
  Object.keys(groups).forEach(group => {
    groups[group].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  });

  return groups;
};

export default useOnboardingProgram;
