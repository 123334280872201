import React from "react";
import {useGroupContext} from "../../../Providers/GroupProvider/GroupProvider";
import {Typography} from "../../../Components";
import GroupInfoLinks from "Widgets/Group/GroupInfo/GroupInfoLinks";

const GroupHomeHeader = () => {
  const {imageUrl, name} = useGroupContext();

  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-6 md:items-center">
      <div className="w-fit p-2 bg-gray-100 rounded-full shrink-0">
        <img className="h-24 rounded-full" src={imageUrl} />
      </div>
      <div>
        <Typography type="dsm md:dmd" color="text-gray-500" weight="sb">
          {name}
        </Typography>
        <GroupInfoLinks />
      </div>
    </div>
  );
};

export default GroupHomeHeader;
