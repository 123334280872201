import React from "react";
import {Math, Typography} from "Components";

const CourseChatGptResultsMessageContent = ({content, inverted}) => {
  let lines = getLinesWithMath(content);

  if (!lines.length) {
    return null;
  }

  return lines.map((line, index) => {
    return (
      <Typography key={index} color={inverted ? "text-white" : "text-gray-900"}>
        <Math contentKey={line.length} text={line} />
      </Typography>
    );
  });
};

export const getLinesWithMath = content => {
  let cleanContent = content;

  if (!content) return [];

  // const math = content.match(/\$\$([^$]*)\$\$/gs);
  const math = content.match(/(?:\${2}|\\\[)([^$]*)(?:\${2}|\\\])/gs);

  if (math) {
    // replace math with a placeholder
    math.forEach((m, i) => {
      let cleanMath = m;
      cleanMath = cleanMath.replace(/\n/g, " ");
      cleanMath = cleanMath.replace(/\$\$/g, "");
      cleanMath = `$$\\displaylines{${cleanMath}}$$`;
      cleanContent = cleanContent.replace(`${m}`, cleanMath);
    }, math);
  }

  return cleanContent.split("\n");
};

export default CourseChatGptResultsMessageContent;
