import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {GET_CURRENT_USER_MEMBERSHIP} from "API/queries/user/useGetCurrentUserSubscription";

const REDEEM_CLAIMED_SUBSCRIPTION_TRIAL_COUPON = gql`
  mutation redeemSubscriptionTrialCoupon(
    $couponId: ID
    $name: String
    $collectionMethod: String
  ) {
    redeemSubscriptionTrialCoupon(
      id: $couponId
      name: $name
      collectionMethod: $collectionMethod
    ) {
      ok
      subscriptionTrialCoupon {
        id
        redeemed
        trialDays
      }
      error {
        message
      }
    }
  }
`;

const useRedeemSubscriptionTrialCoupon = (onCompleted, refetchQueries = []) => {
  const [redeemSubscriptionTrialCoupon, {data, loading}] = useMutation(
    REDEEM_CLAIMED_SUBSCRIPTION_TRIAL_COUPON,
    {
      onCompleted,
      refetchQueries: [
        {
          query: GET_CURRENT_USER_MEMBERSHIP,
          variables: {
            includeInvoices: true,
            includeSubscriptionPlan: true,
            includeStripeData: true,
          },
        },
        ...refetchQueries,
      ],
    }
  );

  return {
    redeemSubscriptionTrialCoupon,
    data,
    loading,
  };
};

export default useRedeemSubscriptionTrialCoupon;
