import React from "react";
import {ExamUploadContext} from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContext";
import {documentGetType} from "API/lambdas";

const useExamUploadDocumentsRowConflictResolver = resources => {
  const {updateResource} = React.useContext(ExamUploadContext);
  const [conflictsResolved, setConflictsResolved] = React.useState(false);

  const resolveConflict = (a, b) =>
    new Promise(async (resolve, reject) => {
      const [newTypeA, newTypeB] = await documentGetType(a.url, b.url);

      if (newTypeA === newTypeB) {
        reject("Could not resolve conflict");
      }

      // Update each resource with the type that was returned from the lambda, if it is different from the current type

      if (newTypeA !== a.type) {
        updateResource({...a, type: newTypeA, saved: false});
      }

      if (newTypeB !== b.type) {
        updateResource({...b, type: newTypeB, saved: false});
      }

      setConflictsResolved(true);
      resolve();
    });

  // Only run once
  React.useEffect(() => {
    for (let i = 0; i < resources.length; i++) {
      const resource = resources[i];
      const conflictingResource = resources.find(
        other => other.type === resource.type && other !== resource
      );

      if (conflictingResource) {
        try {
          resolveConflict(resource, conflictingResource);
          break; // After resolving a conflict, we need to re-run the loop to check if there are any more conflicts
        } catch (e) {
          console.log(e);
        }
      } else {
        setConflictsResolved(true);
      }
    }

    return () => {};
  }, []);

  return conflictsResolved;
};

export default useExamUploadDocumentsRowConflictResolver;
