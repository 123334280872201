import React from "react";
import {motion} from "framer-motion";
import {Trans} from "Components";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import "./GenericToggle.scss";

const GenericToggle = ({title, index, children, subtitle}) => {
  const {t: trans} = useTranslation();
  const [active, setActive] = React.useState(index === 0); // first one will be open by default
  const toggleActive = () => setActive(!active);

  const childrenCount = children.length;

  return (
    <motion.div className="toggle" key={module.id}>
      <motion.div className="toggle-title" layout onClick={toggleActive}>
        <div>
          <span className={classNames("circle", {active})} />
          <h2>{title}</h2>
        </div>

        {active && <Trans>Show less</Trans>}

        {!active && childrenCount > 0 && <Trans>Show more</Trans>}
      </motion.div>

      <motion.div className="toggle-subtitle" layout>
        {subtitle}
      </motion.div>

      {/* {<CourseLandingTopicDetails
        {...{
          tags: module.tags,
          active,
          free: index === 0,
        }}
      />} */}

      {/* TODO: Fix the animation for children coming in */}

      {active && (
        <div className="toggle-children-container">
          {children.map((child, i) => (
            <motion.div
              key={`child-${i}`}
              initial={{x: 100, opacity: 0}}
              animate={{x: 0, opacity: 1}}
              transition={{delay: i * 0.1}}
              exit={{x: 10, opacity: 0}}
            >
              {child}
            </motion.div>
          ))}
        </div>
      )}
    </motion.div>
  );
};

export default GenericToggle;
