import React from "react";
import moment from "moment";
import SubscriptionStatusDescriptionCancelled from "./SubscriptionStatusDescriptionCancelled";
import findLastOpenInvoice from "utilities/findLastOpenInvoice";

const SubscriptionStatusDescriptionPastDue = ({invoices}) => {
  const lastOpenInvoice = findLastOpenInvoice(invoices);

  if (!lastOpenInvoice) return null;

  const inferredCancellationDate = moment(lastOpenInvoice.date).add(7, "days");

  return (
    <SubscriptionStatusDescriptionCancelled
      cancelAt={inferredCancellationDate}
    />
  );
};

export default SubscriptionStatusDescriptionPastDue;
