import React from "react";
import Trans from "Components/Trans";
import {Button, Icon, Popover} from "Components/_Elements/index";

const Confirm = ({
  title = <Trans>Are you sure you?</Trans>,
  description = <Trans>This action cannot be undone</Trans>,
  children,
  onConfirm,
  onCancel,
  confirmButtonLabel = <Trans>Confirm</Trans>,
  cancelButtonLabel = <Trans>Cancel</Trans>,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    setIsOpen(false);
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      content={
        <div className="p-2 rounded-xl">
          <div className="flex gap-1 items-center">
            <Icon icon="exclamation-circle" size="md" color="text-gray" />
            {React.cloneElement(title, {
              type: "tsm",
              weight: "sb",
              color: "text-gray",
              inline: true,
              className: "leading-none",
            })}
          </div>
          {description && (
            <div className="mt-1">
              {React.cloneElement(description, {
                type: "tsm",
                weight: "normal",
                color: "text-gray-light",
              })}
            </div>
          )}

          <div className="mt-4 flex  gap-2">
            <Button size="sm" type="destructive" onClick={handleClose} fluid>
              {cancelButtonLabel}
            </Button>
            <Button size="sm" type="secondary" onClick={handleConfirm} fluid>
              {confirmButtonLabel}
            </Button>
          </div>
        </div>
      }
    >
      {React.cloneElement(children, {
        onClick: () => setIsOpen(true),
      })}
    </Popover>
  );
};

export default Confirm;
