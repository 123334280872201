import React from "react";
import {Icon, Link, Trans, Typography} from "Components";
import ProgressBead from "../../../Components/ProgressBead";
import CompoundProgressBar from "../../../Components/CompoundProgressBar";
import useGetExam from "../../../API/queries/exam/useGetExam";
import useGetExercises from "../../../API/queries/exercise/useGetExercises";
import {segments} from "../../../utilities/exerciseUtilities/exerciseListOperations";
import SkeletonListWithCircle from "../../../Components/_Elements/Skeleton/SkeletonListWithCircle";

const CourseExamsListCard = ({examId, key}) => {
  const {getCourseLink} = React.useContext(window.CourseContext);
  const [exam, examLoading] = useGetExam({id: examId, includeExercises: true});

  const [exercisesData, exercisesLoading] = useGetExercises({
    ids: exam?.exercises?.map(e => e.id) ?? [],
    includeSelfEvaluation: true,
  });

  const examDate = new Date(exam?.date?.substring(0, 10));
  const examExtension = exam?.date?.slice(10).trim();

  const examDateFormatted = examDate.toLocaleDateString("sv-SE", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const {user} = React.useContext(window.UserContext);
  const seg = segments(exercisesData);
  const count = exercisesData.length;

  if (exercisesLoading || examLoading)
    return <SkeletonListWithCircle count={1} fluid={true} height="h-[63px]" />;

  const examLink =
    count > 0
      ? getCourseLink(`exam-exercises/${examId}?dir=asc&sort=number`)
      : getCourseLink(`exam/${examId}`);

  return (
    <Link to={examLink} key={examId} fluid basic>
      <div className="py-4 px-1 flex gap-4">
        <div className="w-fit">
          <ProgressBead height={34} exercises={exercisesData} user={user} />
        </div>
        <div className="overflow-hidden w-full">
          <div className="flex gap-1 w-full">
            <Typography type="tmd" className="whitespace-nowrap">
              {examDateFormatted}
            </Typography>
            {exam.author && (
              <div className="flex gap-1 overflow-hidden">
                <Icon
                  icon="fa-solid fa-circle-small"
                  size="xs"
                  color="text-gray-500"
                />
                <Typography
                  type="tmd"
                  weight="md"
                  color="text-gray-500"
                  className="truncate text-ellipsis"
                >
                  {exam.author}
                </Typography>
                {examExtension && (
                  <Typography
                    type="tmd"
                    color="text-gray-500"
                    className="truncate text-ellipsis"
                  >
                    ({examExtension})
                  </Typography>
                )}
              </div>
            )}
          </div>
          {count > 0 ? (
            <div className="w-3/4 mt-2">
              <CompoundProgressBar nExercises={count} segments={seg} />
            </div>
          ) : (
            <Trans type="txs" color="text-gray-500" className="p-0">
              This exam hasn't been processed yet
            </Trans>
          )}
        </div>
        <Icon
          icon="fa-solid fa-chevron-right"
          size="sm"
          color="text-gray-500"
        />
      </div>
    </Link>
  );
};

export default CourseExamsListCard;
