/**
 * Creates an object where the keys are the years of the
 *  courses and the values are arrays of courses for that year.
 *
 * The courses are sorted by their period they start in
 * @param courses
 * @returns {{
 *     [year: number]: Course[],
 * }}
 */
const groupCoursesByYear = courses => {
  const coursesByYear = {};

  courses.forEach(course => {
    const year = course.year;
    if (!coursesByYear[year]) coursesByYear[year] = [];

    coursesByYear[year].push(course);
  });

  // Sort by period
  Object.keys(coursesByYear).forEach(year =>
    coursesByYear[year].sort((a, b) => a.period - b.period)
  );

  return coursesByYear;
};

export default groupCoursesByYear;
