import React from "react";
import SkeletonList from "Components/_Elements/Skeleton/SkeletonList";
import {Icon, SchoolLogo, Trans, Typography} from "Components";
import useGetSchools from "API/queries/school/useGetSchoolsWithCourses";
import classnames from "classnames";
import {AnimateSharedLayout, motion} from "framer-motion";

const FindCoursesSchoolPicker = ({selectedSchoolId, setSelectedSchoolId}) => {
  const [schools, loading] = useGetSchools();

  const sortedSchools = React.useMemo(() => {
    if (!schools || loading) return [];

    return schools.sort((a, b) => {
      // Sort by selected school first
      if (a.id === selectedSchoolId) return -1;
      if (b.id === selectedSchoolId) return 1;
      // Sort by school name
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
    });
  }, [schools, loading, setSelectedSchoolId]);

  return (
    <div className="p-4 bg-slate-100 rounded-lg">
      <Trans type="tlg" weight="sb" color="slate-900" className="mb-4">
        Choose a school
      </Trans>
      <SkeletonList loading={loading} active>
        <AnimateSharedLayout>
          {sortedSchools.map(school => {
            const selected = selectedSchoolId === school.id;
            const faded = selectedSchoolId && !selected;
            return (
              <motion.div
                layout
                onClick={() => setSelectedSchoolId(school.id)}
                key={school.id}
                animate={{
                  opacity: faded ? 0.75 : 1,
                }}
                transition={{
                  // layout: {
                  delay: 1,
                  duration: 0.5,
                  opacity: {
                    delay: 0,
                  },
                  //   type: "inertia",
                  // },
                }}
                className={classnames(
                  "flex items-center justify-between px-2 py-3 rounded-md mb-4 cursor-pointer border-2  rounded-lg",
                  "transition-colors duration-200 ease-in-out",
                  selected
                    ? "border-primary-400 bg-primary-50"
                    : "border-transparent hover:border-slate-300 bg-white"
                )}
              >
                <div className="flex gap-4 items-center">
                  <SchoolLogo schoolId={school.id} size="xs" />
                  <Typography
                    type="tmd"
                    weight="md"
                    color={selected ? "text-primary-700" : "text-slate-700"}
                  >
                    {school.name}
                  </Typography>
                </div>
                <Icon
                  icon={selected ? "fas fa-check-circle" : "circle"}
                  size="md"
                  color={selected ? "text-primary-600" : "text-slate-400"}
                />
              </motion.div>
            );
          })}
        </AnimateSharedLayout>
      </SkeletonList>
    </div>
  );
};

export default FindCoursesSchoolPicker;
