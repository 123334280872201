import React from "react";
import {Alert, Section, Trans} from "Components";
import useGetGroupMemberInvites from "API/queries/groups/useGetGroupMemberInvites";
import GroupInvitesUser from "routes/Group/GroupInvites/GroupInvitesUser";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import GroupInvitesAddMembers from "routes/Group/GroupInvites/GroupInvitesAddMembers/GroupInvitesAddMembers";

const GroupInvites = () => {
  const {groupId} = useGroupContext();

  const [invites, loading] = useGetGroupMemberInvites({id: groupId});

  if (loading) {
    return null;
  }

  return (
    <Section
      title={<Trans>Invited members</Trans>}
      subTitle={
        <Trans>
          Here you can see all invited members and the status of their
          invitation.
        </Trans>
      }
      action={<GroupInvitesAddMembers />}
    >
      {invites.length === 0 && <Alert title={<Trans>No invites yet</Trans>} />}
      {invites.map(invite => (
        <GroupInvitesUser {...invite} />
      ))}
    </Section>
  );
};

export default GroupInvites;
