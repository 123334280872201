import React from "react";
import {SubSection, Trans} from "Components";
import SubscriptionStatus from "Components/SubscriptionStatus/SubscriptionStatus";
import useGetCurrentUserSubscription from "API/queries/user/useGetCurrentUserSubscription";

const AccountSubscriptionPastSubscriptions = () => {
  const {
    subscription: currentSubscription,
    loading,
    subscriptionActive,
    allSubscriptions,
  } = useGetCurrentUserSubscription(true, true, true);

  // If the currentSubscription is active, we don't show it in the list of past subscriptions

  let subscriptions = allSubscriptions;

  if (subscriptionActive) {
    subscriptions = allSubscriptions.filter(
      subscription => subscription.id !== currentSubscription.id
    );
  }

  if (loading || subscriptions.length === 0) return null;

  return (
    <SubSection title={<Trans>Previous subscriptions</Trans>}>
      {subscriptions.map(subscription => {
        return (
          <div key={subscription.id} className="mb-2">
            <SubscriptionStatus {...subscription} />
          </div>
        );
      })}
    </SubSection>
  );
};

export default AccountSubscriptionPastSubscriptions;
