import React from "react";
import {CourseContext} from "../../../routes/Course/CourseContextProvider";
import useGetCourseExercises from "../../../API/queries/exercise/useGetCourseExercises";
import useGetExercises from "../../../API/queries/exercise/useGetExercises";
import {scoreToDifficultyIndex} from "../../../utilities/exerciseUtilities/formatScore";
import useCourseExercisesData from "../../../routes/Course/CourseExercises/useCourseExercisesData";

const useCoursePopularExercisesFiltersState = () => {
  const {courseId} = React.useContext(CourseContext);
  const {sortingCriteriaOptions} = useCourseExercisesData();

  const [selectedDifficulty, setSelectedDifficulty] = React.useState(1);
  const [sortingCriteria, setSortingCriteria] = React.useState("popularity");
  let difficultyIndex = scoreToDifficultyIndex(selectedDifficulty);

  const [exercises, exercisesLoading] = useGetCourseExercises({
    courseId,
    includeStats: true,
    includeSource: true,
    exerciseFilters: {
      difficultyScores: [difficultyIndex],
    },
  });

  const {label, sortFunction, value} = sortingCriteriaOptions.find(
    criteria => criteria.value === sortingCriteria
  );

  const topExercises = React.useMemo(() => {
    return sortFunction(exercises).slice(0, 10);
  }, [sortingCriteria, exercisesLoading]);

  const [topExercisesData, topExercisesDataLoading] = useGetExercises({
    courseId,
    includeStats: true,
    includeTags: true,
    ids: topExercises.map(exercise => exercise.id),
    includeSource: true,
  });

  return {
    selectedDifficulty,
    setSelectedDifficulty,
    sortingCriteria,
    setSortingCriteria,
    sortingCriteriaOptions: sortingCriteriaOptions.filter(
      criteria => criteria.value !== "all"
    ),
    label,
    value,
    topExercisesData,
    topExercisesDataLoading,
  };
};

export default useCoursePopularExercisesFiltersState;
