import React from "react";
import CourseReviewsSummary from "./CourseReviewsSummary/CourseReviewsSummary";
import "./CourseLandingTitleBanner.scss";
import CourseStats from "./CourseStats/CourseStats";
import { ErrorBoundary } from "Components";
import CourseLandingActivateBtn from "routes/Course/CourseLanding/CourseLandingActivateBtn/CourseLandingActivateBtn";

const CourseLandingTitleBanner = () => {
  const { course, navigationState, productPrice } = React.useContext(
    window.CourseLandingContext
  );

  const { user } = React.useContext(window.UserContext);

  const { name, school, id } = course;

  const handleRatingClick = () =>
    navigationState.allReviewsRef.current.scrollIntoView();

  const activateCourse =
    productPrice === 0 || user?.premium || user?.subscriber;

  return (
    <div className="course-landing-title-banner">
      <div>
        <div className="course-landing-title-banner-name">
          <h1>{name}</h1>
        </div>
        <div className="course-landing-title-banner-stats">
          <ErrorBoundary>
            <CourseReviewsSummary
              handleRatingClick={handleRatingClick}
              school={school.name}
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <CourseStats />
          </ErrorBoundary>
        </div>

        {activateCourse && <CourseLandingActivateBtn courseId={id} />}
      </div>
    </div>
  );
};

export default CourseLandingTitleBanner;
