import React from "react";
import { Icon, Typography } from "Components";
import { motion } from "framer-motion";

const Summer2022CampaignHeroSection = ({ image }) => {
  return (
    <div
      className="w-screen h-[95vh] bg-cover bg-center md:bg-bottom relative"
      style={{
        backgroundImage: `url(${image.url})`,
      }}
    >
      <div className="w-full p-4">
        <img
          src="https://d1mgntrf3vaj6d.cloudfront.net/images/KOLLIN+logo.svg"
          alt="logo"
          className="h-7 w-auto brightness-[200]"
        />
      </div>

      <div dir="rtl" className="mt-10 mr-4">
        <Typography type="dmd" weight="sb" color="text-white" inline>
          Plugga{" "}
        </Typography>
        <div className="relative inline">
          <Typography
            type="dmd"
            weight="sb"
            color="text-white"
            inline
            className="relative z-20"
          >
            stressfritt
          </Typography>
          <div className="absolute h-2 inset-x-0 bottom-0 bg-success-400 z-10" />
        </div>
        <Typography type="dmd" weight="sb" color="text-white">
          med Kollin
        </Typography>
      </div>

      <div
        className="absolute bottom-0 inset-x-0 flex flex-col gap-2 items-center pb-4  bg-gradient-to-b from-transparent to-primary-900"
        // Scroll down on click
        onClick={() => {
          window.scrollTo({
            top: window.innerHeight,
            behavior: "smooth",
          });
        }}
      >
        <Typography type="tlg" weight="sb" color="text-white">
          Läs mer
        </Typography>
        <motion.div
          animate={{ y: [-10, 10], opacity: [0, 1, 0] }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
          }}
        >
          <Icon icon="chevron-down" color="text-white" />
        </motion.div>
      </div>
    </div>
  );
};

export default Summer2022CampaignHeroSection;
