import React from "react";
import PropTypes from "prop-types";
import {getColors, replaceColor} from "lottie-colorify";
import useLottie from "Components/_Elements/Lottie/useLottie";

const LottieReact = React.lazy(() => import("lottie-react"));

const Lottie = ({
  animation,
  delay = null,
  replaceColors = [],
  debug = false,
  ...config
}) => {
  const lottieRef = React.useRef(null);
  const [data, loadAnimation] = useLottie({
    debug,
    replaceColors,
  });

  let autoPlay = config.autoPlay || true;

  if (delay !== null) {
    autoPlay = false;
  }

  React.useEffect(() => {
    // Check if  animation is in the dictionary
    if (!animations[animation]) {
      return;
    }
    const path = animations[animation];
    loadAnimation(path);
  }, []);

  // Effect for applying methods to the animation
  React.useEffect(() => {
    if (lottieRef.current) {
      if (config.speed) {
        lottieRef.current.setSpeed(config.speed);
      }

      if (delay !== null) {
        lottieRef.current.pause();
        let delayTimeout = setTimeout(() => {
          lottieRef.current.play();
        }, delay);

        return () => {
          clearTimeout(delayTimeout);
        };
      }
    }
  }, [lottieRef.current, Object.values(config)]);

  if (data && debug) {
    console.log(data);
  }

  return (
    <React.Suspense fallback={<></>}>
      {data !== null && (
        <LottieReact
          animationData={data}
          autoPlay={autoPlay}
          lottieRef={lottieRef}
          {...config}
        />
      )}
    </React.Suspense>
  );
};

export const animations = {
  bookmark: "./animations/bookmark.json",
  checkMark: "./animations/checkmark.json",
  checkMarkSparkles: "./animations/check-mark-sparkles.json",
  exclamationCircle: "./animations/exclamation-circle.json",
  goldCoin: "./animations/gold-coin.json",
  confetti: "./animations/confetti.json",
  flyingBirdsInDistance: "./animations/flying-birds-in-distance.json",
  spinningStars: "./animations/spinning-stars.json",
  stars: "./animations/stars.json",
  sadCat: "./animations/sad-cat.json",
  fallingConfetti: "./animations/falling-confetti.json",
  giftBox: "./animations/gift-box.json",
  tickets: "./animations/tickets.json",
  flyingPaperPlane:
    "https://lottie.host/4126cc2e-51a8-4683-9997-49ac301fda83/PDMlpsKbgf.json",
  trophyGold:
    "https://lottie.host/899f1b45-0a7e-4e14-a42e-c6ce88ec28df/wWo0Jj8lye.json",
  trophySilver:
    "https://lottie.host/50c4054e-62bf-4420-a466-d34540ceb866/Y5yd3AdZlo.json",
  trophyBronze:
    "https://lottie.host/defe8651-7fa8-4dd4-91e6-1c24a803c399/umrI1DIcrB.json",
  orb:
    "https://lottie.host/493ae82d-27a5-40f4-94ad-43dd9d8eda62/MohgyAKqfR.json",
  ballSteps: "./animations/ball-steps.json",
  machineWorkingPDFs:
    "https://lottie.host/7b8c06d5-709f-4d5f-a7fb-41b79553be3d/J9TlGDTdT3.json",
  snowflakes: "./animations/snowflakes.json",
};

Lottie.propTypes = {
  animation: PropTypes.oneOf([...Object.keys(animations)]),
};
export default Lottie;
