import React from "react";
import OTP from "Widgets/User/OTP/OTP";
import {Trans} from "Components";
import ResetPassword from "routes/Auth/ForgotPassword/ResetPassword/ResetPassword";

const AuthRequiredActionPasswordReset = ({
  action,
  email,
  transparentInput,
  actionOTPProps,
  handleActionCompleted,
}) => {
  const [passwordResetToken, setPasswordResetToken] = React.useState(null);

  if (passwordResetToken) {
    return (
      <ResetPassword
        token={passwordResetToken}
        onComplete={handleActionCompleted}
      />
    );
  }

  return (
    <div>
      <OTP
        action={action}
        initialEmail={email}
        title={
          <Trans type="dsm" weight="bold" className="text-gray-900">
            You need to reset your password
          </Trans>
        }
        subTitle={
          <Trans email={{email}}>
            We will send you a 6-digit code to {{email}}. Enter the code to
            continue.
          </Trans>
        }
        autoSubmit={false}
        transparent={transparentInput}
        onVerificationComplete={response => {
          // Set the password reset token
          setPasswordResetToken(response);
        }}
        {...actionOTPProps}
      />
    </div>
  );
};

export default AuthRequiredActionPasswordReset;
