import React from "react";
import {
  Input,
  ProgramPicker,
  SchoolPicker,
  SubSection,
  Trans,
} from "Components";
import {useTranslation} from "react-i18next";

const AccountPersonalInformationEdit = ({
  values,
  onSave,
  handleChange,
  setFormValue,
  loading,
}) => {
  const {t: trans} = useTranslation();

  return (
    <>
      <SubSection>
        <Trans>Name</Trans>
        <div className="flex gap-2">
          <Input
            transparent={false}
            value={values.firstName}
            onChange={handleChange}
            placeholder={trans("First name")}
            name="firstName"
            loading={loading}
          />
          <Input
            transparent={false}
            value={values.lastName}
            onChange={handleChange}
            placeholder={trans("Last name")}
            name="lastName"
            loading={loading}
          />
        </div>
      </SubSection>
      <SubSection>
        <Trans>Email</Trans>
        <div>
          <Input
            icon="envelope"
            transparent={false}
            value={values.email}
            disabled
            name="email"
            hint={
              <Trans>
                Please contact us if you would like to change your email
              </Trans>
            }
          />
        </div>
      </SubSection>

      <SubSection>
        <Trans>School</Trans>
        <div>
          <SchoolPicker
            onSelect={schoolId =>
              setFormValue({
                schoolId,
              })
            }
            selectedSchoolId={values.schoolId}
          />
        </div>
      </SubSection>

      <SubSection>
        <Trans>Program</Trans>
        <div>
          {values.schoolId ? (
            <ProgramPicker
              schoolId={values.schoolId}
              onSelect={programId =>
                setFormValue({
                  programId,
                })
              }
              allowNoProgram
              selectedProgramId={values.programId}
            />
          ) : (
            <i>
              <Trans>Pick a school first</Trans>
            </i>
          )}
        </div>
      </SubSection>

      <SubSection>
        <Trans>Enrollment year</Trans>
        <div>
          <Input
            transparent={false}
            value={values.enrollmentYear}
            onChange={handleChange}
            name="enrollmentYear"
          />
        </div>
      </SubSection>
    </>
  );
};

export default AccountPersonalInformationEdit;
