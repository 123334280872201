import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_CURRENT_USER_PAYMENT_METHODS = gql`
  query getCurrentUserPaymentMethods {
    currentUser {
      id
      paymentMethods {
        id
        isDefaultPaymentMethod
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
    }
  }
`;

const useGetCurrentUserPaymentMethods = () => {
  let paymentMethods = [];

  const { loading, error, data } = useQuery(GET_CURRENT_USER_PAYMENT_METHODS);

  if (error) {
    console.log(error);
  }

  if (data && data.currentUser) {
    paymentMethods = data.currentUser.paymentMethods;
  }

  return [paymentMethods, loading];
};

export default useGetCurrentUserPaymentMethods;
