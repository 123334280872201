import moment from "moment";

const formatExamDate = date => {
  try {
    const d = date?.slice(0, 10);
    const momentDate = moment(d); // The date is in the format "2019-01-01 other info"
    const year = momentDate.year().toString();
    const month = momentDate.format("MMMM").toString();
    const day = momentDate.format("Do").toString();
    return `${day} ${month} ${year}`;
  } catch (e) {
    console.error(e);
    return date;
  }
};

export default formatExamDate;
