import React from "react";
import useGetCurrentUserActiveCourses from "API/queries/course/useGetCurrentUserActiveCourses";

const useMyCourses = () => {
  const [
    activatedCourses,
    activatedCoursesLoading,
  ] = useGetCurrentUserActiveCourses();

  const [activeCourses, archivedCourses] = React.useMemo(
    () => groupActiveAndArchivedCourses(activatedCourses),
    [activatedCourses]
  );

  const activeCoursesCount = React.useMemo(() => {
    return activeCourses.length;
  }, [activeCourses]);

  return {
    activeCourses,
    archivedCourses,
    activatedCoursesLoading,
    activeCoursesCount,
  };
};

export const groupActiveAndArchivedCourses = courses => {
  const activeCourses = [];
  const archivedCourses = [];

  courses.forEach(course => {
    if (course.currentUser.archived) {
      archivedCourses.push(course);
    } else {
      activeCourses.push(course);
    }
  });

  return [activeCourses, archivedCourses];
};

export default useMyCourses;
