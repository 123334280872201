import React from "react";
import moment from "moment";
import "moment/locale/sv";
import { useTranslation } from "react-i18next";

const relativeTime = (dateTime, lang) => {
  moment.locale(lang);
  return moment(dateTime).fromNow();
};

export const useLocalRelativeTime = dateTime => {
  const { i18n } = useTranslation();
  return React.useMemo(() => {
    const currentLanguage = i18n.language.split("-")[0];

    return moment(dateTime)
      .locale(currentLanguage)
      .fromNow();
  }, [dateTime]);
};

export default relativeTime;
