import React from "react";
import {OnboardingContext} from "routes/Onboarding/Onboarding";
import {Avatar, Button, Input, Trans} from "Components";
import AccountPersonalInformationProfilePicture, {
  AvatarFileUpload,
} from "routes/Account/AccountPersonalInformation/AccountPersonalInformationProfilePicture";
import {useTranslation} from "react-i18next";

const OnboardingPersonalInfo = ({isLastStep}) => {
  const {state, user} = React.useContext(OnboardingContext);
  const {t: trans} = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <Input
        name="firstName"
        label={trans("First name")}
        onChange={state.handleChange}
        value={state.values.firstName}
      />

      <Input
        name="lastName"
        label={trans("Last name")}
        onChange={state.handleChange}
        value={state.values.lastName}
      />

      <div>
        <Trans color="text-gray-700" type="tsm" weight="md">
          Profile picture
        </Trans>
        <div className="mt-1.5">
          <AvatarFileUpload
            showSuccessMessage={false}
            icon={<Avatar user={user} alt="profile picture" size="2xl" />}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingPersonalInfo;
