import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import {CourseContext} from "routes/Course/CourseContextProvider";

const CREATE_CHAPTER = gql`
  mutation createChapter(
    $title: String!
    $name: String!
    $chapter_type: String!
    $locked: Boolean!
    $courseId: ID
  ) {
    createChapter(
      title: $title
      name: $name
      chapter_type: $chapter_type
      locked: $locked
      courseId: $courseId
    ) {
      ok
      errors {
        path
        message
      }
      chapter {
        id
        chapter_type
      }
    }
  }
`;

/**
 * Course id is automatically inserted based on context.
 * Send an object with the following keys as argument:
 *  title, name, chapter_type, locked
 */
const useCreateChapterMutation = ({
  title,
  name,
  chapter_type,
  locked,
  courseId,
}) => {
  let chapter;

  const [createChapter, {data, loading}] = useMutation(CREATE_CHAPTER, {
    variables: {
      title,
      name,
      chapter_type,
      locked,
      courseId,
    },
  });

  if (data) {
    if (data.createChapter.ok) {
      chapter = data.createChapter.chapter;
    } else {
      alert(data.createChapter.errors);
    }
  }

  return {createChapter, loading, chapter};
};

export default useCreateChapterMutation;
