import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";

const SIGNUP_MUTATION = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $couponId: ID
  ) {
    createUser(
      first_name: $firstName
      last_name: $lastName
      password_confirmation: $password
      email: $email
      password: $password
      coupon_id: $couponId
    ) {
      ok
      token
      refreshToken
      errors {
        path
        message
      }
    }
  }
`;

const useCreateUser = onCompleted => {
  // Graphql mutation
  const [createUser, {data, error, loading}] = useMutation(SIGNUP_MUTATION, {
    onCompleted,
  });

  return {
    createUser,

    loading,
    data,
  };
};

export default useCreateUser;
