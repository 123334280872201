import React from "react";

import {Typography} from "Components";
import {useTranslation} from "react-i18next";
import contributionDescriptions from "Widgets/Course/CourseContributions/contributionDescriptions";

export const CourseContributionsCredits = ({objectKeys}) => {
  const {t: trans} = useTranslation();
  return Object.keys(objectKeys).map((key, index) => {
    const count = objectKeys[key].length;
    const description = contributionDescriptions[key];
    if (!description) return null;

    return (
      <div className="flex gap-2 items-center">
        {description.credits > 0 && (
          <Typography color="text-gray-500" type="tsm">
            +{description.credits * count} {trans("credits")}
          </Typography>
        )}
      </div>
    );
  });
};

export default CourseContributionsCredits;
