import React from "react";
import { Button, message, Trans } from "Components";
import useActivateUserCourse from "API/mutations/user/useActivateUserCourse";
import { useTranslation } from "react-i18next";
import { UserContext } from "UserContextProvider";
import useEventTracking from "utilities/hooks/useEventTracking";
import { useHistory, useLocation } from "react-router-dom";

const CourseLandingActivateBtn = ({ courseId }) => {
  const { t: trans } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { user } = React.useContext(UserContext);

  const courseActiveForCompletion = data => {
    if (data && data.activateCourseForCurrentUser) {
      message.success(trans("The course has been successfully activated"));
    } else {
      message.error(trans("Something went wrong"));
    }
  };

  const {
    activateCourseForCurrentUser: activateCourse,
  } = useActivateUserCourse(courseActiveForCompletion, courseId);

  const currentPath = window.location.pathname;
  const { logCourseEvent } = useEventTracking();

  const handleClick = () => {
    logCourseEvent("activate_course_clicked", {
      screen_name: currentPath,
      source: "Course Title Banner",
      logged_in: !!user,
    });

    if (!user) {
      history.push("/login", { from: location.pathname });
    } else {
      activateCourse({
        variables: { courseId },
      });
    }
  };

  return (
    <Button type="primary" size="sm" onClick={handleClick} disabled={!user?.id}>
      <Trans>Activate course</Trans>
    </Button>
  );
};

export default CourseLandingActivateBtn;
