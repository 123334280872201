import React from "react";

const GPT_PRICING = {
  PROMPT: {
    // OpenAI charges 33 cents per 1000 tokens
    price: 10, // Cents
    minTokens: 150, // Charge when the content exceeds the minTokens
  },

  COMPLETION: {
    price: 10, // Cents
    minTokens: 100, // Charge when the content exceeds the minTokens
    minMessages: 1, // Only charge for messages after the first
  },
};

const useGPTEstimateCost = ({
  prompt,
  metadata,
  settings,
  credits,
  messages = [],
}) => {
  /**
   * A helpful rule of thumb is that one token generally corresponds to ~4 characters of text for common English text. This translates to roughly ¾ of a word (so 100 tokens ~= 75 words).
   */

  const selectedInput = [prompt];

  if (settings.includeExerciseText) {
    selectedInput.push(metadata.exerciseText);
  }

  if (settings.includeSolutionText) {
    selectedInput.push(metadata.solutionText);
  }

  const finalPrompt = selectedInput.join("\n");
  const finalPromptLength = finalPrompt.length;
  const promptTokensCount = Math.round(finalPromptLength / 4);

  const promptCost =
    Math.floor(promptTokensCount / GPT_PRICING.PROMPT.minTokens) *
    GPT_PRICING.PROMPT.price;

  const completionTokensCount = settings.maxTokens;

  const completionCost =
    Math.floor(completionTokensCount / GPT_PRICING.COMPLETION.minTokens) *
    GPT_PRICING.COMPLETION.price;

  const previousMessagesTokenCount = Math.round(
    messages.reduce((acc, message) => acc + message.content.length, 0) / 4
  );

  const previousMessagesCost =
    Math.floor(previousMessagesTokenCount / GPT_PRICING.PROMPT.minTokens) *
    GPT_PRICING.PROMPT.price;

  const estimatedCost = promptCost + completionCost + previousMessagesCost;

  const hasEnoughCredits = credits >= estimatedCost;

  return {
    estimatedCost,
    hasEnoughCredits,
  };
};

export default useGPTEstimateCost;
