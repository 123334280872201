import {Section, SubSection, Trans} from "Components";
import React from "react";
import "routes/Account/AccountSubscription/AccountSubscription.scss";
import AccountSubscriptionPaymentMethods from "routes/Account/AccountSubscription/AccountSubscriptionPaymentMethods/AccountSubscriptionPaymentMethods";
import {accountSubSectionTitleProps} from "routes/Account/Account";
import AccountSubscriptionCurrentSubscription from "./AccountSubscriptionCurrentSubscription/AccountSubscriptionCurrentSubscription";
import AccountSubscriptionPastSubscriptions from "routes/Account/AccountSubscription/AccountSubscriptionPastSubscriptions";
import AccountSubscriptionInvoices from "routes/Account/AccountSubscription/AccountSubscriptionInvoices/AccountSubscriptionInvoices";

export const AccountSubscriptionContext = React.createContext(null);

const AccountSubscription = () => {
  return (
    <Section
      title={<Trans>Subscription</Trans>}
      subTitle={<Trans>Manage your subscription and payment methods</Trans>}
    >
      <AccountSubscriptionCurrentSubscription />

      <AccountSubscriptionPastSubscriptions />
      <SubSection>
        <Trans {...accountSubSectionTitleProps}>Payment methods</Trans>

        <AccountSubscriptionPaymentMethods />
      </SubSection>
      <AccountSubscriptionInvoices />
    </Section>
  );
};

export default AccountSubscription;
