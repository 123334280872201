import React from "react";
import {useLocalDate} from "utilities/dates";
import {Trans} from "Components";
import {SubscriptionStatusDescriptionProps} from "./SubscriptionStatusDescription";

const SubscriptionStatusDescriptionTrialing = ({trialEnd}) => {
  // Date of when the trial will end in the future
  const trialEndDate = useLocalDate(trialEnd, "Do MMMM YYYY");

  return (
    <Trans {...SubscriptionStatusDescriptionProps} trialEndDate={trialEndDate}>
      Next invoice on {{trialEndDate}}
    </Trans>
  );
};

export default SubscriptionStatusDescriptionTrialing;
