import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import {UserContext} from "UserContextProvider";
import "Components/Navbars/UserMenu/UserMenu.scss";
import {
  Avatar,
  Button,
  ErrorBoundary,
  Link,
  Logo,
  Trans,
} from "Components/index";
import classnames from "classnames";
import useGetCurrentUserSubscription from "API/queries/user/useGetCurrentUserSubscription";
import UserMenuCredits from "Components/Navbars/UserMenu/UserMenuCredits";
import NotificationsDropDown from "Components/Notifications/NotificationsDropDown/NotificationsDropDown";
import UserMenuDesktopGlobal from "Components/Navbars/UserMenu/UserMenuDesktop/UserMenuDesktopGlobal";

const UserMenuDesktop = ({
  inCourse = false,
  transparent = false,
  showKollinGoldButton = true,
  showCredits = true,
  showNotifications = true,
  inverted = false,
  logoComponentProps = {},
  ...props
}) => {
  const location = useLocation();
  const history = useHistory();

  const {user} = React.useContext(UserContext);

  const current_path = location.pathname;
  if (!user) return null;

  const splittedPathname = current_path.split("/");

  // Hide during test view for full screen appearance
  if (splittedPathname.reverse()[1] === "testview") return null;

  return (
    <div
      className={classnames(
        "flex justify-between items-center",
        inCourse ? "py-2" : "py-4",
        inCourse ? "pl-4 pr-8" : "px-4  lg:px-20",
        inCourse || transparent
          ? "bg-transparent"
          : "bg-gray-25 dark:bg-gray-800 border-b-2 border-color",
        inverted && "dark"
      )}
      id="user-menu"
      key="1"
    >
      {inCourse ? (
        <div className="ml-2 mt-2">
          <Logo
            type={inverted ? "white" : "primary"}
            showText={false}
            {...logoComponentProps}
          />
        </div>
      ) : (
        <UserMenuDesktopGlobal
          inverted={inverted}
          logoComponentProps={logoComponentProps}
        />
      )}

      <div id="user-menu-right" className="flex items-center gap-4">
        {inCourse && (
          <Link
            to="/home"
            inline={true}
            onClick={() =>
              window.analytics.track("user_menu_dashboard_clicked", {
                screen_name: window.location.pathname,
              })
            }
          >
            <Trans>Dashboard</Trans>
          </Link>
        )}
        {showKollinGoldButton && (
          <div className="hidden sm:block">
            <KollinGoldButton />
          </div>
        )}
        {showCredits && (
          <div className="hidden sm:block">
            <UserMenuCredits />
          </div>
        )}
        {showNotifications && (
          <ErrorBoundary hideOnError={true}>
            <NotificationsDropDown />
          </ErrorBoundary>
        )}
        <Avatar
          user={user}
          onClick={() => history.push("/account/profile")}
          size={inCourse ? "sm" : "md"}
        />
      </div>
    </div>
  );
};

const KollinGoldButton = ({}) => {
  const history = useHistory();
  const {
    subscription,
    loading: subscriptionLoading,
  } = useGetCurrentUserSubscription();

  if (subscriptionLoading) {
    return null;
  }

  if (subscription) {
    const {status} = subscription;
    if (status == "active" || status == "trialing" || status == "past_due")
      return null;
  }

  let text = <Trans>Activate Gold</Trans>;

  let onClick = () => history.push("/trial");

  if (!subscription) {
    text = <Trans type="tsm">Try Kollin Gold</Trans>;
  }

  return (
    <Button type="primary" size="sm" onClick={onClick}>
      {text}
    </Button>
  );
};
export default UserMenuDesktop;
