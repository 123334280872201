import React from "react";
import {useParams} from "react-router-dom";
import useGetChapter from "API/queries/chapter/useGetChapter";
import {Icon, Link, Typography, Trans} from "Components";
import {capitalizeFirstLetter} from "utilities/text";

const BreadCrumbChapter = ({}) => {
  const {getCourseLink} = React.useContext(window.CourseContext);

  const {
    chapterType: chapterTypeParam,
    chapterId: chapterIdParam,
  } = useParams();

  const chapterType = chapterTypeParam || "";
  const chapterId = chapterIdParam || "";

  const [chapter, loading] = useGetChapter({
    chapterId,
    chapterType,
    includeTag: true,
  });

  if (loading || !chapterId || !chapterType) return null;

  return (
    <>
      {chapter.tag && (
        <div className="flex gap-1">
          <Icon icon="chevron-right" size="md" />
          <Link
            type="txl"
            weight="md"
            className="text-gray-500"
            to={getCourseLink(`tag/${chapter.tag.id}`)}
          >
            {capitalizeFirstLetter(chapter.tag.name)}
          </Link>
        </div>
      )}
      <div className="flex gap-1">
        <Icon icon="chevron-right" size="md" />
        <Trans type="gray" weight="md" color="text-gray-500">
          {chapter.chapterType}
        </Trans>
      </div>
    </>
  );
};

export default BreadCrumbChapter;
