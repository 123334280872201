import useGetCourse from "API/queries/course/useGetCourse";
import React from "react";

import useGetLandingPageSections from "API/cms/landingPage/useGetLandingPageSections";

export const CourseLandingContext = React.createContext(null);
window.CourseLandingContext = CourseLandingContext;

const useCourseLandingContext = () => {
  const { id: courseId } = React.useContext(window.CourseContext);

  // Queries
  const [course, loading] = useGetCourse(courseId, true);

  // Contentful data
  const [
    landingPageContent,
    landingPageContentLoading,
  ] = useGetLandingPageSections("5Pul0qZ6UqPjI7zr2bBE47");



  // const productPrice = useGetDefaultProductPrice("COURSE", courseId);

  return {
    course,
    landingPageContent,
    loading: loading || landingPageContentLoading,
    navigationState: {},
  };
};

export default useCourseLandingContext;
