import React from "react";
import CourseTopicsListCard from "./CourseTopicsListCard";
import useCourseTopicsList from "./useCourseTopicsList";
import Widget from "../../../Components/Widget/Widget";
import {Trans} from "../../../Components";
import SkeletonListWithCircle from "../../../Components/_Elements/Skeleton/SkeletonListWithCircle";

const CourseTopicsList = ({size}) => {
  const {courseId} = React.useContext(window.CourseContext);
  const {sortedTopics, exercisesLoading} = useCourseTopicsList();
  const topTagIds = sortedTopics(size);
  if (!exercisesLoading && !topTagIds.length) return null;

  return (
    <Widget
      widgetName="CourseTopcisList"
      title={"Topics"}
      action={{label: <Trans>Se mer</Trans>, type: "link", to: "topics"}}
    >
      {exercisesLoading ? (
        <SkeletonListWithCircle count={5} fluid={true} height="h-[63px]" />
      ) : (
        topTagIds.map((tagId, index) => (
          <div className="w-full border-b-1 border-gray-300 last:border-b-0 w-full cursor-pointer hover:surface hover:text-gray-700">
            <CourseTopicsListCard
              tagId={tagId}
              courseId={courseId}
              key={index}
            />
          </div>
        ))
      )}
    </Widget>
  );
};

export default CourseTopicsList;
