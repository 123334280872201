import React from "react";
import {useTranslation} from "react-i18next";
import useGetExerciseBookmarked from "API/queries/bookmark/useGetExerciseBookmarked";
import useToggleBookmarkedMutation from "API/mutations/bookmark/useToggleBookmarkedMutation";
import {UserContext} from "UserContextProvider";
import {Button, message} from "Components/_Elements";
import {isMobile} from "react-device-detect";

const ExerciseViewToolbarBookmarkButton = ({exerciseId}) => {
  const {t: trans} = useTranslation();
  const {user} = React.useContext(UserContext);
  const isBookmarked = useGetExerciseBookmarked(exerciseId);
  const buttonSize = !isMobile ? "lg" : "md";

  const [mutate, mutationLoading] = useToggleBookmarkedMutation(exerciseId);

  const onClick = () => {
    const content = isBookmarked
      ? trans("Bookmarked removed")
      : trans("Bookmarked exercise");
    mutate();

    message.success(content);
  };

  if (user == null) return null;

  const hint = isBookmarked
    ? trans("Remove bookmark")
    : trans("Bookmark exercise");

  return (
    <Button
      hint={hint}
      type="link"
      size={buttonSize}
      icon={isBookmarked ? "fa-solid fa-bookmark" : "fa-regular fa-bookmark"}
      onClick={onClick}
    />
  );
};
export default ExerciseViewToolbarBookmarkButton;
