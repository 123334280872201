import React from "react";
import {Button, Modal, Trans} from "Components";
import useSubscriptionCancellationState from "./useSubscriptionCancellationState";
import {AnimateSharedLayout} from "framer-motion";
import SubscriptionCancellationForm from "routes/Account/AccountSubscription/AccountSubscriptionCurrentSubscription/AccountSubscriptionCurrentSubscriptionActions/SubscriptionCancellation/SubscriptionCancellationForm/SubscriptionCancellationForm";

const SubscriptionCancellation = ({subscriptionId, className}) => {
  const {
    modalVisible,
    setModalVisible,
    onSubmit,
    loading,
    state,
    onOptionChange,
    onExplanationChange,
  } = useSubscriptionCancellationState(subscriptionId);

  return (
    <>
      <Button
        size="sm"
        icon="times"
        iconColor="text-error-500"
        hint={<Trans>Cancel subscription</Trans>}
        onClick={() => setModalVisible(true)}
        className={className}
      ></Button>
      <Modal
        defaultOpen={modalVisible}
        showCancelButton={false}
        closable
        onClose={() => setModalVisible(false)}
        icon="face-sad-tear"
        title={<Trans>We are sad to see you go</Trans>}
        description={
          <Trans>Please select a reason for your cancellation:</Trans>
        }
        action={{
          label: <Trans>Submit</Trans>,
          loading: loading,
          disabled:
            loading ||
            state.explanation === "" ||
            state.selectedOptionName == null,
          onClick: onSubmit,
        }}
      >
        <AnimateSharedLayout layoutId="subscription-cancellation-form-options">
          <SubscriptionCancellationForm
            {...{
              state,
              onOptionChange,
              onExplanationChange,
            }}
          />
        </AnimateSharedLayout>
      </Modal>
    </>
  );
};

export default SubscriptionCancellation;
