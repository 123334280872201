import React from "react";
import {Trans, Typography} from "Components";

// Repeated for translation purposes

export const TotalExercises = ({total}) => (
  <>
    {Number(total) === 0 && ""}
    {Number(total) === 1 && (
      <div className="flex gap-1">
        <Typography>1</Typography>
        <Trans>Exercise</Trans>
      </div>
    )}
    {Number(total) > 1 && (
      <div className="flex gap-1 ml-1">
        <Typography>{total}</Typography>
        <Trans>Exercises</Trans>
      </div>
    )}
  </>
);
