import React from "react";
import StudyPlanCardPurchasedBlock from "./StudyPlanCardPurchasedBlock/StudyPlanCardPurchasedBlock";
import {Button, Link, ProgressSteps, Trans, Typography} from "Components";
import ProgressBar from "Components/_Elements/ProgressBar/ProgressBar";
import {useTranslation} from "react-i18next";
import useStudyPlanCardPurchased from "./useStudyPlanCardPurchased";
import StudyPlanCardAuthor from "Components/StudyPlanCard/StudyPlanCardAuthor/StudyPlanCardAuthor";
import "./StudyPlanCardPurchased.scss";

const StudyPlanCardPurchased = ({studyPlan}) => {
  const {t: trans} = useTranslation();
  const {id: courseId} = React.useContext(window.CourseContext);

  const {
    addCompletedBlock,
    completedBlocks,
    courseStudyPlan,
    loading,
    completedBlocksCount,
    totalBlocks,
  } = useStudyPlanCardPurchased(studyPlan);

  if (loading) return null;

  return (
    <div className="study-plan-card-purchased background">
      <div className="study-plan-card-purchased-header">
        <div className="study-plan-card-purchased-header-info">
          <Typography type="dsm">{studyPlan.name}</Typography>
          <Typography className="my-3">{studyPlan.description}</Typography>
        </div>
        <StudyPlanCardAuthor author={studyPlan.author} />
      </div>
      <div className="study-plan-card-purchased-progress">
        <ProgressBar
          progressRatio={completedBlocksCount / totalBlocks}
          style={{
            borderRadius: "3px",
            height: "12px",
            marginRight: "5px",
            color: "#29B062",
          }}
        />
        <span>
          {completedBlocksCount + "/" + totalBlocks + " " + trans("blocks")}
        </span>
      </div>

      <div className="study-plan-card-purchased-blocks">
        <ProgressSteps
          size="sm"
          numbered={true}
          // steps={courseStudyPlan?.blocksCollection?.items.map((block, i) => ({
          //   description: formatName(block.name),
          //   completed: completedBlocks.has(block.sys.id),
          // }))}
          steps={courseStudyPlan?.blocksCollection?.items.map((block, i) => (
            <StudyPlanCardPurchasedBlock
              key={i}
              index={i}
              block={block}
              addCompletedBlock={addCompletedBlock}
              studyPlanId={studyPlan.id}
            />
          ))}
        />
      </div>

      <Link
        to={{
          pathname: `/course/${courseId}/study-plan/${studyPlan.id}`,
        }}
        basic
      >
        <Button type="primary">
          {completedBlocksCount == 0 ? (
            <Trans>Get started</Trans>
          ) : (
            <Trans>Continue</Trans>
          )}
        </Button>
      </Link>
    </div>
  );
};

export default StudyPlanCardPurchased;
