import React from "react";
import "emoji-mart/css/emoji-mart.css";
import {Picker} from "emoji-mart";
import useAddReaction from "API/mutations/reaction/useAddReaction";
import {UserContext} from "UserContextProvider";
import {Button, Trans} from "Components";
import ReactionsList from "Components/Reactions/ReactionsList";

const Reactions = ({parentId, parentType, size = 16, bottom}) => {
  const {user} = React.useContext(UserContext);

  const [addReaction, addReactionLoading] = useAddReaction({
    parentId,
    parentType,
  });

  const [showPicker, setShowPicker] = React.useState(false);

  React.useEffect(() => {
    if (showPicker) {
      let element = document.getElementsByClassName("emoji-mart")[0];
      if (element) {
        let rect = element.getBoundingClientRect();

        if (rect.y < 0) {
          element.style = `transform: translateY(${-rect.y + 10}px)`;
        }
      }
    }
  }, [showPicker]);

  return (
    <div className="flex items-start gap-2 relative" style={{fontSize: size}}>
      {user && user.id && (
        <div className="relative h-full">
          <Button
            onClick={() => setShowPicker(!showPicker)}
            icon="face-smile-plus"
            size="xs"
            type="gray"
            className="rounded-full px-4"
            hint={<Trans>Add reaction</Trans>}
          />
          {showPicker && (
            <div className="absolute z-50 bottom-10 left-110">
              <Picker
                id="emoji-picker"
                onSelect={emoji => {
                  addReaction({
                    variables: {parentId, parentType, name: emoji.colons},
                  });
                  setShowPicker(false);
                }}
                title={<Trans>Pick your reaction</Trans>}
                emoji="point_up"
                set="apple"
              />
            </div>
          )}
        </div>
      )}
      <ReactionsList
        parentId={parentId}
        parentType={parentType}
        size={size}
        onClick={name =>
          addReaction({
            variables: {parentId, parentType, name},
          })
        }
      />
    </div>
  );
};

Reactions.defaultProps = {
  size: 16,
  bottom: false,
};

export default Reactions;
