import React from "react";
import { CourseContext } from "routes/Course/CourseContextProvider";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import { UserContext } from "UserContextProvider";

export const GET_EXERCISE_TAG_SUGGESTIONS = gql`
  query getExercise($exerciseId: ID!, $courseId: ID!, $loggedIn: Boolean!) {
    getExercise(id: $exerciseId) {
      id
      tag_suggestions {
        id
        name
        tally(exercise_id: $exerciseId) {
          votes
          last_voted
          is_validated
        }
        isPrimary(course_id: $courseId)
        current_user_has_voted(exercise_id: $exerciseId) @include(if: $loggedIn)
      }
    }
  }
`;

const useGetExerciseTagSuggestions = (exerciseId, filter) => {
  const { user } = React.useContext(UserContext);
  const { courseId } = React.useContext(CourseContext);

  let tagSuggestions = [];

  const { loading, data, error } = useQuery(GET_EXERCISE_TAG_SUGGESTIONS, {
    variables: { exerciseId, courseId, loggedIn: user !== null },
  });

  if (error) {
    console.log("Error in useGetExerciseTagSuggestions: ", error);
  }

  if (data) {
    let tags = data.getExercise.tag_suggestions.map(formatTagSuggestion);

    tagSuggestions = _.filter(
      tags,
      t => t.name.toLowerCase().indexOf(filter) !== -1
    );

    tagSuggestions.sort((a, b) => b.votes - a.votes);
  }

  return [tagSuggestions, loading];
};

const formatTagSuggestion = t => ({
  ...t,
  votes: t.tally.votes.toFixed(2),
  lastVoted: t.tally.last_voted,
  isValidated: t.tally.is_validated,
  isPrimary: t.isPrimary,
  hasCurrentUserVoted: t.current_user_has_voted,
});

export default useGetExerciseTagSuggestions;
