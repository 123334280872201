export default {
  samhällsbyggnadssektionen: {
    id: "1728b5f5-4277-44ab-9cc9-efc7a3376006",
    key: "samhällsbyggnadssektionen-kth",
    schoolId: 537,
  },
  test: {
    id: "d1fa61ea-5a27-4c4e-a996-7ec6e1d8d0cb",
    key: "test-kth-open",
    schoolId: 537,
    released: true,
  },
  datasektionen: {
    id: "45935475-8d87-4259-921b-722ced454641",
    key: "datasektionen-kth",
    schoolId: 537,
  },
  bergssektionen: {
    id: "b6db548a-728c-449a-ab75-d21f4dae6525",
    key: "bergssektionen-kth",
    schoolId: 537,
  },
  elektrosektionen: {
    id: "ee9863d8-38b4-44bc-a7cc-3cc62fcb30d8",
    key: "elektrosektionen-kth",
    schoolId: 537,
  },
  flyg: {
    id: "6bf81435-1629-4099-b8c7-aec716f1e2be",
    key: "flygsektionen-kth",
    schoolId: 537,
    released: true,
  },
  fysiksektionen: {
    id: "b80cdeed-dadb-486a-be0e-105b932600f9",
    key: "fysiksektionen-kth",
    schoolId: 537,
  },
  kemisektionen: {
    id: "dd9b62da-d005-4a2c-aaf6-051895fc1928",
    key: "kemisektionen-kth",
    schoolId: 537,
  },
  maskinsektionen: {
    id: "fa416779-a9db-4f92-a3d6-7b3e562e32f9",
    key: "maskinsektionen-kth",
    schoolId: 537,
  },
  cl: {
    id: "14f610b4-aaf1-4134-9713-e8fa8f520d76",
    key: "clsektionen-kth",
    schoolId: 537,
    released: true,
  },
  w: {
    id: "1bfa3627-40b4-4932-b137-3f42898357f6",
    key: "sektionen-för-energi-och-miljö-kth",
    schoolId: 537,
    released: true,
  },
  open: {
    id: "26e241c1-5ff8-4dd5-a5fd-3d409b0a1233",
    key: "opensektionen-kth",
    schoolId: 537,
    released: true,
  },
  in: {
    id: "5d0316a0-bee8-43a9-ad99-9456dbfb56df",
    key: "insektionen-kth",
    schoolId: 537,
    released: true,
  },
  indeksektionen: {
    id: "f7f9c27b-9103-434d-add3-60c41238f6f3",
    key: "sektionen-för-industriell-ekonomi-kth",
    schoolId: 537,
  },
  medteksektionen: {
    id: "0baa73a8-76c7-47e6-b36c-4927884db0c5",
    key: "sektionen-för-medicinsk-teknik-kth",
    schoolId: 537,
  },
  media: {
    id: "868f084e-09df-4092-9777-a67a4df2a016",
    key: "sektionen-för-medieteknik-kth",
    schoolId: 537, // KTH
  },
  mts: {
    id: "84791c71-1695-4855-a806-f687915c2239",
    key: "mts-liu",
    schoolId: 7,
    subGroupIds: [
      "5ca6b2a6-75f0-4f22-aaae-313f92490737",
      "6d4e744d-3f50-4208-b43e-4a693757829c",
      "65514fb3-97e4-4550-8cd5-edda41b6697f",
    ],
    released: true,
  },
  esek: {
    id: "a9c597e4-6455-4a7a-96ee-1895b19bbc15",
    key: "esek",
    schoolId: 6, // Lund
    released: true,
  },
  es: {
    id: "dbcfc2f6-ba52-4cfe-9467-82222b420c3b",
    key: "es-sektionen-public",
    schoolId: 5, // Uppsala
    released: true,
  },
  "maskin-lth": {
    id: "fb92a3f4-495e-45e4-bac6-e02e4f6da6f9",
    key: "maskinlth",
    schoolId: 6, // Lund
    released: true,
  },
  isek: {
    id: "0c6b48cf-d902-41b0-b897-2bf89f10036a",
    key: "isek-lund",
    schoolId: 6, // Lund
    released: true,
  },
  ahead:{
    id: "0ddfc182-e94c-431e-8b6b-7ad11e246ebd",
    key: "ahead",
    schoolId: 18,
    released: true,
  }
};
