import React from "react";
import useStudyPlanCardPurchasedBlock from "./useStudyPlanCardPurchasedBlock";
import ProgressStepsStep from "Components/_Elements/ProgressSteps/ProgressStepsStep";

const StudyPlanCardPurchasedBlock = ({
  block,
  index,
  addCompletedBlock,
  studyPlanId,
  orientation,
}) => {
  const {allStepsCompleted, onClick} = useStudyPlanCardPurchasedBlock(
    block,
    addCompletedBlock,
    studyPlanId
  );

  return (
    <ProgressStepsStep
      orientation={orientation}
      index={index}
      status={allStepsCompleted ? "complete" : "incomplete"}
      description={formatName(block.name)}
      onStepClick={onClick}
    />
  );
};

export default StudyPlanCardPurchasedBlock;

export const formatName = name => {
  return name.split(":").pop();
};
