export default {
  compact: {
    toolbar: {
      language: "en",
      items: ["bold", "italic", "underline", "removeFormat", "math"],
    },
    blockToolbar: [
      "bold",
      "italic",
      "underline",
      "|",
      "link",
      "|",
      "numberedList",
      "bulletedList",
      "|",
      "insertTable",
      "codeBlock",
      "mediaEmbed",
      "math",
    ],
    image: {
      toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
      upload: {
        panel: {
          items: ["uploadImage", "insertImageViaUrl"],
        },
      },
    },

    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    math: {
      engine: "mathjax", // or katex or function. E.g. (equation, element, display) => { ... }
      outputType: "span", // or span
      forceOutputType: false, // forces output to use outputType
      enablePreview: true, // Enable preview view
    },
  },
  regular: {
    toolbar: {
      language: "en",
      items: [
        "bold",
        "italic",
        "underline",
        "link",
        "fontColor",
        "highlight",
        "alignment",
        "removeFormat",
        "math",
      ],
    },
    blockToolbar: [
      "heading",
      "horizontalLine",
      "numberedList",
      "bulletedList",
      "todoList",
      "insertTable",
      // "imageUpload",
      "codeBlock",
      "mediaEmbed",
      "math",
    ],
    image: {
      toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableCellProperties",
        "tableProperties",
      ],
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h3",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h4",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h5",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h6",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
      ],
    },
    math: {
      engine: "mathjax", // or katex or function. E.g. (equation, element, display) => { ... }
      outputType: "span", // or span
      forceOutputType: false, // forces output to use outputType
      enablePreview: true, // Enable preview view
    },
  },
};
