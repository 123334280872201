import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const GET_STATS = gql`
  query getStats {
    getStats {
      schools
      students
      courses
      exercises
    }
  }
`;

const useGetAllStats = () => {
  const statsNames = {
    "schools": "Universities",
    "students": "Students",
    "courses": "Courses",
    "exercises": "Exercises"
  };
  let stats = [];
  const { data, loading, error } = useQuery( GET_STATS );
  if ( data ) {
    for ( const [ key, value ] of Object.entries( data.getStats ) ) {
      if ( statsNames[ key ] ) {
        stats.push( { name: statsNames[ key ], value: value } )
      }
    }
  }
  return [ stats, loading ];
};

export default useGetAllStats;
