const colorStyles = {
  red: ["bg-red-50", "text-red-800"],
  "#FF033E": ["bg-red-50", "text-red-800"],
  blue: ["bg-primary-50", "text-primary-800"],
  green: ["bg-green-50", "text-green-800"],
  "#CC5500": ["bg-orange-50", "text-orange-800"],
};

export const restyleChapterHTML = (htmlString, title) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, "text/html");

  const body = htmlDoc.body;

  // Check if the first element's body is the same as the title, if so, remove it
  if (body.firstElementChild.textContent === title) {
    body.firstElementChild.remove();
  }

  try {
    // For each p tag, add text-body2 class
    Array.from(body.querySelectorAll("p")).forEach(p => {
      p.classList.add("text-body1", "mt-4");
    });

    // For each h1 tag, add text-h4 class
    Array.from(body.querySelectorAll("h1")).forEach(h1 => {
      h1.classList.add("text-h5", "mt-8", "mb-4");
    });

    // Replace <strong> with span and class font-medium
    Array.from(body.querySelectorAll("strong")).forEach(strong => {
      const span = document.createElement("span");

      span.classList.add("font-bold");
      span.innerHTML = strong.innerHTML;
      strong.replaceWith(span);
    });

    // for each span tag, add overflow x scroll
    Array.from(body.querySelectorAll("span")).forEach(span => {
      if (
        span.classList.contains("math") &&
        span.classList.contains("display")
      ) {
        span.classList.add("block", "overflow-x-scroll");
      }
    });

    // Replace hr with div and class border-t-2 border-gray-200
    Array.from(body.querySelectorAll("hr")).forEach(hr => {
      const div = document.createElement("div");
      div.classList.add("border-t-2", "border-gray", "my-4");
      hr.replaceWith(div);
    });

    // for tags that have style="color: red" replace with text-red-500
    Array.from(body.querySelectorAll("[style]")).forEach(tag => {
      const style = tag.getAttribute("style");

      if (style.includes("color:")) {
        const color = style
          .split("color:")[1]
          .split(";")[0]
          .trim();

        if (!colorStyles[color]) return;

        tag.classList.add(
          ...colorStyles[color],
          "rounded-md",
          "px-1",
          "mr-2",
          "font-light"
        );
      }

      // Remove style attribute
      tag.removeAttribute("style");
    });

    // set mt-0 on first element
    body.firstElementChild.classList.add("!mt-0");
  } catch (e) {
    console.log(e);
  }

  return body.innerHTML;
};
