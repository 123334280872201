import React from "react";
import {CourseParamsContext} from "routes/Course/CourseRouter";
import exerciseAttributes from "utilities/exerciseUtilities/exerciseAttributes";
import _ from "lodash";
import exerciseListFilters from "utilities/exerciseUtilities/exerciseListFilters";

const useSortExercises = exercises => {
  const {sort = "date", dir = "asc", filter} = React.useContext(
    CourseParamsContext
  );
  const [sortedExercises, setSortedExercises] = React.useState(exercises);

  let sortAttribute = sort || "date";

  React.useEffect(() => {
    const sortFn = exerciseAttributes[sortAttribute].sortFunction;
    let copied = _.cloneDeep(exercises);

    copied.sort(sortFn);

    if (filter && filter !== "null") {
      const filterFn = exerciseListFilters[filter].filterFunction;
      copied = copied.filter(filterFn);
    }

    if (dir === "desc") {
      copied.reverse();
    }

    setSortedExercises(copied);
  }, [sort, dir, filter, exercises]);
  return sortedExercises;
};

export default useSortExercises;
