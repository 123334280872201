import React from "react";
import useGetAllSchools from "API/queries/school/useGetAllSchools";
import {
  Button,
  Loading,
  SchoolLogo,
  Select,
  Trans,
  Typography,
} from "Components";
import {OnboardingContext} from "routes/Onboarding/Onboarding";
import {formatSchoolOptions} from "utilities/formatSelectOptons";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {AnimateSharedLayout, motion} from "framer-motion";
import OnboardingOptionCard from "routes/Onboarding/OnboardingOptionCard";
import OnboardingSchoolMissing from "routes/Onboarding/OnboardingSchool/OnboardingSchoolMissing";
const OnboardingSchool = ({
  selectedSchoolId,
  onSchoolIdChange = null,
  compact = false,
}) => {
  const onboardingContext = React.useContext(OnboardingContext);

  const {values, setFormValue} = onboardingContext?.state || {};

  const [schools, loading] = useGetAllSchools();

  if (loading) return <Loading />;

  const schoolWithCourses = schools.filter(school => school.coursesCount > 0);

  const handleSelect = schoolId => {
    if (onSchoolIdChange) onSchoolIdChange(schoolId);
    else {
      setFormValue({schoolId});
    }
  };

  const currentSchoolId = selectedSchoolId || values.schoolId;

  return (
    <div className="@container w-full">
      <div
        className={classnames(
          "grid grid-cols-1 sm:grid-cols-2",
          compact ? " gap-4 mt-2" : " gap-4 mt-4"
        )}
      >
        <AnimateSharedLayout>
          {schoolWithCourses.map(school => {
            const selected = currentSchoolId === school.id;
            return (
              <OnboardingOptionCard
                onClick={() => handleSelect(school.id)}
                selected={selected}
                compact={compact}
              >
                <SchoolLogo schoolId={school.id} size={compact ? "sm" : "md"} />
                <Typography
                  type={compact ? "tmd" : "tlg"}
                  weight="md"
                  color="text-gray"
                >
                  {school.name}
                </Typography>
              </OnboardingOptionCard>
            );
          })}
        </AnimateSharedLayout>
      </div>
      <OnboardingSchoolMissing />
    </div>
  );
};

export default OnboardingSchool;
