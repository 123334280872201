import React from "react";
import moment from "moment";
import useOutsideAlerter from "utilities/hooks/useOutsideClickAlerter";

const useDatePicker = ({value, onChange, defaultOpen, format, showInput}) => {
  const pickerRef = React.useRef(null);
  const [showPicker, setShowPicker] = React.useState(defaultOpen);
  /**
   * Hide the picker when clicking outside of it if it's not an input
   */
  useOutsideAlerter(pickerRef, () => {
    setShowPicker(false);
  });

  /**
   * Current date is always a moment object, default is today unless value is passed in
   */
  const [currentDate, setCurrentDate] = React.useState(null);

  React.useEffect(() => {
    const parsedDate = moment(value);

    if (parsedDate.isValid()) {
      setCurrentDate(parsedDate);
    } else {
      setCurrentDate(moment());
    }
  }, [value]);

  /**

     * @param dateString
     */
  const onDateSelected = dateString => {
    setCurrentDate(dateString);

    // Hide the picker on date selection
    showPicker && setShowPicker(false);
  };

  /**
   * Pass the current date back to the parent component
   */
  React.useEffect(() => {
    if (onChange) {
      if (
        currentDate &&
        currentDate.isValid() &&
        currentDate.format(format) !== value
      ) {
        onChange(currentDate.format(format));
      }
    }
  }, [currentDate]);

  return {
    currentDate,
    onDateSelected,
    showPicker,
    setShowPicker,
    pickerRef,
  };
};

export default useDatePicker;
