import React from "react";
import {
  alertPrimaryTextColor,
  alertSecondaryTextColor,
  hoverBorderColors,
} from "Components/_Elements/Alert/Alert.classes";
import {Icon} from "Components";
import classnames from "classnames";
import {safeCloneTextTag} from "utilities/safeClone";
import {alertIconColors} from "Components/_Elements/Alert/AlertIcon";

const AlertText = ({
  type,
  size,
  title,
  subTitle,
  primaryAction = null,
  secondaryAction = null,
}) => {
  return (
    <div className="flex flex-col gap-1">
      {safeCloneTextTag(title, {
        type: size === "xs" ? "txs" : "tsm",
        weight: "medium",
        color: alertPrimaryTextColor[type],
      })}

      {safeCloneTextTag(subTitle, {
        type: size === "xs" ? "txs" : "tsm",
        weight: "normal",
        color: alertSecondaryTextColor[type],
      })}

      {(primaryAction || secondaryAction) && (
        <div className="flex flex-row gap-3 mt-3">
          {secondaryAction && <AlertAction {...secondaryAction} type={type} />}
          {primaryAction && secondaryAction && (
            <Icon
              icon="fa-light fa-pipe"
              className={classnames("mx-1", "my-auto", alertIconColors[type])}
            />
          )}
          {primaryAction && (
            <AlertAction {...primaryAction} isPrimary type={type} />
          )}
        </div>
      )}
    </div>
  );
};

const AlertAction = ({type, label, onClick, isPrimary = false}) => {
  if (!label || !onClick) return null;

  return (
    <div
      className={classnames(
        "flex items-center gap-2 cursor-pointer border-b-2 border-transparent",
        hoverBorderColors[type]
      )}
      onClick={onClick}
    >
      {label &&
        React.cloneElement(label, {
          type: "tsm",
          weight: "medium",
          color: alertPrimaryTextColor[type],
        })}
      {isPrimary && (
        <Icon
          icon="arrow-right"
          size="md"
          color={alertPrimaryTextColor[type]}
        />
      )}
    </div>
  );
};

export default AlertText;
