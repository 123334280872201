import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import {UserContext} from "UserContextProvider";
import {useTranslation} from "react-i18next";
import useEventTracking from "utilities/hooks/useEventTracking";

const useHandleCoursePurchaseClick = (courseId, price) => {
  const { user } = React.useContext(UserContext);
  const { openModal } = React.useContext(window.GlobalModalContext);
  const { logCourseEvent } = useEventTracking();
  let location = useLocation();
  const history = useHistory();

  const { t: trans } = useTranslation();


  const handleUnlockClick = e => {
    if (e) {
      e.preventDefault();
    }

    logCourseEvent("purchase_course_clicked", {
      screen_name: location,
      source: "mobile_course_unlock_banner",
      logged_in: user ? true : false,
    });

    const uri = `/checkout?products=${encodeURIComponent(
        JSON.stringify({productId: courseId, productType: "COURSE"})
    )}`;

    if (location.state?.nextUrl) {
      history.push(location.state?.nextUrl,
          {
            nextUrl: uri
          }
      )
    } else {
      history.push(uri);
    }
  };

  return handleUnlockClick;
};

export default useHandleCoursePurchaseClick;
