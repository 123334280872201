import React from "react";
import {Button, message, Trans} from "Components";
import {useTranslation} from "react-i18next";
import stripe from "utilities/stripe";

const PaymentMethodStripe = ({
  action,
  stripeSessionId,
  disabled,
  loading,
  showAction = true,
}) => {
  const {t: trans} = useTranslation();

  React.useEffect(() => {
    if (stripeSessionId) {
      stripe
        .redirectToCheckout({
          sessionId: stripeSessionId,
        })
        .then(result => {
          // If `redirectToCheckout` fails due to a browser or network error:
          message.warning(
            trans(
              "Something went wrong, please try again or try a different payment method"
            )
          );
        })
        .catch(err => {
          console.log(
            "Error when received response in: useCreateStripePaymentRequest",
            err
          );
        });
    }
  }, [stripeSessionId]);

  if (!showAction) return <div />;

  return (
    <div>
      <Trans className="my-2">Card payment is handled by Stripe</Trans>
      <Button
        id="btn-stripe-pay"
        type="primary"
        fluid
        disabled={disabled}
        loading={loading}
        onClick={() => {
          action({
            stripeSuccessUrl: `${window.location.href}?tab=buy&success=1`,
            stripeCancelUrl: `${window.location.href}`,
            paymentMethod: "STRIPE",
          });
        }}
      >
        <Trans>Go to payment</Trans>
      </Button>
    </div>
  );
};

export default PaymentMethodStripe;
