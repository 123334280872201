import { useMutation } from "@apollo/react-hooks";
import { GET_COURSE_REVIEWS } from "API/queries/reivew/useGetCourseReviews";
import { GET_CURRENT_USER } from "API/queries/user/useGetCurrentUser";
import gql from "graphql-tag";

const CREATE_COURSE_REVIEW = gql`
  mutation createCourseReview(
    $score: Int!
    $title: String
    $text: String
    $courseId: ID!
  ) {
    createCourseReview(
      score: $score
      title: $title
      text: $text
      courseId: $courseId
    ) {
      ok
      error
    }
  }
`;

const useCreateCourseReviewMutation = (courseId, callback) => {
  const [createCourseReview, { data, error, loading }] = useMutation(
    CREATE_COURSE_REVIEW,
    {
      refetchQueries: () => [
        {
          query: GET_COURSE_REVIEWS,
          variables: { courseId, loggedIn: true },
        },
        {
          query: GET_CURRENT_USER,
          variables: { includeStats: false },
        },
      ],
      onCompleted: callback,
    }
  );

  if (error) {
    console.log(`Error in useCreateCourseReviewMutation: ${error}`);
  }

  return { createCourseReview, data, loading };
};

export default useCreateCourseReviewMutation;
