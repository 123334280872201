import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const QUERY = gql`
  query useGetLandingPageSections($id: String!) {
    landingPages(id: $id) {
      sectionsCollection {
        items {
          ...sectionsCollectionFields
        }
      }
    }
  }
  fragment sectionsCollectionFields on LandingPageSection {
    sys {
      id
    }
    order
    title
  }
`;

const useGetLandingPageSections = landingPageId => {
  let sections = [];

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id: landingPageId },
    context: {
      api: "contentful",
    },
  });

  if (error) {
    console.log("Error in useGetLandingPageSections", error);
  }

  if (data && data.landingPages) {
    sections = data.landingPages.sectionsCollection.items;
  } else {
    // Fallback if the data is not returned. We store a JSON snapshot so at least we can show something
  }

  return [sections, loading];
};

export default useGetLandingPageSections;
