import React from "react";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {GET_CURRENT_USER} from "API/queries/user/useGetCurrentUser";

const REDEEM_COUPON = gql`
  mutation redeemCoupon($name: String!) {
    redeemCoupon(name: $name) {
      ok
      error
    }
  }
`;

const useRedeemCreditCouponMutation = (onCompleted, refetechQueries = []) => {
  const [redeemCoupon, {loading, error}] = useMutation(REDEEM_COUPON, {
    onCompleted,
    refetchQueries: () => [
      ...refetechQueries,
      {
        query: GET_CURRENT_USER,
        variables: {includeStats: false, includeMembership: false},
      },
    ],
  });

  if (error) console.log(error);

  return [redeemCoupon, loading];
};

export default useRedeemCreditCouponMutation;
