import React from "react";
import classnames from "classnames";
import {Button, ButtonGroup, Input, Math} from "Components/index";
import Trans from "Components/Trans";

const GeogebraDisplayEquationsItem = ({
  current,
  equation,
  index,
  setSelectedEquation,
  setEditing,
  editing,
  editEquation,
  hasMultipleEquations,
}) => {
  const [value, setValue] = React.useState(equation);

  const onSubmit = () => {
    editEquation(index, value);
  };

  if (editing)
    return (
      <div className="w-full border-2 border-gray rounded-lg p-4 cursor-pointer text-body2 flex gap-4 items-center">
        <Input
          size="lg"
          value={value}
          onChange={e => {
            setValue(e.target.value);
          }}
        />
        <ButtonGroup>
          <Button disabled={value === equation} onClick={onSubmit}>
            <Trans>Update</Trans>
          </Button>
          <Button
            onClick={() => {
              setEditing(false);
            }}
          >
            <Trans>Cancel</Trans>
          </Button>
        </ButtonGroup>
      </div>
    );

  return (
    <div
      onClick={() => {
        if (!current) {
          setSelectedEquation(equation);
        }
      }}
      className={classnames(
        "w-full border-2 border-gray rounded-lg p-4 cursor-pointer text-body2 flex gap-4 items-center justify-between",
        {
          "border-primary surface-primary": current && hasMultipleEquations,
        }
      )}
    >
      <Math>${value}$</Math>
      {current && (
        <Button icon="edit" type="link" onClick={() => setEditing(true)} />
      )}
    </div>
  );
};

export default GeogebraDisplayEquationsItem;
