import React from "react";
import useGetPersonalCoupon from "API/queries/user/userGetPersonalCoupons";
import {Button, Link, Lottie, message, Trans, Typography} from "Components";

const FriendCoupon = () => {
  const [coupon] = useGetPersonalCoupon();
  const [copied, setCopied] = React.useState(false);

  if (!coupon) return null;

  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="grow">
          <Trans type="dsm" weight="bl" color="text-primary-600">
            Your personal code
          </Trans>

          <Trans type="tlg" className="mt-2" weight="md" color="text-gray-500">
            Share your personal code with a friend and you both receive 50 SEK.
          </Trans>
        </div>
        <div
          className="w-28 shrink-0"
          style={{
            filter: "hue-rotate(202deg)",
          }}
        >
          <Lottie animation="giftBox" loop={false} />
        </div>
      </div>

      <div className="mt-4 p-4 bg-slate-100 rounded-lg flex justify-center items-center border-4 border-inside border-dashed border-gray-200">
        <Typography color="text-slate-500" type="dsm" weight="b">
          {coupon}
        </Typography>
      </div>
      {/*<div className="mt-2 flex gap-2">*/}
      {/*  <Button*/}
      {/*    fluid*/}
      {/*    iconLeft={copied ? "far fa-circle-check" : "far fa-clone"}*/}
      {/*    onClick={() => {*/}
      {/*      navigator.clipboard.writeText(coupon);*/}
      {/*      setCopied(true);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {copied ? <Trans>Copied</Trans> : <Trans>Copy</Trans>}*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <div className="mt-4">
        <Button
          fluid
          type="secondary"
          size="lg"
          iconRight="fas fa-copy"
          onClick={() => {
            navigator.clipboard.writeText(coupon);
            message.success("Koden är kopierad!");
          }}
        >
          <Typography>Kopiera</Typography>
        </Button>
      </div>
      <div className="flex flex-col md:flex-row gap-2 items-center mt-2 light">
        {navigator.share ? (
          <Button
            fluid
            type="primary"
            size="lg"
            iconRight="fa-light fa-share-from-square"
            onClick={() => {
              navigator.share({
                title: "Sektionskampen 2023 på Kollin",
                text: text,
                url: url,
              });
            }}
          >
            <Typography>Dela</Typography>
          </Button>
        ) : (
          <>
            <Link
              buttonType="gray"
              fluid
              size="lg"
              href={"https://m.me"}
              target="_blank"
              rel="noopener noreferrer"
              iconRight="fab fa-facebook-messenger"
              iconColor="text-[#00B2FF]"
            >
              <Typography weight="sb" color="text-[#00B2FF]">
                Öppna Messenger
              </Typography>
            </Link>

            <Link
              buttonType="gray"
              fluid
              size="lg"
              href={"https://discord.com/app"}
              target="_blank"
              rel="noopener noreferrer"
              iconRight="fab fa-discord"
              iconColor="text-[#7289da]"
            >
              <Typography weight="sb" color="text-[#7289da]">
                Öppna Discord
              </Typography>
            </Link>

            <Link
              href="slack://open"
              target="_blank"
              rel="noopener noreferrer"
              buttonType="gray"
              fluid
              size="lg"
              iconRight="fab fa-slack"
              iconColor="text-[#4A154B]"
            >
              <Typography weight="sb" color="text-[#4A154B]">
                Öppna Slack
              </Typography>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default FriendCoupon;
