import React from "react";
import {Alert, Button, message, Typography} from "Components";
import {CheckoutContext} from "routes/Checkout/CheckoutContextWrapper/CheckoutContextWrapper";
import StudentVerification from "Widgets/User/StudentVerification/StudentVerification";
import classnames from "classnames";
import useLambda from "API/lambdas/useLambda";
import useRedeemSubscriptionTrialCoupon from "API/mutations/subscriptionTrialCoupon/useRedeemClaimedSubscriptionTrialCoupon";
import {useHistory} from "react-router-dom";
import {GET_CURRENT_USER} from "API/queries/user/useGetCurrentUser";

const GroupDiscountFreeActivation = ({discount, group}) => {
  const {user} = React.useContext(window.UserContext);
  const history = useHistory();
  const checkoutContext = React.useContext(CheckoutContext);

  if (discount.discountPercentage !== 100) return null;

  const onActivationCompleted = () => {
    message.success("Din gratisperiod har aktiverats!");

    if (checkoutContext && checkoutContext.selectedProducts.length > 0) {
      history.goBack();
    } else {
      window.location.reload();
    }
  };

  const {
    redeemSubscriptionTrialCoupon,
    loading: redeemingSubscriptionTrialCoupon,
  } = useRedeemSubscriptionTrialCoupon(onActivationCompleted, [
    {
      query: GET_CURRENT_USER,
      variables: {
        includeMembership: true,
        includeStats: false,
      },
    },
  ]);

  const {data, loading, error} = useLambda({
    lambdaName: "campaign-coupon-provider-dev-api",
    args: {
      userId: user.id,
      campaignName: "sektionskampenWinners",
    },
    autoRun: true,
  });

  const disabled = !user?.student_email;

  if (!data?.name || data?.redeemed) return null;

  return (
    <div className="mt-2 dark">
      {disabled && (
        <StudentVerification subTitle="För att aktivera din rabatt behöver du verifiera dn studentmail" />
      )}
      <div>
        {error ||
          data?.error ||
          data?.message ||
          (data?.ok === false && (
            <Alert
              title={<Typography>Ett fel uppstod</Typography>}
              subTitle={
                <Typography>{error || data?.error || data?.message}</Typography>
              }
              type="error"
            />
          ))}
      </div>

      <Button
        type="primary"
        disabled={disabled}
        className={classnames(
          "mt-2 bg-gradient-to-br from-fuchsia-600 to-amber-600 hover:from-fuchsia-700 hover:to-amber-700",
          disabled && "opacity-50 cursor-not-allowed"
        )}
        fluid
        size="xl"
        loading={loading || redeemingSubscriptionTrialCoupon}
        onClick={() =>
          redeemSubscriptionTrialCoupon({
            variables: {name: data.name, collectionMethod: "send_invoice"},
          })
        }
      >
        <Typography weight="b" color="text-white" type="tmd">
          Aktivera din gratisperiod
        </Typography>
      </Button>
    </div>
  );
};

export default GroupDiscountFreeActivation;
