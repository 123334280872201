import { checkAllWhite } from "Components/ImageWizard/Tools/Vacuum";

export const detectCanvasEdge = ({
  ctx,
  startX,
  startY,
  stepX = null,
  stepY = null,
  width,
  height,
  debug = false,
  targetAllWhite = true,
}) => {
  let sw = stepX || width;
  let sh = stepY || height;

  if (debug) {
    console.log({
      startY,
      startX,
      sw,
      sh,
    });
  }

  const imageData = ctx.getImageData(startX, startY, sw, sh, {
    colorSpace: "srgb",
  });

  const allWhite = checkAllWhite(imageData.data, 255 * 3);

  if (debug) {
    console.log({ allWhite, d: imageData.data });
  }

  if (allWhite === targetAllWhite) {
    let newStartX = stepX ? startX + stepX : startX;
    let newStartY = stepY ? startY + stepY : startY;

    return detectCanvasEdge({
      ctx,
      startX: newStartX,
      startY: newStartY,
      stepX,
      stepY,
      width,
      height,
      debug,
      targetAllWhite,
    });
  }

  if (stepX) {
    return startX;
  }

  if (stepY) {
    return startY;
  }

  return null;
};

const detectSegmentBounds = ({
  pages,
  y1,
  y2,
  relativeTop,
  relativeBottom,
}) => {
  // For each page get the canvvas

  const pageDivs = pages.map(page => {
    return document.querySelector(
      `div.react-pdf__Page[data-page-number="${page}"]`
    );
  });

  let pageWidth,
    scaleFactor,
    pageHeight = 0;

  // Get the image data for all page-canvases and merge them into one array
  const ctxs = pageDivs.map(pd => {
    const ctx = pd.querySelector("canvas").getContext("2d");
    const { width, height } = ctx.canvas;

    pageWidth = ctx.canvas.width; // All pages have the same width
    pageHeight = ctx.canvas.height; // All pages have the same height

    scaleFactor = width / ctx.canvas.offsetWidth;
    return ctx;
  });

  let segmentHeight = pages.length > 1 ? pageHeight - relativeTop : y2 - y1;

  let x1 = detectCanvasEdge({
    ctx: ctxs[0],
    startY: relativeTop * scaleFactor,
    height: segmentHeight * scaleFactor,
    startX: 0,
    stepX: 10,
    targetAllWhite: true,
  });

  let x2 = detectCanvasEdge({
    ctx: ctxs[0],
    startY: relativeTop * scaleFactor,
    height: segmentHeight * scaleFactor,
    startX: pageWidth,
    stepX: -10,
    targetAllWhite: true,
  });

  let newRelativeTop = detectCanvasEdge({
    ctx: ctxs[0],
    startX: 0,
    startY: relativeTop * scaleFactor,
    width: pageWidth,
    stepY: -5,
    targetAllWhite: false,
  });

  // console.log({
  //   relativeTop,
  //   newRelativeTop,
  //   y1,
  // });

  let newY1 = y1 + (newRelativeTop - relativeTop * scaleFactor) / scaleFactor;
  // let newY2 = y2 - (relativeBottom - newRelativeBottom);

  let newX1 = Math.max(0, x1 - 10); // Add some padding
  let newX2 = Math.min(pageWidth, x2 + 10); // Add some padding

  return {
    x1: newX1 / scaleFactor,
    x2: newX2 / scaleFactor,
    y1: newY1,
    // y2: newY2,
  };
};

const refineSegmentsBounds = segments =>
  segments.map(m => ({
    ...m,
    ...detectSegmentBounds({ ...m }),
  }));

/**
 * Checks each segment against the next one to see if they overlap.
 * if segment[i].y2 > segment[i+1].y1, then they overlap
 * Set the segment[i].y2 = segment[i+1].y1
 * @param {Array} segments
 * @returns {Array} segments
 */

export const correctSegmentsOverlap = segments => {
  for (let i = 0; i < segments.length - 1; i++) {
    const segment = segments[i];
    const nextSegment = segments[i + 1];
    if (segment.y2 >= nextSegment.y1) {
      segment.y2 = nextSegment.y1 - 4; // -4 to avoid overlap and add some space
    }
  }
  return segments;
};

export default refineSegmentsBounds;
