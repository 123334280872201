import React from "react";
import {UserContext} from "UserContextProvider";
import {useWindowSize} from "utilities/hooks/useWindowSize";
import PageNavbarDesktop from "./PageNavbarDesktop";
import PageNavbarMobile from "./PageNavbarMobile";
import {useLocation} from "react-router-dom";

const hiddenRoutes = [
  "login",
  "signup",
  "landing",
  "forgot-password",
  "trial",
  "sektionskampen",
  "campaign",
  "resetpassword",
  "guldbiljett",
  "group",
];

const PageNavbar = ({
  location,
  hideInCourse = false,
  forceMount = false,
  transparent = false,
  dark = false,
  logoComponentProps = {},
}) => {
  const routerLocation = useLocation();

  const currentPath = location?.pathname || routerLocation?.pathname;
  const [vWidth] = useWindowSize();
  const {user} = React.useContext(UserContext);

  const splittedPathname = currentPath.split("/");
  // On sign up page don't show header if we are in a hidden route ,unless forceMount is true
  if (splittedPathname.some(path => hiddenRoutes.includes(path)) && !forceMount)
    return null;

  // If logged in and we are in the course. the PageNavbar is not swown at all
  if (user) return null;

  const inCourse = splittedPathname.includes("course");

  if (vWidth > 768)
    return (
      <PageNavbarDesktop
        transparent={transparent}
        user={user}
        location={location}
        inCourse={inCourse}
        logoComponentProps={logoComponentProps}
      />
    );
  return (
    <PageNavbarMobile
      dark={dark}
      transparent={transparent}
      user={user}
      location={location}
      logoComponentProps={logoComponentProps}
    />
  );
};

export default PageNavbar;
