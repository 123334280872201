import React from "react";
import {motion} from "framer-motion";
import Button from "Components/_Elements/Button/Button";
import Icon from "Components/_Elements/Icon/Icon";
import PropTypes from "prop-types";
import classnames from "classnames";
import NotificationElementIcon from "./NotificationElementIcon";
import NotificationElementBody from "./NotificationElementBody";

/**
 * The notification element component
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationElement = ({
  notificationId,
  title,
  subTitle,
  message,
  body,
  primaryAction = null,
  secondaryAction = null,
  icon = "bell",
  iconFillType = "primary",
  closable = true,
  onClose,
  duration = 5000,
  autoClose = true,
}) => {
  // If there is only a title, we can use the compact version
  const onlyTitle = !message && !body && !primaryAction && !secondaryAction;

  /**
   * An effect to close the notification after a certain amount of time
   */
  React.useEffect(() => {
    if (duration != null && autoClose) {
      const timeout = setTimeout(() => onClose(notificationId), duration);
      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <motion.div
      key={"message-" + notificationId}
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0}}
      exit={{opacity: 0, x: 100}}
      transition={{
        type: "spring",
        duration: 0.3,
      }}
      layout={true}
      className="min-w-96 max-w-md background mb-2 rounded-lg p-4 border-1 border-gray shadow-lg"
    >
      <div className={classnames("flex items-start  justify-between")}>
        <div
          className={classnames(
            "flex  justify-between gap-4",
            onlyTitle ? "items-center" : "items-start"
          )}
        >
          <NotificationElementIcon icon={icon} fillType={iconFillType} />
          <NotificationElementBody
            title={title}
            subTitle={subTitle}
            message={message}
            body={body}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
          />
        </div>
        {closable && (
          <Button type="link" onClick={onClose} icon="times" size="lg" />
        )}
      </div>
    </motion.div>
  );
};

NotificationElement.propTypes = {
  /**
   * The notification id, used for layout animation
   */
  notificationId: PropTypes.string.isRequired,
  /**
   * The title of the notification
   *
   */

  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,

  /**
   * The subtitle of the notification (shown inlined with the title)
   */
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  /**
   * The message of the notification
   */
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),

  /**
   * The body of the notification (optionally shown below the message)
   */
  body: PropTypes.element,
  /**
   * The primary action of the notification
   */
  primaryAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  /**
   * The secondary action of the notification
   */

  secondaryAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),

  /**
   * Whether the notification is closable
   */
  closable: PropTypes.bool,
  /**
   * The icon of the notification
   *
   */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * The fill type of the icon
   */
  iconFillType: Object.keys(Icon.propTypes.fillType),
  /**
   *  The callback function when the notification is closed
   */
  onClose: PropTypes.func,
  /**
   * The duration of the notification
   */
  duration: PropTypes.number,
  /**
   * Whether the notification should be closed automatically
   */
  autoClose: PropTypes.bool,
};

export default NotificationElement;
