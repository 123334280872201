import React from "react";
import subdomains from "./subdomains";
import GroupNavigation from "routes/Group/GroupNavigation/GroupNavigation";
import groupRoutes from "./groupRoutes";
import {PageLogoLoader} from "Components";
import {userContext} from "UserContextProvider";
import {useHistory} from "react-router-dom";
import GroupAuthWrapper from "routes/Group/GroupAuthWrapper";
import GroupNoAccess from "Providers/GroupNoAccess";
import useGroupProviderState from "Providers/GroupProvider/useGroupProviderState";

export const isInGroup =
  window.location.host.split(".").length > 1 &&
  subdomains[window.location.host.split(".")[0]] !== undefined;

export const GroupContext = React.createContext(null);
window.GroupContext = GroupContext;

/**
 *  Actual Context provider which is mounted if the user is in a group subdomain
 * @param children
 * @param routes
 * @returns {JSX.Element|null}
 * @constructor
 */
const GroupContextProvider = ({children, routes}) => {
  const {user} = userContext();
  const history = useHistory();
  const subdomain = window.location.host.split(".")[0];
  const subdomainInfo = subdomains[subdomain];

  const {
    groupId,
    group,
    loading,
    currentRoute,
    currentRouteIsAuthRoute,
    currentUserIsMember,
    currentUserIsAdmin,
  } = useGroupProviderState(routes);

  React.useEffect(() => {
    if (!currentRouteIsAuthRoute && !user?.id) {
      history.push("/login");
    }

    // Redirect to home if user is logged in and on auth route
    // if (currentRouteIsAuthRoute && user?.id) {
    //   history.push("/home");
    // }
  }, [user, currentRouteIsAuthRoute]);

  if (loading) return <PageLogoLoader />;

  // Check if current user is a member of the group
  if (!currentUserIsMember && !currentRouteIsAuthRoute) {
    return (
      <GroupContext.Provider
        value={{...group, groupId, subdomain, ...subdomainInfo}}
      >
        <GroupAuthWrapper>
          <GroupNoAccess />
        </GroupAuthWrapper>
      </GroupContext.Provider>
    );
  }

  return (
    <GroupContext.Provider
      value={{
        ...group,
        groupId,
        subdomain,
        ...subdomainInfo,
        currentUserIsAdmin,
        currentUserIsMember,
      }}
    >
      <GroupNavigation routes={Object.values(groupRoutes)}>
        {children}
      </GroupNavigation>
    </GroupContext.Provider>
  );
};

const GroupProvider = ({children, routes}) => {
  if (isInGroup)
    return (
      <GroupContextProvider routes={routes}>{children}</GroupContextProvider>
    );

  return <>{children}</>;
};

export const useGroupContext = () => {
  return React.useContext(GroupContext);
};

export default GroupProvider;
