import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

export const GET_EXERCISE_NOTE_IN_COURSE = gql`
  query getCurrentUsersExerciseNotesInCourse($courseId: ID!) {
    getCurrentUsersExerciseNotesInCourse(courseId: $courseId) {
      id
      text
      createdAt
      updatedAt
      exercise {
        id
      }
    }
  }
`;

const useGetCurrentUsersExerciseNotesInCourse = courseId => {
  let notes = [];

  const { loading, error, data } = useQuery(GET_EXERCISE_NOTE_IN_COURSE, {
    variables: { courseId },
  });

  if (error) {
    console.log("Error in useGetCurrentUsersExerciseNotesInCourse", error);
  }

  if (data) {
    notes = data.getCurrentUsersExerciseNotesInCourse;
  }

  return [notes, loading];
};

export default useGetCurrentUsersExerciseNotesInCourse;
