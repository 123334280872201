import React from "react";
import CDN from "utilities/constants/CDN";
import {Loader, SafeText} from "Components/index";
import "Components/_Elements/PageLogoLoader/PageLogoLoader.scss";
import classnames from "classnames";
import {motion} from "framer-motion";

const PageLogoLoader = ({inverted = true, title = null}) => {
  return (
    <motion.div
      exit={{x: -100}}
      className={classnames(
        "page-logo-loader background",
        inverted &&
          "bg-gradient-to-bl from-secondary-700 to-primary-700 dark:from-secondary-900 dark:to-primary-900"
      )}
      style={{
        background:
          inverted && "linear-gradient(180deg, #9f5e72 0%, #425388 100%)",
      }}
    >
      {inverted ? (
        <img src={`${CDN}/kollin_logo_white.svg`} className="dark:invert" />
      ) : (
        <img src={`${CDN}/kollin_logo.svg`} className="dark:invert" />
      )}
      <Loader type="Bar" color={inverted ? "white" : "#6e8be3"} width={160} />
      {title && (
        <SafeText
          type="tmd"
          weight="sb"
          color={inverted ? "text-white" : "text-primary-light"}
          className="mt-4"
        >
          {title}
        </SafeText>
      )}
    </motion.div>
  );
};

export default PageLogoLoader;
