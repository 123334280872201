import React from "react";
import {getColors, replaceColor, colorify, flatten} from "lottie-colorify";

const useLottie = ({debug = false, replaceColors = []}) => {
  const [data, setData] = React.useState(null);

  const handleData = jsonData => {
    let animationData = jsonData;

    if (debug) {
      console.log(getColors(animationData));
    }

    if (replaceColors.length > 0) {
      replaceColors.forEach(color => {
        if (debug) {
          console.log("from:", color.from, "to:", color.to);
        }

        animationData = replaceColor(color.from, color.to, animationData);
      });
    }

    if (debug) {
      console.log("after replace:", getColors(animationData));
    }

    setData(animationData);
  };

  const loadAnimation = path => {
    if (path.includes("https")) {
      // Fetch the animation
      fetch(path)
        .then(response => response.json())
        .then(jsonData => {
          handleData(jsonData);
        });
      return;
    }

    // Load the animation
    import(`${path}`).then(json => {
      let animationData = json.default;
      handleData(animationData);
    });
  };

  return [data, loadAnimation];
};

export default useLottie;
