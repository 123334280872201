import React from "react";
import {
  Button,
  CourseCard,
  Icon,
  Section,
  SubSection,
  Trans,
  Typography,
} from "Components";
import useSearchCourse from "API/queries/course/useSearchCourse";
import {motion} from "framer-motion";
import classnames from "classnames";

const CourseListNoResults = ({
  cancel,
  query,
  handleCourseClick,
  noResults = false,
}) => {
  const {courses, loading} = useSearchCourse({
    query,
    limit: 3,
  });

  const coursesGroupedBySchoolName = React.useMemo(() => {
    return courses.reduce((acc, course) => {
      if (!acc[course.school.name]) {
        acc[course.school.name] = [];
      }
      acc[course.school.name].push(course);
      return acc;
    }, {});
  }, [courses]);

  return (
    <>
      {noResults && (
        <div className="bg-warning-50 p-4 flex  gap-4 items-center justify-between rounded-xl">
          <div className="flex gap-4">
            <Icon icon="empty-set" size="lg" color="text-warning-400" />
            <Trans type="tmd" color="text-warning-600" weight="md">
              No courses found
            </Trans>
          </div>
          {query && (
            <Button onClick={cancel} type="gray" size="sm">
              <Trans>Clear search input</Trans>
            </Button>
          )}
        </div>
      )}
      {courses.length > 0 && (
        <motion.div
          initial={{opacity: 0, y: 50}}
          animate={{opacity: 1, y: 0}}
          className={classnames("mt-8 opacity-50")}
        >
          <Section secondary title={<Trans>Results from other schools</Trans>}>
            {Object.keys(coursesGroupedBySchoolName).map(
              (schoolName, index) => (
                <SubSection
                  key={schoolName}
                  className={classnames(
                    "mt-4",
                    "transition-all duration-300",
                    noResults ? "opacity-100" : "opacity-50 hover:opacity-100"
                  )}
                >
                  <Typography type="h3" weight="sb">
                    {schoolName}
                  </Typography>
                  <div className="grid grid-cols-1 gap-4 ">
                    {coursesGroupedBySchoolName[schoolName]
                      .slice(0, 3) // Take the first 3 courses
                      .map(course => (
                        <CourseCard
                          {...course}
                          key={course.id}
                          schoolName={schoolName}
                          onClick={handleCourseClick}
                          highlightText={query}
                        />
                      ))}
                  </div>
                </SubSection>
              )
            )}
          </Section>
        </motion.div>
      )}
    </>
  );
};

export default CourseListNoResults;
