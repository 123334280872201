import React from "react";
import documentExtractText from "API/lambdas/documentExtractText";
import Trie from "utilities/DataStructures/Trie";

const useExamUploadDocumentsTableRowTextState = ({ document }) => {
  const [text, setText] = React.useState("");

  const documentTrie = React.useRef(null);

  React.useEffect(() => {
    const initTrie = async () => {
      let docUrl = document?.url;

      // Don't do anything if the document is not an exam or mixed
      if (!docUrl) {
        return;
      }

      // Set author to "loading" while the trie is being built and the author is being searched
      const text = await documentExtractText(docUrl, "text", true, 1);

      documentTrie.current = new Trie(text);
      setText(text);
    };

    initTrie();
  }, []);

  return {
    text,
    documentTrie,
  };
};

export default useExamUploadDocumentsTableRowTextState;
