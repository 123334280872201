import React from "react";

const UseVersionUpdateBanner = () => {
  const version = localStorage.getItem("version");

  const versionParam = new URLSearchParams(window.location.search).get(
    "version"
  );

  React.useEffect(() => {
    if (versionParam) {
      localStorage.setItem("version", versionParam);
    }
  }, []);

  const handleRedirect = () => {
    // Redirect to next.kollin.io and same uri
    const path = window.location.pathname;
    let newPath = path;
    // If path is /course/1234/*/** => /course/1234
    if (path.match(/\/course\/\d+/)) {
      const courseId = path.match(/\/course\/\d+/)[0];
      newPath = courseId;
    }

    const params = new URLSearchParams(window.location.search);

    params.set("version", "next");
    // redirect
    window.location.href = `https://next.kollin.io${newPath}?${params.toString()}`;
  };

  const handleToggle = () => {
    // Update user settings so they are forced to use next.kollin.io
    localStorage.setItem("version", "next");
    handleRedirect();
  };

  // Auto redirect if user settings is set to next
  React.useEffect(() => {
    if (version === "next" && !versionParam) {
      handleRedirect();
    }
  }, []);

  return {
    version,
    handleToggle,
  };
};

export default UseVersionUpdateBanner;
