import React from "react";
import useSubmitForm from "API/sheets";
import {Button, Icon, Input, message, Modal, TextArea, Trans} from "Components";
import useRequestSchool from "Modals/RequestSchool/useRequestSchool";
import AnimatedCheckbox from "Components/Animated/AnimatedCheckbox/AnimatedCheckbox";

const RequestSchool = ({onComplete, onClose}) => {
  const {
    onSubmit,
    loading,
    error,
    handleChange,
    state,
    success,
  } = useRequestSchool(onComplete);

  return (
    <Modal
      defaultOpen
      showCancelButton
      onClose={onClose}
      icon="building-columns"
      action={{
        onClick: onSubmit,
        loading: loading,
        disabled: !state.schoolName || state.schoolName === "",
        error: error,
        iconRight: "paper-plane",
        label: <Trans>Send</Trans>,
      }}
      title={<Trans>Request your school</Trans>}
      description={
        <Trans>
          Here you can request a school to be added to Kollin. We will try to
          add it as soon as possible.
        </Trans>
      }
    >
      <form onSubmit={onSubmit} className="mt-4 flex flex-col gap-4">
        {success ? (
          <div>
            <Trans>
              Thank you for your request. We will notify you when your school is
              available on Kollin
            </Trans>
            <AnimatedCheckbox />
          </div>
        ) : (
          <TextArea
            text={state.schoolName}
            onChange={handleChange}
            name="schoolName"
            label={<Trans>School name</Trans>}
          />
        )}
      </form>
    </Modal>
  );
};
export default RequestSchool;
