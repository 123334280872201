require("dotenv").config();

export const API_URL = process.env.REACT_APP_API_URL;

const [protocol, url] = API_URL.split(":");
const WSProtocol = protocol == "https" ? "wss" : "ws";

const WSUri = `${WSProtocol}://${url}/graphql`;
const uri = `${API_URL}/graphql`;

export {uri, WSUri};
