import React from "react";
import _ from "lodash";

const useVisitedCourses = courseId => {
  const storage = window.localStorage;

  const [visitedCourses, setVisitedCourses] = React.useState([]);

  React.useEffect(() => {
    if (courseId) {
      addVisitedCourse(courseId);
    }
    setVisitedCourses(
      (storage.getItem("visitedSchools") &&
        JSON.parse(storage.getItem("visitedSchools"))) ||
        []
    );
  }, [courseId]);

  const addVisitedCourse = newSchoolId => {
    let visitedSchoolsTmp = [];

    if (storage.getItem("visitedSchools")) {
      visitedSchoolsTmp = JSON.parse(storage.getItem("visitedSchools"));
    }

    visitedSchoolsTmp.unshift(newSchoolId);

    visitedSchoolsTmp = _.uniq(visitedSchoolsTmp);
    storage.setItem(
      "visitedSchools",
      JSON.stringify(visitedSchoolsTmp.slice(0, 4))
    );

    setVisitedCourses(storage.getItem("visitedSchools"));
  };

  return visitedCourses;
};

export default useVisitedCourses;
