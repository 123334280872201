import React from "react";
import useLambda from "API/lambdas/useLambda";
import moment from "moment";
import {schoolYearStart} from "routes/Group/GroupCourses/GroupCoursesTimeline/GroupCoursesTimeline";
import {AnimatePresence, AnimateSharedLayout, motion} from "framer-motion";
import {Typography} from "Components";
import classnames from "classnames";
import {uniqBy} from "lodash";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";

const GroupCoursesTimelineCourse = ({
  courseCode,
  id,
  index,
  weekSpanDivider,
}) => {
  const {subdomain} = useGroupContext();

  const [showInfo, setShowInfo] = React.useState(false);

  const {data, loading, error} = useLambda({
    lambdaName: "course-data-extractor-prod-main",
    args: {
      school: subdomain === "mts" ? "liu" : "kth",
      code: courseCode,
      language: "sv",
      returnSchedule: true,
    },
    autoRun: true,
    cachePeriod: 60 * 60, // 1 day
  });

  if (loading || !data) return null;

  /**
   * Sample object:
   */
  const line = getColumnsInfo(data?.schedule, weekSpanDivider);

  if (!line) return null;

  // logUniqueEvents(line.events, courseCode);

  const {events, firstEventDate, lastEventDate, colSpan, offset} = line;
  const daysSpan = moment(lastEventDate).diff(firstEventDate, "days");

  let displayEvents = events
    .map(event => {
      const einfo = getEventInfo(event);

      if (!einfo) return null;

      return {
        ...event,
        date: moment(event["DTSTART;VALUE=DATE-TIME"]).format("Do MMM"),
        ...einfo,
      };
    })
    .filter(event => event); // remove nulls

  displayEvents = uniqBy(displayEvents, "date");

  return (
    <motion.div
      className="p-4 bg-gray-200 rounded-md group hover:surface-primary cursor-pointer relative shadow-sm"
      style={{
        gridColumn: `${offset + 1} / span ${colSpan}`,
        // Start at row i and span 2 rows
        gridRow: `${index + 1} / span 1`,
        zIndex: 20 + index,
      }}
      initial={{opacity: 0, width: 0}}
      animate={{opacity: 1, width: "auto"}}
      onMouseEnter={() => setShowInfo(true)}
      onMouseLeave={() => setShowInfo(false)}
    >
      <Typography type="tmd" weight="sb">
        {courseCode}
      </Typography>

      {displayEvents.map((event, i) => {
        const eventDate = moment(event["DTSTART;VALUE=DATE-TIME"]);
        const eventInfo = getEventInfo(event);

        const yOffset = (-50 - i * 55).toString() + "%";

        return (
          <AnimateSharedLayout>
            <motion.div
              initial={{
                opacity: 0,
                scale: 0,
                x: i === 0 ? 0 : i * 120,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                top: showInfo ? yOffset : "50%",
                z: showInfo ? 100 : 0,
              }}
              className={classnames(
                "absolute left-0 z-10 flex gap-2 rounded-full whitespace-nowrap overflow-hidden truncate",
                showInfo ? "max-w-64 px-2 py-1 z-max" : "w-2 h-2",
                `bg-${eventInfo.color}-400 text-white`
              )}
              key={event.date}
              layout
              transition={{
                delay: showInfo ? i * 0.1 : 0,
              }}
            >
              {showInfo && (
                <motion.span
                  initial={{opacity: 0, width: 0}}
                  animate={{opacity: 1, width: "100%"}}
                  // transition={{delay: 0.3}}
                >
                  <Typography type="tsm" weight="sb">
                    {eventDate.format("DD MMM")}: {eventInfo.name}
                  </Typography>
                </motion.span>
              )}
            </motion.div>
          </AnimateSharedLayout>
        );
      })}
    </motion.div>
  );
};

const getColumnsInfo = schedule => {
  const calendar = schedule?.VCALENDAR?.[0];

  if (!calendar) {
    return null;
  }

  const events = calendar.VEVENT;

  if (!events || events.length === 0) {
    return null;
  }

  // Find first event that is after schoolYearStart
  const firstEvent = events.find(event => {
    const eventDate = moment(event["DTSTART;VALUE=DATE-TIME"]);
    return eventDate.isAfter(schoolYearStart);
  });

  // findLastEvent from behind that does not match omtenta
  const lastEvent = [...events].reverse().find(event => {
    const isReExam = event["SUMMARY"].match(/omtenta/i);
    return !isReExam;
  });

  try {
    const firstEventDate = moment(firstEvent["DTSTART;VALUE=DATE-TIME"]);
    const lastEventDate = moment(lastEvent["DTSTART;VALUE=DATE-TIME"]);

    // Calculate the number of weeks between the first and last event
    const numberOfWeeks = lastEventDate.diff(firstEventDate, "weeks");

    const colSpan = numberOfWeeks; // Each column represents two weeks

    // Calculate the offset from the first week (schoolYearStart) to the first event
    const offset = firstEventDate.diff(schoolYearStart, "weeks");
    return {
      colSpan,
      offset,
      events,
      firstEventDate,
      lastEventDate,
    };
  } catch (e) {
    return null;
  }
};

const getEventInfo = event => {
  let name = event["SUMMARY"];

  let color;

  if (name.match(/(tentamen)/i)) {
    color = "red";
  }

  if (name.match(/kontrollskrivning/i)) {
    color = "amber";
  }

  if (name.match(/(semiunarium|studiebesök)/i)) {
    color = "purple";
  }

  // if (name.match(/(laboration|lab)/i)) {
  //   color = "fuchsia";
  // }

  if (!color) {
    return null;
  }

  name = name.split(/-|:/)[0].trim();

  return {
    name,
    color,
  };
};

const logUniqueEvents = (events, courseCode) => {
  console.log(courseCode, [
    ...new Set(
      events.map(event => {
        return event["SUMMARY"].split(/-|:/)[0].trim();
      })
    ),
  ]);
};
export default GroupCoursesTimelineCourse;
