import {setProductsSelectedPrices} from "Components/ProductPrice/ProductPriceFunctions";
import React from "react";
import useCheckoutStateRecommendedProducts from "./useCheckoutStateRecommendedProducts";
import useCheckoutTotalCalculations from "routes/Checkout/CheckoutContextWrapper/useCheckoutTotalCalculations";
import useGroupDiscounts from "Widgets/Group/GroupDiscounts/useGroupDiscounts";

const useCheckoutState = ({
  products,
  recommendedProducts,
  toggleLifeTimeSelected,
  lifeTimeSelected,
  setSelectedRecommendedProducts,
}) => {
  const accessDays = lifeTimeSelected ? null : 360;
  const [discount, setDiscount] = React.useState(0);

  const {
    discount: groupDiscountCoupon,
    discountApplicable: groupDiscountCouponApplicable,
  } = useGroupDiscounts();

  const proceedToPaymentDisabled =
    groupDiscountCoupon &&
    groupDiscountCoupon.discountPercentage === 100 &&
    groupDiscountCouponApplicable;

  const [productsState, setProductsState] = React.useState({
    products: setProductsSelectedPrices(products, accessDays),
    recommendedProducts: setProductsSelectedPrices(
      recommendedProducts,
      accessDays
    ),
  });

  const {
    addRecommendedProductToCartByIndex,
    removeRecommendedProductByIndex,
  } = useCheckoutStateRecommendedProducts({
    productsState,
    setProductsState,
    setSelectedRecommendedProducts,
  });

  /**
   * An effect that is run whenever the products change because we need to update the total price
   */
  React.useEffect(() => {
    setProductsState({
      products: setProductsSelectedPrices(productsState.products, accessDays),
      recommendedProducts: setProductsSelectedPrices(
        productsState.recommendedProducts,
        accessDays
      ),
    });
  }, [accessDays]);

  const totals = useCheckoutTotalCalculations({
    productsState,
    discount,
    groupDiscountCoupon,
  });

  const selectedProducts = React.useMemo(() => {
    return [
      ...productsState.products,
      ...productsState.recommendedProducts.filter(p => p.selected),
    ];
  }, [productsState]);

  return {
    ...productsState,
    selectedProducts,
    setProductsState,
    addRecommendedProductToCartByIndex,
    removeRecommendedProductByIndex,
    lifeTimeSelected,
    toggleLifeTimeSelected,
    discount,
    setDiscount,
    groupDiscountCoupon,
    proceedToPaymentDisabled,
    ...totals,
  };
};

export default useCheckoutState;
