import React from "react";
import { Trans } from "Components";

/**
 * @deprecated - Use useSubmitForm instead as it is more generic.
 */
export const useSubmitPurchaseExtension = () => {
  const [response, setResponse] = React.useState({
    success: false,
    error: null,
    loading: false,
  });

  const submit = async variables => {
    const body = getBody(variables);

    try {
      setResponse({
        success: false,
        error: null,
        loading: true,
      });

      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzqWYD4xeKJusJfx9VOIPD0XhotjsJUcudmlurmHztaOT-c3FbaXinbn0wbbu6TUqcH/exec",
        {
          method: "POST",
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          redirect: "follow", // manual, *follow, error
          body,
          headers: {
            Accept: "*/*",
          },
        }
      );

      const json = await response.json();

      if (json.result == "success") {
        setResponse({
          success: true,
          error: null,
          loading: false,
        });
      } else if (json.result == "error") {
        setResponse({
          success: false,
          error: json.error,
          loading: false,
        });
      } else {
        setResponse({
          success: false,
          error: "Unknown error",
          loading: false,
        });
      }
    } catch (error) {
      setResponse({
        success: false,
        error: <Trans>Unexpected error occurred</Trans>,
        loading: false,
      });
    }
  };

  return {
    ...response,
    submit,
  };
};

const getBody = variables => {
  const body = new FormData();

  for (var key in variables) {
    body.append(key, variables[key]);
  }
  body.append("action", "submit");
  body.append("timestamp", new Date().toISOString());

  return body;
};
