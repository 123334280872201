import React from "react";
import PropTypes from "prop-types";
import {motion} from "framer-motion";

import {Badge, Icon, Trans, Typography, CreditCardLogo} from "Components";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {capitalizeFirstLetter} from "utilities/text";

const CreditCard = ({
  brand,
  last4,
  exp_month,
  exp_year,
  selected,
  actions,
  layoutId,
  icon = null,
  className = "",
}) => {
  const {t: trans} = useTranslation();

  const title = `${brand} ${trans("ending in")} ${last4}`;

  return (
    <motion.div
      layoutId={layoutId}
      key={layoutId}
      className={classnames(
        [
          "w-full border-2 rounded-lg p-4 mb-2 flex gap-8  justify-between items-start",
          selected ? "bg-primary-25" : "background",
          className,
        ].join(" ")
      )}
    >
      <div className="flex items-center gap-4">
        <CreditCardLogo size="md" brand={brand} />
        <div>
          <div className="flex gap-2 items-center">
            <Typography
              weight="medium"
              color={selected ? "text-primary-800" : "text-gray-700"}
            >
              {capitalizeFirstLetter(title)}
            </Typography>
            {selected && (
              <Badge type="success" size="sm" icon="star">
                <Trans>Default</Trans>
              </Badge>
            )}
          </div>

          <CreditCardExpiry exp_month={exp_month} exp_year={exp_year} />
        </div>
      </div>
      <div>{actions}</div>

      {icon && <Icon icon={icon} size="md" color="text-gray-500" />}
    </motion.div>
  );
};

export const checkCreditCardExpired = (month, year) =>
  moment().isAfter(moment(`${month}/${year}`, "MM/YYYY"));

const CreditCardExpiry = ({exp_month, exp_year}) => {
  const isExpired = checkCreditCardExpired(exp_month, exp_year);
  const expiryDate = moment(`${exp_month}/${exp_year}`, "MM/YYYY").format(
    "MMMM YYYY"
  );

  if (isExpired)
    return (
      <div className="text-warning-600 flex items-end gap-1 ">
        <Icon icon="triangle-exclamation" color="text-warning-600" size="md" />
        <span>
          <Trans type="tsm" color="text-warning-600" expiryDate={expiryDate}>
            Expired on {{expiryDate}}
          </Trans>
        </span>
      </div>
    );

  return (
    <Trans type="tsm" color="text-gray-500" expiryDate={expiryDate}>
      Expires {{expiryDate}}
    </Trans>
  );
};

CreditCard.propTypes = {
  brand: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
  exp_month: PropTypes.string.isRequired,
  exp_year: PropTypes.string.isRequired,
  funding: PropTypes.oneOf(["credit", "debit"]),
  selected: PropTypes.bool,
  primaryActionOnClick: PropTypes.func,
  secondaryActionOnClick: PropTypes.func,
};

export default CreditCard;
