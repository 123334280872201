import React from "react";
import useUpdateGroupMember from "API/mutations/groups/useUpdateGroupMember";
import {Toggle} from "Components";

const GroupAdminUploadMemberVerifiedToggle = ({
  groupId,
  memberId,
  verified,
}) => {
  const {updateGroupMember, loading} = useUpdateGroupMember({
    showMessages: false,
  });

  const onToggle = async val => {
    // Don't update if the value is the same
    if (val === verified) return;

    updateGroupMember({
      variables: {
        groupId,
        groupMemberId: memberId,
        verified: val,
      },
    });
  };

  return (
    <Toggle
      key={memberId}
      onChange={onToggle}
      disabled={loading}
      name="memberVerified"
      defaultChecked={verified}
    />
  );
};

export default GroupAdminUploadMemberVerifiedToggle;
