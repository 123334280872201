import React from "react";
import {isMobile} from "react-device-detect";
import i18n from "i18n";
import {CourseParamsContext} from "routes/Course/CourseRouter";
import SelectList from "Components/_Elements/Select/Select";
import Trans from "Components/Trans";
import {useHistory} from "react-router-dom";
import exerciseListFilters from "utilities/exerciseUtilities/exerciseListFilters";
import "./ExerciseSortList.scss";

const selectAttributes = [
  {text: i18n.t("Difficulty"), value: "score"},
  {text: i18n.t("Assessment"), value: "selfEvaluation"},
  {text: i18n.t("Author"), value: "author"},
  {text: i18n.t("Date"), value: "date"},
  {text: i18n.t("Exercise number"), value: "number"},
  {text: i18n.t("Topic"), value: "tag"},
];

const selectDirections = [
  {text: i18n.t("Ascending"), value: "asc"},
  {text: i18n.t("Descending"), value: "desc"},
];

const filters = Object.keys(exerciseListFilters).map(key => ({
  text: i18n.t(exerciseListFilters[key].label),
  value: key,
}));

export const ExerciseSort = () => {
  const {
    dir,
    sort,
    getSortAttributeLink,
    getSortDirectionLink,
  } = React.useContext(CourseParamsContext);

  const history = useHistory();
  const key = isMobile ? "value" : "label";

  const getSelectedDirection = () =>
    dir ? selectDirections.find(x => x.value === dir)[key] : null;

  const getSelectedAttribute = () => {
    return sort ? selectAttributes.find(x => x.value === sort)[key] : null;
  };

  const onAttributeSelect = value =>
    history.replace(getSortAttributeLink(value));

  const onDirectionSelect = value =>
    history.replace(getSortDirectionLink(value));
  return (
    <>
      <SelectList
        size="sm"
        placeholder={i18n.t("Sort by")}
        value={getSelectedAttribute()}
        onSelect={onAttributeSelect}
        options={selectAttributes}
      />
      <div className="vs" />
      <SelectList
        placeholder={i18n.t("Sort direction")}
        size="sm"
        onSelect={onDirectionSelect}
        value={getSelectedDirection()}
        options={selectDirections}
      />
    </>
  );
};

export const ExerciseFilter = () => {
  const history = useHistory();
  const {getFilterLink} = React.useContext(CourseParamsContext);
  const onFilterSelect = value => history.replace(getFilterLink(value));
  const resetFilter = () => history.replace(getFilterLink());

  return (
    <>
      {/* <Button type="secondary" className="clear-filters" onClick={ resetFilter } size="small">
        <Trans>Clear All Filters</Trans>
      </Button> */}
      <h4 className="filter-title">
        <Trans>Choose filter</Trans>
      </h4>
      <SelectList
        size="sm"
        placeholder={i18n.t("Filters")}
        onSelect={onFilterSelect}
        options={filters}
        className="filter-select w-max"
      ></SelectList>
    </>
  );
};

const ExerciseSortList = () => {
  return (
    <div className="flex gap-1">
      <ExerciseSort />
      <div className="vs" />
      <ExerciseFilter />
    </div>
  );
};

export default ExerciseSortList;
