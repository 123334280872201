import React from "react";
import {Button, SubSection, Trans} from "Components";

import GroupSettingsInfoLink from "./GroupSettingsInfoLink";

const GroupSettingsInfoLinks = ({
  initialLinks,
  handleSave,
  handleLinksChange,
}) => {
  const [links, setLinks] = React.useState(initialLinks || []);

  const updateLinks = (index, value) => {
    setLinks(
      links.map((link, i) => {
        if (i === index) {
          return value;
        } else {
          return link;
        }
      })
    );
  };

  const createLink = () => {
    setLinks([
      ...links,
      {
        icon: "fas fa-link",
        title: "",
        url: "",
        color: "",
        imageUrl: "",
      },
    ]);
  };

  const handleRemoveLink = index => {
    const newLinks = [...links].filter((link, i) => i !== index);

    setLinks(newLinks);
    handleLinksChange(newLinks);
  };

  const handleSaveLinks = () => {
    handleLinksChange(links);
  };

  return (
    <SubSection
      title={<Trans>Links</Trans>}
      subTitle={
        <div>
          <Trans>Useful links for your group members.</Trans>
        </div>
      }
    >
      <div>
        {links.length === 0 && (
          <div className="text-center text-slate-60">No links yet.</div>
        )}
        {links.map((link, index) => {
          return (
            <GroupSettingsInfoLink
              link={link}
              handleSave={handleSaveLinks}
              handleRemoveLink={() => handleRemoveLink(index)}
              onChange={value => updateLinks(index, value)}
              hasChanged={
                JSON.stringify(link) !== JSON.stringify(initialLinks[index])
              }
            />
          );
        })}
        <Button onClick={createLink} fluid className="mt-2">
          <Trans>Add new</Trans>
        </Button>
      </div>
    </SubSection>
  );
};

export default GroupSettingsInfoLinks;
