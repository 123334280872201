import React from "react";

import {Alert, Trans} from "Components/index";
import findLastOpenInvoice from "utilities/findLastOpenInvoice";
import {useLocalDate} from "utilities/dates";

const SubscriptionStatusMessageBanner = ({
  invoices,
  status,
  cancelAtPeriodEnd,
  expirationDate,
}) => {
  const lastOpenInvoice = findLastOpenInvoice(invoices);

  switch (status) {
    case "past_due":
    case "unpaid":
      return (
        <Alert
          type="warning"
          className="mt-4"
          title={<Trans>Automatic payment failed</Trans>}
          subTitle={
            <Trans>
              The last payment for your subscription did not succeed. Please
              make sure that you have a valid payment method in your account. If
              the payment is not successful within one week after the invoice
              date, your subscription will automatically be suspended.
            </Trans>
          }
          primaryAction={{
            label: <Trans>Complete payment</Trans>,
            onClick: () => {
              window.open(lastOpenInvoice.hosted_invoice_url, "_blank");
            },
          }}
        />
      );

    case "incomplete":
      return (
        <Alert
          type="warning"
          className="mt-4"
          title={<Trans>Automatic payment failed</Trans>}
          subTitle={
            <Trans>
              The initial payment for your subscription has not been completed.
            </Trans>
          }
          primaryAction={{
            label: <Trans>Complete payment</Trans>,
            onClick: () => {
              window.open(lastOpenInvoice.hosted_invoice_url, "_blank");
            },
          }}
        />
      );

    case "incomplete_expired":
      return (
        <Alert
          type="gray"
          className="mt-4"
          title={<Trans>Automatic payment failed</Trans>}
          subTitle={
            <Trans>
              The initial payment for your subscription was not completed within
              24 hours. Please create a new subscription to continue using
              Kollin Gold.
            </Trans>
          }
        />
      );
  }

  if (cancelAtPeriodEnd) {
    const cancellationDate = useLocalDate(expirationDate, "Do MMMM YYYY");

    return (
      <Alert
        title={<Trans>Your subscription will not renew</Trans>}
        type="warning"
        subTitle={
          <Trans>
            Your subscription will be cancelled on {{cancellationDate}}. You can
            keep on using Kollin Gold until then.
          </Trans>
        }
      />
    );
  }
  return null;
};

export default SubscriptionStatusMessageBanner;
