import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const UPDATE_USER_AVATAR = gql`
  mutation updateCurrentUserAvatar($file: Upload!) {
    updateCurrentUserAvatar(file: $file) {
      ok
      error {
        code
        message
      }
      user {
        id
        avatar
      }
    }
  }
`;

const useUpdateCurrentUserAvatar = onCompleted => {
  const [updateCurrentUserAvatar, { loading, data, errors }] = useMutation(
    UPDATE_USER_AVATAR,
    {
      onCompleted,
    }
  );

  return {
    updateCurrentUserAvatar,
    loading,
    data,
  };
};

export default useUpdateCurrentUserAvatar;
