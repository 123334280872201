import React from "react";
import useUpdateMembershipCancelAtPeriodEnd from "API/mutations/membership/useUpdateMembershipCancelAtPeriodEnd";
import {Button, Trans} from "Components";

const SubscriptionReactivation = () => {
  const {
    updateMembershipCancelAtPeriodEnd,
    loading,
  } = useUpdateMembershipCancelAtPeriodEnd(() => {});

  return (
    <Button
      size="sm"
      disabled={loading}
      icon="arrows-repeat"
      loading={loading}
      hint={<Trans>Reactivate</Trans>}
      onClick={() =>
        updateMembershipCancelAtPeriodEnd({
          variables: {
            cancelAtPeriodEnd: false,
          },
        })
      }
    ></Button>
  );
};

export default SubscriptionReactivation;
