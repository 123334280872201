import React from "react";
import { validateCreateComment } from "utilities/validators";

const useCommentReplyFormState = onNewCommentSubmit => {
  const INITIAL_STATE = {
    text: "",
    loading: false,
    anonymous: false,
    username: "",
  };

  const [state, resetState] = React.useState({ ...INITIAL_STATE });

  // Error state
  const [errors, setErrors] = React.useState({
    text: null,
    username: null,
  });

  const setState = newState =>
    resetState(prevState => ({ ...prevState, ...newState }));

  const toggleAnonymous = () => setState({ anonymous: !state.anonymous });

  const disableSubmit =
    state.text.length === 0 || (state.anonymous && state.username.length === 0);

  const onSubmit = () => {
    if (disableSubmit) {
      return;
    }

    // set is loading to true
    setState({ loading: true });

    // Validate form
    const errors = validateCreateComment(state);

    // If there are errors, set the errors and return
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setState({ loading: false });
      return;
    }

    const callback = () => {
      resetState({ ...INITIAL_STATE });
    };
    // Run the callback
    onNewCommentSubmit(state, callback);
  };

  return {
    ...state,
    setState,
    toggleAnonymous,
    errors,
    disableSubmit,
    onSubmit,
  };
};

export default useCommentReplyFormState;
