import React from "react";
import "./CoursePriceTag.scss";
import useCoursePriceTag from "./useCoursePriceTag";
import {Badge, Tooltip} from "Components";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";

const CoursePriceTag = ({courseId}) => {
  const smallScreens = useBreakPointSM();

  const {type, color, tooltipInfo} = useCoursePriceTag(courseId);

  if (type === "") return null;

  return (
    <Tooltip content={tooltipInfo}>
      <Badge
        size={smallScreens("sm", "md")}
        className="mr-2"
        type="primary"
        style={{
          backgroundColor: `${color}33`,
          color,
        }}
      >
        {type}
      </Badge>
    </Tooltip>
  );
};

export default CoursePriceTag;
