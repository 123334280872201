import React from "react";

import ExerciseEvaluationDifficultyAssessment from "./ExerciseEvaluationDifficultyAssessment/ExerciseEvaluationDifficultyAssessment";
import useExerciseEvaluationTimerState from "./useExerciseEvaluationState/useExerciseEvaluationTimerState";
import {Trans} from "Components";
import useHandleLoginClick from "utilities/hooks/useHandleLoginClick";
import {ExerciseEvaluationContext} from "./ExerciseEvaluation";

const ExerciseEvaluationMock = ({exerciseId}) => {
  const mockEvaluation = {
    timeSpent: 0,
    score: 0,
    completed: false,
    selfReflection: {},
  };

  const timerState = useExerciseEvaluationTimerState(mockEvaluation);

  const state = {completed: false, ...timerState};
  const handleLoginClick = useHandleLoginClick();

  return (
    <ExerciseEvaluationContext.Provider
      value={{...state, disabled: true, exerciseId}}
    >
      <div className="exercise-evaluation mock">
        <p className="login-prompt" onClick={handleLoginClick}>
          <i>
            <Trans>Login to self assess and follow up your progress</Trans>
          </i>
        </p>

        <hr />
        <ExerciseEvaluationDifficultyAssessment />
      </div>
    </ExerciseEvaluationContext.Provider>
  );
};

export default ExerciseEvaluationMock;
