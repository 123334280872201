import React from "react";
import GPTActionsSaveNote from "./GPTActionsSaveNote/GPTActionsSaveNote";
import GPTActionsSaveChapter from "./GPTActionsSaveChapter/GPTActionsSaveChapter";

const GPTActionsSave = ({saveFormat, className}) => {
  if (saveFormat === "note") {
    return <GPTActionsSaveNote className={className} />;
  } else if (saveFormat === "chapter") {
    return <GPTActionsSaveChapter className={className} />;
  }

  return null;
};

export default GPTActionsSave;
