import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {GET_EXERCISE_NOTE} from "API/queries/exerciseNote/useGetExerciseNotes";

const DELETE_EXERCISE_NOTE = gql`
  mutation deleteExerciseNote($id: ID!) {
    deleteExerciseNote(id: $id) {
      ok
      error
    }
  }
`;

const useDeleteExerciseNote = (
  exerciseId,
  callback = () => {},
  refetchQueries = []
) => {
  const [deleteExerciseNote, {loading, data, error}] = useMutation(
    DELETE_EXERCISE_NOTE,
    {
      onCompleted: data => {
        // data.deleteExerciseNote.ok
        // data.deleteExerciseNote.error
        if (data.deleteExerciseNote.ok) {
          callback();
        }
      },
      refetchQueries: [
        {query: GET_EXERCISE_NOTE, variables: {id: exerciseId}},
        ...(refetchQueries ? refetchQueries : []),
      ],
    }
  );

  if (error) {
    console.log("Error in useDeleteExerciseNote: ", error);
  }

  return [deleteExerciseNote, loading];
};

export default useDeleteExerciseNote;
