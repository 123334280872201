import React from "react";
import CourseSidebarRoute from "Components/Sidebar/CourseSidebar/CourseSidebarRoute";

const CourseSidebarMobile = ({routes, url, currentPath, currentRoute}) => {
  if (currentRoute?.hideSidebar) return null;

  return (
    <div className="flex p-2 mb-2  gap-2 items-center overflow-auto touch-pan-x snap-x surface">
      {routes.map((route, i) => {
        return (
          <CourseSidebarRoute
            url={url}
            currentPath={currentPath}
            collapsed={false}
            {...route}
            background="bg-transparent outline-transparent"
          />
        );
      })}
    </div>
  );
};

export default CourseSidebarMobile;
