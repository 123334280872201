import { useMutation } from "@apollo/react-hooks";
import { GET_COURSE_TAGS } from "API/queries/tag/useGetCourseTags";
import gql from "graphql-tag";
import { message } from "Components";

const CREATE_TAG = gql`
  mutation createTag($courseId: ID!, $name: String!) {
    createTag(course_id: $courseId, name: $name) {
      ok
      errors {
        path
        message
      }
      tag {
        id
      }
    }
  }
`;

const useCreateTagMutation = (courseId, exerciseId) => {
  const [createTag, { loading, data, errors }] = useMutation(CREATE_TAG, {
    refetchQueries: () => [
      {
        query: GET_COURSE_TAGS,
        variables: { courseId, exerciseId },
      },
    ],
    onCompleted: data => {
      if (!data.createTag.ok) {
        message.error(data.createTag.errors[0].message);
      }
    },
  });

  if (errors) {
    console.log(`Error in useCreateTagMutation: ${errors}`);
  }

  return [createTag, data, loading];
};

export default useCreateTagMutation;
