import React from "react";
import {Button} from "Components";
import {ExerciseViewContext} from "../ExerciseView";
import Trans from "Components/Trans";

const ExerciseViewToolbarSolutionButton = () => {
  const {toggleSolutionVisible, solutionVisible} = React.useContext(
    ExerciseViewContext
  );
  const text = solutionVisible ? (
    <Trans>Hide solution</Trans>
  ) : (
    <Trans>Show solution</Trans>
  );
  const icon = solutionVisible ? "up" : "down";

  return (
    <Button
      onClick={toggleSolutionVisible}
      type="link"
      size="sm"
      key="exercise-solution-button-component"
      iconRight={`angle-${icon}`}
    >
      {text}
    </Button>
  );
};

export default ExerciseViewToolbarSolutionButton;
