/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      introduction
      groupId
      links {
        title
        url
        icon
        imageUrl
        color
        __typename
      }
      contacts {
        name
        role
        email
        phone
        groupMemberId
        __typename
      }
      feedbackFormId
      posts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        introduction
        groupId
        feedbackFormId
        createdAt
        updatedAt

        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const groupsByGroupId = /* GraphQL */ `
  query GroupsByGroupId(
    $groupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupsByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        introduction
        groupId
        feedbackFormId
        links {
          title
          url
          icon
          imageUrl
          color
          __typename
        }
        contacts {
          name
          role
          email
          phone
          groupMemberId
          __typename
        }
        feedbackFormId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroupPost = /* GraphQL */ `
  query GetGroupPost($id: ID!) {
    getGroupPost(id: $id) {
      id
      title
      groupMemberId
      content
      relatedCourseId
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      groupPostsId
      __typename
    }
  }
`;
export const listGroupPosts = /* GraphQL */ `
  query ListGroupPosts(
    $filter: ModelGroupPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        groupMemberId
        content
        relatedCourseId
        createdAt
        updatedAt
        groupPostsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const groupPostsByRelatedCourseId = /* GraphQL */ `
  query GroupPostsByRelatedCourseId(
    $relatedCourseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupPostsByRelatedCourseId(
      relatedCourseId: $relatedCourseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        groupMemberId
        content
        relatedCourseId
        createdAt
        updatedAt
        groupPostsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      groupMemberId
      createdAt
      updatedAt
      groupPostCommentsId
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        groupMemberId
        createdAt
        updatedAt
        groupPostCommentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
