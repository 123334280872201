import {ErrorBoundary} from "Components";
import PageLoader from "Components/PageLoader";
import CourseSidebar from "Components/Sidebar/CourseSidebar/CourseSidebar";
import React, {Suspense} from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import useStudyPlanRouteHandler from "routes/Course/useStudyPlanRouteHandler";
import useVisitedCourses from "utilities/hooks/useVisitedCourses";
import {CourseContext} from "./CourseContextProvider";
import useCourseParams from "./useCourseParams";
import ProductPurchaseExpiryBanner from "Components/ProductPurchaseExpiryBanner/ProductPurchaseExpiryBanner";
import CourseModalsProvider from "routes/Course/CourseModalsProvider";

/**../../API/queries/exam/CourseExamsData
 *
 * This component works as a subroute for all routes coming after /course/:id
 *
 * routes: A JSON object list with all the routes
 *  - path: the actual url that will determine what is shown
 *  - component: the component to render
 *  - text: this is the text shown in the navbar
 *
 * lastElement: A function to get the last element of a list TODO: Move to utils/library
 *
 * CourseRouter
 * -------------
 * This component is the actual router and determines which route is mounted
 *
 */

export const CourseParamsContext = React.createContext();

const CourseRouter = ({routeList, hideSidebar}) => {
  const location = useLocation();
  const history = useHistory();
  const {url} = useRouteMatch();
  const urlState = useCourseParams();

  // Contexts -------------------------------------------------------------
  const {user} = React.useContext(window.UserContext);
  const course = React.useContext(CourseContext);

  // STATES ---------------------------------------------------------------
  const [routes, setRoutes] = React.useState(routeList);

  const currentPath = location.pathname.split("/")[3];

  // Update the routes in state when the routeList changes
  React.useEffect(() => {
    setRoutes(routeList);
  }, [routeList]);

  // HOOKS ---------------------------------------------------------------
  useVisitedCourses(course.id); // For setting latest courses

  // A handler which adds the study plan route to the routes if there are any studyplans in the course
  useStudyPlanRouteHandler(routes, setRoutes);

  return (
    <>
      {/* This route is used to redirect location / to /home */}
      <Route
        exact
        path={`${url}/`}
        render={() => <Redirect to={`${url}/${routes[0].path}`} />}
      />

      <Suspense fallback={<PageLoader />}>
        <CourseParamsContext.Provider value={urlState}>
          <CourseModalsProvider>
            <CourseSidebar
              routes={routes}
              url={url}
              disableSidebar={hideSidebar}
              // Prevent it from reloading
              key={course.id}
            >
              <Switch>
                {routes.map(({path, component, admin}) => {
                  /*
                   * Render all routes as components and the corresponding path wrapped in the url params context provider
                   */

                  return (
                    <Route
                      exact
                      path={`${url}/${path}`}
                      key={`${url}/${path}`}
                      render={props => {
                        // Check if the route has 'admin' set to true and if the user is an admin
                        if (admin === true && !user?.admin) {
                          return <Redirect to={`${url}/home`} />;
                        }

                        return (
                          <>
                            {currentPath !== "study-plan" && (
                              <ErrorBoundary>
                                <ProductPurchaseExpiryBanner
                                  productType="COURSE"
                                  productId={course.id}
                                />
                              </ErrorBoundary>
                            )}

                            <div>{component}</div>
                          </>
                        );
                      }}
                    />
                  );
                })}
                {/* If the path is not in the routeList, redirect to /home */}
                <Route
                  render={() => {
                    // message.error(
                    //   <Trans>
                    //     The page you are looking for does not exist.
                    //   </Trans>
                    // );
                    history.replace(`${url}/home`);
                  }}
                />
              </Switch>
            </CourseSidebar>
          </CourseModalsProvider>
        </CourseParamsContext.Provider>
      </Suspense>
    </>
  );
};

export default CourseRouter;
