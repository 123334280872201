import React from "react";
import classnames from "classnames";
import {Button, Input, Trans} from "Components";

const OTPEmailInput = ({
  inlineForm,
  inputHint,
  inputPlaceholder,
  values,
  handleChange,
  submit,
  errors,
  loading,
  requestSuccessful,
  showModal,
  transparent,
}) => {
  // When request is successful, the email input disappears and the code input (Sibling) will appear
  // However, if the code input is a modal, we don't want to hide the email input

  if (requestSuccessful && !showModal) return null;

  return (
    <form
      onSubmit={submit}
      className={classnames(
        "mt-4 flex gap-2 items-start",
        inlineForm ? "flex-row" : "flex-col"
      )}
    >
      <Input
        type="email"
        name="email"
        placeholder={inputPlaceholder}
        onChange={handleChange}
        error={errors.email}
        errorMessageRelative={true}
        required={true}
        fluid={!inlineForm}
        hint={inputHint}
        transparent={transparent}
        value={values.email}
      />

      <Button
        disabled={values.email === ""}
        loading={loading}
        type="primary"
        className={!inlineForm && "mt-2"} // extra margin if not inline
        size="md"
        fluid={!inlineForm}
        onClick={submit}
      >
        <Trans>Send</Trans>
      </Button>
    </form>
  );
};

export default OTPEmailInput;
