import React from "react";
import {AnimateSharedLayout, motion} from "framer-motion";
import classnames from "classnames";
import {Button, Trans, Typography} from "Components";
import moment from "moment";
import {range} from "utilities/numbers";
import {OnboardingContext} from "routes/Onboarding/Onboarding";

const OnboardingEnrollmentYear = ({
  state = null,
  size = "lg",
  layoutClasses = "grid grid-cols-1 @md:grid-cols-2 gap-4",
  outlineSize = "outline-4",
  rangeSize = 5,
}) => {
  const onboardingContext = React.useContext(OnboardingContext);

  const {values, setFormValue} = onboardingContext?.state || state;

  const [rangeLimit, setRangeLimit] = React.useState(5);

  const years = range(rangeLimit, rangeLimit - rangeSize).map(i =>
    moment()
      .subtract(i, "years")
      .format("YYYY")
  );

  return (
    <div>
      <div className={layoutClasses}>
        <AnimateSharedLayout>
          {years.map((year, index) => {
            const selected = values.enrollmentYear === year;
            return (
              <motion.div
                initial={{opacity: 0, y: 10}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: 10}}
                transition={{delay: index * 0.1}}
                key={year}
                className={classnames(
                  "relative flex justify-center gap-2 items-center border-2 border-gray rounded-lg cursor-pointer",
                  size === "lg" ? " p-4" : " p-3",
                  size === "lg" ? "w-full" : "w-fit"
                )}
                onClick={() => setFormValue({enrollmentYear: year})}
              >
                <Typography type={"t" + size} weight="md" color="text-gray">
                  {year}
                </Typography>
                {selected && (
                  <motion.div
                    className={classnames(
                      "absolute inset-0 w-full h-full outline outline-primary rounded-lg",
                      outlineSize
                    )}
                    layoutId="outline"
                  />
                )}
              </motion.div>
            );
          })}
        </AnimateSharedLayout>
      </div>
      <div className="mt-2 flex gap-2">
        <Button
          onClick={() => setRangeLimit(rangeLimit + rangeSize)}
          iconLeft="chevron-down"
          size={size}
        >
          <Trans>Show earlier years</Trans>
        </Button>
        {rangeLimit > 5 && (
          <Button
            onClick={() => setRangeLimit(rangeLimit - rangeSize)}
            iconLeft="chevron-up"
            size={size}
          >
            <Trans>Show later years</Trans>
          </Button>
        )}
      </div>
    </div>
  );
};

export default OnboardingEnrollmentYear;
