import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

export const COURSE_AUTO_MARKING_LOGS = gql`
  query getAutoMarkingLogs(
    $courseId: Int
    $segmentType: String
    $documentId: ID
  ) {
    autoMarkingLogs(
      courseId: $courseId
      segmentType: $segmentType
      documentId: $documentId
    ) {
      id
      documentId
      segmentType
      settings
      segmentsCount
      segmentPattern
      createdAt
    }
  }
`;

const useGetAutoMarkingLogs = ({courseId, segmentType, documentId}) => {
  const {loading, error, data} = useQuery(COURSE_AUTO_MARKING_LOGS, {
    variables: {
      courseId,
      documentId,
      segmentType,
    },
  });
  React.useEffect(() => {
    if (error) {
      console.log(`Error in useGetAutoMarkingLogs! ${error.message}`);
    }
  }, [error]);

  let autoMarkingLogs = data?.autoMarkingLogs || [];

  return [autoMarkingLogs, loading];
};

export default useGetAutoMarkingLogs;
