export const schools = {
  "2": {
    id: "2",
    name: "Chalmers tekniska högskola",
    nickname: "Chalmers",
    coursesCount: 22,
    logo: "/school-logos/2.png",
  },
  "4": {
    id: "4",
    name: "Stockholms universitet",
    nickname: "SU",
    coursesCount: 7,
    logo: "/school-logos/4.png",
  },
  "5": {
    id: "5",
    name: "Uppsala universitet",
    nickname: "UU",
    coursesCount: 16,
    logo: "/school-logos/5.png",
  },
  "6": {
    id: "6",
    name: "Lunds universitet",
    nickname: "LU",
    coursesCount: 14,
    logo: "/school-logos/6.png",
  },
  "7": {
    id: "7",
    name: "Linköpings universitet",
    nickname: "LiU",
    coursesCount: 49,
    logo: "/school-logos/7.png",
  },
  "11": {
    id: "11",
    name: "Luleå tekniska universitet",
    nickname: "LTU",
    coursesCount: 2,
    logo: "/school-logos/11.png",
  },
  "18": {
    id: "18",
    name: "Gymnasium",
    nickname: "Harvard",
    coursesCount: 7,
    logo: "/school-logos/18.png",
  },
  "239": {
    id: "239",
    name: "Jönköping University",
    nickname: "HJ",
    coursesCount: 1,
    logo: "/school-logos/239.png",
  },
  "537": {
    id: "537",
    name: "Kungliga Tekniska högskolan",
    nickname: "KTH",
    coursesCount: 390,
    logo: "/school-logos/537.png",
  },
  "10": {
    id: "10",
    name: "Handelshögskolan i Stockholm",
    nickname: "HHS",
    coursesCount: 2,
    logo: "/school-logos/10.svg",
  },
};

export const sizeMap = {
  xs: "w-4 md:w-6",
  sm: "w-6 md:w-8",
  md: "w-10 md:w-12",
  lg: "w-14 md:w-16",
  xl: "w-18 md:w-20",
  "2xl": "w-22 md:w-24",
  "3xl": "w-30 md:w-32",
};
