import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

export const GET_COURSE_THREADS = gql`
  query getCourseThreads(
    $courseId: ID!
    $limit: Int!
    $offset: Int!
    $includeExercise: Boolean!
    $includeReplies: Boolean!
  ) {
    getCourseThreads(course_id: $courseId, offset: $offset, limit: $limit) {
      id
      created_at
      solved
      comments_count
      comments {
        id
        html
        created_at
        asker {
          id
          avatar
          first_name
          firstName
          lastName
          last_name
          admin
          assistant
          premium
          enrollmentYear
          program {
            id
            name
          }
        }
        comments @include(if: $includeReplies) {
          id
          html
          created_at
          asker {
            id
            avatar
            firstName
            lastName
            admin
            assistant
            premium
            enrollmentYear
            program {
              id
              name
            }
          }
        }
      }
      exercise @include(if: $includeExercise) {
        id
        number
        score
        imageUrl
        source {
          id
          date
          course {
            id
            name
            code
          }
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

const useGetCourseThreads = (
  limit,
  options = {includeExercise: true, includeReplies: true, courseId: null}
) => {
  // const courseContext = React.useContext(CourseContext);
  // console.log("hejcourseContext", courseContext);
  console.log("options", options.courseId);

  const {data, loading, error, fetchMore, refetch} = useQuery(
    GET_COURSE_THREADS,
    {
      variables: {
        offset: 0,
        limit: limit || 6,
        ...options,
        courseId: options.courseId,
      },
      context: {
        batch: true,
      },
    }
  );

  if (error) {
    console.log(`Error in useGetCourseThreads: ${error}`);
  }

  // A function
  const loadMore = offset =>
    fetchMore({
      variables: {
        offset: data.getCourseThreads.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          getCourseThreads: [
            ...prev.getCourseThreads,
            ...fetchMoreResult.getCourseThreads,
          ],
        });
      },
    });

  let threads = [];

  if (data) {
    threads = data.getCourseThreads;
  }

  return {threads, loading, loadMore, refetch};
};

export default useGetCourseThreads;
