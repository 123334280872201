import React from "react";
import useGetLandingPageSections from "API/cms/landingPage/useGetLandingPageSections";
import LANDING_PAGES_CONTENFUL_IDS from "utilities/constants/LANDING_PAGES_CONTENFUL_IDS";
import Summer2022CampaignFeatures from "routes/Campaign/Summer2022Campaign/Summer2022CampaignFeatures";

const LandingFeaturesSection = () => {
  const [
    landingPageSections,
    landingPageSectionsLoading,
  ] = useGetLandingPageSections(
    LANDING_PAGES_CONTENFUL_IDS["campaignSummer2022"]
  );

  if (landingPageSectionsLoading) return null;

  return (
    <Summer2022CampaignFeatures landingPageSections={landingPageSections} />
  );
};

export default LandingFeaturesSection;
