import React from "react";
import useGetCurrentUsersExerciseNotesInCourse from "API/queries/exerciseNote/useGetCurrentUsersExerciseNotesInCourse";

const useCourseKollinGptSavedNotes = () => {
  const {courseId} = React.useContext(window.CourseContext);
  const [allNotes, loading] = useGetCurrentUsersExerciseNotesInCourse(courseId);

  const notes = React.useMemo(() => {
    if (loading) return [];

    let gptNotes = allNotes.filter(
      ({text}) =>
        text.includes("# Svar från Chat-GPT") ||
        text.includes("# Svar från Kollin-GPT")
    );
    return gptNotes.map(note => ({
      ...note,
      ...getNoteTextContent(note.text),
    }));
  }, [loading]);

  return [notes, loading];
};

const getNoteTextContent = text => {
  let lines = text.split("\n");
  let textContent = lines.filter(line => !line.startsWith("# ") && line !== "");

  textContent = textContent.map(line => line.replace(/#/g, ""));
  const title = textContent[0];
  const body = textContent.slice(1).join("\n");

  return {
    title,
    body,
  };
};

export default useCourseKollinGptSavedNotes;
