import React from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Icon, Trans} from "Components";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";

const GroupInvitationHeader = ({invite, openInvitation, prompt = null}) => {
  const {name, imageUrl, membersCount, members} = useGroupContext();
  const referrerId = new URLSearchParams(window.location.search).get("r");
  const {t: trans} = useTranslation();

  let invitedBy = null;

  invitedBy =
    invite?.invitedBy ||
    members?.find(member => member.id === referrerId)?.user ||
    null;

  const inviterName = invitedBy ? invitedBy?.firstName : null;

  return (
    <div className="p-4 mb-8 bg-violet-100 flex gap-4 rounded-lg justify-between items-start">
      <div className="flex gap-2 items-start">
        <Avatar
          // user={invitedBy?.avatar || null}
          src={invitedBy?.avatar || imageUrl}
          size="lg"
        />
        <div>
          <Title
            inviterName={inviterName}
            name={name}
            membersCount={membersCount}
          />
          {prompt}
        </div>
      </div>
      <div className="mt-1">
        <Icon icon="envelope-open-text" color="text-violet-600" size="xl" />
      </div>
    </div>
  );
};

const Title = ({inviterName, openInvitation, name, membersCount}) => {
  let classes = {
    type: "tlg",
    weight: "md",
    color: "text-violet-800",
  };

  if (inviterName) {
    if (membersCount < 10) {
      return (
        <Trans inviterName={inviterName} name={name} {...classes}>
          Join {{inviterName}} and others in {{name}} on Kollin
        </Trans>
      );
    }
    return (
      <Trans
        inviterName={inviterName}
        membersCount={membersCount}
        name={name}
        {...classes}
      >
        Join {{inviterName}} and {{membersCount}} others in {{name}} on Kollin
      </Trans>
    );
  }

  return (
    <Trans name={name} membersCount={membersCount} {...classes}>
      Join {{membersCount}} members in {{name}} on Kollin
    </Trans>
  );
};

export default GroupInvitationHeader;
