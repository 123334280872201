import React from "react";
import useGetReactions from "API/queries/reaction/useGetReactions";
import {Emoji} from "emoji-mart";
import {Typography} from "Components";
import "./ReactionsList.css";
import classnames from "classnames";

const ReactionsList = ({parentId, parentType, size, onClick}) => {
  const {user} = React.useContext(window.UserContext);
  const {nReactions, distribution, loading} = useGetReactions({
    parentId,
    parentType,
  });

  if (loading) return null;
  if (nReactions === 0) return null;

  return (
    <div className="flex items-center gap-2 flex-wrap">
      {Object.keys(distribution).map((keys, index) => {
        const reactions = distribution[keys];
        let total = reactions.length;
        let emoji = reactions[0].name;

        // check if the reaction is by the current user
        const currentUsersReaction = reactions.some(
          reaction => reaction.user.id === user.id
        );

        // TODO: add tooltip with all names
        let names = distribution[keys].map(reaction => (
          <span className="my-0" key={index}>
            {reaction.user.first_name}
          </span>
        ));

        return (
          <div
            key={emoji}
            className={classnames(
              "flex items-center px-4  py-px border-1 rounded-full cursor-pointer hover:surface-primary shadow-sm",
              currentUsersReaction
                ? "bg-primary-50 border-primary-500"
                : "background border-gray-200"
            )}
            onClick={() => onClick(emoji)}
          >
            <div className="h-full pt-1">
              <Emoji
                set="apple"
                emoji={emoji}
                size={size}
                style={{height: size, paddingBottom: 5}}
              />
            </div>
            {total > 1 && (
              <Typography type="tsm" className="ml-2" weight="sb">
                {total}
              </Typography>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReactionsList;
