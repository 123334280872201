const parseEnrollmentYearToInt = (enrollmentYear) => {
    let parsedEnrollmentYear = enrollmentYear;

    try {
        if (typeof enrollmentYear === "string") {
            parsedEnrollmentYear = parseInt(enrollmentYear);
        }
    } catch (e) {
        // Default to 2020 if the enrollment year is not a number
        // Only runs if the enrollment year is a string and was not parsed
        parsedEnrollmentYear = 2022;
    }

    return parsedEnrollmentYear;
}

export default parseEnrollmentYearToInt;