import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {motion} from "framer-motion";

const Key = ({children, className, animate, inverted = false}) => {
  return (
    <motion.span
      animate={animate ? {scale: [1, 1.1, 1]} : {}}
      transition={{duration: 3, repeatType: "mirror", repeat: Infinity}}
      className={classnames(
        "border-1 px-1.5 py-0.5 rounded mx-1 font-mono font-medium text-body6 inline-block justify-center items-center shadow-sm",
        inverted
          ? "bg-gray-600 text-white border-gray-50"
          : "bg-gray-25 border-gray-500 dark:border-gray-25",
        className
      )}
    >
      <kbd>{children}</kbd>
    </motion.span>
  );
};

Key.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Key;
