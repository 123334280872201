import React from "react";
import {ErrorBoundary, Typography} from "Components";
import SafeText from "Components/_Elements/SafeText/SafeText";
import classnames from "classnames";
import {getTextFromElement} from "Components/_Elements/HighlightedText/HighlightedText";

const TableHeader = ({
  columnsTransposed,
  columnsCount,
  rowClassNames,
  cellClassNames,
  showAsCard,
}) => {
  const cellTextProps = showAsCard
    ? {
        type: "txs",
        weight: "md",
        color: "text-gray-light",
      }
    : {
        type: "tsm",
        weight: "md",
        color: "text-gray",
      };

  return (
    <thead
      className={classnames(
        "table-header-group",
        showAsCard
          ? "surface border-y-2 border-gray"
          : "background rounded-t-lg border-b-2 border-gray"
      )}
    >
      <tr key="header" className={classnames(rowClassNames)}>
        {Object.values(columnsTransposed).map(column => {
          const colHeaderText = getTextFromElement(column.title);
          return (
            <td
              key={column.key}
              className={classnames(
                cellClassNames,
                showAsCard ? "py-2" : "py-4"
              )}
              colSpan={column.colSpan || 1}
            >
              <ErrorBoundary fallbackComponent={<span>{column.title}</span>}>
                <Typography {...cellTextProps}>{colHeaderText}</Typography>
              </ErrorBoundary>
            </td>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
