import React from "react";
import {PromptWrapper} from "routes/Course/ExamWizard/ExamView/ExamMarking/ExamMarkingSegmentActions/ExamMarkingSegmentActionsPrompt";
import {Trans} from "Components";

const BuyCreditsPrompt = ({selectedBundle, createOrderLoading}) => {
  return null;

  if (!selectedBundle) {
    return (
      <PromptWrapper pointerDir="down" size="sm">
        <Trans>Please select a bundle to buy credits</Trans>
      </PromptWrapper>
    );
  }

  return null;
};

export default BuyCreditsPrompt;
