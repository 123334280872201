export default ({ data, sortOrder, sortField }) => {
  const optionsWithDefault = data.map(item => {
    return ({
      ...item,
      sortLabel: sortOrder.includes(item.label) ? item.label : "other",
    })
  });


  const sortByObject = sortOrder.reduce(
    (obj, item, index) => ({
      ...obj,
      [item]: index,
    }),
    {}
  );

  return optionsWithDefault.sort(
    (a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]]
  );
};
