import React from "react";
import { Badge, Icon, Maybe, Trans, Typography } from "Components";
import { CourseLandingContext } from "routes/Course/CourseLanding/useCourseLandingContext";

const CourseLandingTopicsModuleTag = ({ tag }) => {
  const { course } = React.useContext(CourseLandingContext);
  // const [tag, loading] = useGetTag(tagId, false, true, false);
  const loading = false;

  const videosCount = React.useMemo(() => {
    if (!loading) {
      return tag.chapters?.filter(chapter => chapter.chapter_type == "Video")
        .length;
    }
    return null;
  }, [loading]);
  if (loading) return null;

  return (
    <div className="my-2">
      <Typography type="tsm" weight="md" className="truncate">
        {tag.name}
      </Typography>
      <div className="mt-2 flex gap-2 items-center">
        <Maybe dep={tag.chapters?.length}>
          <Badge type="purple" size="sm">
            <Icon
              icon="fa-light fa-book-open fa-sm mr-1"
              className="mr-1"
              color="text-purple-700"
            />
            <Typography color="text-purple-700" className="mr-1" type="txs">
              {tag.chapters?.length}
            </Typography>

            <Trans color="text-purple-700" type="txs">
              Chapters
            </Trans>
          </Badge>
        </Maybe>
        <Maybe dep={tag.exercises?.length}>
          <Badge type="purple" size="sm">
            <Icon
              icon="fa-light fa-pen fa-sm mr-1"
              className="mr-1"
              color="text-purple-700"
            />
            <Typography color="text-purple-700" className="mr-1" type="txs">
              {tag.exercises?.length}
            </Typography>

            <Trans color="text-purple-700" type="txs">
              Exercises
            </Trans>
          </Badge>
        </Maybe>
        <Maybe dep={videosCount}>
          <Badge type="purple" size="sm">
            <Icon
              icon="fa-light fa-film"
              className="mr-1"
              color="text-purple-700"
            />
            <Typography color="text-purple-700" className="mr-1" type="txs">
              {videosCount}
            </Typography>

            <Trans color="text-purple-700" type="txs">
              Videos
            </Trans>
          </Badge>
        </Maybe>
      </div>
    </div>
  );
};

export default CourseLandingTopicsModuleTag;
