export const getPriceWithAccessDays = (prices, accessDays) => {
  let price = prices.find(p => p.accessDays == accessDays);

  // If the price could not be found, return the first one
  if (!price) {
    price = prices[0];
  }

  return {
    ...price,
    // Get the discounted price also so it does not need to be calculated later
    discountedPrice: getPriceAfterDiscount(
      price.priceCents,
      price.discountPercentage
    ),
  };
};

export const getPriceAfterDiscount = (priceCents, discountPercentage) =>
  priceCents - priceCents * (discountPercentage / 100);

export const setProductsSelectedPrices = (products, accessDays) =>
  products?.map(product => ({
    ...product,
    // Default price is the price with 360 days of access (1 year)
    defaultPrice: product.prices.find(p => p.accessDays == 360),
    selectedPrice: getPriceWithAccessDays(product.prices, accessDays), // The selected price is the price with the access days that the user has selected
  }));
