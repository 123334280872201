import React from "react";
import GroupMembershipVerification from "Widgets/Group/GroupMembershipVerification/GroupMembershipVerification";
import {Modal} from "Components";

const GroupMembershipVerificationModal = ({onClose}) => {
  return (
    <Modal
      onClose={onClose}
      defaultOpen={true}
      maskClosable={true}
      escapeClosable={true}
      className="bg-primary-800"
    >
      <GroupMembershipVerification size="lg" />
    </Modal>
  );
};

export default GroupMembershipVerificationModal;
