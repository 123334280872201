import React from "react";

import {Table} from "Components";

import useGetGroupMembers from "API/queries/groups/useGetGroupMembers";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import useGroupMembersColumns from "./useGroupMembersColumns";

const GroupMembersList = ({groupId}) => {
  const {currentUserIsAdmin} = useGroupContext();
  const [allMembers, groupDetails, loading] = useGetGroupMembers({
    id: groupId,
  });

  const {getFullColumns} = useGroupMembersColumns({
    groupId,
    groupDetails,
  });

  // Sort the members and prioritize those with avatars
  const members = [...allMembers].sort((a, b) =>
    a.user.avatar === "" ||
    a.user.avatar === null ||
    a.user.avatar?.match(/default.png/gi)
      ? 1
      : -1
  );

  return (
    <Table
      key={groupId}
      data={members}
      columns={getFullColumns()}
      loading={loading}
    />
  );
};

export default GroupMembersList;
