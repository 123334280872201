// Implemented sizes: sm, lg, 2xl and md (default)
export const sizesMap = {
  xs: "px-2 py-1 rounded-md ",
  sm: "px-3 py-2 rounded-md ",
  md: "px-4 py-2.5 rounded-lg ",
  lg: "px-4.5 py-2.5 rounded-lg ",
  xl: "px-5 py-3 rounded-xl",
  "2xl": "px-5 py-4 rounded-xl",
};

export const textTypeMapping = {
  xs: "txs",
  sm: "tsm",
  md: "tmd",
  lg: "tmd",
  xl: "tlg",
  "2xl": "dsm",
};
