import React from "react";
import useUpdateGroupMember from "API/mutations/groups/useUpdateGroupMember";
import {GET_GROUP} from "API/queries/groups/useGetGroup";
import client from "client";
import {message} from "Components";

const useGroupAdminUploadMembersValidate = ({group}) => {
  const {updateGroupMember, loading} = useUpdateGroupMember({
    showMessages: false,
  });
  const [bulkValidateLoading, setBulkValidateLoading] = React.useState(false);

  const bulkValidate = async members => {
    // Set loading state
    setBulkValidateLoading(true);

    // loop through members and validate them
    for (let i = 0; i < members.length; i++) {
      const member = members[i];

      await updateGroupMember({
        variables: {
          groupId: group.id,
          groupMemberId: member.id,
          verified: true,
        },
      });
    }

    // Refetch group members
    await client.query({
      query: GET_GROUP,
      fetchPolicy: "network-only",
      variables: {
        id: group.id,
        includeMembers: true,
        includeSubGroups: true,
      },
    });

    message.success("Alla medlemmar har verifierts");
    // Set loading state
    setBulkValidateLoading(false);
  };

  return {
    bulkValidate,
    bulkValidateLoading,
  };
};

export default useGroupAdminUploadMembersValidate;
