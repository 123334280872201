import React from "react";

const useExamMarkingContainerState = () => {
  const [container, setContainer] = React.useState({ current: null });
  const [initialWidth, setInitialWidth] = React.useState(null);

  const containerRef = React.useCallback(node => {
    if (node !== null) {
      setContainer({
        current: node,
      });

      setInitialWidth(node.offsetWidth);
    }
  }, []);

  return {
    container,
    containerRef,
    initialWidth,
  };
};

export default useExamMarkingContainerState;
