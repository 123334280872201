import {LAMBDA_BASE_URL} from "API/lambdas/index";

const autoSegmentation = async ({
  docUrl,
  segment,
  containerWidth,
  documentType,
  courseId,
  sourceId,
  debug = true,
  commit = false,
}) => {
  const args = {
    sourceId,
    courseId,
    debug: true,
    ...(commit ? {commit: true} : {}),
  };

  const urlArgs = Object.keys(args)
    .map(key => `${key}=${args[key]}`)
    .join("&");

  // https://api.tntor.se/lambdas/auto-segmentation-dev-main?sourceId=6517&courseId=316&debug=true
  const response = await fetch(
    `${LAMBDA_BASE_URL}/auto-segmentation-dev-main?${urlArgs}`,
    {
      method: segment ? "POST" : "GET",
      headers: {
        "Content-Type": "application/json",
      },

      body: segment
        ? JSON.stringify({
            docUrl,
            segment,
            containerWidth,
            docType: documentType,
            courseId,
            sourceId,
            debug,
            commit,
          })
        : null,
    }
  );

  const data = await response.json();

  if (response.status !== 200) {
    console.log(response);
    return null;
  }

  return data;
};

export default autoSegmentation;
