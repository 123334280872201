import React from "react";
import useSendStudentEmailVerificationLink from "API/mutations/user/useSendStudentEmailVerificationLink";
import {useFormValidation} from "utilities/hooks/useFormValidation";
import {validateEmail} from "utilities/validators";
import {useTranslation} from "react-i18next";

const UseAccountCreditsStudentVerificationState = () => {
  const {t: trans} = useTranslation();

  const [emailSent, setEmailSent] = React.useState(false);

  const {
    sendStudentVerificationLink,
    loading,
    data,
  } = useSendStudentEmailVerificationLink();

  const state = useFormValidation(
    {
      email: "",
    },
    sendStudentVerificationLink,
    validateEmail
  );

  React.useEffect(() => {
    if (data) {
      if (data.sendStudentVerificationLink.ok) {
        setEmailSent(true);
      } else if (data.sendStudentVerificationLink.error) {
        state.setErrors({email: data.sendStudentVerificationLink.error});
      } else {
        state.setErrors({
          email: trans(
            "An unexpected error has occurred, please try again later"
          ),
        });
      }
    }
  }, [data]);

  return {
    ...state,
    loading,
    emailSent,
  };
};

export default UseAccountCreditsStudentVerificationState;
