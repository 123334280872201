import React from "react";
import {Typography} from "Components";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import classnames from "classnames";

const GroupOnboardingSubGroups = ({
  selectedSubGroupId,
  selectedProgramId,
  handleSubGroupChange,
}) => {
  const {id, subGroups} = useGroupContext();

  const handleClick = subGroup => {
    handleSubGroupChange(subGroup.id, subGroup.program?.programId);
  };

  return (
    <div className="flex gap-4 items-center flex-wrap">
      {subGroups.map((subGroup, index) => {
        const isSelected = selectedSubGroupId === subGroup.id;
        return (
          <div
            className={classnames(
              "flex items-center gap-4 px-4 py-3 border-2 border-gray rounded-lg mb-2 last:mb-0 cursor-pointer hover:surface",
              isSelected && "border-primary surface-primary",
              selectedSubGroupId !== null &&
                !isSelected &&
                "opacity-70 hover:opacity-100"
            )}
            onClick={() => handleClick(subGroup)}
            key={index}
          >
            <img src={subGroup.imageUrl} className="w-12" />
            <Typography
              type="tmd"
              weight="sb"
              color={isSelected ? "text-primary-light" : "text-gray-light"}
            >
              {subGroup.name}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default GroupOnboardingSubGroups;
