import React from "react";

import {UserContext} from "UserContextProvider";
import ExerciseEvaluationDifficultyAssessment from "./ExerciseEvaluationDifficultyAssessment/ExerciseEvaluationDifficultyAssessment";
import ExerciseEvaluationMock from "./ExerciseEvaluationMock";
import ExerciseEvaluationTimer from "./ExerciseEvaluationTimer/ExerciseEvaluationTimer";
import useExerciseEvaluationState from "./useExerciseEvaluationState/useExerciseEvaluationState";
import useGetExerciseEvaluation from "./useGetExerciseEvaluation";

import "./ExerciseEvaluation.scss";
import {Button, ButtonGroup, TimerDisplay, Trans} from "Components";
import ErrorBoundary from "../../../Components/ErrorBoundary/ErrorBoundary";
import WorkboardWidget from "Components/Workboard/WorkboardWidgets/WorkboardWidget";
import ExerciseEvaluationDifficultyAssessmentButton from "Widgets/Exercise/ExerciseEvaluation/ExerciseEvaluationDifficultyAssessment/ExerciseEvaluationDifficultyAssessmentButton";

export const ExerciseEvaluationContext = React.createContext(null);

const ExerciseEvaluationContainer = ({
  exerciseId,
  exerciseEvaluationRefetchQueries,
}) => {
  const {user} = React.useContext(UserContext);
  const userEvaluation = useGetExerciseEvaluation(exerciseId, "Workboard");

  if (userEvaluation && userEvaluation.objectId == exerciseId) {
    return (
      <ExerciseEvaluation
        userEvaluation={userEvaluation}
        exerciseId={exerciseId}
        key={"exercise-evaluation-panel-" + exerciseId}
        exerciseEvaluationRefetchQueries={exerciseEvaluationRefetchQueries}
      />
    );
  }

  // For logged out user - show disabled content
  if (!user?.id) {
    return (
      <ExerciseEvaluationMock
        disabled={true}
        userEvaluation={null}
        exerciseId={exerciseId}
        key={"exercise-evaluation-panel-" + exerciseId}
      />
    );
  }

  // Need to ensure that a userEvaluation object exists before returning the main component
  return (
    <div>
      <WorkboardWidget title={<Trans>Timer</Trans>} icon="stopwatch">
        <div className="flex items-center justify-between py-2">
          <TimerDisplay timeSpent={0} textType="txl" loading={true} />
          <Button disabled onClick={() => {}}>
            <Trans>Start timer</Trans>
          </Button>
        </div>
      </WorkboardWidget>

      <WorkboardWidget title={<Trans>Assessment</Trans>} icon="gauge">
        <div className="mt-4 w-full opacity-50">
          {/*
              Mock buttons for logged out users or loading state
          */}

          <ButtonGroup className="mt-2" fluid>
            {[1, 2, 3, 4].map((score, idx) => (
              <ExerciseEvaluationDifficultyAssessmentButton
                type={"gray"}
                key={idx}
                onClick={() => {}}
                score={score}
              />
            ))}
          </ButtonGroup>
        </div>
      </WorkboardWidget>
    </div>
  );
};

const ExerciseEvaluation = ({
  userEvaluation,
  exerciseId,
  exerciseEvaluationRefetchQueries,
}) => {
  const state = useExerciseEvaluationState(userEvaluation);

  return (
    <ErrorBoundary
      showErrorMessage
      errorMessage={<Trans>Exercise evaluation is not working right now</Trans>}
    >
      <ExerciseEvaluationContext.Provider
        value={{...state, exerciseId, exerciseEvaluationRefetchQueries}}
      >
        {/*<div className="exercise-evaluation">*/}
        <WorkboardWidget title={<Trans>Timer</Trans>} icon="stopwatch">
          <ExerciseEvaluationTimer />
        </WorkboardWidget>

        <WorkboardWidget title={<Trans>Assessment</Trans>} icon="gauge">
          <ExerciseEvaluationDifficultyAssessment />
        </WorkboardWidget>
        {/*<ExerciseEvaluationCompletedButton />*/}

        {state.completed && (
          <>{/*<hr /> <ExerciseEvaluationSelfReflection />{" "}*/}</>
        )}
        {/*</div>*/}
      </ExerciseEvaluationContext.Provider>
    </ErrorBoundary>
  );
};

export default ExerciseEvaluationContainer;
