import React from "react";
import {Avatar, Typography} from "../../../Components";
import GroupAdminUploadMemberVerifiedToggle from "../GroupAdminUpload/GroupAdminUploadMemberVerifiedToggle";
import {useGroupContext} from "../../../Providers/GroupProvider/GroupProvider";

const useGroupMembersColumns = ({groupId, groupDetails}) => {
  const {showSubGroup, subGroupType} = groupDetails;
  const {currentUserIsAdmin} = useGroupContext();

  const baseColumns = [
    {
      key: "member",
      dataIndex: "user",
      title: "Medlem",
      colSpan: 2,
      render: user => {
        return (
          <div className="flex gap-2 items-center py-1">
            <Avatar user={user} alt={user.firstName} size="md" />
            <Typography className="ml-2" type="tmd" weight="md">
              {user.firstName + " " + (user.lastName || "")}
            </Typography>
          </div>
        );
      },
    },
    {
      key: "enrollmentYear",
      dataIndex: "user.enrollmentYear",
      title: "Startår",
      render: enrollmentYear => {
        return <Typography>{enrollmentYear}</Typography>;
      },
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "Joinat sedan",
      render: createdAt => {
        // TODO: return the field createdAt in swedish format get the first 10 characters of the string
        return <Typography>{createdAt.slice(0, 10)}</Typography>;
      },
    },
  ];

  const verifiedColumn = {
    key: "Status",
    dataIndex: "verified",
    title: "Status", // TODO: translate "Status
    render: (verified, member) => {
      if (!member.user) return null;
      return (
        <GroupAdminUploadMemberVerifiedToggle
          verified={verified}
          groupId={groupId}
          memberId={member.id}
        />
      );
    },
  };

  const getSubGroupColumns = subGroupType => {
    return {
      key: "subGroupName",
      dataIndex: "subGroupName",
      title: subGroupType,
      render: subGroupName => {
        return (
          <Typography type="tmd" weight="md">
            {subGroupName}
          </Typography>
        );
      },
    };
  };

  const getFullColumns = () => {
    let fullColumns = baseColumns;
    if (showSubGroup === true) {
      fullColumns = [...baseColumns, getSubGroupColumns(subGroupType)];
    }
    if (currentUserIsAdmin) {
      fullColumns = [...fullColumns, verifiedColumn];
    }
    return fullColumns;
  };

  return {getFullColumns};
};

export default useGroupMembersColumns;
