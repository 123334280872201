import { snakeToCamel } from "./text";

const normalizeErrors = errors => {
  let res = {};
  errors.map(({ path, message }) => {
    res[snakeToCamel(path)] = message;
  });
  return res;
};

export default normalizeErrors;
