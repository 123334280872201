import React from "react";
import {Button, Trans} from "Components";
import {OnboardingContext} from "routes/Onboarding/Onboarding";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";
import ONBOARDING_STEPS from "routes/Onboarding/OnboardingStepsList";
import classnames from "classnames";

const OnboardingLayoutActions = ({stacked}) => {
  const {
    onNextStepClick,
    nextStepButtonDisabled,
    currentStepIndex,
    onSkipClick,
  } = React.useContext(OnboardingContext);
  const smallScreens = useBreakPointSM();

  return (
    <div className="w-full sticky md:relative bottom-0 background shadow-t-sm md:shadow-none ">
      <div
        className={classnames(
          "mx-auto p-4 md:p-0 md:mt-8 flex gap-3 justify-end ",
          stacked ? "w-full" : "w-4/5"
        )}
      >
        {currentStepIndex > 0 && (
          <Button
            onClick={onSkipClick}
            size={smallScreens("sm", "xl")}
            type="link"
          >
            <Trans>Skip</Trans>
          </Button>
        )}
        <Button
          onClick={onNextStepClick}
          type="primary"
          size={smallScreens("sm", "xl")}
          color="primary"
          iconRight="arrow-right"
          className={!nextStepButtonDisabled && "animate-pulse"}
          disabled={nextStepButtonDisabled}
        >
          {ONBOARDING_STEPS[currentStepIndex].nextStepPrompt}
        </Button>
      </div>
    </div>
  );
};

export default OnboardingLayoutActions;
