const coursePriceLevels = [
  {
    title: "Gratis",
    description: "Gratis uppgifter för att testa på kursen",
    minPrice: 0,
    price: "0 SEK",
    body:
      "Upp till 50 uppgifter lite huller om buller. Kursen är bara i uppstartsfasen. Men du kan ladda upp ditt eget material och börja träna.",
    link: "/courses",
    badgeType: "success",
  },
  {
    title: "Basic",
    description: "Tentauppgifter, sorterade efter område och svårighetsgrad",
    body:
      "Upp till 100 uppgifter och en ordentlig kategorisering på uppgifterna. Där kan du redan få ett bra hum över kursen och börja träna ett område i taget.",
    price: "49 SEK",
    minPrice: 49,

    link: "/courses",
    color: "#B54708",
    badgeType: "secondary",
  },
  {
    title: "Standard",
    description:
      "Du får både sorterade uppgifter och sammanfattande teorigenomgångar.",
    body:
      "Upp till 300 uppgifter och grundläggande teoriavsnitt som kompletterar uppgifterna. Här får du en tydlig överblick över kursen och komma väldigt långt i plugget",
    price: "99 SEK",
    minPrice: 99,

    link: "/courses",
    color: "#425388",
    badgeType: "primary",
  },
  {
    title: "Pro",
    description:
      "Träna på en stor mängd uppgifter och kompletta teorigenomgångar",
    minPrice: 149,
    price: "149 SEK",
    body:
      "Våra mest utvecklade kurser. Här hittar du i princip allt du behöver inför tentan. Mer än 300 uppgifter och avancerade teoriavsnitt författade av populära assistenter.",
    link: "/courses",
    color: "#5925DC",
    badgeType: "purple",
  },
];

export default coursePriceLevels;
