import React from "react";
import OTPEmailInput from "./OTPEmailInput";
import OTPVerification from "Widgets/User/OTP/OTPVerification/OTPVerification";
import useOTP from "Widgets/User/OTP/useOTP";
import OTPText from "Widgets/User/OTP/OTPText";
import PropTypes from "prop-types";
import {AnimatePresence, motion} from "framer-motion";

const allowedActions = [
  "STUDENT_EMAIL_VERIFICATION",
  "PASSWORD_RESET",
  "EMAIL_VERIFICATION",
  "VERIFY_DEVICE",
  "LOGIN",
];

const OTP = ({
  title = null,
  subTitle = null,
  inlineForm = false,
  action = "EMAIL_VERIFICATION",
  showModal = true,
  inputHint = null,
  inputPlaceholder = "student@universitet.se",
  onVerificationComplete = () => {},
  transparent = false,
  initialEmail = null,
  autoSubmit = false, // Auto submit the email if initialEmail is set
}) => {
  const {requestSuccessful, onComplete, hide, ...state} = useOTP({
    action,
    onVerificationComplete,
    initialEmail,
    autoSubmit,
  });

  if (!allowedActions.includes(action)) {
    return null;
  }

  return (
    <AnimatePresence>
      {!hide && (
        <motion.div
          exit={{opacity: 0, scale: 0, height: 0}}
          className="mt-4"
          transition={{duration: 0.2}}
        >
          <OTPText
            title={title}
            subTitle={subTitle}
            requestSuccessful={requestSuccessful}
            showModal={showModal}
            email={state.values.email}
          />
          {/*// show the email input screen*/}
          <OTPEmailInput
            requestSuccessful={requestSuccessful}
            showModal={showModal}
            inlineForm={inlineForm}
            inputHint={inputHint}
            inputPlaceholder={inputPlaceholder}
            {...state}
            transparent={transparent}
          />

          {/*// show the code input screen*/}
          <OTPVerification
            requestSuccessful={requestSuccessful}
            showModal={showModal}
            onComplete={onComplete}
            email={state.values.email}
            action={action}
            resend={state.submit}
            transparent={transparent}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

OTP.propTypes = {
  /**
   * The action to perform after the OTP
   */
  action: PropTypes.oneOf(allowedActions),
  /**
   * Whether to show the modal or not.
   */
  showModal: PropTypes.bool,
  /**
   * Whether to show the email input and submit button inline or not.
   */
  inlineForm: PropTypes.bool,
  /**
   * The title of the OTP widget
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The subtitle of the OTP widget
   */
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The hint for the input field
   */
  inputHint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The callback function to be called after the verification is complete
   */
  onVerificationComplete: PropTypes.func,
};

export default OTP;
