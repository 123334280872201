import React from "react";
import {Trans} from "Components";
import useRedeemSubscriptionTrialCoupon from "API/mutations/subscriptionTrialCoupon/useRedeemClaimedSubscriptionTrialCoupon";
import {useTranslation} from "react-i18next";
import useRedeemCreditCouponMutation from "API/mutations/user/useRedeemCreditCouponMutation";

const useAccountCreditsRedeemCouponState = (
  initialValue = "",
  couponType = null,
  mutationVariables = {},
  capitalizeInput = false
) => {
  const {t: trans} = useTranslation();

  const [state, setState] = React.useState({
    value: initialValue,
    error: null,
    message: null,
  });

  const handleChange = event =>
    setState({
      value: capitalizeInput
        ? event.target.value.toUpperCase()
        : event.target.value,
      error: null,
      message: null,
    });

  const onCompletedSubscriptionTrialCoupon = data => {
    if (data.redeemSubscriptionTrialCoupon.ok) {
      setState({
        ...state,
        error: null,
        message: <Trans>The coupon has been redeemed</Trans>,
      });
    } else {
      setState({
        ...state,
        message: null,
        error: data.redeemSubscriptionTrialCoupon.error.message,
      });
    }
  };

  const {
    redeemSubscriptionTrialCoupon,
    loading: redeemingSubscriptionTrialCoupon,
  } = useRedeemSubscriptionTrialCoupon(onCompletedSubscriptionTrialCoupon);

  const onCompleted = data => {
    if (data.redeemCoupon.ok) {
      setState({
        ...state,
        error: null,
        message: <Trans>Credits have been added to your account</Trans>,
      });
    } else {
      setState({...state, message: null, error: data.redeemCoupon.error});
    }
  };

  const [redeemCoupon, loading] = useRedeemCreditCouponMutation(onCompleted);

  // We run both mutations in parallel so we can handle both classical coupons and subscription trial coupons
  const submit = e => {
    if (e) {
      e.preventDefault();
    }
    console.log("submitting", couponType, state.value, mutationVariables);
    if (couponType === null) {
      redeemSubscriptionTrialCoupon({
        variables: {name: state.value, ...mutationVariables},
      });

      redeemCoupon({variables: {name: state.value, ...mutationVariables}});

      return;
    }

    if (couponType && couponType === "subscriptionTrialCoupon") {
      redeemSubscriptionTrialCoupon({
        variables: {name: state.value, ...mutationVariables},
      });
    }

    if (couponType && couponType === "classicCoupon") {
      redeemCoupon({variables: {name: state.value, ...mutationVariables}});
    }
  };

  return {
    ...state,
    handleChange,
    submit,
    loading: loading || redeemingSubscriptionTrialCoupon,
  };
};

export default useAccountCreditsRedeemCouponState;
