import React from "react";
import {Alert, PageLogoLoader, Trans} from "Components";
import useGroupInvitation from "routes/Group/GroupInvitation/useGroupInvitation";
import {userContext} from "UserContextProvider";
import GroupPageJoinAuth from "routes/Campaign/Sektionskampen/GroupPage/GroupPageJoin/GroupPageJoinAuth";
import GroupInvitationHeader from "routes/Group/GroupInvitation/GroupInvitationHeader";
import GroupInviteAcceptButton from "routes/Group/GroupInvitation/GroupInviteAcceptButton";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";

// http://localhost:3000/group-invite/0af9155871bd817a94a80d025969a692
// http://opensektionen.localhost:3000/group-invite/0af9155871bd817a94a80d025969a692

const GroupInvitation = ({
  wrapper = "div",
  openInvitation = false,
  ...props
}) => {
  const groupContext = useGroupContext();
  const {user} = userContext();
  const {loading, invite} = useGroupInvitation();

  if (loading || !groupContext?.id) {
    return <PageLogoLoader />;
  }

  if (!openInvitation && (!invite || !invite.group)) {
    return (
      <div className="mx-auto my-auto max-w-md py-8">
        <Alert
          type="warning"
          title={
            <Trans>
              The invitation link is invalid. Please contact the group
              administrator.
            </Trans>
          }
        />
      </div>
    );
  }

  const Wrapper = wrapper ? wrapper : "div";

  if (!user) {
    // The user should login/register first before they can join the group
    return (
      <Wrapper>
        <GroupInvitationHeader
          invite={invite}
          openInvitation={openInvitation}
          prompt={<Trans>Login or create an account to join the group</Trans>}
        />
        <GroupPageJoinAuth signupSource="group-invite" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <GroupInvitationHeader
        invite={invite}
        prompt={
          <Trans>
            Press the button below to accept the invitation and join the group.
          </Trans>
        }
      />
      <GroupInviteAcceptButton openInvitation={openInvitation} />
    </Wrapper>
  );
};

export default GroupInvitation;
