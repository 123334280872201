/**
 * Formats an email address with an extension
 * Example: email = "test", extension = "@example.com" => "test@exmaple.com"
 * Example 2: if email already has extension, it will be kept
 *
 * @param email
 * @param extension
 */
export const formatEmailWithExtension = (email, extension) => {
  if (email.endsWith(extension)) {
    return email;
  }

  const splitted = email.split("@");

  if (splitted.length === 1) {
    return splitted[0] + extension;
  }

  if (splitted.length === 2) {
    return splitted.join("@");
  }
};
