import useGetExercise from "API/queries/exercise/useGetExercise";
import {ErrorBoundary} from "Components";
import React from "react";
import useEventTracking from "utilities/hooks/useEventTracking";
import useLastExerciseInCourse from "utilities/hooks/useLastExerciseInCourse";
import ExerciseViewExerciseDisplay from "./ExerciseViewExerciseDisplay";
import ExerciseViewHeader from "./ExerciseViewHeader/ExerciseViewHeader";
import ExerciseViewSolution from "./ExerciseViewSolution";
import ExerciseViewToolbar from "./ExerciseViewToolbar/ExerciseViewToolbar";
import useExerciseViewState from "./useExerciseViewState";
import "./ExerciseView.scss";
import ExerciseViewForum from "Components/Workboard/ExerciseView/ExerciseViewForum/ExerciseViewForum";
import {motion} from "framer-motion";
import useExerciseViewFocusMode from "Components/Workboard/ExerciseView/useExerciseViewFocusMode";
import classnames from "classnames";
import {useTranslation} from "react-i18next";

export const ExerciseViewContext = React.createContext();

const ExerciseView = ({
  keepTitle = false,
  exerciseId,
  hideEvaluation,
  initSolutionVisibility = false,
  showForum,
  showHeader = true,
  showUserTools = true,
  allowFocusMode = false,
  testMode = false,
}) => {
  const {t: trans} = useTranslation();

  const [focusMode, toggleFocusMode] = useExerciseViewFocusMode(
    allowFocusMode,
    exerciseId
  );

  const [exercise, exerciseLoading] = useGetExercise(exerciseId, true);

  useLastExerciseInCourse(exercise ? exercise.id : null);

  // TODO: handle exerciseLoading
  const state = useExerciseViewState(
    exerciseId,
    initSolutionVisibility,
    showForum
  );

  const {logExerciseEvent} = useEventTracking();

  React.useEffect(() => {
    if (exercise?.id) {
      // logExerciseEvent("exercise_shown");
      setTitle(trans, exercise, keepTitle);
    }
  }, [exercise]);

  // Return empty card if loading
  if (exerciseLoading || !exercise) {
    return (
      <motion.div key="exercise-view" style={{height: 500, width: "100%"}} />
    );
  }

  return (
    <div>
      {/*<ExerciseViewFocusModeOverlay focusMode={focusMode} />*/}
      <ExerciseViewContext.Provider
        value={{...state, ...exercise, showHeader, showUserTools}}
      >
        <motion.div
          id="exercise-view"
          className={classnames("exercise-view-card background rounded-xl")}
          key="exercise-view"
        >
          <div className="exercise-view">
            {showHeader && (
              <ExerciseViewHeader
                exercise={exercise}
                loading={exerciseLoading}
              />
            )}

            <motion.div className="xs:p-2 sm:p-4 md:py-8">
              <ExerciseViewExerciseDisplay
                exerciseId={exerciseId}
                hideEvaluation={hideEvaluation}
                focusMode={focusMode}
              />
            </motion.div>

            {/*{!exerciseLoading && !testMode && (*/}
            {/*  // When loading the exercise, don't mount the component*/}
            {/*  <ErrorBoundary>*/}
            {/*    <ExerciseViewTags exerciseId={exerciseId} />*/}
            {/*  </ErrorBoundary>*/}
            {/*)}*/}

            <div>
              <ExerciseViewToolbar
                loading={exerciseLoading}
                toggleFocusMode={toggleFocusMode}
                focusMode={focusMode}
                allowFocusMode={false}
              />
            </div>

            {state.solutionVisible && (
              <motion.div className="xs:p-2 sm:p-4 md:p-8">
                <ExerciseViewSolution />
              </motion.div>
            )}

            {/* Don't mount the component until the solution is visible, to avoid loading the data */}

            <ErrorBoundary>
              <ExerciseViewForum exerciseId={exercise?.id} />
            </ErrorBoundary>
          </div>
        </motion.div>
      </ExerciseViewContext.Provider>
    </div>
  );
};

const setTitle = (t, exercise, keepTitle = false) => {
  if (exercise && !keepTitle) {
    const title = `${t("Exercise")} ${exercise.source?.date}#${
      exercise.number
    } ${
      // show author if it exists
      exercise.source?.author ? t("by ") + exercise.source.author : ""
    }`;
    document.title = title;
  }
};

export default ExerciseView;

// if ( exercise ) {
//   const metaTitleTag = document.createElement( "meta" );
//   metaTitleTag.setAttribute( "property", "og:title" );
//   metaTitleTag.setAttribute(
//     "content",
//     `${exercise.source.date}#${exercise.number} by ${exercise.source.author} `
//   );
//   document.head.prepend( metaTitleTag );

//   const metaImageTag = document.createElement( "meta" );
//   metaImageTag.setAttribute( "property", "og:image" );
//   metaImageTag.setAttribute( "content", EXAM_IMAGES_URL + exercise.imageUrl );
//   document.head.prepend( metaImageTag );

//   const metaTypeTag = document.createElement( "meta" );
//   metaTypeTag.setAttribute( "property", "og:type" );
//   metaTypeTag.setAttribute( "content", "article" );
//   document.head.prepend( metaTypeTag );

//   const metaDescriptionTag = document.createElement( "meta" );
//   metaDescriptionTag.setAttribute( "property", "og:description" );
//   metaDescriptionTag.setAttribute( "content", exercise.text );
//   document.head.prepend( metaDescriptionTag );
// }
