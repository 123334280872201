import React from "react";
import usePagination from "./usePagination";
import PropTypes from "prop-types";
import PaginationControls from "Components/_Elements/Pagination/PaginationControls";

const Pagination = ({
  items,
  position = "bottom",
  justify = "spread",
  showPageButtons = true,
  pageSize = 10,
  defaultPageIndex = 0,
  children,
  buttonProps = {},
}) => {
  const {
    pages,
    currentPage,
    currentPageItems,
    onPageIndexClick,
    onPreviousPageClick,
    onNextPageClick,
    previousPageButtonDisabled,
    nextPageButtonDisabled,
    paginationUnnecessary,
  } = usePagination({
    items,
    pageSize,
    defaultPageIndex,
  });

  // Make sure children is a function
  if (typeof children !== "function") {
    throw new Error("Pagination component requires a function as its children");
  }

  /**
   * Pagination runs a render function that returns the children of the Pagination component.
   */

  if (paginationUnnecessary) {
    return children(items);
  }

  return (
    <>
      {position === "bottom" && children(currentPageItems)}

      <PaginationControls
        {...{
          pages,
          showPageButtons,
          currentPage,
          onPageIndexClick,
          onPreviousPageClick,
          onNextPageClick,
          previousPageButtonDisabled,
          nextPageButtonDisabled,
          justify,
          buttonProps,
        }}
      />

      {position === "top" && children(currentPageItems)}
    </>
  );
};

Pagination.propTypes = {
  items: PropTypes.array.isRequired,
  position: PropTypes.oneOf(["top", "bottom"]),
  justify: PropTypes.oneOf(["center", "left", "right", "spread"]),
  showPageButtons: PropTypes.bool,
  pageSize: PropTypes.number,
  defaultPageIndex: PropTypes.number,
  children: PropTypes.func.isRequired,
};

export default Pagination;
