import React from "react";
import { useHistory } from "react-router-dom";
import useUpdateCurrentUserMutation from "API/mutations/user/useUpdateCurrentUserMutation";
import { GET_CURRENT_USER } from "API/queries/user/useGetCurrentUser";

const UseSummer2022CampaignOnBoardingEnrollmentState = ({
  school,
  program,
}) => {
  const history = useHistory();
  // State
  const [state, replaceState] = React.useState({
    schoolId: school?.id,
    programId: program?.id,
    enrollmentYear: 2022,
    editing: !school?.id || !program?.id,
  });

  // State functions

  const setState = obj => {
    replaceState({ ...state, ...obj });
  };

  const setEditing = () => {
    setState("editing", true);
  };

  // Mutation and configuration
  const onComplete = () => history.push("/home");
  const [updateUser, loading] = useUpdateCurrentUserMutation(
    onComplete,
    GET_CURRENT_USER
  );
  const submit = () => {
    let parsedEnrollmentYear = state.enrollmentYear;

    try {
      if (typeof state.enrollmentYear === "string") {
        parsedEnrollmentYear = parseInt(state.enrollmentYear);
      }
    } catch (e) {
      // Default to 2020 if the enrollment year is not a number
      // Only runs if the enrollment year is a string and was not parsed
      parsedEnrollmentYear = 2022;
    }

    updateUser({
      variables: {
        schoolId: state.schoolId,
        programId: state.programId,
        enrollmentYear: parsedEnrollmentYear,
      },
    });
  };

  return {
    state,
    setState,
    setEditing,
    submit,
    loading,
  };
};

export default UseSummer2022CampaignOnBoardingEnrollmentState;
