import React from "react";
import useUpdateChapterMutation from "API/mutations/chapter/useUpdateChapterMutation";
import {Trans} from "Components/index";
import {GET_COURSE_CHAPTERS} from "API/queries/chapter/useGetUserChapters";

const useGPTActionsSaveChapter = ({courseId}) => {
  const {notify} = React.useContext(window.NotificationsContext);
  const {updateChapter, loading} = useUpdateChapterMutation({
    refetchQueries: [
      {
        query: GET_COURSE_CHAPTERS,
        variables: {
          courseId,
        },
      },
    ],
  });

  const handleUpdate = async ({messages, chapterId, isNew = false}) => {
    const response = await updateChapter({
      variables: {
        id: chapterId,
        chapter_type: "Kollin-GPT",
        markdown: JSON.stringify(messages), // since it has to be a string, we can parse it back to JSON when displaying it
      },
    });

    if (response.data.updateChapter.ok) {
      // Notify user of success

      notify({
        key: "course-kollin-gpt-saved",
        title: isNew ? (
          <Trans>Conversation saved</Trans>
        ) : (
          <Trans>Conversation updated</Trans>
        ),
        message: (
          <Trans>
            You can find your saved conversations on the Course home page
          </Trans>
        ),
        icon: "check-circle",
        iconFillType: "success",
      });
    }
  };

  return {
    handleUpdate,
    loading,
  };
};

export default useGPTActionsSaveChapter;
