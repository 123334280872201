import React from "react";
import useGetCurrentUserNotifications from "API/queries/notification/useGetCurrentUserNotifications";
import Link from "Components/_Elements/Link/Link";
import {UserContext} from "UserContextProvider";
import Trans from "Components/Trans";
import SidebarMenu from "../../SidebarMenu/SidebarMenu";

const UserMenuSidebar = ({open, setOpen}) => {
  const {user} = React.useContext(UserContext);
  const [notifications] = useGetCurrentUserNotifications();

  const unreadNotifications = notifications.filter(
    notification => notification.read === false
  );

  let links = [
    <Link to="/home" key="home">
      <Trans>Dashboard</Trans>
    </Link>,
    <Link to="/courses" key="courses">
      <Trans>All courses</Trans>
    </Link>,
    <Link to="/trial" key="trial">
      <Trans>Get Kollin Gold</Trans>
    </Link>,
  ];

  return (
    <SidebarMenu open={open} setOpen={setOpen}>
      {links}
    </SidebarMenu>
  );
};

export default UserMenuSidebar;
