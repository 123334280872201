import React from "react";
import {Alert, Button, Modal, ModalContext, Trans} from "Components";
import CreateStripeIntent from "Widgets/Payment/Stripe/CreateStripeIntent/CreateStripeIntent";

const AccountSubscriptionPaymentMethodsCreate = ({alert = true}) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      {alert ? (
        <Alert
          title={<Trans>No payment methods</Trans>}
          subTitle={
            <Trans>
              You don't have any payment methods attached to this account yet
            </Trans>
          }
          primaryAction={{
            label: <Trans>Add a payment method</Trans>,
            onClick: () => setShowModal(true),
          }}
        />
      ) : (
        <Button type="link" iconLeft="plus" onClick={() => setShowModal(true)}>
          <Trans>Add a payment method</Trans>
        </Button>
      )}

      <Modal
        className="account-subscription-payment-methods-create"
        title={<Trans>Add a new payment method</Trans>}
        icon="credit-card"
        subTitle={
          <Trans>
            The payment method will be added to your account. You can choose
            which payment method to use for your next payment.
          </Trans>
        }
        defaultOpen={showModal}
        onClose={() => setShowModal(false)}
        showCancelButton={true}
        maskClosable={false}
      >
        <Form />
      </Modal>
    </>
  );
};

const Form = () => {
  const {handleClose} = React.useContext(ModalContext);
  return (
    <CreateStripeIntent
      title={null}
      submitPrompt={<Trans>Save</Trans>}
      onComplete={handleClose}
    />
  );
};

export default AccountSubscriptionPaymentMethodsCreate;
