import React from "react";
import {Button, Link, Trans} from "Components";

const PreviewSidebarHeader = ({handleClosePreview, expandLink = null}) => {
  return (
    <div
      key="preview-sidebar-header"
      className="sticky top-0 z-[60] bg-primary-800 flex items-center justify-between gap-2 px-4 py-2 z-50 border-b-2 border-gray"
    >
      <Button
        type="link"
        onClick={handleClosePreview}
        icon="times"
        inverted
        size="lg"
        hint={<Trans>Close preview</Trans>}
      />
      {expandLink !== null && (
        <Link
          to={expandLink}
          inverted
          buttonType="link"
          iconRight="arrow-right"
        >
          <Trans>Open page</Trans>
        </Link>
      )}
    </div>
  );
};

export default PreviewSidebarHeader;
