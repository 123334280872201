import React from "react";
import classnames from "classnames";
import {motion} from "framer-motion";
import useButtonClickTracking from "Components/_Elements/Button/useButtonClickTracking";
const GeogebraHighlightMath = ({math, block}) => {
  const {
    selectorActive,
    setCurrentEquation,
    currentEquation,
    visualisationActive,
  } = React.useContext(window.GeogebraContext);
  const {trackClick} = useButtonClickTracking();

  const equation = React.useMemo(() => {
    // Remove $ signs from math
    let cleaned = math;
    // Remove the dollar signs from the equation
    cleaned = cleaned.replace(/\$/g, "").trim();
    // Remove <br /> from equation
    cleaned = cleaned.replace(/<br \/>/g, "");
    // Remove trailing . from equation
    cleaned = cleaned.replace(/\.$/g, "");
    // Remove trailing , from equation
    cleaned = cleaned.replace(/,$/g, "");

    return cleaned;
  }, [math]);

  React.useEffect(() => {
    window.MathJax.typesetPromise();
  }, [equation]);

  const active = currentEquation === equation;

  const eligible = React.useMemo(() => {
    return checkVisualizeEligible(equation);
  }, [equation]);

  const handleClick = () => {
    if (eligible && selectorActive) {
      if (active) {
        setCurrentEquation(null);
      } else {
        setCurrentEquation(equation);
        trackClick({
          event: "visualize_function",
          equation,
        });
      }
    }
  };

  const display = block ? "block mb-1" : "inline-block";

  const animate = selectorActive && eligible && !visualisationActive && !active;

  return (
    <motion.span
      onClick={handleClick}
      className={classnames(
        display,
        selectorActive &&
          eligible &&
          "cursor-pointer px-1 border-1 rounded-md surface-gray text-gray-light  border-gray",
        active && "surface-primary text-primary-light  border-primary "
      )}
      dangerouslySetInnerHTML={{
        __html: math || "",
      }}
      initial={{scale: 1}}
      animate={{
        scale: animate ? [0.95, 1, 0.95, 1, 0.95, 1] : 1,
      }}
      transition={{
        duration: 10,
        repeat: Infinity,
        repeatType: "mirror",
      }}
    />
  );
};

const checkVisualizeEligible = equation => {
  // Currently only supports 2D and 3D functions.
  const allowedEquations = [
    /^[A-Z-a-z]\(([A-Za-z]\s?,?\s?){1,2}\)\s?:?=/gm, // A(x), A(x,y)
    /^[A-Za-z]\s?:?=/gm, // y = 3x + 2 or z = x^2 + y^2
    //    0 \leq x \leq 2 y \leq 3
    // x y z+x^{2}-2 y^{2}+z^{3}=14 :
    // /^\\i{1,3}nt_\{?.+}?\^\{?.+}?/gm,
    /^[0-9]+\s?=\s?/gm,
    /^.*\s?=\s?[0-9]+$/gm,
  ];

  const eligible = allowedEquations.some(regex => regex.test(equation));

  // console.log(equation, eligible);
  return eligible;
};

export default GeogebraHighlightMath;
