import React from "react";
import {Typography} from "Components";
import contributionDescriptions from "Widgets/Course/CourseContributions/contributionDescriptions";

const CourseContributionsDescription = ({objectKeys}) => {
  return Object.keys(objectKeys).map((key, index) => {
    const count = objectKeys[key].length;
    const description = contributionDescriptions[key];
    if (!description) return null;

    return (
      <div className="flex gap-2 items-center">
        <Typography type="tsm">
          {description.verb}{" "}
          <span className="text-primary-700 font-medium">
            {count}{" "}
            {count > 1 ? description.noun.plural : description.noun.singular}
          </span>
        </Typography>
      </div>
    );
  });
};

export default CourseContributionsDescription;
