import React from "react";
import {isMobile} from "react-device-detect";
import useGetExercise from "API/queries/exercise/useGetExercise";
import PropTypes from "prop-types";
import ExercisesList from "./ExerciseList/ExercisesList";
import ExerciseView from "./ExerciseView/ExerciseView";
import useWorkboardState from "./useWorkboardState";
import "./Workboard.scss";
import {AnimateSharedLayout} from "framer-motion";
import {ErrorBoundary} from "Components";
import ActionPane from "Components/Workboard/ActionPane/ActionPane";
import GeogebraDisplay from "Components/Geogebra/GeogebraDisplay/GeogebraDisplay";
import GeogebraProvider from "Components/Geogebra/GeogebraProvider";
import GPTDisplayWorkboard from "Components/GPT/GPTDisplay/GPTDisplayWorkboard";

export const WorkboardContext = React.createContext();

const Workboard = props => {
  const {previewMode} = React.useContext(window.PreviewSidebarContext);

  let {
    exercises,
    exerciseListColumns,
    testMode,
    hierarchyLevel,
    orientation,
    showExerciseViewHeader = false,
  } = props;

  const state = useWorkboardState();

  const {exerciseId} = state;

  if (!exerciseId || previewMode)
    return (
      <WorkboardContext.Provider value={state}>
        <div className="py-2 px-0">
          <ErrorBoundary>
            <ExercisesList
              hierarchyLevel={hierarchyLevel}
              exercises={exercises}
              columns={exerciseListColumns}
              orientation={orientation}
            />
          </ErrorBoundary>
        </div>
      </WorkboardContext.Provider>
    );

  return (
    // Need to mount new component becuase we need to load the exercise
    <WorkboardExercise state={state} {...props} displayMode="list" />
  );
};

const WorkboardExercise = ({
  state,
  exercises,
  exerciseListColumns,
  testMode,
  displayMode,
  showExerciseViewHeader,
}) => {
  const {exerciseId, exerciseListOrientation} = state;
  const [exercise, exerciseLoading] = useGetExercise(exerciseId, true);

  return (
    <WorkboardContext.Provider
      value={{...state, testMode, exercise, exerciseLoading}}
    >
      <GeogebraProvider dependency={exerciseId}>
        <AnimateSharedLayout layoutId="workboard" type="switch">
          <div>
            <div className="@container gap-4 place-content-start">
              <ErrorBoundary>
                <ExercisesList
                  orientation={
                    isMobile ? "horizontal" : exerciseListOrientation
                  }
                  exercises={exercises}
                  columns={exerciseListColumns}
                  displayMode={displayMode}
                />
              </ErrorBoundary>

              <div className="pr-4">
                <ErrorBoundary>
                  <ExerciseView
                    exerciseId={exerciseId}
                    allowFocusMode
                    testMode={testMode}
                    showHeader={showExerciseViewHeader}
                  />
                </ErrorBoundary>

                <div className="mt-4">
                  <ErrorBoundary>
                    <GPTDisplayWorkboard showHeader key={exerciseId} />
                  </ErrorBoundary>
                </div>

                <GeogebraDisplay key={exerciseId} />
              </div>
            </div>

            {exerciseId && (
              <ActionPane exerciseId={exerciseId} key={exerciseId} />
            )}
          </div>
        </AnimateSharedLayout>
      </GeogebraProvider>
    </WorkboardContext.Provider>
  );
};
export const WorkboardLayoutTransition = {
  // ease: "linear",
  // duration: 1,
};

Workboard.propTypes = {
  exercises: PropTypes.array,
  exerciseListColumns: PropTypes.array,
};

export default Workboard;
