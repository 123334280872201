import React from "react";
import { Summer2022CampaignProgramContext } from "routes/Campaign/Summer2022Campaign/Summer2022Campaign";
import useGetCoupon from "API/queries/coupon/useGetCoupon";

const useSummer2022CampaignOnBoardingState = () => {
  // Get the programKey from the context which comes from the url, it should be the same as the coupon's name
  const { programKey, programLogo } = React.useContext(
    Summer2022CampaignProgramContext
  );

  const userContext = React.useContext(window.UserContext);

  // Get the coupon from the database
  const [coupon, loading] = useGetCoupon({ name: programKey });

  // Scroll to the top of the page
  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // State used to keep track of the current step the user is at
  const [currentStep, setCurrentStep] = React.useState(
    userContext?.user?.id ? 1 : 0
  );

  const onComplete = () => {
    setCurrentStep(1);
  };

  return {
    currentStep,
    onComplete,
    coupon,
    loading,
  };
};

export default useSummer2022CampaignOnBoardingState;
