import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_GROUP = gql`
  query group(
    $id: ID!
    $includeSubGroups: Boolean = false
    $includeMembers: Boolean = false
  ) {
    group(id: $id) {
      id
      key
      name
      description
      imageUrl
      hidden
      membershipRequiresValidation
      createdAt
      updatedAt
      membersCount
      program {
        id
        name
      }

      currentUserMembership {
        id
        admin
        verified
      }

      subGroups @include(if: $includeSubGroups) {
        id
        key
        name
        description
        imageUrl

        currentUserMembership {
          id
          admin
          verified
        }

        program {
          id
          name
        }
        members @include(if: $includeMembers) {
          ...member
        }
      }
      members @include(if: $includeMembers) {
        ...member
      }
    }
  }

  fragment member on GroupMember {
    id
    admin
    verified
    createdAt
    user {
      id
      admin
      firstName
      lastName
      avatar
      enrollmentYear
      emailHash
      studentEmailHash
    }
  }
`;
const useGetGroup = ({
  id,
  includeSubGroups = false,
  includeMembers = false,
}) => {
  let group = {};

  const {data, loading, error} = useQuery(GET_GROUP, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
    variables: {
      id,
      includeSubGroups,
      includeMembers,
    },
  });

  if (data) {
    group = data.group;
  }
  console.log("group", group);

  return [group, loading];
};

export default useGetGroup;
