import React from "react";
import useGetCourseModules from "API/queries/module/useGetCourseModules";
import "./CourseLandingTopics.scss";
import {Trans, Typography} from "Components";
import {AnimateSharedLayout, motion} from "framer-motion";
import CourseLandingTopicDetails from "./CourseLandingTopicDetails/CourseLandingTopicDetails";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {textSingularPluralTranslated} from "utilities/textSingularPlural";
import {TotalExercises} from "./helpers/CourseLandingTopicsHelpers";

const CourseLandingTopics = () => {
  const {courseId, navigationState} = React.useContext(
    window.CourseLandingContext
  );

  const [modules, loading] = useGetCourseModules(courseId, true, true, true);

  if (loading) return null;

  return (
    <div ref={navigationState.topicsRef} className="course-landing-topics">
      <Trans type="txl" weight="sb">
        Overview
      </Trans>

      <AnimateSharedLayout exitBeforeEnter>
        {modules.map((module, index) => (
          <CourseLandingTopicsModule
            key={index}
            {...{
              index,
              module,
              modulesTotal: modules.length,
            }}
          />
        ))}
      </AnimateSharedLayout>
    </div>
  );
};

const CourseLandingTopicsModule = ({module, index, modulesTotal}) => {
  const {t: trans} = useTranslation();

  const [active, setActive] = React.useState(index === 0); // first one will be open by default
  const toggleActive = () => setActive(!active);

  const totalTags = module.tags?.length;
  const totalExercises = module.tags.reduce(
    (prev, curr) => prev + curr.exercises?.length,
    0
  );

  if (modulesTotal === 1) {
    return (
      <motion.div className="course-landing-topics-module" key={module.id}>
        <CourseLandingTopicDetails
          {...{
            tags: module.tags,
            active,
            free: index === 0,
            soloModule: true,
          }}
        />
      </motion.div>
    );
  }

  return (
    <motion.div className="course-landing-topics-module" key={module.id}>
      <motion.div
        className="course-landing-topics-module-title"
        layout
        onClick={toggleActive}
      >
        <div>
          <span className={classNames("circle", {active})} />
          <h2 className="course-landing-topics-module-title-h2">
            {module.name}
          </h2>

          {index === 0 && (
            <span className="course-landing-topics-module-free">
              <Trans>Preview</Trans>
            </span>
          )}

          {index > 0 && <i className="fas fa-lock" />}
        </div>

        <motion.div
          initial={{rotate: 0}}
          animate={{
            rotate: active ? 180 : 0,
          }}
        >
          <i className="fa fa-chevron-down" />
        </motion.div>
      </motion.div>

      <motion.div
        className="course-landing-topics-module-total-exercises flex"
        layout
      >
        <Typography className="flex">
          {totalTags > 0 &&
            textSingularPluralTranslated(totalTags, "subtopic") + " | "}{" "}
          <TotalExercises total={totalExercises} />
        </Typography>
      </motion.div>

      <CourseLandingTopicDetails
        {...{
          tags: module.tags,
          active,
          free: index === 0,
        }}
      />
    </motion.div>
  );
};

export default CourseLandingTopics;
