import React from "react";
import { Icon } from "Components";

const ExamUploadStepTitle = ({
  title,
  subTitle,
  completedMessage,
  currentMessage,
  status,
}) => {
  switch (status) {
    case "not started":
      return (
        <div>
          <Title type="tmd" weight="medium" color="text-primary-700">
            {title}
          </Title>
          <Title type="tsm" weight="normal" color="text-primary-700">
            {subTitle}
          </Title>
        </div>
      );
    case "current":
      return (
        <div className="flex flex-row justify-between items-center">
          <div>
            <Title type="dsm" weight="normal" color="text-primary-900">
              {title}
            </Title>
            <Title type="tmd" weight="medium" color="text-primary-700">
              {subTitle}
            </Title>
          </div>
          <div>{currentMessage}</div>
        </div>
      );
    case "completed":
      return (
        <div className="flex justify-between items-center h-full">
          <Title type="tmd" weight="medium" color="text-gray-700">
            {title}
          </Title>
          <div className="flex items-center gap-2">
            <Title type="tsm" weight="normal" color="text-gray-500">
              {completedMessage}
            </Title>
            <Icon icon="check-circle" color="text-success-light" size="md" />
          </div>
        </div>
      );
    default:
      return null;
  }
};

const Title = ({ children, type, weight, color }) => {
  return React.cloneElement(children, {
    type,
    weight,
    color,
  });
};

export default ExamUploadStepTitle;
