import React from "react";
import {Button, CourseCard, Section, Trans} from "Components";

const MyCoursesArchive = ({archivedCourses}) => {
  const [showArchivedCourses, setShowArchivedCourses] = React.useState(false);

  if (!archivedCourses.length) return null;

  return (
    <Section
      title={<Trans className="tmd">Archived courses</Trans>}
      secondary
      action={
        <Button
          type="secondary"
          iconLeft={
            showArchivedCourses
              ? "fa-solid fa-chevron-up"
              : "fa-solid fa-chevron-down"
          }
          onClick={() => setShowArchivedCourses(!showArchivedCourses)}
        >
          {showArchivedCourses ? (
            <Trans>Hide archived courses</Trans>
          ) : (
            <Trans>Show archived courses</Trans>
          )}
        </Button>
      }
    >
      {showArchivedCourses &&
        archivedCourses.map(course => (
          <CourseCard
            key={course.id}
            {...course}
            {...course.currentUser} // To add the props "active" and "archived"
            schoolName={course.school?.name}
          />
        ))}
    </Section>
  );
};

export default MyCoursesArchive;
