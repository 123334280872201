import React from "react";
import {schools, sizeMap} from "./SchoolLogo.constants";
import classnames from "classnames";
import CDN from "utilities/constants/CDN";

const SchoolLogo = ({
  schoolId, // School ID
  size = "md", // Size of the logo
}) => {
  const school = schools[schoolId];
  if (!school) return null;
  return <img className={classnames(sizeMap[size])} src={CDN + school.logo} />;
};

export default SchoolLogo;
