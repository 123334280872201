import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_CURRENT_USER_STATS = gql`
  query getCurrentUserStats {
    currentUser {
      id

      completedExercisesCount
      averageRating
    }
  }
`;

const useGetCurrentUserStats = () => {
  let stats = null;
  const {data, loading, error} = useQuery(GET_CURRENT_USER_STATS);

  if (error) {
    console.log(`Error  in useGetCurrentUserStats: ${error}`);
  }

  if (data) {
    stats = {
      completedExercisesCount: data.currentUser.completedExercisesCount,
      averageRating: data.currentUser.averageRating,
    };
  }

  return [stats, loading];
};

export default useGetCurrentUserStats;
