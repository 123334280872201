import React from "react";
import PropTypes from "prop-types";
import TableHeader from "Components/_Elements/Table/TableHeader";
import classnames from "classnames";
import TableCardHeader from "Components/_Elements/Table/TableCardHeader";
import TableBody from "Components/_Elements/Table/TableBody";
import usePagination from "Components/_Elements/Pagination/usePagination";
import PaginationControls from "Components/_Elements/Pagination/PaginationControls";
import {columnSpacingMap} from "Components/_Elements/Table/Table.constants";

const Table = ({
  data,
  columns,
  fixed = true,
  loading = false,
  card = null,
  minRows = 0, // by default, shrink the table to fit the data
  pagination = {
    justify: "spread",
    showPageButtons: true,
    pageSize: 10,
    defaultPageIndex: 0,
    size: "sm",
  },
  rowSpacing = "md",
  columnSpacing = "md",
}) => {
  const columnsCount = columns?.reduce(
    (sum, column) => sum + (column.colSpan || 1),
    0
  );

  const showAsCard = card !== null;

  const columnsTransposed = React.useMemo(() => {
    return columns?.reduce((acc, column) => {
      const {key, ...rest} = column;
      acc[key] = rest;
      return acc;
    }, {});
  });

  const paginationState = usePagination({
    items: data,
    ...pagination,
  });

  if (!columnsCount) return null;

  const minimumRowsToDisplay = Math.min(
    data.length,
    Math.max(minRows, pagination.pageSize)
  ); // Minimum rows to display but not more than the data length

  const cellClassNames = classnames(
    "table-cell align-middle first:pl-4 last:pr-4",
    columnSpacingMap[columnSpacing]
  ); // First and last have a little more padding

  const rowClassNames = "table-row";

  // TODO: Handle empty data
  return (
    <div className="background rounded-lg border-1 border-gray w-full h-full">
      <TableCardHeader {...card} showAsCard={showAsCard} />
      <div
        className={classnames(
          !fixed && "overflow-x-auto touch-pan-x",
          showAsCard ? "" : "p-2"
        )}
      >
        <div
          className={classnames(
            "table w-full  border-collapse",
            fixed ? "table-fixed" : "table-auto"
          )}
        >
          <TableHeader
            columnsTransposed={columnsTransposed}
            columnsCount={columnsCount}
            cellClassNames={cellClassNames}
            rowClassNames={rowClassNames}
            showAsCard={showAsCard}
          />

          <TableBody
            rowSpacing={rowSpacing}
            rows={paginationState.currentPageItems}
            loading={loading}
            columnsCount={columnsCount}
            rowClassNames={rowClassNames}
            cellClassNames={cellClassNames}
            columnsTransposed={columnsTransposed}
            minimumRowsToDisplay={minimumRowsToDisplay}
          />
        </div>
      </div>
      {!paginationState.paginationUnnecessary && (
        <div className={classnames("py-2 px-4 border-t-1 border-gray")}>
          <PaginationControls {...pagination} {...paginationState} />
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  /**
   * Data to be displayed in the table
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Columns to be displayed in the table
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Unique key for the column
       */
      key: PropTypes.string.isRequired,
      /**
       * Title of the column (optional)
       */
      title: PropTypes.string,
      /**
       *  Property name of the data to be displayed in the column
       */
      dataIndex: PropTypes.string.isRequired,
      /**
       * Custom render function for the column
       */
      render: PropTypes.func,
    })
  ),
  /**
   * Whether the table should be fixed or scrollable (default: true)
   */
  fixed: PropTypes.bool,

  /**
   * Whether the table should be displayed in a card (default: false)
   */
  card: PropTypes.shape({
    /**
     * Title of the card
     */
    title: PropTypes.string.isRequired,
    /**
     * Subtitle of the card
     */
    subTitle: PropTypes.string,
    /**
     * Actions to be displayed in the card header (right side)
     */
    actions: PropTypes.arrayOf(PropTypes.node),
    // TODO: Add footer props
  }),

  pagination: PropTypes.shape({
    justify: PropTypes.oneOf(["center", "left", "right", "spread"]),
    showPageButtons: PropTypes.bool,
    pageSize: PropTypes.number,
    defaultPageIndex: PropTypes.number,
  }),
};

export default Table;
