import React from "react";

import {Badge, Button, Icon, Trans, Typography} from "Components";
import classnames from "classnames";
import BuyCreditsActions from "Widgets/Credits/BuyCredits/BuyCreditsActions";
import {getDiscountedPriceCents} from "Components/ProductPrice/ProductPrice";

const BuyCreditsBundle = ({
  id,
  name,
  amount,
  description,
  imageUrl,
  prices,
  faded,
  selected,
  children,
}) => {
  const defaultPrice = prices[0];

  const price = getDiscountedPriceCents(defaultPrice) / 100;
  const discount = calculateDeal(price, amount);

  const credits = amount / 100;

  return (
    <div
      className={classnames(
        "p-4 grid grid-cols-12 items-center gap-4 w-full border-b-1 border-gray last:border-none mt-4 first:mt-0",
        faded && "opacity-50"
      )}
    >
      <Icon icon="coins" color="text-primary-light" size="xl" />

      <div
        className={classnames(
          "flex gap-2",
          selected ? "col-span-5" : "col-span-7"
        )}
      >
        {credits === 1 ? (
          <Trans
            type="tlg"
            weight="sb"
            color="text-gray-light"
            credits={credits}
          >
            {{credits}} Credit
          </Trans>
        ) : (
          <Trans
            type="tlg"
            weight="sb"
            color="text-gray-light"
            credits={credits}
          >
            {{credits}} Credits
          </Trans>
        )}
        {discount > 0 && (
          <Badge type="success">
            <Trans
              type="tlg"
              weight="sb"
              color="text-success-light"
              discount={discount}
            >
              {{discount}}% discount
            </Trans>
          </Badge>
        )}
      </div>
      <div
        className={classnames(
          selected ? "col-span-12 lg:col-span-6" : "col-span-4"
        )}
      >
        {React.cloneElement(children, {
          id,
          price,
          defaultPrice,
        })}
      </div>
    </div>
  );
};

const calculateDeal = (price, amount) => {
  // default is 1:1 for amount == 10

  const expectedPrice = amount / 100;
  const difference = expectedPrice - price;
  return 100 * (difference / expectedPrice);
};

export default BuyCreditsBundle;
