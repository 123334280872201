import React from "react";
import {userContext} from "UserContextProvider";

const useFindCourses = () => {
  const {user} = userContext();
  const [selectedSchoolId, setSelectedSchoolId] = React.useState(null);

  React.useEffect(() => {
    if (user?.school) setSelectedSchoolId(user.school.id);
  }, [user]);

  const [searchQuery, setSearchQuery] = React.useState("");

  const handleSearchQueryChange = e => {
    setSearchQuery(e.target.value);
  };

  return {
    selectedSchoolId,
    setSelectedSchoolId,
    searchQuery,
    handleSearchQueryChange,
    setSearchQuery,
  };
};

export default useFindCourses;
