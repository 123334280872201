import React from "react";
import {Icon} from "Components";

export const alertIconColors = {
  primary: "text-primary-600",
  gray: "text-gray-600",
  success: "text-success-600",
  warning: "text-warning-600",
  error: "text-error-600",
};

const iconBorderColor = {
  primary: "border-primary-600",
  gray: "border-gray-600",
  success: "border-success-600",
  warning: "border-warning-600",
  error: "border-error-600",
};

const icons = {
  primary: "circle-info",
  gray: "circle-info",
  success: "circle-check",
  warning: "triangle-exclamation",
  error: "circle-xmark",
};

const iconSizes = {
  xs: "lg",
  sm: "md",
  md: "lg",
  lg: "lg",
};

const AlertIcon = ({type, icon, size}) => {
  if (icon) return icon;
  return (
    <Icon
      icon={`fa-regular fa-${icons[type]}`}
      className={alertIconColors[type]}
      size="lg"
    />
  );
};

export default AlertIcon;
