import React from "react";
import useGetCourseContributions from "API/queries/contributions/useGetCourseContributions";
import {groupBy} from "lodash";
import moment from "moment";

const DATE_INTERVAL = 10 * 60 * 1000;

const useCourseContributions = () => {
  const {courseId} = React.useContext(window.CourseContext);

  const [unFilteredContribution, loading] = useGetCourseContributions({
    courseId,
    pagination: {
      limit: 30,
      offset: 0,
      orderDir: "desc",
      orderBy: "created_at",
    },
  });

  // Contributions are already sorted by date

  // Filter out the contributions where user is null

  const contributions = React.useMemo(() => {
    if (loading) {
      return [];
    }

    // Filter out the contributions where user is null or the contribution is a comment
    let filteredContributions =
      unFilteredContribution?.filter(
        contribution =>
          contribution.user &&
          contribution.user.id !== 1 && // 1 is the system user
          contribution.objectType !== "Comment" // Don't show exercise notes
      ) || [];

    filteredContributions = [...filteredContributions].sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return groupContributions(filteredContributions);
  }, [loading]);

  return {
    contributions,
    loading,
  };
};

const groupContributions = allContributions => {
  if (!allContributions.length) {
    return [];
  }
  // The contributions are already sorted by date but each object is counted as a separate contribution
  // We want to group contributions that are done within the same 1 hour interval and count them as one contribution with the count of the contributions

  // Round the date to the nearest 1 hour

  let contributions = allContributions.map(contribution => ({
    ...contribution,
    createdAt: getDisplayDate(contribution.createdAt),
  }));

  // Group the contributions by date
  const groupedContributions = groupBy(contributions, "createdAt");

  // Group each date group by user
  Object.keys(groupedContributions).forEach(date => {
    groupedContributions[date] = {
      date,
      // Create groups for each user
      users: groupBy(groupedContributions[date], "user.id"),
    };
  });

  // map the users to an array but also add the count of the contributions for each user
  contributions = Object.keys(groupedContributions).map(date => {
    return Object.values(groupedContributions[date].users).map(
      userContributions => {
        const user = userContributions[0].user; // same user for all contributions

        const contributionsCountByType = groupBy(
          userContributions,
          "objectType"
        );

        return {
          date,
          user,
          ...contributionsCountByType,
        };
      }
    );
  });

  // Flatten the array
  contributions = contributions.flat();

  // sort the contributions by date desc
  contributions.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return contributions.slice(0, 10); // only show last 10 contributions
};

const getDisplayDate = createdAt => {
  const dateMoment = moment(createdAt).startOf("hour");

  let dateDisplay = dateMoment.format("LL");

  if (dateMoment.isSame(moment(), "month")) {
    dateDisplay = dateMoment.fromNow();
  }

  return dateDisplay;
};

export default useCourseContributions;
