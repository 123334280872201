import React from "react";
import {Button, Trans} from "Components";

const ModalActions = ({
  action = null,
  closable,
  showCancelButton,
  handleClose,
  type,
}) => {
  if (action === null && !closable) return null;

  return (
    <div className="flex gap-2 mt-8">
      {closable && showCancelButton && (
        <Button
          fluid
          type="gray"
          className="w-full"
          key="cancel"
          onClick={handleClose}
        >
          <Trans>Close</Trans>
        </Button>
      )}
      {action && action.label && action.onClick && (
        <Button
          fluid
          type="primary"
          key="action"
          className="w-full"
          onClick={action.onClick}
          disabled={action.disabled}
          loading={action.loading}
        >
          {action.label}
        </Button>
      )}
    </div>
  );
};

export default ModalActions;
