import { useHistory, useLocation } from "react-router-dom";

const useHandleLoginClick = () => {
  let location = useLocation();
  const history = useHistory();
  const handleLoginClick = e => {
    if (e) {
      e.preventDefault();
    }

    history.push({
      pathname: "/modal/login",
      state: {
        background: location,
      },
    });
  };

  return handleLoginClick;
};

export default useHandleLoginClick;
