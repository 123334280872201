import React from "react";
import {RadialProgress} from "react-radial-progress-indicator";
import {kollinGrey} from "utilities/constants/COLORS";
import {
  avgScore,
  avgSelfEvaluation,
  nCompleted,
} from "utilities/exerciseUtilities/exerciseListOperations";
import {scoreToDifficultyColor} from "utilities/exerciseUtilities/formatScore";
import {Typography} from "Components";

const ProgressBead = ({loading, height, exercises, animated, user}) => {
  let size = height || 32;

  if (loading) return <ProgressBeadMock size={size} />;

  let color = scoreToDifficultyColor(avgSelfEvaluation(exercises));
  let end = nCompleted(exercises);
  let score = avgScore(exercises);
  let text = isNaN(score) ? "." : Number(score).toFixed(1);

  return (
    <RadialProgress
      height={size}
      width={size}
      ringThickness={0.1}
      steps={exercises.length}
      step={end}
      segmented={false}
      duration={1000}
      fontRatio={2.5}
      ringFgColour={color}
      ringBgColour={kollinGrey}
      text={() => (
        <Typography type="txs" weight="md">
          {text}
        </Typography>
      )}
    ></RadialProgress>
  );
};

const ProgressBeadMock = ({size}) => {
  let state;
  const [reversed, setReversed] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setReversed(true);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  state = {
    start: 0,
    end: 10,
    bg: "#ccc",
    fg: "#666",
  };

  if (reversed) {
    state = {start: 0, bg: state.fg, fg: state.bg};
  }

  return (
    <RadialProgress
      height={size}
      width={size}
      backgroundColour={kollinGrey}
      backgroundTransparent
      duration={1000}
      fontRatio={2.5}
      ringBgColour={state.bg}
      ringFgColour={state.fg}
      ringThickness={0.1}
      segmented={false}
      startStep={state.start}
      step={state.end}
      steps={10}
      text={() => ".."}
    />
  );
};

export default ProgressBead;
