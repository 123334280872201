import React from "react";
import Summer2022CampaignHeroSection from "routes/Campaign/Summer2022Campaign/Summer2022CampaignHeroSection";
import Summer2022CampaignCta from "routes/Campaign/Summer2022Campaign/Summer2022CampaignCTA";
import Summer2022CampaignTestimonials from "routes/Campaign/Summer2022Campaign/Summer2022CampaignTestimonials";
import Summer2022CampaignCourses from "routes/Campaign/Summer2022Campaign/Summer2022CampaignCourses";
import { Typography } from "Components";
import useGetSummer2022CampaignProgramMapping from "API/cms/campaignSummer2022/useGetSummer2022CampaignProgramMapping";
import Summer2022CampaignIntro from "routes/Campaign/Summer2022Campaign/Summer2022CampaignIntro";
import Summer2022CampaignFeatures from "routes/Campaign/Summer2022Campaign/Summer2022CampaignFeatures";
import Footer from "routes/Landing/Footer/Footer";
import Summer2022CampaignBottomCTA from "routes/Campaign/Summer2022Campaign/Summer2022CampaignBottomCTA";
import useSummer2022Campaign from "routes/Campaign/Summer2022Campaign/useSummer2022Campaign";
import Summer2022CampaignAuth from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignAuth";

/**
 * This is just a wrapper component so we can fetch the
 * ProgramData ContenfulId from the programKey in the url.
 * @param programKey
 * @returns {JSX.Element|null}
 * @constructor
 */
const Summer2022Campaign = ({ programKey }) => {
  const { user } = React.useContext(window.UserContext);
  const [programMapping, loading] = useGetSummer2022CampaignProgramMapping();

  if (loading) {
    return null;
  }

  const programDataContentfulId = programMapping[programKey];

  if (!programDataContentfulId) {
    return <Typography type="dxl">Not found!</Typography>;
  }

  return (
    <Summer2022CampaignProgram
      programKey={programKey}
      programDataContentfulId={programDataContentfulId}
    />
  );
};

export const Summer2022CampaignProgramContext = React.createContext(null);

const Summer2022CampaignProgram = ({
  programDataContentfulId,
  programKey,
  disabled,
}) => {
  const {
    campaignData,
    campaignDataLoading,
    landingPageSections,
    landingPageSectionsLoading,
    onBoarding,
    initiateOnBoarding,
  } = useSummer2022Campaign({
    programDataContentfulId,
    disabled,
  });

  // If the campaign data is loading
  if (campaignDataLoading || landingPageSectionsLoading) return null;

  // If user clicks on the "Get started" button we show them the OnBoarding component
  if (onBoarding) {
    return (
      <Summer2022CampaignProgramContext.Provider
        value={{ ...campaignData, programKey, initiateOnBoarding, disabled }}
      >
        <Summer2022CampaignAuth />
      </Summer2022CampaignProgramContext.Provider>
    );
  }

  return (
    <Summer2022CampaignProgramContext.Provider
      value={{ ...campaignData, initiateOnBoarding, disabled }}
    >
      <div className="w-screen bg-primary-25 overflow-x-hidden">
        <Summer2022CampaignHeroSection image={campaignData.heroImage} />

        <div className="md:w-[36rem] md:mx-auto">
          <Summer2022CampaignIntro landingPageSections={landingPageSections} />

          <Summer2022CampaignCta
            {...campaignData}
            programKey={programKey}
            onClick={initiateOnBoarding}
            disabled={disabled}
          />
          <div className="px-3">
            <Summer2022CampaignCourses courseIds={campaignData.courses} />

            {/*<Summer2022CampaignSignUpForm />*/}
            <Summer2022CampaignTestimonials
              testimonials={campaignData.testimonialsCollection?.items}
            />
          </div>
          <Summer2022CampaignFeatures
            landingPageSections={landingPageSections}
          />
          <Summer2022CampaignBottomCTA
            testimonials={campaignData.testimonialsCollection?.items}
          />
        </div>
      </div>
      <Footer hideLanguagePicker />
    </Summer2022CampaignProgramContext.Provider>
  );
};

export const Summer2022CampaignSectionTitle = ({ children }) => {
  return (
    <Typography
      type="dsm"
      weight="sb"
      color="text-primary-700"
      className="mb-3 mt-8 text-center"
    >
      {children}
    </Typography>
  );
};

export default Summer2022Campaign;
