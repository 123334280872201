import React from "react";
import useStudentVerification from "Widgets/User/StudentVerification/useStudentVerification";
import OTP from "Widgets/User/OTP/OTP";

const StudentVerification = ({
  title = null,
  subTitle = null,
  inputHint = null, // This is the hint for the input field
  inlineForm = false,
  showModal = true, // Modal is for the code input screen
  onComplete = () => {}, // This is the callback function that will be called after the verification is complete
  transparent = true,
  autoSubmit = false,
  initialEmail = null,
  emailExtension = null,
}) => {
  const {showVerification, refetch} = useStudentVerification();

  if (!showVerification) return null;

  const onVerificationComplete = async () => {
    await refetch();
    onComplete();
  };

  return (
    <OTP
      transparent={transparent}
      title={title}
      subTitle={subTitle}
      inlineForm={inlineForm}
      action="STUDENT_EMAIL_VERIFICATION"
      showModal={showModal}
      onVerificationComplete={onVerificationComplete}
      inputHint={inputHint}
      autoSubmit={autoSubmit}
      initialEmail={initialEmail}
    />
  );
};

export default StudentVerification;
