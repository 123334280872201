import React from "react";
import {Icon} from "../index";
import useGetExercise from "../../../API/queries/exercise/useGetExercise";
import {Trans, Typography} from "Components";

const BreadCrumbExercise = ({exerciseId, examId}) => {
  if (!exerciseId) return null;
  const [exercise, exerciseLoading] = useGetExercise(exerciseId);

  if (exerciseLoading) return null;
  const {number, date} = exercise;
  const examDate = new Date(date.substring(0, 10));
  const examExtension = date.slice(10).trim();
  const examDateFormatted = examDate.toLocaleDateString("sv-SE", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return (
    <div className="flex gap-1">
      <Icon icon="chevron-right" size="md" />
      {examId ? (
        <Trans type="gray" number={{number}} weight="md" color="text-gray-500">
          Exercise {number}
        </Trans>
      ) : (
        <div className="flex gap-1">
          <Typography type="gray" weight="md" color="text-gray-500">
            {examDateFormatted} #{number}
          </Typography>
          {examExtension && (
            <Typography type="gray" weight="md" color="text-gray-500">
              ({examExtension})
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default BreadCrumbExercise;
