import React from "react";
import useGroupDiscounts from "Widgets/Group/GroupDiscounts/useGroupDiscounts";
import GroupDiscountsBanner from "Widgets/Group/GroupDiscounts/GroupDiscountsBanner/GroupDiscountsBanner";
import {Alert, FileUpload, Link, Trans, Typography} from "Components";
import useGroupMembershipVerification from "Widgets/Group/GroupMembershipVerification/useGroupMembershipVerification";
import GroupMembershipVerificationStatus from "Widgets/Group/GroupMembershipVerification/GroupMembershipVerificationStatus/GroupMembershipVerificationStatus";
import classnames from "classnames";
import GroupDiscountFreeActivation from "Widgets/Group/GroupDiscounts/GroupDiscountFreeActivation";

const GroupMembershipVerification = ({size = "md", className}) => {
  const {user} = React.useContext(window.UserContext);
  const {
    discount,
    group,
    discountApplicable,
    loading,
    groupMembershipId,
  } = useGroupDiscounts();

  const {
    onFileUpload,
    loading: fileUploadLoading,
    uploadError,
    fileUrl,
    resetFileUrl,
  } = useGroupMembershipVerification();
  if (
    loading ||
    group === null ||
    discount === null ||
    !user?.id ||
    (discountApplicable && discount.discountPercentage < 100)
  )
    return null;

  /**
   * If the discount is not applicable, the user must verify their group membership
   * by uploading their transcript.
   */

  const groupName = group.name;

  return (
    <div
      className={classnames("p-4 bg-primary-800 dark rounded-xl", className)}
    >
      <GroupDiscountsBanner
        size={size}
        showDiscountPercentage
        subTitle={
          <Typography group={groupName} type="tmd" color="text-gray">
            {/*In order to use this discount, you need to verify your membership in{" "}*/}
            {/*{{groupName}}. You can do this by uploading your registration*/}
            {/*transcript.*/}
            För att använda denna rabatt måste du verifiera ditt medlemskap i{" "}
            {groupName}. Du kan göra detta genom att ladda upp ditt
            registreringsintyg som du hittar på:{" "}
            <Link
              href="https://www.student.ladok.se/student/app/studentwebb/intyg/skapa-intyg"
              target="_blank"
              rel="noopener noreferrer"
              className="text-fuchsia-100 hover:underline"
              iconRight="fas fa-external-link-alt"
            >
              Ladok
            </Link>
          </Typography>
        }
        padding="p-0"
      />
      {fileUrl && !discountApplicable && (
        <GroupMembershipVerificationStatus
          resetFileUrl={resetFileUrl}
          groupId={group.id}
          fileUrl={fileUrl}
          groupMembershipId={groupMembershipId}
        />
      )}

      {!fileUrl && !discountApplicable && (
        <div className="mt-4">
          <FileUpload
            title={<Typography>Ladda upp ditt registreringsintyg</Typography>}
            subTitle={
              <Typography>
                Klick här eller dra och släpp PDF-filen här
              </Typography>
            }
            showSubTitle
            className="border-dashed border-2"
            multiple={false}
            compact={size === "md"}
            accept="application/pdf"
            onFilesAccepted={onFileUpload}
            loading={fileUploadLoading}
            disabled={fileUploadLoading}
          />
          {uploadError && (
            <Alert title={uploadError} type="error" className="mt-4" />
          )}
        </div>
      )}
    </div>
  );
};

export default GroupMembershipVerification;
