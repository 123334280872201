import React from "react";
import EmptyState from "Components/EmptyState/EmptyState";
import {Button, Trans} from "Components";
import {useHistory} from "react-router-dom";
import Onboarding from "routes/Onboarding/Onboarding";

const DashboardCoursesProgramEmptyState = () => {
  const {user} = React.useContext(window.UserContext);

  const history = useHistory();

  const {openModal, closeModal} = React.useContext(window.GlobalModalContext);

  // Check if onboarding is complete
  const onboardingComplete = React.useMemo(() => {
    return user.schoolId !== null && user.programId !== null;
  }, [user]);

  if (!onboardingComplete) {
    return (
      <Onboarding
        customHeader={
          <Trans type="tlg" weight="sb" color="text-gray-light">
            Complete your profile to get a personalized course plan
          </Trans>
        }
        showFooter={false}
        stacked
        progressStepsOrientation="vertical"
        progressStepsSize="md"
        compact
      />
    );
  }

  return (
    <div className="mt-8">
      <EmptyState
        size="sm"
        type="gray"
        illustration="cloud"
        title={<Trans>Oj vad pinsamt</Trans>}
        subTitle={
          <Trans>
            Tyvärr har vi inte hunnit fixa utbildningsplanen för ditt program
            än. Om du inte hittar dina kurser kan du skicka en förfrågan så
            lägger vi upp de så fort som möjligt!
          </Trans>
        }
        secondaryAction={
          <Button onClick={() => history.push("/courses")}>
            Till alla kurser
          </Button>
        }
        primaryAction={
          <Button onClick={() => openModal("REQUEST_COURSE")}>
            Skicka förfrågan
          </Button>
        }
      />
    </div>
  );
};

export default DashboardCoursesProgramEmptyState;
