import React from "react";
import {ResponsiveLine} from "@nivo/line";
import {ResponsivePie} from "@nivo/pie";
import {linearGradientDef} from "@nivo/core";
import {ResponsiveBar} from "@nivo/bar";

const MetricChart = ({
  data,
  chartType = "line",
  colors = {
    scheme: "pastel2",
  },
  gradient = false,
  enablePoints = false,
  ...props
}) => {
  const Chart = {
    line: ResponsiveLine,
    pie: ResponsivePie,
    bar: ResponsiveBar,
  }[chartType];

  return (
    <Chart
      data={data}
      margin={{top: 16, right: 0, bottom: 16, left: 0}}
      animate={true}
      enableArea={true}
      curve="basis"
      enableGridX={false}
      enableGridY={false}
      enablePoints={enablePoints}
      enableLabel={false}
      showLegend={false}
      enableCrosshair={false}
      layers={[
        "lines",
        "areas",
        "markers",
        "bars",
        "mesh",
        "arcs",
        "parts",
        "labels",
        "annotations",
      ]}
      // Pie chart props
      innerRadius={0.5}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      startAngle={90}
      endAngle={-90}
      // Common props
      colors={colors}
      defs={[
        linearGradientDef("gradientA", [
          {offset: 0, color: "inherit"},
          {offset: 100, color: "inherit", opacity: 0},
        ]),
      ]}
      fill={[{match: "*", id: gradient ? "gradientA" : null}]}
      {...props}
    />
  );
};

export default MetricChart;
