import React from "react";
import {Loading} from "Components/_Elements";
const ReactGeogebra = React.lazy(() => import("react-geogebra"));

const GeogebraDisplayView = ({equation, width}) => {
  const onAppletLoad = () => {
    const api = window.geogebra;

    // Initialize the view depending on the equation, e.g. 2D or 3D
    initView(equation);

    // Render the equation
    // Sample function for testing: f(x,y)=\ln \left(x^{2}+x y^{2}-4\right)
    api.evalLaTeX(equation);

    const objects = api.getAllObjectNames();

    objects.forEach(object => {
      api.setColor(object, 110, 139, 226);
    });
  };

  return (
    <React.Suspense fallback={<Loading />}>
      <ReactGeogebra
        key={equation}
        id="geogebra"
        borderColor="#fff"
        width={width}
        height={width}
        showMenuBar={false}
        showToolBar={false}
        showAlgebraInput={false}
        showResetIcon={false}
        enableRightClick={false}
        enableUndoRedo={false}
        reloadOnPropChange={true}
        appletOnLoad={onAppletLoad}
        errorDialogsActive={false}
        debug={false}
        showLogging={false}
      />
    </React.Suspense>
  );
};

const initView = equation => {
  const api = window.geogebra;
  // Check if function is 3D or 2D

  const lhs = equation.split("=")[0];
  const rhs = equation.split("=")[1];

  // TODO: Handle integrals
  // const isIntegral = equation.match(/^\\i{1,3}nt_\{?.+}?\^\{?.+}?/gm);
  //
  // if (isIntegral) {
  //   handleIntegral(api, equation);
  //   return;
  // }

  const is3D =
    equation.match(/^[A-Z-a-z]\(([A-Za-z]\s?,?\s?){2}\)\s?:?=/gm) ||
    (lhs.includes("x") && lhs.includes("y")) ||
    (rhs.includes("x") && rhs.includes("y"));

  if (is3D) {
    api.enable3D(true);
    api.setPerspective("T"); // 3D perspective: see more: https://wiki.geogebra.org/en/SetPerspective_Command
    api.setGridVisible(false);
    api.setAxisLabels(3, "x", "y", "z");
    return;
  }

  api.setGridVisible(true);
  api.setPerspective("G");
  api.setAxisLabels(3, "x", "y");
};
/**
 * Handle integrals, s.a. \int_{2}^{3} x e^{x^{2}} d x => Integral[2,3,x e^{x^{2}}]
 * @param api
 * @param equation
 */
const handleIntegral = (api, equation) => {
  const startRegex = "\\\\i{1,3}nt";
  const lBRegex = "_\\{[\\\\ {}a-z0-9\\s]+}";
  const uBRegex = "\\^\\{[\\\\ \\{}a-z0-9\\s]+}";
  const funcRegex = ".+";
  const dRegex = "\\s?d\\s?[a-z]";

  const integralRegex = new RegExp(
    `^${startRegex}(${lBRegex})(${uBRegex})(${funcRegex})(?:${dRegex})+\$`,
    "gism"
  );

  const integralParts = [...equation.matchAll(integralRegex)][0];

  let l = integralParts[1].replace(/^_{/g, "").replace(/}$/g, "");
  let u = integralParts[2].replace(/^\^{/g, "").replace(/}$/g, "");
  let f = integralParts[3];
};

export default GeogebraDisplayView;
