import React from "react";
import {useHistory} from "react-router-dom";
import subdomains from "Providers/GroupProvider/subdomains";
import useGetGroup from "API/queries/groups/useGetGroup";
import {userContext} from "UserContextProvider";
import paramAgnosticPathCompare from "utilities/paramAgnosticPathCompare/paramAgnosticPathCompare";
import {uniqBy} from "lodash";

const useGroupProviderState = routes => {
  const {user} = userContext();
  const history = useHistory();
  const currentPath = history.location.pathname;
  const isSuperAdmin = user?.admin;

  const sub = window.location.host.split(".")[0];
  const id = subdomains[sub].id;

  const [group, loading] = useGetGroup({
    id,
    includeSubGroups: true,
    includeMembers: true, // temp
  });

  const currentRoute = React.useMemo(() => {
    return routes.find(route =>
      paramAgnosticPathCompare(route.path, currentPath)
    );
  }, [routes, currentPath]);

  const currentRouteIsAuthRoute = React.useMemo(() => {
    return currentRoute && currentRoute.group === "auth";
  }, [routes, currentPath]);

  const currentUserIsMember = React.useMemo(() => {
    if (isSuperAdmin) return true;

    const rootMembership =
      group && group.currentUserMembership && group.currentUserMembership.id;

    const subGroupMembership =
      group &&
      group.subGroups &&
      group.subGroups.some(sg => {
        return sg.currentUserMembership && sg.currentUserMembership.id;
      });

    return rootMembership || subGroupMembership;
  }, [user, group]);

  const currentUserIsAdmin = React.useMemo(() => {
    return (
      (group &&
        group.currentUserMembership &&
        group.currentUserMembership.admin) ||
      user?.admin
    );
  }, [user, group]);

  if (group) {
    group.allMembers = uniqBy(
      [
        ...(group?.members || []),
        ...(group?.subGroups?.reduce(
          (acc, sg) => [...acc, ...(sg.members || [])],
          []
        ) || []),
      ],
      "user.id"
    );
  }

  return {
    groupId: id,
    group,
    loading,
    currentRoute,
    currentRouteIsAuthRoute,
    currentUserIsMember,
    currentUserIsAdmin,
  };
};

export default useGroupProviderState;
