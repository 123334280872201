import React from "react";
import {AnimatedCheckbox, Avatar, FileUpload, message, Trans} from "Components";
import useUpdateCurrentUserAvatar from "API/mutations/user/useUpdateCurrentUserAvatarMutation";
import renameInputFile from "utilities/renameInputFile";

const ProfilePicture = ({onComplete}) => {
  const {user} = React.useContext(window.UserContext);
  const {updateCurrentUserAvatar, loading, data} = useUpdateCurrentUserAvatar();

  if (data && data.updateCurrentUserAvatar?.ok) {
    setTimeout(() => {
      onComplete();
    }, 1000);

    return (
      <div className="flex gap-2 items-end">
        <AnimatedCheckbox size="md" />
        <Trans type="dxs">Your profile picture has been updated</Trans>
      </div>
    );
  }

  return (
    <FileUpload
      icon={<Avatar user={user} alt="profile picture" size="2xl" />}
      disabled={loading}
      maxSize={5000000} // 5 MB
      onFilesAccepted={files => {
        if (files.length === 0) {
          message.error(<Trans>Something went wrong, please try again.</Trans>);
          return;
        }

        const extension = files[0].name.split(".").pop();
        const file = renameInputFile(files[0], `avatar.${extension}`);
        updateCurrentUserAvatar({
          variables: {
            file,
          },
        });
      }}
      onError={error => {
        message.error(<Trans>Something went wrong, please try again.</Trans>);
        console.log(error);
      }}
      subTitle={<Trans>SVG, PNG, JPG or GIF (max. 2 MB)</Trans>}
    />
  );
};

export default ProfilePicture;
