import { useMutation } from "@apollo/react-hooks";
import { GET_EXERCISE_THREADS } from "API/queries/thread/useGetExerciseThreads";
import gql from "graphql-tag";

const CREATE_THREAD = gql`
  mutation createThread(
    $exerciseId: ID!
    $text: String!
    $username: String
    $anonymous: Boolean
    $url: String
  ) {
    createThread(
      exercise_id: $exerciseId
      text: $text
      username: $username
      anonymous: $anonymous
      url: $url
    ) {
      ok
      error
    }
  }
`;

const useCreateThreadMutation = exerciseId => {
  const [createThread, { data, error, loading }] = useMutation(CREATE_THREAD, {
    refetchQueries: [
      { query: GET_EXERCISE_THREADS, variables: { exerciseId } },
    ],
  });

  if (error) {
    console.log(`Error in useCreateThreadMutation: ${error}`);
  }
  return { createThread, data };
};

export default useCreateThreadMutation;
