import React from "react";
import Typography from "Components/_Elements/Typography/Typography";
import AnimatedCheckbox from "Components/Animated/AnimatedCheckbox/AnimatedCheckbox";
import {Icon} from "Components/_Elements/index";
import {motion} from "framer-motion";
const InputSuccessMessage = ({successMessage}) => {
  if (!successMessage) return null;

  return (
    <div className="flex items-center gap-1 mt-1.5">
      <motion.div
        initial={{opacity: 0, scale: 0}}
        animate={{opacity: 1, scale: 1}}
      >
        <Icon icon="fas fa-check-circle" size="sm" color="text-success-light" />
      </motion.div>
      <Typography type="tsm" color="text-success-light">
        {successMessage}
      </Typography>
    </div>
  );
};

export default InputSuccessMessage;
