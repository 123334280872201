import React from "react";
import {Badge, Icon, Link, Skeleton} from "Components";
import PreviewButton from "../PreviewButton/PreviewButton";
import useGetTag from "../../API/queries/tag/useGetTag";
import {CourseParamsContext} from "../../routes/Course/CourseRouter";
import {CourseContext} from "routes/Course/CourseContextProvider";

const ExerciseTag = ({tagId, tab = "exercises", previewMode = false}) => {
  const {coursePurchased} = React.useContext(CourseContext);
  const [tag, tagLoading] = useGetTag(tagId, false, true);
  const {getTagLink} = React.useContext(CourseParamsContext);
  const tagLink = getTagLink(tagId);

  if (tagLoading) {
    return <Skeleton />;
  }

  const type = "primary";

  if (previewMode) {
    return (
      <PreviewButton
        route={tagLink}
        urlParams={{
          previewTab: tab,
        }}
        size="md"
        disabled={!coursePurchased}
      >
        <Badge
          type={coursePurchased ? type : "darkGray"}
          size="md"
          className="flex gap-2 items-center group cursor-pointer hover:bg-primary-200"
        >
          {tag.name}
          {coursePurchased && (
            <Icon
              icon="fa-light fa-sidebar-flip"
              size="md"
              color={type}
              sharp
            />
          )}
        </Badge>
      </PreviewButton>
    );
  }

  return (
    <Link to={tagLink} size="md">
      <Badge
        type={type}
        size="md"
        className="flex gap-2 items-center group cursor-pointer hover:bg-primary-200"
      >
        {tag.name}
      </Badge>
    </Link>
  );
};

export default ExerciseTag;
