import React from "react";
import {Carousel} from "Components";
import {useLocation} from "react-router-dom";
import useGetCourseStudyPlans from "API/queries/StudyPlan/useGetCourseStudyPlans";
import StudyPlanCard from "Components/StudyPlanCard/StudyPlanCard";
import {CourseLandingContext} from "routes/Course/CourseLanding/useCourseLandingContext";
import LandingPageSection from "Components/LandingPageSection/LandingPageSection";

const CourseStudyPlanBanner = () => {
  const location = useLocation();

  const {navigationState} = React.useContext(CourseLandingContext);
  const {course, landingPageContent} = React.useContext(CourseLandingContext);

  const landingPageSectionId = React.useMemo(
    () =>
      landingPageContent?.find(content => content.title === "studyplans")?.sys
        ?.id,
    []
  );

  // const [studyPlan, loading] = useGetCourseStudyPlan(courseId);
  const [studyPlans, loading] = useGetCourseStudyPlans(course.id);

  if (loading || studyPlans.length === 0) return <></>;

  return (
    <div ref={navigationState.studyPlansRef} className="mt-8">
      <LandingPageSection sectionId={landingPageSectionId} />

      <div>
        <Carousel showDots>
          {studyPlans.map((studyPlan, i) => (
            <StudyPlanCard
              showUnpurchased
              key={i}
              name={studyPlan.name}
              description={studyPlan.description}
              author={studyPlan.author}
              studyPlan={studyPlan}
              backUrl={location.pathname}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default CourseStudyPlanBanner;
