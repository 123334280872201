import React from "react";
import {StringParam, useQueryParams} from "use-query-params";

const useTabsUrlState = urlParamName => {
  const previewSidebarContext = React.useContext(window.PreviewSidebarContext);

  const previewMode = previewSidebarContext
    ? previewSidebarContext.previewMode
    : false;

  const [urlState, setUrlState] = useQueryParams({
    [urlParamName]: StringParam,
    previewTab: StringParam,
  });

  const currentTabInUrl = previewMode
    ? urlState.previewTab
    : urlState[urlParamName];

  const setCurrentTabInUrl = tabKey => {
    console.log("setCurrentTabInUrl", tabKey);
    if (previewMode) {
      setUrlState({previewTab: tabKey});
    } else {
      const newUrlState = {
        ...urlState,
        [urlParamName]: tabKey,
      };

      console.log("newUrlState", newUrlState);
      setUrlState(newUrlState);
    }
  };

  React.useEffect(() => {
    // Get tab and previewTab from the query string, if they exist and are different update the urlState
    const urlParams = new URLSearchParams(window.location.search);
    const tabKey = urlParams.get(urlParamName);

    const previewTab = urlParams.get("previewTab");

    if (tabKey && tabKey !== urlState[urlParamName]) {
      setUrlState({
        [urlParamName]: tabKey,
      });
    }

    if (previewTab && previewTab !== urlState.previewTab) {
      setUrlState({
        previewTab,
      });
    }
  }, [window.location.search]);

  return {
    currentTabInUrl,
    setCurrentTabInUrl,
  };
};

export default useTabsUrlState;
