import React from "react";
import SocialLoginButton from "./SocialLoginButton";
import {Trans} from "Components";
import classnames from "classnames";

const SocialLogin = ({
  action = "login",
  socialButtonsCompact = false,
  disableFacebookButton = false,
  disableGoogleButton = false,
  facebookButtonPrompt = <Trans>Login with Facebook</Trans>,
  googleButtonPrompt = <Trans>Login with Google</Trans>,
  handleAuthResponse,
  providers = ["FACEBOOK", "GOOGLE"],
}) => {
  const showFacebookButton = providers.includes("FACEBOOK");
  const showGoogleButton = providers.includes("GOOGLE");

  return (
    <div
      className={classnames(
        "flex gap-2 w-full grow items-stretch",
        socialButtonsCompact ? "flex-row" : "flex-col"
      )}
    >
      {showFacebookButton && (
        <div className="w-full grow">
          <SocialLoginButton
            fluid
            action={action}
            disabled={disableFacebookButton}
            handleAuthResponse={handleAuthResponse}
            provider="FACEBOOK"
          >
            {facebookButtonPrompt}
          </SocialLoginButton>
        </div>
      )}
      {showGoogleButton && (
        <div className="w-full grow ">
          <SocialLoginButton
            fluid
            action={action}
            disabled={disableGoogleButton}
            handleAuthResponse={handleAuthResponse}
            provider="GOOGLE"
          >
            {googleButtonPrompt}
          </SocialLoginButton>
        </div>
      )}
    </div>
  );
};

export default SocialLogin;
