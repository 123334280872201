export const sizesMap = {
  xs: "px-1 py-1 rounded-md ",
  sm: "px-1.5 py-2 rounded-md ",
  md: "px-2 py-2.5 rounded-lg ",
  lg: "px-2.5 py-3 rounded-lg ",
  xl: "px-3 py-3.5 rounded-xl",
  "2xl": "px-3.5 py-4 rounded-xl",
};

export const textSizeMap = {
  xs: "text-body5",
  sm: "text-body4",
  md: "text-body3",
  lg: "text-body2",
  xl: "text-body1",
  "2xl": "text-h6",
};
