import React from "react";
import {Button, Typography} from "Components/_Elements/index";
import {capitalizeFirstLetter} from "utilities/text";
import moment from "moment/moment";

const DatePickerCalendarHeader = ({
  currentPage,
  cellClassNames,
  onPrevMonth,
  onNextMonth,
}) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <Button
          type="link"
          size="sm"
          icon="chevron-left"
          onClick={onPrevMonth}
        />

        <Typography weight="md">
          {capitalizeFirstLetter(currentPage.format("MMMM YYYY"))}
        </Typography>

        <Button
          type="link"
          size="sm"
          icon="chevron-right"
          onClick={onNextMonth}
        />
      </div>
      <div className="mt-4 grid grid-cols-7">
        {moment.weekdaysMin(true).map((day, index) => (
          <div className={cellClassNames}>
            <Typography key={index} weight="md">
              {capitalizeFirstLetter(day)}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatePickerCalendarHeader;
