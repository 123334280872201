import useGetManyUserEvaluations from "API/queries/userEvaluation/useGetManyUserEvaluations";

const useStudyPlanBlockEvaluation = steps => {
  // Need to get all the step evaluations for the given block and group them by id:

  const studyPlanStepsEvaluationArgs = steps.map(step => ({
    objectId: step.sys.id,
    objectName: "StudyPlanStep",
    source: "StudyPlan",
  }));

  const [
    stepEvaluationsList,
    stepEvaluationsListLoading,
  ] = useGetManyUserEvaluations(studyPlanStepsEvaluationArgs);

  // Create an object of all exercises's evaluations where we have the evaluation for each exercise that can be obtained by ID of that exercise.

  let stepEvaluations = {};

  if (!stepEvaluationsListLoading) {
    stepEvaluationsList.forEach(stepEvaluation => {
      if (stepEvaluation.length) {
        stepEvaluations[stepEvaluation[0].objectId] =
          stepEvaluation[0].evaluation;
      }
    });
  }

  return [stepEvaluations, studyPlanStepsEvaluationArgs];
};

export default useStudyPlanBlockEvaluation;
