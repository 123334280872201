import React from "react";
import {
  Alert,
  Button,
  Icon,
  Loader,
  ProgressSteps,
  Typography,
} from "Components/_Elements";
import {Math, Trans} from "Components/index";
import {motion} from "framer-motion";
import classnames from "classnames";
const GeogebraMenu = () => {
  const {
    toggleSelector,
    selectorActive,
    visualisationActive,
  } = React.useContext(GeogebraContext);
  return (
    <div className="py-4 pr-4">
      <div className="flex  gap-4 items-center">
        <Trans>
          Visualise functions and graphs from the exercise or solution text
        </Trans>

        <Button
          onClick={toggleSelector}
          type={selectorActive ? "destructive" : "secondary"}
        >
          {selectorActive ? <Trans>Cancel</Trans> : <Trans>Start</Trans>}
        </Button>
      </div>
      {selectorActive && (
        <div
          className={classnames(
            "flex gap-4 items-center mt-2",
            visualisationActive && "opacity-50"
          )}
        >
          <motion.span
            animate={{
              opacity: 1,
              x: visualisationActive ? 0 : [0, 10, 0, 10, 0],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              repeatType: "mirror",
            }}
          >
            <Icon icon="hand-point-left" size="lg" color="text-primary-light" />
          </motion.span>
          <Trans color="text-primary-light">
            Select the function you want to see
          </Trans>
        </div>
      )}

      <Alert
        className="mt-4"
        title={<Trans>This function is still under development</Trans>}
        closable={true}
        size="sm"
        subTitle={
          <Trans type="txs">
            Here are are some examples of functions you can try.
          </Trans>
        }
      >
        <div className="grid w-full  grid-cols-2  place-items-stretch gap-2 even:ml-2 even:bg-success-50">
          <div>
            <Typography type="txs" color="text-gray-light">
              <Math text="$f(x,y)=\ln \left(x^{2}+x y^{2}-4\right)$"></Math>
            </Typography>
          </div>
          <div>
            <Typography type="txs" color="text-gray-light">
              <Math text="$4 x^{2}+3 y^{2}=7$"></Math>
            </Typography>
          </div>
          <div>
            <Typography type="txs" color="text-gray-light">
              <Math text="$f(x)=\frac{1}{x^{2}-4}$"></Math>
            </Typography>
          </div>
          <div>
            <Typography type="txs" color="text-gray-light">
              <Math text="$z = x^2 + y^2$"></Math>
            </Typography>
          </div>
        </div>
      </Alert>
    </div>
  );
};

export default GeogebraMenu;
