import React from "react";
import {Comment} from "Components";
import Reactions from "Components/Reactions/Reactions";
import useExerciseDiscussionThreadOnNewComment from "Components/Workboard/ExerciseDiscussion/useExerciseDiscussionThreadOnNewComment";

const ExerciseDiscussionThread = ({thread}) => {
  const {user} = React.useContext(window.UserContext);
  const rootComment = thread.comments;

  const onNewCommentSubmit = useExerciseDiscussionThreadOnNewComment(thread);

  return (
    <Comment
      className="px-0 rounded-b-none border-l-0 border-r-0 border-t-0 border-b-1 last:border-b-0"
      repliesClassNames="px-3 border-l-2 border-primary border-t-0"
      createdAt={thread.created_at}
      content={rootComment.html}
      author={rootComment.asker}
      size="sm"
      extra={<Reactions parentId={rootComment.id} parentType="Comment" />}
      replies={rootComment.comments.map(comment => ({
        content: comment.html,
        author: comment.asker,
        createdAt: comment.created_at,
        extra: <Reactions parentId={comment.id} parentType="Comment" />,
      }))}
      expandable={true}
      currentUser={user}
      onNewCommentSubmit={onNewCommentSubmit}
    />
  );
};

export default ExerciseDiscussionThread;
