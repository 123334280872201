import React from "react";
import useExamMarkingSegments from "routes/Course/ExamWizard/ExamView/ExamMarking/useExamMarkingSegments/useExamMarkingSegments";
import {ExamViewContext} from "routes/Course/ExamWizard/ExamView/useExamViewContext";
import useManualMarking from "routes/Course/ExamWizard/ExamView/ExamMarking/ExamMarkingManualMarking/useManualMarking";
import useDocumentMarginDetector from "utilities/hooks/useDocumentMarginDetector";
import useExamMarkingContainerState from "routes/Course/ExamWizard/ExamView/ExamMarking/useExamMarkingContainerState";
import useExamMarkingAutoSegmentation from "routes/Course/ExamWizard/ExamView/ExamMarking/ExamMarkingAutoSegmentation/useExamMarkingAutoSegmentation";

export const ExamMarkingContext = React.createContext(null);

const useExamMarkingContext = () => {
  const {pdfUrl} = React.useContext(ExamViewContext);

  const {
    container,
    containerRef,
    initialWidth,
  } = useExamMarkingContainerState();

  const {
    segments,
    currentSegment,
    currentSegmentIsEmpty,
    updateCurrentSegment,
    createSegment,
    removeSegment,
    setSegmentFocus,
    ...rest
  } = useExamMarkingSegments({container, pdfUrl});

  const margins = useDocumentMarginDetector({
    container,
    initialWidth,
    dependencies: [segments, currentSegment],
  });

  const [targetSegmentCount, setTargetSegmentCount] = React.useState(
    segments.length
  );

  // // Uses the PDF text data to automatically segment the document

  const {
    autoSegmentationEnabled,
    toggleAutoSegmentationEnabled,
    logAutoSegmentation,
    autoSegmentationState,
  } = useExamMarkingAutoSegmentation({
    segments,
    setSegments: rest.setSegments,
    initialWidth,
    margins,
  });

  const {manualSelectionActive, toggleManualSelection} = useManualMarking({
    container,
    initialWidth,
    onSelectionComplete: updateCurrentSegment,
  });

  React.useEffect(() => {
    // Trigger manual selection if the current segment is empty
    if (!manualSelectionActive && currentSegmentIsEmpty && initialWidth) {
      toggleManualSelection();
    }
  }, [currentSegment]);

  return {
    // Document container state
    container,
    containerRef,

    // Segment state
    segments,
    currentSegment,
    currentSegmentIsEmpty,
    updateCurrentSegment,
    createSegment,
    removeSegment,
    setSegmentFocus,
    ...rest,

    targetSegmentCount,
    setTargetSegmentCount,

    // Manual marking state
    manualSelectionActive,
    toggleManualSelection,

    // Auto segmentation state
    logAutoSegmentation,
    autoSegmentationEnabled,
    toggleAutoSegmentationEnabled,
    autoSegmentationState,
  };
};

export default useExamMarkingContext;
