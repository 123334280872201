import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import "./CourseLandingTopicDetails.scss";
import classnames from "classnames";
import CourseLandingTopicTag from "./CourseLandingTopicTag/CourseLandingTopicTag";

const CourseLandingTopicDetails = ({tags, active, free, soloModule}) => {
  if (!active) return null;

  return (
    <AnimatePresence>
      <div
        className={classnames("course-landing-topic-details", {
          active,
          soloModuleContainer: soloModule,
        })}
      >
        {active &&
          tags.map((tag, tagIndex) => (
            <CourseLandingTopicTag
              key={tag.id}
              tag={tag}
              tagIndex={tagIndex}
              freeTopic={free}
            />
          ))}
      </div>
    </AnimatePresence>
  );
};

export default CourseLandingTopicDetails;
