import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const QUERY = gql`
  query useGetLandingPageSectionContent($id: String!) {
    landingPageSection(id: $id) {
      sys {
        id
      }
      order
      title
      h1
      h2
      illustrationCollection {
        items {
          url
          title
          description
        }
      }
      contentsCollection {
        items {
          ...contentsCollectionFields
        }
      }
    }
  }

  fragment contentsCollectionFields on LandingPageExplainerSection {
    title
    topIcon
    h1
    h2
    list
    illustration {
      url
      title
    }
  }
`;

const useGetLandingPageSectionContent = sectionId => {
  let section = {};

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id: sectionId },
    context: {
      api: "contentful",
    },
  });

  if (error) {
    console.log("Error in useGetLandingPageSectionContent", error);
  }

  if (data && data.landingPageSection) {
    section = data.landingPageSection;
  }

  return [section, loading];
};

export default useGetLandingPageSectionContent;
