import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

const GET_TEXT_DATA = gql`
  query getTextData($parentType: String!, $parentId: String!) {
    textData(parentType: $parentType, parentId: $parentId) {
      id
      text
      handwritten
      confidence
      confidenceRate
      source
      createdAt
      updatedAt
      revision
    }
  }
`;
/**
 * @param {Object} parent
 * @param {String} parent.parentType - one of ["exercise", "solution"]
 * @param {('exercise'|'solution')} parent.parentId - id of the parent
 * @returns {[null,boolean]}
 */

const useGetTextData = ({parentType, parentId}) => {
  let textData = null;

  const {data, loading, error} = useQuery(GET_TEXT_DATA, {
    variables: {
      parentType,
      parentId,
    },
    skip: !parentId,
    fetchPolicy: "cache-first",
  });

  if (data) {
    textData = data.textData;
  }

  return [textData, loading];
};

export default useGetTextData;
