import moment from "moment";

const now = moment();

export default {
  uncategorized: {
    label: "Uncategorized exercises",
    filterFunction: exercise => exercise.tags.length <= 0,
  },
  uncompleted: {
    label: "Not assessed",
    filterFunction: exercise => {
      try {
        if (exercise.self_evaluation == null) {
          return true;
        }

        if (exercise.self_evaluation && exercise.self_evaluation.score !== 0) {
          const { date } = exercise.self_evaluation;

          if (moment(date).isBefore(now)) {
            return false;
          }
        }

        return true;
      } catch (e) {
        console.log(e);

        return false;
      }
    },
  },
  easy: {
    label: "Assessed as easy",
    filterFunction: exercise =>
      exercise.selfEvaluation && exercise.selfEvaluation === 1,
  },
  hard: {
    label: "Assessed as difficult",
    filterFunction: exercise =>
      exercise.selfEvaluation && exercise.selfEvaluation > 3,
  },
};
