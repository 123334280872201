import React from "react";
import {Alert, Button, Input, Toggle, Trans} from "Components";
import Editor from "Components/Editor/Editor";
import useCommentReplyFormState from "Components/Comment/CommentReplyForm/useCommentReplyFormState";
import {useTranslation} from "react-i18next";

const CommentReplyForm = ({currentUser, onNewCommentSubmit, expanded}) => {
  const {t: trans} = useTranslation();

  const {
    text,
    anonymous,
    username,
    setState,
    toggleAnonymous,
    errors,
    disableSubmit,
    loading,
    onSubmit,
  } = useCommentReplyFormState(onNewCommentSubmit);

  if ((!currentUser || !currentUser?.id) && expanded)
    return (
      <Alert
        className="mt-4"
        type="gray"
        title={<Trans>Logga in för att delta i diskussionen</Trans>}
      />
    );

  return (
    <div
      initial={{
        height: 0,
      }}
      animate={{
        height: expanded ? "auto" : 0,
      }}
      className="overflow-hidden "
    >
      <div className="mt-4 bg-primary-25 rounded-lg p-4">
        <Trans weight="md" type="tmd">
          Post a reply
        </Trans>
        <div className="mt-2" />
        <Editor
          state={{text}}
          updateText={text => setState({text})}
          type="compact"
          className="background rounded-lg"
        />

        <div className="mt-2  ">
          <Toggle
            name="anonymous"
            defaultChecked={anonymous}
            onChange={toggleAnonymous}
            label={<Trans>Post anonymously</Trans>}
          />

          {anonymous && (
            <Input
              size="small"
              placeholder={trans("Anonymous username")}
              errorMessageRelative
              name="anonymous-username"
              error={errors.username}
              value={username}
              disabled={!anonymous}
              onChange={e => setState({username: e.target.value})}
            />
          )}
        </div>
        <Button
          type="secondary"
          className="mt-2"
          fluid
          disabled={disableSubmit}
          iconRight="send"
          loading={loading}
          onClick={onSubmit}
        >
          <Trans>Post</Trans>
        </Button>
      </div>
    </div>
  );
};

export default CommentReplyForm;
