import React from "react";
import CheckboxGroup from "Components/_Elements/CheckboxGroup/CheckboxGroup";
import {useTranslation} from "react-i18next";
import {TextArea, Trans, Typography} from "Components";

const SubscriptionCancellationForm = ({
  state,
  onOptionChange,
  onExplanationChange,
}) => {
  const {t: trans} = useTranslation();

  const cancellationReasonOptions = [
    {
      value: "not-helpful",
      text: trans("I didn’t find Kollin helpful"),
      prompt: trans("What would you have needed?"),
    },
    {
      value: "missing-courses",
      text: trans("Kollin doesn’t have my courses"),
      prompt: trans("Which courses would you need?"),
    },
    {
      value: "only-needed-before-exam",
      text: trans("Only needed before my exam"),
      prompt: trans("What would help during the semester?"),
    },
    {
      value: "other",
      text: trans("Other"),
      prompt: trans("What didn’t work out for you?"),
    },
  ];

  const textPrompt = cancellationReasonOptions.find(
    option => option.value == state.selectedOptionName
  )?.prompt;

  return (
    <div>
      <CheckboxGroup
        allowMultiple={false}
        onChange={onOptionChange}
        options={cancellationReasonOptions}
      />
      {textPrompt && (
        <div className="mt-4">
          <Typography className="mb-2">
            {textPrompt}
            <span className="text-red-500"> *</span>
          </Typography>
          <TextArea
            onChange={onExplanationChange}
            text={state.explanation}
            placeholder={<Trans>Write your answer here</Trans>}
          />
        </div>
      )}
    </div>
  );
};

export default SubscriptionCancellationForm;
