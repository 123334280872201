/**
 * Get a list of numbers in the given range
 * @param {Number} end The last element of the list
 * @param {Number} [start=0] The first element in the list (defaults to 0)
 */
export const range = (end, start) => {
  let i = 0;
  let list = [];

  if (start) {
    i = start;
  }

  while (i < end) {
    list.push(i);
    i++;
  }

  return list;
};

export const randomNumber = (lower, upper) =>
  Math.floor(Math.random() * upper) + lower;
