import {Alert, Button, Modal, Trans} from "Components";
import React from "react";
import useSubscriptionChangePlan from "./useSubscriptionChangePlan";
import {getDescription, getTitle} from "utilities/subscriptionPricing";
import CheckboxGroup from "Components/_Elements/CheckboxGroup/CheckboxGroup";

const SubscriptionChangePlan = ({currentPlan, subscriptionId, className}) => {
  const {
    subscriptionPlans,
    subscriptionPlansLoading,
    showModal,
    setShowModal,
    currentPlanPrice,
    selectedPlanId,
    setSelectedPlanId,
    onPlanChange,
    mutationLoading,
  } = useSubscriptionChangePlan({currentPlan, subscriptionId});

  if (subscriptionPlansLoading) return null;

  const sortedSubscriptionPlans = subscriptionPlans.sort((a, b) => {
    return b.price - a.price;
  });

  return (
    <>
      <Button
        size="sm"
        onClick={() => setShowModal(true)}
        icon="arrows-repeat"
        hint={<Trans>Change payment plan</Trans>}
        className={className}
      />

      <Modal
        defaultOpen={showModal}
        icon="arrows-repeat"
        showCancelButton
        onClose={() => setShowModal(false)}
        title={<Trans>Choose new payment plan</Trans>}
        action={{
          label: <Trans>Change plan</Trans>,
          onClick: onPlanChange,
          disabled: selectedPlanId == null || mutationLoading,
          loading: mutationLoading,
        }}
      >
        <Alert
          title={
            <Trans>Changing payment plans will lead to prorated charges</Trans>
          }
          subTitle={
            <Trans>
              Pay only for what you use. For example, if you upgrade from 1 to 6
              months, we will deduct your last payment from the new invoice.
            </Trans>
          }
          user={null}
        />
        <div className="mt-4">
          <CheckboxGroup
            onChange={setSelectedPlanId}
            allowMultiple={false}
            options={sortedSubscriptionPlans.map((plan, index) => ({
              text: getTitle(plan.interval),
              value: plan.id,
              selected:
                selectedPlanId === plan.id || plan.id === currentPlan.id,
              badge: plan.id === currentPlan.id ? <Trans>Current</Trans> : null,
              disabled: plan.id === currentPlan.id, // disable current plan
              description: getDescription(plan.interval, plan.price),
              icon: "fa-regular fa-bolt",
            }))}
            selectedValues={[selectedPlanId]}
            badgeBorder={true}
          />
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionChangePlan;
