import React from "react";
import {Icon} from "Components/_Elements/index";

const InputClearButton = ({clearable, onChange, value}) => {
  // Don't show the clear button if the input is not clearable or if the input is empty
  if (!clearable || !value || value.trim() === "") {
    return null;
  }
  const handleClear = () => {
    onChange({target: {value: ""}});
  };

  return (
    <Icon
      icon="fas fa-xmark"
      color="text-gray-500"
      size="md"
      fixedWidth
      onClick={handleClear}
    />
  );
};

export default InputClearButton;
