import React from "react";
import Summer2022CampaignAuthRecurring from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignAuthRecurring/Summer2022CampaignAuthRecurring";
import Summer2022CampaignOnBoarding from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignOnBoarding/Summer2022CampaignOnBoarding";
import { Typography } from "Components";
import { Summer2022CampaignProgramContext } from "routes/Campaign/Summer2022Campaign/Summer2022Campaign";

/**
 * Flow:
 * 1. Show signup page
 * 2. If user clicks on "Already have an account" we show them the login page
 * 3. The user logs in on the login page
 * 4. The user is redirected to redeem page
 * ---
 * If user is already logged in we
 *
 */
const Summer2022CampaignAuth = () => {
  const userContext = React.useContext(window.UserContext);
  const { allowRegisteredUsers } = React.useContext(
    Summer2022CampaignProgramContext
  );

  // If user is already logged they are recurring users
  const [recurringUser, setRecurringUser] = React.useState(
    userContext?.user?.id
  );

  if (allowRegisteredUsers === true && recurringUser) {
    return <Summer2022CampaignAuthRecurring />;
  }

  return <Summer2022CampaignOnBoarding setRecurringUser={setRecurringUser} />;
};

export const Summer2022CampaignAuthContainer = ({ children, title }) => (
  <div className="bg-primary-800 h-screen">
    <div className="h-[90vh] w-screen overflow-y-scroll bg-primary-800 flex flex-col items-center py-8 scroll-smooth">
      <img
        src="https://d1mgntrf3vaj6d.cloudfront.net/images/KOLLIN+logo.svg"
        alt="logo"
        className="h-6 w-auto brightness-[200]"
      />
      <div className="px-4 mt-8 mb-2 flex flex-col items-center gap-4 ">
        <Typography
          type="dsm"
          weight="b"
          color="text-primary-50"
          className="text-center"
        >
          {title}
        </Typography>
        {children}
      </div>
    </div>
  </div>
);

export default Summer2022CampaignAuth;
