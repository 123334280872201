// from https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react

import React from "react";

export const useWindowSize = () => {
  // get initial size
  const width = document.body.clientWidth;
  const height = document.body.clientHeight;

  const [size, setSize] = React.useState([width, height]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const useBreakPointSM = () => {
  const [width] = useWindowSize();

  const func = (smVale, mdValue) => {
    if (width < 720) {
      return smVale;
    } else {
      return mdValue;
    }
  };

  return func;
};

export const useBreakPointXS = () => {
  const [width] = useWindowSize();

  const func = (xsValue, smValue) => {
    if (xsValue === undefined || smValue === undefined) {
      return true;
    }

    if (width < 480) {
      return xsValue;
    } else {
      return smValue;
    }
  };

  return func;
};
