export const calculateReviewsStatistics = reviews => {
  const reviewsLength = reviews.length;
  const ratingsMap = [5, 4, 3, 2, 1];
  const result = [];

  ratingsMap.map(rating => {
    const reviewIterate = reviews.filter(review => review.score === rating);
    result.push({
      rating: rating,
      percentage: reviewIterate.length / reviewsLength,
    });
  });

  return result;
};
