import React from "react";
import useHandleCoursePurchaseClick from "utilities/hooks/useHandleCoursePurchaseClick";
import {Button, ProductPrice, Trans, Typography} from "Components";
import {UserContext} from "UserContextProvider";
import CourseLandingActivateBtn from "routes/Course/CourseLanding/CourseLandingActivateBtn/CourseLandingActivateBtn";
import useGetDefaultProductPrice from "Components/ProductPrice/useGetDefaultProductPrice";
import CourseReviewsSummary from "../CourseLandingTitleBanner/CourseReviewsSummary/CourseReviewsSummary";
import NonSubscriber from "../../../../Wrappers/Authorization/NonSubscriber";

const CourseBuyBannerMobile = ({courseId}) => {
  const {user} = React.useContext(UserContext);

  const productPrice = useGetDefaultProductPrice("COURSE", courseId);

  const handleCoursePurchase = useHandleCoursePurchaseClick(courseId);

  return (
    <div className="z-20 sticky bottom-0 bg-primary-800 flex justify-between items-center py-3 px-4 ">
      <div className="flex flex-col items-start justify-between align-self-center">
        <NonSubscriber>
          {productPrice == 0 ? (
            <div>
              <Trans type="tlg" color="text-white" weight="b" className="mb-1">
                Free
              </Trans>
            </div>
          ) : (
            <div>
              <Trans type="tsm" color="text-white">
                From
              </Trans>
              <ProductPrice
                textProps={{
                  color: "text-white",
                  weight: "b",
                }}
                productId={courseId}
                productType="COURSE"
              />
            </div>
          )}
        </NonSubscriber>

        <CourseReviewsSummary
          courseId={courseId}
          white
          showCourseTag={false}
          showNbReviews={false}
        />
      </div>

      <div className="flex items-center">
        {productPrice === 0 || user?.premium || user?.subscriber ? (
          <CourseLandingActivateBtn courseId={courseId} />
        ) : (
          <div className="flex flex-col gap-2 min-w-[8rem]">
            <Button
              onClick={handleCoursePurchase}
              type="primary"
              size="sm"
              fluid
            >
              <Trans>Buy</Trans>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseBuyBannerMobile;
