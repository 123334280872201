import React from "react";
import {Icon, Trans, Typography} from "Components";
import useStudyPlanCardStats from "./useStudyPlanCardStats";
import {useTranslation} from "react-i18next";

const StudyPlanCardStats = ({studyPlan}) => {
  const {author} = studyPlan;
  const {t: trans} = useTranslation();
  const {totalExercises, totalSteps, estimatedTime} = useStudyPlanCardStats(
    studyPlan
  );

  const iconProps = {
    filled: true,
    size: "lg",
    outlined: false,
    fixedWidth: true,
    fillType: "primary",
  };

  return (
    <div>
      <Trans type="tsm" weight="b" className="text-center py-2">
        Snabbfakta om studieplanen
      </Trans>
      <div className="grid grid-cols-2 grid-rows-2">
        <Stat
          icon={<Icon icon="fa-regular fa-hourglass-clock" {...iconProps} />}
          header={textSingularPlural(
            estimatedTime,
            trans("hour"),
            trans("hours")
          )}
          body={
            <>
              <Trans>of intensive study</Trans>
            </>
          }
        />
        <Stat
          icon={<Icon icon="fa-regular fa-list-check" {...iconProps} />}
          header={textSingularPlural(
            totalExercises,
            trans("exercise"),
            trans("exercises")
          )}
          body={
            <>
              <Trans>divided into</Trans>{" "}
              {textSingularPlural(totalSteps, trans("step"), trans("steps"))}
            </>
          }
        />
        {studyPlan.studentsCount > 10 && (
          <Stat
            icon={
              <Icon icon="fa-regular fa-fire-flame-curved" {...iconProps} />
            }
            header={
              <>
                {studyPlan.studentsCount} <Trans>students</Trans>
              </>
            }
            body={
              <>
                <Trans>have already followed it</Trans>
              </>
            }
          />
        )}
        <Stat
          icon={<Icon icon="fa-regular fa-chalkboard-user" {...iconProps} />}
          header={<>{` ${author.first_name} ${author.last_name}`}</>}
          body={<Trans>has authored it</Trans>}
        />
      </div>
    </div>
  );
};

const Stat = ({icon, header, body}) => (
  <div className="flex items-center p-2">
    <div>{icon}</div>
    <div className="pl-2">
      <Typography type="tsm" weight="sb">
        {header}
      </Typography>
      <Typography type="txs">{body}</Typography>
    </div>
  </div>
);

const textSingularPlural = (value, singular, plural) => {
  if (value === 1) {
    return `${value} ${singular}`;
  }
  return `${value} ${plural}`;
};

export default StudyPlanCardStats;
