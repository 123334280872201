import React from "react";
import useAcceptGroupMemberInvite from "API/mutations/groups/useAcceptGroupMemberInvite";
import {useHistory, useParams} from "react-router-dom";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import useJoinGroup from "API/mutations/groups/useJoinGroup";

const useGroupInvitationHandler = openInvitation => {
  const {token} = useParams();
  const {id: groupId} = useGroupContext();
  const history = useHistory();

  const [error, setError] = React.useState(null);

  const {
    acceptGroupMemberInvite,
    response: acceptGroupMemberInviteResponse,
    loading: acceptGroupMemberInviteLoading,
  } = useAcceptGroupMemberInvite({
    token,
    groupId,
  });
  ///group-invite/a52af3d86cd0157d0d5cca98eacdb52b

  const {
    joinGroup: joinPublicGroup,
    response: joinPublicGroupResponse,
    loading: joinPublicGroupLoading,
  } = useJoinGroup({refetchQueries: []});

  let response = openInvitation
    ? joinPublicGroupResponse
    : acceptGroupMemberInviteResponse;

  React.useEffect(() => {
    if (response) {
      if (
        response.ok ||
        response.error?.code === "GMI07" ||
        response.error?.code === "GMB06"
      ) {
        history.replace(`/`);
      }

      // If there is an error, set the error
      if (response.error) {
        setError(response.error.message);
      }
    }
  }, [
    acceptGroupMemberInviteResponse,
    joinPublicGroupResponse,
    acceptGroupMemberInviteLoading,
    joinPublicGroupLoading,
  ]);

  const handleAcceptInvite = () => {
    if (openInvitation) {
      joinPublicGroup({
        variables: {
          groupId,
        },
      });
    } else {
      acceptGroupMemberInvite();
    }
  };

  return {
    handleAcceptInvite,
    response,
    loading: acceptGroupMemberInviteLoading || joinPublicGroupLoading,
  };
};

export default useGroupInvitationHandler;
