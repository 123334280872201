import { useTranslation } from "react-i18next";

const useGetCurrentLocale = () => {
  const { i18n } = useTranslation();

  try {
    const currentLanguage = i18n.language.split("-")[0];

    return currentLanguage;
  } catch (error) {
    return "en";
  }
};

export default useGetCurrentLocale;
