import {HttpLink} from "apollo-boost";
import {setContext} from "apollo-link-context";

const appsyncEndpoint = new HttpLink({
  uri: `https://jgsbshesm5advigzznyid7juny.appsync-api.eu-north-1.amazonaws.com/graphql`,
});

const appsyncLinkMiddleware = setContext((_, {headers}) => ({
  headers: {
    ...headers,
    "x-api-key": "da2-p5xy2amj6vdgjf6vm6qygnkyrq",
  },
}));

const appsyncLink = appsyncLinkMiddleware.concat(appsyncEndpoint);

export default appsyncLink;
