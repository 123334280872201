import React from "react";
import {message} from "Components";
import useCreateOrder from "API/mutations/order/useCreateOrder";
import {stringToENUM} from "utilities/text";

const useCheckoutOrderHandler = ({
  products,
  discount,
  transformInputProducts = true,
}) => {
  // Initiate hook for order creation mutation

  const {createOrder: createOrderMutation, data, loading} = useCreateOrder();

  // Create state for storing mutation response

  const [orderState, setOrderState] = React.useState({
    error: null,
    // The following will be set depending on the response after creating an order
    order: null,
    orderCreationSuccess: false,
  });

  const updateOrderState = keyValObject =>
    setOrderState({...orderState, ...keyValObject});

  // Create trigger for order mutation

  const createOrder = ({
    products: inputProducts = [],
    phoneNumber,
    stripeSuccessUrl,
    stripeCancelUrl,
    paymentMethod,
  }) => {
    setOrderState({error: null, order: null, orderCreationSuccess: false});

    let productsToSubmit = inputProducts.length > 0 ? inputProducts : products;
    let transformedProductsInput = transformInputProducts
      ? transformObjectToProductInput(productsToSubmit)
      : productsToSubmit;

    createOrderMutation({
      variables: {
        products: transformedProductsInput,
        paymentMethod,
        discount,
        phoneNumber,
        stripeCancelUrl,
        stripeSuccessUrl,
      },
    });
  };

  // Capture the response from the server and do the appropriate action:

  React.useEffect(() => {
    if (data && data.createOrder) {
      if (data.createOrder.ok) {
        if (data.createOrder.order) {
          // Different actions depending on payment method:
          updateOrderState({
            order: data.createOrder.order,
            orderCreationSuccess: true,
          });
        } else {
          // If ok but order is null, something went wrong
          setOrderState({error: "ORD03"});
        }
      } else if (data.createOrder.error) {
        updateOrderState({error: data.createOrder.error});
        message.error(getErrorMessage(data.createOrder.error));
      }
    }
  }, [data]);

  const cancelOrder = () => {
    // Reset state
    updateOrderState({error: null, order: null, orderCreationSuccess: false});
  };

  // Return state and handler and helper functions:

  return {createOrder, cancelOrder, loading, ...orderState};
};

const transformObjectToProductInput = products =>
  products.map(product => ({
    productId: product.id,
    productType: stringToENUM(product.__typename),
    productPriceId: product.selectedPrice.id,
  }));

const getErrorMessage = error => {
  if (window.ErrorCodes && window.ErrorCodes[error]) {
    return window.ErrorCodes[error];
  }

  return error;
};
export default useCheckoutOrderHandler;
