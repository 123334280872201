import React from "react";
import {Trans, Typography, StarRating} from "Components";
import ProgressBar from "Components/_Elements/ProgressBar/ProgressBar";

const CourseLandingAllReviewsStats = ({averageRating, reviewStats}) => (
  <div>
    <div className="flex gap-6 items-end">
      <Typography color="text-warning-600" type="dxs" weight="b">
        {averageRating}
      </Typography>
      <Trans type="txs" color="text-gray-500">
        Snittbetyg från tidigare kursare
      </Trans>
    </div>
    {reviewStats.map((reviewStat, index) => {
      return (
        <div className="flex items-baseline justify-start gap-2" key={index}>
          <div className="w-[10rem]">
            <ProgressBar progressRatio={reviewStat.percentage} thin />
          </div>
          <StarRating rating={reviewStat.rating} />
          <Typography className="flex-shrink-0">
            {Math.ceil(reviewStat.percentage * 100) + "%"}
          </Typography>
        </div>
      );
    })}
  </div>
);

export default CourseLandingAllReviewsStats;
