import React from "react";
import useGetExercise from "API/queries/exercise/useGetExercise";
import {Icon, Link, Trans, Typography} from "Components";
import useGetCourse from "API/queries/course/useGetCourse";
import {getCleanCourseName} from "utilities/formatCourseName";
import DifficultyChip from "Components/DifficultyChip/DifficultyChip";
import ExerciseCheckMark from "Components/ExerciseCheckMark/ExerciseCheckMark";
import ExerciseTag from "Components/ExerciseTag/ExerciseTag";

const LatestExerciseCard = ({exerciseId, courseId}) => {
  const [exercise, loading] = useGetExercise(exerciseId, true, false, courseId);
  const [course, courseLoading] = useGetCourse(courseId);

  const courseName = getCleanCourseName(course?.name);

  if (loading || courseLoading) return null;

  const {
    id,
    score,
    author,
    number,
    source,
    self_evaluation: selfEvaluation,
    tags,
  } = exercise || {};
  // Get exam in date format DD Jan YYYY
  const examDate = new Date(source.date.substring(0, 10));
  const examExtension = source.date.slice(10).trim();
  const examDateFormatted = examDate.toLocaleDateString("sv-SE", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return (
    <div className="flex flex-col gap-2 p-4 bg-slate-100 rounded-lg">
      <span className=" gap-2">
        <Trans inline className="text-gray-500" weight="sb">
          Jump back into your latest exercise in
        </Trans>
        <Link to={`/course/${courseId}`} inline iconRight="arrow-right">
          <Typography>{courseName}</Typography>
        </Link>
      </span>
      <div className=" bg-gray-50  p-2">
        <img
          src={`https://kollin-exams.s3.eu-north-1.amazonaws.com/${exercise?.imageUrl}`}
          alt="exercise thumbnail"
          className="rounded-lg mt-2"
        />
        <div className="w-full flex flex justify-between items-bottom gap-4 mt-2">
          <div className="flex gap-2 items-center">
            <Typography type="tmd" color="text-gray-500" className="">
              Uppgift {number}
            </Typography>
            <DifficultyChip score={score} />
          </div>
          <div className="flex gap-2 items-center">
            <ExerciseCheckMark
              exercise={exercise}
              showIfUnrated={true}
              key={selfEvaluation?.score}
              size="lg"
            />
          </div>
        </div>{" "}
        <div className="flex flex-col gap-2 px-2 my-2">
          <div className="flex items-center gap-2">
            <Link
              to={`/course/${courseId}/exam-exercises/${source.id}?dir=asc&exercise=${id}&sort=number&displayMode=list`}
              // to={getExamLink(source.id, {exercise: id})}
              asButton
              inline
              type="link"
            >
              {examDateFormatted}
            </Link>
            {author && (
              <div className="flex items-center gap-2 overflow-hidden">
                <Icon
                  icon="fa-solid fa-circle-small"
                  size="xs"
                  color="text-gray-500"
                />
                <div className="flex gap-1 overflow-hidden">
                  <Typography
                    type="tmd"
                    color="text-gray-500"
                    className="truncate text-ellipsis"
                  >
                    {author}
                  </Typography>
                  {examExtension && (
                    <Typography
                      type="tmd"
                      color="text-gray-500"
                      className="truncate text-ellipsis"
                    >
                      ({examExtension})
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const GroupHomeQuickActionsLastViewedExercise = () => {
  const latestCourses =
    JSON.parse(window.localStorage?.getItem("visitedSchools")) || [];

  // Get the last viewed exercise for the latest course

  const lastCourseIdWithExercise = latestCourses.find(courseId => {
    return localStorage.getItem(`LAST_EXERCISE_IN_COURSE_${courseId}`) !== null;
  });

  const lastExerciseId = localStorage.getItem(
    `LAST_EXERCISE_IN_COURSE_${lastCourseIdWithExercise}`
  );

  if (!lastExerciseId) return null;

  return (
    <LatestExerciseCard
      exerciseId={lastExerciseId}
      courseId={lastCourseIdWithExercise}
    />
  );
};

export default GroupHomeQuickActionsLastViewedExercise;
