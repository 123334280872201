import React from "react";

import {Button, Icon, TimerDisplay, Trans} from "Components";
import {Authorization} from "Wrappers";
import {ExerciseEvaluationContext} from "../ExerciseEvaluation";

const ExerciseEvaluationTimer = () => {
  const {
    timeSpent,
    backgroundTimer,
    userTimerStarted,
    startUserTimer,
    resetTimer,
    timerPaused,
    toggleTimerPaused,
    completed,
  } = React.useContext(ExerciseEvaluationContext);

  return (
    <>
      <div className="flex items-center justify-between py-2">
        <TimerDisplay timeSpent={timeSpent} textType="txl" />
        <ExerciseEvaluationTimerActions
          startUserTimer={startUserTimer}
          userTimerStarted={userTimerStarted}
          completed={completed}
          resetTimer={resetTimer}
          timerPaused={timerPaused}
          toggleTimerPaused={toggleTimerPaused}
        />
      </div>
      <Authorization.Admin>
        <p>Background time spent: {backgroundTimer}s.</p>
      </Authorization.Admin>
    </>
  );
};

const ExerciseEvaluationTimerActions = ({
  startUserTimer,
  userTimerStarted,
  completed,
  resetTimer,
  timerPaused,
  toggleTimerPaused,
}) => {
  if (completed) {
    return (
      <div className="flex gap-2 mr-2">
        <Icon
          icon="fa-regular fa-badge-check"
          size="xl"
          color="text-primary-500"
        />
      </div>
    );
  }

  if (!userTimerStarted) {
    return (
      <Button
        onClick={startUserTimer}
        iconLeft="fa-regular fa-stopwatch"
        type="secondary"
        size="sm"
        trackParams={{
          event: "start_timer_clicked",
        }}
      >
        <Trans>Start</Trans>
      </Button>
    );
  }

  return (
    <div className="flex gap-2">
      {timerPaused && (
        <Button
          disabled={completed}
          onClick={() => {
            resetTimer();
          }}
          icon="fa-regular fa-clock-rotate-left"
          type="gray"
          size="sm"
          hint={<Trans>Reset timer</Trans>}
        />
      )}
      <Button
        disabled={completed}
        type="gray"
        size="sm"
        onClick={toggleTimerPaused}
        icon={timerPaused ? "fa-solid fa-play" : "fa-solid fa-pause"}
        hint={
          timerPaused ? <Trans>Resume timer</Trans> : <Trans>Pause timer</Trans>
        }
      />
    </div>
  );
};

export default ExerciseEvaluationTimer;
