import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

import {UserContext} from "UserContextProvider";

export const GET_PRODUCTS = gql`
  #   input ProductInput {
  #     productId: Int
  #     productType: String
  #   }

  query getProducts($productInputs: [ProductInput], $auth: Boolean!) {
    getProducts(products: $productInputs) {
      id
      purchased @include(if: $auth)
      expiryDate @include(if: $auth)

      ... on Course {
        name
        icon
      }

      ... on StudyPlan {
        name
        imageUrl
      }

      ... on CreditsBundle {
        name
        amount
        imageUrl
        description
        expiryDate
      }
    }
  }
`;

/**
 *
 * @param {Object} products
 * @param {Integer} products.productId - Id of the product
 * @param {String} products.productType - Type of the product (see server api docs)
 * @returns
 */
const useGetProduct = (productType, productId) => {
  const {user} = React.useContext(UserContext);

  let product = null;

  const {loading, error, data} = useQuery(GET_PRODUCTS, {
    variables: {
      productInputs: [{productId, productType}],
      auth: user !== null,
    },
    context: {
      batch: true,
    },
  });

  if (error) {
    console.log("Error in useGetProduct ", error);
  }

  if (data && data.getProducts.length) {
    product = data.getProducts[0];
  }

  return [product, loading];
};

export default useGetProduct;
