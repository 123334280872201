import React from "react";
import useCurrentParams from "utilities/hooks/useCurrentParams";
import useGPTMetadataExercise from "Components/GPT/hooks/useGPTMetadata/useGPTMetadataExercise";
import useEmptyHook from "utilities/hooks/useEmptyHook";

const UseGPTMetadata = () => {
  const {exerciseId} = useCurrentParams();

  const [metadata, setMetadata] = React.useState({
    exerciseId: 0, // use 0 instead of 0 because the lambda can't handle null values for numeric input
    solutionId: 0, // use 0 instead of 0 because the lambda can't handle null values for numeric input
    exerciseText: null,
    solutionText: null,
  });

  const updateMetadata = obj => {
    setMetadata(prev => {
      return {...prev, ...obj};
    });
  };

  let hook = useEmptyHook;

  if (exerciseId) {
    hook = useGPTMetadataExercise;
  }

  const loading = hook({exerciseId, metadata, updateMetadata});

  return {
    loading,
    metadata,
  };
};

export default UseGPTMetadata;
