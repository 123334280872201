import React from "react";
import {useHistory} from "react-router-dom";
// import { StudyPlanRouterContext } from "routes/Course/StudyPlan/StudyPlan";
import useStudyPlanBlockEvaluation from "routes/Course/StudyPlan/StudyPlanBlocks/useStudyPlanBlockEvaluation";

const useStudyPlanCardPurchasedBlock = (
  block,
  addCompletedBlock,
  studyPlanId
) => {
  // const { onBlockSelect, onStepAndBlockSelect } = React.useContext(
  //     StudyPlanRouterContext
  // );
  const {id: courseId} = React.useContext(window.CourseContext);
  const history = useHistory();

  const [stepEvaluations] = useStudyPlanBlockEvaluation(
    block.stepsCollection.items
  );

  // --- Functions
  const stepsCompleted = Object.values(stepEvaluations).reduce(
    (prev, next) => prev + (next.completed ? 1 : 0),
    0
  );

  const allStepsCompleted =
    stepsCompleted == block.stepsCollection.items.length;

  const onClick = () => {
    window.analytics.track("study-plan-block-clicked", {
      blockId: block.sys.sid,
    });

    history.push(
      `/course/${courseId}/study-plan/${studyPlanId}?block=${
        block.sys.id
      }&step=${allStepsCompleted ? 0 : stepsCompleted}`
    );
  };

  // --- Effects
  React.useEffect(() => {
    if (allStepsCompleted) {
      addCompletedBlock(block.sys.id);
    }
  }, [stepsCompleted]);

  if (Object.values(stepEvaluations).length > 0) {
    // console.log("StudyPlanCardPurchasedBlock ", {
    //   block,
    //   stepEvaluations,
    //   allStepsCompleted,
    // });
  }

  return {
    allStepsCompleted,
    onClick,
  };
};

export default useStudyPlanCardPurchasedBlock;
