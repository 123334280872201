import React from "react";
import {Button, Link, Trans} from "Components";
import {CourseParamsContext} from "routes/Course/CourseRouter";
import {useHistory} from "react-router-dom";
import {isMobile, isMobileOnly} from "react-device-detect";

const CourseKollinGptSavedAction = ({type, content, id, exercise}) => {
  const history = useHistory();
  const {courseId, getCourseLink} = React.useContext(window.CourseContext); // needed for the chapter
  const {getExerciseWithListLink} = React.useContext(CourseParamsContext);

  const {openModal} = React.useContext(window.GlobalModalContext);

  const handleShowConversationClick = () => {
    if (isMobile) {
      history.push(getCourseLink(`kollin-gpt?conversation=${id}`));
    } else {
      openModal("CHAT_GPT", null, {
        initialResults: content,
        existingChapterId: id,
        courseId,
      });
    }
  };

  if (type === "chapter") {
    return (
      <div>
        <Button
          size="sm"
          type="link"
          iconRight="arrow-right"
          onClick={handleShowConversationClick}
        >
          <Trans>View conversation</Trans>
        </Button>
      </div>
    );
  }

  if (type === "note") {
    return (
      <div className="flex gap-2">
        <Link
          size="sm"
          type="link"
          iconRight="arrow-right"
          to={getExerciseWithListLink(exercise.id, [exercise.id], "note")}
        >
          <Trans>Go to exercise</Trans>
        </Link>
      </div>
    );
  }

  return null;
};

export default CourseKollinGptSavedAction;
