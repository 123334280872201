import React from "react";
import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";

const GET_SIGNED_URL = gql`
  mutation signS3FileUpload(
    $fileName: String!
    $hash: String!
    $fileType: String!
    $ACL: String
  ) {
    signS3FileUpload(
      fileName: $fileName
      hash: $hash
      fileType: $fileType
      ACL: $ACL
    ) {
      signedRequest
      url
    }
  }
`;

const useS3FileUpload = () => {
  const [loading, setLoading] = React.useState(false);

  const [getSignedUrl, {loading: mutationLoading}] = useMutation(
    GET_SIGNED_URL
  );

  const uploadFile = async ({file, fileType, fileName, hash, ACL}) => {
    setLoading(true);
    // Get the signed url
    const {data, errors} = await getSignedUrl({
      variables: {
        fileName: fileName || file.name,
        fileType: fileType || file.type,
        ACL,
        hash,
      },
    });

    if (errors) {
      console.log("errors", errors);
      setLoading(false);
      return null;
    }

    const {signedRequest, url} = data.signS3FileUpload;

    // Upload the file to S3

    try {
      const response = await fetch(signedRequest, {
        method: "PUT",
        headers: {
          "Content-Type": fileType || file.type,
        },
        body: file,
      });

      setLoading(false);
      return url;
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      return null;
    }
  };

  return {
    uploadFile,
    loading: loading || mutationLoading,
  };
};

export default useS3FileUpload;
