import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const UPDATE_USER_COURSE = gql`
  mutation updateUserCourse($courseId: ID!, $archiveCourse: Boolean!) {
    updateUserCourse(courseId: $courseId, archived: $archiveCourse) {
      ok
      error
      course {
        id
        currentUser {
          active
          archived
        }
      }
    }
  }
`;

const useUpdateUserCourse = onCompleted => {
  const [updateUserCourse] = useMutation(UPDATE_USER_COURSE, {
    onCompleted,
  });

  return {
    updateUserCourse,
  };
};

export default useUpdateUserCourse;
