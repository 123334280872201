import React from "react";
import {NumberParam, StringParam, useQueryParams} from "use-query-params";
import Workboard from "Components/Workboard/Workboard";
import useGetExercises from "../../../API/queries/exercise/useGetExercises";
import {Section} from "../../../Components";
import useCourseExercises from "./useCourseExercisesData";
import ExerciseListProgress from "../../../Components/ExerciseListProgress/ExerciseListProgress";
import Breadcrumb from "../../../Components/_Elements/Breadcrumb/Breadcrumb";

const CourseExercises = () => {
  const [urlParam, setUrlParam] = useQueryParams({
    exercise: NumberParam,
    exerciseIds: StringParam,
    value: StringParam,
  });
  const {exerciseIds} = urlParam;

  const {sortingCriteriaOptions} = useCourseExercises();

  const title = sortingCriteriaOptions.find(
    option => option.value === urlParam.value
  )?.label;

  const Wrapper = title ? Section : "div";

  const exerciseIdsArray = exerciseIds?.split(",");

  const [exercises, loading] = useGetExercises({
    ids: exerciseIdsArray,
    includeTags: true,
    includeStats: true,
    includeSource: true,
    includeSelfEvaluation: true,
    includeBookmarked: true,
  });

  if (loading) return null;

  return (
    <div>
      <div className="border-b-2 border-color w-full flex justify-between">
        <div>
          <Breadcrumb />
          <Wrapper title={title} secondary={true}>
            {exerciseIdsArray?.length > 1 && (
              <div className="w-full md:w-96">
                <ExerciseListProgress
                  loading={loading}
                  exercises={exercises}
                  segmented
                />
              </div>
            )}
          </Wrapper>
        </div>
        <div id="action-pane-trigger-container"></div>
      </div>
      <Workboard exercises={exercises} showExerciseViewHeader={false} />
    </div>
  );
};

export default CourseExercises;
