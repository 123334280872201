import React from "react";
import useGetAutoMarkingLogs, {
  COURSE_AUTO_MARKING_LOGS,
} from "API/queries/autoMarkingLog/useGetAutoMarkingLogs";
import {ExamViewContext} from "routes/Course/ExamWizard/ExamView/useExamViewContext";
import useCreateAutoMarkingLog from "API/mutations/autoMarkingLog/useCreateAutoMarkingLog";

/**
 * This is used for logging the state of the segmentation
 * for automating future segmentations
 */
const useExamMarkingAutoSegmentationCommitLog = ({
  patterns,
  totalSegmentsCount,
}) => {
  const {id: courseId} = React.useContext(window.CourseContext);
  const {returnObjectName, sourceId} = React.useContext(ExamViewContext);

  const logIdentifier = {
    courseId,
    segmentType: returnObjectName,
    documentId: sourceId.toString(),
  };

  const {createAutoMarkingLog, loading} = useCreateAutoMarkingLog({
    refetchQueries: [
      {
        query: COURSE_AUTO_MARKING_LOGS,
        variables: logIdentifier,
      },
    ],
  });

  const [autoMarkingLogs, logsLoading] = useGetAutoMarkingLogs(logIdentifier);

  const commit = React.useCallback(async () => {
    /**
     * Check if the current document has already been logged
     */

    if (autoMarkingLogs.length > 0) {
      return;
    }

    const result = await createAutoMarkingLog({
      variables: {
        ...logIdentifier,
        segmentsCount: totalSegmentsCount,
        segmentPattern: patterns,
        settings: {},
        verified: true, // since it's a manual commit
      },
    });
  }, [totalSegmentsCount, sourceId, returnObjectName, courseId, patterns]);

  return commit;
};

export default useExamMarkingAutoSegmentationCommitLog;
