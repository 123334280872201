import React from "react";
import paramAgnosticPathCompare from "../../paramAgnosticPathCompare/paramAgnosticPathCompare";
import {useLocation, useParams} from "react-router-dom";

const useGetCurrentRoute = routes => {
  const {pathname} = useLocation();
  const params = useParams();

  const currentPath = React.useMemo(
    () =>
      pathname
        .split("/")
        .slice(3)
        .join("/"),
    [pathname]
  ); // Get the current path, remove /course/:id from the path

  const currentRoute = React.useMemo(
    () =>
      routes?.find(route => paramAgnosticPathCompare(route.path, currentPath)),
    [currentPath, routes]
  );

  return {
    currentPath,
    currentRoute,
    currentParams: params,
  };
};

export default useGetCurrentRoute;
