import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";

export const CREATE_SOLUTION = gql`
  mutation createSolution(
    $exerciseId: ID!
    $sourceImageUrl: String!
    $boundingBox: BoundingBoxInput!
    $published: Boolean! # Default to true for manual updates
  ) {
    createSolution(
      exerciseId: $exerciseId
      sourceImageUrl: $sourceImageUrl
      boundingBox: $boundingBox
      published: $published
    ) {
      ok
      error {
        message
        code
      }
      solution {
        id
        imageUrl
      }
    }
  }
`;

const useCreateSolution = () => {
  const [createSolution, {loading, data, error}] = useMutation(
    CREATE_SOLUTION,
    {
      onError: error => {
        console.log("Error in useCreateSolution: ", error);
      },
    }
  );

  if (error) {
    console.log("Error in useCreateSolution: ", error);
  }

  const result = data?.createSolution;

  return {
    createSolution,
    loading,
    result,
    error,
  };
};

export default useCreateSolution;
