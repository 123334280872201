import React from "react";
import {Button, Trans} from "Components";
import {getLinesWithMath} from "Widgets/Course/CourseChatGPT/CourseChatGPTResultsMessage/CourseChatGPTResultsMessageContent";
import {GPTContext} from "../../../GPT/GPTProvider";
import useExerciseNotesNewNoteState from "../../../../Widgets/Exercise/ExerciseNotes/ExerciseNotesNewNote/useExerciseNotesNewNoteState";
import useCurrentParams from "../../../../utilities/hooks/useCurrentParams";

const GPTActionsSaveNote = ({className}) => {
  const {messages} = React.useContext(GPTContext);
  const {exerciseId} = useCurrentParams();

  const formattedNote = formatDiscussionToNotes(messages);

  const {submit, loading, response} = useExerciseNotesNewNoteState(
    exerciseId,
    formattedNote
  );

  if (response?.ok) {
    return (
      <Button
        disabled={true}
        fluid
        icon="check-circle"
        iconColor="text-success"
        size="sm"
        type="gray"
        className={className}
        hint={<Trans>Saved</Trans>}
      />
    );
  }

  return (
    <Button
      size="sm"
      onClick={submit}
      loading={loading}
      fluid
      type="gray"
      icon="floppy-disk"
      hint={<Trans>Save as note</Trans>}
      className={className}
    />
  );
};

const formatDiscussionToNotes = messages => {
  let filteredMessages = messages.filter(result => result.role !== "system");

  return [
    "# Svar från Kollin-GPT",
    ...filteredMessages.map(message => {
      let content = getLinesWithMath(message.content).join("\n");

      if (message.role === "user") {
        return `## ${content}\n`;
      }

      return `\n\n${content}`;
    }),
  ].join("\n");
};

export default GPTActionsSaveNote;
