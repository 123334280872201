import React from "react";
import {isMobileOnly, isTablet} from "react-device-detect";
import {UserContext} from "UserContextProvider";
import UserMenuDesktop from "./UserMenuDesktop/UserMenuDesktop";
import UserMenuMobile from "./UserMenuMobile";
import "./UserMenu.scss";
import Banners from "Components/Banners/Banners";
import {useWindowSize} from "utilities/hooks/useWindowSize";
import {useLocation} from "react-router-dom";
import {isInGroup} from "Providers/GroupProvider/GroupProvider";

const hiddenOnPaths = [
  "trial",
  "sektionskampen",
  "campaign",
  "guldbiljett",
  "silverbiljett",
];

const UserMenu = ({
  inCourse,
  transparent = false,
  rootMount = false,
  ...props
}) => {
  const {user} = React.useContext(UserContext);
  const [width] = useWindowSize();
  const {pathname} = useLocation();

  if (!user || user?.id === undefined) return null;

  if (isInGroup) return null;

  /**
   * If the user is on a path that should hide the user menu and
   * it's mounted on the root (App.js)
   *
   * Other components can mount the UserMenu and it will still be visible
   * even if the user is on a path that should hide it if rootMount is false
   */
  if (
    hiddenOnPaths.some(
      p => pathname.toLowerCase().indexOf(p.toLowerCase()) > -1
    ) &&
    rootMount
  )
    return null;
  // Mobile view or tablets in portrait mode
  return isMobileOnly || (isTablet && width < 1024) ? (
    <>
      <UserMenuMobile transparent={transparent} user={user} {...props} />
      <Banners />
    </>
  ) : (
    <>
      <UserMenuDesktop
        transparent={transparent}
        inCourse={inCourse}
        {...props}
      />
      <Banners />
    </>
  );
};

export default UserMenu;
