import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import classnames from "classnames";

const spacing = {
  d2xl: "4.5rem",
  dxl: "text-h2",
  dlg: "text-h3",
  dmd: "text-h4",
  dsm: "text-h5",
  dxs: "text-h6",
  txl: "text-body1",
  tlg: "text-body2",
  tmd: "text-body3",
  tsm: "text-body4",
  txs: "text-body5",
};

const Numbers = ({ value }) => {
  // Try to parse the value as a number. If it fails, return the value as a string.
  const parsedValue = getParseValue(value);
  const [currentValue, setCurrentValue] = React.useState(parsedValue);

  React.useEffect(() => {
    if (currentValue !== parsedValue) {
      setTimeout(() => setCurrentValue(parsedValue), 200);
    }
  }, [value]);

  // TODO: split into separate components for each number and add entry/exit animations for each
  const numbersArray = currentValue.toString().split("");
  return (
    <AnimatePresence initial={false}>
      <span className="relative">
        <span className="opacity-0">{currentValue} </span>
        <motion.span
          className={classnames("absolute left-0")}
          transition={{ duration: 0.2 }}
          initial={{
            scale: 0,
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          exit={{
            scale: 0.5,
            opacity: 0,
          }}
          key={currentValue}
        >
          {currentValue}
        </motion.span>
      </span>
    </AnimatePresence>
  );
};

Numbers.propTypes = {};

const getParseValue = input => {
  try {
    const number = parseInt(input);
    return number;
  } catch (e) {
    return input;
  }
};

export default Numbers;
