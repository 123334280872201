import { useSubscription } from "@apollo/react-hooks";
import gql from "graphql-tag";

const PAYMENT_STATUS_SUBSCRIPTION = gql`
  subscription($paymentRequestId: ID!) {
    swishPaymentStatus(payment_request_id: $paymentRequestId) {
      ok
      payment_request_id
      error
    }
  }
`;

const useListenSwishStatus = paymentRequestId => {
  const { data, loading, error } = useSubscription(
    PAYMENT_STATUS_SUBSCRIPTION,
    {
      variables: {
        paymentRequestId,
      },
    }
  );

  return [data, loading];
};

export default useListenSwishStatus;
