import React from "react";
import i18n from "i18next";
import moment from "moment";

import {Badge, Button, ButtonGroup, Trans, Typography} from "Components";
import {capitalizeFirstLetter} from "utilities/text";
import {getTitle} from "utilities/subscriptionPricing";

const useAccountSubscriptionInvoicesData = () => {
  const columns = [
    {
      title: <Trans>Date</Trans>,
      dataIndex: "period_end",
      key: "period_end",
      render: text => (
        <Typography>
          {capitalizeFirstLetter(moment(text).format("MMMM YYYY"))}
        </Typography>
      ),
    },
    {
      title: <Trans>Status</Trans>,
      dataIndex: "status",
      key: "status",
      render: text => (
        <Badge border={true} type={statusBadgeTypes[text]}>
          {statusTranslations[text]}
        </Badge>
      ),
    },
    {
      title: <Trans>Plan</Trans>,
      dataIndex: "subscription",
      key: "subscription",
      render: subscription =>
        getTitle(subscription?.subscriptionPlan?.interval),
    },
    {
      title: <Trans>Payed amount</Trans>,
      dataIndex: "total",
      key: "total",
      render: (text, row) => (
        <Typography>{`${text /
          100} ${row?.currency.toUpperCase()}`}</Typography>
      ),
    },
    {
      title: <Trans>Discount</Trans>,
      dataIndex: "coupons",
      key: "coupons",
      responsive: ["sm"],
      render: (coupons, row) => {
        if (coupons.length == 0) return "0 SEK";
        const totalDiscount = coupons.reduce(
          (acc, coupon) => acc + coupon.amountOff,
          0
        );
        return `${totalDiscount / 100} ${row?.currency.toUpperCase()}`;
      },
    },

    {
      title: <Trans>Invoice</Trans>,
      dataIndex: "hosted_invoice_url",
      key: "hosted_invoice_url",
      responsive: ["sm"],
      render: (_, invoice) => (
        <ButtonGroup>
          <Button
            hint={<Trans>Open in new tab</Trans>}
            icon="arrow-up-right-from-square"
            onClick={() => {
              window.open(invoice.hosted_invoice_url, "_blank");
            }}
          />
          <Button
            hint={<Trans>Download</Trans>}
            icon="download"
            onClick={() => {
              window.open(invoice.invoice_pdf, "_blank");
            }}
          />
        </ButtonGroup>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return {columns, onChange};
};

export const statusTranslations = {
  failed: i18n.t("Failed"),
  refunded: i18n.t("Refunded"),
  paid: i18n.t("Paid"),
  draft: i18n.t("Draft"),
  open: i18n.t("Open"),
  uncollectible: i18n.t("Uncollectible"),
  void: i18n.t("Void"),
  unpaid: i18n.t("Unpaid"),
};

export const statusBadgeTypes = {
  unpaid: "warning",
  paid: "success",
  failed: "error",
  refunded: "darkGray",
  draft: "gray",
  open: "primary",
  uncollectible: "error",
  void: "purple",
};

export default useAccountSubscriptionInvoicesData;
