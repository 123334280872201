import React from "react";
import classnames from "classnames";

const ButtonGroup = ({children, className = ""}) => {
  return (
    <div
      className={classnames(
        "flex items-center flex-nowrap",
        "first:rounded-l-none first:hover:rounded-l-none first:border-r-none",
        "last:rounded-r-none last:hover:rounded-r-none last:border-l-none",
        className
      )}
    >
      {React.Children.map(children, (child, index) => {
        if (!child?.props) return null;
        return React.cloneElement(child, {
          className: classnames(child.props.className || "", {
            "rounded-l-none hover:rounded-l-none border-r-none": index > 0,
            "rounded-r-none hover:rounded-r-none border-l-none":
              index < children.length - 1,
          }),
        });
      })}
    </div>
  );
};

export default ButtonGroup;
