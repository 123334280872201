import React from "react";
import classnames from "classnames";
import {Typography} from "Components";
const TableCell = ({
  renderFunction,
  value,
  cellClassNames,
  row,
  truncate = true,
  colSpan = 1,
}) => {
  const textProps = {
    type: "tsm",
    color: "text-gray",
    weight: "normal",
    className: "truncate",
  };

  const render = React.useMemo(() => {
    try {
      // If we have a render function, we call it with the value and row
      if (typeof renderFunction === "function") {
        const renderedComponent = renderFunction(value, row);
        // If the renderedComponent is a string, we wrap it in a Typography component
        // If it is already a Typography or Trans component we need to apply the correct classnames and props
        // Otherwise we just return the component

        if (typeof renderedComponent === "string") {
          return <Typography {...textProps}>{renderedComponent}</Typography>;
        }

        if (
          renderedComponent?.type?.name === "Typography" ||
          renderedComponent?.type?.name === "Trans"
        ) {
          return React.cloneElement(renderedComponent, {
            ...textProps,
          });
        }

        if (React.isValidElement(renderedComponent)) {
          // Apply the text props as classnames to the component

          return React.cloneElement(renderedComponent, {
            className: classnames(
              renderedComponent.props.className,
              "text-body4 text-gray"
            ),
          });
        }

        return renderedComponent; // Don't know how to render this
      }
    } catch (e) {
      console.error("Error executing render function", e);
      // If we caught an error show noting
      return null;
    }

    return <Typography {...textProps}>{value}</Typography>;
  }, [renderFunction, value]);

  return (
    <td
      className={classnames(cellClassNames, truncate && "truncate")}
      colSpan={colSpan}
    >
      {render}
    </td>
  );
};

export default TableCell;
