import React from "react";
import classnames from "classnames";
import {bgColor} from "Components/CTASection/CTASection.constants";
import {safeCloneTextTag} from "utilities/safeClone";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";

const CTASection = ({
  type = "primary",
  inverted = false,
  title,
  icon,
  subTitle,
  children,
  imageUrl = null,
  imageComponent = null,
}) => {
  const hasImage = imageUrl != null || imageComponent != null;
  const smallScreens = useBreakPointSM();

  return (
    <div
      className={classnames(
        "p-8 rounded-xl overflow-hidden",
        "w-full",
        bgColor(type, inverted),
        hasImage && "flex flex-col md:flex-row items-center justify-between"
      )}
    >
      <div
        className={classnames(
          "flex gap-8 justify-between flex-col items-start z-10",
          // horizontal ? "items-center" : "flex-col items-stretch",
          hasImage && "md:w-56 grow flex-col items-stretch"
        )}
      >
        <div className="flex flex-col items-start gap-4">
          {icon}
          {safeCloneTextTag(title, {
            color: inverted ? "text-white" : "text-gray-900",
            type: "dsm",
            weight: "sb",
          })}
          {safeCloneTextTag(subTitle, {
            color: inverted ? "text-gray-200" : "text-gray-light",
            type: smallScreens("txl", "tlg"),
          })}
        </div>
        <div>
          {children &&
            React.cloneElement(children, {
              className: classnames(children.props.className),
            })}
        </div>
      </div>

      {imageUrl && (
        <img
          src={imageUrl}
          alt="cta"
          className="md:block w-1/2 h-auto rounded-lg -mr-10 -mb-10 ml-2 mt-2 absolute top-4 z-0"
        />
      )}
      {imageComponent && (
        <div className="md:block w-1/2 h-auto rounded-lg -mr-10 -mb-10 ml-2 mt-2 absolute top-4 right-8 z-0">
          {React.cloneElement(imageComponent, {})}
        </div>
      )}
    </div>
  );
};

export default CTASection;
