import useMarkNotificationAsRead from "API/mutations/notification/useMarkNotificationAsRead";
import {Avatar} from "Components";
import React from "react";
import {useHistory} from "react-router-dom";
import relativeTime from "utilities/relativeTime";
import useNotificationDisplayMessage from "./useNotificationDisplayMessage";

const NotificationsListElement = ({notification}) => {
  const [markNotificationAsRead] = useMarkNotificationAsRead();
  const notificationDisplayMessages = useNotificationDisplayMessage();

  const {url, message} = notificationDisplayMessages[notification.type](
    notification
  );

  const history = useHistory();

  const onNotificationClick = () => {
    markNotificationAsRead({variables: {id: notification.id}});
    history.push(url);
  };

  return (
    <div
      onClick={onNotificationClick}
      className="flex items-center justify-between mt-2 p-2 hover:bg-gray-100 rounded-md cursor-pointer"
    >
      <Avatar
        src={notification.sender.avatar}
        user={notification.sender}
        name={relativeTime(notification.createdAt)}
        description={message}
      />
      {notification.read ? null : (
        <span class="relative flex h-2 w-2">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-500 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-2 w-2 bg-primary-400"></span>
        </span>
      )}
    </div>
  );
};

export default NotificationsListElement;
