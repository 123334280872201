import {ErrorBoundary} from "Components";
import ProductPurchaseExpiryBanner from "Components/ProductPurchaseExpiryBanner/ProductPurchaseExpiryBanner";
import React from "react";
import {CourseContext} from "routes/Course/CourseContextProvider";
import CourseLandingAllReviews from "./CourseLandingAllReviews/CourseLandingAllReviews";
import CourseLandingDiscussions from "./CourseLandingDiscussions/CourseLandingDiscussions";
import CourseLandingExams from "./CourseLandingExams/CourseLandingExams";
import CourseLandingNavigation from "./CourseLandingNavigation/CourseLandingNavigation";
import CourseLandingTitleBanner from "./CourseLandingTitleBanner/CourseLandingTitleBanner";
import CourseLandingTopics from "./CourseLandingTopics/CourseLandingTopics";
import CourseLandingUnlockCard from "./CourseLandingUnlockCard/CourseLandingUnlockCard";
import CourseStudyPlanBanner from "./CourseStudyPlanBanner/CourseStudyPlanBanner";
import Footer from "routes/Landing/Footer/Footer";
import "./CourseLanding.scss";
import CourseLandingContext from "./CourseLandingContext";

const CourseLandingDesktop = () => {
  const course = React.useContext(CourseContext);

  return (
    <>
      <CourseLandingContext>
        <ProductPurchaseExpiryBanner
          productType="COURSE"
          productId={course.id}
        />
        <div className="course-landing">
          <div className="course-landing-primary">
            <CourseLandingNavigation />
            <CourseLandingTitleBanner />
            <CourseLandingUnlockCard />
          </div>

          <div className="course-landing-content">
            <ErrorBoundary>
              <CourseLandingTopics />
            </ErrorBoundary>
            <ErrorBoundary>
              <CourseStudyPlanBanner />
            </ErrorBoundary>
            <ErrorBoundary>
              <CourseLandingExams />
            </ErrorBoundary>
            <ErrorBoundary>
              <CourseLandingDiscussions />
            </ErrorBoundary>
            <ErrorBoundary>
              <CourseLandingAllReviews />
            </ErrorBoundary>
          </div>
        </div>
      </CourseLandingContext>

      <Footer />
    </>
  );
};

export default CourseLandingDesktop;
