import React from "react";
import { isMobile } from "react-device-detect";
import useTabVisibility from "utilities/hooks/useTabVisibility";

const UseExerciseViewFocusMode = (allowFocusMode, exerciseId) => {
  const [manualFocusMode, setManualFocusMode] = React.useState(false);

  const manualToggleFocusMode = () => {
    setManualFocusMode(!manualFocusMode);
  };

  const visible = useTabVisibility();

  const [focused, setFocused] = React.useState(false);

  const threshold = 90; // one and half minute of inactivity
  const [idleTime, setIdleTime] = React.useState(0);

  /**
   * The timer is turned off for now.
   * This causes a lot of re-renders and I am not sure if this is efficient.
   * A better approach is to use merge the manualFocusMode state and the focused state
   */

  // const mouseMoveListener = () => {
  //   // Every time the mouse moves, set the focused state to false
  //   setIdleTime(0);
  // };
  //
  // // When exerciseId changes, reset the idle time
  // React.useEffect(() => {
  //   setIdleTime(0);
  // }, [exerciseId]);
  //
  // // Mouse listener timeout
  // React.useEffect(() => {
  //   // If the user moves the mouse, the timeout is cleared
  //   document.addEventListener("mousemove", mouseMoveListener);
  //   // If the user scrolls, the timeout is cleaered
  //   document.addEventListener("scroll", mouseMoveListener);
  //
  //   return () => {
  //     document.removeEventListener("mousemove", mouseMoveListener);
  //     document.removeEventListener("scroll", mouseMoveListener);
  //   };
  // }, []);

  // Count every second
  // React.useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIdleTime(prev => {
  //       if (visible && prev <= threshold) {
  //         return prev + 1;
  //       }
  //       return prev;
  //     });
  //   }, 1000);
  //
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // If the user is idle for threshold seconds, set the overlay
  React.useEffect(() => {
    if (idleTime > threshold) {
      setFocused(true);
    }

    if (focused && idleTime === 0) {
      setFocused(false);
    }
  }, [idleTime]);

  // If visibility changes, reset the idle time
  React.useEffect(() => {
    setIdleTime(0);
  }, [visible]);

  if (isMobile || !allowFocusMode) {
    return [false, manualToggleFocusMode];
  }

  if (manualFocusMode) {
    return [true, manualToggleFocusMode];
  }

  return [focused, manualToggleFocusMode];
};

export default UseExerciseViewFocusMode;
