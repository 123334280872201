import React from "react";
import PropTypes from "prop-types";
import {Select, Skeleton} from "Components";
import useGetSchools from "API/queries/school/useGetSchoolsWithCourses";
import {useTranslation} from "react-i18next";

SchoolPicker.propTypes = {
  onSelect: PropTypes.func.isRequired, // Callback when the school is selected
};

function SchoolPicker({onSelect, selectedSchoolId, ...props}) {
  const {t: trans} = useTranslation();
  const [schools, schoolsLoading] = useGetSchools();

  if (schoolsLoading) return <Skeleton size={"lg"} />;

  const options = schools.map(school => ({
    value: school.id,
    text: school.name,
  }));

  options.unshift({
    value: null,
    text: trans("No school"),
  });

  return (
    <Select
      defaultSelectedValue={selectedSchoolId}
      options={options}
      onSelect={onSelect}
      placeholder={props.placeholder || trans("Select school")}
      showSearch
      transparent={false}
    />
  );
}

export default SchoolPicker;
