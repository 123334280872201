import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import formatExercise from "utilities/exerciseUtilities/formatExercise";

export const GET_COURSE_EXERCISES = gql`
  query getCourseExercises(
    $courseId: ID!
    $includeTags: Boolean!
    $includeSelfEvaluation: Boolean! = false
    $includeNotes: Boolean! = false
    $includeStats: Boolean! = false
    $includeSource: Boolean! = false
    $exerciseFilters: ExerciseFiltersInput
  ) {
    getCourse(id: $courseId) {
      id
      exercises(filters: $exerciseFilters) {
        id
        number
        score
        published
        imageUrl
        tags @include(if: $includeTags) {
          id
          name
          module(course_id: $courseId) {
            id
            name
          }
        }
        self_evaluation @include(if: $includeSelfEvaluation) {
          id
          score
          date
        }
        bookmarked
        notes @include(if: $includeNotes) {
          id
          text
        }
        source @include(if: $includeSource) {
          id
          date
          author
        }
        stats @include(if: $includeStats) {
          total
          easy
          medium
          hard
          nightmare
        }
      }
    }
  }
`;

const useGetCourseExercises = ({
  courseId,
  includeTags = false,
  includeSelfEvaluation = false,
  includeNotes = false,
  includeSource = false,
  includeStats = false,
  exerciseFilters = {},
}) => {
  const {loading, error, data} = useQuery(GET_COURSE_EXERCISES, {
    variables: {
      courseId,
      includeTags,
      includeSelfEvaluation,
      includeNotes,
      includeSource,
      includeStats,
      exerciseFilters,
    },
  });

  let exercises = [];

  if (error) {
    console.log("Error in useGetCourseExercises: ", error);
  }

  if (data && data.getCourse.exercises) {
    exercises = data.getCourse.exercises.map(ex => formatExercise(ex));
  }

  return [exercises, loading];
};

export default useGetCourseExercises;
