import React from "react";
import { ErrorBoundary, Loader } from "Components/index";

const Maybe = ({ children, dep, fallback, loading }) => {
  if (loading) {
    return <Loader />;
  }
  if (dep === undefined || dep === null || dep === 0 || dep === false) {
    if (fallback) {
      return fallback;
    }

    return null;
  }

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default Maybe;
