import React from "react";
import {Table, Typography} from "Components";
import moment from "moment";
import useGetCurrentUserCreditTransactions from "API/queries/user/useGetCurrentUserCreditTransactions";
import {accountSubSectionTitleProps} from "routes/Account/Account";
import AccountCreditsTransactionsTableDescription from "routes/Account/AccountCredits/AccountCreditsTransactionsTableDescription";
import {groupBy} from "utilities/objects";
import i18n from "i18n";
import Trans from "Components/Trans";

const columns = [
  {
    title: <Trans>Date</Trans>,
    dataIndex: ["createdAt"],
    key: "redeemed",
    width: 120,
    render: createdAt => (
      <Typography>{moment(createdAt).format("DD MMM YYYY")}</Typography>
    ),
  },
  {
    title: <Trans>Credits</Trans>,
    dataIndex: ["amount"],
    key: "amount",
    width: 120,
    render: amount => <Typography>{amount / 100}</Typography>,
  },
  {
    title: <Trans>Description</Trans>,
    dataIndex: ["description"],
    key: "description",
    render: (text, transaction) => (
      <AccountCreditsTransactionsTableDescription transaction={transaction} />
    ),
  },
];

const AccountCreditsTransactionsTable = () => {
  const [creditTransactions, loading] = useGetCurrentUserCreditTransactions();
  if (loading || creditTransactions.length === 0) return null;
  const data = getTransactionsData(creditTransactions);

  return (
    <Table
      size="sm"
      card={{
        showAsCard: false,
        title: <Trans>Credit history</Trans>,
        subTitle: (
          <Trans>See the transactions for receiving and using credits</Trans>
        ),
      }}
      fixed={false}
      data={data}
      columns={columns}
      pagination={{pageSize: 10}}
    />
  );
};

const getTransactionsData = transactions => {
  // extract GPT transactions, relatedObjectType = "GPT-Session" and group them by day.
  let gptTransactions = transactions.filter(transaction => {
    return transaction.relatedObjectType === "GPT-Session";
  });

  const otherTransactions = transactions.filter(transaction => {
    return transaction.relatedObjectType !== "GPT-Session";
  });

  const gptTransactionsByDay = groupBy(gptTransactions, "createdAt", val =>
    moment(val)
      .startOf("day")
      .format("YYYY-MM-DD")
  );

  // Collapse gptTransactions into a single transaction per day
  gptTransactions = Object.keys(gptTransactionsByDay).map(key => {
    const gptTransactionsOnDay = gptTransactionsByDay[key];

    const amount = gptTransactionsOnDay.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );

    return {
      ...gptTransactionsOnDay[0], // Keep metadata from first transaction
      description: i18n.t("Kollin-GPT usage"),
      amount,
    };
  });

  let data = [...gptTransactions, ...otherTransactions];

  data.sort((a, b) => {
    return moment(b.createdAt).diff(moment(a.createdAt));
  });

  return data;
};

export default AccountCreditsTransactionsTable;
