import React from "react";
import CourseChatGPTHeader from "../../../Widgets/Course/CourseChatGPT/CourseChatGPTHeader";
import GPTProvider from "../GPTProvider";
import CourseChatGPTResults from "../../../Widgets/Course/CourseChatGPT/CourseChatGPTResults";
import GPTPromptInput from "../GPTPromptInput";
import useGPTDisplayModalState from "./useGPTDisplayModalState";
import classnames from "classnames";
import GPTActions from "../GPTActions";
import {useTranslation} from "react-i18next";

const GPTDisplayDashboard = props => {
  const parentRef = React.useRef(null);

  const {t: trans} = useTranslation();

  return (
    <GPTProvider className="bg-gray-800 rounded-lg overflow-clip" {...props}>
      <div className={classnames("w-full")} ref={parentRef}>
        <CourseChatGPTHeader parentRef={parentRef} inverted={true} />

        <GPTActions saveFormat="chapter" inverted />
        <div
          className={classnames(
            "flex flex-col gap-2 w-full -mt-4 bg-gray-800 p-4"
          )}
        >
          <CourseChatGPTResults
            showAvatar={true}
            resultsContainerHeight="h-[24rem]"
            inverted
          />
          <GPTPromptInput placeholder={trans("Ask me anything")} />
        </div>
      </div>
    </GPTProvider>
  );
};

export default GPTDisplayDashboard;
