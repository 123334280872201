import React from "react";
import useGetCurrentLocale from "utilities/hooks/useGetCurrentLocale";

const ChapterDisplayVideo = ({html}) => {
  /**
   * We get a string of iframe tags, and we want to extract the src link
   */
  const lang = useGetCurrentLocale();
  console.log(html);

  const videos = React.useMemo(() => {
    try {
      const sources = html.match(/src="(\w|\/|:|\.|&|=|-|\?)+"/g);

      if (sources.length === 0) return [];

      return sources.map(link => {
        let newLink = link.replace('src="', "");
        newLink = newLink.replace('"', "");

        return newLink;
        // `&cc_lang_pref=${lang}&fs=1&hl=${lang}&modestbranding=1&rel=0`
      });
    } catch (e) {
      return [];
    }
  }, [html]);

  return (
    <div className="flex flex-col gap-2 items-start justify-start">
      {videos.map((video, index) => {
        return (
          <div className="w-full" key={index}>
            <iframe
              src={video}
              className="w-full aspect-video"
              loading="lazy"
              fetchPriority={index === 0 ? "high" : "low"}
              allowFullScreen={true}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ChapterDisplayVideo;
