import React from "react";
import paramAgnosticPathCompare from "utilities/paramAgnosticPathCompare/paramAgnosticPathCompare";
import {Icon, Link, SafeText} from "Components";
import classnames from "classnames";
import {motion} from "framer-motion";

const CourseSidebarRoute = ({
  currentPath,
  url,
  path,
  to = null, // override path
  activePaths,
  hideInNavigation,
  label,
  icon,
  collapsed,
  toggleCollapsed,
  expandOnHover,
  color = "text-gray",
  selectedColor = "text-primary",
  background = "background outline-primary",
  selectedBackground = "surface-primary outline-color",
  outlineColor = "outline-primary",
  iconColor = "text-gray-light",
  iconSelectedColor = "text-primary-light",
  motionProps = {},
  textMotionProps = {},
  showLabelOnHover = true,
}) => {
  const selected = React.useMemo(
    () =>
      paramAgnosticPathCompare(path, currentPath) ||
      (activePaths
        ? activePaths.some(activePath =>
            paramAgnosticPathCompare(activePath, currentPath)
          )
        : false), // activePath is used for routes that are not in the sidebar but are sub-path of top-level routes
    [currentPath, path, activePaths]
  );

  const ref = React.useCallback(node => {
    if (node !== null && selected) {
      node.scrollIntoView({behavior: "smooth", block: "nearest"});
    }
  }, []);

  if (hideInNavigation) return null;

  return (
    // Basic link ensures the content is returned as is, without any styling
    <Link to={to || `${url}/${path}`} basic className="z-100">
      <motion.div
        ref={ref}
        className={classnames(
          "flex p-2 gap-2 rounded-md relative group my-px hover:outline hover:outline-1 ",
          selected ? selectedBackground : background,
          collapsed ? "hover:shadow-sm" : ""
        )}
        initial={false}
        animate={{
          width: collapsed ? "fit-content" : "100%",
        }}
        {...motionProps}
      >
        <Icon
          icon={icon}
          size="md"
          fixedWidth={true}
          color={selected ? iconSelectedColor : iconColor}
        />

        <SafeText
          weight="md"
          color={selected ? selectedColor : color}
          className={classnames(
            "whitespace-nowrap truncate",
            collapsed ? "hidden" : "block",
            collapsed &&
              showLabelOnHover &&
              "group-hover:block group-hover:background"
          )}
        >
          {label}
        </SafeText>
      </motion.div>
    </Link>
  );
};

export default CourseSidebarRoute;
