import React from "react";
import {GeogebraContext} from "Components/Geogebra/GeogebraProvider";

const useGeogebraDisplayEquations = () => {
  const {currentEquation} = React.useContext(GeogebraContext);

  const allEquations = currentEquation?.split("\\quad").map(equation => {
    let cleaned = equation;
    // Remove trailing . from equation
    cleaned = cleaned.replace(/\.$/, "").trim();
    // Remove trailing , from equation
    cleaned = cleaned.replace(/,$/, "").trim();
    cleaned = cleaned.replace(/&lt;/, "<").trim();

    return cleaned;
  });

  const [equations, setEquations] = React.useState(
    allEquations || ["f(x)= x^2"]
  );

  const [selectedEquation, setSelectedEquation] = React.useState(
    allEquations[0]
  );

  const editEquation = (index, equation) => {
    setEquations(prev => prev.map((eq, i) => (i === index ? equation : eq)));
    setSelectedEquation(equation);
    setEditing(false); // Close the edit box
  };

  const [editing, setEditing] = React.useState(false);

  return {
    equations,
    editEquation,
    selectedEquation,
    setSelectedEquation,
    editing,
    setEditing,
  };
};

export default useGeogebraDisplayEquations;
