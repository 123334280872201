import React from "react";
import GroupHomeCoursesCard from "./GroupHomeCoursesCard";
import GroupHomeMembersCard from "./GroupHomeMembersCard";
import GroupHomeExamsCard from "./GroupHomeExamsCard";

const GroupHomeLinkCards = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
      <GroupHomeCoursesCard />
      <GroupHomeMembersCard />

      {/*<GroupHomeExamsCard />*/}
    </div>
  );
};

export default GroupHomeLinkCards;
