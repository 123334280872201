import React from "react";
import PropTypes from "prop-types";
import {motion} from "framer-motion";
import {useHistory} from "react-router-dom";

import {Trans, Typography} from "Components";

const SchoolsCarousel = ({schools}) => {
  const history = useHistory();

  return (
    <div className="flex flex-nowrap gap-4 overflow-x-auto mt-2 snap-x w-screen px-4">
      {schools.map(school => {
        const coursesCount = school.coursesCount;
        const studentsCount = school.studentsCount || 100;
        return (
          <div
            key={`school-card-${school.id}`}
            onClick={() => {
              window.analytics.track("homepage_school_card_clicked", {
                school_id: school.id,
                school_name: school.name,
              });
              history.push(`/courses?school=${school.id}`);
            }}
            className={
              "group h-48 md:h-96 aspect-[4/3]  rounded-xl cursor-pointer snap-center "
            }
          >
            <div className="group relative overflow-clip w-full h-full rounded-xl">
              <motion.div
                className="absolute z-10 group-hover:scale-110 ease-in-out	transition-all inset-0 bg-cover bg-center bg-no-repeat w-full h-full rounded-xl"
                style={{
                  backgroundImage: `url(https://d1mgntrf3vaj6d.cloudfront.net/schools-images/${school.id}.jpg)`,
                }}
              />
              {/*Slight overlay*/}
              {/*<div className="absolute inset-0 bg-black opacity-20 rounded-xl"></div>*/}
              {/* Container for text */}
              <div
                className=" absolute z-20 rounded-xl h-full w-full flex flex-col justify-end p-4 text-white "
                style={{
                  // bg-gradient-to-t from-gray-800
                  background:
                    "linear-gradient(to top, #1D2939 0%, rgba(0,0,0,0) 100%)",
                }}
              >
                {/*
              box with linear gradient from bottom to top
            */}
                <div className="hidden gap-4 hidden md:flex">
                  <Trans coursesCount={coursesCount} type="tsm md:tmd">
                    {{coursesCount}} courses
                  </Trans>
                  |
                  <Trans studentsCount={studentsCount} type="tsm md:tmd">
                    {{studentsCount}} active students
                  </Trans>
                </div>

                <Typography type="dxs md:dsm" weight="sb" color="text-white">
                  {school.name}
                </Typography>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

SchoolsCarousel.propTypes = {
  schools: PropTypes.array,
};

export default SchoolsCarousel;
