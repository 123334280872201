import React from "react";
import classnames from "classnames";
import DifficultyChip from "Components/DifficultyChip/DifficultyChip";
import { Typography } from "Components";

const CourseLandingExercisesExerciseCard = ({
  onClick,
  selected,
  exercise,
}) => {
  return (
    <div
      onClick={onClick}
      className={classnames(
        "background p-2 w-36 shrink-0 rounded-lg border-2 ",
        "shadow-md",
        "cursor-pointer",
        "snap-start",
        selected ? "border-primary-400" : "border-gray-100"
      )}
    >
      <DifficultyChip score={exercise.score} filled height={36} />

      <Typography
        className="mt-1"
        type="txs"
        color="text-primary-600"
        weight="b"
      >
        {exercise.date}#{exercise.number}
      </Typography>

      <Typography className="mt-1" type="txs" color="text-gray-600">
        {exercise.author || "Okänd författare"}
      </Typography>
    </div>
  );
};

export default CourseLandingExercisesExerciseCard;
