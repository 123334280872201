import React from "react";
import {decodeTokens} from "./utilities/authentication";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import * as Sentry from "@sentry/react";
import useEventTracking from "utilities/hooks/useEventTracking";

export const UserContext = React.createContext(null);
window.UserContext = UserContext;

const UserContextProvider = ({children}) => {
  const {logUserProperties} = useEventTracking();

  const decoded = decodeTokens();

  const [decodedUser, setUser] = React.useState(decoded ? decoded.user : null);

  const [serverUser, loading, refetchServerUser] = useGetCurrentUser();

  React.useEffect(() => {
    if (decodedUser && !serverUser.id) {
      refetchServerUser();
    }
  }, [decodedUser]);

  let user = null;

  if (serverUser.id || decodedUser) {
    user = {...decodedUser, ...serverUser};
  }

  React.useEffect(() => {
    if (user && user.id && user.email) {
      logUserProperties(user);

      Sentry.setUser({
        id: user.id,
        username: user.first_name + " " + user.last_name,
      });
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        // Merge properties from decodedUser and serverUser
        user,
        loading,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const userContext = () => {
  return React.useContext(UserContext);
};

export default UserContextProvider;
