import React, {useState} from "react";
import MessageComponent from "Components/_Elements/Message/MessageComponent";
import {AnimatePresence, AnimateSharedLayout} from "framer-motion";
import {mountMessageQueueContainer} from "./initMessageQueueContainer";

let addMessage = () => {};

const createMessage = (message, defaultConfig, delay) => {
  return {
    id: Math.random() * 1e10,
    timeout: defaultConfig.timeout + delay,
    ...defaultConfig,
    ...message,
  };
};

const MessageQueueContainer = () => {
  const [messageQueue, setMessageQueue] = useState([]);

  const defaultConfig = {
    timeout: 2000, // remove the message after 2 seconds
    replace: false, // if true, future messages with the same id will replace this one
  };

  // override global scope addMessage function
  addMessage = message => {
    if (!setMessageQueue) return null;

    setMessageQueue(queue => {
      // If the last message has replace set to true, replace the new message with the last one
      const lastMessage = queue[queue.length - 1];

      if (lastMessage && lastMessage.replace) {
        queue[queue.length - 1] = createMessage(
          {
            ...queue[queue.length - 1], // keep the id of the last message
            ...message,
            replace: message.replace || false, // if the new message has replace set to true, keep it
          },
          defaultConfig,
          0
        );
        return [...queue];
      }

      return [
        ...queue,
        // New message:
        createMessage(message, defaultConfig, queue.length * 100),
      ];
    });
  };

  const removeMessage = id => {
    setMessageQueue(queue => queue.filter(message => message.id !== id));
  };

  return (
    <div className="pt-4 sm:pt-2">
      <AnimatePresence presenceAffectsLayout>
        <AnimateSharedLayout type="crossfade">
          {messageQueue.map((message, index) => (
            <MessageComponent
              key={message.id}
              index={index}
              message={message}
              onExit={() => removeMessage(message.id)}
            />
          ))}
        </AnimateSharedLayout>
      </AnimatePresence>
    </div>
  );
};

/**
 * Initialize the message queue so it can be used globally
 * @param message
 *
 */
mountMessageQueueContainer(MessageQueueContainer);

export default {
  info: (content, config = {}) => {
    addMessage({content, type: "info", ...config});
    return null;
  },
  warning: (content, config) => {
    addMessage({content, type: "warning", ...config});
    return null;
  },
  error: (content, config) => {
    addMessage({content, type: "error", ...config});
    return null;
  },
  success: (content, config) => {
    addMessage({content, type: "success", ...config});
    return null;
  },
  loading: (content, config) => {
    addMessage({content, type: "loading", ...config, replace: true});
    return null;
  },
  trigger: (content, config) => {
    addMessage({content, type: "trigger", ...config});
    return null;
  },
  config: config => {
    // empty function to prevent errors
    return null;
  },
};
