import React from "react";
import {Button, ProgressSteps, Section, Trans, Typography} from "Components";
import useGetGroup from "API/queries/groups/useGetGroup";
import useGroupAdminUpload from "./useGroupAdminUpload";
import GroupAdminUploadPanesInvite from "./GroupAdminUploadPanes/GroupAdminUploadPanesInvite/GroupAdminUploadPanesInvite";
import GroupAdminUploadFile from "./GroupAdminUploadFile/GroupAdminUploadFile";
import GroupAdminUploadResult from "./GroupAdminUploadResult";
import GroupAdminUploadMembersValidate from "./GroupAdminUploadMembersValidate/GroupAdminUploadMembersValidate";
import GroupInvites from "routes/Group/GroupInvites/GroupInvites";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";

const GroupAdminUpload = () => {
  const {groupId} = useGroupContext();

  const [group, groupLoading] = useGetGroup({
    id: groupId,
    includeMembers: true, // To include members in the group we need to match the members with the CSV file
    includeSubGroups: true, // Include all subgroups and their members
  });

  const groupMembers = React.useMemo(() => {
    if (groupLoading) return [];

    return group.subGroups
      .reduce((acc, curr) => [...acc, ...curr.members], [])
      .concat(group.members);
  }, [group]);

  const {
    result,
    matchLoading,
    fileMembers,
    setFileMembers,
    clearFileMembers,
    currentStep,
    handleNextStep,
    setCurrentStep,
    handleFinish,
  } = useGroupAdminUpload({
    group,
    groupMembers,
    groupLoading,
  });

  if (groupLoading) {
    return null;
  }

  return (
    <>
      <GroupInvites />
      <div className="mt-8">
        <Section
          title={<Typography>Validera och bjud in medlemmar</Typography>}
          subTitle={
            <Trans>
              Upload a CSV file with members and bulk validate or invite them to
              to your group
            </Trans>
          }
        >
          <ProgressSteps
            size="lg"
            numbered
            vertical={true}
            currentStep={currentStep}
            className={currentStep === null ? "opacity-75" : "opacity-100"}
            onStepClick={step => {
              if (currentStep === null) {
                setCurrentStep(0);
              }

              if (step < currentStep) {
                setCurrentStep(step);
              }
            }}
            steps={[
              {
                title: "Ladda upp CSV",
                description: "Ladda upp en CSV-fil med medlemmar",
                status:
                  currentStep === 0
                    ? "current"
                    : currentStep > 0
                    ? "complete"
                    : "incomplete",
                component: (
                  <GroupAdminUploadFile
                    fileMembers={fileMembers}
                    setFileMembers={setFileMembers}
                    clearFileMembers={clearFileMembers}
                    handleNextStep={handleNextStep}
                    currentStep={currentStep}
                  >
                    <GroupAdminUploadResult
                      matchLoading={matchLoading}
                      result={result}
                      fileMembers={fileMembers}
                      groupMembers={groupMembers}
                    />
                  </GroupAdminUploadFile>
                ),
              },
              {
                title: "Validera gruppmedelmmar",
                description: "Vi identifierar medlemmarna i din CSV-fil",
                status:
                  currentStep === 1
                    ? "current"
                    : currentStep > 1
                    ? "complete"
                    : "incomplete",
                component: (
                  <GroupAdminUploadMembersValidate
                    fileMembers={fileMembers}
                    group={group}
                    result={result}
                    handleNextStep={handleNextStep}
                  />
                ),
              },
              {
                title: "Bjud in studenter till gruppen",
                description:
                  "Bjud in studenter som inte är medlemmar i gruppen direkt från Kollin",
                status:
                  currentStep === 2
                    ? "current"
                    : currentStep > 2
                    ? "complete"
                    : "incomplete",
                component: (
                  <>
                    <GroupAdminUploadPanesInvite
                      fileMembers={fileMembers}
                      result={result}
                      // findEmailColumn={findEmailColumn}
                    />
                    <Button
                      type="destructive"
                      fluid
                      className="mt-6"
                      onClick={handleFinish}
                    >
                      Avsluta uppladdning och rensa filer
                    </Button>
                  </>
                ),
              },
            ]}
          />
        </Section>
      </div>
    </>
  );
};

export default GroupAdminUpload;
