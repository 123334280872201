import React from "react";
import {courseRoutes} from "routes/Course/courseRoutesList";
import useGetCurrentRoute from "../../../utilities/hooks/course/useGetCurrentRoute";
import {CourseParamsContext} from "../../../routes/Course/CourseRouter";
import {Icon, Link} from "../index";
import BreadcrumbTag from "./BreadcrumbTag";
import BreadCrumbModule from "./BreadCrumbModule";
import BreadCrumbExam from "./BreadCrumbExam";
import BreadCrumbExercise from "./BreadCrumbExercise";
import BreadCrumbChapter from "Components/_Elements/Breadcrumb/BreadCrumbChapter";
import {isMobile, isMobileOnly} from "react-device-detect";
import useCurrentParams from "utilities/hooks/useCurrentParams";

/**
 * Automatically render the breadcrumbs based on the currentRoute and Params.
 *
 * A: Some Params are coming from the URLParams, from CourseParamsContext see: useCourseParams/PARAMS
 * B: Some params are coming from the router instead, e.g. examId which is accessed using the currentParams dictionary.
 *
 * We need to first merge params coming from source A and B, e.g:
 *     const examId = currentParams.examId || urlParams.exam || null;
 *
 * There are multiple tiers in the BC.
 *
 * Tier 1
 * ------------------------
 * Root level route, this is taken directly from currentPath
 *
 *
 * @returns {JSX.Element}
 * @constructor
 */

const Breadcrumb = () => {
  const {getCourseLink} = React.useContext(window.CourseContext);
  const {currentRoute} = useGetCurrentRoute(courseRoutes);

  const {
    getModuleLink,
    getTagLink,
    getExamLink,
    getExerciseLink,
  } = React.useContext(CourseParamsContext);

  const rootPath = currentRoute?.rootPath || currentRoute?.path;

  // get path from currentPathRoute by matching it with the routes
  const rootRoute = courseRoutes.find(route => route.path === rootPath);

  const {moduleId, tagId, chapterId, examId, exerciseId} = useCurrentParams();

  // Hide on mobile
  if (isMobileOnly) return null;

  return (
    <div className="flex gap-1 items-start-end">
      <Icon icon={rootRoute.icon} size="md" />
      <Link
        type="txl"
        weight="md"
        className="text-gray-500"
        to={getCourseLink(rootRoute.path)}
      >
        {rootRoute.label}
      </Link>
      <BreadCrumbModule moduleId={moduleId} getModuleLink={getModuleLink} />
      <BreadcrumbTag
        moduleId={moduleId}
        tagId={tagId}
        getTagLink={getTagLink}
      />
      <BreadCrumbChapter chapterId={chapterId} />
      <BreadCrumbExam examId={examId} getExamLink={getExamLink} />
      {!chapterId && (
        <BreadCrumbExercise exerciseId={exerciseId} examId={examId} />
      )}
    </div>
  );
};

export default Breadcrumb;
