import React from "react";
import {GeogebraContext} from "Components/Geogebra/GeogebraProvider";
import GeogebraDisplayEquations from "./GeogebraDisplayEquations/GeogebraDisplayEquations";
import {Button, Icon} from "Components/_Elements";
import Trans from "Components/Trans";

const GeogebraDisplay = () => {
  const {currentEquation, toggleSelector, selectorActive} = React.useContext(
    GeogebraContext
  );

  const [width, setWidth] = React.useState(0);

  const containerRef = React.useCallback(node => {
    if (node !== null) {
      setWidth(node.clientWidth - 8); // 8px padding
    }
  }, []);

  if (currentEquation === null) return null;

  return (
    <div
      className="w-full aspect-square p-4"
      ref={containerRef}
      key={currentEquation}
    >
      <div className="flex items-center justify-between gap-4 mb-2 py-4 border-t-1 border-gray">
        <div className="flex items-center gap-4">
          <Icon icon="bezier-curve" size="md" color="text-gray-light" />
          <Trans
            type="tlg"
            className="leading-none"
            color="text-gray-light"
            weight="md"
          >
            Visualise function
          </Trans>
        </div>
        <Button
          onClick={toggleSelector}
          type={selectorActive ? "destructive" : "secondary"}
        >
          <Trans>Close</Trans>
        </Button>
      </div>

      <GeogebraDisplayEquations width={width} />
    </div>
  );
};

export default GeogebraDisplay;
