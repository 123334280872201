import React from "react";
import GroupHome from "routes/Group/GroupHome/GroupHome";
import GroupAuthWrapper from "routes/Group/GroupAuthWrapper";
import {isInGroup} from "Providers/GroupProvider/GroupProvider";
import groupRoutes from "Providers/GroupProvider/groupRoutes";
import GroupOnboarding from "routes/Group/GroupOnboarding/GroupOnboarding";
import GroupTrialWrapper from "routes/Group/GroupTrialWrapper/GroupTrialWrapper";

const useGroupRouteTransformer = routes => {
  // Check if the user is in a group context {groupKey}.kollin.io
  // If the user is in a group context, transform the routes so that components are replaced with the group components
  // If the user is not in a group context, return the original routes
  return React.useMemo(() => {
    const r = {...routes};

    if (isInGroup) {
      // Transform the routes => override matching routes with group routes
      Object.keys(groupRoutes).forEach(key => {
        r[key] = {...groupRoutes[key]};
      });

      r["/"].Component = GroupHome;
      r["/"].exact = true;

      // For each route where group = "auth" => set componentProps = {wrapper: GroupAuthWrapper}
      Object.keys(r).forEach(key => {
        if (r[key].group === "auth") {
          r[key].componentProps = {
            ...r[key].componentProps,
            wrapper: GroupAuthWrapper,
            showTitle: true,
            showSignupLink: true,
            onComplete: () => {
              window.location.reload();
            },
          };
        }
      });

      r["/signup"].componentProps.onComplete = null;
      r["/trial"].hideSidebar = true;
      // Override the onboarding route
      r["/onboarding"].Component = GroupOnboarding;

      return r;
    }

    return routes;
  }, [routes, isInGroup, groupRoutes]);
};

export default useGroupRouteTransformer;
