import React from "react";
import {motion} from "framer-motion";
import PropTypes from "prop-types";
import {Icon, SafeText, Typography} from "Components";
import classnames from "classnames";
import InputIconRight from "Components/_Elements/Input/InputIconRight";
import InputErrorMessage from "Components/_Elements/Input/InputErrorMessage";
import {
  sizesMap,
  textSizeMap,
} from "Components/_Elements/Input/Input.constants";
import InputClearButton from "Components/_Elements/Input/InputClearButton";

const Input = React.forwardRef((props, ref) => {
  const {
    label,
    transparent = true,
    inverted,
    placeholder,
    type,
    size = "md",
    loading,
    error,
    onChange,
    onFocus,
    style,
    disabled,
    autoFocus,
    icon, // Icon to show on the left side of the input
    iconRight,
    hint,
    successMessage,
    required = false,
    wrap = false,
    children,
    className,
    errorMessageRelative = false,
    autoComplete,
    fluid = true,
    after = null,
    clearable = false,
    ...restOfProps
  } = props;
  // Used to set the input's type locally, depending on interactions.
  let [
    currentType,
    passwordIcon,
    togglePasswordVisible,
  ] = useTogglePasswordVisible(type);

  const [focused, setFocused] = React.useState(autoFocus);

  const handleBlur = e => {
    setFocused(false);
    // Parent callback for onBlur
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  return (
    <div className={classnames("relative", fluid ? "w-full" : "w-fit")}>
      {label && (
        <Typography
          color="text-gray-700 dark:text-gray-300"
          type="tsm"
          className="mb-1.5"
          weight="md"
        >
          {label}
        </Typography>
      )}
      <motion.div
        initial={{x: 0}}
        animate={error && {x: [0, 5, -5, 0]}}
        transition={{duration: 0.25}}
        className={classnames(
          "input-field",
          transparent ? "bg-transparent" : "bg-white",
          inverted && "text-primary-25 placeholder:text-primary-25",
          disabled && "input-base-disabled",
          focused && "input-base-focused",
          error && "border-error-500",
          sizesMap[size],
          textSizeMap[size],
          className
        )}
      >
        {icon && <Icon icon={icon} size="sm" className="pr-2" />}
        {wrap ? (
          children
        ) : (
          <input
            {...restOfProps}
            className="input-element"
            ref={ref}
            type={currentType}
            placeholder={placeholder}
            onChange={onChange}
            value={props.value}
            name={props.name}
            disabled={disabled}
            autoFocus={autoFocus}
            onFocus={e => {
              if (onFocus) {
                onFocus();
              }
              setFocused(true);
            }}
            onBlur={handleBlur}
            required={required}
            autoComplete={autoComplete || props.name}
          />
        )}

        <InputIconRight
          {...props}
          togglePasswordVisible={togglePasswordVisible}
          passwordIcon={passwordIcon}
        />

        <InputClearButton
          clearable={clearable}
          onChange={onChange}
          value={props.value}
          name={props.name}
        />

        {after !== null && (
          <div className="flex items-center px-4 py-3 h-full highlight font-semibold text-white -my-3 -mr-2 ml-2 rounded-r-md">
            {after}
          </div>
        )}
      </motion.div>
      <InputErrorMessage
        error={error}
        errorMessageRelative={errorMessageRelative}
      />
      {hint && !error && (
        <SafeText type="txs" className="mt-1.5" color="text-gray-light">
          {hint}
        </SafeText>
      )}
    </div>
  );
});

const useTogglePasswordVisible = type => {
  const [currentType, setCurrentType] = React.useState(type);

  const togglePasswordVisible = () => {
    if (currentType === "password") {
      setCurrentType("text");
    } else {
      setCurrentType("password");
    }
  };

  let passwordIcon = currentType === "password" ? "eye-slash" : "eye";

  return [currentType, passwordIcon, togglePasswordVisible];
};

Input.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizesMap)),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(["text", "password", "email", "tel"]),
  loading: PropTypes.bool,
  error: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  fluid: PropTypes.bool,
  hint: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  autoComplete: PropTypes.string,
  errorMessageRelative: PropTypes.bool,
};

export default Input;
