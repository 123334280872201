import stripe from "utilities/stripe";

export default sessionId => {
  if (sessionId) {
    stripe
      .redirectToCheckout({
        sessionId,
      })
      .then(result => {
        console.log("result", result);
        // If `redirectToCheckout` fails due to a browser or network error:
        alert("Something went wrong! please try again...");
      })
      .catch(err => {
        console.log("Stripe error: ", err);
      });
  }
};
