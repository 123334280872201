import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const SIGNUP = gql`
  mutation signup(
    $method: SIGNUP_METHOD!
    $email: String
    $token: String
    $firstName: String
    $lastName: String
    $password: String
    $enrollmentYear: Int
    $schoolId: ID
    $programId: ID
    $couponId: ID
  ) {
    signup(
      method: $method
      email: $email
      token: $token
      firstName: $firstName
      lastName: $lastName
      password: $password
      enrollment_year: $enrollmentYear
      school_id: $schoolId
      program_id: $programId
      couponId: $couponId
    ) {
      ok
      token
      refreshToken
      errors {
        code
        message
        path
      }
    }
  }
`;

const useSignup = ({onCompleted = () => {}}) => {
  const [signup, {data, error, loading}] = useMutation(SIGNUP, {
    onCompleted,
  });

  if (error) {
    console.error(error);
  }

  return {signup, data, loading};
};

export default useSignup;
