import { HttpLink } from "apollo-boost";
import { setContext } from "apollo-link-context";

const contentHttpLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/wk5n7xv1bw2r`,
});

const contentLinkMiddleware = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: "Bearer Ub7BQESYiwF9w2MCzmUccvA7AhI4GZyhg41kGv7cL_U",
  },
}));

const contentfulLink = contentLinkMiddleware.concat(contentHttpLink);

export default contentfulLink;
