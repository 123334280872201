import React from "react";
import useGetContentfulStudyPlan from "API/cms/StudyPlan/useGetContentfulStudyPlan";

const useStudyPlanCardStats = studyPlan => {
  const [courseStudyPlan, loading] = useGetContentfulStudyPlan(
    studyPlan?.contentfulId
  );

  let totalExercises = 0;
  let totalSteps = 0;
  let totalBlocks = courseStudyPlan?.blocksCollection?.items?.length;

  for (let i in courseStudyPlan?.blocksCollection?.items) {
    const blockItem = courseStudyPlan.blocksCollection.items[i];
    const allStepsExercises = blockItem?.stepsCollection?.items.map(
      stepItem => {
        if (Array.isArray(stepItem?.exerciseIds)) {
          return stepItem.exerciseIds.length;
        } else {
          return 0;
        }
      }
    );

    totalExercises = totalExercises + allStepsExercises.reduce((a, b) => a + b);
    totalSteps = totalSteps + blockItem?.stepsCollection?.items.length;
  }

  const estimatedTime = Math.ceil(totalExercises * 0.25);

  return {
    totalBlocks,
    totalSteps,
    totalExercises,
    estimatedTime: estimatedTime,
  };
};

export default useStudyPlanCardStats;
