import React from "react";
import {CourseContext} from "routes/Course/CourseContextProvider";
import useGetCourseReviews from "API/queries/reivew/useGetCourseReviews";
import CourseLandingAllReviewsStats from "./CourseLandingAllReviewsStats/CourseLandingAllReviewsStats";
import {Trans} from "Components";
import CourseLandingFeatureReviewCard from "routes/Course/CourseLanding/CourseLandingFeatureReviews/CourseLandingFeatureReviewCard/CourseLandingFeatureReviewCard";
import {calculateReviewsStatistics} from "utilities/calculateReviewsStatistics";
import {CourseLandingContext} from "routes/Course/CourseLanding/useCourseLandingContext";

const CourseLandingAllReviews = () => {
  const {courseId} = React.useContext(CourseContext);
  const {navigationState} = React.useContext(CourseLandingContext);

  const {averageRating, nReviews, reviews} = useGetCourseReviews(
    courseId,
    false
  );

  const reviewStats = calculateReviewsStatistics(reviews);

  if (averageRating == 0 || nReviews == 0) {
    return null;
  }

  const reviewsSorted = reviews
    .sort((firstEl, secondEl) => secondEl.createdAt - firstEl.createdAt)
    .slice(0, 10);

  return (
    <div ref={navigationState.ratingsRef} className="px-3 mt-2">
      <Trans
        type="dsm"
        weight="sb"
        color="text-primary-700"
        className="mt-2 text-center"
      >
        Reviews
      </Trans>
      <CourseLandingAllReviewsStats
        reviewStats={reviewStats}
        averageRating={averageRating}
        nReviews={nReviews}
      />

      {reviewsSorted.map(review => {
        return (
          <CourseLandingFeatureReviewCard key={review.id} review={review} />
        );
      })}
    </div>
  );
};

export default CourseLandingAllReviews;
