import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

export const GET_EXERCISE_NOTE = gql`
  query getExerciseNote($id: ID!) {
    getExercise(id: $id) {
      id
      notes {
        id
        text
        public
        createdAt
        updatedAt
      }
    }
  }
`;

const useGetExerciseNotes = exerciseId => {
  let notes = [];

  const {loading, error, data} = useQuery(GET_EXERCISE_NOTE, {
    variables: {id: exerciseId},
  });

  if (error) {
    console.log("Error in useGetExerciseNotes", error);
  }

  if (data && data.getExercise && data.getExercise.notes) {
    notes = [...data.getExercise.notes];
  }

  return [notes, loading];
};

export default useGetExerciseNotes;
