import React from "react";
import { Trans, Typography } from "Components";

export const safeCloneTextTag = (input, props, Tag = "Typography") => {
  if (!input) return null;

  if (!["Typography", "Trans"].includes(Tag)) {
    throw new Error("Tag must be a either Typography or Trans");
  }

  // If the input is a string, return a Tag with the input as the children
  if (typeof input === "string") {
    if (Tag === "Typography") {
      return <Typography {...props}>{input}</Typography>;
    }
    if (Tag === "Trans") {
      return <Trans {...props}>{input}</Trans>;
    }

    return input;
  }

  return React.cloneElement(input, props);
};
