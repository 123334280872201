import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const UPDATE_MEMBERSHIP_SUBSCRIPTION_PLAN = gql`
  mutation updateMembershipSubscriptionPlan(
    $subscriptionPlanId: ID!
    $membershipId: ID!
  ) {
    updateMembershipSubscriptionPlan(
      subscriptionPlanId: $subscriptionPlanId
      membershipId: $membershipId
    ) {
      ok
      error {
        code
        message
      }
      membership {
        id
        subscriptionPlan {
          id
          name
          price
          interval
          pricingId
        }
      }
    }
  }
`;

const useUpdateMembershipSubscriptionPlan = onCompleted => {
  const [updateMembershipSubscriptionPlan, { data, loading }] = useMutation(
    UPDATE_MEMBERSHIP_SUBSCRIPTION_PLAN,
    {
      onCompleted,
    }
  );

  return {
    updateMembershipSubscriptionPlan,
    data,
    loading,
  };
};

export default useUpdateMembershipSubscriptionPlan;
