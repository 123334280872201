import React from "react";
import {Button, Loader, Lottie, Trans, Typography} from "Components";
import useGroupMembershipVerificationStatus from "./useGroupMembershipVerificationStatus";

const GroupMembershipVerificationStatus = ({
  fileUrl,
  groupId,
  resetFileUrl,
  groupMembershipId,
}) => {
  const {data, loading, error} = useGroupMembershipVerificationStatus({
    transcriptUrl: fileUrl,
    groupId,
    groupMembershipId,
  });

  if (loading) {
    return (
      <Wrapper>
        <Loader type="Fade" color="#f5d0fe" size={40} />

        <Typography className="text-fuchsia-100">
          Verifierar ditt registreringsbevis...
        </Typography>
      </Wrapper>
    );
  }

  if (data?.ok) {
    return (
      <Wrapper>
        <div className="w-28">
          <Lottie
            animation="checkMarkSparkles"
            replaceColors={[
              {
                from: [1, 191, 165],
                to: "#fbbf24",
              },
            ]}
            loop={false}
          />
        </div>
        <Typography className="text-fuchsia-100">
          Ditt registreringsbevis är verifierat!
        </Typography>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="w-24">
        <Lottie animation="exclamationCircle" />
      </div>
      <Typography className="text-fuchsia-100">
        Något gick fel när vi försökte verifiera ditt registreringsbevis
      </Typography>
      <Typography type="tsm" className="text-error-200">
        {error?.message || data?.message || data?.error}
      </Typography>
      <Button type="primary" fluid onClick={resetFileUrl}>
        <Typography>Prova igen</Typography>
      </Button>
    </Wrapper>
  );
};

const Wrapper = ({children}) => (
  <div className="mt-4 p-4 bg-primary-900 rounded-lg shadow-md flex flex-col gap-2 justify-center items-center ">
    {children}
  </div>
);

export default GroupMembershipVerificationStatus;
