import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

const QUERY = gql`
  query {
    testimonialCollection {
      items {
        name
        school
        body
        homepageHidden
        image {
          title
          url
        }
      }
    }
  }
`;

const useGetTestimonialsCollection = () => {
  let result = [];
  window.content = { ...window.content, testimonials: [] };

  const { loading, error, data } = useQuery(QUERY, {
    context: {
      api: "contentful",
    },
  });

  if (error) {
    console.log("Error in useGetTestimonialsCollection", error);
  }

  if (data) {
    result = data.testimonialCollection.items;
    window.content.testimonials = data.testimonialCollection.items;
  }

  return [result, loading];
};

export default useGetTestimonialsCollection;
