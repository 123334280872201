import React from "react";
import "./CourseLandingFeatureReviewCard.scss";
import {CardDeprecated, Avatar, StarRating} from "Components/index";
import moment from "moment";
import {maybeString} from "utilities/text";

const CourseLandingFeatureReviewCard = ({review}) => {
  return (
    <CardDeprecated className="course-landing-feature-review-card">
      <div className="course-landing-feature-review-card-user">
        <Avatar
          user={review.user}
          name={
            maybeString(review.user?.first_name) +
            " " +
            maybeString(review.user?.last_name)
          }
          description={<StarRating rating={Number(review.score)} />}
        />

        {/*<div className="course-landing-feature-review-card-user-rate ml-2">*/}
        {/* */}
        {/* */}
        {/*</div>*/}
      </div>
      <p>{review.text}</p>
      <small>
        {moment(review.createdAt)
          .locale("se")
          .fromNow()}
      </small>
    </CardDeprecated>
  );
};

export default CourseLandingFeatureReviewCard;
