import MD5 from "crypto-js/md5";
import {Typography} from "Components/_Elements/index";
import {programNameCleaner} from "Components/ProgramPicker/ProgramPicker";
import React from "react";
import CDN from "utilities/constants/CDN";

export const sizeTextTypes = {
  xs: "txs",
  sm: "txs",
  md: "tmd",
  lg: "tmd",
  xl: "tlg",
  "2xl": "txl",
  "3xl": "txl",
  "4xl": "txl",
  "5xl": "txl",
};

/**
 * Get the src url or icon for the avatar
 * @param {String|null} defaultSrc - The user's avatar url or null
 *
 */

export const getSrc = (defaultSrc, user) => {
  if (defaultSrc !== null) {
    return defaultSrc;
  }

  let src = defaultSrc;

  if (user) {
    src = user.avatar;
  }

  if (
    src ==
      "http://kollin.s3.eu-north-1.amazonaws.com/user-images/default.png" ||
    src ==
      "https://s3-eu-west-1.amazonaws.com/kollin/user-images/default.png" ||
    src == null
  ) {
    if (user?.id) {
      let hash = MD5(user.id.toString()).toString();
      src = `https://www.gravatar.com/avatar/${hash}?default=retro`;
    }
  }

  // Example: https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=2024571484234098&height=200&width=200&ext=1695564267&hash=AeSwT84q9Iw6C4eavxM
  if (src?.includes("https://platform-lookaside.fbsbx.com/")) {
    const srcParams = new URLSearchParams(src.split("?")[1]);
    const asidParam = srcParams.get("asid");

    if (asidParam) {
      src = `https://graph.facebook.com/${asidParam}/picture?type=square&width=300&height=300`;
    }
  }

  return src;
};

export const getNameNode = (name, size) => {
  if (name === null) {
    return null;
  }

  if (typeof name === "string") {
    return (
      <Typography type={sizeTextTypes[size]} color="text-gray-700" weight="md">
        {name}
      </Typography>
    );
  }

  return name;
};

export const getDescriptionNode = (description, size) => {
  if (description === null) {
    return null;
  }

  if (typeof description === "string") {
    return (
      <Typography
        type={sizeTextTypes[size]}
        color="text-gray-500"
        weight="normal"
      >
        {description}
      </Typography>
    );
  }

  return description;
};

export const getProgramAndYear = (user, size, showEnrollmentYear = true) => {
  if (!user?.program) {
    return null;
  }

  let result = programNameCleaner(user.program?.name);

  if (user.enrollmentYear && showEnrollmentYear) {
    result = `${result} - ${user.enrollmentYear.toString().slice(-2)}`;
  }

  return (
    <Typography type={sizeTextTypes[size]} color="text-gray-500">
      {result}
    </Typography>
  );
};
