import React from "react";
import useGetProduct from "API/queries/product/useGetProduct";
import moment from "moment";
import {Alert, Trans} from "Components";
import "./ProductPurchaseExpiryBanner.scss";

import ProductPurchaseExpiryBannerExtensionForm from "Components/ProductPurchaseExpiryBanner/ProductPurchaseExpiryBannerExtensionForm/ProductPurchaseExpiryBannerExtensionForm";
import useGetCurrentUserSubscription from "API/queries/user/useGetCurrentUserSubscription";
import {textSingularPluralTranslated} from "utilities/textSingularPlural";

const ProductPurchaseExpiryBanner = ({productType, productId}) => {
  const [modalVisible, setModalVisible] = React.useState(false);

  // If user is premium don't need to show the banner
  const [product, loading] = useGetProduct(productType, productId);

  const {user} = React.useContext(window.UserContext);
  const {
    subscription,
    loading: subscriptionLoading,
    subscriptionActive,
  } = useGetCurrentUserSubscription();

  if (
    loading ||
    subscriptionLoading ||
    (user && user.premium) ||
    (subscription && subscriptionActive) // if a subscription is active, don't return anything
  )
    return null;

  if (!product) return null;

  if (product.expiryDate == null) return null;

  const expiryDate = moment(product.expiryDate);

  const daysTilExpire = expiryDate.diff(moment(), "days");
  let Text = null;

  const productName = product.name;
  const days = textSingularPluralTranslated(daysTilExpire, "day");
  const expiryDateFormatted = expiryDate.format("dddd DD MMMM YYYY");

  // If the expiry date is within one week.
  if (daysTilExpire > 0 && daysTilExpire < 3) {
    Text = (
      <Trans productName={productName} days={{days}}>
        Your access to {{productName}} will expire in {{days}}
      </Trans>
    );
  }

  if (daysTilExpire <= 0) {
    Text = (
      <Trans
        productName={productName}
        expiryDateFormatted={{expiryDateFormatted}}
      >
        Your access to {{productName}} has expired on {{expiryDateFormatted}}.
      </Trans>
    );
  }

  if (Text) {
    return (
      <div className="mb-2">
        <Alert
          title={Text}
          type={"warning"}
          primaryAction={{
            label: <Trans>Renew purchase</Trans>,
            onClick: () =>
              (window.location.href = `/checkout?products=${encodeURIComponent(
                JSON.stringify({productId, productType})
              )}`),
          }}
          secondaryAction={{
            label: <Trans>Apply for extension</Trans>,
            onClick: () => setModalVisible(true),
          }}
        />

        <ProductPurchaseExpiryBannerExtensionForm
          productName={productName}
          productId={product.id}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      </div>
    );
  }
  return null;
};

export default ProductPurchaseExpiryBanner;
