import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_CREDITS_BUNDLES = gql`
  query getCreditsBundles {
    creditsBundles {
      id
      name
      amount
      description
      imageUrl
      createdAt
      updatedAt
      productType
      prices {
        id
        priceCents
        accessDays
        discountPercentage
      }
    }
  }
`;

const useGetCreditsBundles = () => {
  const {loading, error, data} = useQuery(GET_CREDITS_BUNDLES);

  if (error) {
    console.log("useGetCreditsBundles error: ", error);
  }

  const bundles = data?.creditsBundles || [];

  return [bundles, loading];
};

export default useGetCreditsBundles;
