import React from "react";

import "./AnimatedCheckbox.css";
import classnames from "classnames";

const scaleMap = {
  xs: 20,
  sm: 40,
  md: 60,
  lg: 100,
  xl: 120,
};

const sizeMap = {
  xs: "w-8 h-8",
  sm: "w-10 h-10",
  md: "w-12 h-12",
  lg: "w-14 h-14",
  xl: "w-16 h-16",
};

const AnimatedCheckbox = ({size = "lg"}) => {
  /**
   * Sizes are achieved using scaling, not by changing the viewBox.
   * This is because the viewBox is used to calculate the path.
   */

  return (
    <div
      className={classnames(sizeMap[size], "flex items-start justify-start")}
    >
      <svg
        className={classnames(
          "block rounded-full stroke-2 stroke-white w-12 h-12",
          "checkmark"
        )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
        style={{
          scale: scaleMap[size] + "%",
        }}
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          // Path should be dynamic based on size
          d="M14.1 27.2 l7.1 7.2 16.7-16.8"
        />
      </svg>
    </div>
  );
};

export const sizes = Object.keys(scaleMap);

export default AnimatedCheckbox;
