import React from "react";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";

const useGroupAdminUploadPanesInviteExport = ({membersToInvite}) => {
  const {name} = useGroupContext();
  const emails = membersToInvite?.map(member => member.email).join("; ") || [];
  const csv = makeCsv(membersToInvite);

  const [emailsCopied, setEmailsCopied] = React.useState(false);
  const [emailsSaved, setEmailSaved] = React.useState(false);

  const [csvCopied, setCsvCopied] = React.useState(false);
  const [csvSaved, setCsvSaved] = React.useState(false);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(emails);

    setEmailsCopied(true);
    // Allow the user to copy the text again after 2 seconds
    setTimeout(() => setEmailsCopied(false), 1000);
  };

  const handleSaveEmail = () => {
    setEmailSaved(true);
    downloadAsFile({
      text: emails,
      type: "text/plain;charset=utf-8;",
      fileName: `${name} Kollin - invites (${new Date().toLocaleDateString(
        "sv-SE"
      )}).txt`,
    });

    // Allow the user to copy the text again after 2 seconds
    setTimeout(() => setEmailSaved(false), 1000);
  };

  const handleCopyCsv = () => {
    navigator.clipboard.writeText(csv);

    setCsvCopied(true);

    // Allow the user to copy the text again after 2 seconds
    setTimeout(() => setCsvCopied(false), 1000);
  };

  const handleSaveCsv = () => {
    setCsvSaved(true);
    downloadAsFile({
      text: csv,
      type: "text/csv;charset=utf-8;",
      fileName: `${name} Kollin - invites (${new Date().toLocaleDateString(
        "sv-SE"
      )}).csv`,
    });
    setTimeout(() => setCsvSaved(false), 1000);
  };

  return {
    emails,
    handleCopyEmail,
    handleSaveEmail,
    emailsCopied,
    emailsSaved,
    csv,
    csvCopied,
    csvSaved,
    handleCopyCsv,
    handleSaveCsv,
  };
};

const makeCsv = membersToInvite => {
  if (!membersToInvite.length) return [];
  // Take keys from first member for the header
  const columns = Object.keys(membersToInvite[0]);
  const header = columns.join(", ");

  const rows = membersToInvite.map(member => {
    const values = columns.map(column => member[column]);
    return values.join(", ");
  });

  return [header, ...rows].join("\n");
};

const downloadAsFile = ({text, type, fileName}) => {
  // Save emails as a txt file
  const blob = new Blob([text], {type});

  // Save the file
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  // a.download = `${name} Kollin - invites (${new Date().toLocaleDateString(
  //     "sv-SE"
  // )}).txt`;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export default useGroupAdminUploadPanesInviteExport;
