import React from "react";
import {useTranslation} from "react-i18next";
import useGetSchools from "API/queries/school/useGetSchoolsWithCourses";
import SchoolsCarousel from "Components/ArrowCarousel/SchoolsCarousel/SchoolsCarousel";
import "./LandingPageSchoolsCarousel.scss";
import {LandingPageSectionBody} from "Components/LandingPageSection/LandingPageSection";

const LandingPageSchoolsCarousel = ({}) => {
  const {t: trans, i18n} = useTranslation();
  const [schools, schoolsLoading] = useGetSchools(true);

  const title = window.content.homepage
    ? window.content.homepage.schoolCarouselTitle
    : "";

  if (schoolsLoading) return null;

  schools.sort((a, b) => b.coursesCount - a.coursesCount);
  return (
    // <div className="pl-[4%]">
    <LandingPageSectionBody h1={title}>
      <SchoolsCarousel schools={schools}></SchoolsCarousel>
    </LandingPageSectionBody>
    // </div>
  );
};

export default LandingPageSchoolsCarousel;
