import React from "react";
import { Button, Icon, Input, message, Trans } from "Components";
import useSubmitForm from "API/sheets";

const RequestCourse = ({ onComplete }) => {
  const { user } = React.useContext(window.UserContext);
  const [state, setState] = React.useState({
    courseName: "",
    courseCode: "",
  });

  const handleChange = event =>
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });

  const { success, error, loading, submit } = useSubmitForm(
    "COURSE_REQUEST_FORM"
  );

  React.useEffect(() => {
    if (success) {
      message.success(
        "Tack för din förfrågan! Vi meddelar dig när kursen har lagts upp!"
      );
      onComplete();
    }
  }, [success]);

  const onSubmit = event => {
    event.preventDefault();
    submit({
      ...state,
      userId: user?.id,
      email: user?.email,
      programId: user?.program?.id,
      programName: user?.program?.name,
      schoolId: user?.school?.id,
      schoolName: user?.school?.name,
    });
  };

  return (
    <div>
      <Icon
        icon="fa-regular fa-hands-holding-heart"
        size="lg"
        filled
        color="text-primary-600"
        outlined
      />
      <form onSubmit={onSubmit} className="mt-4 flex flex-col gap-4">
        <Trans type="dxs">Föreslå en kurs</Trans>
        <Trans>
          Här kan du ange vilken kurs du saknar på Kollin så ska vi försöka
          lägga till den så fort som möjligt
        </Trans>
        <Input
          name="courseCode"
          onChange={handleChange}
          label={<Trans>Kurskod (valfritt)</Trans>}
        />
        <Input
          name="courseName"
          onChange={handleChange}
          label={<Trans>Kursnamn</Trans>}
          error={error}
          errorMessageRelative
        />

        <Button
          type="primary"
          iconRight="fa-light fa-paper-plane"
          onClick={onSubmit}
          loading={loading}
          error={error}
          disabled={!state.courseName || state.courseName === ""}
        >
          <Trans>Skicka</Trans>
        </Button>
      </form>
    </div>
  );
};

export default RequestCourse;
