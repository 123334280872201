import React from "react";
import CourseCardProgress from "Components/CourseCard/CourseCardProgress";
import {Badge, Trans} from "Components";
import CourseReviewsSummary from "routes/Course/CourseLanding/CourseLandingTitleBanner/CourseReviewsSummary/CourseReviewsSummary";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";

const CourseCardBottomSection = ({
  courseId,
  purchased,
  active,
  archived,
  showActiveBadge = true,
}) => {
  const smallScreens = useBreakPointSM();

  // Archived has higher precedence than active, because when a course is archived, it is most likely also active. We don't set courses to active=false when they are archived.
  if (active) {
    let Title = <Trans>Active</Trans>;
    let icon = "fa-solid fa-wave-pulse";
    let badgeType = "success";

    if (archived) {
      icon = "fa-solid fa-archive";
      badgeType = "gray";
      Title = <Trans>Completed</Trans>;
    }

    return [
      <div className="w-full mr-4" key="1">
        <CourseCardProgress courseId={courseId} archived={archived} />
      </div>,

      ...(showActiveBadge
        ? [
            <Badge
              size={smallScreens("sm", "md")}
              type={badgeType}
              icon={icon}
              key="2"
            >
              {Title}
            </Badge>,
          ]
        : []),
    ];
  }

  // If the course is neither active or archived, we return the rating element
  return [
    <CourseReviewsSummary
      key="1"
      courseId={courseId}
      showCourseTag={false}
      textSizeClassName="text-body5 md:text-body3"
    />,
  ];
};

export default CourseCardBottomSection;
