import i18n from "i18next";
//import Backend from 'i18next-xhr-backend';
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resources from "locale";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources,
    order: [
      "htmlTag",
      "querystring",
      "localStorage",
      "cookie",
      "sessionStorage",
      "navigator",
      "path",
      "subdomain",
    ],
    debug: false,
    supportedLngs: Object.keys(resources),
    nsSeparator: false,
    keySeparator: false,
    caches: ["localStorage"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
