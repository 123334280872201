import React from "react";
import useUpdateGroupMember from "API/mutations/groups/useUpdateGroupMember";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {Toggle, Trans} from "Components";

const GroupInfoSettingsAdminsToggle = ({groupMemberId}) => {
  const {allMembers, groupId} = useGroupContext();
  const member = allMembers.find(member => member.id === groupMemberId);

  const {updateGroupMember, loading} = useUpdateGroupMember({
    showMessages: false,
  });

  const onChange = () => {
    updateGroupMember({
      variables: {
        groupMemberId,
        groupId,
        admin: !member?.admin,
      },
    });
  };

  return (
    <div className="p-2 ">
      <Toggle
        name="admin"
        defaultChecked={member?.admin}
        onChange={onChange}
        loading={loading}
        disabled={loading}
        label={
          <Trans type="tsm" weight="b" color="text-primary-600">
            Admin
          </Trans>
        }
      />
    </div>
  );
};

export default GroupInfoSettingsAdminsToggle;
