import React from "react";
import {useHistory} from "react-router-dom";
import {Card, Trans, Button, Typography, Badge} from "Components";
import useGetCourseExams from "API/queries/exam/useGetCourseExams";

const CourseStatsExamsProcessed = () => {
  const {user} = React.useContext(window.UserContext);
  const history = useHistory();
  const {courseId, getCourseLink} = React.useContext(window.CourseContext);
  const examsMarkingLink = getCourseLink("exams");
  const [exams, loading] = useGetCourseExams(courseId, false);
  const examsProcessed = exams?.filter(exam => exam.exercises.length >= 1)
    ?.length;
  const examsCount = exams?.length;

  if (loading) return null;

  return (
    <Card
      title={<Trans>Exams processed</Trans>}
      action={
        <div className="flex gap-2">
          <Button
            type="link"
            disabled={!user?.id}
            size="xs"
            disabled
            onClick={() => history.push(examsMarkingLink)}
          >
            <Trans>Process more exams</Trans>
          </Button>
          <Badge type="primary" size="sm">
            <Trans>Coming soon</Trans>
          </Badge>
        </div>
      }
    >
      <Typography className="p-2" type="dmd" weight="bold">
        {examsProcessed} / {examsCount}
      </Typography>
    </Card>
  );
};

export default CourseStatsExamsProcessed;
