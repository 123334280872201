import React from "react";

const useGroupInvitesAddMembers = () => {
  const [emailsString, setEmailsString] = React.useState("");
  const [emails, setEmails] = React.useState([]);

  const handleEmailsChange = e => {
    setEmailsString(e.target.value);
  };

  // Parse emailsString string and set parsedEmails.
  React.useEffect(() => {
    let parsedEmails = emailsString
      .split(/(\s|,|\n)/gi)
      .map(email => email.trim());

    if (parsedEmails.length === 1) {
      setEmails([]);
    }

    // Remove the last element since they user may be typing a new email.
    parsedEmails.pop();

    const uniqueEmails = [
      ...new Set(parsedEmails.filter(email => email !== "")),
    ].map(email => ({
      text: email.toLowerCase(),
      isValid: /^[A-Z0-9._%+-]+@[[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email),
    }));

    setEmails(uniqueEmails);

    // Remove parsed emails from emailsString.
    // setEmailsString(
    //   emailsString.replace(new RegExp(parsedEmails.join("|"), "gi"), "")
    // );
  }, [emailsString]);

  const handleRemoveEmail = email => {
    setEmails(emails.filter(parsedEmail => parsedEmail !== email));
  };

  return {
    emailsString,
    handleEmailsChange,
    emails,
    handleRemoveEmail,
  };
};

export default useGroupInvitesAddMembers;
