import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

const GET_COURSE_STUDY_PLANS = gql`
  query getCourseStudyPlans($courseId: ID!, $auth: Boolean!) {
    getCourse(id: $courseId) {
      id
      studyPlans {
        id
        contentfulId
        published
        name
        description
        imageUrl
        purchased @include(if: $auth)
        studentsCount
        author {
          id
          first_name
          last_name
          avatar
          admin
        }
      }
    }
  }
`;

const useGetCourseStudyPlans = courseId => {
  const auth = React.useContext(window.UserContext)?.user !== null;

  let studyPlans = [];

  const { data, loading, error } = useQuery(GET_COURSE_STUDY_PLANS, {
    variables: {
      courseId,
      auth,
    },
  });

  if (error) {
    console.log(`Error in useGetCourseStudyPlans: ${error}`);
  }

  if (data && data.getCourse) {
    studyPlans = data.getCourse.studyPlans;
  }

  return [studyPlans, loading];
};

export default useGetCourseStudyPlans;
