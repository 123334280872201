import React from "react";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import {Typography, Tooltip, Badge} from "Components/_Elements";
import {Trans} from "Components/index";

const UserMenuCredits = ({}) => {
  const [user] = useGetCurrentUser(false, false);

  if (!user) return null;

  return (
    <Tooltip content={<Trans type="tsm">Kollin credits</Trans>}>
      <Badge size="md" type="primary" icon="fas fa-coins">
        <Typography type="tmd" color={"text-primary-500"} weight="sb">
          {Math.floor(user?.credits / 100)}
        </Typography>
      </Badge>
    </Tooltip>
  );
};

export default UserMenuCredits;
