import React from "react";
import classnames from "classnames";
import {safeCloneTextTag} from "utilities/safeClone";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";

const PageHeaderTitle = ({title, subTitle, label, inverted}) => {
  const smallScreens = useBreakPointSM();

  return (
    <div className="flex flex-col items-start gap-4">
      {safeCloneTextTag(label, {
        color: inverted ? "text-primary-200" : "text-primary-600",
        type: smallScreens("tsm", "tmd"),
        weight: "sb",
      })}

      {safeCloneTextTag(title, {
        color: inverted ? "text-white" : "text-gray-900",
        type: smallScreens("dmd", "dlg"),
        weight: "sb",
      })}
      <div className="max-w-4xl">
        {safeCloneTextTag(subTitle, {
          color: inverted ? "text-primary-50" : "text-gray-light",
          type: smallScreens("txl", "tlg"),
        })}
      </div>
    </div>
  );
};

export default PageHeaderTitle;
