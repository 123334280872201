import React from "react";
import useGetExercise from "../../../API/queries/exercise/useGetExercise";
import {Icon, Trans, Typography} from "../../../Components";
import {linearGradientDef} from "@nivo/core";
import {ResponsiveLine} from "@nivo/line";
import {scoreToDifficultyIndex} from "../../../utilities/exerciseUtilities/formatScore";

const ExerciseEvaluationCommunityDistribution = ({
  exerciseId,
  currentUserScore,
}) => {
  const [exercise, loading] = useGetExercise(exerciseId);

  let difficultyIndex = scoreToDifficultyIndex(exercise?.score);

  const colorMatching = {
    1: "#12B76A",
    2: "#DC6803",
    3: "#D92D20",
    4: "#6938EF",
  };

  const lineColor = colorMatching[difficultyIndex];

  const statsData = [
    {x: 0, y: 0},
    {x: 1, y: exercise?.stats?.easy},
    {x: 2, y: exercise?.stats?.medium},
    {x: 3, y: exercise?.stats?.hard},
    {x: 4, y: exercise?.stats?.nightmare},
    {x: 5, y: 0},
  ];

  const mockData = [
    {x: 0, y: 0},
    {x: 1, y: 0},
    {x: 2, y: 0},
    {x: 3, y: 0},
    {x: 4, y: 0},
    {x: 5, y: 0},
  ];

  const emptyData = [
    {
      id: "all",
      data: mockData,
    },
  ];

  const data = [
    {
      id: "all",
      data: statsData,
    },
  ];

  const gradient = true;

  return (
    <div className="w-full flex gap-4 justify-between items-end">
      <div className="flex gap-2 items-center whitespace-nowrap w-1/2 mb-8">
        <Trans weight="sb" color="text-gray-light">
          Community votes
        </Trans>
        <Typography className="flex gap-1">
          {exercise?.stats?.total}
          <Icon icon="fa-solid fa-fire-flame-curved" size="xs" />
        </Typography>
      </div>
      <div className="h-36 p-2 w-1/2">
        <ResponsiveLine
          data={!exercise || loading ? emptyData : data}
          margin={{top: 8, right: 8, bottom: 20, left: 8}}
          animate={true}
          enableArea={true}
          layers={["lines", "areas", "points", "markers"]}
          curve="basis"
          enablePoints={false}
          enableGridX={false}
          enableGridY={false}
          enableLabel={false}
          showLegend={false}
          enableCrosshair={true}
          colors={() => lineColor} // green color code> #34D399
          markers={[
            {
              axis: "x",
              value: currentUserScore,
              lineStyle: {stroke: "#D0D5DD", strokeWidth: 2},
            },
          ]}
          // Common props
          defs={[
            linearGradientDef("gradientA", [
              {offset: 0, color: "inherit"},
              {offset: 100, color: "inherit", opacity: 0},
            ]),
          ]}
          fill={[{match: "*", id: gradient ? "gradientA" : null}]}
        />
      </div>
    </div>
  );
};

export default ExerciseEvaluationCommunityDistribution;
