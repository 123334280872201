import React from "react";
import OTP from "Widgets/User/OTP/OTP";
import {Trans} from "Components";

const AuthRequiredActionVerifyDevice = ({
  action,
  transparentInput,
  handleActionCompleted,
  actionOTPProps,
  email,
}) => {
  return (
    <div>
      <OTP
        action={action}
        initialEmail={email}
        title={
          <Trans type="dsm" weight="bold" className="text-gray-900">
            Two-factor authentication
          </Trans>
        }
        subTitle={
          <Trans email={{email}}>
            To continue, please enter the verification code sent to {{email}}
          </Trans>
        }
        autoSubmit={true}
        transparent={transparentInput}
        onVerificationComplete={handleActionCompleted}
        {...actionOTPProps}
      />
    </div>
  );
};

export default AuthRequiredActionVerifyDevice;
