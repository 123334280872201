import React from "react";
import useGetTag from "API/queries/tag/useGetTag";
import PageLoader from "Components/PageLoader";
import CourseLandingTopicPreview from "routes/Course/CourseLanding/CourseLandingDesktop/CourseLandingTopics/CourseLandingTopicPreview/CourseLandingTopicPreview";
import {GET_TAG_EXERCISES} from "API/queries/exercise/useGetTagExercises";
import {CourseContext} from "routes/Course/CourseContextProvider";
import CourseTag from "routes/Course/CourseTag/CourseTag";

export const CourseLandingTopicPreviewContext = React.createContext(null);

const CourseLandingTopicPreviewContextWrapper = ({
  tag: tagData,
  tagIndex,
  toggleOpenModal,
}) => {
  const {id: courseId} = React.useContext(CourseContext);
  const [tag, loading] = useGetTag(tagData?.id, true, true, true);

  const exerciseEvaluationRefetchQueries = [
    {query: GET_TAG_EXERCISES, variables: {courseId, tagId: tagData.id}},
  ];

  if (loading) return <PageLoader />;

  return (
    <CourseTag
      tagId={tag.id}
      allowTabsPreview={false}
      showExerciseViewHeader={false}
      showHeader={false}
    />
  );

  // return (
  //   <CourseLandingTopicPreviewContext.Provider
  //     value={{
  //       tag,
  //       tagData,
  //       headerExercises: tagData?.exercises,
  //       tagIndex,
  //       toggleOpenModal,
  //       exerciseEvaluationRefetchQueries,
  //     }}
  //   >
  //     <CourseLandingTopicPreview />
  //   </CourseLandingTopicPreviewContext.Provider>
  // );
};

export default CourseLandingTopicPreviewContextWrapper;
