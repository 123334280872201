import React from "react";
import {Icon, Link} from "Components";
import useGetExam from "API/queries/exam/useGetExam";

const BreadCrumbExam = ({examId, getExamLink}) => {
  if (!examId) return null;
  const [exam, examLoading] = useGetExam({id: examId});

  if (examLoading) return null;

  const examDate = new Date(exam.date.substring(0, 10));
  const examExtension = exam.date.slice(10).trim();
  const examDateFormatted = examDate.toLocaleDateString("sv-SE", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const examTitle =
    examDateFormatted + (examExtension ? ` (${examExtension})` : "");

  return (
    <div className="flex gap-1">
      <Icon icon="chevron-right" size="md" />
      <Link
        type="txl"
        weight="md"
        className="text-gray-500"
        to={getExamLink(examId)}
      >
        {examTitle}
      </Link>
    </div>
  );
};

export default BreadCrumbExam;
