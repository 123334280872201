import React from "react";
import {Numbers, Section, SubSection, Trans, Typography} from "Components";
import AccountCreditsRedeemCoupon from "./AccountCreditsRedeemCoupon/AccountCreditsRedeemCoupon";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import AccountSettingsSubscriptionCredits from "routes/Account/AccountSettings/AccountSettingsSubscriptionCredits";
import AccountCreditsPersonalCode from "routes/Account/AccountCredits/AccountCreditsPersonalCode";
import AccountCreditsStudentVerification from "routes/Account/AccountCredits/AccountCreditsStudentVerification/AccountCreditsStudentVerification";
import AccountCreditsTransactionsTable from "routes/Account/AccountCredits/AccountCreditsTransactionsTable";

const AccountCredits = props => {
  const [user, loading] = useGetCurrentUser();

  if (loading) return null;

  return (
    <Section
      title={<Trans>Credits</Trans>}
      subTitle={
        <Trans>
          Here you can see your available credits and redeem coupons.
        </Trans>
      }
    >
      <SubSection>
        <Trans>Available credits</Trans>
        <div>
          <Typography type="tlg" weight="md" color="text-gray-light">
            {user.credits ? <Numbers value={user.credits / 100} /> : 0} SEK
          </Typography>
          <Typography inline></Typography>
        </div>
      </SubSection>
      <AccountSettingsSubscriptionCredits />
      <AccountCreditsPersonalCode />
      <AccountCreditsStudentVerification />
      <AccountCreditsRedeemCoupon disabled={!user.student_email} />
      <AccountCreditsTransactionsTable />
    </Section>
  );
};

export default AccountCredits;
