import React from "react";
import AuthLayout from "routes/Auth/AuthLayout";
import ResetPassword from "routes/Auth/ForgotPassword/ResetPassword/ResetPassword";
import {useParams} from "react-router-dom";

const RestPasswordUrlToken = () => {
  const {token} = useParams();

  return (
    <AuthLayout>
      <div className="mt-8">
        <ResetPassword token={token} />
      </div>
    </AuthLayout>
  );
};

export default RestPasswordUrlToken;
