import React from "react";
import Trans from "Components/Trans";

import useGetTestimonialsCollection from "API/cms/useGetTestimonialsCollection";
import {Summer2022CampaignTestimonialsCard} from "routes/Campaign/Summer2022Campaign/Summer2022CampaignTestimonials";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";
const Masonry = React.lazy(() => import("react-responsive-masonry"));

const Testimonials = () => {
  const [testimonialsList, loading] = useGetTestimonialsCollection();
  const smallScreens = useBreakPointSM();

  const testimonials = React.useMemo(() => {
    if (!testimonialsList || loading) return [];
    // filter home page hidden
    let selected = testimonialsList.filter(
      item => item.homepageHidden !== true
    );

    // randomize order
    selected.sort(() => Math.random() - 0.5);

    // take 10
    selected = selected.slice(0, smallScreens(5, 9)); // take  9 because we have three columns

    return selected;
  }, [testimonialsList, loading]);
  return (
    <React.Suspense fallback={<div />}>
      <div className="testimonials pb-8">
        <Trans
          type="dmd"
          weight="sb"
          color="text-primary-700"
          className="mt-3 text-center sm:text-left"
        >
          What our users say
        </Trans>

        <div
          className="mt-4"
          style={{
            maxWidth: 1300,
          }}
        >
          <Masonry columnsCount={smallScreens(1, 3)} gutter="20px">
            {testimonials.map((item, index) => (
              <Summer2022CampaignTestimonialsCard {...item} fluidHeight />
            ))}
          </Masonry>
        </div>
        {/*</Carousel>*/}
      </div>
    </React.Suspense>
  );
};

export default Testimonials;
