import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const sizeMapping = {
  "2xs": 8,
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 18,
  "2xl": 20,
};

const fillPaddingMapping = {
  "2xs": "p-1",
  xs: "p-1",
  sm: "p-2",
  md: "p-2",
  lg: "p-3",
  xl: "p-4",
  "2xl": "p-6",
};

const outlineSizeMapping = {
  "2xs": "border-0",
  xs: "border-1",
  sm: "border-2",
  md: "border-4",
  lg: "border-6",
  xl: "border-8",
  "2xl": "border-8",
};
/**
 * For duo-tone icons, the color is set by the CSS class in general.css
 *
 * @param icon
 * @param spin
 * @param size
 * @param onClick
 * @param color
 * @param className
 * @param filled
 * @param outlined
 * @param fixedWidth
 * @param fillType
 * @param sharp
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const Icon = ({
  icon = "",
  spin = false,
  size = "sm",
  onClick,
  color = "text-gray-400 dark:text-gray-500",
  className,
  filled = false,
  outlined = true,
  fixedWidth = true,
  fillType = "primary",
  sharp = false,
  solid = false,
  disabled = false,
  ...props
}) => {
  // Filled version is with a background color
  // Calculate size of div to match the icon size
  const sizeDiv = React.useMemo(() => sizeMapping[size] * 2, []);

  const iconName = React.useMemo(() => {
    // If the icon includes a prefix, use it as is, otherwise use the default prefix
    try {
      let parts = icon.split(" "); // Split the icon name into parts
      if (parts.length === 0) {
        return "fa-solid fa-face-confused"; // Default icon if nothing is provided to remind the devs.
      }

      if (parts.length > 1) {
        let prefix = parts[0]; // The first part is the prefix

        if (parts[0] === "far") {
          prefix = "fa-regular";
        }

        return `${prefix} ${parts[1]}`; // The second part is the icon name
      }
      return "fa-regular fa-" + parts;
    } catch (e) {}
    return icon;
  }, [icon]);

  const filledIconClassNames = React.useMemo(
    () => getFilledIconClassNames(fillType),
    [fillType]
  );

  const IconComponent = (
    <i
      disabled={disabled}
      className={classnames(
        iconName,
        size != null && size !== "md" && "fa-" + size, // fa-md is the default size and doesn't need to be specified
        className,
        {
          "icon-clickable": onClick,
          "fa-spin": spin,
          "fa-fw": fixedWidth,
          "fa-sharp": sharp,
        }
      )}
      // TODO: Handle Multiple Colors for DuoTone icons
      style={{
        "--fa-secondary-color": "#e386a3",
        "--fa-primary-color": "#586FB5",
        ...props.style,
      }}
    />
  );

  if (!filled) {
    return (
      <span
        onClick={onClick}
        className={color}
        key={iconName + size}
        disabled={disabled}
      >
        {IconComponent}
      </span>
    );
  }

  return (
    <div
      disabled={disabled}
      onClick={onClick}
      key={iconName}
      className={classnames(
        "inline-flex items-center justify-center",
        "rounded-full box-content",
        filledIconClassNames,
        outlined && outlineSizeMapping[size],
        fillPaddingMapping[size],
        `w-[${sizeDiv}px]`,
        `h-[${sizeDiv}px]`,
        "aspect-square"
      )}
    >
      {IconComponent}
    </div>
  );
};

const getFilledIconClassNames = fillType => {
  let type = fillType;

  if (type === "primary")
    return "bg-primary-100 dark:bg-primary-800 text-primary-600 dark:text-primary-300 border-primary-50 dark:border-primary-900";

  if (type === "success")
    return "bg-success-100 dark:bg-success-800  text-success-600 dark:text-success-300 border-success-50 dark:border-success-900";

  if (type === "error" || type === "danger" || type === "destructive")
    return "bg-error-100 dark:bg-error-800 text-error-600 dark:text-error-300 border-error-50 dark:border-error-900";

  if (type === "warning")
    return "bg-warning-50 dark:bg-warning-800 text-warning-400 dark:text-warning-300 border-warning-50 dark:border-warning-900";

  if (type === "warning-dark") {
    return "bg-warning-100 dark:bg-warning-800 text-warning-600 dark:text-warning-300 border-warning-50 dark:border-warning-900";
  }

  if (type === "amber") {
    return "bg-amber-100 dark:bg-amber-800 text-amber-600 dark:text-amber-300 border-amber-50 dark:border-amber-900";
  }

  if (type === "purple")
    return "bg-purple-100 dark:bg-purple-800 text-purple-600 dark:text-purple-300 border-purple-50 dark:border-purple-900";

  if (type === "purple-dark") {
    return "bg-purple-200 dark:bg-purple-800 text-purple-600 dark:text-purple-300 border-purple-100 dark:border-purple-900";
  }

  if (type === "gray") {
    return "bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 border-gray-50 dark:border-gray-900";
  }

  return "bg-primary-100 dark:bg-primary-800 text-primary-400 border-primary-50 dark:border-primary-900";
};

Icon.propTypes = {
  /**
   * The icon to display, use hyphen-case for fontawesome icons.
   * For non-solid icons, use the full name of the icon, e.g. "far fa-copy"
   */
  icon: PropTypes.string,
  /**
   * If true, the icon will spin
   */
  spin: PropTypes.bool,
  /**
   * The size in pixels or a textsize (see typography)
   */
  size: PropTypes.oneOf(["2xs", "xs", "sm", "md", "lg", "xl", "2xl"]),
  /**
   * The color of the icon, use text-* colors
   */
  color: PropTypes.string,
  /**
   * The onClick handler
   */
  onClick: PropTypes.func,

  filled: PropTypes.bool,
  outlined: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  fillType: PropTypes.oneOf([
    "primary",
    "success",
    "error",
    "destructive",
    "warning",
    "purple",
    "gray",
    "warning-dark",
    "amber",
  ]),
};

export default Icon;
