import React from "react";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import useCreateStripeIntentFormResponseState from "Widgets/Payment/Stripe/CreateStripeIntent/CreateStripeIntentForm/useCreateStripeIntentFormResponseState";

const useCreateStripeIntentForm = ({onComplete}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const {
    message,
    setClientSecret,
    setMessage,
  } = useCreateStripeIntentFormResponseState({
    stripe,
    onComplete,
    setErrorMessage,
  });

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    setErrorMessage(null);
    setMessage(null);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const {error, setupIntent} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.log(error);
      setErrorMessage(error.message);
    } else {
      setClientSecret(setupIntent.client_secret);
    }

    setLoading(false);
  };

  return {
    loading,
    errorMessage,
    handleSubmit,
    message,
  };
};

export default useCreateStripeIntentForm;
