import React from "react";
import { Avatar, Trans } from "Components";
import "./StudyPlanCardAuthor.scss";

const StudyPlanCardAuthor = ({ author }) => {
  return (
    <div className="study-plan-card-author">
      <div className="study-plan-card-author-info">
        <Avatar user={author} size="xl" />
        <span className="study-plan-card-author-name">
          <Trans>By</Trans>
          {` ${author.first_name} ${author.last_name}`}
        </span>
      </div>
    </div>
  );
};

export default StudyPlanCardAuthor;
