import React from "react";
import {Badge, Icon, Tooltip, Trans, Typography} from "Components";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {statusBadgeTypes} from "routes/Account/AccountSubscription/AccountSubscriptionInvoices/useAccountSubscriptionInvoicesData";
import AccountOrdersProduct from "routes/Account/AccountOrders/AccountOrdersProduct";
import CDN from "utilities/constants/CDN";

const useAccountOrdersTableColumns = () => {
  const {t: trans} = useTranslation();
  return [
    {
      title: <Trans>Product</Trans>,
      dataIndex: "productId",
      render: (productId, row) => <AccountOrdersProduct {...row} />,
      truncate: true,
      colSpan: 4,
    },
    {
      title: <Trans>Purchase date</Trans>,
      dataIndex: "createdAt",
      key: "createdAt",
      render: text => (
        <Typography>{moment(text).format("DD MMMM YYYY")}</Typography>
      ),
      truncate: false,
    },
    {
      title: <Trans>Access</Trans>,
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: renderAccess,
    },
    {
      title: <Trans>Price</Trans>,
      dataIndex: "price",
      key: "price",
      render: (price, row) => (
        <Typography>{`${price /
          100} ${row?.currency.toUpperCase()}`}</Typography>
      ),
    },
    {
      title: <Trans>Discount</Trans>,
      dataIndex: "discount",
      key: "discount",
      render: (discount, row) =>
        discount > 0 ? (
          <Typography>{`-${discount /
            100} ${row?.currency.toUpperCase()}`}</Typography>
        ) : null,
    },
    {
      title: <Trans>Status</Trans>,
      dataIndex: "status",
      key: "status",
      render: status => (
        <Badge type={statusBadgeTypes[status]}>{trans(status)}</Badge>
      ),
    },
    {
      title: <Trans>Payment method</Trans>,
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: renderPaymentMethod,
    },
  ];
};

const renderPaymentMethod = paymentMethod => {
  switch (paymentMethod) {
    case "STRIPE":
      return (
        <Tooltip content={<Trans>Card payment</Trans>}>
          <Icon
            icon="fa-regular fa-credit-card"
            size="md"
            color="text-gray-light"
          />
        </Tooltip>
      );
    case "CREDITS":
      return (
        <Tooltip content={<Trans>Kollin credits</Trans>}>
          <Icon icon="fa-regular fa-coins" size="md" color="text-gray-light" />
        </Tooltip>
      );
    case "SWISH":
      return <img src={CDN + `/icons/swish.svg`} alt={name} className="w-14" />;
    default:
      return null;
  }
};

const renderAccess = (expiryDate, status) => {
  const expiryDateFormatted = moment(expiryDate).format("YYYY-MM-DD");
  if (moment(expiryDate).isAfter("2100-01-01")) {
    return (
      <Badge type="success" size="xs" icon="infinity">
        <Trans>Lifetime</Trans>
      </Badge>
    );
  }

  if (moment().isAfter(expiryDate)) {
    return (
      <Badge icon="lock" type="darkGray" size="xs">
        <Trans expiryDateFormatted={expiryDateFormatted}>Expired</Trans>
      </Badge>
    );
  }

  return (
    <Badge icon="check-circle" type="primary" size="xs">
      <Trans expiryDateFormatted={expiryDateFormatted}>1 year</Trans>
    </Badge>
  );
};

export default useAccountOrdersTableColumns;
