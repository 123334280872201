import React from "react";

import {ErrorBoundary} from "Components";
import LandingPageSection from "Components/LandingPageSection/LandingPageSection";

const Summer2022CampaignFeatures = ({landingPageSections}) => {
  let firstSection = React.useMemo(
    () => landingPageSections.find(section => section.order === 2),
    []
  );

  let secondSection = React.useMemo(
    () => landingPageSections.find(section => section.order === 3),
    []
  );

  return (
    <div className=" px-3 pb-5">
      <ErrorBoundary>
        <LandingPageSection sectionId={firstSection.sys.id} />
      </ErrorBoundary>
      <ErrorBoundary>
        <LandingPageSection sectionId={secondSection.sys.id} />
      </ErrorBoundary>
    </div>
  );
};

const Summer2022CampaignFeaturesSection = ({
  title,
  description,
  topIcon,
  benefits,
  illustration,
}) => {
  return <div>{/*rounded icon with border*/}</div>;
};

export default Summer2022CampaignFeatures;
