import React from "react";
import useGetCourseExercises from "../../../API/queries/exercise/useGetCourseExercises";

const useCourseTopicsList = () => {
  const {courseId} = React.useContext(window.CourseContext);
  const [exercises, exercisesLoading] = useGetCourseExercises({
    courseId,
    includeTags: true,
    includeSelfEvaluation: true,
    includeStats: true,
  });

  const latestExercisesCompletedByTag = React.useMemo(() => {
    if (exercisesLoading) {
      return [];
    }
    // filter exercises by self_evaluation not empty and sort by date of self evaluation descending
    const latestExercisesDone = exercises
      .filter(exercise => exercise.selfEvaluation !== null)
      .sort((a, b) => {
        return (
          new Date(b.self_evaluation.date) - new Date(a.self_evaluation.date)
        );
      })
      .map(exercise => {
        return exercise.tags.map(tag => tag.id);
      });
    return Array.from(new Set(latestExercisesDone.flat()));
  }, [exercises, exercisesLoading]);

  // get tags ordered by how many self evaluations they have
  const tagsOrderedByNExercises = React.useMemo(() => {
    if (exercisesLoading) {
      return [];
    }
    // get exercises, group by tag, count exercises in each group
    const tagCounts = exercises.reduce((acc, exercise) => {
      exercise.tags.forEach(tag => {
        acc[tag.id] = acc[tag.id] ? acc[tag.id] + 1 : 1;
      });
      return acc;
    }, {});
    // sort tags by count descending
    const sortedTags = Object.keys(tagCounts).sort(
      (a, b) => tagCounts[b] - tagCounts[a]
    );

    return sortedTags;
  }, [exercises, exercisesLoading]);

  const sortedTopics = size =>
    React.useMemo(() => {
      if (exercisesLoading) {
        return [];
      }

      const topPersonalTags = latestExercisesCompletedByTag.slice(0, size);
      if (topPersonalTags.length < size) {
        const topCourseTags = tagsOrderedByNExercises.filter(
          tag => !topPersonalTags.includes(tag)
        );
        return topPersonalTags.concat(
          topCourseTags.slice(0, size - topPersonalTags.length)
        );
      }
      return topPersonalTags;
    }, [
      exercisesLoading,
      latestExercisesCompletedByTag,
      tagsOrderedByNExercises,
    ]);

  return {sortedTopics, exercisesLoading};
};

export default useCourseTopicsList;
