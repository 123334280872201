import React from "react";
import {Button} from "Components";
import {Trans} from "Components/index";
import UseAccountCreditsStudentVerificationState from "routes/Account/AccountCredits/AccountCreditsStudentVerification/useAccountCreditsStudentVerificationState";
import {useHistory} from "react-router-dom";
import StudentVerification from "Widgets/User/StudentVerification/StudentVerification";

const SubscriptionTrialBannerActions = ({studentEmailVerified, loggedIn}) => {
  const history = useHistory();

  const {
    emailSent,
    values: {email},
    handleChange,
    submit,
    errors,
    loading,
  } = UseAccountCreditsStudentVerificationState();

  const onComplete = () => {
    history.push("/trial");
  };

  if (!loggedIn)
    return (
      <div className="flex gap-2 flex-wrap items-start relative z-10 max-w-sm">
        <Button
          type="secondary"
          onClick={() => history.push("/login")}
          className="grow"
        >
          <Trans>Login</Trans>
        </Button>
        <Button
          type="primary"
          onClick={() => history.push("/signup")}
          className="grow"
        >
          <Trans>Signup now</Trans>
        </Button>
      </div>
    );

  if (studentEmailVerified)
    return (
      <Button type="secondary" onClick={onComplete}>
        <Trans>Start my free trial now</Trans>
      </Button>
    );

  return (
    <StudentVerification
      title={null}
      showModal={true}
      onComplete={onComplete}
      inputHint={true}
      inlineForm={true}
      transparent={false}
    />
  );
};

export default SubscriptionTrialBannerActions;
