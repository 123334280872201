import React from "react";
import useGroupHomeLatestQuestions from "Widgets/Group/GroupHomeLatestQuestions/useGroupHomeLatestQuestions";
import {Comment, Trans} from "Components";
import Reactions from "Components/Reactions/Reactions";
import {userContext} from "UserContextProvider";

const GroupHomeLatestQuestions = () => {
  const {user} = userContext();
  const {threads, loading} = useGroupHomeLatestQuestions();

  if (loading || threads.length === 0) return null;

  return (
    <div>
      <Trans type="tlg" weight="sb">
        Latest discussions in the group
      </Trans>
      <div className="mt-4" />

      {threads.map(thread => {
        const root = thread.comments;

        return (
          <div key={thread.id}>
            {/*<div>{root.html}</div>*/}
            {/*<div>{root.asker.name}</div>*/}
            <Comment
              className="mb-4 surface border-transparent"
              repliesClassNames="px-3 border-l-2 border-primary border-t-0"
              createdAt={thread.created_at}
              content={root.html}
              author={root.asker}
              extra={<Reactions parentId={root.id} parentType="Comment" />}
              showExerciseInfo={true}
              exerciseId={thread.exercise?.id}
              showCourseTag={true}
              exerciseData={thread.exercise}
              replies={root.comments?.map(comment => ({
                content: comment?.html,
                author: comment?.asker,
                createdAt: comment?.created_at,
                extra: (
                  <Reactions parentId={comment?.id} parentType="Comment" />
                ),
              }))}
              // replies={[]}
              expandable={true}
              currentUser={user}
            />
          </div>
        );
      })}
    </div>
  );
};

export default GroupHomeLatestQuestions;
