import useGetCurrentUserPaymentMethods from "API/queries/paymentMethods/useGetCurrentUserPaymentMethods";
import {CreditCard, Icon, Trans} from "Components";
import {AnimateSharedLayout} from "framer-motion";
import React from "react";
import AccountSubscriptionPaymentMethodsCreate from "./AccountSubscriptionPaymentMethodsCreate/AccountSubscriptionPaymentMethodsCreate";

import AccountSubscriptionPaymentMethodsCardAction from "./AccountSubscriptionPaymentMethodsCardAction";

const AccountSubscriptionPaymentMethods = () => {
  // Get the user's payment methods and display them

  const [paymentMethods, loading] = useGetCurrentUserPaymentMethods();

  if (paymentMethods.length === 0) {
    return <AccountSubscriptionPaymentMethodsCreate />;
  }

  return (
    <div className="w-full">
      <AnimateSharedLayout layoutId="payment-methods-list">
        {paymentMethods.sort(sorter).map(paymentMethod => {
          return (
            <CreditCard
              key={paymentMethod.id}
              layoutId={`payment-method-${paymentMethod.id}`}
              selected={paymentMethod.isDefaultPaymentMethod}
              {...paymentMethod.card}
              actions={
                <AccountSubscriptionPaymentMethodsCardAction
                  paymentMethod={paymentMethod}
                />
              }
            />
          );
        })}
      </AnimateSharedLayout>

      <AccountSubscriptionPaymentMethodsCreate alert={false} />
    </div>
  );
};

const sorter = (a, b) =>
  a.isDefaultPaymentMethod === b.isDefaultPaymentMethod
    ? 0
    : a.isDefaultPaymentMethod
    ? -1
    : 1;

export default AccountSubscriptionPaymentMethods;
