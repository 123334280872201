import React from "react";
import { SchoolPicker, Typography } from "Components";
import SCHOOL_LOGOS from "utilities/constants/SCHOOL_LOGOS";

import { motion } from "framer-motion";
import { Summer2022CampaignOnBoardingEnrollmentInfoContainer } from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignOnBoarding/Summer2022CampaignOnBoardingEnrollment/Summer2022CampaignOnBoardingEnrollment";

const Summer2022CampaignOnBoardingEnrollmentSchool = ({
  state,
  setState,
  school,
}) => {
  const { schoolId } = state;

  if (schoolId == null || school?.id !== schoolId) {
    return (
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
      >
        <SchoolPicker
          onSelect={schoolId => setState({ schoolId })}
          selectedSchoolId={schoolId}
          placeholder="Välj skola"
        />
      </motion.div>
    );
  }

  return (
    <Summer2022CampaignOnBoardingEnrollmentInfoContainer
      label="Skola"
      onClick={() => setState({ schoolId: null, programId: null })}
    >
      <img src={SCHOOL_LOGOS[school?.name]} className="w-8" />
      <Typography type="tsm" weight="sb" color="text-primary-800">
        {school?.name}
      </Typography>
    </Summer2022CampaignOnBoardingEnrollmentInfoContainer>
  );
};

export default Summer2022CampaignOnBoardingEnrollmentSchool;
