import React from "react";
import RESOURCES_URL from "utilities/constants/RESOURCES_URL";
import {documentFindDates, documentGetType} from "API/lambdas";

const useExamUploadResourcesState = ({
  examsByDate,
  setFilesAdded,
  failedFiles,
}) => {
  const existingResources = examsToResources(examsByDate);
  const [resources, setResources] = React.useState(existingResources);

  React.useEffect(() => {
    if (resources.length == 0) {
      const existingResources = examsToResources(examsByDate);

      if (existingResources.length > 0) {
        setResources(existingResources);
      }
    }
  }, [examsByDate]);

  /**
   * // Add resource to documents in the correct pair-set (exam, solutions)
   * @param resource
   * @returns {Promise<void>}
   */
  const addResource = async resource => {
    const docUrl = RESOURCES_URL + resource.url;

    try {
      // 1. Get the suggested dates of the document
      const dates = (await documentFindDates(docUrl)) || [];

      //  2. Get the document type (exam, solutions)
      const [type, _] = await documentGetType(docUrl);

      // 3. Check if it already exists in the exam list

      const saved = false;

      const rejected = dates?.length === 0;

      let author = null;

      const doc = {
        ...resource,
        dates,
        type,
        author,
        saved,
        url: docUrl,
        hidden: false,
        isNew: true, // All added resources are new
        rejected,
      };

      setResources(resources => [...resources, doc]);
    } catch (e) {
      console.error(e);
      failedFiles.push(resource);
    }
  };

  const updateResource = updatedResource => {
    setResources(resources =>
      resources.map(resource => {
        if (resource.id === updatedResource.id) {
          return updatedResource;
        }
        return resource;
      })
    );
  };

  const visibleResources = React.useMemo(
    () => resources.filter(r => !r.hidden),
    [resources]
  );

  return {
    visibleResources,
    resources,
    addResource,
    updateResource,
  };
};

const examsToResources = examsByDate => {
  const resources = [];

  for (const date in examsByDate) {
    const exam = examsByDate[date];
    const {exam_exercises_src: exercises, exam_solutions_src: solutions} = exam;

    if (exercises === solutions) {
      resources.push({
        id: exam.id + "-mixed",
        type: "mixed",
        author: exam.author || null,
        name: getNameFromUrl(exercises, "Exercises.pdf"),
        url: exercises,
        dates: [date],
        hidden: true,
        isNew: false,
        saved: true,
      });
      continue;
    }

    if (exercises !== null) {
      resources.push({
        id: exam.id + "-exam",
        type: "exam",
        author: exam.author || null,
        name: getNameFromUrl(exercises, "Exercises.pdf"),
        url: exercises,
        dates: [date],
        hidden: true,
        isNew: false,
        saved: true,
      });
    }

    if (solutions !== null) {
      resources.push({
        id: exam.id + "-solutions",
        type: "exam_solutions",
        name: getNameFromUrl(solutions, "Solutions.pdf"),
        url: solutions,
        dates: [date],
        hidden: true,
        isNew: false,
        saved: true,
      });
    }
  }

  return resources;
};

const getNameFromUrl = (url, fallback) => {
  //" https://kollin-exams.s3.eu-north-1.amazonaws.com/resources/9da50d0e7132f435930249a761731fa7-SF1625Tentamen20201015.pdf"
  let name;

  if (url === null) return fallback;

  try {
    name = url
      .split("/")
      .pop()
      .split("-")
      .pop();

    return name;
  } catch (e) {
    // console.log(e, url);
    return fallback;
  }
};

export default useExamUploadResourcesState;
