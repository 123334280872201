import React from "react";
import {Avatar, Badge, Link} from "Components/_Elements";
import {maybeString} from "utilities/text";
import {useLocalRelativeTime} from "utilities/relativeTime";

const CommentAuthor = ({
  author,
  createdAt,
  size = "md",
  showRole = true,
  showCourseTag = false,
  exerciseData = null,
}) => {
  const relativeTimeStamp = useLocalRelativeTime(createdAt);

  if (!author) return null;

  return (
    <div className="flex justify-between">
      <Avatar
        size={size}
        user={author}
        premium={author.premium}
        showRole={showRole}
        name={
          maybeString(author.firstName) + " " + maybeString(author.lastName)
        }
        description={relativeTimeStamp}
        showPrograInfo={true}
      />
      {exerciseData && showCourseTag && (
        <div className="flex items-center gap-2">
          <Link to={`/course/${exerciseData.source.course.id}`}>
            <Badge size="md">{exerciseData.source.course.code}</Badge>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CommentAuthor;
