import { useSubscription } from "@apollo/react-hooks";
import gql from "graphql-tag";

const EMAIL_VERIFICATION_STATUS = gql`
  subscription {
    emailVerificationStatus {
      ok
      error
    }
  }
`;

const useEmailVerificationStatusSubscription = () => {
  let status = null;

  const { data, loading, error } = useSubscription(
    EMAIL_VERIFICATION_STATUS,
    {}
  );

  if (error) {
    console.log("Error in useEmailVerificationStatusSubscription: ", error);
  }

  if (data && data.emailVerificationStatus) {
    status = data.emailVerificationStatus;
  }

  return [status, loading];
};

export default useEmailVerificationStatusSubscription;
