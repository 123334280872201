import React from "react";
import {Avatar, Icon} from "Components";

const CourseChatGptResultsMessageAvatar = ({
  showAvatar = true,
  role,
  sender,
  avatarSize = "sm",
}) => {
  if (!showAvatar) {
    return null;
  }

  if (role === "assistant") {
    return (
      <Icon
        icon="fa-kit fa-kollin"
        size={avatarSize}
        showText={false}
        filled
        fillType="purple-dark"
        outlined={false}
      />
    );
  }

  return <Avatar user={sender} size={avatarSize} />;
};

export default CourseChatGptResultsMessageAvatar;
