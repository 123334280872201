import {WebSocketLink} from "@apollo/client/link/ws";
import {WSUri} from "client/constants";
import {getAuthToken, getRefreshToken} from "utilities/authentication";

const wsLink = new WebSocketLink({
  uri: WSUri,
  options: {
    lazy: true,
    reconnect: true,
    timeout: 10000,
    connectionParams: {
      "x-token": getAuthToken(),
      "x-refresh-token": getRefreshToken(),
    },
  },
});

export default wsLink;
