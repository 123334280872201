import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Trans from "../../Trans";
import {getTextFromElement} from "../HighlightedText/HighlightedText";

export const resizeOptions = ["none", "horizontal", "vertical", "both"];

const TextArea = ({
  placeholder,
  name,
  disabled,
  text,
  resize,
  onChange,
  onFocus,
  onBlur,
  expandOnFocus = false,
  rows,
  cols,
  error,
  autoFocus,
  className,
  blurredHeight = "h-12",
  borderColor = "border-gray",
  backgroundColor = "background",
  expandedHeight = "h-auto",
  padding = "p-2",
}) => {
  const [focused, setFocused] = React.useState(false);

  const handleFocus = e => {
    if (disabled) return;

    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = e => {
    if (disabled) return;
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const expanded = focused || text?.length > 0;

  return (
    <>
      <textarea
        autoFocus={autoFocus}
        onFocus={handleFocus}
        onBlur={handleBlur}
        name={name}
        onChange={onChange}
        value={text}
        rows={rows}
        cols={cols}
        style={{
          resize,
        }}
        className={classnames(
          "rounded-md w-full border-2",
          padding,
          backgroundColor,
          borderColor,
          expandOnFocus && (expanded ? expandedHeight : blurredHeight), // If expandOnFocus is true, then expand the textarea when focused
          className,
          disabled && "cursor-not-allowed pointer-events-none"
        )}
        placeholder={getTextFromElement(placeholder)}
      ></textarea>
      {error && <p className="help is-danger">{error}</p>}
    </>
  );
};

TextArea.defaultProps = {
  outlined: false,
  greyBackground: false,
  resize: "none",
  placeholder: <Trans>Write here</Trans>,
  rows: 4,
  cols: 50,
  border: "visible",
  autoFocus: false,
};

TextArea.propTypes = {
  outlined: PropTypes.bool,
  placeholder: PropTypes.element,
  resize: PropTypes.oneOf(resizeOptions),
  border: PropTypes.string,
  greyBackground: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  autoFocus: PropTypes.bool,
};

export default TextArea;
