import React from "react";
import ExerciseViewExerciseDisplay from "Components/Workboard/ExerciseView/ExerciseViewExerciseDisplay";

const CommentExerciseInfo = ({showExerciseInfo, exerciseId}) => {
  if (!showExerciseInfo) return null;

  return (
    //  Ensure it's as wide as the comment box, ignoring the padding
    <div className="my-4 py-2 px-4 -mx-4 relative shadow-inner">
      <ExerciseViewExerciseDisplay exerciseId={exerciseId} imageSize="sm" />
      {/*    Overlay */}
      <div className="absolute inset-0 bg-gray-400 bg-opacity-10" />
    </div>
  );
};

export default CommentExerciseInfo;
