import React from "react";
import classnames from "classnames";
import {motion} from "framer-motion";

const OnboardingOptionCard = ({
  onClick,
  children,
  selected,
  className,
  compact = false,
  layoutId,
}) => {
  return (
    <div
      className={classnames(
        "relative flex justify-start gap-2 items-center w-full border-2 border-gray rounded-lg cursor-pointer hyphens-auto overflow-visible",
        compact ? "p-3" : "p-4",
        className
      )}
      onClick={onClick}
    >
      {children}
      {selected && (
        <motion.div
          className="absolute z-10 inset-0 w-full h-full outline outline-4  outline-primary rounded-lg overflow-visible"
          layoutId={layoutId + "-outline"}
        />
      )}
    </div>
  );
};

export default OnboardingOptionCard;
