export const sizeClasses = {
  sm: "p-2 gap-4",
  md: "p-4 gap-6",
  lg: "p-6 gap-8",
};

export const orientationClasses = {
  horizontal: "flex-row",
  vertical: "flex-col",
};

export const textSizes = {
  sm: "tsm",
  md: "tmd",
  lg: "tlg",
};

export const titleTextSize = (size, onlyTitle) => {
  if (!onlyTitle) return textSizes[size]; // same as text size
  // If only title, the text size is bigger
  return {
    sm: "tmd",
    md: "tlg",
    lg: "txl",
  }[size];
};

export const circleSizeClasses = {
  sm: "w-6 h-6",
  md: "w-8 h-8",
  lg: "w-12 h-12",
};

export const circleBorderWidth = {
  sm: "ring-2",
  md: "ring-[2.5px]",
  lg: "ring-[3px]",
};

export const circleTextSize = {
  sm: "tsm",
  md: "tlg",
  lg: "dxs",
};
