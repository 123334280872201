import React from "react";
import useGetCreditsBundles from "API/queries/creditsBundle/useGetCreditsBundles";
import useCheckoutOrderHandler from "routes/Checkout/CheckoutPayment/useCheckoutOrderHandler";

const useBuyCredits = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
    "swish"
  );

  const [allCreditBundles, loading] = useGetCreditsBundles();
  const creditsBundles = allCreditBundles?.sort((a, b) => a.amount - b.amount);

  const [selectedBundle, setSelectedBundle] = React.useState(null);

  const {
    createOrder: action,
    cancelOrder,
    order,
    loading: actionLoading,
  } = useCheckoutOrderHandler({
    discount: null,
    transformInputProducts: false,
    products: [
      {
        productId: selectedBundle?.id,
        productType: "CREDITS_BUNDLE",
        productPriceId: selectedBundle?.priceId,
      },
    ],
  });

  const handleBuyCreditsClick = (bundleId, priceId) => {
    setSelectedBundle({id: bundleId, priceId});
  };

  const handleCancelClick = () => {
    setSelectedBundle(null);
    cancelOrder();
  };

  const resetSelectedBundle = () => {
    setSelectedBundle(null);
  };

  React.useEffect(() => {
    if (
      selectedBundle &&
      selectedPaymentMethod === "stripe" &&
      !actionLoading
    ) {
      // Create the order immediately
      action({
        stripeSuccessUrl: `${window.location.href}&status=success`,
        stripeCancelUrl: `${window.location.href}`,
        paymentMethod: "STRIPE",
      });
    }
  }, [selectedBundle, selectedPaymentMethod]);

  const handleTabChange = key => {
    setSelectedBundle(null);
    setSelectedPaymentMethod(key);
  };

  return {
    selectedBundle,
    resetSelectedBundle,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    handleTabChange,
    creditsBundles,
    handleBuyCreditsClick,
    handleCancelClick,
    loading,
    action,
    actionLoading,
    order,
  };
};

export default useBuyCredits;
