import React from "react";
import { Button, ButtonGroup, Trans } from "Components";

const CourseExamViewSolutionsToggle = ({ showSolution, setShowSolution }) => {
  return (
    <ButtonGroup>
      <Button
        onClick={setShowSolution.bind(null, false)}
        type={showSolution ? "gray" : "primary"}
      >
        <Trans>Exercises</Trans>
      </Button>
      <Button
        onClick={setShowSolution.bind(null, true)}
        type={showSolution ? "primary" : "gray"}
      >
        <Trans>Solutions</Trans>
      </Button>
    </ButtonGroup>
  );
};

export default CourseExamViewSolutionsToggle;
