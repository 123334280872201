import React from "react";
import {Trans, Typography} from "Components/index";
import PropTypes from "prop-types";

const SafeText = ({
  children = null,
  fallback = "",
  Tag = "Typography",
  ...props
}) => {
  if (!children) return fallback;

  // If the input is a string, return a Tag with the input as the children
  if (typeof children === "string" || typeof children === "number") {
    if (Tag === "Typography") {
      return <Typography {...props}>{children}</Typography>;
    }
    if (Tag === "Trans") {
      return <Trans {...props}>{children}</Trans>;
    }
    // Fall back to just the string

    return children;
  }

  if (React.isValidElement(children)) {
    // If the children is already a React element, return it with the props applied
    return React.cloneElement(children, props);
  }

  return null;
};

SafeText.prototype = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fallback: PropTypes.string,
  Tag: PropTypes.oneOf(["Typography", "Trans"]),
};

SafeText.Typography = props => <SafeText Tag="Typography" {...props} />;
SafeText.Trans = props => <SafeText Tag="Trans" {...props} />;

export default SafeText;
