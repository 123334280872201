import React from "react";
import PropTypes from "prop-types";
import {Icon} from "Components";
import {AnimatePresence, motion} from "framer-motion";
import {wrap} from "popmotion";
import classnames from "classnames";

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
      y: 0,
      zIndex: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    y: 0,
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
      y: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 20000;

const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Carousel = ({children, loop = false, showDots, height, key}) => {
  const totalChildren = React.useMemo(() => React.Children.count(children), [
    children,
  ]);

  const [[page, direction], setPage] = React.useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, totalChildren, page);

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection]);
  };

  // Map through children and return each child
  return (
    <div
      className={classnames(
        "w-full relative flex flex-col justify-end gap-2",
        height
      )}
      key={key}
    >
      <AnimatePresence initial={false} custom={direction}>
        {/* Add a hidden element just to get the height to be correct */}
        <div className="opacity-0 w-full">{children[imageIndex]}</div>
        <motion.div
          className="w-full absolute top-0"
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: {type: "spring", stiffness: 300, damping: 30},
            opacity: {duration: 0.4},
          }}
          drag="x"
          dragConstraints={{left: 0, right: 0}}
          dragElastic={1}
          onDragEnd={(e, {offset, velocity}) => {
            const swipe = swipePower(offset.x, velocity.x);
            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        >
          {children[imageIndex]}
        </motion.div>
      </AnimatePresence>

      {showDots && (
        <div className="flex justify-center items-center my-2">
          {Array.from({length: totalChildren}).map((_, i) => {
            return (
              <Icon
                key={i}
                icon="fa-solid fa-circle-small"
                className="mr-1"
                size="xs"
                onClick={() => setPage([i, 0])}
                color={
                  i === imageIndex ? "text-primary-light" : "text-gray-200"
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  loop: PropTypes.bool,
  showArrows: PropTypes.bool,
  showDots: PropTypes.bool,
  height: PropTypes.string,
  key: PropTypes.string,
};

export default Carousel;
