import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";

const QUERY = gql`
  query($id: String!, $locale: String!) {
    homepage(id: $id, locale: $locale) {
      title
      subtitle
      heroImage {
        title
        url
      }
      tutorialSlidesCollection {
        items {
          ...tutorialSlideFields
        }
      }
      schoolCarouselTitle
      tutorialTitle
      testimonialsTitle
    }
  }

  fragment tutorialSlideFields on TutorialCarousel {
    title
    body
    image(locale: "en") {
      # The image is added onl for english locale
      title
      url
    }
  }
`;

const useGetHomepageContent = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language.split("-")[0] == "sv" ? "sv" : "en";
  let result = {};
  window.content = { ...window.content, homepage: {} };

  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      id: "13uCrWnGnlgsWrO9m2tkyb",
      locale,
    },
    context: {
      api: "contentful",
    },
  });

  if (error) {
    console.log("Error in useGetHomepageContent", error);
    window.content.homepage = require("./fallbackData/getHomePageContentFallback.json");
  }

  if (data && data.homepage) {
    result = data.homepage;
    window.content.homepage = data.homepage;
  } else {
    // Fallback if the data is not returned. We store a JSON snapshot so at least we can show something
    window.content.homepage = require("./fallbackData/getHomePageContentFallback.json");
  }

  return [result, loading];
};

export default useGetHomepageContent;
