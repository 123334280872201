import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_GROUP = gql`
  query groupMembers($id: ID!) {
    group(id: $id) {
      id
      program {
        id
        name
      }
      subGroups {
        id
        name
        members {
          admin
          verified
          id
          createdAt
          user {
            id
            firstName
            lastName
            avatar
            enrollmentYear
            emailHash
            studentEmailHash
            program {
              id
              name
            }
          }
        }
      }
      members {
        id
        admin
        verified
        createdAt
        user {
          id
          firstName
          lastName
          avatar
          enrollmentYear
          emailHash
          studentEmailHash
        }
      }
    }
  }
`;
const useGetGroupMembers = ({id}) => {
  let members = [];
  let groupDetails = {showSubGroup: false, subGroupType: "Inriktning"};

  const {data, loading, error} = useQuery(GET_GROUP, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    variables: {
      id,
      includeSubGroups: true,
    },
  });

  if (data) {
    members = data.group?.members || [];
    if (data.group?.subGroups?.length > 0) {
      groupDetails["showSubGroup"] = true;
      data.group.subGroups.forEach(subGroup => {
        // add subGroup members to members array and add subGroup name to each member
        subGroup.members.forEach(member => {
          member.subGroupName = subGroup.name;
        });

        members = members.concat(subGroup.members);
      });
      if (!data.group?.program?.id) {
        groupDetails["subGroupType"] = "Program";
      }
    }
  }

  return [members, groupDetails, loading];
};

export default useGetGroupMembers;
