import React from "react";
import CourseChatGPTResults from "Widgets/Course/CourseChatGPT/CourseChatGPTResults";
import {Icon, Math, Typography} from "Components";
import moment from "moment";
import {capitalizeFirstLetter} from "utilities/text";
import classnames from "classnames";

const CourseKollinGptSavedContent = ({title, body, type, createdAt}) => {
  const lines = body.split("\n");

  const linesToDisplay = lines.slice(0, 4);

  return (
    <div className="w-full text-ellipsis overflow-hidden">
      <div className="flex flex-col md:flex-row justify-between gap-2">
        <Typography type="tmd md:tlg" weight="md" color="text-primary">
          {title}
        </Typography>

        <Typography type="tsm md:tmd" weight="md">
          {capitalizeFirstLetter(moment(createdAt).format("LLLL"))}
        </Typography>
      </div>
      <div className="mt-2">
        {linesToDisplay.map((line, i) => (
          <Typography
            className={classnames(
              "mr-1",
              i % 2 === 1 ? "bg-primary-25" : "bg-transparent"
            )}
            inline
            type="tsm md:tmd"
            weight={i % 2 === 1 ? "md" : "normal"}
            color={i % 2 === 1 ? "text-primary" : "text-gray"}
          >
            <Math inline>{line}</Math>
          </Typography>
        ))}
        {lines.length > 3 && <span className="text-gray-500 inline">...</span>}
      </div>
    </div>
  );
};

export default CourseKollinGptSavedContent;
