import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const topVariants = {
  1: "M-23.14,140.33 C155.19,50.83 338.60,161.35 502.25,-3.45 L500.00,150.00 L-3.38,160.37 Z",
  2: "M-17.49,48.85 C150.00,150.00 353.27,106.09 502.25,0.51 L500.00,150.00 L0.00,150.00 Z",
  3: "M-14.67,-12.32 C203.16,157.41 353.27,106.09 502.25,0.51 L500.00,150.00 L0.00,150.00 Z",
};

const bottomVariants = {
  1: "M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z",
  2: "M-17.49,149.51 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z",
};

/**
 * Creates a box with a wave border.
 * Use the following link for generating different paths:
 * https://smooth.ie/blogs/news/svg-wavey-transitions-between-sections
 *
 */
const WaveBorderBox = ({
  className,
  children,
  bgColor = "bg-primary-700",
  fillColor = "fill-primary-700",
  topPath,
  bottomPath,
  topVariant = 1,
  bottomVariant = 1,
  containerClassName,
}) => {
  return (
    <div className={containerClassName}>
      {topVariant && (
        <div className="h-20 overflow-hidden">
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            className="h-full w-full"
          >
            <path
              d={topPath || topVariants[topVariant]}
              className={fillColor}
            />
          </svg>
        </div>
      )}
      <div className={classnames(className, bgColor, "-my-px")}>{children}</div>
      <div className="h-20 overflow-hidden">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          className="h-full w-full"
        >
          <path
            d={bottomPath || bottomVariants[bottomVariant]}
            className={fillColor}
          />
        </svg>
      </div>
    </div>
  );
};

// Prop types
WaveBorderBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  /**
   * One of the predefined paths from the topVariants object.
   */
  topVariant: PropTypes.number,
  /**
   * One of the predefined paths from the bottomVariants object.
   */
  bottomVariant: PropTypes.number,
  /**
   * The SVG path to use for the top of the box. (overrides topVariant)
   */
  topPath: PropTypes.string,
  /**
   * The SVG path to use for the bottom of the box. (overrides bottomVariant)
   */
  bottomPath: PropTypes.string,
  /**
   * background color of the box
   */
  bgColor: PropTypes.string,
};

export default WaveBorderBox;
