import React from "react";
import useDarkModeToggleState from "Components/DarkModeToggle/useDarkModeToggleState";
import { Toggle, Trans } from "Components";

const DarkModeToggle = ({ showLabel = false }) => {
  const { darkMode, toggleDarkMode } = useDarkModeToggleState();

  const label = React.useMemo(
    () => (darkMode ? <Trans>Active</Trans> : <Trans>Inactive</Trans>),
    [darkMode]
  );

  return (
    <Toggle
      name={"Dark Mode"}
      defaultChecked={darkMode}
      onChange={toggleDarkMode}
      label={showLabel ? label : null}
      activeIconName="moon"
      inactiveIconName="sun-bright"
    />
  );
};

export default DarkModeToggle;
