import useDetachPaymentMethodFromCurrentUser from "API/mutations/paymentMethods/useDettachPaymentMethodFromCurrentUser";
import useSetDefaultPaymentMethodForCurrentUser from "API/mutations/paymentMethods/useSetDefaultPaymentMethodForCurrentUser";
import {Button, ButtonGroup, Trans} from "Components";
import React from "react";

import {checkCreditCardExpired} from "Components/CreditCard/CreditCard";

const AccountSubscriptionPaymentMethodsCardAction = ({paymentMethod}) => {
  const isExpired = checkCreditCardExpired(
    paymentMethod.card.exp_month,
    paymentMethod.card.exp_year
  );

  const {
    detachPaymentMethodFromCurrentUser,
  } = useDetachPaymentMethodFromCurrentUser(paymentMethod.id);

  const {
    setDefaultPaymentMethodForCurrentUser,
  } = useSetDefaultPaymentMethodForCurrentUser(paymentMethod.id);

  if (paymentMethod.isDefaultPaymentMethod)
    return (
      <Button
        size="sm"
        onClick={detachPaymentMethodFromCurrentUser}
        icon="trash"
        hint={<Trans>Remove</Trans>}
      />
    );

  return (
    <ButtonGroup>
      {!isExpired && !paymentMethod.isDefaultPaymentMethod && (
        <Button
          size="sm"
          onClick={setDefaultPaymentMethodForCurrentUser}
          icon="star"
          hint={<Trans>Set as default</Trans>}
        />
      )}

      <Button
        size="sm"
        onClick={detachPaymentMethodFromCurrentUser}
        icon="trash"
        hint={<Trans>Remove</Trans>}
      />
    </ButtonGroup>
  );
};

export default AccountSubscriptionPaymentMethodsCardAction;
