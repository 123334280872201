import React from "react";
import useCreateThreadMutation from "API/mutations/thread/useCreateThreadMutation";
import {useFormValidation} from "utilities/hooks/useFormValidation";
import {validateCreateComment} from "utilities/validators";
import {message} from "Components";
import Trans from "Components/Trans";

const useNewThreadState = (refetch, exerciseId) => {
  const {createThread, data} = useCreateThreadMutation(exerciseId);

  const INITIAL_STATE = {
    anonymous: false,
    username: "",
    text: "",
    error: "",
    message: "",
    url: null,
    exerciseId,
  };

  const state = useFormValidation(
    INITIAL_STATE,
    createThread,
    validateCreateComment
  );

  // Catching the response in data
  React.useEffect(() => {
    if (data) {
      if (data.createThread.ok) {
        state.setValues(INITIAL_STATE);
        message.success(<Trans>The comment has been posted</Trans>);
      }
      // catch the errors and set in the state
      else state.setErrors({text: data.createThread.error});
    }
  }, [data]);

  return {
    ...state,
  };
};

export default useNewThreadState;
