import React from "react";
import decode from "jwt-decode";

const debug = process.env.NODE_ENV === "development" ? console.log : () => {};

const TOKEN = "_token";
const REFRESH_TOKEN = "_r_token";

export const decodeTokens = () => {
  const token = getAuthToken();
  const refreshToken = getRefreshToken();

  let decoded = null;

  try {
    decoded = decode(token);

    const {exp} = decode(token);

    if (Date.now() / 1000 > exp) {
      // THe token is expired => log out
      logout("/");
    }
  } catch (err) {
    // ! Removed the console because if there is no token it shows up as an error since decode is trying to decode a null value
    //   console.log("Error while decoding token: ", err);
    return null;
  }

  return decoded;
};

export const setAuthenticated = (token, refreshToken, refreshPage = true) => {
  localStorage.setItem("AUTH_TOKEN", token);
  localStorage.setItem("REFRESH_TOKEN", refreshToken);

  const decodedToken = decodeTokens(refreshPage);

  // Set the token in cookies as well so that it's shared with the app in the app's browser session during authentication
  setCookieToken(TOKEN, token, decodedToken.exp);
  setCookieToken(REFRESH_TOKEN, refreshToken, decodedToken.exp);

  return decodedToken.user;
};

export const logout = redirectTo => {
  localStorage.removeItem("AUTH_TOKEN");
  localStorage.removeItem("REFRESH_TOKEN");

  // Remove the token from cookies as well
  removeCookieToken(TOKEN);
  removeCookieToken(REFRESH_TOKEN);

  if (redirectTo && typeof redirectTo === "string") {
    return window.location.replace(redirectTo);
  }

  if (window.location == "/home") {
    window.location.replace("/");
  } else {
    const reloaded = new URLSearchParams(window.location.search).get("r");

    if (!reloaded) {
      window.location.search = "?r=false";
      window.location.reload();
    }
  }
};

export const getAuthToken = () => {
  const localStorageToken = localStorage.getItem("AUTH_TOKEN");
  const cookieToken = getCookieToken(TOKEN);

  debug("LS token " + localStorageToken);
  debug("Cookie token " + cookieToken);
  // Check if both are null
  if (!localStorageToken && !cookieToken) {
    debug("No tokens found");
    return null;
  }

  let token = localStorageToken || cookieToken;

  let exp = decode(token).exp;

  // If cookie token is set but local storage token is not, set the local storage token
  if (!localStorageToken && cookieToken) {
    debug("Setting local storage token based on cookie token");
    localStorage.setItem("AUTH_TOKEN", cookieToken);
    return cookieToken;
  }

  // Vice versa
  if (localStorageToken && !cookieToken) {
    debug("Setting cookie token based on local storage token");
    setCookieToken(TOKEN, localStorageToken, exp);
    return localStorageToken;
  }

  return localStorageToken || cookieToken;
};

export const getRefreshToken = () => {
  return localStorage.getItem("REFRESH_TOKEN") || getCookieToken(REFRESH_TOKEN);
};

const setCookieToken = (name, value, expiry) => {
  const hostname = window.location.hostname;
  const hostNameParts = hostname.split(".");
  let domain = hostname;

  if (hostNameParts.length > 2) {
    domain = hostNameParts[1]; // the domain without the subdomain
  }

  const expires = new Date(expiry * 1000).toUTCString();
  document.cookie = `${name}=${value}; path=/; expires=${expires};`;
};

const removeCookieToken = name => {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

const getCookieToken = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    let cookie = parts.pop().split(";");

    return cookie.shift();
  }

  return null;
};
