import React, {useContext} from "react";
import useDeleteGroupMemberInvite from "API/mutations/groups/useDeleteGroupMemberInvite";
import {Button, Confirm, Trans, message} from "Components";

const GroupInvitesDelete = ({id}) => {
  const {groupId} = useContext(GroupContext);
  const {
    deleteGroupMemberInvite,
    loading,
    response,
  } = useDeleteGroupMemberInvite({
    groupId,
  });

  const handleDelete = () => {
    deleteGroupMemberInvite({
      variables: {
        inviteId: id,
        groupId,
      },
    });
  };

  React.useEffect(() => {
    if (response?.ok) {
      message.success(<Trans>Invite has been delete</Trans>);
    }

    if (response?.error) {
      message.error(response.error.message);
    }
  }, [response]);

  return (
    <Confirm onConfirm={handleDelete}>
      <Button
        icon="times"
        type="destructive"
        size="xs"
        loading={loading}
        disabled={response !== null}
      />
    </Confirm>
  );
};

export default GroupInvitesDelete;
