import React from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {GET_CURRENT_USER_GROUPS} from "API/queries/groups/useGetCurrentUserGroups";

const JOIN_GROUP = gql`
  mutation joinGroup($groupId: ID!) {
    currentUserCreateGroupMembership(groupId: $groupId) {
      ok
      error {
        code
        message
      }
      groupMember {
        id
      }
    }
  }
`;

const useJoinGroup = ({refetchQueries = []}) => {
  const [joinGroup, {data, loading, error}] = useMutation(JOIN_GROUP, {
    errorPolicy: "all",
    refetchQueries: [
      {
        query: GET_CURRENT_USER_GROUPS,
      },
      ...refetchQueries,
    ],
  });

  if (error) {
    console.log(`Error in useJoinGroup: ${error}`);
  }

  const response = data?.currentUserCreateGroupMembership;

  return {
    joinGroup,
    response,
    loading,
  };
};

export default useJoinGroup;
