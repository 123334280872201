import React from "react";
import Trans from "Components/Trans";
import useCreateSubscriptionMutation from "API/mutations/payment/useCreateSubscriptionMutation";
import {Button} from "Components";
import handleStripeSession from "utilities/handleStripeSession";

const SubscriptionFailed = () => {
  const {
    stripeCreateSubscription,
    sessionId,
    loading,
    error,
  } = useCreateSubscriptionMutation();
  React.useEffect(() => handleStripeSession(sessionId), [sessionId]);

  return (
    <div className="container flex flex-col items-center">
      <img src="https://d1mgntrf3vaj6d.cloudfront.net/images/payment-failed.svg" />
      <h1 className="red">
        <Trans>Oops</Trans>
      </h1>
      <h6>
        <Trans>
          It seems like something went wrong during the payment process. You
          have not been charged anything.
        </Trans>
        <Trans>Please try again or contact us if the problem persists</Trans>
      </h6>
      <Button
        type="primary"
        onClick={() => stripeCreateSubscription()}
        loading={loading}
        disabled={loading}
      >
        <Trans>Try again</Trans>
      </Button>
      {error && <p className="red">error</p>}
    </div>
  );
};

export default SubscriptionFailed;
