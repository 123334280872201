import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

export const GET_PUBLIC_EXERCISE_NOTE = gql`
  query getPublicExerciseNotes($exerciseId: ID!) {
    getPublicExerciseNotes(exerciseId: $exerciseId) {
      id
      text
      user {
        id
        first_name
        last_name
        avatar
        admin
        assistant
      }
    }
  }
`;

const useGetPublicExerciseNotes = exerciseId => {
  let notes = [];

  const {loading, error, data} = useQuery(GET_PUBLIC_EXERCISE_NOTE, {
    variables: {exerciseId},
  });

  if (error) {
    console.log("Error in useGetPublicExerciseNotes", error);
  }

  if (data && data.getPublicExerciseNotes) {
    notes = data.getPublicExerciseNotes;
  }

  return [notes, loading];
};

export default useGetPublicExerciseNotes;
