import React from "react";

const useExamMarkingSegmentNumbers = ({ segments, setSegments }) => {
  React.useEffect(() => {
    // Sort the segments by y1
    const sortedSegments = [...segments].sort((a, b) => a.y1 - b.y1);
    // Check if the there are any segments where number is not set or number is not equal to the index
    const segmentsWithWrongLocalNumber = sortedSegments.filter(
      (segment, index) => {
        return (
          segment.localNumber === undefined || segment.localNumber === null
        );
      }
    );

    // If there are any segments with wrong number, update the number for all segments
    if (segmentsWithWrongLocalNumber.length > 0) {
      sortedSegments.forEach((segment, index) => {
        segment.localNumber = index + 1;
      });

      // Update the segments
      console.log("Setting segment numbers", sortedSegments);

      setSegments(sortedSegments);
    }
  }, [segments]);
};

export default useExamMarkingSegmentNumbers;
