import React from "react";
import {Badge, Typography} from "Components/_Elements";
import {AnimatePresence, motion} from "framer-motion";

const suggestions = [
  "Kan du ge mig ett tips till uppgiften?",
  "Förklara lösningsförslaget",
  "Lös uppgiften steg för steg",
];

const ExerciseSolutionHintsPromptSuggestions = ({
  badgeSize = "md",
  badgeType = "darkGray",
}) => {
  const {
    loading,
    prompt,
    chatActive,
    handlePromptChange,
    promptInputFocused,
    metadata,
  } = React.useContext(GPTContext);

  const handleSuggestionClick = suggestion => {
    if (loading) return;
    handlePromptChange({suggestedPrompt: suggestion});
  };

  const hideSuggestions =
    chatActive ||
    prompt.length > 0 ||
    promptInputFocused ||
    metadata.exerciseId === 0;

  return (
    <AnimatePresence>
      {!hideSuggestions && (
        <motion.div
          className="flex flex-wrap gap-2"
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
        >
          {suggestions.map((suggestion, index) => (
            <Badge
              disabled={loading}
              onClick={() => handleSuggestionClick(suggestion)}
              type={loading ? "gray" : badgeType}
              size={badgeSize}
              className="cursor-pointer hover:bg-gray-300"
              icon="lightbulb"
            >
              <Typography>{suggestion}</Typography>
            </Badge>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ExerciseSolutionHintsPromptSuggestions;
