import useGetCourseExams from "API/queries/exam/useGetCourseExams";
import React from "react";
import {CourseContext} from "routes/Course/CourseContextProvider";
import {Button, ExamCard, Trans, Typography} from "Components";

import "./CourseLandingExams.scss";
import GenericToggle from "./GenericToggle/GenericToggle";

import {textSingularPluralTranslated} from "utilities/textSingularPlural";

const CourseLandingExams = () => {
  const {courseId} = React.useContext(CourseContext);
  const {navigationState} = React.useContext(window.CourseLandingContext);

  const [showAll, setShowAll] = React.useState(false);
  const [exams, loading] = useGetCourseExams(courseId);

  if (loading) {
    return null;
  }

  const Title = (
    <div className="course-landing-exams-title mb-4">
      <Trans type="txl" weight="sb">
        Past exams
      </Trans>
    </div>
  );

  if (exams.length < 6) {
    return (
      <div ref={navigationState.examsRef} className="course-landing-exams">
        {Title}
        {exams.map((exam, index) => (
          <ExamCard {...exam} key={exam.id} />
        ))}
      </div>
    );
  }

  // Group exams by year for the toggle

  const [examsGroupedByYear, sortedYearKeys] = groupExamsByYear(exams);

  const sliceLimit = 4;

  const years = showAll ? sortedYearKeys : sortedYearKeys.slice(0, sliceLimit);

  return (
    <div ref={navigationState.examsRef} className="course-landing-exams">
      {Title}
      {years.map((year, index) => {
        const exams = examsGroupedByYear[year];
        return (
          <GenericToggle
            index={index}
            title={year}
            children={exams.map(exam => (
              <div className="mb-2">
                <ExamCard {...exam} />
              </div>
            ))}
            subtitle={
              <Typography type="tmd">
                {textSingularPluralTranslated(exams.length, "exam")}
              </Typography>
            }
          />
        );
      })}

      {sortedYearKeys.length > sliceLimit && (
        <Button onClick={() => setShowAll(!showAll)} className="flex">
          {showAllExamsText(showAll, sortedYearKeys.length)}
        </Button>
      )}
    </div>
  );
};

const showAllExamsText = (showAll, nYears) => {
  return showAll ? (
    <Trans>Hide all</Trans>
  ) : (
    <Trans nYears={nYears}>Show all {{nYears}} years</Trans>
  );
};

const groupExamsByYear = exams => {
  const data = {rest: []};

  exams.forEach(exam => {
    try {
      const year = exam.date.split("-")[0];

      if (data[year] == undefined) {
        data[year] = [exam];
      } else {
        data[year] = [...data[year], exam];
      }
    } catch (error) {
      // Edge case for handling erroneous dates
      data["rest"].append(exam);
    }
  });

  // Sort the keys so latest year is first:
  const keys = Object.keys(data).sort((a, b) => (a > b ? -1 : 1));

  keys.shift(); // removes the key "rest from the list of keys"

  return [data, keys];
};

export default CourseLandingExams;
