import React from "react";
import { message } from "Components";
import useProductPurchaseExpiryBannerExisitingApplicationState from "Components/ProductPurchaseExpiryBanner/ProductPurchaseExpiryBannerExtensionForm/useProductPurchaseExpiryBannerExisitingApplicationState";
import { useSubmitPurchaseExtension } from "API/sheets/useSubmitPurchaseExtension";

const useProductPurchaseExpiryBannerExtensionFormState = ({
  productId,
  productName,
}) => {
  const { user } = React.useContext(window.UserContext);
  const {
    hasApplied,
    setHasApplied,
  } = useProductPurchaseExpiryBannerExisitingApplicationState(
    productId,
    productName
  );

  const {
    submit,
    loading,
    success,
    error: submissionError,
  } = useSubmitPurchaseExtension();

  const [state, setState] = React.useState({
    reason: null,
    userId: user.id,
    email: user.email,
    productId,
    productName,
  });

  // Update reason on change
  const onChange = e => setState({ ...state, reason: e.target.value });

  const onSubmit = () => submit(state);

  // Catch response
  React.useEffect(() => {
    if (success) {
      // Clear reason
      setState({ ...state, reason: null });
      // Set applied
      setHasApplied(1);
    }
    if (submissionError) {
      message.error(submissionError);
    }
  }, [success]);

  return {
    state,
    submissionError,
    loading,
    success,
    onSubmit,
    onChange,
    hasApplied,
  };
};

export default useProductPurchaseExpiryBannerExtensionFormState;
