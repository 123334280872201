import React from "react";
import {useLocation, useParams, useRouteMatch} from "react-router-dom";
import useGetCurrentRoute from "utilities/hooks/course/useGetCurrentRoute";
import paramAgnosticPathCompare from "utilities/paramAgnosticPathCompare/paramAgnosticPathCompare";
import {isMobileOnly} from "react-device-detect";

const routesWithSidebarHidden = [
  "trial",
  "checkout",
  "campaign",
  "guldbiljett",
  "silverticket",
  "sektionskampen",
  "frontpage",
  "group-invite",
  "invite",
];

const useGroupNavigationState = routes => {
  // collapsed is managed automatically if the user is inside a course

  const params = useParams();
  const url = window.location.pathname;

  const isCourseRoute = url.includes("/course/");

  const collapsed = isCourseRoute && !isMobileOnly;

  const {pathname} = useLocation();

  const currentRoute = React.useMemo(
    () => routes?.find(route => paramAgnosticPathCompare(route.path, pathname)),
    [pathname, routes]
  );

  let hideSidebar = currentRoute?.hideSidebar || false;

  if (routesWithSidebarHidden.some(route => pathname.includes(route))) {
    hideSidebar = true;
  }

  return {
    collapsed,
    url,
    currentPath: pathname,
    currentRoute,
    hideSidebar,
  };
};

export default useGroupNavigationState;
