import React from "react";
import {useParams} from "react-router-dom";

import useCheckPDFExists from "utilities/hooks/useCheckPDFExists";
import useGetExam, {GET_EXAM} from "API/queries/exam/useGetExam";
import useUpdateExamMutation from "../../../API/mutations/exam/useUpdateExamMutation";

const useCourseExamView = examId => {
  const {examId: paramExamId} = useParams();
  const id = examId || paramExamId;
  const [exam, loading] = useGetExam({id});
  const [showSolution, setShowSolution] = React.useState(false);

  const pdfUrl = React.useMemo(() => {
    if (showSolution && exam?.exam_solutions_src) {
      return exam?.exam_solutions_src;
    }
    return exam?.exam_exercises_src;
  }, [exam, showSolution]);

  const [pdfExists, pdfLoading] = useCheckPDFExists(pdfUrl);

  const refetchQueries = [
    {
      query: GET_EXAM,
      variables: {examId: id, includeExercises: false, includeSolutions: false},
    },
  ];

  const {
    updateExam,
    loading: updatingExam,
    data: updatedExam,
  } = useUpdateExamMutation(refetchQueries);

  const setSolutionSource = async source => {
    const response = await updateExam({
      variables: {
        id: exam.id,
        date: exam.date,
        exam_solutions_src: source,
      },
    });
  };

  return {
    exam,
    loading,
    showSolution,
    setShowSolution,
    pdfUrl,
    pdfExists,
    pdfLoading,
    setSolutionSource,
  };
};

export default useCourseExamView;
