import React from "react";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Dropdown,
  Icon,
  Trans,
  Typography,
} from "Components/index";

import {GPTContext} from "Components/GPT/GPTProvider";
import GPTUserSettingsLength from "Components/GPT/GPTUserSettings/GPTUserSettingsLength";
import classnames from "classnames";

const GPTUserSettings = () => {
  const {
    settings,
    setIncludeExerciseText,
    setIncludeSolutionText,
    chatActive,
    metadata,
  } = React.useContext(GPTContext);

  return (
    <Dropdown
      content={
        <div className="mt-2 p-2 w-64 background">
          {metadata.exerciseId !== 0 && (
            <div className={classnames(chatActive ? "opacity-50" : "mb-4")}>
              <Trans type="tmd" weight="sb">
                System input
              </Trans>
              <div className="flex justify-between items-center py-1 mt-2">
                <Trans>Include exercise</Trans>
                <Checkbox
                  disabled={chatActive}
                  checked={settings.includeExerciseText}
                  onChange={setIncludeExerciseText}
                  label=""
                />
              </div>
              <div className="flex justify-between items-center py-1 mt-2 z-50">
                <Trans>Include solution</Trans>
                <Checkbox
                  disabled={chatActive}
                  checked={settings.includeSolutionText}
                  onChange={setIncludeSolutionText}
                  label=""
                />
              </div>
            </div>
          )}
          <div>
            <Trans type="tmd" weight="sb">
              Answers
            </Trans>
            <div className="flex justify-between items-center py-1  mt-2">
              <Trans>Length</Trans>
              <GPTUserSettingsLength />
            </div>
          </div>
        </div>
      }
    >
      <Button iconRight="chevron-down" size="sm" type="gray">
        <Trans>Settings</Trans>
      </Button>
    </Dropdown>
  );
};

export default GPTUserSettings;
