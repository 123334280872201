import React from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import classnames from "classnames";
import PropTypes from "prop-types";
import {SafeText} from "Components/_Elements/index";

const Tooltip = ({
  children,
  content,
  defaultOpen,
  open,
  side = "bottom",
  placement = "bottom",
  variant = "dark",
  delayDuration = 300,
  asChild = false,
}) => {
  return (
    <RadixTooltip.Provider delayDuration={delayDuration}>
      <RadixTooltip.Root defaultOpen={defaultOpen} open={open}>
        <RadixTooltip.Trigger className="cursor-auto" asChild={asChild}>
          {children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            side={placement || side}
            className={classnames(
              "shadow-lg p-3 rounded-lg data-[side=bottom] z-[600]",
              variant === "light"
                ? "bg-white text-gray-700"
                : "bg-black text-white"
            )}
            sideOffset={5}
          >
            <SafeText type="tsm">{content}</SafeText>
            <RadixTooltip.Arrow
              width={10}
              height={5}
              className={classnames(
                variant === "light" ? "fill-white" : "fill-black"
              )}
            />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
  defaultOpen: PropTypes.bool,
  open: PropTypes.bool,
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  variant: PropTypes.oneOf(["light", "dark"]),
};

export default Tooltip;
