import {message, Trans} from "Components";
import useUpdateMembershipCancelAtPeriodEnd from "API/mutations/membership/useUpdateMembershipCancelAtPeriodEnd";
import useSubmitForm from "API/sheets";
import {MD5} from "crypto-js";
import React from "react";
import {AccountSubscriptionContext} from "routes/Account/AccountSubscription/AccountSubscription";
import {UserContext} from "UserContextProvider";

/**
 * Used for the Subscription Cancellation modal when user submits the form
 */
const useSubscriptionCancellationStateSubmission = ({
  setModalVisible,
  state,
  subscriptionId,
}) => {
  const {user} = React.useContext(UserContext);

  const {submit, ...formSubmissionState} = useSubmitForm(
    "MEMBERSHIP_CANCELLATION_FORM"
  );

  const onMembershipUpdateCompleted = data => {
    if (data.updateMembershipCancelAtPeriodEnd?.ok) {
      message.success(
        <Trans>Your membership will be cancel at the end of this period</Trans>
      );
      setModalVisible(false); // This will also reset the form
    } else if (data.updateMembershipCancelAtPeriodEnd?.error) {
      console.error(data.updateMembershipCancelAtPeriodEnd.error);
      message.error(<Trans>An error occurred, please try again</Trans>);
    }
  };

  const {
    updateMembershipCancelAtPeriodEnd,
    loading,
  } = useUpdateMembershipCancelAtPeriodEnd(onMembershipUpdateCompleted);

  const onSubmit = () => {
    updateMembershipCancelAtPeriodEnd({
      variables: {
        cancelAtPeriodEnd: true,
      },
    });

    submit({
      cancellationReason: state.selectedOptionName,
      explanation: state.explanation,
      userId: user.id,
      userEmail: user.email,
      membershipId: subscriptionId,
      id: MD5(`${user.id}${subscriptionId}`).toString(),
    });
  };

  return {
    onSubmit,
    loading: loading || formSubmissionState.loading,
    error: formSubmissionState.error,
  };
};

export default useSubscriptionCancellationStateSubmission;
