import React from "react";
import {Icon} from "Components";
import {safeCloneTextTag} from "utilities/safeClone";

const CardHeader = ({
                      title = null,
                      subtitle = null,
                      icon = null,
                      type,
                      iconSize = "lg",
                    }) => {
  if (!title && !subtitle && !icon) return null;
  return (
    <div className="flex items-center gap-4 pt-4 px-4">
      {icon && (
        <Icon
          fixedWidth
          filled={true}
          fillType={type}
          icon={icon}
          size={iconSize}
        />
      )}
      <div className="flex flex-col">
        {safeCloneTextTag(title, {
          type: "tmd",
        })}
        {safeCloneTextTag(subtitle, {
          type: "tsm",
        })}
      </div>
    </div>
  );
};

export default CardHeader;
