import React from "react";
import {Button, Icon, Link, Trans} from "Components";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import useGroupInvitationHandler from "routes/Group/GroupInvitation/useGroupInvitationHandler";
import {useHistory} from "react-router-dom";

const GroupNoAccess = () => {
  const {name, membershipRequiresValidation} = useGroupContext();

  const history = useHistory();
  return (
    <div className="p-4 bg-slate-500 rounded-xl">
      <div className="flex flex-col gap-4 items-start">
        <Icon
          icon="lock"
          size="md"
          filled
          className="text-slate-600"
          fillType="gray"
          outlined
        />

        <div>
          <Trans
            type="tlg"
            weight="md"
            color="text-slate-50"
            className="text-center md:text-left"
            name={name}
          >
            This page is ony for members of {{name}}
          </Trans>

          <div className="mt-4 flex gap-2 items-center  ">
            {!membershipRequiresValidation && (
              <Link
                size="sm"
                buttonType="gray"
                iconLeft="user-plus"
                to="/onboarding"
              >
                <Trans name={name}>Join {{name}}</Trans>
              </Link>
            )}
            <Link
              href={window.location.href.replace(
                window.location.hostname.split(".")[0] + ".", // remove subdomain
                ""
              )}
              buttonType="secondary"
              size="sm"
              iconLeft="home"
            >
              <Trans>Go to main Kollin</Trans>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupNoAccess;
