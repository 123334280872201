import React from "react";
import classnames from "classnames";
import GeogebraHighlight from "Components/Geogebra/GeogebraHighlight/GeogebraHighlight";
import {GeogebraContext} from "Components/Geogebra/GeogebraProvider";

const Math = ({children, html, text, className, inline, contentKey}) => {
  const geogebraContext = React.useContext(GeogebraContext);

  const ref = React.useCallback(node => {
    if (node !== null && window.MathJax !== undefined) {
      try {
        window.MathJax.typesetPromise();
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  if (text && geogebraContext) {
    return <GeogebraHighlight text={text} mathRef={ref} />;
  }

  if (text) {
    return (
      <div
        className={classnames(inline && "inline", className)}
        ref={ref}
        dangerouslySetInnerHTML={{__html: text}}
      />
    );
  }

  if (html) {
    return (
      <div
        className={classnames(inline && "inline", className)}
        ref={ref}
        dangerouslySetInnerHTML={{__html: html}}
      />
    );
  }
  return (
    <div
      key={contentKey}
      className={classnames(inline && "inline", className)}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default React.memo(Math);
