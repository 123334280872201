import React from "react";
import {useParams} from "react-router-dom";
import useGetChapter from "API/queries/chapter/useGetChapter";
import SkeletonList from "Components/_Elements/Skeleton/SkeletonList";
import ChapterDisplay from "./ChapterDisplay/ChapterDisplay";
import ChapterMetaInfo from "./ChapterMetaInfo";
import ChapterHeader from "routes/Course/Chapter/ChapterHeader";
import CheckoutAuthentication from "routes/Checkout/CheckoutAuthentication/CheckoutAuthentication";
import GroupPageJoinAuth from "routes/Campaign/Sektionskampen/GroupPage/GroupPageJoin/GroupPageJoinAuth";
import {Typography} from "Components";

const Chapter = ({
  chapterType: chapterTypeProp,
  chapterId: chapterIdProp,
  showHeader = true,
}) => {
  const {user} = React.useContext(UserContext);
  const {
    chapterType: chapterTypeParam,
    chapterId: chapterIdParam,
  } = useParams();

  const chapterType = chapterTypeParam || chapterTypeProp;
  const chapterId = chapterIdParam || chapterIdProp;

  const [chapter, loading] = useGetChapter({
    chapterId,
    chapterType,
    includeAuthor: true,
    includeTag: true,
    includeText: true,
  });

  if (loading) return <SkeletonList count={20} size="sm" />;

  if (!user)
    return (
      <div className="max-w-2xl mx-auto p-4 surface rounded-xl ">
        <GroupPageJoinAuth
          signupSource={"chapter-page"}
          registerTitle={
            "Du behöver vara skapa ett konto för att läsa detta kapitel."
          }
          loginTitle={"Logga in för att läsa detta kapitel."}
        />
      </div>
    );

  return (
    <div className="@container max-w-5xl px-2 md:px-4 ">
      <ChapterHeader chapter={chapter} showHeader={showHeader} />
      <div className="flex flex-col @md:flex-row  items-start gap-8">
        <div className="w-full @md:w-8/12 ">
          <ChapterDisplay chapter={chapter} />
        </div>
        <div className="grow w-full @md:w-4/12">
          <ChapterMetaInfo chapter={chapter} />
        </div>
      </div>
    </div>
  );
};

export default Chapter;
