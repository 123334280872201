import React from "react";
import PropTypes from "prop-types";
import {scoreToDifficultyColor} from "utilities/exerciseUtilities/formatScore";

const CompoundProgressBar = ({nExercises, segments, thin, style, animated}) => {
  return (
    <div
      className="h-1 md:h-2 w-full w-full flex rounded-xl box-content overflow-hidden bg-stone-200"
      style={{
        backgroundColor: (style && style.backgroundColor) || "",
      }}
    >
      {segments &&
        segments.map((s, i) => (
          <div
            key={s.selfEvaluation}
            className={i === 0 ? "rounded-l-xl" : ""}
            style={{
              boxSizing: "content-box",
              transition: animated ? "width 0.5s ease-out" : "",
              height: "100%",
              width: `${(100 * s.nCompleted) / nExercises}%`,
              backgroundColor:
                s.color || scoreToDifficultyColor(s.selfEvaluation),
              opacity: 0.5,
            }}
          ></div>
        ))}
    </div>
  );
};

CompoundProgressBar.propTypes = {
  nExercises: PropTypes.number.isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      selfEvaluation: PropTypes.number,
      nCompleted: PropTypes.number,
    })
  ),
  thin: PropTypes.bool,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export default CompoundProgressBar;
