import React from "react";
import GroupCoursesListByYear from "routes/Group/GroupCourses/GroupCoursesListByYear";
import {ErrorBoundary, Tabs, Trans, Typography} from "Components";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import GroupCoursesTimeline from "routes/Group/GroupCourses/GroupCoursesTimeline/GroupCoursesTimeline";

const GroupCourses = () => {
  const group = useGroupContext();
  const groupId = group?.id;
  const name = group?.name;

  const subGroups = React.useMemo(() => {
    if (group.subGroups.length > 0) {
      if (group?.program?.id) {
        return [group, ...group.subGroups];
      }

      return group.subGroups;
    }

    return [group];
  }, [group?.id]);

  return (
    <Tabs type="button" orientation="horizontal" size="xl">
      {subGroups.map((subGroup, index) => {
        const program = subGroup?.program;

        return (
          <Tabs.Pane
            key={index}
            title={
              subGroup.id === groupId ? (
                <Trans>Gemensamt</Trans>
              ) : (
                <Typography>{subGroup.name}</Typography>
              )
            }
          >
            <Typography type="dxs md:dsm">{program?.name}</Typography>
            <GroupCoursesListByYear programId={program?.id} key={index} />
            <div className="mt-8" />
            {group.schoolId == 537 && (
              <div>
                <Typography type="dsm">Timeline</Typography>
                <ErrorBoundary hideOnError={true}>
                  <GroupCoursesTimeline programId={program?.id} key={index} />
                </ErrorBoundary>
              </div>
            )}
          </Tabs.Pane>
        );
      })}
    </Tabs>
  );

  // return <GroupCoursesListByYear programId={programId} />;
};

export default GroupCourses;
