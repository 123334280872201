import React from "react";
import { Loader, Trans } from "Components";
import { motion } from "framer-motion";
import { range } from "utilities/numbers";

const ExamUploadProcessing = ({ progress }) => {
  const steps = 36;
  return (
    <div
      className="max-w-lg p-4 border-0 border-purple rounded-xl flex flex-col items-center justify-center gap-4"
      style={{
        background: "radial-gradient(#EBE9FE, transparent  75%)",
      }}
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{
          scale: range(steps).map((_, i) => (i % 2 === 0 ? 1.2 : 0.8)),
          rotate: range(steps).map((_, i) => i * 10),
        }}
        transition={{ duration: steps / 2, repeat: "reverse" }}
      >
        <Loader type="Ring" color={"#5925DC"} size={100} />
      </motion.div>
      <Trans color="text-purple-900" weight="sb" type="tlg" className="mt-4">
        Processing files, please wait...
      </Trans>
      <Trans color="text-purple-900" type="tmd" progress={progress}>
        Status: {{ progress }}% complete
      </Trans>
    </div>
  );
};

export default ExamUploadProcessing;
