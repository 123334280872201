import React from "react";

const useOutsideAlerter = (ref, closeAction) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) closeAction();
  };

  React.useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    // Unbind the event listener on clean up
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
};

export default useOutsideAlerter;
