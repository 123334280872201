import React from "react";
import {Modal} from "../../_Elements";

const useGPTDisplayModalState = ({modal, autoModalWhenActive}) => {
  const [showAsModal, setShowAsModal] = React.useState(false);
  let Wrapper = "div";

  if (showAsModal || modal) {
    Wrapper = Modal;
  }

  const toggleModal = () => setShowAsModal(!showAsModal);

  return {
    toggleModal,
    showAsModal,
    Wrapper,
  };
};

export default useGPTDisplayModalState;
