import React, { isValidElement } from "react";
import CDN from "utilities/constants/CDN";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Typography } from "Components/_Elements";

const titleSizeMapping = {
  sm: "tmd",
  md: "tlg",
  lg: "txl",
};

const subtitleSizeMapping = {
  sm: "tsm",
  md: "tsm",
  lg: "tmd",
};

const EmptyState = ({
  title,
  subTitle,
  illustration = "box",
  size = "sm",
  type = "primary",
  primaryAction = null,
  secondaryAction = null,
  fluidActions = true,
  horizontal = false,
}) => {
  const imgSrc = React.useMemo(() => {
    let illustrationVariant = illustration;
    // If illustration is not in the list of available illustrations, use the default one
    if (!illustrations.includes(illustration)) {
      illustrationVariant = "box";
    }

    return CDN + `/empty-states/${illustrationVariant}-${type}-${size}.svg`;
  }, [illustration, type, size]);

  return (
    <div
      className={classnames(
        " flex items-center",
        horizontal
          ? "flex-row w-full gap-4"
          : " text-center flex-col max-w-[32em]  mx-auto"
      )}
    >
      <img src={imgSrc} alt="empty state illustration" />
      {/*Override the text formatting on the Trans/Typography tag*/}

      <div>
        <div className={classnames(size === "sm" ? "mt-4" : "mt-5")}>
          <SafeTitle
            title={title}
            type={titleSizeMapping[size]}
            color="text-gray-900"
            weight="normal"
          />
        </div>
        <div className="mt-2">
          <SafeTitle
            title={subTitle}
            type={subtitleSizeMapping[size]}
            color="text-gray-500"
            weight="normal"
          />
        </div>
        <Actions
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          fluidActions={fluidActions}
          size={size}
        />
      </div>
    </div>
  );
};

const Actions = ({ primaryAction, secondaryAction, fluidActions, size }) => {
  return (
    <div
      className={classnames(
        "w-full flex gap-2 justify-between",
        size === "sm" ? "mt-4" : "mt-8"
      )}
    >
      {secondaryAction &&
        React.cloneElement(secondaryAction, {
          type: "gray",
          buttonType: "gray",
          size,
          fluid: fluidActions,
        })}
      {primaryAction &&
        React.cloneElement(primaryAction, {
          type: "primary",
          buttonType: "primary",
          size,
          fluid: fluidActions,
        })}
    </div>
  );
};

const SafeTitle = ({ title, type, weight, color }) => {
  if (title === null) return null;
  try {
    if (isValidElement(title)) {
      return React.cloneElement(title, {
        type,
        weight,
        color,
      });
    }
  } catch (e) {
    console.error("Error in EmptyState/SafeTitle", e);
  }

  // Fallback to a Typography component if string or invalid element
  return (
    <Typography type={type} weight={weight} color={color}>
      {title}
    </Typography>
  );
};

export const illustrations = ["box", "cloud", "credit-card", "documents"];
export const sizes = ["sm", "md", "lg"];
export const types = ["primary", "gray"];

EmptyState.propTypes = {
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  illustration: PropTypes.oneOf(illustrations),
  size: PropTypes.oneOf(sizes),
  type: PropTypes.oneOf(types),
  primaryAction: PropTypes.node,
  secondaryAction: PropTypes.node,
};

export default EmptyState;
