import React from "react";
import {useHistory, useLocation} from "react-router-dom";

const useLogin = ({onSignupClick}) => {
  const location = useLocation();
  const history = useHistory();

  /**
   * What to do when the user clicks the login link
   */
  const handleSignUpClick = () => {
    if (onSignupClick && typeof onSignupClick === "function") {
      onSignupClick();
    } else {
      history.push("/signup" + window.location.search, {...location.state}); // Keep the location state
    }
  };

  return {
    handleSignUpClick,
  };
};

export default useLogin;
