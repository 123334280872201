import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {GET_EXERCISE_NOTE} from "API/queries/exerciseNote/useGetExerciseNotes";

const CREATE_EXERCISE_NOTE = gql`
  mutation createExerciseNote(
    $exerciseId: ID!
    $public: Boolean! = false
    $text: String!
  ) {
    createExerciseNote(
      exerciseId: $exerciseId
      isPublic: $public
      text: $text
    ) {
      ok
      error
    }
  }
`;

const useCreateExerciseNote = exerciseId => {
  const [createExerciseNote, {loading, data, error}] = useMutation(
    CREATE_EXERCISE_NOTE,
    {
      refetchQueries: [{query: GET_EXERCISE_NOTE, variables: {id: exerciseId}}],
    }
  );

  if (error) {
    console.log("Error in useCreateExerciseNote: ", error);
  }

  const response = data?.createExerciseNote;

  return {createExerciseNote, loading, response};
};

export default useCreateExerciseNote;
