import React from "react";
import useGetCourseExercises from "API/queries/exercise/useGetCourseExercises";
import CourseLandingExercisesExerciseCard from "routes/Course/CourseLanding/CourseLandingExercises/CourseLandingExercisesExerciseCard";
import ExerciseView from "Components/Workboard/ExerciseView/ExerciseView";
import {CourseLandingContext} from "routes/Course/CourseLanding/useCourseLandingContext";
import LandingPageSection from "Components/LandingPageSection/LandingPageSection";

const CourseLandingExercises = () => {
  const {course, landingPageContent} = React.useContext(CourseLandingContext);
  const landingPageContentSectionId = React.useMemo(
    () =>
      landingPageContent?.find(content => content.title === "exercises")?.sys
        ?.id,
    []
  );
  const [exercises, loading] = useGetCourseExercises({
    courseId: course.id,
    includeStats: true,
    includeSource: true,
  });

  // Sort exercises by most self-evaluations
  let selectedExercises = React.useMemo(() => {
    exercises.sort((a, b) => b.stats?.total - a.stats?.total);
    return exercises.slice(0, 10);
  }, [loading]);

  const [selectedExerciseId, setSelectedExerciseId] = React.useState(null);

  // After Exercises have been loaded, set the first exercise as selected

  React.useEffect(() => {
    if (!loading && selectedExercises.length > 0) {
      setSelectedExerciseId(selectedExercises[0].id);
    }
  }, [selectedExercises.length]);

  if (loading || selectedExercises.length === 0) {
    return null;
  }

  return (
    <div>
      <LandingPageSection sectionId={landingPageContentSectionId} />
      <div className="flex gap-2 pb-3 mt-4 relative overflow-x-auto snap-x">
        {selectedExercises.map((exercise, index) => {
          return (
            <CourseLandingExercisesExerciseCard
              exercise={exercise}
              selected={selectedExerciseId === exercise.id}
              key={"exercise-card-" + exercise.id}
              onClick={() => setSelectedExerciseId(exercise.id)}
            />
          );
        })}
      </div>
      {selectedExerciseId && (
        <div className="mt-2">
          <ExerciseView
            showHeader={false}
            showUserTools={false}
            exerciseId={selectedExerciseId}
            key={"exercise-view-" + selectedExerciseId}
            hideTips
            hideEvaluation
            showForum={true}
          />
        </div>
      )}
    </div>
  );
};

export default CourseLandingExercises;
