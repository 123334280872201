import React from "react";
import {isMobile} from "react-device-detect";
import {
  Button,
  ButtonGroup,
  Icon,
  Skeleton,
  Trans,
  Typography,
} from "Components";
import ExerciseSortList from "../ExerciseSortList/ExerciseSortList";
import ExerciseListProgress from "../ExerciseListProgress/ExerciseListProgress";
import {removeEscapedText} from "utilities/text";
import classnames from "classnames";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";
import Breadcrumb from "../_Elements/Breadcrumb/Breadcrumb";
import {CourseContext} from "../../routes/Course/CourseContextProvider";
import {CourseParamsContext} from "../../routes/Course/CourseRouter";
import usePlaylistHeaderCollapsedState from "Components/PlaylistHeader/usePlaylistHeaderCollapsedState";

const PlaylistHeader = ({
  title,
  loading,
  exercises,
  filtersFluid,
  bookmarked = false,
  showExerciseList = false,
  showTitle = true,
  showBreadcrumbs = true,
}) => {
  const smallScreens = useBreakPointSM();
  const {previewMode} = React.useContext(window.PreviewSidebarContext);

  const {
    exerciseListDisplayMode,
    setExerciseListDisplayMode,
  } = React.useContext(CourseContext);

  const {exercise, unsetExercise} = React.useContext(CourseParamsContext);

  return (
    <div className="border-b-2 border-color pr-2 pb-4">
      <div>
        {showTitle && (
          <div className="flex justify-between">
            <Typography
              className="mt-2 mb-4"
              type={smallScreens("dxs", "dsm")}
              weight={"sb"}
              a
              color="text-gray-600"
            >
              {!loading ? removeEscapedText(title) : <Skeleton size="lg" />}
            </Typography>
            <div id="action-pane-trigger-container" />
          </div>
        )}
        {showBreadcrumbs && <Breadcrumb />}
      </div>

      <div className="w-full md:w-96 mt-4">
        <ExerciseListProgress
          loading={loading}
          exercises={exercises}
          segmented
        />
      </div>
      {isMobile || previewMode ? (
        <></>
      ) : (
        <div
          className={classnames(
            "w-full gap-2 mt-2 flex items-end justify-between"
            // !filtersFluid && "md:w-2/5 "
          )}
        >
          <ExerciseSortList exercises={exercises} />
          <ButtonGroup className="mt-4">
            <Button
              type={
                exerciseListDisplayMode === "list" && !exercise
                  ? "secondary"
                  : "gray"
              }
              onClick={() => {
                setExerciseListDisplayMode("list");
                unsetExercise();
              }}
              size="sm"
              hint={<Trans>List view</Trans>}
            >
              <Icon icon="fa-regular fa-list" size="lg" />
            </Button>
            <Button
              type={
                exerciseListDisplayMode === "grid" && !exercise
                  ? "secondary"
                  : "gray"
              }
              onClick={() => {
                setExerciseListDisplayMode("grid");
                unsetExercise();
              }}
              size="sm"
              hint={<Trans>Grid view</Trans>}
            >
              <Icon icon="fa-regular fa-grid" size="lg" />
            </Button>

            <Button
              type={exercise ? "secondary" : "gray"}
              size="sm"
              hint={<Trans>Exercise view</Trans>}
              disabled
            >
              <Icon icon="fa-kit fa-exercise-view" size="lg" />
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export default PlaylistHeader;
