import {ErrorBoundary, PageLogoLoader} from "Components";
import ProductPurchaseExpiryBanner from "Components/ProductPurchaseExpiryBanner/ProductPurchaseExpiryBanner";
import React from "react";
import CourseBuyBannerMobile from "./CourseBuyBannerMobile/CourseBuyBannerMobile";
import CourseLandingAllReviews from "./CourseLandingAllReviews/CourseLandingAllReviews";
import CourseLandingTitleBanner from "./CourseLandingTitleBanner/CourseLandingTitleBanner";
import CourseLandingFeatures from "./CourseLandingFeatures/CourseLandingFeatures";
import CourseLandingUnlockCard from "./CourseLandingUnlockCard/CourseLandingUnlockCard";
import CourseStudyPlanBanner from "./CourseStudyPlanBanner/CourseStudyPlanBanner";
import CourseLandingExamPreview from "./CourseLandingExamPreview/CourseLandingExamPreview";
import Footer from "routes/Landing/Footer/Footer";
import useCourseLandingContext, {
  CourseLandingContext,
} from "routes/Course/CourseLanding/useCourseLandingContext";
import CourseLandingTopics from "routes/Course/CourseLanding/CourseLandingTopics/CourseLandingTopics";
import CourseLandingExercises from "routes/Course/CourseLanding/CourseLandingExercises/CourseLandingExercises";
import classnames from "classnames";
import CourseLandingDesktop from "routes/Course/CourseLanding/CourseLandingDesktop/CourseLandingDesktop";
import {useWindowSize} from "utilities/hooks/useWindowSize";
import CourseLandingCampaignCta from "routes/Course/CourseLanding/CourseLandingCampaignCTA/CourseLandingCampaignCTA";
import GroupDiscountsBanner from "Widgets/Group/GroupDiscounts/GroupDiscountsBanner/GroupDiscountsBanner";

const CourseLanding = () => {
  const courseLandingContext = useCourseLandingContext();
  const [wWidth] = useWindowSize();

  if (courseLandingContext.loading) return <PageLogoLoader inverted />;

  // Return Desktop (OLD) version when screen is bigger than 720px (>tablets)
  if (wWidth > 720) {
    return <CourseLandingDesktop />;
  }

  return (
    <CourseLandingContext.Provider
      value={{
        ...courseLandingContext,
      }}
    >
      <div className="py-2 md:py-5 relative">
        <ProductPurchaseExpiryBanner
          productType="COURSE"
          productId={courseLandingContext.course.id}
        />
        <div
          className={classnames(
            // "flex flex-col md:flex-row gap-4 ",
            "grid grid-cols-1 md:grid-cols-12",
            "gap-4",
            "p-2 md:p-8"
          )}
          style={{
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0), #EBE9FE 100%)",
          }}
        >
          <div className="hidden md:block md:col-span-2">
            <div className="sticky top-2">
              <div />
            </div>
          </div>

          <div className="md:col-span-7">
            <CourseLandingTitleBanner />

            <ErrorBoundary className="mt-4" hideOnError={true}>
              <GroupDiscountsBanner
                showDiscountPercentage={true}
                showActivateButton={true}
              />
            </ErrorBoundary>

            <CourseLandingExamPreview />

            <ErrorBoundary>
              <CourseLandingTopics />
            </ErrorBoundary>

            <ErrorBoundary>
              <CourseLandingExercises />
            </ErrorBoundary>

            <ErrorBoundary>
              <CourseLandingCampaignCta />
            </ErrorBoundary>

            <ErrorBoundary>
              <CourseStudyPlanBanner />
            </ErrorBoundary>

            <ErrorBoundary>
              <CourseLandingAllReviews />
            </ErrorBoundary>
            <CourseLandingUnlockCard />
            <ErrorBoundary>
              <CourseLandingFeatures />
            </ErrorBoundary>
          </div>

          {/**
            I have removed this because this is to account for both desktop and mobile, right this page is only for mobile.
          <div className="md:col-span-3">
            <div className="sticky top-2">
              <CourseLandingUnlockCard />
            </div>
          </div>
         */}
        </div>
        <CourseBuyBannerMobile courseId={courseLandingContext.course?.id} />
        <Footer />
      </div>
    </CourseLandingContext.Provider>
  );
};

export default CourseLanding;
