import React from "react";
import {useHistory} from "react-router-dom";
import {Card, Trans, Button, Typography, Badge} from "Components";
import useGetCourseExercises from "API/queries/exercise/useGetCourseExercises";

const CourseStatsExercisesTagged = () => {
  const {user} = React.useContext(window.UserContext);
  const {courseId, getCourseLink} = React.useContext(window.CourseContext);
  const history = useHistory();
  const exerciseLink = getCourseLink(`home?all=1&dir=asc&sort=score`);
  const [exercises, loading] = useGetCourseExercises({
    courseId,
    includeTags: true,
  });

  if (loading) return null;

  const exercisesCount = exercises?.length;
  const taggedExercisesCount = exercises?.filter(
    exercise => !!exercise?.tags?.length
  )?.length;

  return (
    <Card
      title={<Trans>Exercises tagged</Trans>}
      action={
        <div className="flex gap-2">
          <Button
            type="link"
            size="xs"
            disabled
            onClick={() => history.push(exerciseLink)}
          >
            <Trans>Tag more exercises</Trans>
          </Button>
          <Badge type="primary" size="sm">
            <Trans>Coming soon</Trans>
          </Badge>
        </div>
      }
    >
      <Typography type="dmd" className="p-2" weight="bold">
        {taggedExercisesCount} / {exercisesCount}
      </Typography>
    </Card>
  );
};

export default CourseStatsExercisesTagged;
