import Trans from "Components/Trans";
import OnboardingPersonalInfo from "./OnboardingPersonalInfo";
import OnboardingSchool from "./OnboardingSchool/OnboardingSchool";
import OnboardingProgram from "./OnboardingProgram/OnboardingProgram";
import React from "react";
import OnboardingEnrollmentYear from "routes/Onboarding/OnboardingEnrollmentYear";

export const ONBOARDING_STEPS = [
  {
    path: "/name",
    name: <Trans>Your details</Trans>,
    title: <Trans>Please enter your details</Trans>,
    description: (
      <Trans>
        This will make it easier for your friends to find you on Kollin
      </Trans>
    ),
    icon: "user-plus",
    Component: <OnboardingPersonalInfo />,
    nextStepPrompt: <Trans>Pick school</Trans>,
    key: "name",
  },
  {
    path: "/school",
    name: <Trans>School</Trans>,
    title: <Trans>Pick your school</Trans>,
    description: <Trans>Get recommended courses for your school</Trans>,
    Component: <OnboardingSchool />,
    icon: "building-columns",
    nextStepPrompt: <Trans>Choose program</Trans>,
    key: "school",
  },
  {
    path: "/program",
    name: <Trans>Program</Trans>,
    title: <Trans>Select your program</Trans>,
    description: <Trans>Get recommended courses for your program</Trans>,
    Component: <OnboardingProgram />,
    icon: "graduation-cap",
    nextStepPrompt: <Trans>Choose start year</Trans>,
    key: "program",
  },
  {
    path: "/year",
    name: <Trans>Start year</Trans>,
    title: <Trans>Which year did you start</Trans>,
    description: <Trans>Get recommended courses for your class</Trans>,
    Component: <OnboardingEnrollmentYear />,
    icon: "calendar-alt",
    nextStepPrompt: <Trans>Get started</Trans>,
    key: "year",
  },
];

export default ONBOARDING_STEPS;
