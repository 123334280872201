import React from "react";
import {
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  ClimbingBoxLoader,
  ClipLoader,
  ClockLoader,
  DotLoader,
  FadeLoader,
  GridLoader,
  HashLoader,
  MoonLoader,
  PacmanLoader,
  PropagateLoader,
  PuffLoader,
  PulseLoader,
  RingLoader,
  RiseLoader,
  RotateLoader,
  ScaleLoader,
  SkewLoader,
  SquareLoader,
  SyncLoader,
} from "react-spinners";

import PropTypes from "prop-types";
// https://www.davidhu.io/react-spinners/storybook/?path=/docs/cliploader--main
const Loader = ({
  color = "#6E8BE2",
  loading = true,
  speedMultiplier = 1,
  style,
  size,
  width,
  height,
  type = "Pulse",
}) => {
  const Component = React.useMemo(() => getLoader(type), [type]);

  return (
    (
      <Component
        cssOverride={style}
        color={color}
        loading={loading}
        speedMultiplier={speedMultiplier}
        size={size}
        width={width}
        height={height}
      />
    ) || null
  );
};

const getLoader = type => {
  switch (type) {
    case "BarLoader":
    case "Bar":
      return BarLoader;
    case "BeatLoader":
    case "Beat":
      return BeatLoader;
    case "BounceLoader":
    case "Bounce":
      return BounceLoader;
    case "CircleLoader":
    case "Circle":
      return CircleLoader;
    case "ClimbingBoxLoader":
    case "ClimbingBox":
      return ClimbingBoxLoader;
    case "ClipLoader":
    case "Clip":
      return ClipLoader;
    case "ClockLoader":
    case "Clock":
      return ClockLoader;
    case "DotLoader":
    case "Dot":
      return DotLoader;
    case "FadeLoader":
    case "Fade":
      return FadeLoader;
    case "GridLoader":
    case "Grid":
      return GridLoader;
    case "HashLoader":
    case "Hash":
      return HashLoader;
    case "MoonLoader":
    case "Moon":
      return MoonLoader;
    case "PacmanLoader":
    case "Pacman":
      return PacmanLoader;
    case "PropagateLoader":
    case "Propagate":
      return PropagateLoader;
    case "PuffLoader":
    case "Puff":
      return PuffLoader;
    case "PulseLoader":
    case "Pulse":
      return PulseLoader;
    case "RingLoader":
    case "Ring":
      return RingLoader;
    case "RiseLoader":
    case "Rise":
      return RiseLoader;
    case "RotateLoader":
    case "Rotate":
      return RotateLoader;
    case "ScaleLoader":
    case "Scale":
      return ScaleLoader;
    case "SkewLoader":
    case "Skew":
      return SkewLoader;
    case "SquareLoader":
    case "Square":
      return SquareLoader;
    case "SyncLoader":
    case "Sync":
      return SyncLoader;
    default:
      return PulseLoader;
  }
};

export const LoaderTypes = [
  "Bar",
  "Beat",
  "Bounce",
  "Circle",
  "ClimbingBox",
  "Clip",
  "Clock",
  "Dot",
  "Fade",
  "Grid",
  "Hash",
  "Moon",
  "Pacman",
  "Propagate",
  "Puff",
  "Pulse",
  "Ring",
  "Rise",
  "Rotate",
  "Scale",
  "Skew",
  "Square",
  "Sync",
];

Loader.propTypes = {
  type: PropTypes.oneOf(LoaderTypes),
};

export default Loader;
