import React from "react";
import useCourseKollinGPTSaved from "./useCourseKollinGPTSaved";
import {Icon, Loading, Trans} from "Components";
import CourseKollinGptSavedContent from "routes/Course/CourseKollinGPT/CourseKollinGPTSaved/CourseKollinGPTSavedContent";
import CourseKollinGptSavedAction from "routes/Course/CourseKollinGPT/CourseKollinGPTSaved/CourseKollinGptSavedAction";
import Widget from "Components/Widget/Widget";
import Pagination from "Components/_Elements/Pagination/Pagination";

const CourseKollinGPTSaved = ({
  title = <Trans>Saved conversations with Kollin-GPT</Trans>,
}) => {
  const [conversations, loading] = useCourseKollinGPTSaved();

  if (loading) return null;
  if (conversations.length === 0) return null;

  return (
    <Widget title={title}>
      <div className="flex flex-col gap-2">
        <Pagination items={conversations} pageSize={5}>
          {items =>
            items.map((conversation, index) => {
              return (
                <div
                  key={index}
                  className="py-4 border-b-2 border-gray w-full flex gap-4 items-start"
                >
                  {conversation.type === "note" ? (
                    <Icon icon="note" filled fillType="warning" size="md" />
                  ) : (
                    <Icon icon="messages" filled fillType="purple" size="md" />
                  )}

                  <div className="w-full grow overflow-hidden">
                    <CourseKollinGptSavedContent {...conversation} />
                    <div className="mt-4">
                      <CourseKollinGptSavedAction {...conversation} />
                    </div>
                  </div>
                </div>
              );
            })
          }
        </Pagination>
      </div>
    </Widget>
  );
};

export default CourseKollinGPTSaved;
