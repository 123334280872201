import React from "react";
import {ExamUploadContext} from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContext";
import {message, Trans} from "Components";
import useExamUploadSaveActionCreate from "routes/Course/ExamUpload/ExamUploadContext/ExamUploadSaveAction/useExamUploadSaveActionCreate";
import useExamUploadSaveActionUpdate from "routes/Course/ExamUpload/ExamUploadContext/ExamUploadSaveAction/useExamUploadSaveActionUpdate";
import validatedResources from "utilities/examUploadDocumentValidatedResources/examUploadDocumentValidatedResources";
import autoSegmentation from "API/lambdas/autoSegmentation";

const useExamUploadDocumentsRowStateActions = ({
  document,
  date,
  author,
  conflictsResolved,
}) => {
  const {id: courseId} = React.useContext(window.CourseContext);
  const {
    updateResource,
    examsByDate,
    documents,
    togglePreview,
  } = React.useContext(ExamUploadContext);

  const exam = examsByDate[date];

  const onResourceSaved = resource => {
    updateResource({...resource, saved: true});
  };

  const handleUpdate = useExamUploadSaveActionUpdate(onResourceSaved);
  const handleCreate = useExamUploadSaveActionCreate(onResourceSaved);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    // Reset the error when the document changes
    setError(null);
  }, [document]);

  const afterSave = () => {
    // Go to the next documents first file
    const dates = Object.keys(documents.dates);

    const index = dates.indexOf(date);
    for (let i = index + 1; i < dates.length; i++) {
      const exam = documents.dates[dates[i]];
      if (!exam) continue;

      if (exam.hidden || exam.saved) {
        continue;
      }
      const files = exam.files;
      if (!files || files.length === 0) continue;
      // Go through each file until a non-saved non-hidden is found
      for (const file of files) {
        if (!file.hidden && !file.saved) {
          togglePreview(file);
          return;
        }
      }
    }
  };

  const onSave = async (goToNext = true) => {
    const {
      exercisesResource,
      solutionsResource,
      error: validationError,
    } = validatedResources(exam, document);

    if (validationError) {
      setError(validationError);
      return;
    }

    setSaveLoading(true);

    try {
      if (exam) {
        await handleUpdate({
          exam,
          exercisesResource,
          solutionsResource,
          author,
        });
      }
      // If the exam doesn't exist, create it
      else {
        await handleCreate({
          date,
          exercisesResource,
          solutionsResource,
          author,
        });
      }
    } catch (e) {
      message.error(<Trans>Failed to save exam</Trans>);
    } finally {
      setSaveLoading(false);
      if (goToNext) {
        afterSave();
      }
    }
  };

  /**
   * Auto save the documents when the files change
   */

  React.useEffect(() => {
    if (!document.saved && document.files?.length > 0) {
      // Wait for the author to be set
      if (author == null || !conflictsResolved) return;

      onSave(false);
    }
  }, [document.files, author, conflictsResolved]);

  /**
   * Run autobot when document is saved and there is an exam.id
   *
   */

  React.useEffect(() => {
    if (exam?.id && exam?.exercises.length === 0) {
      console.log("Running autobot for exam", exam.id);

      autoSegmentation({
        sourceId: exam.id,
        courseId,
        commit: true,
      });
    }
  }, [exam]);

  return {
    saveLoading,
    onSave,
    saveDisabled: false,
    error,
  };
};

export default useExamUploadDocumentsRowStateActions;
