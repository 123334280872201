import React from "react";
import useVerifyOTP from "API/mutations/OTP/useVerifyOTP";
import {useFormValidation} from "utilities/hooks/useFormValidation";
import {emptyValidator} from "utilities/validators";
import {useTranslation} from "react-i18next";

const UseOTPVerification = ({email, action, onComplete}) => {
  const {t: trans} = useTranslation();
  const {loading, result, verifyOTP} = useVerifyOTP();

  const state = useFormValidation(
    {
      email,
      action,
      code: "",
    },
    verifyOTP,
    emptyValidator
  );

  // Submit the code when code is changed also
  React.useEffect(() => {
    if (state.values.code.length === 6) {
      state.submit();
    }
  }, [state.values.code]);

  // Automatically call the onComplete callback when the result is ok
  React.useEffect(() => {
    if (!result) return;

    if (result.ok) {
      setTimeout(() => {
        onComplete(result.response);
      }, 1500);
      return;
    }

    if (result.error) {
      state.setErrors({code: trans(result.error.message)});
    }
  }, [result]);

  const verificationSuccessful = result && result.ok;

  return {
    ...state,
    loading,

    verificationSuccessful,
  };
};

export default UseOTPVerification;
