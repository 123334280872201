import React from "react";
import useGroupNavigationRoutes from "routes/Group/GroupNavigation/useGroupNavigationRoutes";
import GroupNavigationSidebar from "routes/Group/GroupNavigation/GroupNavigationSidebar/GroupNavigationSidebar";
import {AnimateSharedLayout, motion} from "framer-motion";
import classnames from "classnames";
import useGroupNavigationState from "routes/Group/GroupNavigation/useGroupNavigationState";

const GroupNavigation = ({children, routes}) => {
  const {user} = React.useContext(window.UserContext);

  const {allowedRoutes, groupHidden} = useGroupNavigationRoutes({routes});

  const {collapsed, hideSidebar} = useGroupNavigationState(routes);

  if (groupHidden || hideSidebar) {
    return children;
  }

  return (
    <AnimateSharedLayout layoutId="group-sidebar">
      <div
        id="group-sidebar"
        className={classnames(
          "grid md:grid-rows-1",
          collapsed ? " bg-gray-800" : "bg-gray-700",
          collapsed
            ? "grid-cols-1 md:grid-cols-[4rem_minmax(0,1fr)] "
            : "grid-cols-1 md:grid-cols-[200px_minmax(0,1fr)] lg:grid-cols-[225px_minmax(0,1fr)] "
          // hideSidebar ? "hidden" : "block"
        )}
      >
        <div
          className={classnames(
            "group/sidebar relative z-10"
            // hideSidebar ? "hidden" : "block"
          )}
          layout="position"
          key="sidebar"
          // transition={{delay: collapsed ? 0.25 : 0, type: "tween"}}
        >
          <GroupNavigationSidebar
            routes={allowedRoutes}
            width={classnames("w-full", getWidth(collapsed, hideSidebar))}
            collapsed={collapsed}
            menuItemProps={{
              background: collapsed
                ? "bg-gray-800 outline-gray-700"
                : "bg-gray-700 outline-gray-600",
              color: collapsed ? "text-gray-200" : "text-white",
              iconColor: collapsed ? "text-gray-200" : "text-white",
              selectedBackground: collapsed
                ? "bg-gray-700 outline-gray-600"
                : "bg-gray-600 outline-gray-500",
              selectedColor: "text-gray-100",
              iconSelectedColor: "text-gray-100",
            }}
          />
        </div>

        <div
          layout="position"
          key="course-main-panel"
          className={classnames(
            "grid min-h-screen background overflow-clip",
            "grid-flow-row md:grid-flow-col",
            collapsed
              ? "px-0 mt-0"
              : "mt-2 p-2 md:mt-4 md:p-4 md:rounded-tl-2xl",
            collapsed
              ? "grid-cols-1 md:auto-cols[1fr_1fr] md:auto-cols-[minmax(50%,_100%)_30%] lg:auto-cols-[minmax(50%,_100%)_35%]"
              : "grid-cols-1 md:auto-cols[1fr_1fr] md:auto-cols-[minmax(50%,_100%)_25%] lg:auto-cols-[minmax(50%,_100%)_30%]" // a little bit smaller when sidebar is expanded
          )}
          // transition={{delay: collapsed ? 0.25 : 0, type: "tween"}}
          style={{
            // box shadow on the right side of the sidebar
            boxShadow: collapsed ? "none" : "-2px 0px 5px 0px #00000050",
          }}
        >
          <div className="w-full">{children}</div>
        </div>
      </div>
    </AnimateSharedLayout>
  );
};

const getWidth = (collapsed, hideSidebar) => {
  if (hideSidebar) return "w-0";
  if (collapsed) return "md:w-16";
  return "md:w-[200px] lg:w-[225px]";
};

export default GroupNavigation;
