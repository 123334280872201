import React from "react";
import {
  Button,
  CourseCard,
  Input,
  Link,
  Section,
  Trans,
  Typography,
} from "Components";
import useMyCourses from "routes/MyCourses/useMyCourses";
import MyCoursesWidget from "routes/MyCourses/MyCoursesWidget";
import useGetCurrentUserStats from "API/queries/user/useGetCurrentUserStats";
import MyCoursesArchive from "routes/MyCourses/MyCoursesArchive";
import useCoursesSearchState from "routes/MyCourses/useCoursesSearchState";

import {useTranslation} from "react-i18next";
import {AnimateSharedLayout, motion} from "framer-motion";
import DashboardCoursesProgramEmptyState from "routes/Dashboard/DashboardCourses/DashboardCoursesProgram/DashboardCoursesProgramEmptyState";
import MyCoursesEmpty from "routes/MyCourses/MyCoursesEmpty";

const MyCourses = () => {
  const {t: trans} = useTranslation();

  const {
    activeCourses,
    activeCoursesCount,
    archivedCourses,
    activatedCoursesLoading,
  } = useMyCourses();

  const {
    filteredCourses,
    searchHidden,
    handleSearchValueChange,
    searchValue,
  } = useCoursesSearchState({
    courses: activeCourses,
    loading: activatedCoursesLoading,
  });

  const [stats, loading] = useGetCurrentUserStats();

  return (
    <Section
      title={<Trans>My courses</Trans>}
      subTitle={
        <Trans>Here you can find all your active and archived courses</Trans>
      }
      action={
        <Link
          to="/find-courses"
          buttonType="primary"
          iconLeft="telescope"
          size="lg"
        >
          <Trans>Find courses</Trans>
        </Link>
      }
    >
      <div className="grid grid-cols-12 gap-4 ">
        <div className="order-last lg:order-1 lg:order-1 col-span-12 lg:col-span-6">
          {!searchHidden && (
            <Input
              onChange={handleSearchValueChange}
              value={searchValue}
              placeholder={trans("Search among your courses")}
              iconRight="search"
              className="mb-4"
            />
          )}
          <AnimateSharedLayout>
            {filteredCourses.length === 0 && <MyCoursesEmpty />}
            {filteredCourses.map((course, i) => (
              <motion.div
                layout
                key={course.id}
                initial={{opacity: 0, x: 0, y: 0}}
                animate={{
                  x: 0,
                  y: 0,
                  opacity: course.hidden ? 0.5 : 1,
                }}
                className="transform-none"
              >
                <CourseCard
                  {...course}
                  {...course.currentUser} // To add the props "active" and "archived"
                  schoolName={course.school?.name}
                  highlightText={searchValue}
                />
              </motion.div>
            ))}
          </AnimateSharedLayout>
          <MyCoursesArchive archivedCourses={archivedCourses} />
        </div>
        <div className="lg:order-1 col-span-12 lg:col-span-6 flex flex-col gap-4">
          <div className="flex gap-2">
            <MyCoursesWidget
              type="purple"
              title={<Typography>{activeCourses.length}</Typography>}
              icon="wave-pulse"
              subTitle={<Trans>Active courses</Trans>}
            />
            <MyCoursesWidget
              type="stone"
              title={<Typography>{archivedCourses.length}</Typography>}
              icon="archive"
              subTitle={<Trans>Archived courses</Trans>}
            />
          </div>
          {stats && (
            <div className="flex gap-2">
              <MyCoursesWidget
                type="success"
                title={<Typography>{stats.completedExercisesCount}</Typography>}
                icon="list-check"
                subTitle={<Trans>Completed exercises</Trans>}
              />
              <MyCoursesWidget
                type="amber"
                title={
                  <Typography>
                    {stats.averageRating?.toFixed(2) || "--"}
                  </Typography>
                }
                icon="star"
                subTitle={<Trans>Average difficulty</Trans>}
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default MyCourses;
