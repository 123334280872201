import React from "react";
import {CourseContext} from "../CourseContextProvider";
import {UserContext} from "UserContextProvider";
import CourseReviewForm from "Components/CourseReviewForm/CourseReviewForm";
import useCreateReviewMutation from "API/mutations/review/useCreateReviewMutation";
import {useFormValidation} from "utilities/hooks/useFormValidation";
import {validateCourseReview} from "utilities/validators";
import useUpdateCourseReviewMutation from "API/mutations/review/useUpdateCourseReviewMutation";
import Trans from "Components/Trans";
import useLocalStorageState from "utilities/hooks/useLocalStorageState";
import useLastExerciseInCourse from "utilities/hooks/useLastExerciseInCourse";
import {Icon, message} from "Components";
import Widget from "Components/Widget/Widget";
import useGetCurrentUserCourseReview from "API/queries/reivew/useGetCurrentUserCourseReview";

const CourseReviewContainer = () => {
  const {user} = React.useContext(UserContext);
  const {courseId, price, courseName, isCommunityCourse} = React.useContext(
    CourseContext
  );

  const state = useCreateReviewState({id: courseId});
  const [currentUsersReview, loading] = useGetCurrentUserCourseReview();

  if (
    !user ||
    loading ||
    (currentUsersReview && currentUsersReview.id) ||
    isCommunityCourse
  ) {
    return null;
  }

  return (
    <Widget
      icon="fa-solid fa-stars"
      feedbackEnabled={false}
      title={<Trans>Post a review</Trans>}
      description={price > 0 && <Trans>10 credits</Trans>}
    >
      <Trans className="mt-4" type="tsm">
        Share your experience with the course and give valuable information to
        future students
      </Trans>
      <CourseReviewForm state={state} />
    </Widget>
  );
};

export const useCreateReviewState = (
  {id, score = 5, title = "", text = ""},
  courseId
) => {
  const {createCourseReview, loading} = useCreateReviewMutation(id, data => {
    if (data.createCourseReview.ok) {
      message.success(<Trans>Your review has been posted</Trans>);
    }
  });

  const {updateCourseReview} = useUpdateCourseReviewMutation(courseId, data => {
    if (data.updateCourseReview.ok) {
      message.success(
        <span>
          <Trans>Thank you!</Trans> {"! "}
          <Trans>Your review has been updated</Trans>
        </span>
      );
    }
  });

  const callback = courseId ? updateCourseReview : createCourseReview;

  const state = useFormValidation(
    {
      score: score,
      title: title,
      text: text,
      courseId: id,
    },
    callback,
    validateCourseReview
  );
  return {...state, loading};
};

export default CourseReviewContainer;
