import React from "react";
import GeogebraHighlightMath from "Components/Geogebra/GeogebraHighlight/GeogebraHighlightMath";

const GeogebraHighlight = ({text, mathRef}) => {
  // inline math is $ and $$, we need to take all text and split into an array of strings where we separate the math from the text
  // then we can map over the array and render the text and math separately

  const seperatedText = getMathSeperatedText(text);

  let Content = seperatedText.map((item, index) => {
    // If item starts with $ and ends with $ then it is  math
    if (item.startsWith("$") && item.endsWith("$")) {
      const block = item.startsWith("$$");

      return <GeogebraHighlightMath math={item} block={block} />;
    }

    return <span key={index} dangerouslySetInnerHTML={{__html: item}} />;
  });

  return (
    <div ref={mathRef} className="leading-loose">
      {Content}
    </div>
  );
};

const getMathSeperatedText = text => {
  const splitText = text.split(/(\$)/).filter(item => item !== "");

  const newText = [];

  // Loop through the splitText array and check if the item is a $, if it is then we need to check if the next item is also a $, if it is then we need to add the next item to the current item and push it to the newText array
  let currentText = "";
  for (let i = 0; i < splitText.length; i++) {
    if (splitText[i] === "$") {
      // If next is also $
      if (
        splitText[i + 1] === "$" &&
        splitText[i + 2] !== undefined &&
        splitText[i + 3] !== undefined &&
        splitText[i + 4] !== undefined
      ) {
        currentText += splitText[i]; // $
        currentText += splitText[i + 1]; // $
        currentText += splitText[i + 2]; // math
        currentText += splitText[i + 3]; // $
        currentText += splitText[i + 4]; // $
        newText.push(currentText);
        currentText = "";
        i += 4;
        continue;
      }

      if (splitText[i + 1] !== undefined && splitText[i + 2] !== undefined) {
        currentText += splitText[i]; // $
        currentText += splitText[i + 1]; // math
        currentText += splitText[i + 2]; // $
        newText.push(currentText);
        currentText = "";
        i += 2;
        continue;
      }
    }
    newText.push(splitText[i]);
  }

  return newText;
};

export default GeogebraHighlight;
