import React from "react";
import {Icon} from "Components/_Elements";

const NotificationElementIcon = ({icon, fillType}) => {
  if (!icon) return null;
  if (typeof icon === "string")
    return (
      <Icon
        size="md"
        icon={icon}
        color="text-primary-light"
        filled
        fillType={fillType}
      />
    );

  return icon;
};

export default NotificationElementIcon;
