import React from "react";
import PlaylistHeader from "Components/PlaylistHeader/PlaylistHeader";
import Workboard from "Components/Workboard/Workboard";

const CourseTagExercises = ({
  exercises,
  tag,
  key,
  loading,
  showExerciseViewHeader = false,
}) => {
  return (
    <div>
      <PlaylistHeader
        exercises={exercises}
        showExerciseList={true}
        showTabs={true}
        showTitle={false}
        showBreadcrumbs={false}
      />
      <Workboard
        exercises={exercises}
        tagId={tag?.id}
        hierarchyLevel={tag?.name}
        exerciseListColumns={["progressBead", "date", "number", "author"]}
        orientation="vertical"
        displayMode="grid"
        showExerciseViewHeader={showExerciseViewHeader}
      />
    </div>
  );
};

export default CourseTagExercises;
