import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {sortObjectKeys} from "utilities/objects";

export const GET_REACTIONS = gql`
  query getReactions($parentId: ID!, $parentType: String!) {
    getReactions(parentId: $parentId, parentType: $parentType) {
      id
      name
      user {
        id
      }
    }
  }
`;

const useGetReactions = args => {
  let reactions = [];

  let distribution = {};
  let nReactions = 0;
  const {loading, error, data} = useQuery(GET_REACTIONS, {
    variables: {...args},
    context: {
      batch: true,
    },
  });

  if (error) {
    console.log("Error in useGetReactions", error);
  }

  if (data) {
    reactions = data.getReactions;
    nReactions = reactions.length;
    reactions.forEach(reaction => {
      if (!distribution[reaction.name]) {
        distribution[reaction.name] = [];
      }
      distribution[reaction.name].push(reaction);
    });

    // Sort keys of distribution by name
    distribution = sortObjectKeys(distribution, (a, b) => a.localeCompare(b));
  }

  return {
    reactions,
    distribution,
    nReactions,
    loading,
  };
};

export default useGetReactions;
