import React from "react";
import useKeyboardShortcuts from "utilities/hooks/useKeyboardShortcuts";

const useExamMarkingSegmentsKeyboardNavigation = ({
  segments,
  setSegmentFocus,
  currentSegmentIndex,
  createSegment,
}) => {
  const handlePrevious = () => {
    if (currentSegmentIndex > 0) {
      setSegmentFocus(segments[currentSegmentIndex - 1], true);
    }
  };

  const handleNext = () => {
    if (currentSegmentIndex < segments.length - 1) {
      setSegmentFocus(segments[currentSegmentIndex + 1], true);
    }
  };

  useKeyboardShortcuts(
    [
      {
        key: "ArrowRight",
        action: handleNext,
      },
      {
        key: "ArrowLeft",
        action: handlePrevious,
      },
      {
        key: "t",
        action: createSegment,
      },
    ],
    [currentSegmentIndex, segments]
  );
};

export default useExamMarkingSegmentsKeyboardNavigation;
