import {BatchHttpLink} from "apollo-link-batch-http";
import {uri} from "./constants";
/**
 *
 * If you use BatchHttpLink instead of HttpLink as your terminating link,
 * Apollo Client automatically batches executed GraphQL operations and
 * transmits them to your server according to the batching options you provide.
 *
 * Batching is used to improve performance and reduce the number of HTTP requests
 */

const batchHttpLink = new BatchHttpLink({
  uri,
  headers: {batch: "true"},
  batchInterval: 200, // maximum number of milliseconds between two requests
  batchMax: 30, // max number of requests in a batch
  batchDebounce: true, // Allows the batch interval to be debounced
});

export default batchHttpLink;
