import React from "react";
import {Badge} from "Components";

const TabsPaneBadge = ({badge, type, size, selected}) => {
  if (!badge) return null;

  const badgeTypes = {
    button: "secondary",
    "button-gray": "gray",
    underline: "primary",
    "underline-filled": "gray",
  };

  return (
    <Badge type={badgeTypes[type]} size="md">
      {badge}
    </Badge>
  );
};

export default TabsPaneBadge;
