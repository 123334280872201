import React from "react";
import classnames from "classnames";
import PaginationPageButtons from "Components/_Elements/Pagination/PaginationPageButtons";
import {Button, ButtonGroup} from "Components/_Elements/index";
import {Trans} from "Components/index";

const PaginationControls = ({
  pages,
  showPageButtons,
  currentPage,
  onPageIndexClick,
  previousPageButtonDisabled,
  nextPageButtonDisabled,
  onPreviousPageClick,
  onNextPageClick,
  size = "md",
  justify = "spread",
  buttonProps = {},
}) => {
  return (
    <div
      className={classnames(
        "w-full flex gap-4 items-center",
        justifyClasses[justify],
        "my-2"
      )}
    >
      {/*
        TODO: send in buttonProps and  to PaginationPageButtons
      */}
      <PaginationPageButtons
        pages={pages}
        showPageButtons={showPageButtons}
        currentPage={currentPage}
        onPageIndexClick={onPageIndexClick}
        buttonProps={buttonProps}
      />

      <ButtonGroup>
        <Button
          type="gray"
          size={size}
          disabled={previousPageButtonDisabled}
          onClick={onPreviousPageClick}
          iconLeft="arrow-left"
          {...buttonProps}
        >
          <Trans>Previous</Trans>
        </Button>

        <Button
          type="gray"
          size={size}
          disabled={nextPageButtonDisabled}
          onClick={onNextPageClick}
          iconRight="arrow-right"
          {...buttonProps}
        >
          <Trans>Next</Trans>
        </Button>
      </ButtonGroup>
    </div>
  );
};

const justifyClasses = {
  center: "justify-center",
  left: "justify-start",
  right: "justify-end",
  spread: "justify-between",
};

export default PaginationControls;
