import React from "react";
import useGetUserChapters from "API/queries/chapter/useGetUserChapters";
import {compact} from "lodash";

const useCourseKollinGPTSavedChapters = () => {
  const {courseId} = React.useContext(window.CourseContext);

  const [unFilteredChapters, loading] = useGetUserChapters(courseId);

  const chapters = React.useMemo(() => {
    if (loading) return [];

    let kollinGPTChapters = unFilteredChapters.filter(
      chapter => chapter.chapterType === "Kollin-GPT"
    );

    kollinGPTChapters = kollinGPTChapters.map(chapter => {
      let content = getChapterContent(chapter);

      if (!content) return null;

      content = content.filter(
        content => content.role === "user" || content.role === "assistant"
      );

      content.sort((a, b) => a.timestamp - b.timestamp);

      let title = content[0].content;
      let body = content
        .slice(1)
        .map(({content}) => content)
        .join("\n");

      delete chapter.html;
      delete chapter.markdown;

      return {
        ...chapter,
        content,
        title,
        body,
        contentType: "JSON",
      };
    });

    // remove null
    return compact(kollinGPTChapters);
  }, [loading, unFilteredChapters]);

  return [chapters, loading];
};

export const getChapterContent = chapter => {
  try {
    if (chapter.html) {
      return JSON.parse(chapter.html);
    }
    if (chapter.markdown) {
      return JSON.parse(chapter.markdown);
    }
  } catch (e) {
    return null;
  }

  return null;
};

export default useCourseKollinGPTSavedChapters;
