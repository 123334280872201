import React from "react";
import useGetCurrentUserSettings, {
  GET_CURRENT_USER_SETTINGS,
} from "API/queries/user/useGetCurrentUserSettings";
import {SubSection, Toggle, Trans} from "Components";
import useUpdateCurrentUserMutation from "../../../API/mutations/user/useUpdateCurrentUserMutation";

const AccountSettingsSubscriptionCredits = props => {
  const [settings, settingsLoading] = useGetCurrentUserSettings();

  // Mutation for updating the user's settings
  const [
    updateUserMutation,
    updateLoading,
  ] = useUpdateCurrentUserMutation(() => {}, [
    {query: GET_CURRENT_USER_SETTINGS},
  ]);

  const onChange = checked => {
    updateUserMutation({
      variables: {
        settings: {
          useCreditsForSubscription: checked,
        },
      },
    });
  };

  if (settingsLoading) return null;

  return (
    <SubSection
      title={<Trans>Use credits for subscription</Trans>}
      subTitle={
        <Trans>
          If you have Kollin credits, they will be used on your next
          subscription payment.
        </Trans>
      }
    >
      <Toggle
        name="useCreditsForSubscription"
        label={
          settings.useCreditsForSubscription ? (
            <Trans>Activated</Trans>
          ) : (
            <Trans>Activate</Trans>
          )
        }
        disabled={settingsLoading || updateLoading}
        defaultChecked={settings.useCreditsForSubscription}
        onChange={onChange}
      />
    </SubSection>
  );
};

export default AccountSettingsSubscriptionCredits;
