/**
 * Formats a full name to a first name and last name
 *
 * @param fullName - The full name to format
 * @returns {{firstName: string, lastName: string?}} - key: lastName may be undefined if the full name is only one word
 */

export const formatFullName = fullName => {
  const [firstName, ...rest] = fullName.trim().split(" ");

  let lastName = rest.join(" ").trim();

  if (lastName.length === 0) {
    return {
      firstName,
    };
  }

  return {
    firstName,
    lastName,
  };
};
