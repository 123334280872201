import React from "react";
import {GeogebraContext, Math, Trans} from "Components";
import PropTypes from "prop-types";
import useGetTextData from "API/queries/textData/useGetTextData";

const TextDataDisplay = ({parentType, parentId, onFail, timeout = 5000}) => {
  const [textData, loading] = useGetTextData({
    parentId: parentId.toString(),
    parentType: parentType,
  });

  // Handle the timeout
  React.useEffect(() => {
    if (timeout) {
      const timeoutId = setTimeout(() => {
        if (!textData && onFail) {
          onFail();
        }
      }, timeout);
      return () => clearTimeout(timeoutId);
    }
  }, [timeout, textData]);

  if (loading) return <Trans>Loading...</Trans>;

  if (!textData) {
    return <Trans>No text found, try image mode instead</Trans>;
  }

  const text = textData.text
    .replace(/</g, "&lt;") // Escape < to prevent conflicts with HTML
    .replace(/\n/g, "<br />")
    .replace(/(\$*\(\d+.{0,10}p}?\)\$*)/g, "")
    .replace(/\$\(\\mathbf\{(\d)\} \\mathbf\{p\}\)\$/g, "");

  return (
    <div className="theory-display math text-body2 md:text-h6">
      <Math key={textData.id} text={text} />
    </div>
  );
};

TextDataDisplay.propTypes = {
  // The type of the parent object
  parentType: PropTypes.string.isRequired,
  // The id of the parent object
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  // Callback for when the text data fails to load
  onFail: PropTypes.func,
  //  Timeout for the text data query (how long to wait before giving up)
  timeout: PropTypes.number,
};

export default React.memo(TextDataDisplay);
