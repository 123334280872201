import React from "react";
import { Summer2022CampaignAuthContainer } from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignAuth";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import { Icon, Link, Typography } from "Components";
import Summer2022CampaignAuthRecurringRedeemStudentVerification from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignAuthRecurring/Summer2022CampaignAuthRecurringRedeemStudentVerification";
import { Summer2022CampaignProgramContext } from "routes/Campaign/Summer2022Campaign/Summer2022Campaign";
import useGetCoupon from "API/queries/coupon/useGetCoupon";
import useRedeemCreditCouponMutation from "API/mutations/user/useRedeemCreditCouponMutation";
import { useHistory } from "react-router-dom";

const Summer2022CampaignAuthRecurringRedeem = () => {
  // Get programKey to find coupon
  const { programKey, programLogo } = React.useContext(
    Summer2022CampaignProgramContext
  );

  // Get the coupon from the database
  const [coupon, couponLoading] = useGetCoupon({ name: programKey });

  const [user, userLoading] = useGetCurrentUser();

  if (userLoading || couponLoading) {
    return <Summer2022CampaignAuthContainer title="Var vänlig vänta..." />;
  }

  if (coupon?.id == undefined) {
    return (
      <Summer2022CampaignAuthContainer title="Hoppsan! Ett fel har uppstått!">
        <Typography color="text-gray-50">
          Erbjudandet hittades inte eller har utgått
        </Typography>
        <div className="flex items-center gap-2 my-4">
          <Link inverted to="/contact" buttonType="gray">
            Kontaktformulär
          </Link>
          <Link inverted to="/home" buttonType="secondary">
            Gå till startsidan
          </Link>
        </div>
      </Summer2022CampaignAuthContainer>
    );
  }

  // First the user needs to verify their student status
  if (!user?.student_email) {
    return <Summer2022CampaignAuthRecurringRedeemStudentVerification />;
  }

  return <Summer2022CampaignAuthRecurringAutoRedeem coupon={coupon} />;
};

const Summer2022CampaignAuthRecurringAutoRedeem = ({ coupon }) => {
  const history = useHistory();
  const [error, setError] = React.useState(null);

  const onCompleted = data => {
    if (data?.redeemCoupon?.ok) {
      history.push("/home");
    } else if (data?.redeemCoupon?.error) {
      setError(data?.redeemCoupon?.error);
    }
  };

  // Hook for redeeming coupon
  const [redeemCoupon, loading] = useRedeemCreditCouponMutation(onCompleted);

  // Redeem the coupon
  React.useEffect(() => {
    if (coupon?.id) {
      // TODO: Change to coupon.name
      redeemCoupon({ variables: { name: coupon.name } });
    }
  }, []);

  if (error) {
    return (
      <Summer2022CampaignAuthContainer title="Hoppsan! Ett fel har uppstått!">
        <Typography color="text-gray-50">{error}</Typography>
        <div className="flex items-center gap-2 my-4">
          <Link inverted to="/contact" buttonType="gray">
            Kontaktformulär
          </Link>
          <Link inverted to="/home" buttonType="secondary">
            Gå till startsidan
          </Link>
        </div>
      </Summer2022CampaignAuthContainer>
    );
  }
  return (
    <Summer2022CampaignAuthContainer title="Aktiverar ditt erbjudande">
      <div className="flex justify-center my-4">
        <Icon icon="spinner" spin size="2xl" color="text-gray-50" />
      </div>
    </Summer2022CampaignAuthContainer>
  );
};
export default Summer2022CampaignAuthRecurringRedeem;
