import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

const useGetCurrentUser = (
  includeStatsParam = false,
  includeMembership = false
) => {
  let user = {};

  let includeStats = false;

  if (includeStatsParam) {
    includeStats = includeStatsParam;
  }

  const {data, loading, error, refetch} = useQuery(GET_CURRENT_USER, {
    variables: {
      includeStats,
      includeMembership,
    },
    fetchPolicy: "cache-first",
    errorPolicy: "all",
    context: {
      batch: true,
    },
  });

  // if (error) {
  //   console.info(`Error in useGetCurrentUser: ${error}`);
  // }

  if (data && data.currentUser) {
    user = {...data.currentUser};
    user.firstName = user.first_name;
    user.lastName = user.last_name;
    user.enrollmentYear = user.enrollment_year;
    user.schoolId = user.school && user.school.id;
    user.programId = user.program && user.program.id;

    /**
     *  remove snake_case fields because they cause troubles.
     *  When submitting an update mutation,
     *  we normally spread all the user's values into the mutation variables.
     *  This causes the snake_case fields to be sent to the backend,
     *  which causes the old values to be sent again and not the edited ones
     */
    delete user.first_name;
    delete user.last_name;
    delete user.enrollment_year;
  }

  return [user, loading, refetch];
};

export const GET_CURRENT_USER = gql`
  query currentUser($includeStats: Boolean!, $includeMembership: Boolean!) {
    currentUser {
      id
      assistant
      admin
      avatar
      phoneNumber
      first_name
      last_name
      email
      createdAt
      premium
      subscriber
      credits
      enrollment_year
      student_email
      passwordSet
      membership @include(if: $includeMembership) {
        id
        status
      }
      school {
        id
        name
      }
      program {
        id
        name
      }
      activeCoursesCount @include(if: $includeStats)
      completedExercisesCount @include(if: $includeStats)
      averageRating @include(if: $includeStats)
    }
  }
`;

export default useGetCurrentUser;
