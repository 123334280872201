import React from "react";
import useGetCourseExercises from "API/queries/exercise/useGetCourseExercises";
import Metric from "Components/_Elements/Metric/Metric";

import {Trans} from "Components";
import useGetManyUserEvaluations from "API/queries/userEvaluation/useGetManyUserEvaluations";
import useCourseUserStatsHistoricalData from "Widgets/Course/CourseUserStats/useCourseUserStatsHistoricalData";
import {sum} from "lodash";
import MetricLoading from "Components/_Elements/Metric/MetricLoading";

const CourseUserStatsTimeSpentToday = ({size = "md"}) => {
  const {courseId} = React.useContext(window.CourseContext);

  const [exercises, loading] = useGetCourseExercises({
    courseId,
  });

  const userEvaluationArgs = exercises.map(e => ({
    objectId: e.id,
    objectName: "Exercise",
    source: "Workboard",
  }));

  const [evaluations, evaluationsLoading] = useGetManyUserEvaluations(
    userEvaluationArgs,
    true
  );

  const {
    data,
    valueToday,
    valueChangePercentage,
    chartColorScheme,
    valueChangeLabel,
    allValuesEmpty,
  } = useCourseUserStatsHistoricalData({
    data: evaluations.flat(),
    groupByProperty: "createdAt",
    groupAggregateFunction: groupArray =>
      sum(groupArray.map(e => e.evaluation?.backgroundTimer)) / 60,
  });

  if (loading || evaluationsLoading) return <MetricLoading size={size} />;

  if (evaluationsLoading || allValuesEmpty) return null;

  return (
    <Metric
      size={size}
      title={<Trans>Time spent today</Trans>}
      value={`${valueToday} min`}
      changePercentage={valueChangePercentage}
      changeLabel={valueChangeLabel}
      chartType="line"
      colors={chartColorScheme}
      gradient={true}
      data={[
        {
          id: "all",
          data,
        },
      ]}
      feedbackName="CourseUserStatsTimeSpentToday"
    />
  );
};

export default CourseUserStatsTimeSpentToday;
