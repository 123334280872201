import React from "react";
import useGetExerciseSolution from "API/queries/solution/useGetExerciseSolution";
import useGetTextData from "API/queries/textData/useGetTextData";

const useGPTMetadataExercise = ({exerciseId, metadata, updateMetadata}) => {
  const [solution, solutionLoading] = useGetExerciseSolution(exerciseId); // To get the solution Id

  const [exerciseTextData, exerciseTextDataLoading] = useGetTextData({
    parentId: exerciseId?.toString(),
    parentType: "exercise",
  });

  const [solutionTextData, solutionTextDataLoading] = useGetTextData({
    parentId: solution?.id?.toString(),
    parentType: "solution",
  });

  React.useEffect(() => {
    if (
      exerciseTextDataLoading === false && // If the exercise text data is not loading
      metadata.exerciseText == null && // If the exercise text is already set, don't update it
      exerciseTextData?.text !== null // Sometimes the text is null this is to avoid infinite loop
    ) {
      updateMetadata({exerciseText: exerciseTextData?.text || "", exerciseId});
    }
  }, [exerciseTextData, exerciseTextDataLoading]);

  React.useEffect(() => {
    if (
      !solutionLoading && // Solution is not loading
      !solutionTextDataLoading && // Solution text data is not loading
      metadata?.solutionId != solution?.id // If the solution id is already set, don't update it
    ) {
      updateMetadata({
        solutionText: solutionTextData?.text || "", // Sometimes the text is null this is to avoid infinite loop
        solutionId: solution?.id,
      });
    }
  }, [solution?.id, metadata, solutionTextData, solutionTextDataLoading]);

  return solutionLoading || solutionTextDataLoading || exerciseTextDataLoading;
};

export default useGPTMetadataExercise;
