import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

const CREATE_AUTO_MARKING_LOG = gql`
  mutation createAutoMarkingLog(
    $courseId: Int!
    $segmentType: String!
    $documentId: ID!
    $settings: JSON!
    $segmentsCount: Int!
    $segmentPattern: JSON!
    $verified: Boolean!
  ) {
    createAutoMarkingLog(
      courseId: $courseId
      segmentType: $segmentType
      documentId: $documentId
      settings: $settings
      segmentsCount: $segmentsCount
      segmentPattern: $segmentPattern
      verified: $verified
    ) {
      ok
      error {
        message
        code
      }
      autoMarkingLog {
        id
        documentId
        segmentType
      }
    }
  }
`;

const useCreateAutoMarkingLog = ({refetchQueries = []}) => {
  const [createAutoMarkingLog, {data, loading}] = useMutation(
    CREATE_AUTO_MARKING_LOG,
    {
      refetchQueries,
    }
  );

  return {
    createAutoMarkingLog,
    data,
    loading,
  };
};

export default useCreateAutoMarkingLog;
