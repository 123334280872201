import React from "react";

/**
 * Checks if a PDF exists by its url
 */

const useCheckPDFExists = url => {
  const [exists, setExists] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const checkPDFExists = async () => {
      const response = await fetch(url);
      setExists(response.ok);
    };

    if (url !== null) {
      fetch(url)
        .then(response => {
          setExists(response.ok);
          setLoading(false);
        })
        .catch(error => {
          setExists(false);
          setLoading(false);
        });
    }
  }, [url]);

  return [exists, loading];
};

export default useCheckPDFExists;
