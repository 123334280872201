import React from "react";
import {useLocalDate} from "utilities/dates";
import {Trans} from "Components";
import SubscriptionStatusDescriptionCancelled from "./SubscriptionStatusDescriptionCancelled";
import SubscriptionStatusDescriptionTrialing from "./SubscriptionStatusDescriptionTrialing";
import SubscriptionStatusDescriptionPastDue from "./SubscriptionStatusDescriptionPastDue";

const SubscriptionStatusDescription = ({
  status,
  trialEnd,
  cancelAtPeriodEnd,
  expirationDate,
  invoices = [],
}) => {
  // Date of when the subscription will renew for the next period
  const renewalDate = useLocalDate(expirationDate, "Do MMMM YYYY");

  // 1. Highest priority is to show the cancellation date

  if (cancelAtPeriodEnd || status === "canceled") {
    // Check if the cancellation date is in the future to show either "cancelled" or "cancels on"
    return (
      <SubscriptionStatusDescriptionCancelled
        cancelAt={expirationDate}
        invoices={invoices}
      />
    );
  }

  // If the subscription is trialing, show when the trial will expire
  // Note that if the subscription is trialing but cancelled, the cancellation date should be prioritized
  if (status === "trialing") {
    return <SubscriptionStatusDescriptionTrialing trialEnd={trialEnd} />;
  }

  // If the subscription is past_due, it will automatically cancel ONE WEEK after the last open invoice was issued
  if (status === "past_due") {
    return <SubscriptionStatusDescriptionPastDue invoices={invoices} />;
  }

  if (status === "active") {
    return (
      <Trans {...SubscriptionStatusDescriptionProps} renewalDate={renewalDate}>
        Next invoice on {{renewalDate}}
      </Trans>
    );
  }

  return (
    <Trans {...SubscriptionStatusDescriptionProps}>No future invoices</Trans>
  );
};

export const SubscriptionStatusDescriptionProps = {
  type: "tsm",
  color: "text-gray-light",
};

export default SubscriptionStatusDescription;
