import React from "react";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {GET_CURRENT_USER} from "API/queries/user/useGetCurrentUser";

const VERIFY_OTP = gql`
  mutation VerifyOTP($email: String!, $action: OTPAction!, $code: String!) {
    verifyOTP(email: $email, action: $action, code: $code) {
      ok
      response
      error {
        code
        message
      }
    }
  }
`;

const useVerifyOTP = action => {
  const [verifyOTP, {loading, error: mutationError, data}] = useMutation(
    VERIFY_OTP,
    {}
  );

  if (mutationError) {
    console.log("useVerifyOTP mutationError", mutationError);
  }

  const result = data?.verifyOTP || {};

  const error = window.ErrorCodes[result.error?.code] || null;

  return {
    verifyOTP,
    loading,
    error,
    result,
  };
};

export default useVerifyOTP;
