import React from "react";
import classnames from "classnames";
import {Skeleton} from "Components/_Elements/index";
import {chartSizeMap} from "Components/_Elements/Metric/Metric.constants";

const MetricLoading = ({size = "sm"}) => {
  return (
    <div
      className={classnames(
        "relative background rounded-md border border-1 border-gray shadow-sm w-full p-4"
      )}
    >
      <div className={classnames("flex flex-row items-start gap-2")}>
        <div className="grow">
          <Skeleton size="xs" />

          <div className="mt-2 flex items-end gap-2">
            <Skeleton
              size="md"
              fluid={false}
              className="aspect-square w-auto"
            />
          </div>
        </div>

        <div className="-my-4">
          <div
            className={classnames(chartSizeMap[size], "aspect-square")}
          ></div>
        </div>
      </div>
      <Skeleton size="2xs" />
    </div>
  );
};

export default MetricLoading;
