import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_COURSE_REVIEWS = gql`
  query getCourseReviews($courseId: ID!, $loggedIn: Boolean!) {
    getCourse(id: $courseId) {
      id
      reviews {
        id
        user {
          id
          first_name
          last_name
          avatar
          email
        }
        title
        score
        text
        createdAt
      }
      currentUsersReview @include(if: $loggedIn) {
        id
        user {
          avatar
          id
          first_name
          last_name
          email
        }
        title
        score
        text
      }
    }
  }
`;

const GetCourseReviews = (courseId, loggedIn = false) => {
  let reviews = [];
  let currentUsersReview = {};
  let averageRating = 0;
  let nReviews = 0;

  let scoreCount = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0};

  const {data, loading, error} = useQuery(GET_COURSE_REVIEWS, {
    variables: {courseId, loggedIn},
    skip: !courseId,
    context: {
      batch: true,
    },
  });

  if (data) {
    reviews = data.getCourse.reviews;
    nReviews = reviews.length;
    currentUsersReview = data.getCourse.currentUsersReview || {};

    averageRating = reviews.length
      ? reviews.reduce((curr, next) => {
          scoreCount[next.score]++;
          return curr + next.score;
        }, 0) / reviews.length
      : 0;

    averageRating = averageRating.toFixed(1);

    // Remove current users review from all to prevent duplicate rendering:
    reviews = reviews.filter(r =>
      currentUsersReview.id ? r.id !== currentUsersReview.id : true
    );
  }

  if (error) {
    console.log("CourseReviewsData error: ", error.message);
  }

  return {
    reviews,
    currentUsersReview,
    averageRating,
    data,
    loading,
    scoreCount,
    nReviews,
  };
};

export default GetCourseReviews;
