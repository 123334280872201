import * as React from "react";
import {useParams} from "react-router-dom";
import {PageLogoLoader} from "Components";
import {UserContext} from "UserContextProvider";
import CourseRouter from "./CourseRouter";
import {
  courseLandingRoutes,
  courseRoutes,
} from "routes/Course/courseRoutesList";
import useGetCourse from "API/queries/course/useGetCourse";
import CourseNotFound from "routes/Course/CourseNotFound";
import useLocalStorageState from "utilities/hooks/useLocalStorageState";

export const CourseContext = React.createContext(null);

window.CourseContext = CourseContext;

const Course = () => {
  let {courseId} = useParams();
  const {user} = React.useContext(UserContext);
  const {previewMode} = React.useContext(window.PreviewSidebarContext);

  const [searchQuery, setSearchQuery] = React.useState(null);
  const [course, loading] = useGetCourse(courseId, false, false, false);

  const [
    exerciseListDisplayMode,
    setExerciseListDisplayMode,
  ] = useLocalStorageState(
    previewMode ? "previewExerciseListDisplayMode" : "exerciseListDisplayMode",
    previewMode ? "grid" : "list"
  );

  if (loading) return <PageLogoLoader inverted />;

  if (!loading && !course?.id) {
    return <CourseNotFound />;
  }

  let routeList;
  let hideSidebar = false;

  // TODO: Handle the case when public_tagging is false but the course is free, what should we show in that case?
  if (course?.currentUser?.active || course.public_tagging) {
    routeList = courseRoutes;
  } else {
    routeList = courseLandingRoutes;
    hideSidebar = true;
  }

  /**
   * Returns a link to a course page, don't include the /course/:id/ part and no leading slash
   * @param {string} path The path to the page e.g. "upload"
   * @returns {`/course/${string}/${string}`}
   */
  const getCourseLink = path => `/course/${course.id}/${path}`;

  return (
    <CourseContext.Provider
      value={{
        ...course,
        courseId: course.id,
        courseName: course.name,
        courseIcon: course.icon,
        coursePrice: course.price,
        coursePurchased: course.purchased,
        schoolName: course.school.name,
        schoolNickname: course.school.nickname,
        isCommunityCourse: course.public_tagging, // TODO: change this to also take the price into account
        searchQuery,
        setSearchQuery,
        getCourseLink,
        exerciseListDisplayMode,
        setExerciseListDisplayMode,
      }}
    >
      <CourseRouter routeList={routeList} hideSidebar={hideSidebar} />
    </CourseContext.Provider>
  );
};

export default Course;
