import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_MODULE = gql`
  query getModule($moduleId: ID!) {
    getModule(id: $moduleId) {
      id
      name
    }
  }
`;

const useGetModule = moduleId => {
  let mod = null;

  const { loading, error, data } = useQuery(GET_MODULE, {
    variables: { moduleId },
    context: {
      batch: true,
    },
  });

  if (error) return `Error in useGetModule: ${error.message}`;

  if (data) {
    mod = data.getModule;
  }

  return [mod, loading];
};

export default useGetModule;
