import React from "react";
import useLambda from "API/lambdas/useLambda";
import CDN from "utilities/constants/CDN";
import client from "client";
import {GET_CURRENT_USER_GROUPS} from "API/queries/groups/useGetCurrentUserGroups";

const useGroupMembershipVerificationStatus = ({
  transcriptUrl,
  groupId,
  groupMembershipId,
}) => {
  const {user} = React.useContext(UserContext);

  const {data, loading, error, run} = useLambda({
    lambdaName: "group-membership-verifier-dev-main",
    args: {
      // Replace the CDN url with the S3 url
      transcriptUrl: transcriptUrl.replace(
        CDN,
        "https://kollin.s3.eu-north-1.amazonaws.com"
      ),
      groupId,
      userId: user.id,
      method: "transcript",
      groupMembershipId,
    },
  });

  React.useEffect(() => {
    if (data?.ok) {
      setTimeout(() => {
        // Refetch the user's current group memberships to check if the verification was successful
        client.query({
          query: GET_CURRENT_USER_GROUPS,
          fetchPolicy: "network-only",
        });
      }, 500);
    }
  }, [data?.ok]);

  return {
    data,
    loading,
    error,
  };
};

export default useGroupMembershipVerificationStatus;
