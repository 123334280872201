import React from "react";
import {scoreToDifficultyIndex} from "utilities/exerciseUtilities/formatScore";
import difficultyNames from "utilities/constants/DIFFICULTY_NAMES";
import PropTypes from "prop-types";
import {Badge, Typography} from "Components";

import classnames from "classnames";
import {useTranslation} from "react-i18next";

const difficultyToBadgeType = {
  0: "gray",
  1: "success",
  2: "warning",
  3: "error",
  4: "purple",
};

const DifficultyChip = ({
  score,
  filled,
  className = "",
  displayScore = true,
  onClick,
  icon = null,
  faded = false,
}) => {
  const {t: trans} = useTranslation();
  let difficultyIndex = scoreToDifficultyIndex(score);
  let difficultyName = trans(difficultyNames[difficultyIndex]);
  let badgeType = difficultyToBadgeType[difficultyIndex];

  let displayText = "0";

  try {
    displayText = Number(score).toFixed(1);
  } catch (err) {}

  return (
    <Badge
      type={badgeType}
      border={true}
      uppercase={true}
      className={classnames("rounded-md flex items-center", className)}
      onClick={onClick}
      faded={faded}
      icon={icon}
    >
      {displayScore ? (
        <Typography>
          {displayText} {difficultyName}
        </Typography>
      ) : (
        <Typography className="mr-1">{difficultyName}</Typography>
      )}
    </Badge>
  );
};

DifficultyChip.propTypes = {
  score: PropTypes.any.isRequired,
};

export default DifficultyChip;
