import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import {useHistory} from "react-router-dom";
import {CourseCard, Loading} from "Components";
import useSearchCourse from "API/queries/course/useSearchCourse";
import CourseListNoResults from "routes/Courses/CoursesList/CourseListNoResults";
import {AnimatePresence, motion} from "framer-motion";

const CoursesList = ({filtersState}) => {
  const history = useHistory();

  const handleClick = courseId => {
    history.push(`/course/${courseId}`);
  };

  const hasSearched =
    filtersState.query !== "" &&
    filtersState.query !== null &&
    filtersState.query?.length > 2;

  const {
    courses,
    loading: coursesLoading,
    loadMore,
    canLoadMore,
  } = useSearchCourse({
    schoolId: filtersState.schoolId,
    query: filtersState.query || "",
    limit: 10,
  });

  return (
    <div className="relative">
      <AnimatePresence>
        {coursesLoading && (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{delay: 0.3}}
            className="absolute top-1 left-0 w-full z-10"
          >
            <Loading />
          </motion.div>
        )}
      </AnimatePresence>
      {courses.length > 0 && (
        <InfiniteScroll
          loadMore={loadMore}
          threshold={100}
          hasMore={canLoadMore}
          pageStart={0}
          initialLoad={false}
          useWindow={true} // Add scroll listeners to the window, or else, the component's parentNode.
        >
          {courses.length > 0 &&
            courses.map((course, i) => (
              <CourseCard
                key={course.id}
                {...course}
                schoolName={course.school.name}
                onClick={handleClick}
                highlightText={filtersState.query}
                showActiveBadge={true}
                showPurchaseInfo={true}
              />
            ))}
        </InfiniteScroll>
      )}

      {courses.length < 3 && hasSearched && (
        <CourseListNoResults
          cancel={() => {
            filtersState.setQuery("");
          }}
          {...filtersState}
          handleCourseClick={handleClick}
          noResults={courses.length === 0}
        />
      )}
    </div>
  );
};

export default CoursesList;
