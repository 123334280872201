import React from "react";
import {Button, Document, Icon} from "Components";
import ReactDOM from "react-dom";
import {motion} from "framer-motion";
import useOutsideClickAlerter from "utilities/hooks/useOutsideClickAlerter";
import classnames from "classnames";

const FilePreview = ({
  url,
  fileContainer,
  togglePreview,
  previewOpen,
  previewButtonProps = {},
}) => {
  const fileType = url.split(".").pop(); // pdf, doc, docx, etc.

  // const previewPopupContainer = React.useRef(null); // Target container for the portal
  const documentContainerRef = React.useRef(null); //  container for the Document container (for determining size)

  // Close the preview if the user clicks outside of the preview popup
  useOutsideClickAlerter(documentContainerRef, togglePreview);

  // TODO: Handle preview for other file types
  if (!fileType.includes("pdf")) {
    return null;
  }

  // Show the preview icon, if URL is given
  if (previewOpen) {
    return [
      <Button
        icon="fa-solid fa-eye-slash"
        type="link"
        onClick={togglePreview}
        key="1"
        {...previewButtonProps}
      />,
      // Create portal for preview
      ReactDOM.createPortal(
        <motion.div
          layoutId="preview"
          initial={{
            opacity: 0,
            scale: 0.5,
            // translateY: "-30%",
            translateX: "80%",
          }}
          animate={{
            opacity: 1,
            scale: 1,
            // translateY: "-30%",
            translateX: "10%",
          }}
          // exit={{ opacity: 0, scale: 0.5 }}
          className={classnames(
            "absolute  z-50", // position
            " w-[400px] p-2 rounded-lg  background", // Shape and size
            "flex flex-col items-end overflow-hidden", // Layout
            "border-2 border-gray-50  shadow-xl" // Border & Shadow
          )}
          ref={documentContainerRef}
        >
          <Icon icon="fa-solid fa-times" size="sm" onClick={togglePreview} />
          <Document
            fileType={"pdf"}
            url={url}
            container={documentContainerRef}
            showAllPages={false}
          />
        </motion.div>,
        fileContainer.current,
        "2"
      ),
    ];
  }

  return (
    <Button
      icon="fa-solid fa-eye"
      type="link"
      onClick={togglePreview}
      key="1"
      {...previewButtonProps}
    />
  );
};

export default FilePreview;
