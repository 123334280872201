import React from "react";
import useGetCurrentUserOrders from "API/queries/order/useGetCurrentUserOrders";
import useAccountOrdersTableColumns from "routes/Account/AccountOrders/useAccountOrdersTableColumns";
import {Table, Trans} from "Components";

const AccountOrders = () => {
  const [orders, loading] = useGetCurrentUserOrders();
  const columns = useAccountOrdersTableColumns();

  const data = orders
    .reduce((acc, order) => {
      const discount = order.discount || 0;

      return [
        ...acc,
        ...order.items.map(item => ({
          ...order,
          ...item,
          discount: Math.min(discount, item.price),
        })),
      ];
    }, [])
    .filter(order => order.status !== "unpaid");

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      fixed={false}
      rowSpacing="lg"
      card={{
        title: <Trans>Past purchases</Trans>,
        subTitle: (
          <Trans>
            Here you can find all your past purchases of courses and study plans
          </Trans>
        ),
      }}
    />
  );
};

export default AccountOrders;
