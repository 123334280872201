import { useMutation } from "@apollo/react-hooks";
import { GET_EXERCISE_TAG_SUGGESTIONS } from "API/queries/exercise/useGetExerciseTagSuggestions";
import gql from "graphql-tag";
import React from "react";
import { CourseContext } from "routes/Course/CourseContextProvider";
import { UserContext } from "UserContextProvider";

const SET_VALIDATED_MUTATION = gql`
  mutation setTagVoteValidated(
    $tagId: ID!
    $exerciseId: ID!
    $validated: Boolean!
  ) {
    setTagVoteValidated(
      tag_id: $tagId
      exercise_id: $exerciseId
      validated: $validated
    ) {
      ok
      error
    }
  }
`;

const useValidateTagVoteMutation = exerciseId => {
  const { user } = React.useContext(UserContext);
  const { courseId } = React.useContext(CourseContext);

  const [setTagValidationStatus, { loading }] = useMutation(
    SET_VALIDATED_MUTATION,
    {
      refetchQueries: () => [
        {
          query: GET_EXERCISE_TAG_SUGGESTIONS,
          variables: { exerciseId, courseId, loggedIn: user !== null },
        },
      ],
    }
  );

  // const toggleValidated = (tagId, validated) => {
  //   setTagValidationStatus(
  //     { variables: { tagId, validated, exerciseId } }
  //     //   {
  //     //     update(cache, { data: { setIsValidated } }) {
  //     //       const { getExercise } = cache.readQuery({
  //     //         query: GET_SUGGESTIONS,
  //     //         variables: { exerciseId, courseId },
  //     //       });

  //     //       let newTagSuggestions = getExercise.tag_suggestions;

  //     //       newTagSuggestions.forEach(t => {
  //     //         if (t.id === tagId) {
  //     //           t.tally.is_validated = isValidated;
  //     //         }
  //     //       });
  //     //       getExercise.tag_suggestions = newTagSuggestions;
  //     //       cache.writeQuery({
  //     //         query: GET_SUGGESTIONS,
  //     //         data: {
  //     //           getExercise,
  //     //         },
  //     //       });
  //     //     },
  //     //   }
  //   );
  // };

  return [setTagValidationStatus, loading];
};

export default useValidateTagVoteMutation;
