import React from "react";
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";

const CREATE_RESOURCE = gql`
  mutation createResource($file: Upload!, $hash: String!, $courseId: ID!) {
    uploadResource(file: $file, hash: $hash, courseId: $courseId) {
      ok
      error {
        code
        message
      }
      resource {
        id
        name
        format
        url
        author
        type
        createdAt
      }
    }
  }
`;

const useCreateResourceMutation = onCompleted => {
  const [createResource, {loading, data, error}] = useMutation(
    CREATE_RESOURCE,
    {
      onCompleted,
    }
  );

  return {
    createResource,
    loading,
    data,
    error,
  };
};

export default useCreateResourceMutation;
