import * as React from "react";
import classnames from "classnames";
import {motion} from "framer-motion";
import PropTypes from "prop-types";

export const types = ["primary", "purple", "success", "warning", "danger"];
const barColors = {
  primary: "highlight-primary",
  purple: "highlight-purple",
  success: "highlight-success",
  warning: "highlight-warning",
  danger: "highlight-danger",
};

const backgroundColors = {
  primary: "surface-primary",
  purple: "surface-purple",
  success: "surface-success",
  warning: "surface-warning",
  danger: "surface-error",
};

const ProgressBar = ({
  progressRatio,
  animated = false,
  dark = false,
  thin,
  type = "primary",
  bordered = false,
  segments = null,
}) => {
  const [opacity, setOpacity] = React.useState(1);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(prev => (prev === 1 ? 0.5 : 1));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const bars = React.useMemo(() => {
    if (segments && segments.length > 0) {
      return segments;
    }
    return [
      {
        color: barColors[type],
        ratio: progressRatio,
      },
    ];
  }, [progressRatio, segments, type]);

  return (
    <div
      className={[
        `box-content w-full ${backgroundColors[type]} my-1 rounded-lg overflow-clip flex`,
        thin ? "h-2" : "h-4",
        bordered && `border-${type}-600 border-2`,
        dark && "dark",
      ].join(" ")}
    >
      {bars.map(({ratio, color}, index) => {
        const barWidth = Math.max(Number.isNaN(ratio) ? 0 : ratio * 100);

        return (
          <motion.div
            initial={{width: 0}}
            animate={{
              width: `${barWidth}%`,
              // If the progress bar is animated, we want to animate the opacity
              opacity: animated ? opacity : 1,
            }}
            transition={{
              duration: 0.7,
              type: "tween",
            }}
            className={classnames(
              `box-content h-full ${color} rounded-lg`,
              "-my-px py-px",
              {
                "rounded-l-none hover:rounded-l-none border-r-none": index > 0,
                "rounded-r-none hover:rounded-r-none border-l-none":
                  index < segments?.length - 1,
              }
            )}
          />
        );
      })}
    </div>
  );
};

ProgressBar.propTypes = {
  progressRatio: PropTypes.number,
  animated: PropTypes.bool,
  thin: PropTypes.bool,
  type: PropTypes.oneOf(types),
};

export default ProgressBar;
