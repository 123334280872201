import React from "react";
import { Button, Typography, WaveBorderBox } from "Components";
import useGetCoupon from "API/queries/coupon/useGetCoupon";

const Summer2022CampaignCta = ({
  programKey,
  program,
  programLogo,
  programReception,
  nStudents,
  onClick,
  disabled,
}) => {
  let cleanProgramName = program.replace(/.*-\s/g, "");
  const [coupon, loading] = useGetCoupon({ name: programKey });

  const extraOffer =
    coupon?.amount > 0 ? (
      <span>
        {" "}
        och <b>{coupon?.amount / 100}</b> Kollin krediter!
      </span>
    ) : (
      "!"
    );

  return (
    <div>
      <WaveBorderBox
        className="p-4 relative fill-"
        bgColor="bg-primary-800"
        fillColor="fill-primary-800"
      >
        <div className="flex items-center justify-center ">
          <img src={programLogo.url} alt="logo" className="h-28 w-auto" />
        </div>

        <Typography
          type="dxs"
          weight="sb"
          color="text-primary-25"
          className="mb-3 mt-8 text-center"
        >
          Joina {nStudents || "201"} andra kursare från {cleanProgramName} på
          Kollin
        </Typography>

        <Typography
          className="mt-4 text-center"
          type="tlg"
          color="text-gray-100"
        >
          Tack vare <b>{programReception}</b> får du <br />
          din <b> 2:a läsperiod </b>
          gratis{extraOffer}
        </Typography>

        <div className="mt-4">
          <Button
            key="submit"
            type={disabled ? "secondary" : "primary"}
            fluid
            disabled={disabled}
            onClick={onClick}
            iconLeft={disabled ? "exclamation-circle" : ""}
          >
            {disabled ? "Gäller endast nyantagna studenter" : "Kom igång"}
          </Button>
        </div>
      </WaveBorderBox>
    </div>
  );
};

export default Summer2022CampaignCta;
