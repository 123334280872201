import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";

const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      ok
      token
      refreshToken
      errors {
        path
        message
      }
    }
  }
`;

const useResetPassword = () => {
  const [resetPassword, {data, loading}] = useMutation(RESET_PASSWORD);

  return {
    data,
    loading,
    resetPassword,
  };
};

export default useResetPassword;
