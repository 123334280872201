import CDN from "utilities/constants/CDN";

export const imageUrls = {
  primary: CDN + "/logo/logo.svg",
  white: CDN + "/kollin_logo_white.svg",
  gold: CDN + "/images/logo-gold.svg",
  transparent: CDN + "/logo/logo-transparent.svg",
};

export const sizes = ["xs", "sm", "md", "lg", "xl", "2xl", "3xl"];
export const types = ["primary", "white", "gold"];

export const imageSizes = {
  "2xs": "w-3",
  xs: "w-4",
  sm: "w-6",
  md: "w-8",
  lg: "w-12",
  xl: "w-16",
  "2xl": "w-20",
  "3xl": "w-24",
};

export const gapSizes = {
  "2xs": "gap-1",
  xs: "gap-1",
  sm: "gap-1",
  md: "gap-2",
  lg: "gap-3",
  xl: "gap-4",
  "2xl": "gap-4",
  "3xl": "gap-4",
};

export const textColors = {
  primary: "text-[#6E8BE2]",
  white: "text-white",
  gold: "text-[#FFCE36]",
  transparent: "text-transparent",
};

export const textSizes = {
  "2xs": "txs",
  xs: "txs",
  sm: "tlg",
  md: "dxs",
  lg: "dmd",
  xl: "dlg",
  "2xl": "dxl",
  "3xl": "d2xl",
};
