import {InMemoryCache} from "apollo-boost";
import {IntrospectionFragmentMatcher} from "apollo-cache-inmemory";
import {defaultDataIdFromObject} from "@apollo/client";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
});

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    if (object.__typename === "PublicUser") {
      // Return the hash of the object if it is a PublicUser
      return `PublicUser:${object.id}:${object.firstName}:${object.lastName}`;
    }

    if (object.sys && object.sys.id) {
      // Return the hash of the object if it is a Contentful object
      return `Contentful:${object.__typename}:${object.sys.id}`;
    }

    return defaultDataIdFromObject(object);
  },
  fragmentMatcher,
});

export default cache;
