import React from "react";
import {Trans, Typography} from "Components";
import TypographyGradient from "Components/_Elements/Typography/TypographyGradient";
import useGroupDiscounts from "Widgets/Group/GroupDiscounts/useGroupDiscounts";
import {getDiscountedPriceCents} from "Components/ProductPrice/ProductPrice";

const GroupDiscountsPriceWrapper = ({
  textProps = {
    type: "tlg",
    weight: "md",
  },
  defaultPriceCents,
  gradient = true,
  monthly = false,
}) => {
  const {loading, discount, group, discountApplicable} = useGroupDiscounts();

  if (loading)
    return (
      <Typography {...textProps}>{defaultPriceCents / 100 + " SEK"}</Typography>
    );

  if (!discount)
    return (
      <span>
        <Typography {...textProps}>
          {defaultPriceCents / 100 + " SEK"}{" "}
        </Typography>
        {monthly && (
          <Trans {...textProps} inline>
            / Månad
          </Trans>
        )}
      </span>
    ); // No discount to show}

  const discountedPriceCents = getDiscountedPriceCents({
    priceCents: defaultPriceCents,
    discountPercentage: discount.discountPercentage,
  });

  const roundedPrice = Math.round(discountedPriceCents / 100);

  return (
    <GroupDiscountsFreeWrapper>
      <div className="w-full flex items-center gap-2">
        <Typography
          {...textProps}
          color="text-gray-400"
          className="line-through leading-none"
        >
          {defaultPriceCents / 100} SEK
        </Typography>
        {gradient ? (
          <div>
            <TypographyGradient
              {...textProps}
              inline
              className={"leading-none"}
            >
              {roundedPrice} SEK{" "}
            </TypographyGradient>
            {monthly && (
              <TypographyGradient {...textProps} type="tlg" weight="sb" inline>
                / Månad
              </TypographyGradient>
            )}
          </div>
        ) : (
          <div>
            <Typography {...textProps} inline>
              {roundedPrice} SEK{" "}
            </Typography>
            {monthly && (
              <Trans {...textProps} inline>
                / Månad
              </Trans>
            )}
          </div>
        )}
      </div>
    </GroupDiscountsFreeWrapper>
  );
};

export const GroupDiscountsFreeWrapper = ({children}) => {
  const {discount, discountApplicable} = useGroupDiscounts();

  if (discount && discountApplicable && discount.discountPercentage === 100) {
    return null;
  }

  return children;
};
export default GroupDiscountsPriceWrapper;
