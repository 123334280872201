import React from "react";
import useResizeHandler from "./useResizeHandler";
import classnames from "classnames";
import Handle from "./Handle";
import CornerHandle from "./CornerHandle";
import usePanHandler from "./usePanHandler";

export const getStyle = coordinates => ({
  top: coordinates.y1,
  left: coordinates.x1,
  width:
    coordinates.x2 && coordinates.x1 ? coordinates.x2 - coordinates.x1 : "100%",
  height: coordinates.y2 - coordinates.y1,
});

const directions = ["top", "bottom", "left", "right"];

const ResizeableContainer = ({
  initial,
  className = "",
  debug = false,
  handleColor = "bg-primary-500",
  dirs = ["top", "bottom", "left", "right"],
  corners = ["top-left", "top-right", "bottom-left", "bottom-right"],
  disablePan = false,
  onDragEnd,
  onDrag,
  actions,
  disabled = false,
  id,
  children,
}) => {
  const coordinatesRef = React.useRef(initial);
  const [coordinates, setCoordinates] = React.useState(initial);

  let callback = onDragEnd ? onDragEnd : () => {};

  // Set initial coordinates
  React.useEffect(() => {
    coordinatesRef.current = initial;
    setCoordinates(initial);
  }, [initial]);

  React.useEffect(() => {
    if (!disabled) {
      if (
        initial.x1 !== coordinates.x1 ||
        initial.y1 !== coordinates.y1 ||
        initial.x2 !== coordinates.x2 ||
        initial.y2 !== coordinates.y2
      ) {
        onDrag && onDrag(coordinates);
      }
    }
  }, [coordinates]);

  const container = usePanHandler({
    setCoordinates,
    disablePan,
    callback,
    coordinatesRef,
  });

  const handleRef = useResizeHandler({
    setCoordinates,
    callback,
    coordinates,
    coordinatesRef,
  });

  return (
    <div
      id={id}
      ref={container}
      className={classnames(
        "absolute",
        disablePan ? "" : "cursor-move",
        className
      )}
      style={getStyle(coordinates)}
    >
      {actions}
      {debug && <Debug coordinates={coordinates} />}
      {directions.map(dir => (
        <Handle
          key={dir}
          position={dir}
          handleColor={handleColor}
          handleRef={handleRef}
          disabled={disabled}
          hidden={!dirs.includes(dir)}
        />
      ))}
      {corners.map(corner => (
        <CornerHandle
          key={corner}
          position={corner}
          handleColor={handleColor}
          handleRef={handleRef}
          disabled={disabled}
        />
      ))}
      {children}
    </div>
  );
};

const Debug = ({ coordinates }) => {
  return Object.keys(coordinates).map((k, i) => (
    <p>
      {k}: {coordinates[k]}
      const callback = onChange;
    </p>
  ));
};

export default ResizeableContainer;
