import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";

export const GET_COURSE_CHAPTERS = gql`
  query getUserChapters($courseId: ID!) {
    getUserChapters(courseId: $courseId) {
      id
      chapter_type
      title
      published
      html
      markdown
      createdAt
      locked
    }
  }
`;

const useGetUserChapters = courseId => {
  let chapters = [];
  const {data, loading, error} = useQuery(GET_COURSE_CHAPTERS, {
    variables: {courseId},
  });

  if (data) {
    chapters = data.getUserChapters.map(chapter => ({
      ...chapter,
      chapterType: chapter.chapter_type,
    }));
  }

  return [chapters, loading];
};

export default useGetUserChapters;
