import React from "react";

const useLocalStorageState = (key, defaultValue) => {
  let existingValue = localStorage.getItem(key);

  // Cast from string to appropriate type
  if (typeof defaultValue == "number") {
    existingValue = Number(existingValue);
  }

  if (typeof defaultValue == "boolean") {
    existingValue = JSON.parse(existingValue);
  }

  if (Array.isArray(defaultValue)) {
    existingValue = JSON.parse(existingValue);
  }

  // Set the initial value of the state to the existing value found in the local storage
  const [value, setValue] = React.useState(
    existingValue === null ? defaultValue : existingValue
  );

  // Set the value the in the local storage every time it changes
  let box = value;

  if (Array.isArray(box)) {
    box = JSON.stringify(box);
  }

  React.useEffect(() => localStorage.setItem(key, box), [value]);

  return [value, setValue];
};

export default useLocalStorageState;
