import React from "react";
import useGetCurrentUserNotifications from "API/queries/notification/useGetCurrentUserNotifications";
import {Trans, Dropdown, Badge, Typography, ErrorBoundary} from "Components";
import NotificationsList from "../NotificationsList/NotificationsList";

const NotificationsDropDown = ({trigger = null, dropDownSide = "bottom"}) => {
  const [notifications, loading] = useGetCurrentUserNotifications();

  const unreadNotifications = notifications.filter(
    notification => notification.read === false
  );

  return (
    <Dropdown
      triggerAsChild={trigger !== null}
      content={
        <div className="w-96 h-96 overflow-y-auto background z-50">
          <div className="border-b border-gray-300 ">
            <Trans weight="md" color="text-gray-700">
              Notifications
            </Trans>
          </div>

          <NotificationsList notifications={notifications} loading={loading} />
        </div>
      }
      side={dropDownSide}
    >
      {trigger ? (
        <div>{trigger(unreadNotifications.length)}</div>
      ) : (
        <Badge icon="fa-solid fa-bell" size="md" type="primary">
          <Typography type="tmd" color="text-primary-500" weight="sb">
            {unreadNotifications.length}
          </Typography>
        </Badge>
      )}
    </Dropdown>
  );
};

const Notifications = args => {
  return (
    <ErrorBoundary fallbackComponent={null}>
      <NotificationsDropDown {...args} />
    </ErrorBoundary>
  );
};

export default Notifications;
