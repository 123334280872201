import React from "react";

const UseSelectSearchState = ({options}) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };

  const items = React.useMemo(() => {
    if (searchTerm === "") {
      return options;
    }

    const regex = new RegExp(searchTerm, "i");

    return options.filter(opt => {
      return opt.text.match(regex);
    });
  }, [options, searchTerm]);

  return {
    items,
    handleSearch,
    searchTerm,
  };
};

export default UseSelectSearchState;
