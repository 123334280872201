import React from "react";
import { message } from "Components";
import Trans from "Components/Trans";
import useCreateCommentMutation from "API/mutations/comment/useCreateCommentMutation";

const useExerciseDiscussionThreadOnNewComment = thread => {
  const onCompleted = data => {
    if (data) {
      if (data.createComment.ok) {
        message.success(<Trans>The comment has been posted</Trans>);
      }
      // catch the errors and set in the state
      else state.error({ text: data.createComment.error });
    }
  };

  const { createComment } = useCreateCommentMutation(
    thread?.exercise?.id || null,
    onCompleted
  );

  const onNewCommentSubmit = (state, callback) => {
    createComment({
      variables: {
        ...state,
        threadId: thread.id,
        commentId: thread.comments.id,
      },
    }).then(data => {
      if (data.data.createComment.ok) {
        callback();
      }
    });
  };

  return onNewCommentSubmit;
};

export default useExerciseDiscussionThreadOnNewComment;
