import React from "react";
import {useDropzone} from "react-dropzone";
import {Icon, message, SafeText, Trans} from "Components";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {motion} from "framer-motion";
import PropTypes from "prop-types";

const FileUpload = ({
  onFilesAccepted,
  multiple = false,
  accept = "image/*",
  subTitle,
  showSubTitle = true,
  showIcon = true,
  maxSize = 2000000,
  onError,
  title,
  error = null,
  compact = false,
  fluid = true,
  disabled = false,
  loading,
  icon,
  className,
}) => {
  const {t: trans} = useTranslation();
  const onDrop = React.useCallback(
    acceptedFiles => onFilesAccepted(acceptedFiles),
    []
  );

  const onDropRejected = React.useCallback(rejectedFiles => {
    if (onError) {
      onError(rejectedFiles);
    } else {
      const errorMessage =
        trans("File too big. ") +
        trans("Max: ") +
        (maxSize / 1000000).toString() +
        "MB";

      message.error(errorMessage);
    }
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    multiple,
    accept,
    maxSize,
    onDropRejected,
    disabled,
  });

  return (
    <motion.div
      className={classnames(
        "file-upload select-none group",
        compact && "flex-row justify-start gap-4 items-center",
        disabled && "opacity-50 cursor-not-allowed",
        className
      )}
      initial={{
        width: "100%",
      }}
      animate={{
        width: fluid ? "100%" : "fit-content",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      {showIcon && (
        <div className="file-upload-icon-container">
          {icon || (
            <Icon
              icon={disabled ? "circle-notch" : "fa-regular fa-cloud-arrow-up"}
              size={compact ? "sm" : "lg"}
              fillType={disabled ? "gray" : "purple"}
              outlined
              filled
              spin={loading || disabled}
            />
          )}
        </div>
      )}

      <div
        className={classnames(
          compact ? "text-left" : "text-center truncate mt-5"
        )}
      >
        <FileUploadTitle loading={loading} title={title} />
        <FileUploadSubTitle
          subTitle={subTitle}
          showSubtitle={showSubTitle}
          error={error}
        />
      </div>
    </motion.div>
  );
};

const FileUploadSubTitle = ({subTitle, showSubtitle, error}) => {
  if (!showSubtitle) return null;
  if (error)
    return React.cloneElement(error, {type: "tsm", color: "text-error-600"});

  if (subTitle) {
    return (
      <SafeText type="tsm" color="text-primary-600">
        {subTitle}
      </SafeText>
    );
  }
  // Standard subtitle if none is provided
  return (
    <Trans type="tsm" color="text-primary-600">
      or drag and drop here
    </Trans>
  );
};

const FileUploadTitle = ({loading, title}) => {
  const titleProps = {
    type: "tsm",
    weight: "medium",
    color: "text-primary",
  };

  if (loading)
    return <Trans {...titleProps}>Your file is uploading please wait...</Trans>;

  if (title) {
    return <SafeText {...titleProps}>{title}</SafeText>;
  }

  return <Trans {...titleProps}>Click to upload</Trans>;
};

FileUpload.propTypes = {
  error: PropTypes.element,
  title: PropTypes.element,
  subTitle: PropTypes.element,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onFilesAccepted: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

export default FileUpload;
