import React from "react";
import classnames from "classnames";
import PageHeader from "Components/_Elements/Page/PageHeader/PageHeader";
import {WaveBorderBox} from "Components/_Elements/index";
import UserMenu from "Components/Navbars/UserMenu/UserMenu";
import PageNavbar from "Components/Navbars/PageNavbar/PageNavbar";
import {useLocation} from "react-router-dom";
import Footer from "routes/Landing/Footer/Footer";

const Page = ({
  title,
  subTitle,
  label,
  actions = null,
  imageUrl = null,
  children,
  className,
  inverted = false,
  showWaveBorder = true,
  showUserMenu = true,
  showFooter = true,
}) => {
  const waveBorderBottomVariant = inverted && showWaveBorder ? "1" : null;
  /**
   * Both the PageHeader and the PageContent are wrapped in a div with a
   * max width of 1280px. This is to ensure that the content is centered
   * on larger screens.
   */
  return (
    <div>
      <WaveBorderBox
        bottomVariant={waveBorderBottomVariant}
        topVariant={null}
        fillColor="fill-primary-800" // same as background of PageHeader
      >
        <div className={classnames(inverted ? "bg-primary-800" : "background")}>
          <div className="px-2 sm:px-4 md:px-8 py-16 max-w-7xl mx-auto  overflow-clip">
            <PageHeader
              title={title}
              subTitle={subTitle}
              label={label}
              actions={actions}
              imageUrl={imageUrl}
              inverted={inverted}
            />
          </div>
        </div>
      </WaveBorderBox>
      <div
        className={classnames(
          "px-2 sm:px-4 md:px-8 max-w-7xl mx-auto",
          className
        )}
      >
        <div className="py-5">{children}</div>
      </div>
      {showFooter && (
        <div className="mt-8">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Page;
