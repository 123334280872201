import React from "react";
import { Alert, Badge, Button, Trans, Typography } from "Components";

const ExamUploadDocumentsRowActions = ({
  focused,
  document,
  onSave,
  saveDisabled,
  saveLoading,
  onRemoveClick,
  error,
}) => {
  if (error) {
    return (
        <div className="pb-2">
          <Alert size="sm" title={<Typography>{error}</Typography>} type="error"/>
        </div>
    );
  }

  if (document.saved) {
    return (
      <Badge type="success" icon="far fa-check-circle" size="md">
        <Trans>Saved</Trans>
      </Badge>
    );
  }

  if (focused) {
    return (
      <div className="flex flex-wrap items-center gap-2">
        <Button
          loading={saveLoading}
          disabled={saveDisabled}
          iconLeft="check"
          size="sm"
          type="primary"
          onClick={onSave}
        >
          <Trans>Save</Trans>
        </Button>
      </div>
    );
  }
  return null;
};

export default ExamUploadDocumentsRowActions;
