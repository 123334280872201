import useGetCourse from "API/queries/course/useGetCourse";
import { PageLogoLoader } from "Components";
import React from "react";
import useCourseLandingNavigation from "routes/Course/CourseLanding/CourseLandingDesktop/useCourseLandingNavigation";
import useGetCourseReviews from "API/queries/reivew/useGetCourseReviews";
import useGetCourseModules from "API/queries/module/useGetCourseModules";
import useGetDefaultProductPrice from "Components/ProductPrice/useGetDefaultProductPrice";
import useGetCourseExams from "API/queries/exam/useGetCourseExams";
import useGetCourseThreads from "API/queries/thread/useGetCourseThreads";
import useGetCourseStudyPlans from "API/queries/StudyPlan/useGetCourseStudyPlans";

export const CourseLandingContext = React.createContext(null);
window.CourseLandingContext = CourseLandingContext;

const CourseLandingContextWrapper = ({ children }) => {
  const { id: courseId } = React.useContext(window.CourseContext);

  // Queries
  const [course, courseLoading] = useGetCourse(courseId, true);
  // const [studyPlan, studyPlanLoading] = useGetCourseStudyPlan(courseId);
  const [studyPlans, studyPlansLoading] = useGetCourseStudyPlans(courseId);
  const [modules, modulesLoading] = useGetCourseModules(courseId, true, true, true);
  const [exams, examsLoading] = useGetCourseExams(courseId);
  const { nReviews, loading } = useGetCourseReviews(courseId, false);

  const {
    threads,
    loading: threadsLoading
  } = useGetCourseThreads(3);

  const navigationState = useCourseLandingNavigation({
    modules,
    studyPlans,
    exams,
    threads,
    reviews: nReviews,
  });

  const productPrice = useGetDefaultProductPrice("COURSE", courseId);

  if (
    courseLoading ||
    studyPlansLoading ||
    modulesLoading ||
    examsLoading ||
    threadsLoading ||
    loading
  )
    return <PageLogoLoader />;

  return (
    <CourseLandingContext.Provider
      value={{
        navigationState,
        course,
        courseId,
        productPrice
      }}
    >
      {children}
    </CourseLandingContext.Provider>
  );
};

export default CourseLandingContextWrapper;
