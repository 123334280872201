import React from "react";
import i18n from "i18n";

export const formatSchoolOptions = schools => {
  if (!schools) return []; // handle loading state

  return [
    ...schools.map(s => ({
      value: s.id,
      text: s.name,
      count: s.coursesCount,
    })),
  ];
};

export const formatProgramOptions = programs => {
  if (!programs) return []; // handle loading state
  return [...programs.map(s => ({value: s.id, label: s.name, text: s.name}))];
};

export const formatModuleOptions = modules => {
  // const defaultValue = { value: "", text: i18n.t("Choose module") };

  if (!modules) return []; // handle loading state
  return [
    ...modules.map((m, index) => ({
      value: m.id,
      text: m.name,
      icon: (index + 1).toString(),
    })),
  ];
};

export const formatTagOptions = tags => {
  const defaultValue = {value: 0, text: i18n.t("Choose category")};

  if (!tags) return [defaultValue]; // handle loading state
  return [defaultValue, ...tags.map(m => ({value: m.id, text: m.name}))];
};

export const getEnrollmentYearOptions = () => {
  let nYears = 10;
  let currentYear = new Date().getFullYear();
  let yearList = [];
  for (var year = currentYear; year > currentYear - nYears; year--) {
    yearList.push(year);
  }

  return [
    {value: null, text: i18n.t("Choose year")},
    ...yearList.map(y => ({value: y, text: y})),
  ];
};

export const getDifficultyOptions = () => {
  return [
    {value: 1, text: i18n.t("Easy")},
    {value: 2, text: i18n.t("Medium")},
    {value: 3, text: i18n.t("Hard")},
    {value: 4, text: i18n.t("Nightmare")},
  ];
};
