import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_CURRENT_USER_GROUPS = gql`
  query currentUserGroups {
    currentUserGroups {
      id
      key
      name
      description
      imageUrl
      hidden
      membershipRequiresValidation
      membersCount
      createdAt
      updatedAt
      program {
        id
      }
      parentGroup {
        id
        key
        name
        description
        imageUrl
      }
      currentUserMembership {
        id
        verified
        admin
      }
      discounts {
        id
        name
        discountPercentage
        issuerName
        description
        createdAt
        updatedAt
      }
    }
  }
`;
const useGetCurrentUserGroups = () => {
  let currentUserGroups = [];

  const {data, loading, error} = useQuery(GET_CURRENT_USER_GROUPS, {
    fetchPolicy: "cache-first",
  });

  if (error) {
    console.log(`Error in useGetCurrentUsersGroups: ${error}`);
  }

  if (data) {
    currentUserGroups = data.currentUserGroups;
  }

  const alreadyJoinedGroup = React.useMemo(() => {
    if (loading) {
      return false;
    }

    return currentUserGroups.length > 0;
  }, [currentUserGroups, loading]);

  return {currentUserGroups, loading, alreadyJoinedGroup};
};

export default useGetCurrentUserGroups;
