import React from "react";
import classnames from "classnames";
import Comment from "./Comment";

const CommentReplies = ({
  replies,
  expanded,
  className,
  size,
  repliesClassNames = null,
}) => {
  if (replies.length > 0 && expanded) {
    return (
      <div
        className="overflow-hidden"
        initial={{opacity: 0, height: 0}}
        animate={{opacity: 1, height: expanded ? "auto" : 0}}
      >
        <div
          className={classnames(
            repliesClassNames || "border-t-2 border-color mt-4 p-2"
          )}
        >
          {replies?.map((reply, index) => (
            <Comment
              size={size}
              key={reply.id}
              {...reply}
              expandable={false}
              className={classnames(
                "border-0 border-color rounded-none mt-4",
                index === replies.length - 1 ? "" : "border-b-2 ", // Don't add border to last comment
                className
              )}
            />
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default CommentReplies;
