// import useOutsideAlerter from "utilities/hooks/useOutsideClickAlerter";

import React from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import useOutsideAlerter from "../../../utilities/hooks/useOutsideClickAlerter";
import {motion, AnimatePresence} from "framer-motion";
import PropTypes from "prop-types";
import {animationVariants} from "Components/_Elements/Modal/Modal.constants";
import ModalHeader from "Components/_Elements/Modal/ModalHeader";
import ModalActions from "Components/_Elements/Modal/ModalActions";
import {Button} from "Components/_Elements/index";
import useKeyboardShortcuts from "utilities/hooks/useKeyboardShortcuts";
import {useBreakPointSM, useBreakPointXS} from "utilities/hooks/useWindowSize";

export const ModalContext = React.createContext(null);

const Modal = ({
  defaultOpen,
  children,
  style,
  closable = true,
  trigger,
  className,
  size = "md",
  onClose,
  type = "primary",
  icon = null,
  title = null,
  description = null,
  subTitle = null, // alias for description
  action = null,
  showCancelButton = false,
  maskClosable = true,
  escapeClosable = false,
  animate = true,
  showCloseIcon = true,
  darkMode = false,
  dark = false,
}) => {
  const onMobile = useBreakPointXS();

  // We store the "open" state internally, so we can have a modal uncontrolled from the parent
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  // A mounted state to be able to run exit animations
  const [mounted, setMounted] = React.useState(false);

  // If the "defaultOpen" prop changes outside, we update it locally in the state
  React.useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const handleClose = e => {
    if (closable) {
      if (onClose) {
        onClose();
      } else {
        setIsOpen(false);
      }
    }
  };

  // Ref and useOutsideAlerter are used to close the modal when the user clicks outside of it
  const modalRef = React.useRef(null);

  const handleMaskClick = () => {
    if (maskClosable) {
      handleClose();
    }
  };

  useOutsideAlerter(modalRef, handleMaskClick);

  const handleEscape = () => {
    if (escapeClosable && closable) {
      handleClose();
    }
  };

  useKeyboardShortcuts(
    [
      {
        key: "Escape",
        action: handleEscape,
      },
    ],
    [],
    false
  );

  React.useEffect(() => {
    if (isOpen) {
      setMounted(true);
    }
  }, [isOpen]);

  return [
    //  Check if there is a trigger, if so, we render it and pass the onClick handler to open the modal, otherwise we render null
    trigger
      ? React.cloneElement(trigger, {
          onClick: () => setIsOpen(true),
          key: "modal-trigger",
        })
      : null,
    mounted &&
      ReactDOM.createPortal(
        <div
          className={classnames("modal-base", (darkMode || dark) && "dark")}
          style={{...style}}
        >
          <AnimatePresence
            onExitComplete={() => {
              setMounted(false);
            }}
          >
            {isOpen && (
              <motion.div
                key="modal-background"
                className="modal-background"
                variants={animationVariants["modalBackgroundOpen"]}
                animate={animate ? "modalBackgroundOpen" : null}
              />
            )}
            {isOpen && (
              <motion.div
                key="modal-content"
                className={classnames(
                  "modal-content",
                  "transform translate-y-0 sm:-translate-y-1/2 -translate-x-1/2",
                  className
                )}
                ref={modalRef}
                custom={onMobile(true, false)}
                initial={animate ? "modalContentClosed" : undefined}
                animate={animate ? "modalContentOpen" : undefined}
                exit={
                  animate ? onMobile("modalContentExit", undefined) : undefined
                }
                variants={animationVariants}
              >
                <ModalContext.Provider value={{isOpen, handleClose}}>
                  <ModalHeader
                    title={title}
                    description={description || subTitle}
                    type={type}
                    size={size}
                    icon={icon}
                    showCloseIcon={showCloseIcon}
                    closable={closable}
                    handleClose={handleClose}
                  />
                  <div className={classnames(icon && "sm:pl-16")}>
                    {children}
                    <ModalActions
                      type={type}
                      action={action}
                      closable={closable}
                      showCancelButton={showCancelButton}
                      handleClose={handleClose}
                    />
                  </div>
                </ModalContext.Provider>
              </motion.div>
            )}
          </AnimatePresence>
        </div>,
        document.body,
        "modal"
      ),
  ];
};

Modal.propTypes = {
  /**
   * If true, the modal will be open by default
   */
  defaultOpen: PropTypes.bool,
  /**
   * The content of the modal
   */
  children: PropTypes.node,
  /**
   * The style of the modal
   */
  style: PropTypes.object,
  /**
   * If true, the modal will be closable
   */
  closable: PropTypes.bool,
  /**
   * The trigger of the modal (optional) e.g. a button
   */
  trigger: PropTypes.node,
  /**
   * The class name of the modal
   */
  className: PropTypes.string,

  /**
   * The size of the modal
   */
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  /**
   * The function to call when the modal is closed
   */
  onClose: PropTypes.func,
  /**
   * The type of the modal
   */
  type: PropTypes.oneOf(["primary", "secondary", "success", "danger"]),
  /**
   * The icon of the modal
   *
   */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * The title of the modal
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * The description of the modal
   */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * The action of the modal
   */
  action: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onClick: PropTypes.func,
    ...Button.propTypes,
  }),

  /**
   * If true, the modal will show a cancel button
   */
  showCancelButton: PropTypes.bool,
};

export default Modal;
