import React from "react";
import findExaminerInDocument from "utilities/findExaminerInDocument";
import useExamUploadDocumentsTableRowTextState from "routes/Course/ExamUpload/ExamUploadDocumentsTable/ExamUploadDocumentsTableRow/ExamUploadDocumentsTableRowState/useExamUploadDocumentsTableRowTextState";
import { useTranslation } from "react-i18next";

const useExamUploadDocumentsRowAuthorState = ({ resource }) => {
  const { t: trans } = useTranslation();
  // CONTEXTS ---------------------------------------------------------------
  const { globalAuthors, addGlobalAuthor, updateResource } = React.useContext(
    window.ExamUploadContext
  );

  // Get the text of the document
  const { documentTrie, text } = useExamUploadDocumentsTableRowTextState({
    document: resource,
  });

  let docUrl = resource?.url;

  const [author, setAuthor] = React.useState(null);

  // Search for the author in the document
  // - When Trie is ready
  // - OR when the global authors change and the author is not set.
  React.useEffect(() => {
    if (documentTrie.current && docUrl && author == null) {
      findExaminerInDocument({
        trie: documentTrie.current,
        text,
        authorOptionsSet: globalAuthors,
        docUrl,
      }).then(foundAuthors => {
        // Check the type of the found authors, if it is an array then it is the author options
        // If it is a string then it is the author
        let suggestedAuthor = Array.isArray(foundAuthors)
          ? foundAuthors[0]
          : foundAuthors;

        suggestedAuthor = suggestedAuthor || trans("Unknown author");

        setAuthor(suggestedAuthor);
      });
    }
  }, [documentTrie.current, globalAuthors, author]);

  // Add the author to the global authors
  React.useEffect(() => {
    if (author != null) {
      // Don't add loading to the global authors
      addGlobalAuthor(author);
    }
  }, [author]);

  return {
    author,
  };
};

export default useExamUploadDocumentsRowAuthorState;
