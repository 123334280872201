import React from "react";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {userContext} from "UserContextProvider";
import {Typography} from "Components";

const useGroupNavigationRoutes = ({routes}) => {
  const {user} = userContext();
  const {currentUserIsMember, name, currentUserIsAdmin} = useGroupContext();

  const groupHidden = !currentUserIsMember;

  const allowedRoutes = React.useMemo(() => {
    let filteredRoutes = routes.filter(route => {
      // If route is admin only, check that user is admin.
      if (route.admin) {
        // Check that user is group admin or global admin.
        return currentUserIsAdmin;
      }

      return true;
    });

    // Set the index [/, /home] routes labels to the name of the group.

    filteredRoutes = filteredRoutes.map(route => {
      if (route.path === "/") {
        return {
          ...route,
          hidden: true,
        };
      }

      if (route.path === "/home") {
        return {
          ...route,
          label: <Typography>{name}</Typography>,
        };
      }

      return route;
    });

    return filteredRoutes;
  }, [currentUserIsMember, routes]);

  return {allowedRoutes, groupHidden};
};

export default useGroupNavigationRoutes;
