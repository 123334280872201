import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const SUBSCRIPTION_PLANS = gql`
  query subscriptionPlans {
    subscriptionPlans {
      id
      price
      name
      interval
      trialDays
      pricingId
    }
  }
`;

const useGetSubscriptionPlans = () => {
  let subscriptionPlans = [];

  const {data, loading} = useQuery(SUBSCRIPTION_PLANS, {
    fetchPolicy: "cache-first",
  });

  if (data && data.subscriptionPlans) {
    subscriptionPlans = data.subscriptionPlans;
  }

  return [subscriptionPlans, loading];
};

export default useGetSubscriptionPlans;
