import React from "react";
import ReactDOM from "react-dom";
import {Button} from "Components";
import {motion} from "framer-motion";

import useOutsideAlerter from "utilities/hooks/useOutsideClickAlerter";

const SidebarMenu = ({open, setOpen, children, dark = false}) => {
  if (!open) return null;

  const handleClose = () => {
    setOpen(false);
  };

  const ref = React.useRef(null);
  // Close the menu when the user clicks outside of it
  useOutsideAlerter(ref, handleClose);

  return ReactDOM.createPortal(
    <div className={dark && "dark"}>
      <div className="background-overlay" />
      <motion.div
        ref={ref}
        className="z-40 h-full w-2/3  fixed top-0 left-0 background p-4"
        initial={{x: -100}}
        animate={{x: 0}}
        exit={{x: -100}}
        transition={{
          type: "tween",
        }}
      >
        <div className="flex w-full justify-end">
          <Button
            icon="times"
            size="lg"
            type="link"
            onClick={() => setOpen(false)}
          />
        </div>

        {React.Children.toArray(children).map(child => (
          <div className="w-full p-2 border-b border-gray">{child}</div>
        ))}
      </motion.div>
    </div>,
    document.body
  );
};

export default SidebarMenu;
