import useLoginUser from "API/mutations/user/useLoginUser";
import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import {setAuthenticated} from "utilities/authentication";
import {useFormValidation} from "utilities/hooks/useFormValidation";
import normalizeErrors from "utilities/normalizeErrors";
import {validateLogin} from "utilities/validators";

const useLoginFormState = ({handleAuthResponse}) => {
  const handleResponse = data => {
    handleAuthResponse(data, "login", "email");
  };

  const {login, loading} = useLoginUser(handleResponse);

  const state = useFormValidation(
    {
      email: "",
      password: "",
    },
    login,
    validateLogin
  );

  return {...state, loading};
};

export default useLoginFormState;
