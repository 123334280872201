import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {GET_CURRENT_USER} from "API/queries/user/useGetCurrentUser";

const CREATE_SETUP_INTENT = gql`
  mutation createStripeSetupIntent {
    createStripeSetupIntent {
      ok
      clientSecret
      error {
        code
        message
      }
    }
  }
`;

const useCreateSetupIntent = onCompleted => {
  const [createSetupIntent, {data, loading}] = useMutation(
    CREATE_SETUP_INTENT,
    {
      onCompleted,
    }
  );

  const result = data?.createStripeSetupIntent || {};

  return {
    createSetupIntent,
    result,
    loading,
  };
};

export default useCreateSetupIntent;
