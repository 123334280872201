import React from "react";
import { Button, Icon, Typography } from "Components";
import useSummer2022CampaignOnBoardingSchoolState from "./useSummer2022CampaignOnBoardingEnrollmentState";
import { Summer2022CampaignProgramContext } from "routes/Campaign/Summer2022Campaign/Summer2022Campaign";
import Summer2022CampaignOnBoardingEnrollmentSchool from "./Summer2022CampaignOnBoardingEnrollmentSchool";
import Summer2022CampaignOnBoardingEnrollmentProgram from "./Summer2022CampaignOnBoardingEnrollmentProgram";
import Summer2022CampaignOnBoardingEnrollmentYear from "./Summer2022CampaignOnBoardingEnrollmentYear";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

const Summer2022CampaignOnBoardingEnrollment = ({ program, school }) => {
  const { programLogo, programs } = React.useContext(
    Summer2022CampaignProgramContext
  );
  const {
    submit,
    loading,
    ...rest
  } = useSummer2022CampaignOnBoardingSchoolState({ program, school });

  const { state } = rest;

  const disabled =
    state.schoolId == null ||
    state.programId == null ||
    state.enrollmentYear == null;

  return (
    <AnimatePresence>
      <AnimateSharedLayout>
        <div className="flex flex-col gap-4 w-10/12">
          <div className="max-w-sm">
            <Typography className="mb-2" type="tsm" color="text-gray-50">
              Skola
            </Typography>
            <Summer2022CampaignOnBoardingEnrollmentSchool
              school={school}
              {...rest}
            />
          </div>
          <div className="max-w-sm">
            <Typography className="mb-2" type="tsm" color="text-gray-50">
              Program
            </Typography>
            <Summer2022CampaignOnBoardingEnrollmentProgram
              school={school}
              program={program}
              programLogo={programLogo}
              allowedPrograms={programs}
              {...rest}
            />
          </div>
          <div className="max-w-sm">
            <Typography className="mb-2" type="tsm" color="text-gray-50">
              Startår
            </Typography>
            <Summer2022CampaignOnBoardingEnrollmentYear {...rest} />
          </div>
          <Button
            onClick={submit}
            loading={loading}
            type="primary"
            disabled={disabled}
          >
            Ja, nu kör vi!
          </Button>
        </div>
      </AnimateSharedLayout>
    </AnimatePresence>
  );

  // Not editing state: only display the data:
};

export const Summer2022CampaignOnBoardingEnrollmentInfoContainer = ({
  children,
  onClick,
}) => (
  <motion.div
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -100, scale: 0 }}
  >
    <div className="flex justify-between items-center background rounded-xl h-20">
      <div className="pl-3 py-3 flex gap-3 items-center ">{children}</div>
      <div
        className="bg-gray-200  px-3 py-3  h-full rounded-r-xl flex justify-center items-center"
        onClick={onClick}
      >
        <Icon icon="xmark" size="lg" />
      </div>
    </div>
  </motion.div>
);

export default Summer2022CampaignOnBoardingEnrollment;
