import React from "react";
import OnboardingEnrollmentYear from "routes/Onboarding/OnboardingEnrollmentYear";
import classnames from "classnames";

const GroupOnboardingEnrollmentYear = ({
  enrollmentYear,
  handleEnrollmentYearChange,
  isDisabled,
}) => {
  return (
    <div className={classnames(isDisabled && "opacity-50")}>
      <OnboardingEnrollmentYear
        state={{
          values: {enrollmentYear},
          setFormValue: ({enrollmentYear}) =>
            handleEnrollmentYearChange(enrollmentYear),
        }}
        size="sm"
        outlineSize={"outline-2"}
        layoutClasses="flex gap-2 flex-wrap items-center mb-4"
        // rangeSize={6}
      />
    </div>
  );
};

export default GroupOnboardingEnrollmentYear;
