import React from "react";
import {Skeleton} from "../_Elements";

const ExerciseThumbnailLoading = () => {
  return (
    <div>
      <Skeleton
        style={{
          width: "336px",
          height: "227px",
        }}
        className="mb-2"
      ></Skeleton>
      <Skeleton
        style={{
          maxWidth: "100px",
          height: "21px",
        }}
        className="mb-2"
      ></Skeleton>
      <Skeleton
        style={{
          maxWidth: "150px",
          height: "21px",
        }}
        className="mb-2"
      ></Skeleton>
    </div>
  );
};

export default ExerciseThumbnailLoading;
