import React from "react";
import useGetCourseExercises from "API/queries/exercise/useGetCourseExercises";
import useCourseExercisesTable from "Widgets/Course/CourseExercisesTable/useCourseExercisesTable";
import {Link, Table, Trans} from "Components";
import {CourseParamsContext} from "../../../routes/Course/CourseRouter";

const CourseExercisesTable = ({
  exerciseFilters = {
    published: null,
  },
  pollForUpdates = false,
}) => {
  const {courseId} = React.useContext(window.CourseContext);
  const {getExerciseWithListLink} = React.useContext(CourseParamsContext);

  const [exercises, loading] = useGetCourseExercises({
    courseId,
    includeNotes: true,
    includeSelfEvaluation: true,
    includeSource: true,
    includeStats: true,
    exerciseFilters: exerciseFilters,
    pollForUpdates,
  });

  const columns = useCourseExercisesTable(exercises);

  if (!exercises || exercises?.length === 0) return null;

  return (
    <Table
      columns={columns}
      data={exercises || []}
      fixed={false}
      card={{
        title: <Trans>Exercises</Trans>,
        actions: [
          <Link
            to={getExerciseWithListLink(
              exercises[0].id,
              exercises.map(e => e.id),
              "all"
            )}
            key="exams"
            iconRight="angle-right"
          >
            <Trans>View All</Trans>
          </Link>,
        ],
      }}
    />
  );
};

export default CourseExercisesTable;
