import React from "react";
import {EmptyState, Skeleton, Trans} from "Components/index";
import TableRow from "Components/_Elements/Table/TableRow";
import {range} from "utilities/numbers";

const TableBody = ({
  rows,
  loading,
  columnsCount,
  rowClassNames,
  cellClassNames,
  columnsTransposed,
  minimumRowsToDisplay,
  rowSpacing,
}) => {
  const emptyRows = minimumRowsToDisplay - rows.length;

  if (loading) {
    return range(5).map(i => (
      <tr className="table-row w-full">
        <td colSpan={columnsCount} className="w-full pt-1">
          <Skeleton className="w-full grow" fluid />
        </td>
      </tr>
    ));
  }

  if (!rows || rows.length === 0) {
    return (
      <tr className="table-row w-full text-center">
        <td colSpan={columnsCount} className="p-8 w-full text-center">
          <EmptyState title={<Trans>No data</Trans>} illustration="documents" />
        </td>
      </tr>
    );
  }

  return [
    ...rows.map((item, index) => (
      <TableRow
        rowClassNames={rowClassNames}
        cellClassNames={cellClassNames}
        key={"data-row" + index}
        columnsTransposed={columnsTransposed}
        rowSpacing={rowSpacing}
        {...item}
      />
    )),
    ...range(emptyRows).map(i => (
      <TableRow
        rowClassNames={rowClassNames}
        cellClassNames={cellClassNames}
        key={"empty-row" + i}
        columnsTransposed={columnsTransposed}
        emptyRow={true}
        rowSpacing={rowSpacing}
      />
    )),
  ];
};

export default TableBody;
