import React from "react";
import {Checkbox, Icon, Toggle} from "Components";
import {WorkboardContext} from "Components/Workboard/Workboard";
import {Typography} from "Components/_Elements";

const ExerciseViewHeaderTextModeToggle = () => {
  const workboardContext = React.useContext(WorkboardContext);

  if (!workboardContext) return null;

  const {textMode, toggleTextMode, textModeDisabled} = workboardContext;

  return (
    <Checkbox
      name="text-mode"
      label={""}
      onChange={toggleTextMode}
      checked={textMode}
      disabled={textModeDisabled}
    />
  );
};

export default ExerciseViewHeaderTextModeToggle;
