import React from "react";
import {useQuery} from "@apollo/react-hooks";
import Trans from "Components/Trans";
import gql from "graphql-tag";
import {uniqueBy} from "utilities/objects";
import {useHistory} from "react-router-dom";
import useMarkNotificationAsRead from "API/mutations/notification/useMarkNotificationAsRead";
import useNotificationDisplayMessage from "Components/Notifications/NotificationsList/useNotificationDisplayMessage";
import {Avatar} from "Components";
import {NotificationsContext} from "Components/_Elements/Notification/NotificationsContextProvider";

export const GET_CURRENT_USER_NOTIFICATIONS = gql`
  query getCurrentUserNotifications {
    getCurrentUserNotifications {
      id
      type
      read
      objectType
      objectId
      data
      url
      createdAt
      updatedAt
      sender {
        id
        first_name
        email
        avatar
      }
    }
  }
`;

const NEW_NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription listenCurrentUserNotifications {
    listenCurrentUserNotifications {
      id
      type
      read
      objectType
      objectId
      data
      url
      createdAt
      updatedAt
      sender {
        id
        first_name
        email
        avatar
      }
    }
  }
`;

const useGetCurrentUserNotifications = () => {
  let notifications = [];

  const {notify, closeNotification} = React.useContext(NotificationsContext);

  const [markNotificationAsRead] = useMarkNotificationAsRead();

  const notificationDisplayMessages = useNotificationDisplayMessage();

  const q = useQuery(GET_CURRENT_USER_NOTIFICATIONS, {});

  const history = useHistory();

  const {data, loading, error, subscribeToMore} = q;

  if (subscribeToMore && !loading && typeof subscribeToMore === "function") {
    try {
      subscribeToMore({
        document: NEW_NOTIFICATIONS_SUBSCRIPTION,
        updateQuery: (prev, payload) => {
          return updateQueryFunction({
            prev,
            subscriptionData: payload.subscriptionData,
            markNotificationAsRead,
            history,
            notificationDisplayMessages,
            notify,
            closeNotification,
          });
        },
      });
    } catch (e) {
      // Don't do anything since it's a subscription
    }
  }

  if (error) {
    console.log("Error in useGetCurrentUserNotifications", error);
  }

  if (data) {
    notifications = [...data.getCurrentUserNotifications];
  }

  return [notifications, loading];
};

const updateQueryFunction = ({
  prev,
  subscriptionData,
  markNotificationAsRead,
  history,
  notificationDisplayMessages,
  notify,
  closeNotification,
}) => {
  if (!subscriptionData?.data) return prev;

  const newItem = subscriptionData.data.listenCurrentUserNotifications;

  // Trigger a new notification popup:
  const {url, message} = notificationDisplayMessages[newItem.type](newItem);

  // Showing a notification popup immidiately
  notify({
    key: newItem.id,
    title: <Trans>New notification</Trans>,
    message,
    secondaryAction: {
      label: <Trans>Dismiss</Trans>,
      onClick: () => {
        markNotificationAsRead({variables: {id: newItem.id}});
        closeNotification(newItem.id);
      },
    },
    primaryAction: {
      label: <Trans>Reply</Trans>,
      onClick: () => {
        markNotificationAsRead({variables: {id: newItem.id}});
        history.push(url);
      },
    },
    icon: <Avatar user={newItem.sender} size="md" />,
    closable: true,
  });
  /**
   * Merge the new notification with the previous notifications
   * @type {{getCurrentUserNotifications: *[]}}
   */
  const next = {
    getCurrentUserNotifications: uniqueBy(
      [newItem, ...prev.getCurrentUserNotifications],
      "id"
    ).sort((a, b) => (a.id < b.id ? 1 : -1)),
  };

  return next;
};

export default useGetCurrentUserNotifications;
