import React from "react";

const useDocumentTitle = (title = null, dependencies = []) => {
  React.useEffect(() => {
    if (title) {
      if (title.includes("Kollin")) {
        document.title = title;
      } else {
        document.title = `${title} | Kollin`;
      }
    } else {
      document.title = "Kollin";
    }
  }, dependencies);
};

export default useDocumentTitle;
