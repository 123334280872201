import useCreateUserEvaluation from "API/mutations/userEvaluation/useCreateUserEvaluation";
import useGetUserEvaluations, {
  GET_USER_EVALUATIONS,
} from "API/queries/userEvaluation/useGetUserEvaluations";
import React from "react";
import {UserContext} from "UserContextProvider";
/**
 * Get the existing user evaluation or create one if it already does not exist
 * @param {ID} exerciseId - The ID of the Exercise
 * @param {String} source - The source where the user evaluation is coming from
 */

const useGetExerciseEvaluation = (exerciseId, source) => {
  // const [created, setCreated] = React.useState(false);

  const {user} = React.useContext(UserContext);

  const parameters = {
    objectName: "Exercise",
    objectId: exerciseId?.toString(),
    source,
  };

  const [
    exerciseEvaluations,
    exerciseEvaluationsLoading,
  ] = useGetUserEvaluations({
    ...parameters,
  });

  const userEvaluation = exerciseEvaluations.length
    ? exerciseEvaluations[0]
    : null;

  const updateCache = (cache, {data: {createUserEvaluation}}) => {
    if (createUserEvaluation.ok) {
      // write the new evaluation to the cache
      cache.writeQuery({
        query: GET_USER_EVALUATIONS,
        variables: {...parameters},
        data: {
          getUserEvaluations: [createUserEvaluation.userEvaluation],
        },
      });
    }
  };

  const [createUserEvaluation] = useCreateUserEvaluation(updateCache);

  React.useEffect(() => {
    // Create a new default evaluation object if it already does not exist and logged in

    if (!exerciseEvaluationsLoading && !userEvaluation && user?.id) {
      createUserEvaluation({
        variables: {
          ...parameters,
          evaluation: {
            timeSpent: 0,
            backgroundTimer: 0,
            completed: false,
            difficultyAssessments: 0,
            selfReflection: {},
          },
        },
      });

      // setCreated(true);
    }
  }, [exerciseEvaluations, exerciseEvaluationsLoading]);

  return userEvaluation;
};

export default useGetExerciseEvaluation;
