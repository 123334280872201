import React from "react";
import classnames from "classnames";
import PageHeaderTitle from "Components/_Elements/Page/PageHeader/PageHeaderTitle";

const PageHeader = ({
  title,
  subTitle,
  label,
  actions = null,
  imageUrl = null,
  inverted = false,
}) => {
  return (
    <div
      className={classnames(
        "w-full",
        imageUrl && "flex items-center justify-between"
      )}
    >
      {/*Title and actions */}
      <div
        className={classnames(
          "flex flex-col gap-4 justify-between items-stretch",
          imageUrl ? "w-1/2 " : "w-full"
        )}
      >
        <PageHeaderTitle
          title={title}
          subTitle={subTitle}
          label={label}
          inverted={inverted}
        />
        {actions && (
          <div>
            {React.cloneElement(actions, {
              className: classnames(actions.props.className),
            })}
          </div>
        )}
      </div>
      {/* add an angle to the right side of the header */}
      {imageUrl && (
        <div className="relative w-1/2 h-full scale-150 overflow-clip -mx-2 sm:-mx-4 md:-mx-8 -my-20">
          <div
            className={classnames(
              "absolute -left-[100%] -top-[25%] scale-150 h-full w-full rotate-12",
              inverted ? "bg-primary-800" : "background"
            )}
          />
          <img src={imageUrl} alt="" />
        </div>
      )}
    </div>
  );
};

export default PageHeader;
