import React from "react";
import {NotificationsContext} from "./NotificationsContextProvider";
import NotificationElement from "./NotificationElement/NotificationElement";
import ReactDOM from "react-dom";
import {AnimatePresence, AnimateSharedLayout} from "framer-motion";

const NotificationsContainer = () => {
  const {notifications} = React.useContext(NotificationsContext);

  if (notifications?.length === 0) return null;

  return ReactDOM.createPortal(
    <div className="fixed top-2 right-2 z-[100]">
      <AnimatePresence presenceAffectsLayout>
        <AnimateSharedLayout type="crossfade">
          {notifications.map((notification, index) => (
            <NotificationElement
              key={notification.notificationId}
              {...notification}
              index={index}
            />
          ))}
        </AnimateSharedLayout>
      </AnimatePresence>
    </div>,
    document.body
  );
};

export default NotificationsContainer;
