import React from "react";
import {
  CourseStatsExamsProcessed,
  CourseStatsExamsUploaded,
  CourseStatsExercisesTagged,
} from "Widgets";
import {Section, Trans} from "Components";

const CourseDashboardDevelopmentStats = () => {
  const {isCommunityCourse} = React.useContext(window.CourseContext);

  // TODO: Check instead how many exams and exercises are in the course to determine if it's a community course

  if (!isCommunityCourse) return null; // don't show the development stats for non-community courses

  return (
    <Section secondary={true} title={<Trans>Course development</Trans>}>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
        {/*<CourseCommunityBanner />*/}
        <CourseStatsExamsUploaded />
        <CourseStatsExamsProcessed />
        <CourseStatsExercisesTagged />
      </div>
    </Section>
  );
};

export default CourseDashboardDevelopmentStats;
