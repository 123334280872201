import i18n from "i18next";
import disposableEmailValidator from "utilities/disposableEmailValidator";

export const validateLogin = values => {
  let errors = {};
  // Take out all values
  const {email, password} = values;

  // Email errors
  if (!email) {
    errors.email = i18n.t("You must enter an email address");
  } else if (!/^[A-Z0-9._%+-]+@[[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = i18n.t("Invalid email address");
  }

  // Password errors
  if (!password) {
    errors.password = i18n.t("A password must be entered");
  }

  return errors;
};

export const validatePassword = values => {
  let errors = {};
  // Take out all values
  const {password} = values;

  // Password errors
  if (!password) {
    errors.password = i18n.t("Password must be entered");
  } else if (password.length < 6)
    errors.password = i18n.t("Password must be at least 6 characters");
  else if (password.length > 120)
    errors.password = i18n.t("Password can be at most 120 characters");

  return errors;
};

// Reset password
export const validateDoublePasswordVerify = values => {
  let errors = {};
  // Take out all values
  const {password, confirmPassword} = values;

  // Password errors
  if (!password) {
    errors.password = "Password must be entered";
  } else if (password.length < 6) {
    errors.password = "Password must be at least 6 characters";
  } else if (password.length > 120) {
    errors.password = "Password can be at most 120 characters";
  }

  // Confirmation password errors
  if (!confirmPassword) {
    errors.confirmPassword = "Confirm your password";
  } else if (confirmPassword !== password) {
    errors.confirmPassword = "Passwords do not match";
  }

  return errors;
};

export const validateEmail = values => {
  let errors = {};
  // Take out all values
  const {email} = values;

  // Email errors
  if (!email) {
    errors.email = i18n.t("Email address must be entered");
  } else if (!/^[A-Z0-9._%+-]+@[[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = i18n.t("Invalid email address");
  }

  return errors;
};

export const validateEmailWithExtension = (extension, values) => {
  let errors = {};
  // Take out all values
  let {email} = {...values};

  // Email errors
  if (!email) {
    errors.email = i18n.t("Email address must be entered");
    return errors;
  }

  // e.g. adnanja@kth.se => adnanja + {extension}
  // e.g. adnanja => adnanja + {extension}

  const [first, inputExtension] = email.split("@");

  if (inputExtension !== undefined && inputExtension !== extension) {
    errors.email = i18n.t("Invalid e-post address");
    return errors;
  }

  email = `${first}@${extension}`;

  if (!/^[A-Z0-9._%+-]+@[[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = i18n.t("Invalid email address");
  }

  return errors;
};

export const validateSingleEmail = email => {
  return /^[A-Z0-9._%+-]+@[[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

// Full name (ex: Onboarding)
export const validateFullName = values => {
  let errors = {};

  const numbersRegex = /^[0-9]+$/;

  const {fullName} = values;

  const [firstName, ...rest] = fullName.split(" ");
  const lastName = rest.join(" ");

  // fullName errors
  if (!fullName) {
    errors.fullName = "Name is required";
  }
  if (!firstName || !lastName.replace(/s+/g, "")) {
    errors.fullName = "Full name must contain the First name and Last name";
  }

  if (numbersRegex.test(firstName) || numbersRegex.test(lastName)) {
    errors.fullName = "Name can only consist of letters";
  }

  return errors;
};

export const validateRegisterWithoutName = values => {
  return validateRegisterWithFirstAndLastName(values, false, true);
};

export const validateRegisterWithoutPassword = values => {
  return validateRegisterWithFirstAndLastName(values, true, false);
};

export const validateRegisterWithFirstAndLastName = async (
  values,
  requireName = true,
  requirePassword = true
) => {
  let errors = {};

  // Take out all values
  const {firstName, lastName, email, password} = values;

  // Email errors
  if (!email) {
    errors.email = i18n.t("Email address must be entered");
  } else if (!/^[A-Z0-9._%+-]+@[[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = i18n.t("Invalid email address");
  }

  // Password errors
  if (requirePassword) {
    if (!password) {
      errors.password = i18n.t("Password must be entered");
    } else if (password.length < 6)
      errors.password = i18n.t("Password must be at least 6 characters");
    else if (password.length > 120)
      errors.password = i18n.t("Password can be at most 120 characters");
  }

  if (requireName && (!firstName || firstName.length < 2)) {
    errors.firstName = i18n.t("First name must be atleast 2 letters");
  }

  if (requireName && (!lastName || lastName.length < 2)) {
    errors.lastName = i18n.t("Last name must be atleast 2 letters");
  }

  return errors;
};

export const validateProfile = values => {
  let errors = {};

  // Take out all values
  const {firstName, lastName, email} = values;

  if (!firstName || firstName.length < 2) {
    errors.firstName = i18n.t("First name must be atleast 2 letters");
  }

  if (!lastName || lastName.length < 2) {
    errors.lastName = i18n.t("Last name must be atleast 2 letters");
  }

  return errors;
};

export const validateFlashcard = values => {
  let errors = {};

  // Take out all values
  const {question, answer, moduleId} = values;

  if (!question || question.split(" ").length < 3) {
    errors.question = "Question must be at least 3 words";
  }

  if (!answer) {
    errors.answer = "Answer must be entered";
  }

  if (!moduleId) {
    errors.moduleId = "No module has been selected";
  }

  return errors;
};

export const validateCreateComment = values => {
  let errors = {};

  const {anonymous, text, username} = values;

  if (!text || !text.replace(/s+/g, "")) {
    errors.text = "The text cannnot be empty";
  }

  if (anonymous && (!username || !username.replace(/s+/g, ""))) {
    errors.username = "A username must be choosen if anonymous";
  }

  // Prevent email address from being used as username
  if (anonymous && username && username.includes("@")) {
    errors.username = "Username should contain email address";
  }

  return errors;
};

export const validateGenerateTest = values => {
  let errors = {};

  let {moduleIds, difficulties, nExercises} = values;

  if (moduleIds.length === 0) {
    errors.moduleIds = "Atleast one module must be selected";
  }

  if (difficulties.length === 0) {
    errors.difficulties = "Atleast one difficulty must be selected";
  }

  if (nExercises === 0) {
    errors.nExercises = "Number of exercise must be atleast 1";
  }

  return errors;
};

export const validateUploadForm = ({type, date}) => {
  let errors = {};
  let moment = require("moment");

  if (!type || !type.replace(/s+/g, "")) {
    errors.type = "The document type must be given";
  }

  if (!moment(date, "YYYY-MM-DD", true).isValid()) {
    errors.date = "The date is invalid";
  }

  return errors;
};

export const validateCourseReview = ({score, text}) => {
  let errors = {};

  if (score < 0 || score > 5) {
    errors.score = "The score must be between 0 and 5";
  }

  if (!text) {
    errors.text = "The review text can not be empty";
  }

  return errors;
};

export const validateChatMessage = ({text}) => {
  let errors = {};

  if (!text.replace(/s+/g, "")) {
    errors.text = "The review text can not be empty";
  }

  return errors;
};

export const validateContactForm = values => {
  let errors = {};
  // Take out all values
  const {email, message} = values;

  // Email errors
  if (!email) {
    errors.email = "You must enter your email address";
  } else if (!/^[A-Z0-9._%+-]+@[[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = "Invalid email address";
  }

  // Password errors
  if (!message) {
    errors.password = "You must enter a message";
  }

  return errors;
};

export const validatePhoneNumber = phoneNumber => {
  let error;

  if (!phoneNumber) {
    error = i18n.t("You must enter your phone number");
  } else if (phoneNumber.length !== 10) {
    error = i18n.t("Your phone number must have 10 digits");
  } else if (!/^07[0-9]{8}$/i.test(phoneNumber)) {
    error = i18n.t("Invalid phone number");
  }
  return error;
};

export const validateExerciseNote = values => {
  let errors = {};

  const {text} = values;

  if (!text.replace(/s+|\n/g, "")) {
    errors.text = "The text cannot be empty";
  }

  return errors;
};

/**
 * Validates that the
 * @param {String} phoneNumber
 * @returns
 */
export const validatePhoneNumberE164 = phoneNumber =>
  new Promise((resolve, reject) => {});

export const emptyValidator = () => ({});
