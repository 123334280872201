import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export const isLocal = window.location.hostname.includes("kollin.io") === false;

const debug = (message, ...args) => {
  if (isLocal) {
    console.log("[SENTRY DEBUG]: ", message, ...args);
  }
};

const ignoredMessages = [
  "En server med det angivna värdnamnet hittades inte.",
  "Failed to fetch",
  "Network error: Load failed",
  "not authenticated",
  "Response not successful: Received status code 400",
];

const initSentry = history => {
  Sentry.init({
    // dsn: isLocal  ? null : "https://2866f822b8464427b681c012c5c2f53c@o282797.ingest.sentry.io/1510577",
    dsn:
      "https://2866f822b8464427b681c012c5c2f53c@o282797.ingest.sentry.io/1510577",
    environment: window.location.hostname,
    release: "v" + process.env.REACT_APP_VERSION,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    sampleRate: 1.0,

    debug: false,
    // TODO: Ignore GraphQL error: not authenticated
    // beforeSend(event, hint) {
    //   const error = hint.originalException;
    //
    //   if (
    //     error &&
    //     error.message &&
    //     ignoredMessages.some(ignored => error.message === ignored)
    //   ) {
    //     return null;
    //   }
    //   return event;
    // },
  });
};

export default initSentry;
