import React from "react";
import {UserContext} from "UserContextProvider";
import {Avatar, Icon, Typography} from "Components/_Elements";
import {maybeString} from "utilities/text";
import {getProgramAndYear} from "Components/_Elements/Avatar/Avatar.helpers";
import {useHistory} from "react-router-dom";
import classnames from "classnames";

const CourseSidebarFooterUser = ({
  collapsed,
  showProgram = true,
  dark = false,
  showEmail = false,
  avatarSize = "sm",
  expand = true,
  showCog = true,
}) => {
  const history = useHistory();
  const {user} = React.useContext(UserContext);
  const name = `${maybeString(user?.firstName)} ${maybeString(user?.lastName)
    .charAt(0)
    .toUpperCase()}.`;
  const size = avatarSize;

  const description = React.useMemo(() => {
    if (!user) return null;
    return getProgramAndYear(user, size, false);
  }, [user]);

  if (!user) return null;

  return (
    <div
      className={classnames(
        "flex justify-between gap-2 p-2 border-t-1 border-gray pt-4 mt-2 cursor-pointer hover:surface",
        expand ? "-mx-2" : ""
      )}
      onClick={() => history.push("/account")}
    >
      <div className="flex gap-2 items-center ">
        <Avatar
          user={user}
          size={size}
          showRole={true}
          showProgramInfo={false}
        />
        {!collapsed && (
          <div>
            <Typography
              type="tsm"
              weight="md"
              color={dark ? "text-white" : "text-gray"}
            >
              {name}
            </Typography>
            {showProgram && (
              <Typography type="tsm" color="text-gray-light">
                {description}
              </Typography>
            )}
            {showEmail && (
              <Typography type="tsm" className="mt-1" color={"text-gray-light"}>
                {user.email}
              </Typography>
            )}
          </div>
        )}
      </div>
      {!collapsed && showCog && (
        <Icon icon="cog" size="md" color="text-gray-light" />
      )}
    </div>
  );
};

export default CourseSidebarFooterUser;
