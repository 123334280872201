import React from "react";
import { Icon, Input, Trans } from "Components";
import CheckoutSection from "routes/Checkout/CheckoutSection";

const CheckoutAuthenticationUser = () => {
  const { user } = React.useContext(window.UserContext);

  return (
    <CheckoutSection
      title={<Trans>Your information</Trans>}
      className="mt-0 bg-white "
      action={<Icon icon="check-circle" color="text-primary-light" />}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
        <Input
          id={"name"}
          name={"name"}
          type={"text"}
          required={true}
          label={<Trans>Name</Trans>}
          value={user.firstName + " " + user.lastName}
          disabled
          autoComplete="family-name"
          fluid
        />
        <Input
          id={"email"}
          email={"email"}
          type={"text"}
          required={true}
          label={<Trans>Email</Trans>}
          value={user.email}
          disabled
          autoComplete="email"
          fluid
        />
      </div>
    </CheckoutSection>
  );
};

export default CheckoutAuthenticationUser;
