import React from "react";
import useGetCourseExercises from "API/queries/exercise/useGetCourseExercises";
import {groupBy} from "utilities/objects";
import {
  scoreToDifficultyColor,
  scoreToLabel,
} from "utilities/exerciseUtilities/formatScore";
import Metric from "Components/_Elements/Metric/Metric";
import {sum} from "lodash";
import {Trans} from "Components";
import MetricLoading from "Components/_Elements/Metric/MetricLoading";

const CourseUserStatsSelfEvaluationDistribution = ({size = "md"}) => {
  const {courseId} = React.useContext(window.CourseContext);

  const [exercises, loading] = useGetCourseExercises({
    courseId,
    includeSelfEvaluation: true,
  });

  const selfEvaluationsByScore = groupBy(exercises, "self_evaluation.score");

  const data = Object.keys(selfEvaluationsByScore).map(score => ({
    value: selfEvaluationsByScore[score].length,
    label: scoreToLabel(score),
    color: scoreToDifficultyColor(score, true),
    id: score,
  }));

  if (loading) return <MetricLoading size={size} />;

  if (!exercises?.length) return null;

  return (
    <Metric
      size={size}
      value={sum(data.map(d => d.value)).toString()}
      title={<Trans>Exercise self evaluations</Trans>}
      chartType="bar"
      data={data}
      indexBy="label"
      keys={["value"]}
      colors={{
        datum: "data.color",
      }}
      borderRadius={4}
      feedbackName="CourseUserStatsSelfEvaluationDistribution"
    />
  );
};

export default CourseUserStatsSelfEvaluationDistribution;
