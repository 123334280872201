import React from "react";
import {
  HighlightedText,
  Input,
  Loading,
  ProgramLogo,
  Tabs,
  Typography,
} from "Components";
import {useTranslation} from "react-i18next";
import {AnimateSharedLayout} from "framer-motion";
import useOnboardingProgram, {
  ProgramGroups,
} from "routes/Onboarding/OnboardingProgram/useOnboardingProgram";
import {capitalizeFirstLetter} from "utilities/text";
import OnboardingOptionCard from "routes/Onboarding/OnboardingOptionCard";

const OnboardingProgram = ({compact = false}) => {
  const {t: trans} = useTranslation();

  const {
    programs,
    loading,
    currentProgramId,
    handleProgramSelect,
    groupedPrograms,
    searchTerm,
    setSearchTerm,
  } = useOnboardingProgram();

  if (loading) return <Loading />;

  return (
    <div className="@container w-full ">
      <Input
        placeholder={trans("Search programs")}
        onChange={e => setSearchTerm(e.target.value)}
        name="program"
        value={searchTerm}
        icon="search"
      />

      <div className="mt-4">
        <Tabs type="button" fluid={false}>
          {Object.keys(groupedPrograms).map(key => (
            <Tabs.Pane title={ProgramGroups[key].title} key={key}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-1">
                <AnimateSharedLayout key={key}>
                  {groupedPrograms[key].map(program => {
                    const selected = currentProgramId === program.id;
                    return (
                      <OnboardingOptionCard
                        className="justify-between"
                        onClick={() => handleProgramSelect(program.id)}
                        selected={selected}
                        layoutId={key}
                        compact={compact}
                      >
                        <Typography
                          type={compact ? "tmd" : "tlg"}
                          weight="md"
                          color="text-gray"
                        >
                          <HighlightedText highlight={searchTerm}>
                            {capitalizeFirstLetter(program.name)}
                          </HighlightedText>
                        </Typography>
                        <ProgramLogo
                          programId={program.id}
                          size={compact ? "sm" : "md"}
                        />
                      </OnboardingOptionCard>
                    );
                  })}
                </AnimateSharedLayout>
              </div>
            </Tabs.Pane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default OnboardingProgram;
