import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router";

const useHandleStudyPlanUnlockAction = studyPlan => {
  const {user} = React.useContext(window.UserContext);
  const {openModal} = React.useContext(window.GlobalModalContext);

  const {purchased: coursePurchased, id: courseId} = React.useContext(
    window.CourseContext
  );

  const history = useHistory();
  let location = useLocation();
  const {t: trans} = useTranslation();

  const handler = () => {
    const products = [{productId: studyPlan.id, productType: "STUDY_PLAN"}];

    if (!coursePurchased) {
      products.unshift({
        productId: courseId,
        productType: "COURSE",
      });
    }

    const uri = `/checkout?products=${encodeURIComponent(
      JSON.stringify(products)
    )}`;

    history.push(uri);
  };

  return handler;
};

export default useHandleStudyPlanUnlockAction;
