import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import React from "react";
import classnames from "classnames";
import {Avatar, Icon, Typography} from "Components";
import Math from "Components/Math/Math";
import Trans from "Components/Trans";
import {motion} from "framer-motion";

const parseLinks = text => {
  return text;
  // TODO: Parse links and replace with html
  let html = text;

  html.replace(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    "<a>$1</a>"
  );

  return html;
};

const ExerciseTips = ({title, text, type, user}) => {
  const [collapsed, setCollapsed] = React.useState(true);
  if (!text) return null;

  const markdown = text && text.json; // Set to markdown mode if there is json object (from contentful)

  let icon = "lightbulb";
  let bgColor = "surface-primary";
  let Title = <Trans>Tips</Trans>;

  if (type === "warning") {
    icon = "triangle-exclamation";
    Title = <Trans>Warning</Trans>;
    bgColor = "surface-warning";
  }

  if (type === "gray") {
    bgColor = "surface-gray";
  }

  if (title) {
    Title = title;
  }

  return (
    <motion.div
      className={classnames("rounded-xl", bgColor)}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
    >
      <div
        onClick={() => setCollapsed(!collapsed)}
        className={classnames(
          "flex justify-between items-center p-4 border-gray-100 w-full cursor-pointer",
          collapsed ? "border-b-0" : "border-b-2"
        )}
      >
        <div className="flex items-center gap-2">
          {user ? (
            <div className="exercise-tips-avatar">
              <Avatar user={user} size="sm" />
              {(user.firstName || "") + " " + (user.lastName || "")}
            </div>
          ) : (
            <Icon icon={icon} filled fillType={type} />
          )}
          {React.cloneElement(Title, {
            type: "tmd",
            weight: "sb",
            color: "text-primary-800",
          })}
        </div>
        <motion.div
          className="flex justify-end"
          animate={{
            rotate: collapsed ? 0 : 45,
          }}
        >
          <Icon size="xl" icon="fa-regular fa-plus-circle" />
        </motion.div>
      </div>

      <motion.div
        className="overflow-hidden"
        initial={{
          height: 0,
        }}
        animate={{
          height: collapsed ? 0 : "auto",
        }}
      >
        <div className="mt-2 p-4">
          <Math>
            {markdown ? (
              documentToReactComponents(text.json)
            ) : (
              <Typography type="tsm">{parseLinks(text)}</Typography>
            )}
          </Math>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ExerciseTips;
