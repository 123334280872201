import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import _ from "lodash";

const GET_SOLUTION = gql`
  query getSolution($exerciseId: ID!) {
    getExercise(id: $exerciseId) {
      id
      solution {
        id
        imageUrl
        segments {
          id
          revision
          imageUrl
          createdAt
        }
      }
    }
  }
`;

const useGetExerciseSolution = exerciseId => {
  let solution = null;

  const {data, loading, error} = useQuery(GET_SOLUTION, {
    variables: {exerciseId},
  });

  if (error) {
    console.log("Error in useGetExerciseSolution, ", error);
  }

  if (data && data.getExercise) {
    solution = {...data.getExercise.solution};
  }

  return [solution, loading];
};

export default useGetExerciseSolution;
