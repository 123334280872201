import React from "react";
import {Route, useHistory, useLocation} from "react-router-dom";
import {UserContext} from "./UserContextProvider";

export const AdminRoute = props => {
  const {user} = React.useContext(UserContext);
  const history = useHistory();

  if (!user || !user.admin) {
    history.push({path: "/", replace: true});
    return null;
  }

  return <Route {...props} />;
};

export const AuthRoute = props => {
  const history = useHistory();
  const location = useLocation();
  const {user} = React.useContext(UserContext);

  // If the nextUrl is already set, use that. otherwise, use the incoming path
  const nextUrl = location.state?.nextUrl || props.path;

  React.useEffect(() => {
    if (!user) {
      history.replace("/login", {
        nextUrl,
        message: "You need to login to view this page",
      });
    }
  }, []);

  if (!user) {
    return null;
  }

  return <Route {...props} />;
};

export const NonAuthRoute = props => {
  const {user} = React.useContext(UserContext);
  const history = useHistory();

  let redirectUrl = props.redirectUrl || "/";

  // Check if there is a coupon code in the URL
  const coupon = new URLSearchParams(window.location.search).get("coupon");

  if (coupon) {
    // Redirect to /account/profile?coupon=XXXX
    redirectUrl = `/account/profile?coupon=${coupon}`;
  }

  React.useEffect(() => {
    if (user) {
      const timeout = setTimeout(() => {
        history.replace(redirectUrl);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, []);

  if (user) {
    return null;
  }

  return <Route {...props} />;
};
