import React from "react";
import classnames from "classnames";
import {motion} from "framer-motion";
import {Icon, Typography} from "Components/_Elements";
import {Trans} from "Components/index";

const GPTCostFreeTier = () => {
  const {chatActive, promptInputFocused, freeTierQuota} = React.useContext(
    GPTContext
  );

  const fade = chatActive || promptInputFocused;

  return (
    <motion.div
      className={classnames("flex w-full justify-end p-2")}
      initial={{opacity: 0}}
      animate={{
        opacity: fade ? 0.5 : 1,
      }}
    >
      <motion.div
        className={classnames(
          "flex gap-2 items-center pl-1 pr-3 py-0.5  w-fit rounded-full",
          "bg-success-100"
        )}
      >
        <div className="flex gap-1  items-center rounded-full px-2 py-0.5 bg-success-50">
          <Icon
            icon="fas fa-gift"
            size="xs"
            color="text-success-light"
            fixedWidth
          />
          <Typography
            type="tsm"
            color={"text-success-light"}
            weight="b"
            className="mt-0.5"
          >
            {freeTierQuota.available}/{freeTierQuota.limit}
          </Typography>
        </div>
        <Trans
          type="tsm"
          color="text-success"
          weight="sb"
          className="leading-none whitespace-nowrap"
          inline
        >
          Free conversations remaining today
        </Trans>
      </motion.div>
    </motion.div>
  );
};

export default GPTCostFreeTier;
