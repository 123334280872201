import React from "react";
import {
  AnimatedCheckbox,
  Avatar,
  Button,
  FileUpload,
  message,
  Modal,
  Trans,
} from "Components";
import useUpdateCurrentUserAvatar from "API/mutations/user/useUpdateCurrentUserAvatarMutation";
import renameInputFile from "utilities/renameInputFile";

const AccountPersonalInformationProfilePicture = ({user}) => {
  return (
    <div className="pr-10">
      <div className="rounded-full background shadow-md w-fit h-fit relative">
        <Avatar user={user} alt="profile picture" size="5xl" />
        <Modal
          closable
          trigger={
            <Button
              type="primary"
              size="sm"
              className="absolute bottom-2 right-2 mt-1 mr-1 rounded-full"
              icon="edit"
            />
          }
        >
          <AvatarFileUpload />
        </Modal>
      </div>
    </div>
  );
};

export const AvatarFileUpload = ({icon = null, showSuccessMessage = true}) => {
  const {updateCurrentUserAvatar, loading, data} = useUpdateCurrentUserAvatar();

  if (data && data.updateCurrentUserAvatar?.ok && showSuccessMessage) {
    return (
      <div>
        <AnimatedCheckbox />
        <Trans>Your profile picture has been updated</Trans>
      </div>
    );
  }

  return (
    <FileUpload
      icon={icon}
      disabled={loading}
      maxSize={5000000} // 5 MB
      onFilesAccepted={files => {
        if (files.length === 0) {
          return;
        }

        const extension = files[0].name.split(".").pop();
        const file = renameInputFile(files[0], `avatar.${extension}`);
        updateCurrentUserAvatar({
          variables: {
            file,
          },
        });
      }}
      subTitle={<Trans>SVG, PNG, JPG or GIF (max. 5 MB)</Trans>}
    />
  );
};

export default AccountPersonalInformationProfilePicture;
