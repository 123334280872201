import useGetExerciseThreads from "API/queries/thread/useGetExerciseThreads";
import NewComment from "Components/Thread/ThreadAnswers/NewComment";
import {Trans} from "Components";
import React from "react";
import {useTranslation} from "react-i18next";
import useNewThreadState from "./useNewThreadState";
import EmptyState from "Components/EmptyState/EmptyState";
import {AnimateSharedLayout} from "framer-motion";
import ExerciseDiscussionThread from "Components/Workboard/ExerciseDiscussion/ExerciseDiscussionThread";

const ExerciseDiscussion = ({
  exerciseId,
  hideWhenEmpty,
  discussionPreview = false,
  setCount = () => {},
}) => {
  const allowNewThread = !discussionPreview; // Renaming because it's confusing
  const {t: trans} = useTranslation();

  const {threads, loading} = useGetExerciseThreads(exerciseId);
  React.useEffect(() => {
    if (threads) {
      setCount(threads.length);
    }
  }, [threads]);

  if (loading) return null;

  if (threads.length === 0 && hideWhenEmpty)
    return (
      <div>
        {allowNewThread && (
          <NewComment
            key="new-comment"
            exerciseId={exerciseId}
            state={useNewThreadState}
            placeholder={trans("Start a new discussion")}
          />
        )}
      </div>
    );

  return (
    <AnimateSharedLayout>
      <div
        // key="exercise-discussions"
        // transition={WorkboardLayoutTransition}
        className="mt-4"
      >
        {/*<Trans type="dxs">Discussions</Trans>*/}
        <div className="mt-2" />

        {threads.length > 0 ? (
          threads.map((thread, index) => (
            <div className="mt-4" key={thread.id}>
              <ExerciseDiscussionThread thread={thread} index={index} />
            </div>
          ))
        ) : (
          <EmptyState
            illustration="documents"
            type="gray"
            subTitle={
              <Trans>
                There are no discussions for this exercise yet, be the first one
                to start a thread
              </Trans>
            }
          />
        )}
        {allowNewThread && (
          <NewComment
            key="new-comment"
            exerciseId={exerciseId}
            state={useNewThreadState}
            placeholder={trans("Start a new discussion")}
          />
        )}
      </div>
    </AnimateSharedLayout>
  );
};

export default ExerciseDiscussion;
