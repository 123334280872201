import React from "react";
import {Icon, Typography} from "Components";
import {useLocation} from "react-router-dom";
import useGetCoupon from "API/queries/coupon/useGetCoupon";

const SignupCouponBanner = () => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("coupon");

  const [coupon, loading] = useGetCoupon({id}, true, true);

  if (loading || !coupon) {
    return null;
  }

  // Check if coupon has expired
  if (new Date(coupon.expiryDate) < new Date()) {
    return null;
  }

  const issuerName = coupon.issuerName;
  const amount = coupon.amount / 100;

  return (
    <div className="flex items-center justify-start w-full surface-primary border-2 border-primary p-4 rounded-lg border-2 mb-2">
      <Icon
        icon="fa-regular fa-hand-holding-heart"
        size="xl"
        className="text-secondary-600 mr-4"
      />
      <Typography color="text-primary" type="tsm">
        Tack vare {issuerName} får du {amount}kr i Kollin krediter när du skapar
        ett konto.
      </Typography>
    </div>
  );
};

export default SignupCouponBanner;
