import React from "react";
import {Button, Trans, Typography} from "../../../Components";
import MyCoursesWidget from "routes/MyCourses/MyCoursesWidget";
import useGroupHome from "./useGroupHome";
import {useHistory} from "react-router-dom";

const GroupHomeCoursesCard = () => {
  const history = useHistory();
  const {getGroupCourses} = useGroupHome();

  return (
    <MyCoursesWidget
      size="sm"
      title={<Trans>Courses</Trans>}
      icon="books"
      type="violet"
      subTitle={
        <Typography className="p-2" type="dmd" weight="bold">
          {getGroupCourses().length || 0}
        </Typography>
      }
      action={
        <Button
          type="link"
          size="xs"
          onClick={() => {
            history.push("courses");
          }}
        >
          <Trans>See all</Trans>
        </Button>
      }
    />
  );
};

export default GroupHomeCoursesCard;
