import React from "react";
import {Button, Icon, message, Trans} from "Components";
import PropTypes from "prop-types";
import useSocialLogin from "routes/Auth/SocialLogin/useSocialLogin";
import {LoginSocialFacebook, LoginSocialGoogle} from "reactjs-social-login";

const SocialLoginButton = ({
  provider = "FACEBOOK",
  screenName,
  disabled,
  children,
  handleAuthResponse,
  action,
  ...props
}) => {
  const {onResolve, loading} = useSocialLogin({
    provider,
    screenName,
    handleAuthResponse,
    action,
  });

  if (provider === "GOOGLE") {
    return (
      <LoginSocialGoogle
        // isOnlyGetToken
        client_id="951935468767-chqjrvd5mh7uhhtvh8qbmfffo3s494m4.apps.googleusercontent.com"
        typeResponse="idToken"
        onResolve={res => {
          console.log(res);
          const {data} = res;
          onResolve(data.credential);
        }}
        onReject={error => {
          console.log(error);
          message.error(<Trans>Failed to login with Google</Trans>);
        }}
        scope="https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
      >
        <Button
          disabled={disabled}
          loading={loading}
          type="gray"
          fluid
          {...props}
        >
          <span className="inline-flex gap-2 items-center">
            <Icon
              icon={SocialLoginIcon[provider]}
              color={SocialLoginIconColor[provider]}
            />
            {children}
          </span>
        </Button>
      </LoginSocialGoogle>
    );
  }

  if (provider === "FACEBOOK") {
    return (
      <LoginSocialFacebook
        isOnlyGetToken
        appId={"129021697851868"}
        onResolve={({data}) => {
          onResolve(data.accessToken);
        }}
        onReject={error => {
          console.log(error);
          message.error(<Trans>Failed to login with Facebook</Trans>);
        }}
      >
        <Button
          disabled={disabled}
          loading={loading}
          type="gray"
          fluid
          {...props}
        >
          <span className="inline-flex gap-2 items-center">
            <Icon
              icon={SocialLoginIcon[provider]}
              color={SocialLoginIconColor[provider]}
            />
            {children}
          </span>
        </Button>
      </LoginSocialFacebook>
    );
  }

  return null;
};

const SocialLoginIcon = {
  FACEBOOK: "fa-brands fa-facebook",
  GOOGLE: "fa-brands fa-google",
};

const SocialLoginIconColor = {
  FACEBOOK: "text-facebook",
  GOOGLE: "text-google",
};

SocialLoginButton.propTypes = {
  provider: PropTypes.oneOf(["FACEBOOK", "GOOGLE"]),
};

export default SocialLoginButton;
