import React from "react";

import useGetProductPrices from "API/queries/product/useGetProductPrices";
import {Trans} from "Components";
import GroupDiscountsPriceWrapper from "Widgets/Group/GroupDiscounts/GroupDiscountsPriceWrapper";

const ProductPrice = ({
  productType,
  productId,
  hideOriginalPrice,
  showGroupDiscounts,
  textProps = {},
}) => {
  const [prices, loading] = useGetProductPrices(productType, productId);

  if (loading) return null;

  if (prices.length == 0) return <Trans>Free</Trans>; // Free products don't have price objects

  const defaultPrice = prices.find(price => price.accessDays == 360);

  if (defaultPrice) {
    if (defaultPrice.discountPercentage > 0) {
      return (
        <span>
          {!hideOriginalPrice && (
            <strike>{defaultPrice.priceCents / 100}</strike>
          )}
          {" " + getDiscountedPriceCents(defaultPrice) / 100 + " SEK"}
        </span>
      );
    }

    return (
      // Shows the price with a group discount if the user is in a group
      <GroupDiscountsPriceWrapper
        textProps={textProps}
        defaultPriceCents={defaultPrice.priceCents}
      />
    );
  }

  return (
    // Shows the price with a group discount if the user is in a group
    <GroupDiscountsPriceWrapper
      textProps={textProps}
      defaultPriceCents={prices[0].priceCents}
    />
  );
};

export const getDiscountedPriceCents = price => {
  return price.priceCents - price.priceCents * (price.discountPercentage / 100);
};

export default ProductPrice;
