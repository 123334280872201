import i18n from "i18next";

const validationRules = [
  {
    label: "conflicting_types",
    message: i18n.t("Multiple files can't have the same type"),
    validate: document => {
      const fileTypes = document.files.map(file => file.type);
      // Find duplicates in the file types array:
      return fileTypes.some(
        (fileType, index) =>
          fileTypes.indexOf(fileType) !== index && fileType !== "unknown"
      );
    },
  },
  {
    label: "only_solutions",
    message: i18n.t("Only solutions file can't be uploaded. Please upload exercises as well or change type to mixed."),
    validate: document => {
      const solutionFiles = document.files.filter(
        file => file.type === "exam_solutions"
      );
      return solutionFiles.length === document.files.length;
    },
  },
  {
    label: "unknown_file_type",
    message: i18n.t("Files with unknown type can't be uploaded"),
    validate: document => {
      const unknownFiles = document.files.filter(
        file => file.type === "unknown"
      );
      return unknownFiles.length > 0;
    },
  },
];

const validatedResources = (exam, document) => {
  let exercisesResource = null;
  let solutionsResource = null;

  let error = null;

  // Run validation rules:
  validationRules.forEach(rule => {
    if (rule.validate(document)) {
      error = rule.message || rule.label;
    }
  });

  if (error) {
    return {
      exercisesResource,
      solutionsResource,
      error,
    };
  }

  exercisesResource = document.files.find(file => file.type === "exam");

  solutionsResource = document.files.find(
    file => file.type === "exam_solutions"
  );

  // Fallback to mixed either resource is missing:
  if (!exercisesResource) {
    exercisesResource =
      document.files.find(file => file.type === "mixed") || null;
  }

  if (!solutionsResource) {
    solutionsResource =
      document.files.find(file => file.type === "mixed") || null;
  }

  return {
    exercisesResource,
    solutionsResource,
    error,
  };
};

export default validatedResources;
