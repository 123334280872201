import React from "react";
import AuthLayout from "routes/Auth/AuthLayout";
import {useLocation} from "react-router-dom";
import {Button, message, Trans} from "Components";
import useLogin from "routes/Auth/Login/useLogin";
import LoginForm from "routes/Auth/Login/LoginForm/LoginForm";

import AuthError from "routes/Auth/AuthError";
import useAuthResponseHandler from "routes/Auth/useAuthResponseHandler";
import AuthRequiredAction from "routes/Auth/AuthRequiredAction/AuthRequiredAction";

const Login = ({
  showTitle = true,
  showSignupLink = true,
  onComplete,
  footer = null,
  onSignupClick,
  // Config props
  grid = false,
  transparentInput = false,
  showForgotPasswordLink = true,
  loginActionOTPProps = {
    showModal: false,
  },
  socialButtonsCompact = false,
  wrapper = AuthLayout,
}) => {
  const location = useLocation();

  const {
    handleAuthResponse,
    handleActionCompleted,
    error,
    requiredAction,
  } = useAuthResponseHandler({
    onComplete,
    redirectLink: "/home",
    trackingParams: {
      event: "login",
    },
  });

  const {handleSignUpClick} = useLogin({onSignupClick});

  React.useEffect(() => {
    if (location?.state?.message) {
      message.info(location?.state?.message);
    }
  }, []);

  const Wrapper = wrapper ? wrapper : "div";

  if (requiredAction !== null) {
    // Return action component since we have a required action
    return (
      <Wrapper className="w-full">
        <AuthRequiredAction
          requiredAction={requiredAction}
          actionOTPProps={loginActionOTPProps}
          transparentInput={transparentInput}
          handleActionCompleted={handleActionCompleted}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper className="w-full">
      {showTitle && (
        <div className=" flex flex-col">
          <Trans type="dsm" weight="bold" className="text-gray-900">
            Log in
          </Trans>
          <Trans className="my-2.5">Enter your credentials</Trans>
        </div>
      )}

      <AuthError error={error} handleSignUpClick={handleSignUpClick} />

      <LoginForm
        handleAuthResponse={handleAuthResponse}
        grid={grid}
        transparentInput={transparentInput}
        showForgotPasswordLink={showForgotPasswordLink}
      />

      <AuthLayout.Footer
        socialLoginProps={{
          action: "login",
          facebookButtonPrompt: socialButtonsCompact ? (
            <Trans>Use Facebook</Trans>
          ) : (
            <Trans>Login with Facebook</Trans>
          ),
          googleButtonPrompt: socialButtonsCompact ? (
            <Trans>Use Google</Trans>
          ) : (
            <Trans>Login with Google</Trans>
          ),
          handleAuthResponse,
          socialButtonsCompact,
        }}
      >
        {footer}
        {showSignupLink && (
          <div className="flex gap-2 items-center">
            <Trans inline>Don't have an account on Kollin?</Trans>
            <Button type="link" onClick={handleSignUpClick}>
              <Trans inline>Sign up</Trans>
            </Button>
          </div>
        )}
      </AuthLayout.Footer>
    </Wrapper>
  );
};

export default Login;
