import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";

const UPDATE_USER_EVALUATION = gql`
  mutation updateUserEvaluation($id: ID!, $evaluation: JSON!) {
    updateUserEvaluation(id: $id, evaluation: $evaluation) {
      ok
      error
      userEvaluation {
        id
        objectId
        objectName
        source
        evaluation
      }
    }
  }
`;

const useUpdateUserEvaluation = refetchQueries => {
  const [updateUserEvaluation, {data, loading, error}] = useMutation(
    UPDATE_USER_EVALUATION,
    {
      refetchQueries,
    }
  );

  if (error) {
    console.log(`Error  in useUpdateUserEvaluation ${error}`);
  }

  return [updateUserEvaluation, loading];
};

export default useUpdateUserEvaluation;
