import React from "react";
import useQueryParamsWithPush from "utilities/hooks/useQueryParamsWithPush";
import {ArrayParam, NumberParam} from "use-query-params";

const useOnboardingStepsState = ({user, hideStep = null}) => {
  const [urlStep, setUrlStep] = useQueryParamsWithPush({
    step: NumberParam,
  });

  const {step: currentStepIndex} = urlStep;

  const setCurrentStepIndex = stepIndex => {
    setUrlStep({step: stepIndex});
  };

  React.useEffect(() => {
    if (!user?.id) return;
    const initialStep = getInitialStep(user, hideStep);
    // Update the current step if the initial step is different
    if (currentStepIndex === null || currentStepIndex === undefined) {
      setCurrentStepIndex(initialStep);
    }
  }, [user]);

  return [currentStepIndex, setCurrentStepIndex];
};

const getInitialStep = (user, hideStep) => {
  if (user?.firstName === null && user?.lastName === null && hideStep !== 0)
    return 0;

  if (!user?.school?.id && hideStep !== 1) return 1;
  if (!user?.program?.id && hideStep !== 2) return 2;
  if (!user?.enrollmentYear && hideStep !== 2) return 2; // shown in the same step as program

  return 1; // default to school
};

export default useOnboardingStepsState;
