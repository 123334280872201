import React from "react";

const useExerciseCardScrollIntoView = active => {
  const ref = React.createRef();

  React.useEffect(() => {
    if (active && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  }, [active]);

  return ref;
};

export default useExerciseCardScrollIntoView;
