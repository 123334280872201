import React from "react";
import {v4 as uuidv4} from "uuid";

const useGptProviderState = ({initialPrompt, key}) => {
  const INITIAL_STATE = {
    error: null,
    prompt: initialPrompt || "",
    submitted: false,
  };

  const [state, setState] = React.useState({
    ...INITIAL_STATE,
    key,
    sessionId: uuidv4(), // Generate a random session ID when mounted
  });

  const resetPrompt = () => {
    setState({...state, prompt: "", error: null});
  };

  const resetState = () => {
    setState({...INITIAL_STATE, key, sessionId: uuidv4()});
  };

  const handlePromptChange = e => {
    let value = "";
    if (e.suggestedPrompt) {
      value = e.suggestedPrompt;
    } else {
      value = e.target.value; // <-- moved outside asynchronous context
    }

    setState(prev => ({...prev, prompt: value, error: null}));
  };

  const promptEmpty = React.useMemo(
    () => state.prompt == null || state.prompt.trim().length === 0,
    [state.prompt]
  );

  return {
    ...state,
    promptEmpty,
    resetPrompt,
    resetState,
    handlePromptChange,
  };
};

export default useGptProviderState;
