import React from "react";
import {Icon, Trans, Typography} from "../../../Components";
import moment from "moment";

const SubscriptionTrialTimeline = () => {
  const threeWeeks = moment()
    .add(23, "days")
    .format("Do MMMM");

  const trialEndDate = moment()
    .add(30, "days")
    .format("Do MMMM");

  return (
    <div>
      <Trans type="dxs md:dmd" color="text-white">
        How your free trial will work
      </Trans>
      <div className="flex gap-4 h-[300px] md:h-[350px] mt-4 md:mt-8">
        {/*make a vertical line of height 300px*/}
        <div>
          <div className="w-[35px] h-[90%] bg-white/80 md:bg-white backdrop-blur-lg rounded-3xl text-center z-10 flex flex-col justify-between relative py-4" />
          <div
            className="w-[35px] h-[20%] -mt-5 text-center"
            style={{
              background: "linear-gradient(180deg, #12B76A 0%, #FFFFFF00 100%)", // fallback some times tailwind doesn't work for gradient
            }}
          />
        </div>
        <div className="flex flex-col justify-between pt-2 pb-6">
          <div className="relative">
            <Trans type="tlg md:dxs" color="text-white" weight="sb" inline>
              Today
            </Trans>
            <Trans color="text-gray-200" type="tmd md:tlg">
              Get free access now and change your studies.
            </Trans>
            <div className="absolute -left-12 top-2 z-20">
              <Icon
                icon="fas fa-unlock"
                size="lg"
                color="text-success-600"
                fixedWidth
              />
            </div>
          </div>
          <div className="relative">
            <Typography type="tlg md:dxs" color="text-white" weight="sb">
              {threeWeeks}
            </Typography>
            <Trans color="text-gray-200" type="tmd md:tlg">
              Get a reminder one week before your trial ends.
            </Trans>
            <div className="absolute -left-12 top-2 z-20">
              <Icon
                icon="fas fa-bell"
                size="lg"
                color="text-success-600"
                fixedWidth
              />
            </div>
          </div>
          <div className="relative">
            <Typography type="tlg md:dxs" color="text-white" weight="sb">
              {trialEndDate}
            </Typography>
            <Trans color="text-gray-200" type="tmd md:tlg">
              You will be charged for your first period, cancel anytime before.
            </Trans>
            <div className="absolute -left-12 top-2 z-20">
              <Icon
                icon="fas fa-star"
                size="lg"
                color="text-success-600"
                fixedWidth
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionTrialTimeline;
