import React from "react";
import useGetExerciseNotes from "API/queries/exerciseNote/useGetExerciseNotes";
import {AnimateSharedLayout, motion} from "framer-motion";
import ExerciseNotesListHeader from "./ExerciseNotesListHeader";
import ExerciseNotesListItem from "./ExerciseNotesListItem/ExerciseNotesListItem";

const ExerciseNotesList = ({exerciseId, setCount = () => {}}) => {
  const {user} = React.useContext(window.UserContext);

  const [notes, loading] = useGetExerciseNotes(exerciseId);

  React.useEffect(() => {
    if (notes) {
      setCount(notes.length);
    }
  }, [notes]);

  if (loading || !notes || !user || !notes?.length) return null;

  // Sort notes by updatedAt
  const sortedNotes = notes?.sort((a, b) => {
    return new Date(b.updatedAt) - new Date(a.updatedAt);
  });

  return (
    <div className="w-full">
      <motion.div
        className="overflow-hidden flex flex-col gap-2 mt-4"
        initial={{
          height: "auto",
        }}
      >
        <AnimateSharedLayout type="crossfade">
          {sortedNotes.map((note, index) => {
            return (
              <ExerciseNotesListItem
                key={note.id}
                {...note}
                exerciseId={exerciseId}
                transparent
                index={index}
              />
            );
          })}
        </AnimateSharedLayout>
      </motion.div>
    </div>
  );
};

export default ExerciseNotesList;
