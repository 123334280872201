import React from "react";
import useExamUploadDocumentMatching from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadDocumentMatching";
import useExamUploadContextPreview from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContextPreview";
import useGetCourseExams from "API/queries/exam/useGetCourseExams";
import useExamUploadResourcesState from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadResourcesState";

export const ExamUploadContext = React.createContext(null);
window.ExamUploadContext = ExamUploadContext;

const useExamUploadContext = () => {
  const {courseId} = React.useContext(window.CourseContext);

  const [filesAdded, setFilesAdded] = React.useState(0);
  const [failedFiles, setFailedFiles] = React.useState([]);

  // Get existing exams by date
  const [exams, loading] = useGetCourseExams(courseId);
  const examsByDate = React.useMemo(() => groupExamsByDate(exams), [exams]);

  const {
    resources,
    visibleResources,
    addResource,
    updateResource,
  } = useExamUploadResourcesState({examsByDate, setFilesAdded, failedFiles});

  const {
    documents,
    examsCount,
    unsavedDocumentsCount,
    removeDocumentByDate,
    updateDocumentByDate,
    documentsOverview,
  } = useExamUploadDocumentMatching({
    resources,
    examsByDate,
  });

  const {
    togglePreview,
    previewResource,
    updatePreviewResource,
  } = useExamUploadContextPreview(updateResource);

  let currentStep = 0;
  let totalFiles = filesAdded - failedFiles.length;
  if (filesAdded) currentStep = 1;

  const processingProgress = React.useMemo(() => {
    const total = totalFiles;

    const visibleResources = resources.filter(resource => resource.isNew)
      .length;

    const visibleDocuments = documents["all"].filter(f => f.isNew).length;

    const processed = (visibleResources + visibleDocuments) / 2;

    return Math.max(Math.min(Math.round((processed / total) * 100), 100), 0);
  }, [documents, resources, totalFiles]);

  if (totalFiles > 0 && resources.length > 0 && processingProgress === 100) {
    currentStep = 2;
  }

  const startReview = () => {
    for (const exam of Object.values(documents["dates"])) {
      if (!exam.hidden && !exam.saved) {
        togglePreview(exam.files[0]);
        return;
      }
    }
  };

  return {
    // Resources state
    addResource,
    updateResource,
    resources,
    loading,

    // Preview state
    togglePreview,
    previewResource,
    updatePreviewResource,

    // Document matching state
    documents,
    removeDocumentByDate,
    updateDocumentByDate,
    examsCount,
    unsavedDocumentsCount,
    documentsOverview,

    // Local states
    currentStep,
    processingProgress,
    filesAdded,
    setFilesAdded,
    examsByDate,
    startReview,
    existingExamsCount: exams.length,
  };
};

const groupExamsByDate = exams => {
  const output = {};
  exams.forEach(exam => {
    const cleanDate = exam.date.substring(0, 10);
    output[cleanDate] = exam;
  });
  return output;
};

export default useExamUploadContext;
