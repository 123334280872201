import React from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {GET_GROUP_MEMBER_INVITES} from "API/queries/groups/useGetGroupMemberInvites";

const CREATE_GROUP_MEMBER_INVITE = gql`
  mutation createGroupMemberInvite(
    $groupId: ID!
    $email: String!
    $verified: Boolean!
  ) {
    createGroupMemberInvite(
      groupId: $groupId
      email: $email
      verified: $verified
    ) {
      ok
      error {
        message
        code
      }
      groupMemberInvite {
        id
        email
      }
    }
  }
`;

const useCreateGroupMemberInvite = ({groupId, refetchQueries = []}) => {
  const [createGroupMemberInvite, {data, loading, error}] = useMutation(
    CREATE_GROUP_MEMBER_INVITE,
    {
      errorPolicy: "all",
      refetchQueries: [
        {
          query: GET_GROUP_MEMBER_INVITES,
          errorPolicy: "all",
          variables: {
            id: groupId,
          },
        },
        ...refetchQueries,
      ],
    }
  );

  if (error) {
    console.log(`Error in useCreateGroupMemberInvite: ${error}`);
  }

  console.log(data);

  const response = data?.createGroupMemberInvite;

  return {
    createGroupMemberInvite,
    response,
    loading,
  };
};

export default useCreateGroupMemberInvite;
