import React from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";

const CREATE_EXAM_FROM_RESOURCES = gql`
  mutation createSourceFromResources(
    $courseId: ID!
    $date: String!
    $author: String
    $exercisesResourceId: ID!
    $solutionsResourceId: ID
  ) {
    createSourceFromResources(
      courseId: $courseId
      date: $date
      author: $author
      exercisesResourceId: $exercisesResourceId
      solutionsResourceId: $solutionsResourceId
    ) {
      ok
      error {
        path
        message
      }
    }
  }
`;

const useCreateSourceFromResources = ({refetchQueries, onCompleted}) => {
  const [createSourceFromResources, {loading, data, error}] = useMutation(
    CREATE_EXAM_FROM_RESOURCES,
    {
      refetchQueries,
      onCompleted,
    }
  );

  if (error) {
    console.log("Error in useCreateExamFromResources: ", error);
  }

  return {createSourceFromResources, data, loading};
};

export default useCreateSourceFromResources;
