import React from "react";
import useGetPublicExerciseNotes from "API/queries/exerciseNote/useGetPublicExerciseNotes";

import {ExerciseTips, Typography} from "Components";
import "./ExerciseViewTips.scss";
import Math from "../../../Math/Math";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const parseLinks = text => {
  return text;
  // TODO: Parse links and replace with html
  let html = text;

  html.replace(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    "<a>$1</a>"
  );

  return html;
};

const ExerciseViewTips = ({
  exerciseId,
  collapsible = true,
  setCount = () => {},
  setHidden = () => {},
}) => {
  const [notes, notesLoading] = useGetPublicExerciseNotes(exerciseId);
  const text = notes && notes[0] && notes[0].text;
  const markdown = text && text.json; // Set to markdown mode if there is json object (from contentful)

  React.useEffect(() => {
    if (notes && notes.length > 0) {
      setCount(notes.length);
    } else {
      setHidden(true);
    }
  }, [notes]);

  if (notesLoading || !notes) {
    return null;
  }

  return (
    <div>
      {collapsible ? (
        <ExerciseTips
          text={notes[0].text}
          user={notes[0].user}
          type="gray"
          collapsible={collapsible}
        />
      ) : (
        <div className="mt-2 p-4">
          <Math>
            {markdown ? (
              documentToReactComponents(text.json)
            ) : (
              <Typography type="tmd">{parseLinks(text)}</Typography>
            )}
          </Math>
        </div>
      )}
    </div>
  );
};

export default ExerciseViewTips;
