import Loader from "Components/_Elements/Loader/Loader";
import PropTypes from "prop-types";
import React, {Suspense} from "react";
import "./Editor.css";
import config from "./EditorConfig";
import classnames from "classnames";
import CustomBuild from "kollin-ckeditor5/build/ckeditor";
import CKEditor from "@ckeditor/ckeditor5-react";

const Editor = ({
  state,
  updateText,
  type,
  onFocus,
  onBlur,
  readOnly,
  style,
  placeholder,
  className,
}) => {
  return (
    <Suspense fallback={<Loader />}>
      <div
        className={classnames(
          "editor",
          "border-2 border-color rounded-lg",
          className
        )}
        style={style}
      >
        <CKEditor
          placeholder={placeholder}
          editor={CustomBuild}
          data={state.text || ""}
          config={config[type]}
          onInit={editor => {
            // You can store the "editor" and use when it is needed.
            window.editor = editor;
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            updateText(data);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={readOnly}
        />
      </div>
    </Suspense>
  );
};

Editor.propTypes = {
  state: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
  updateText: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

Editor.defaultProps = {
  type: "regular",
  readOnly: false,
};
export default Editor;
