import {useQueryParams} from "use-query-params";

const useQueryParamsWithPush = (parameterDefinition, prefix = "") => {
  const [urlState, setUrlState] = useQueryParams(parameterDefinition);
  return [urlState, newState => setUrlState(newState, "pushIn")];
};

export const addPrefixToObjectKeys = (obj, prefix) => {
  if (prefix === "") return obj;

  return Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [`${prefix}${key}`]: obj[key],
    };
  }, {});
};

export const removePrefixFromObjectKeys = (obj, prefix) => {
  if (prefix === "") return obj;

  return Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [key.replace(prefix, "")]: obj[key],
    };
  }, {});
};

export default useQueryParamsWithPush;
