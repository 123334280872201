import React from "react";
import PropTypes from "prop-types";
import {SafeText} from "Components";

const TableCardHeader = ({showAsCard, title, subTitle, actions}) => {
  if (!showAsCard) return null;

  return (
    <div className="background rounded-t-lg p-4 gap-4 flex justify-between items-center">
      <div className="grow">
        <SafeText type="tlg" weight="md">
          {title}
        </SafeText>
        <SafeText type="tsm" color="text-gray-light" className="mt-2">
          {subTitle}
        </SafeText>
      </div>
      {actions && actions.length && (
        <div className="flex items-center gap-2">
          {actions.map((action, index) =>
            React.cloneElement(action, {
              key: index,
              size: "sm", // If it's a button, set the size to sm
            })
          )}
        </div>
      )}
    </div>
  );
};

TableCardHeader.propTypes = {
  showAsCard: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  actions: PropTypes.element,
};

export default TableCardHeader;
