import React from "react";
import {EmptyState, FileUpload, Trans, message} from "Components";

const GroupAdminUploadFileDropZone = ({setFile}) => {
  const [error, setError] = React.useState(null);

  return (
    <div>
      <FileUpload
        onFilesAccepted={files => {
          if (files.length > 0) {
            setFile(files[0]);
            return;
          }

          setError(
            <Trans>
              We could not find any file in your upload. Please try again.
            </Trans>
          );
        }}
        multiple={false}
        icon={<EmptyState size="sm" type="gray" illustration="documents" />}
        showIcon
        title={<Trans>Click to upload a list of program members</Trans>}
        accept=".csv"
        subTitle={<Trans>CSV (max. 2 MB)</Trans>}
        onError={error => {
          setError(<Trans>Something went wrong, please try again.</Trans>);
        }}
        error={error}
      />
    </div>
  );
};

export default GroupAdminUploadFileDropZone;
