import React from "react";
import {Icon, SubSection, Trans, Typography} from "Components";
import {accountSubSectionTitleProps} from "routes/Account/Account";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import classnames from "classnames";
import StudentVerification from "Widgets/User/StudentVerification/StudentVerification";

const AccountCreditsStudentVerification = ({
  disabled,
  showTitle = true,
  showSubTitle = true,
}) => {
  const [user, userLoading] = useGetCurrentUser();

  if (userLoading) {
    return null;
  }

  const verified = user.student_email !== null;

  return (
    <>
      <SubSection
        className={classnames({
          "py-4": !user.student_email,
        })}
        title={showTitle && <Trans>Student verification</Trans>}
        subTitle={
          showSubTitle &&
          (verified ? (
            <Trans type="tsm">Your are verified</Trans>
          ) : (
            <Trans type="tsm">
              You need to verify that you are a student before you can redeem a
              coupon or start a trial
            </Trans>
          ))
        }
      >
        {user.student_email ? (
          <div>
            <Typography>
              {user.student_email}{" "}
              <Icon icon="far fa-check-circle" color="text-primary-500" />
            </Typography>
          </div>
        ) : (
          <StudentVerification
            inlineForm
            transparent={false}
            inputHint={
              <Trans type="txs">
                Enter your university e-email address and we will send you a
                verification link
              </Trans>
            }
          />
        )}
      </SubSection>
    </>
  );
};

export default AccountCreditsStudentVerification;
