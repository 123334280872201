import useGetCurrentUserSubscription from "API/queries/user/useGetCurrentUserSubscription";
import React from "react";
import {Trans} from "Components";

import "./SubscriptionStatusBanner.scss";
import moment from "moment";
import {useLocalDate} from "utilities/dates";
import {motion} from "framer-motion";
import findLastOpenInvoice from "utilities/findLastOpenInvoice";

/**
 * A banner for showing various subscription status messages.
 * - past_due: user has a subscription that is past due and pending payments
 */
const SubscriptionStatusBanner = React.memo(() => {
  const {subscription, loading} = useGetCurrentUserSubscription(
    true,
    false,
    false
  );

  if (loading) {
    return null;
  }

  if (subscription?.status == "past_due") {
    return <PastDueBanner subscription={subscription} />;
  }

  return null;
});

const PastDueBanner = ({subscription}) => {
  const lastOpenInvoice = findLastOpenInvoice(subscription.invoices);

  const inferredCancellationDate = useLocalDate(
    moment(lastOpenInvoice.date).add(7, "days"),
    "Do MMMM YYYY"
  );

  return (
    <motion.div
      layout
      key="past_due"
      className="subscription-status-banner"
      data-status={subscription.status}
    >
      <Trans>
        The last payment for your subscription did not succeed. If the payment
        is not completed before
      </Trans>{" "}
      <b>{inferredCancellationDate}</b>{" "}
      <Trans>your subscription will automatically be suspended.</Trans>
      <a
        basic
        href={lastOpenInvoice.hosted_invoice_url}
        target="_blank"
        rel="noreferrer"
      >
        <Trans>Complete payment</Trans>
        <i className="fas fa-external-link-alt" />
      </a>
    </motion.div>
  );
};

export default SubscriptionStatusBanner;
