import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import {setAuthenticated} from "utilities/authentication";
import {message} from "Components";

const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ok
      requiredAction {
        action
        email
      }
      token
      refreshToken
      errors {
        code
        path
        message
      }
    }
  }
`;

const useLoginUser = onCompleted => {
  // Graphql mutation
  const [login, {data, error, loading}] = useMutation(LOGIN_MUTATION, {
    // onCompleted: data => {
    //   if (data.login.ok) {
    //     const { token, refreshToken } = data.login;
    //     setUser(setAuthenticated(token, refreshToken));
    //   }
    //   // catch the errors and set in the state
    //   else {
    //     message.error(data.login.errors[0].message);
    //   }
    // },
    onCompleted,
  });

  return {
    login,
    loading,
    data,
  };
};

export default useLoginUser;
