import React from "react";
import moment from "moment/moment";
import {debounce} from "lodash";

const useDatePickerInputState = ({currentDate, onDateSelected, format}) => {
  const [inputValue, setInputValue] = React.useState("");

  // Try to parse the date and set it if it's valid after 1 second

  const debouncedSetParsedDate = React.useCallback(
    debounce(value => {
      const parsedDate = moment(value);

      if (parsedDate.isValid()) {
        onDateSelected(parsedDate);
      }
    }, 1000),
    []
  );

  /**
   * Handle the date input change
   * @param e
   */
  const handleDateInputChange = e => {
    const dateString = e.target.value;

    setInputValue(dateString);
    debouncedSetParsedDate(dateString);
  };

  React.useEffect(() => {
    setInputValue(currentDate ? currentDate.format(format) : "");
  }, [currentDate]);

  return {
    inputValue,
    handleDateInputChange,
  };
};

export default useDatePickerInputState;
