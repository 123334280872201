import React from "react";
import { Button, ProgressBar, Trans } from "Components";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";

const ExamUploadSaveBanner = () => {
  const { documentsOverview } = React.useContext(ExamUploadContext);
  const { id } = React.useContext(window.CourseContext);

  const ratio = documentsOverview.savedExams / documentsOverview.totalExams;
  let complete = false;
  if (ratio === 1) {
    complete = true;
  }
  const history = useHistory();

  if (documentsOverview["newFiles"] === 0) return null;

  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      className="surface fixed bottom-0 -ml-2 z-20 border-t-2 border-gray shadow-t-sm"
      style={{
        width: document.getElementById("course-main-panel").offsetWidth,
      }}
    >
      <div className="px-8 py-4 flex gap-4 justify-between items-center">
        <div className="grow">
          <Trans type="txl">Review progress</Trans>
          <ProgressBar type="primary" progressRatio={ratio} />
        </div>
        <Button
          type="primary"
          onClick={() => {
            history.push(`/course/${id}/exams`);
          }}
          disabled={!complete}
        >
          <Trans>Finish upload</Trans>
        </Button>
      </div>
    </motion.div>,
    document.getElementById("course-main-panel")
  );
};

export default ExamUploadSaveBanner;
