import React from "react";
import useExerciseEvaluationMutation from "API/mutations/exercise/useExerciseEvaluationMutation";
import useGetExerciseEvaluation from "API/queries/exercise/useGetExerciseEvaluation";
import useEventTracking from "utilities/hooks/useEventTracking";
import {ExerciseEvaluationContext} from "../ExerciseEvaluation";
import {motion} from "framer-motion";
import "moment/locale/sv";
import {ButtonGroup} from "Components";
import {difficultyColorsToBorderColor} from "utilities/constants/COLORS";
import useKeyboardShortcuts from "utilities/hooks/useKeyboardShortcuts";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";
import ExerciseEvaluationDifficultyAssessmentButton from "Widgets/Exercise/ExerciseEvaluation/ExerciseEvaluationDifficultyAssessment/ExerciseEvaluationDifficultyAssessmentButton";
import ExerciseEvaluationCommunityDistribution from "../ExerciseEvaluationCommunityDistribution";

const ExerciseEvaluationDifficultyAssessment = ({setCount = () => {}}) => {
  const {user} = React.useContext(window.UserContext);
  const {id: courseId} = React.useContext(window.CourseContext);

  const smallScreens = useBreakPointSM();

  const {
    exerciseId,
    updateUserEvaluationScore,
    exerciseEvaluationRefetchQueries,
  } = React.useContext(ExerciseEvaluationContext);

  const [currentScore, evaluatedAt] = useGetExerciseEvaluation(exerciseId);
  const [updateExerciseEvaluation] = useExerciseEvaluationMutation(
    exerciseId,
    exerciseEvaluationRefetchQueries
  );
  React.useEffect(() => {
    if (currentScore) {
      setCount(1);
    }
  }, [currentScore]);

  const {logExerciseEvent, logUserProperties} = useEventTracking();

  const handleEvaluationClick = score => {
    updateExerciseEvaluation(score);
    updateUserEvaluationScore(score);
    logExerciseEvent("self_evaluation_clicked", {score});
  };

  useKeyboardShortcuts(
    [1, 2, 3, 4].map(n => ({
      key: n.toString(),
      action: () => handleEvaluationClick(n),
    })),
    []
  );

  const disabled = !user?.id;

  return (
    <div className="mt-4 w-full">
      <div className="w-full">
        {/*<Trans weight="sb">You</Trans>*/}

        <ButtonGroup className="" fluid>
          {[1, 2, 3, 4].map((score, idx) => (
            <ExerciseEvaluationDifficultyAssessmentButton
              type={score === currentScore ? "secondary" : "gray"}
              key={idx}
              onClick={() => handleEvaluationClick(score)}
              disabled={disabled}
              className={
                score === currentScore && difficultyColorsToBorderColor[score]
              }
              score={score}
            />
          ))}
        </ButtonGroup>
      </div>
      {currentScore && (
        <motion.div
          className="-mb-8"
          initial={{
            opacity: currentScore ? 1 : 0,
            height: currentScore ? "auto" : 0,
          }}
          animate={{
            opacity: 1,
            height: "auto",
          }}
          transition={{
            delay: 0.5,
          }}
        >
          <ExerciseEvaluationCommunityDistribution
            exerciseId={exerciseId}
            currentUserScore={currentScore}
          />
        </motion.div>
      )}
    </div>
  );
};

export default ExerciseEvaluationDifficultyAssessment;
