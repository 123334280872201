import React from "react";
import useGetCurrentUserActiveCourses from "API/queries/course/useGetCurrentUserActiveCourses";
import useOutsideClickAlerter from "utilities/hooks/useOutsideClickAlerter";

const useCourseSidebarHeaderPicker = currentCourseId => {
  const ref = React.useRef(null);
  const [courses, loading] = useGetCurrentUserActiveCourses();

  const [showCoursePicker, setShowCoursePicker] = React.useState(false);

  const activateCoursePicker = () => {
    setShowCoursePicker(true);
  };

  const hideCoursePicker = () => {
    setShowCoursePicker(false);
  };

  const courseList = React.useMemo(
    () => courses.filter(course => course.id !== currentCourseId).slice(0, 5),
    [courses, currentCourseId]
  );

  useOutsideClickAlerter(ref, hideCoursePicker);

  return {
    ref,
    showCoursePicker,
    activateCoursePicker,
    hideCoursePicker,
    loading,
    courseList,
  };
};

export default useCourseSidebarHeaderPicker;
