import React from "react";
import useCourseKollinGPTSavedChapters from "routes/Course/CourseKollinGPT/CourseKollinGPTSaved/useCourseKollinGPTSavedChapters";
import useCourseKollinGptSavedNotes from "routes/Course/CourseKollinGPT/CourseKollinGPTSaved/useCourseKollinGPTSavedNotes";

const useCourseKollinGPTSaved = () => {
  const [chapters, chaptersLoading] = useCourseKollinGPTSavedChapters();
  const [notes, notesLoading] = useCourseKollinGptSavedNotes();

  const conversations = React.useMemo(() => {
    if (chaptersLoading || notesLoading) return [];

    let results = [
      ...chapters.map(chapter => ({
        ...chapter,
        type: "chapter",
      })),
      ...notes.map(note => ({
        ...note,
        type: "note",
      })),
    ];

    results.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return results;
  }, [chaptersLoading, notesLoading, chapters, notes]);

  const loading = chaptersLoading || notesLoading;

  return [conversations, loading];
};

export default useCourseKollinGPTSaved;
