import React from "react";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {Button, Icon, Link, Typography} from "Components";
import {userContext} from "UserContextProvider";

const GroupAdminUploadPanesInviteLink = () => {
  const {name, currentUserMembership} = useGroupContext();
  const inviteLink = `https://${window.location.hostname}/invite?r=${currentUserMembership?.id}`;
  const [copied, setCopied] = React.useState(false);

  return (
    <div className="flex flex-col gap-4 p-2  items-start rounded-md w-full overflow-hidden">
      <div className="flex items-center w-full gap-2 mb-2">
        <Icon icon="link" size="md" color="text-primary-light" />
        <Typography type="tmd" weight="sb" color="text-primary-700">
          Inbjudanslänk till {name}
        </Typography>
      </div>

      <Link
        href={inviteLink}
        target="_blank"
        rel="noopener noreferrer"
        className="w-full whitespace-break-spaces overflow-hidden break-all"
      >
        <Typography
          type="tmd"
          weight="md"
          color="text-gray-500 light underline decoration-2 decoration-primary-200 underline-offset-2"
        >
          {inviteLink}
        </Typography>
      </Link>
      <Button
        type="ghost"
        size="sm"
        onClick={() => {
          setCopied(true);

          navigator.clipboard.writeText(inviteLink);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        }}
        iconLeft={copied ? "check-circle" : "copy"}
        fluid={false}
      >
        <Typography>{copied ? "Kopierad!" : "Kopiera"}</Typography>
      </Button>
    </div>
  );
};

export default GroupAdminUploadPanesInviteLink;
