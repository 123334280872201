import React from "react";
import PropTypes from "prop-types";
import TabsPane from "./TabsPane/TabsPane";
import useTabs from "Components/_Elements/Tabs/useTabs";
import classnames from "classnames";
import {AnimateSharedLayout} from "framer-motion";

const Tabs = ({
  defaultSelectedTabKey,
  children,
  fluid = false,
  type = "underline",
  size = "md",
  align = "left",
  orientation = "horizontal",
  onTabChange,
  contentClassName,
  persistCurrentTabInUrl = false,
  urlParamName = "tab",
  className,
  animate = true,
}) => {
  const vertical = orientation === "vertical";

  const {selectedTabKey, setSelectedTabKey, SelectedTabContent} = useTabs({
    defaultSelectedTabKey,
    children,
    onTabChange,
    persistCurrentTabInUrl,
    urlParamName,
  });

  return (
    <div
      className={classnames(
        "flex relative ",
        align === "right" ? "items-end" : "items-start",
        vertical ? "flex-row gap-2" : "flex-col", // alignment of the tabs and content

        className
      )}
    >
      <AnimateSharedLayout>
        <div
          className={classnames(
            "flex rounded-lg gap-2 group/tabheader",
            vertical
              ? "flex-col sticky top-0 gap-4 w-fit" // vertical tabs
              : "flex-row overflow-x-auto max-w-screen touch-pan-x", // horizontal tabs
            fluid ? "w-full" : "w-fit", // fluid tabs, but on small screens we must have full width otherwise scroll doesn't work
            type === "button-gray"
              ? "bg-gray-50 border-1 border-gray-100 p-1.5 gap-2"
              : "py-2 bg-transparent"
          )}
        >
          {React.Children.map(children, (child, index) => {
            if (React.isValidElement(child)) {
              const {
                key,
                props: {title},
              } = child;

              // Make sure the type is correct
              if (child.type !== TabsPane) {
                throw new Error(
                  `Tabs children must be of type TabsPane. Found ${child.type} instead.`
                );
              }

              // Validate the props of the child, if they are missing we don't return that TabsPane
              if (key !== null && title !== null) {
                // On click, set the selectedTabKey to the child's key
                const onClick = () => {
                  // call the onClick function if it exists
                  if (child.props.onClick) {
                    child.props.onClick();
                  } else {
                    setSelectedTabKey(key);
                  }
                };

                const selected = selectedTabKey === key;

                return React.cloneElement(child, {
                  onClick,
                  selected,
                  fluid: fluid || vertical, // always fluid if vertical
                  type,
                  size,
                  vertical,
                  tabKey: key,
                  animate,
                });
              }
            }
          })}
        </div>
      </AnimateSharedLayout>
      <div
        className={classnames(
          "tabs-content w-full py-3 overflow-hidden",
          contentClassName
        )}
      >
        {SelectedTabContent}
      </div>
    </div>
  );
};

/**
 *  defaultSelectedTabKey,
 *   children,
 *   fluid = false,
 *   orientation = "horizontal",
 * @type {{defaultSelectedTabKey: Requireable<string>, type: Requireable<string>}}
 */
Tabs.propTypes = {
  /**
   * The key of the tab that should be selected by default
   */
  defaultSelectedTabKey: PropTypes.string,
  /**
   * The type of the tabs,
   */
  type: PropTypes.oneOf(["underline", "button", "button-gray"]),
  /**
   * The size of the tabs,
   */
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  /**
   * The alignment of the tabs (only works for horizontal orientation),
   */
  align: PropTypes.oneOf(["left", "right"]),
  /**
   * The orientation of the tabs
   */
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  /**
   * The children of the tabs, should be of type TabsPane
   */
  children: PropTypes.node.isRequired,
  /**
   * Whether the tabs should be fluid or not (take the full width of the parent) only works for horizontal orientation
   */
  fluid: PropTypes.bool,
};

// Set the child component as a property of the Tabs component for easier access.
Tabs.Pane = TabsPane;

export default Tabs;
