import React from "react";
import {WorkboardContext} from "Components/Workboard/Workboard";

export const GeogebraContext = React.createContext();
window.GeogebraContext = GeogebraContext;

const GeogebraProvider = ({children, dependency}) => {
  const {forceActivateTextMode} = React.useContext(WorkboardContext);

  const [currentEquation, setCurrentEquation] = React.useState(null);
  const [selectorActive, setSelectorActive] = React.useState(false);

  const toggleSelector = () => {
    forceActivateTextMode();

    if (currentEquation !== null) {
      setCurrentEquation(null);
    }

    setSelectorActive(prev => !prev);
  };

  React.useEffect(() => {
    if (dependency) {
      // Reset context when dependency changes
      setCurrentEquation(null);
      setSelectorActive(false);
    }
  }, [dependency]);

  return (
    <GeogebraContext.Provider
      value={{
        currentEquation,
        toggleSelector,
        selectorActive,
        setCurrentEquation,
        visualisationActive: currentEquation !== null,
      }}
    >
      {children}
    </GeogebraContext.Provider>
  );
};

export default GeogebraProvider;
