import React from "react";
import useResetPassword from "API/mutations/resetPassword/useResetPassword";
import {useFormValidation} from "utilities/hooks/useFormValidation";
import {validatePassword} from "utilities/validators";
import normalizeErrors from "utilities/normalizeErrors";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {message} from "Components";
import useAuthResponseHandler from "routes/Auth/useAuthResponseHandler";

const useResetPasswordState = (token, onComplete) => {
  const history = useHistory();
  const {t: trans} = useTranslation();

  const {authenticate} = useAuthResponseHandler({
    onComplete: null,
    redirectLink: "/home",
    trackingParams: {
      event: "reset_password",
    },
  });
  const {data, loading, resetPassword} = useResetPassword();

  const {handleChange, values, errors, submit, setErrors} = useFormValidation(
    {
      password: "",
      token,
    },
    resetPassword,
    validatePassword
  );

  // Catching the response in data
  React.useEffect(() => {
    if (data) {
      if (data.resetPassword.ok) {
        message.success(trans("The password has been reset"));

        if (onComplete) {
          onComplete(data.resetPassword); // Send the response to the login handler
          return;
        }

        // Login the user
        authenticate(data.resetPassword);
      } else {
        setErrors(normalizeErrors(data.resetPassword.errors));
        message.error(trans("Could not reset"));
      }
    }
  }, [data]);

  return {
    handleChange,
    values,
    errors,
    submit,
    data,
    loading,
  };
};

export default useResetPasswordState;
