import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GET_EXERCISE_THREADS } from "API/queries/thread/useGetExerciseThreads";

const CREATE_COMMENT = gql`
  mutation createComment(
    $threadId: ID!
    $commentId: ID
    $text: String!
    $username: String
    $anonymous: Boolean
  ) {
    createComment(
      thread_id: $threadId
      comment_id: $commentId
      text: $text
      username: $username
      anonymous: $anonymous
    ) {
      ok
      error
    }
  }
`;

const useCreateCommentMutation = (exerciseId, onCompleted = () => {}) => {
  const [createComment, { data, error, loading }] = useMutation(
    CREATE_COMMENT,
    {
      refetchQueries: [
        { query: GET_EXERCISE_THREADS, variables: { exerciseId } },
      ],
      onCompleted,
    }
  );

  return { createComment, data, loading };
};

export default useCreateCommentMutation;
