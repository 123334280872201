import React from "react";
import { Button, Document, Typography } from "Components";
import useExamUploadDocumentsPreviewState from "./useExamUploadDocumentsPreviewState";
import ReactDOM from "react-dom";

const ExamUploadDocumentsPreview = () => {
  const {
    container,
    resource,
    toggleExpanded,
    expanded,
    onClose,
  } = useExamUploadDocumentsPreviewState();

  if (!resource) return null;

  const resourceName = resource.name || resource.url;

  const handleClose = () => {
    if (expanded) {
      toggleExpanded();
    } else {
      onClose();
    }
  };

  const Content = (
    <div className="background " key="preview">
      <div
        className="flex items-center justify-between p-4 surface rounded-lg"
        key="preview-document-header"
      >
        <Typography type="tlg" weight="md" resourceName={resourceName}>
          {resourceName}
        </Typography>
        <div className="flex gap-2">
          <Button
            icon={expanded ? "compress" : "expand"}
            type="default"
            onClick={toggleExpanded}
          />
          <Button icon="close" type="destructive" onClick={handleClose} />
        </div>
      </div>
      <div
        key="preview-document"
        ref={container}
        className="max-h-screen overflow-x-hidden overflow-y-auto mt-2"
      >
        <Document
          container={container}
          url={resource.url}
          fileType={"pdf"}
          showAllPages={true}
        />
      </div>
    </div>
  );

  return expanded
    ? ReactDOM.createPortal(
        <div>
          <div className="modal-background" />
          <div className="z-50 rounded-xl background w-[80vw] absolute left-1/2 top-10 -translate-x-1/2">
            {Content}
          </div>
        </div>,
        document.body
      )
    : Content;
};

export default ExamUploadDocumentsPreview;
