import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";

export const CREATE_EXERCISE = gql`
  mutation createExercise(
    $sourceId: ID!
    $sourceImageUrl: String!
    $number: String!
    $boundingBox: BoundingBoxInput!
    $published: Boolean!
  ) {
    createExercise(
      sourceId: $sourceId
      sourceImageUrl: $sourceImageUrl
      number: $number
      boundingBox: $boundingBox
      published: $published
    ) {
      ok
      error {
        message
        code
      }
      exercise {
        id
        imageUrl
      }
    }
  }
`;

const useCreateExercise = sourceId => {
  const [createExercise, {loading, data, error}] = useMutation(
    CREATE_EXERCISE,
    {
      onError: error => {
        console.log("Error in useCreateExercise: ", error);
      },
    }
  );

  if (error) {
    console.log("Error in useCreateExercise: ", error);
  }

  const result = data?.createExercise;

  return {
    createExercise,
    loading,
    result,
    error,
  };
};

export default useCreateExercise;
