import React from "react";

const ProductCardImage = ({imageUrl, name, vertical}) => {
  return (
    <div className="h-1/2  @sm:col-span-3 flex justify-center items-start bg-gradient-to-r">
      <img src={imageUrl} alt={name} className="w-full" />
    </div>
  );
};
export default ProductCardImage;
