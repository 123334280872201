import React from "react";
import {Icon} from "Components";

const PreviewSidebarResizeHandle = ({resizeHandleRef, sidebarWidth}) => {
  return (
    <div
      key="preview-sidebar-resize-handle"
      className="fixed top-0 z-[60] w-2 h-full cursor-col-resize flex flex-col items-center justify-center"
      style={{right: sidebarWidth - 8}}
      ref={resizeHandleRef}
    >
      <Icon icon="ellipsis-vertical" size="xl" color="text-gray-800" sharp />
    </div>
  );
};

export default PreviewSidebarResizeHandle;
