import SubscriptionStatusBanner from "./SubscriptionStatusBanner/SubscriptionStatusBanner";
import React from "react";

/**
 * This is a controller component that renders a list of banners
 * in the priority order provided in the banners array.
 *
 * For example, if the first component renders, no more components will be rendered
 * since it has higher priority.
 *
 * If a component returns null, it will be skipped.
 *
 * By using this component:
 * - we don't need to render each banner separately in e.g. App.jsx
 * - we can make sure the position of each banner is correct and
 * - we don't have stacked banners (multiple banners rendered on top of each other)
 *
 * Banners are mounted under the UserMenu component. /src/Components/Navbars/UserMenu/UserMenu.jsx
 */
const Banners = () => {
  const banners = [SubscriptionStatusBanner];

  return banners.map((Banner, index) => <Banner key={index} />);
};

export default Banners;
