import React from "react";
import {motion} from "framer-motion";
import usePreviewSidebarState from "Wrappers/PreviewSidebar/usePreviewSidebarState";
import PreviewSidebar from "Wrappers/PreviewSidebar/PreviewSidebar";
import usePreviewSidebarSizeState from "Wrappers/PreviewSidebar/usePreviewSidebarSizeState";

export const PreviewSidebarContext = React.createContext(null);
window.PreviewSidebarContext = PreviewSidebarContext;

const PreviewSidebarWrapper = ({Router}) => {
  const {
    previewOpen,
    setPreview,
    previewState,
    handleClosePreview,
  } = usePreviewSidebarState();

  const {
    mainBarWidth,
    sidebarWidth,
    resizeHandleRef,
  } = usePreviewSidebarSizeState();

  // console.log("previewState", previewState);

  return (
    <>
      <PreviewSidebarContext.Provider
        value={{
          setPreview,
          openPreview: setPreview,
          handleClosePreview,
          currentPreviewRoute: previewState.route,
          previewSidebarIsOpen: previewOpen,
        }}
      >
        <motion.div
          initial={false}
          animate={{
            width: previewOpen ? mainBarWidth : "100%",
          }}
          transition={{
            // type: "just",
            type: "ease",
            duration: 0,
          }}
        >
          {/*  main content router is always rendered */}
          {Router}
        </motion.div>
      </PreviewSidebarContext.Provider>

      <PreviewSidebarContext.Provider
        value={{
          setPreview,
          openPreview: setPreview,
          handleClosePreview,
          previewMode: previewOpen,
          currentPreviewRoute: previewState.route,
          previewOpen,
        }}
      >
        <PreviewSidebar
          {...previewState}
          previewOpen={previewOpen}
          Router={Router}
          handleClosePreview={handleClosePreview}
          sidebarWidth={sidebarWidth}
          resizeHandleRef={resizeHandleRef}
        />
      </PreviewSidebarContext.Provider>
    </>
  );
};

export default PreviewSidebarWrapper;
