import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "utilities/hooks/useWindowSize";
import "./Stats.scss";
import { Trans } from "react-i18next";
import { Typography } from "Components/_Elements";

const Stats = ({ stats }) => {
  const [vWidth] = useWindowSize();
  if (vWidth < 768) stats.reverse();

  return (
    <div className=" px-4 bg-primary-50 grid grid-cols-2 md:grid-cols-4 gap-x-4 md:gap-x-8 justify-items-center">
      {stats.map(({ value, name }, index) => (
        <div key={index} className="flex flex-col justify-center items-center">
          <Typography className="text-secondary-600" type="dmd" weight="b">
            {getValue(value)}
          </Typography>

          <Trans>{name}</Trans>
        </div>
      ))}
    </div>
  );
};

const getValue = value => {
  try {
    const num = Number(value);
    if (num > 1000) {
      return Math.round(num / 1000) + "k";
    }
    return value;
  } catch (error) {
    return value;
  }
};

Stats.propTypes = {
  text: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // Fontawesome icon full name
  icon: PropTypes.string.isRequired,
  // Text for the link
  link: PropTypes.string,
  linkAction: PropTypes.func,
};

export default Stats;
