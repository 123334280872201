import React from "react";
import PropTypes from "prop-types";
import {orientationClasses, sizeClasses} from "./ProgressSteps.constants";
import classnames from "classnames";
import ProgressStepsStep from "Components/_Elements/ProgressSteps/ProgressStepsStep";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";
const ProgressSteps = ({
  size = "md",
  vertical = false,
  numbered = false,
  steps = [],
  onStepClick = () => {},
  currentStep = null,
  className,
}) => {
  const smallScreens = useBreakPointSM();

  let orientation = smallScreens(
    "vertical",
    vertical ? "vertical" : "horizontal"
  );

  if (!steps.length) {
    return null;
  }

  let currentStepIdx =
    currentStep === null
      ? steps.findIndex(step => step.status === "current") || null // Find the current step index
      : currentStep;

  return (
    <div
      className={classnames(
        "flex w-full justify-between items-start",
        orientationClasses[orientation],
        sizeClasses[size],
        className
      )}
    >
      {steps.map((step, index) => {
        //  if the step is already an element of type ProgressStepsStep, just return it otherwise create a new one
        if (React.isValidElement(step)) {
          return React.cloneElement(step, {
            index,
            numbered,
            orientation,
            size,
            isLast: index === steps.length - 1,
            selected: currentStepIdx === index || step.status === "current",
            currentStepIdx,
          });
        }

        return (
          <ProgressStepsStep
            onStepClick={onStepClick}
            {...step}
            key={index}
            index={index}
            numbered={numbered}
            orientation={orientation}
            size={size}
            isLast={index === steps.length - 1}
            selected={currentStepIdx === index || step.status === "current"}
            currentStepIdx={currentStepIdx}
          />
        );
      })}
    </div>
  );
};

ProgressSteps.propTypes = {
  /**
   * The size of the progress steps
   */
  size: PropTypes.oneOf(Object.keys(sizeClasses)),
  /**
   * The orientation of the progress steps - default is horizontal on desktop and vertical on small screens
   */
  vertical: PropTypes.bool,
  /**
   *  If numbered, the number is shown instead of a bullet
   */
  numbered: PropTypes.bool,

  steps: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        /**
         * The title of the step
         */
        title: PropTypes.string.isRequired,
        /**
         * The description of the step (optional)
         */
        description: PropTypes.string,
        /**
         * The status of the step
         */
        status: PropTypes.oneOf(["current", "complete", "incomplete"])
          .isRequired,
      }),
      PropTypes.element,
    ])
  ).isRequired,
};

export default ProgressSteps;
