import React from "react";
import * as RadixPopover from "@radix-ui/react-popover";
import PropTypes from "prop-types";
import classnames from "classnames";

const Popover = ({
  children,
  isOpen,
  onOpenChange,
  content,
  side,
  zIndex = "z-10",
  asChild = false,
}) => {
  return (
    <RadixPopover.Root open={isOpen} onOpenChange={onOpenChange}>
      <RadixPopover.Trigger asChild={asChild}>{children}</RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          sideOffset={5}
          side={side}
          className={classnames(
            "rounded-md p-4 bg-white shadow-lg border-gray border-2 focus:outline-none",
            zIndex
          )}
          isOpen={isOpen}
        >
          {content}
          <RadixPopover.Arrow className="fill-white" />
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

Popover.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
  open: PropTypes.bool,
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
};

export default Popover;
