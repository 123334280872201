import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";

export const UPDATE_EXERCISE = gql`
  mutation updateExercise(
    $id: ID!
    $number: String
    $imageUrl: String
    $sourceImageUrl: String
    $boundingBox: BoundingBoxInput
    $published: Boolean
  ) {
    updateExercise(
      id: $id
      imageUrl: $imageUrl
      number: $number
      boundingBox: $boundingBox
      sourceImageUrl: $sourceImageUrl
      published: $published
    ) {
      ok
      error {
        message
        code
      }
      exercise {
        id
        imageUrl
        number
      }
    }
  }
`;

const useUpdateExercise = () => {
  const [updateExercise, {loading, data, error}] = useMutation(
    UPDATE_EXERCISE,
    {
      onError: error => {
        console.log("Error in useUpdateExercise: ", error);
      },
    }
  );

  if (error) {
    console.log("Error in useUpdateExercise: ", error);
  }

  const result = data?.updateExercise;

  return {
    updateExercise,
    loading,
    result,
    error,
  };
};

export default useUpdateExercise;
