import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";

const UPDATE_USER = gql`
  mutation updateUser(
    $firstName: String
    $lastName: String
    $enrollmentYear: Int
    $schoolId: ID
    $programId: ID
    $settings: JSON
  ) {
    updateUser(
      first_name: $firstName
      last_name: $lastName
      enrollment_year: $enrollmentYear
      school_id: $schoolId
      program_id: $programId
      settings: $settings
    ) {
      ok
      errors {
        path
        message
      }
      user {
        id
        first_name
        last_name
        school {
          id
          name
        }
        program {
          id
          name
        }
        enrollment_year
      }
    }
  }
`;

const useUpdateCurrentUserMutation = (
  onCompleted = () => {},
  refetchQueries = []
) => {
  const [updateUserMutation, {loading, errors}] = useMutation(UPDATE_USER, {
    onCompleted,
    refetchQueries: refetchQueries || [],
  });

  return [updateUserMutation, loading];
};

export default useUpdateCurrentUserMutation;
