import React from "react";

import {validatePhoneNumber} from "utilities/validators";

const usePaymentMethodSwishState = ({action, onCancel, actionFailed}) => {
  const [state, setState] = React.useState({
    phoneNumber: "",
    error: null,
    submitted: false,
  });

  const onChange = e => setState({phoneNumber: e.target.value});

  const submit = () => {
    let error = validatePhoneNumber(state.phoneNumber);

    if (error) {
      setState({...state, error, submitted: false});
    } else {
      // validation successful:
      action({
        phoneNumber: `46${state.phoneNumber.substring(1)}`,
        paymentMethod: "SWISH",
      });

      setState({...state, error: null, submitted: true});
    }
  };

  const handleCancel = () => {
    setState({...state, submitted: false});
    if (onCancel) onCancel();
  };

  React.useEffect(() => {
    if (actionFailed) {
      setState({...state, submitted: false});
    }
  }, [actionFailed]);

  return {...state, onChange, handleCancel, submit};
};

export default usePaymentMethodSwishState;
