import React from "react";
import PropTypes from "prop-types";

import {Button, TextArea, StarRating, Typography} from "Components";
import {useTranslation} from "react-i18next";
import "./CourseReviewForm.css";
import Trans from "Components/Trans";

const CourseReviewForm = ({state, cancel, cancelButtonText}) => {
  const {t: trans} = useTranslation();
  const {values, handleChange, submit, errors, setFormValue, loading} = state;

  const desc = [
    trans("Terrible"),
    trans("Bad"),
    trans("Fine"),
    trans("Good"),
    trans("Great"),
  ];

  const submitForm = () => {
    if (typeof cancel === "function") {
      cancel();
    }
    submit();
  };

  return (
    <form className="review-card">
      <div className="flex gap-2 items-center mt-2">
        <StarRating
          rating={values.score}
          clickable
          onChange={value => setFormValue({score: value})}
        />
        {values.score ? (
          <Typography type="txs" color="text-warning-600">
            {desc[values.score - 1]}
          </Typography>
        ) : (
          ""
        )}
      </div>
      <TextArea
        className="mt-2 rounded-lg"
        placeholder={trans("Your review")}
        name="text"
        text={values.text}
        onChange={handleChange}
        expandOnFocus
      />

      {errors.text && <p className="red">{errors.text}</p>}

      {values.text?.length > 0 && (
        <Button onClick={submitForm} loading={loading} className="mt-2">
          <Trans>Post your review</Trans>
        </Button>
      )}
      <div className="vs" />
      {cancel && (
        <Button onClick={cancel}>
          {cancelButtonText ? cancelButtonText : <Trans>Cancel</Trans>}
        </Button>
      )}
    </form>
  );
};

CourseReviewForm.propTypes = {
  state: {
    values: {
      score: PropTypes.number.isRequired,
      title: PropTypes.string,
      text: PropTypes.string,
    },
    handleChange: PropTypes.func,
    submit: PropTypes.func,
    errors: PropTypes.object,
    setFormValue: PropTypes.func,
  },
};

export default CourseReviewForm;
