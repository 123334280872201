import Link from "Components/_Elements/Link/Link";
import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Logo, Trans} from "Components";
import {useHistory} from "react-router-dom";
import classnames from "classnames";

const PageNavbarDesktop = ({
  transparent = false,
  user,
  inCourse = false,
  location,
  logoComponentProps = {},
}) => {
  let navLogoLink = (
    <Link to="/" basic>
      <Logo size="lg" {...logoComponentProps} />
    </Link>
  );

  if (inCourse && user) {
    navLogoLink = null;
  }

  return (
    <div
      className={classnames(
        !transparent && "background border-2 border-b-gray-200",
        "flex justify-between ",
        "px-2 md:px-20 py-3",
        {
          inCourse: inCourse,
        }
      )}
      id="page-navbar"
    >
      <div className="p-2">{navLogoLink}</div>

      <PageNavbarDesktopLinks location={location} />
    </div>
  );
};

const PageNavbarDesktopLinks = ({location}) => {
  const {openModal} = React.useContext(window.GlobalModalContext);
  const {t: trans} = useTranslation();
  const history = useHistory();
  const current_path = window.location.pathname;

  return (
    <div className="flex gap-2 items-center">
      <Link
        inline={false}
        to="/courses"
        className={location.pathname == "/courses" ? "selected" : ""}
        onClick={() => {
          window.analytics.track("navbar_all_courses_clicked", {
            screen_name: current_path,
          });
        }}
      >
        <Trans>Courses</Trans>
      </Link>
      <Link
        inline={false}
        to="/pricing"
        className={location.pathname == "/pricing" ? "selected" : ""}
        onClick={() => {
          window.analytics.track("navbar_pricing_clicked", {
            screen_name: current_path,
          });
        }}
      >
        <Trans>Pricing</Trans>
      </Link>

      <Button size="sm" onClick={() => openModal("LOGIN")}>
        {trans("Login")}
      </Button>

      <Button
        type="primary"
        size="sm"
        onClick={() => {
          window.analytics.track("register_navbar_clicked", {
            screen_name: current_path,
          });
          history.push({
            pathname: "/signup",
            state: {location},
          });
        }}
      >
        {trans("Sign up")}
      </Button>
    </div>
  );
};

export default PageNavbarDesktop;
