import moment from "moment";
import { useTranslation } from "react-i18next";

export const useLocalDate = (date, format = "MMMM Do YYYY") => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.split("-")[0];

  if (date == null) return null; // Handle null dates

  try {
    if (currentLanguage === "en" || currentLanguage == null) {
      return moment(date).format(format);
    }

    require(`moment/locale/${currentLanguage}`);

    return moment(date)
      .locale(currentLanguage)
      .format(format);
  } catch (error) {
    return moment(date).format(format);
  }
};
