// import React from "react";
// import {range} from "utilities/numbers";
//
// const pageButtonsToDisplay = React.useMemo(() => {
//     // A sliding window of page indexes to display when there are more than 5 pages. The last and first page is always displayed.
//     if (pages.length <= 5) {
//         return pages;
//     }
//
//     let selectedPages = [];
//
//     const windowSize = 3;
//     // First page is always displayed
//     selectedPages.push(0);
//
//     // If the current page is within the first 3 pages, display the first 3 pages
//     if (currentPage <= windowSize) {
//         selectedPages = selectedPages.concat(range(windowSize + 1, 1));
//     }
//
//     // Always display the last page
//     selectedPages.push(pages.length - 1);
//     return selectedPages;
// }, [pages.length, currentPage]);

import React from "react";
import {Button, Trans} from "Components";

const PaginationPageButtons = ({
  pages,
  currentPage,
  onPageIndexClick,
  showPageButtons,
  buttonProps = {},
}) => {
  const currentPageNumber = currentPage + 1;
  const totalPages = pages.length;

  if (!showPageButtons) {
    return (
      <Trans currentPageNumber={currentPageNumber} totalPages={totalPages}>
        Page {{currentPageNumber}} of {{totalPages}}
      </Trans>
    );
  }

  const firstPageButton = (
    <Button
      key="first-page-button"
      onClick={() => onPageIndexClick(0)}
      type={currentPage === 0 ? "secondary" : "gray"}
      {...buttonProps}
      className="rounded-r-none"
    >
      1
    </Button>
  );

  const lastPageButton = (
    <Button
      key="last-page-button"
      className="rounded-l-none"
      onClick={() => onPageIndexClick(pages.length - 1)}
      type={currentPage === pages.length - 1 ? "secondary" : "gray"}
      {...buttonProps}
    >
      {pages.length}
    </Button>
  );

  const buttonsSliceStart = Math.max(1, currentPage - 2);
  const buttonsSliceEnd = currentPage + 4;

  const pageButtons = pages
    .slice(buttonsSliceStart, buttonsSliceEnd)
    .map((page, index) => {
      const pageIndex = buttonsSliceStart + index;
      return (
        <Button
          key={`page-button-${pageIndex}`}
          onClick={() => onPageIndexClick(pageIndex)}
          type={currentPage === pageIndex ? "secondary" : "gray"}
          className="rounded-l-none rounded-r-none"
        >
          {pageIndex + 1}
        </Button>
      );
    });

  return (
    <div>
      {firstPageButton}
      {pageButtons}
      {currentPage !== pages.length - 2 && (
        <Button key="page-button-ellipsis" type="link" disabled icon="ellipsis">
          ...
        </Button>
      )}
      {lastPageButton}
    </div>
  );
};

export default PaginationPageButtons;
