import React from "react";
import {throttle} from "lodash";
import {MAX_TOKENS} from "Components/GPT/hooks/useGPTRequest/useGPTRequest";

const getInputMessages = messages => {
  return messages.filter(result => result.content !== ""); // Filter out empty messages
};

const useGptRequestCallApi = ({
  sessionId,
  messages,
  setMessageById,
  refetchCredits,
  metadata,
  settings,
}) => {
  const stoppedRef = React.useRef(false);

  const runChatGPTEffect = async () => {
    // Find id of the result that has not been processed yet
    const messageId = messages.find(result => !result.processed)?.id;

    if (messageId === undefined) {
      return;
    }

    // Set the result as processes before sending it to the API so that it is not processed again
    // This avoids sending the same request multiple times in a loop
    setMessageById({
      id: messageId,
      processed: true,
    });

    // Set stopped to false otherwise the response is discarded
    stoppedRef.current = false;

    try {
      const response = await fetch(
        `https://jaif7yyllse2tzj75z5fz54zgi0xakso.lambda-url.eu-north-1.on.aws/`,
        {
          method: "POST",
          redirect: "follow",
          body: JSON.stringify({
            messages: getInputMessages(messages),
            sessionId,
            userToken: localStorage.getItem("AUTH_TOKEN"),
            exerciseId: metadata.exerciseId,
            solutionId: metadata.solutionId,
            exerciseText: metadata.exerciseText,
            solutionText: metadata.solutionText,
            config: {
              ...settings,
            },
          }),
        }
      );

      const reader = response.body.getReader();

      reader
        .read()
        .then(function processText({done, value}) {
          // Result objects contain two properties:
          // done  - true if the stream has already given you all its data.
          // value - some data. Always undefined when done is true.
          if (done) {
            setMessageById({
              id: messageId,
              loading: false,
            });

            // Refetch credits after each request to make sure that the user has enough credits
            try {
              refetchCredits();
            } catch (e) {
              console.log("Error in refetchCredits", e);
            }

            return;
          }

          // turn array buffer into string
          const chunk = new TextDecoder("utf-8").decode(value);

          setMessageById({
            id: messageId,
            content: chunk,
            addTokensCount: true,
          });

          if (stoppedRef.current === false) {
            // Read some more, and call this function again
            return reader.read().then(processText);
          }

          if (stoppedRef.current === true) {
            // Abort the request
            reader.cancel("Request aborted");
          }
        })

        .catch(e => {
          console.log("Error in reader", e);
          setMessageById({
            id: messageId,
            loading: false,
            content:
              "Kunde inte generera ett tips, var god försök igen senare.",
            append: false,
          });
        });
    } catch (e) {
      console.log("Error in fetch", e);

      setMessageById({
        id: messageId,
        loading: false,
        content: "Kunde inte generera ett tips, var god försök igen senare.",
        append: false,
      });
    }
  };
  /**
   * A function that is throttled to run every 2 seconds max to avoid sending too many requests
   * @type {DebouncedFunc<(function(): Promise<void>)|*>}
   */

  const throttledRunChatGPTEffect = throttle(runChatGPTEffect, 2000, {
    leading: true,
  });

  /**
   * Effect that runs when the messages array changes in length - when a new message is added
   */

  React.useEffect(() => {
    throttledRunChatGPTEffect();
  }, [messages]);

  const stopRequest = () => {
    stoppedRef.current = true;
  };

  return {stopRequest};
};

export default useGptRequestCallApi;
