import React from "react";
import {setAuthenticated} from "utilities/authentication";
import normalizeErrors from "utilities/normalizeErrors";
import {useHistory, useLocation} from "react-router-dom";
import errorCodes from "ErrorCodes";

const useAuthResponseHandler = ({onComplete, redirectLink, trackingParams}) => {
  const location = useLocation();
  const history = useHistory();
  const {setUser} = React.useContext(window.UserContext);
  const [requiredAction, setRequiredAction] = React.useState(null);

  const [error, setError] = React.useState(null);

  const backUrl = location.state?.background;

  // The url to redirect to after completed login
  const nextUrl =
    location.state?.nextUrl ||
    location.state?.background?.nextUrl ||
    new URLSearchParams(location.search).get("nextUrl");

  const forceRedirect = new URLSearchParams(location.search).get(
    "forceRedirect"
  );

  /**
   * What to do when the user completes the login
   */
  const onLoginSuccess = () => {
    if (onComplete && !forceRedirect) {
      onComplete(location.pathname);
      return;
    }

    let redirectUrl = redirectLink || backUrl || nextUrl || "/home";

    history.push(redirectUrl);
  };

  const authenticate = ({token, refreshToken}) => {
    if (!token) {
      return;
    }
    const loggedInUser = setAuthenticated(token, refreshToken);
    setUser(loggedInUser);

    onLoginSuccess();
  };

  // When data is returned from the server
  const handleAuthResponse = (data, returnObjectName, method) => {
    if (data) {
      const response = data[returnObjectName];

      // FOR DEBUGGING
      // console.log("handleAuthResponse", {response, returnObjectName, method});

      if (response.ok) {
        window.analytics.track(trackingParams.event, {
          trigger_screen: backUrl?.pathname,
          current_screen: location.pathname,
          method: method || "unknown",
        });
        // Pass tokens to the authentication service
        return authenticate(response);
      }

      // If there are required actions, set the required action in state which will be handled by the consumer
      if (response.requiredAction) {
        setRequiredAction(response.requiredAction);
        return;
      }

      if (response.errors) {
        // Take the first error since if there are multiple errors they can be handled one by one
        const error = response.errors[0];
        setError(error);
      }
    }
  };

  const handleActionCompleted = response => {
    authenticate(response);
  };

  return {
    handleAuthResponse,
    handleActionCompleted,
    requiredAction,
    error,
    authenticate,
  };
};

const authMethod = {
  login: "email",
  signup: "email",
};

export default useAuthResponseHandler;
