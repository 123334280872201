import React from "react";
import {Trans, Typography} from "Components/index";
import classnames from "classnames";

const TimerDisplay = ({
  textType = "tmd",
  timeSpent,
  title,
  className,
  loading = false,
}) => {
  // Presentational logic so it is allowed to be here
  const seconds = timeSpent % 60;
  const minutes = Math.floor(timeSpent / 60).toFixed(0) % 60;
  const hours = Math.floor(minutes / 60).toFixed(0);

  if (timeSpent === 0 || loading) {
    return (
      <div
        className={classnames(
          "flex gap-1 items-baseline leading-none ",
          loading && "opacity-50",
          className
        )}
      >
        {title}
        <Typography
          type={textType || "tlg"}
          className="font-[Redacted_Script]"
          weight="md"
        >
          {"0 "}
        </Typography>
        <Trans type={textType}>min.</Trans>
      </div>
    );
  }

  return (
    <div
      className={classnames(
        "flex gap-1 items-baseline leading-none ",
        loading && "opacity-50",
        className
      )}
    >
      {title}
      {minutes > 0 && (
        <>
          <Typography
            type={textType || "tlg"}
            className="font-[Redacted_Script]"
            weight="md"
          >
            {minutes + " "}
          </Typography>
          <Trans type={textType}>min.</Trans>
        </>
      )}

      {seconds > 0 && (
        <>
          <Typography
            type={textType || "tlg"}
            className="font-[Redacted_Script]"
            weight="md"
          >
            {seconds + " "}
          </Typography>
          <Trans type={textType}>sec.</Trans>
        </>
      )}
    </div>
  );
};

export default TimerDisplay;
