import React from "react";
import {v4 as uuidv4} from "uuid";

const useGPTMessagesState = ({initialMessages}) => {
  const defaultMessages = initialMessages?.length > 0 ? initialMessages : [];

  const [messages, setMessages] = React.useState(defaultMessages);

  const addMessage = ({
    content = "",
    role,
    loading = null,
    processed = null,
    ...otherProperties
  }) => {
    const messageId = uuidv4();

    setMessages(prev => [
      ...prev,
      {
        content,
        role,
        tokens: 0, // always set tokens to 0 when adding a new result
        loading: loading || false,
        processed: processed || false,
        ...otherProperties,
        timestamp: new Date().getTime(), // Always set timestamp to current time
        id: messageId,
      },
    ]);
  };

  const setMessageById = ({
    id,
    content = null,
    tokens = null,
    loading = null,
    processed = null,
    append = true,
    addTokensCount = false,
  }) => {
    setMessages(prev => {
      return prev.map(result => {
        if (result.id === id) {
          let newContent = result.content || "";

          if (content !== null) {
            if (append) {
              // If append is true, append content to result.content otherwise replace it
              newContent += content;
            } else {
              // If content is provided, use it
              newContent = content;
            }
          }

          let newTokens = result.tokens || 0;

          if (addTokensCount) {
            newTokens += 1;
          }

          if (tokens !== null) {
            newTokens = tokens;
          }

          return {
            ...result,
            tokens: newTokens,
            loading: loading === null ? result.loading : loading,
            processed: processed === null ? result.processed : processed,
            content: newContent,
          };
        }

        return result;
      });
    });
  };

  /**
   * The last prompt is the last message sent by the user
   * @type {*}
   */
  const lastPrompt = React.useMemo(
    () =>
      messages.filter(result => result.role === "user").slice(-1)[0]?.content,
    [messages.length]
  );

  /**
   * Last completion message is the last message sent by the assistant
   * @type {*}
   */

  const lastCompletion = messages
    .filter(result => result.role === "assistant")
    .slice(-1)[0];

  const messagesNotEmpty = React.useMemo(
    () => messages.filter(result => result.role !== "system").length > 0,
    [messages.length]
  );

  const resetMessages = () => {
    setMessages(defaultMessages);
  };

  const removeSystemMessage = () => {
    setMessages(prev => {
      return prev.filter(result => result.role !== "system");
    });
  };

  const messagesLoading = messages.some(m => m.loading);

  return {
    messages,
    addMessage,
    resetMessages,
    setMessages,
    setMessageById,
    lastPrompt,
    lastCompletion,
    messagesNotEmpty,
    messagesLoading,
    removeSystemMessage,
  };
};

export default useGPTMessagesState;
