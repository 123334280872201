import React from "react";
import classnames from "classnames";

const LogoSVG = ({sizeClasses, fillColor}) => {
  return (
    <div className={classnames(sizeClasses)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 80 50"
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        {/*<clipPath id="logoClipPath">*/}
        <path
          d="M72.9732 27.1404C72.1712 29.1142 70.8516 30.1648 69.4286 31.0244C66.298 32.9027 62.9346 32.7117 59.6487 32.266C53.1805 31.3746 47.0228 28.8277 40.891 26.249C36.1304 24.2752 31.525 21.7601 26.7127 19.9455C22.3919 18.3219 17.9677 17.4623 13.4917 19.3088C11.0855 20.2957 9.0933 21.9512 7.64442 24.5299C5.91095 27.5861 4.92778 27.5224 3.32367 24.3389C1.4867 20.7414 0.141313 16.9529 0.0119494 12.6233C-0.16916 6.82912 1.69368 2.18109 6.60951 0.493785C8.75695 -0.23844 11.0079 -0.047423 13.1812 0.366443C18.0712 1.32152 22.4695 3.86839 26.6609 6.92463C31.1628 10.2037 35.4577 13.8967 40.063 16.9529C45.8068 20.7732 51.7317 24.1478 58.0446 26.249C62.0032 27.5543 66.0393 28.1592 70.1272 27.7453C71.0586 27.6498 72.0159 27.3314 72.9732 27.1404Z"
          className={fillColor}
        />
        <path
          d="M64.666 37.6564C64.666 38.4487 64.2773 38.5755 63.9663 38.8607C59.1205 43.266 53.7822 46.2451 47.848 47.7981C37.3788 50.5236 27.5056 48.5904 18.2026 42.2518C15.8185 40.6355 13.4604 38.8924 11.8278 36.0084C9.98792 32.8074 9.75469 29.8917 11.2059 27.0711C12.0351 25.423 13.3567 24.6624 14.7561 24.187C18.2803 23.0144 21.7787 23.4264 25.1993 24.8209C32.9734 27.9901 40.5144 31.92 48.444 34.5822C53.549 36.2936 58.7058 37.6247 64.0441 37.593C64.2255 37.5613 64.4328 37.6247 64.666 37.6564Z"
          className={fillColor}
        />
        <path
          d="M40.3959 11.2166C40.5255 10.6439 40.8106 10.5803 41.1217 10.5485C44.1025 10.0394 46.7206 8.35322 49.3386 6.66701C53.6155 3.89908 57.9443 1.13115 62.7915 0.303956C67.1203 -0.459611 71.3713 0.113066 75.1298 3.19915C77.9552 5.52166 79.4845 8.76682 78.8624 12.9982C78.1884 17.5478 75.9593 20.5385 72.3303 21.9702C68.2608 23.5609 64.2171 22.8292 60.2512 21.6838C53.2785 19.6795 46.8761 15.8934 40.6292 11.5984C40.5514 11.5347 40.4996 11.3757 40.3959 11.2166Z"
          className={fillColor}
        />
        {/*</clipPath>*/}
      </svg>
    </div>
  );
};

export default LogoSVG;
