import React from "react";
import useS3FileUpload from "API/mutations/S3/useS3FileUpload";
import {Typography} from "Components";
import MD5 from "crypto-js/md5";

const useGroupMembershipVerification = () => {
  const {user} = React.useContext(window.UserContext);
  const hash = MD5(user?.id?.toString()).toString();

  const [fileUrl, setFileUrl] = React.useState(null);
  const [uploadError, setUploadError] = React.useState(null);

  const {uploadFile: uploadFileToS3, loading} = useS3FileUpload();

  // Takes the first file from the array and uploads it to S3
  const onFileUpload = React.useCallback(async ([file]) => {
    // Upload the file to S3
    const uploadUrl = await uploadFileToS3({
      file,
      fileName: "user-registration-transcript.pdf",
      fileType: "application/pdf",
      ACL: "private",
      hash,
    });

    if (uploadUrl) {
      setFileUrl(uploadUrl);
      return;
    }

    setUploadError(
      <Typography>
        Uppladdning misslyckades, Var god försök igen eller kontakta supporten.
      </Typography>
    );
  }, []);

  const resetFileUrl = () => {
    setFileUrl(null);
  };

  return {
    onFileUpload,
    loading,
    fileUrl,
    uploadError,
    resetFileUrl,
  };
};

export default useGroupMembershipVerification;
