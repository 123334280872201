import React from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {message} from "Components/_Elements";

const UPDATE_GROUP_MEMBER = gql`
  mutation UpdateGroupMember(
    $groupId: ID!
    $groupMemberId: ID!
    $admin: Boolean
    $verified: Boolean
  ) {
    updateGroupMember(
      groupId: $groupId
      groupMemberId: $groupMemberId
      admin: $admin
      verified: $verified
    ) {
      ok
      groupMember {
        id
        verified
        admin
      }
      error {
        code
        message
      }
    }
  }
`;

const useUpdateGroupMember = ({showMessages = true} = {}) => {
  const [updateGroupMember, {data, loading, error}] = useMutation(
    UPDATE_GROUP_MEMBER,
    {
      refetchQueries: [],
      context: {
        batch: true,
      },
    }
  );

  React.useEffect(() => {
    if (data && data.updateGroupMember) {
      if (data.updateGroupMember.ok && showMessages) {
        message.success("Group member updated successfully");
      }

      if (data.updateGroupMember.error && showMessages) {
        message.error(data.updateGroupMember.error.message);
      }
    }

    if (error && showMessages) {
      message.error(error.message);
    }
  }, [data?.updateGroupMember, error]);

  return {updateGroupMember, loading};
};

export default useUpdateGroupMember;
