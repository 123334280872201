import React from "react";
import {Button, Trans} from "Components";
import {useHistory} from "react-router-dom";

const OnboardingSchoolMissing = () => {
  const history = useHistory();

  const {openModal} = React.useContext(window.GlobalModalContext);
  const onSuccess = () => history.push("/home"); // go to dashboard after request is sent
  const handleClick = () => openModal("REQUEST_SCHOOL", onSuccess);
  return (
    <div className="mt-4">
      <Button type="link" onClick={handleClick}>
        <Trans>Can't find your school?</Trans>
      </Button>
    </div>
  );
};

export default OnboardingSchoolMissing;
