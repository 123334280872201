import React from "react";
import PropTypes from "prop-types";
import {Icon, Typography} from "Components/_Elements/index";
import FilePreview from "Components/_Elements/File/FilePreview";
import classnames from "classnames";
import {motion} from "framer-motion";
import variants from "utilities/animations";

const truncatedName = (name, length = 30) => {
  try {
    // Truncate to 20 characters with ellipsis in the middle
    const halfLength = Math.floor(length / 2);
    if (name.length > length) {
      return (
        name.substring(0, halfLength) +
        "..." +
        name.substring(name.length - halfLength, name.length)
      );
    }

    return name;
  } catch (error) {
    console.log(error);
    return name;
  }
};

const File = ({
  name = "File",
  selected = false,
  nameDisplayLength = 30,
  url = null,
  size = 0,
  showSize = true,
  iconSize = "md",
  animation,
  fluid = true,
  onPreviewToggle = null,
  previewButtonProps,
  layoutId = null,
  storedInCloud = true,
  icon,
}) => {
  const container = React.useRef(null);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const togglePreview = () => {
    if (onPreviewToggle) {
      onPreviewToggle();
    } else {
      setPreviewOpen(!previewOpen);
    }
  };

  if (url === null && storedInCloud) {
    return null;
  }

  return (
    <motion.div
      className={classnames(
        "p-2  border-2 rounded-lg flex items-center justify-between",
        previewOpen || selected
          ? "border-primary-strong"
          : "border-primary-50 dark:border-primary-800",
        selected ? "surface-primary" : "background",
        fluid ? "w-full" : "w-64"
      )}
      ref={container}
      layout={layoutId ? "position" : false}
      initial={"initial"}
      animate={"animate"}
      variants={animation ? variants[animation] : {}}
      layoutId={layoutId}
    >
      <Icon
        fixedWidth
        size={iconSize}
        icon="fa-solid fa-file"
        fillType="purple"
        filled
      />
      <div className="ml-4 w-full overflow-hidden">
        <div className="flex items-center justify-between w-full">
          <Typography
            type="tsm"
            weight="medium"
            color="text-primary"
            className="truncate"
          >
            {truncatedName(name, nameDisplayLength)}
          </Typography>
          {/*  Show the preview icon, if URL is given */}
          {url && (
            <FilePreview
              name={name}
              url={url}
              fileContainer={container}
              previewOpen={previewOpen}
              togglePreview={togglePreview}
              previewButtonProps={previewButtonProps}
            />
          )}
        </div>
        {// Only show the file size if it is greater than 0
        size !== null && showSize && (
          <Typography
            type="tsm"
            color="text-gray-light"
            className="mt-1"
            weight="light"
          >
            {/*One decimal rounding*/}
            {Math.round((size / 1000) * 10) / 10} KB
          </Typography>
        )}
      </div>

      {icon && React.isValidElement(icon) && <div>{icon}</div>}
    </motion.div>
  );
};

File.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  showSize: PropTypes.bool,
  animation: PropTypes.oneOf(Object.keys(variants)),
};

export default File;
