import LanguagePicker from "Components/LanguagePicker";
import Link from "Components/_Elements/Link/Link";
import Trans from "Components/Trans";
import React from "react";
import {useWindowSize} from "utilities/hooks/useWindowSize";

import "./Footer.scss";
import {Button, Typography} from "Components";

const SocialLink = ({name, href, icon}) => (
  <a
    href={href}
    target="_blank"
    className="white flex flex-column social-link"
    onClick={() =>
      window.analytics.track("footer_social_link_clicked", {target: href})
    }
  >
    <i className={`fab ${icon} text-h6 md:text-h5`} />
    {name}
  </a>
);

const links = [
  {
    // name: "Instagram",
    href: "https://www.instagram.com/kollin.io/",
    icon: "fa-instagram",
  },
  {
    // name: "Facebook",
    href: "https://www.facebook.com/Tntor-137913966837063/",
    icon: "fa-facebook-square",
  },
  {
    // name: "LinkedIn",
    href: "https://www.linkedin.com/company/10400828/",
    icon: "fa-linkedin",
  },
  {
    // name: "Youtube",
    href: "https://www.youtube.com/channel/UCwOemjj0Vvrm-Iju0anP6ww",
    icon: "fa-youtube",
  },
  {
    // name: "Twitch",
    href: "https://www.twitch.tv/kollin_io/videos",
    icon: "fa-twitch",
  },
];

const Footer = ({hideLanguagePicker = false}) => {
  const [vWidth] = useWindowSize();

  return (
    <div
      className={`bg-primary-900 row  m-0 pt-8 footer w-screen ${
        vWidth > 768 ? "" : "centered"
      }`}
    >
      <div className="col-md-10 col-sm-12 mb-4 offset-md-1 px-5 px-md-0">
        <div className="logo">
          <img
            src="https://d1mgntrf3vaj6d.cloudfront.net/kollin_logo_white.svg"
            alt="logo_white"
            className="mr-2"
          />
          <h5>KOLLIN</h5>
        </div>

        <div className="row m-0">
          <div className="col-md-3 col-sm-12">
            <p className="subtitle mb-3"></p>
            <div className="grid grid-cols-5 divide-x-2 divide-primary-700 justify-between ">
              {links.map((link, key) => (
                <SocialLink {...link} key={"link-" + key} />
              ))}
            </div>
          </div>

          {!hideLanguagePicker && (
            <div className="col-md-3 cols-sm-12 offset-md-1 pt-3 pt-md-0">
              <p className="subtitle mb-3" style={{marginRight: 10}}>
                <Trans>Language</Trans>
                {/* <i className="fas fa-globe-africa" /> */}
              </p>
              <LanguagePicker />
            </div>
          )}
          <div className="col-md-4 col-sm-12 pt-3 pt-md-0 mx-2 mt-4">
            <p>
              <Link inverted to="/static/terms">
                <Trans>Terms & Conditions</Trans>{" "}
              </Link>
            </p>
            <p>
              <Link inverted to="/static/privacy">
                <Trans>Privacy policy</Trans>
              </Link>
            </p>
            <Button type="link" inverted size="xs">
              <a
                className="text-gray-300 hover:text-gray-400"
                href="mailto:contact@kollin.io"
              >
                <span>contact@kollin.io</span>
              </a>
            </Button>
          </div>
        </div>
        <div className="footer-copyright">
          <Typography className="mt-4 mx-2" color="text-gray-50" type="txs">
            Copyright {new Date().getFullYear()} © Crash Course Sverige AB.
            <br />
            Strandbergsgatan 61, 112 51 Stockholm
            <br /> Org. number: 559039-3483
            <br />v{process.env.REACT_APP_VERSION}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Footer;
