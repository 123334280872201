import React from "react";
import GPTActionsSave from "./GPTActionsSave/GPTActionsSave";
import GPTActionsReset from "./GPTActionsReset";
import {GPTContext} from "./GPTProvider";
import {Button} from "Components/_Elements";
import {Trans} from "Components/index";
import classnames from "classnames";

const GPTActions = ({saveFormat, allowExpand = true, inverted = false}) => {
  const {chatActive, toggleModal, showAsModal} = React.useContext(GPTContext);

  if (chatActive || showAsModal) {
    return (
      <div
        className={classnames(
          "flex gap-2 justify-between items-center align-self-end mb-4 rounded-xl",
          inverted ? "bg-gray-700 dark rounded-b-none" : "surface-primary",
          showAsModal ? "p-4 rounded-b-none -mx-6 -mt-6" : "pl-4 pr-2 py-2"
        )}
      >
        <Trans color={inverted ? "text-white" : "text-gray-600"} weight="sb">
          Your conversation
        </Trans>
        <div className="flex">
          <Button
            size="sm"
            fluid
            type="gray"
            icon={showAsModal ? "compress" : "expand"}
            onClick={toggleModal}
            hint={
              showAsModal ? <Trans>Minimize</Trans> : <Trans>Maximize</Trans>
            }
            className="rounded-r-none hover:rounded-r-none border-l-none"
          />
          {chatActive && (
            <GPTActionsSave
              saveFormat={saveFormat}
              className="rounded-r-none rounded-l-none hover:rounded-r-none border-x-0"
            />
          )}

          <GPTActionsReset className="rounded-l-none hover:rounded-l-none border-r-none" />
        </div>
      </div>
    );
  }

  return null;
};

export default GPTActions;
