import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";

export const UPDATE_SOLUTION = gql`
  mutation updateSolution(
    $id: ID!
    $sourceImageUrl: String!
    $boundingBox: BoundingBoxInput!
    $imageUrl: String
    $published: Boolean
  ) {
    updateSolution(
      id: $id
      sourceImageUrl: $sourceImageUrl
      boundingBox: $boundingBox
      published: $published
      imageUrl: $imageUrl
    ) {
      ok
      error {
        message
        code
      }
      solution {
        id
        imageUrl
      }
    }
  }
`;

const useUpdateSolution = () => {
  const [updateSolution, {loading, data, error}] = useMutation(
    UPDATE_SOLUTION,
    {
      onError: error => {
        console.log("Error in useUpdateSolution: ", error);
      },
    }
  );

  if (error) {
    console.log("Error in useUpdateSolution: ", error);
  }

  const result = data?.updateSolution;

  return {
    updateSolution,
    loading,
    result,
    error,
  };
};

export default useUpdateSolution;
