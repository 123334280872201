import ReactDOMServer from "react-dom/server";
import { Trans } from "Components";
import React from "react";
import useLocalStorageState from "utilities/hooks/useLocalStorageState";

const useProductPurchaseExpiryBannerExisitingApplicationState = (
  productId,
  productName
) => {
  const { user } = React.useContext(window.UserContext);
  const [hasApplied, setHasApplied] = useLocalStorageState(
    "PRODUCT_PURCHASE_ACCESS_EXTENSION_APPLIED_" + productId + "_" + user.id,
    0
  );

  React.useEffect(() => {
    // When user has applied remove existing banner and show successful application message:
    if (hasApplied) {
      const banner = document.getElementById("product-purchase-expiry-banner");
      if (banner) {
        // remove all children
        banner.classList.add("success");

        // Set new banner content using portal
        banner.innerHTML = ReactDOMServer.renderToStaticMarkup(
          <>
            <i className="fas fa-check-circle" />
            <span>
              <Trans>Your application for extended access to</Trans>{" "}
              {" " + productName + " "}
              <Trans>has been received. We will process it shortly</Trans>
            </span>
          </>
        );
      }
    }
  }, [hasApplied]);

  return {
    hasApplied,
    setHasApplied,
  };
};

export default useProductPurchaseExpiryBannerExisitingApplicationState;
