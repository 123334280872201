import React from "react";
import classnames from "classnames";
import {Icon, Maybe, Typography} from "Components/_Elements";

const ExplainerSection = ({
  content,
  illustrationProps = {},
  theme = "primary",
}) => {
  const {topIcon, h1, h2, list, illustration} = content;

  return (
    <div
      className={classnames(
        "mt-12 px-4 w-full mx-auto",
        "flex flex-col items-center",
        "md:flex-row md:gap-8 md:even:flex-row-reverse md:items-center md:text-left"
      )}
    >
      <div className="w-full">
        <Maybe dep={topIcon}>
          {/*  TODO: Move to Icon.jsx and add optional color changing to the outline/fill */}

          <Icon
            icon={topIcon}
            filled
            fillType={theme}
            outlined={true}
            size="xl"
          />
        </Maybe>

        <Maybe dep={h1}>
          <Typography
            type="dxs"
            weight="md"
            color={`text-${theme}`}
            className="mt-4"
          >
            {h1}
          </Typography>
        </Maybe>

        <Maybe dep={h2}>
          <Typography type="txl" color={`text-${theme}-light`} className="mt-4">
            {h2}
          </Typography>
        </Maybe>

        <Maybe dep={list}>
          <div className="mt-4">
            {list?.map((benefit, index) => (
              <div className="flex gap-4 mt-4 items-start" key={index}>
                <Icon
                  icon="check-circle"
                  filled
                  fillType={theme}
                  outlined={false}
                  size="md"
                />
                <div>
                  <Typography color={`text-${theme}-light`} type="tlg">
                    {benefit}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </Maybe>
      </div>

      <Maybe dep={illustration}>
        <div className="mt-10 w-full md:w-2/3 max-w-xl">
          <img
            src={illustration?.url}
            alt={illustration?.title}
            className="w-full"
            {...illustrationProps}
          />
        </div>
      </Maybe>
    </div>
  );
};
export default ExplainerSection;
