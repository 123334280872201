import React from "react";
import {Button, Trans} from "Components";
import {isMobile} from "react-device-detect";

const PreviewButton = ({
  route,
  withText = false,
  type = "link",
  children,
  className = "",
  size = "lg",
  urlParams = null,
  disabled = false,
  ...buttonProps
}) => {
  const {setPreview, currentPreviewRoute} = React.useContext(
    window.PreviewSidebarContext
  );

  if (isMobile) return null;

  const isCurrentPreview = currentPreviewRoute === route;

  const icon = isCurrentPreview
    ? "fa-light fa-square-xmark"
    : "fa-light fa-sidebar-flip";

  const label = isCurrentPreview ? (
    <Trans>Close</Trans>
  ) : (
    <Trans>Preview</Trans>
  );

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) return;

    // Close sidebar if already open
    if (currentPreviewRoute === route) {
      setPreview({route: null});
      return;
    }
    setPreview({route, urlParams});
  };

  if (children) {
    return React.cloneElement(children, {
      onClick: handleClick,
    });
  }

  if (withText) {
    return (
      <Button
        {...buttonProps}
        onClick={handleClick}
        type={type}
        iconLeft={icon}
        iconProps={{sharp: true}}
        className={className}
        active={isCurrentPreview}
      >
        {label}
      </Button>
    );
  }

  return (
    <Button
      {...buttonProps}
      onClick={handleClick}
      type={isCurrentPreview ? "destructive" : type}
      hint={label}
      icon={icon}
      size={size}
      iconProps={{sharp: true}}
      className={className}
      active={isCurrentPreview}
    />
  );
};

export default PreviewButton;

/** (6428, 6365, 6366, 6367)
 */
