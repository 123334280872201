import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {UserContext} from "UserContextProvider";

export const GET_COURSE = gql`
  query getCoursePrerequisites($courseId: ID!, $loggedIn: Boolean!) {
    getCourse(id: $courseId) {
      id
      prerequisites {
        id
        required
        course {
          id
          code
          name
          icon
          currentUser @include(if: $loggedIn) {
            active
            archived
          }
        }
      }
    }
  }
`;

const useGetCoursePrerequisites = courseId => {
  const {user} = React.useContext(UserContext);
  let prerequisites = [];
  const {loading, error, data} = useQuery(GET_COURSE, {
    variables: {
      courseId,
      loggedIn: !!user?.id,
    },
  });

  if (data && data.getCourse) {
    prerequisites = data.getCourse.prerequisites;
  }

  if (error) {
    console.log("Error in getCourse-hook: ", error);
  }

  return [prerequisites, loading];
};

export default useGetCoursePrerequisites;
