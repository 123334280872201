import React from "react";
import ExerciseNotesNewNote from "./ExerciseNotesNewNote/ExerciseNotesNewNote";
import ExerciseNotesList from "./ExerciseNotesList/ExerciseNotesList";

const ExerciseNotes = ({
  exerciseId,
  collapsible = true,
  setCount = () => {},
}) => {
  return (
    <div>
      <ExerciseNotesList
        exerciseId={exerciseId}
        collapsible={collapsible}
        setCount={setCount}
      />
      <ExerciseNotesNewNote exerciseId={exerciseId} />
    </div>
  );
};

export default ExerciseNotes;
