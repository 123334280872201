import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "utilities/hooks/useWindowSize";

export const AppContext = React.createContext(null);

window.AppContext = AppContext;

const AppContextProvider = ({ children }) => {
  const [wWidth, wHeight] = useWindowSize();

  const { i18n } = useTranslation();
  React.useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.language]);

  const [renderCount, setRenderCount] = React.useState(0);
  const triggerReRender = () => setRenderCount(renderCount + 1);

  return (
    <AppContext.Provider
      value={{
        triggerReRender,
      }}
    >
      <div style={{ height: wHeight, width: wWidth }}>{children}</div>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
