import React from "react";
import {useTranslation} from "react-i18next";
import {Select} from "Components";

const LANGS = {
  es: "Español",
  fr: "Français",
  de: "Deutsch",
  en: "English",
  sv: "Svenska",
  ur: "اُردُو",
  pt: "Português",
};

const FLAGS = {
  pt: "🇵🇹",
  sv: "🇸🇪",
  es: "🇪🇸",
  fr: "🇫🇷",
  de: "🇩🇪",
  en: "🇬🇧",
  ur: "🇵🇰",
};

const languageOptions = Object.keys(LANGS).map(key => ({
  value: key,
  text: FLAGS[key] + " " + LANGS[key],
}));

const LanguagePicker = () => {
  const {i18n} = useTranslation();
  const {triggerReRender} = React.useContext(window.AppContext);

  if (!i18n) return null;

  let currentLanguage = i18n.language.split("-")[0];
  if (!currentLanguage) currentLanguage = "en";

  currentLanguage = currentLanguage.toLowerCase();

  const changeLanguage = key => {
    i18n.changeLanguage(key);
    triggerReRender();
  };

  return (
    <Select
      options={languageOptions}
      defaultSelectedValue={currentLanguage}
      onSelect={changeLanguage}
      size="md"
    />
  );
};

export default LanguagePicker;
