import React from "react";
import {Button, Trans, Typography} from "Components";
import classnames from "classnames";

const ProductCardDescription = ({description}) => {
  // If description is longer than 100 characters, truncate it and add a show more button
  const [showMore, setShowMore] = React.useState(false);

  if (!description) return null;

  let truncatedDescription = description;
  let showMoreButton = false;

  if (description.length > 100) {
    truncatedDescription = description.slice(0, 100) + "...";
    showMoreButton = true;
  }

  return (
    <div className="w-full ">
      <Typography
        type="tsm"
        color="text-gray-light"
        className={classnames(" overflow-clip", !showMore && "h-10")}
      >
        {showMore ? description : truncatedDescription}
      </Typography>
      {showMoreButton && (
        <Button
          onClick={() => setShowMore(!showMore)}
          iconLeft={showMore ? "chevron-up" : "chevron-down"}
          type="link-gray"
          size="xs"
        >
          {showMore ? <Trans>Show less</Trans> : <Trans>Show more</Trans>}
        </Button>
      )}
    </div>
  );
};

export default ProductCardDescription;
