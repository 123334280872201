import React from "react";
import {BreadCrumb, Typography} from "Components";

const ChapterHeader = ({chapter, showHeader}) => {
  if (!showHeader) return null;

  return (
    <div className="flex flex-col gap-4 items-start mb-2">
      <BreadCrumb />

      <Typography type="dmd" weight="md">
        {removeExtraInfo(chapter.title)}
      </Typography>
    </div>
  );
};

const removeExtraInfo = title => {
  try {
    const [titleWithoutExtraInfo] = title.split(" - ");
    return titleWithoutExtraInfo;
  } catch (error) {
    console.error(error);
    return title;
  }
};

export default ChapterHeader;
