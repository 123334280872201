import React from "react";
import classnames from "classnames";
import GPTUserSettings from "Components/GPT/GPTUserSettings/GPTUserSettings";
import {Button} from "Components/_Elements";
import useCurrentParams from "utilities/hooks/useCurrentParams";

const GPTPromptActions = () => {
  const {exerciseId} = useCurrentParams();

  const {
    promptEmpty,
    loading,
    handleSubmit,
    disabled,
    messages,
    promptInputFocused,
    promptInputHasBeenFocused,
    chatActive,
  } = React.useContext(GPTContext);

  const firstMessage = messages.find(result => result.role === "user");

  const showActions =
    !promptEmpty ||
    promptInputFocused ||
    chatActive ||
    exerciseId === null ||
    promptInputHasBeenFocused;

  return (
    <div
      className={classnames(
        "py-2 flex gap-2 items-center justify-between transition-opacity ease-in-out",
        showActions
          ? "opacity-100 h-auto duration-300 delay-100 "
          : "pointer-events-none opacity-0 h-0 duration-0"
      )}
    >
      <GPTUserSettings />
      <Button
        onClick={handleSubmit}
        type={disabled ? "secondary" : "primary"}
        size="sm"
        role="submit"
        name="submit"
        disabled={disabled}
        loading={loading}
        trackParams={{event: "ask_gpt", prompt: firstMessage?.content}}
        icon="paper-plane"
      />
    </div>
  );
};

export default GPTPromptActions;
