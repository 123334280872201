import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

import { GET_EXERCISE_EVALUATION } from "API/queries/exercise/useGetExerciseEvaluation";
import { CourseContext } from "routes/Course/CourseContextProvider";

import useSubscribeToCourseMutation from "../course/useSubscribeToCourseMutation";

const SET_SELF_EVALUATE = gql`
  mutation selfEvaluate($exerciseId: ID!, $courseId: ID!, $score: Int!) {
    selfEvaluate(
      exercise_id: $exerciseId
      course_id: $courseId
      score: $score
    ) {
      ok
      error
      score
    }
  }
`;

const useExerciseEvaluationMutation = (exerciseId, refetchQueries = []) => {
  const { courseId } = React.useContext(CourseContext);
  // const { exerciseId } = React.useContext(ExerciseContext);

  const [selfEvaluate, { error, loading }] = useMutation(SET_SELF_EVALUATE, {
    refetchQueries: [
      ...refetchQueries,
      { query: GET_EXERCISE_EVALUATION, variables: { exerciseId } },
    ],
  });

  const subscribeToCourse = useSubscribeToCourseMutation({ courseId });

  const setScore = score => {
    selfEvaluate({
      variables: {
        exerciseId,
        courseId,
        score,
      },
    });
  };

  return [setScore, loading];
};

export default useExerciseEvaluationMutation;
