import React from "react";
import classnames from "classnames";
import {Typography} from "Components";
import {textSizes, titleTextSize} from "./ProgressSteps.constants";
import ProgressStepCircle from "Components/_Elements/ProgressSteps/ProgressStepCircle";
import ProgressStepLine from "Components/_Elements/ProgressSteps/ProgressStepLine";
import {safeCloneTextTag} from "utilities/safeClone";
import {motion} from "framer-motion";

const ProgressStepsStep = ({
  title,
  component,
  description = null,
  status = "incomplete",
  numbered = false,
  index,
  orientation,
  size = "md",
  isLast = false,
  onStepClick = () => {},
  selected = false,
  currentStepIdx = null,
  disabled = false,
}) => {
  const onlyTitle = !description;

  return (
    <div
      className={classnames(
        "w-full flex items-start gap-2 relative  group",
        orientation === "vertical" ? "flex-row gap-4  pb-4" : "flex-col",
        onlyTitle ? "items-center" : "items-start",
        !selected && !disabled && "cursor-pointer"
      )}
      onClick={() => onStepClick(index)}
    >
      {/* Step Indicator */}
      <ProgressStepCircle
        status={status}
        size={size}
        numbered={numbered}
        index={index}
        selected={selected}
      />
      {!isLast && (
        <ProgressStepLine
          orientation={orientation}
          status={status}
          size={size}
          selected={selected}
        />
      )}

      {/* Step Title and description */}
      <div className="flex flex-col gap-1 grow w-full">
        {safeCloneTextTag(title, {
          className:
            !selected &&
            "group-hover:underline decoration-primary-600 decoration-2",
          type: titleTextSize(size, onlyTitle),
          weight: "md",
          color: selected ? "text-primary-light" : "text-gray",
        })}

        {safeCloneTextTag(description, {
          className:
            !selected &&
            "group-hover:underline decoration-primary-600 decoration-2",
          type: textSizes[size],
          weight: "normal",
          color: selected ? "text-primary-light" : "text-gray-light",
        })}
        {component && orientation === "vertical" && (
          <motion.div
            class={classnames("mt-4 overflow-clip")}
            initial={{height: 0}}
            animate={{
              height: selected ? "fit-content" : 0,
              opacity: selected ? 1 : 0,
            }}
            transition={{duration: 0.5}}
          >
            {component}
          </motion.div>
        )}
      </div>
    </div>
  );
};

ProgressStepsStep.propTypes = {};

export default ProgressStepsStep;
