import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_USER_ACTIVE_COURSES = gql`
  query currentUserActiveCourses(
    $includeSchool: Boolean!
    $includeSubject: Boolean!
    $includePurchased: Boolean!
  ) {
    currentUserActiveCourses {
      id
      name
      code
      icon
      price
      school @include(if: $includeSchool) {
        id
        name
      }
      subject @include(if: $includeSubject) {
        id
        name
      }
      purchased @include(if: $includePurchased)
      expiryDate @include(if: $includePurchased)
      currentUser {
        active
        archived
      }
    }
  }
`;

const useGetCurrentUserActiveCourses = ({
  includeSubject = true,
  includeSchool = true,
  includePurchased = true,
  filterArchived = false,
} = {}) => {
  let courses = [];

  const {loading: loadingCourse, error: errorCourse, data} = useQuery(
    GET_USER_ACTIVE_COURSES,

    {
      variables: {
        includeSchool,
        includeSubject,
        includePurchased,
      },
      fetchPolicy: "cache-first",
    }
  );

  if (errorCourse) {
    console.log("Error in getCourse-hook: ", errorCourse);
  }

  if (data) {
    courses = data.currentUserActiveCourses;
    if (filterArchived) {
      courses = courses.filter(course => course.currentUser.active);
    }
  }

  return [courses, loadingCourse];
};

export default useGetCurrentUserActiveCourses;
