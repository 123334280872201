import React from "react";
import classnames from "classnames";

const Handle = ({ position, handleColor, handleRef, disabled, hidden }) => {
  const positionClasses = React.useMemo(() => {
    switch (position) {
      case "top":
        return "-top-1 -left-1 w-[calc(100%+0.5rem)] h-1 cursor-row-resize";

      case "bottom":
        return "-bottom-1 -left-1 w-[calc(100%+0.5rem)] h-1 cursor-row-resize";

      case "left":
        return "-left-1 top-0 h-full w-1 cursor-col-resize";

      case "right":
        return "-right-1 top-0 h-full w-1 cursor-col-resize";
      default:
        return "";
    }
  }, []);

  return (
    <div
      ref={handleRef}
      className={classnames(
        "absolute",
        disabled ? "bg-gray-200" : handleColor,
        positionClasses
      )}
      data-position={hidden ? "" : position}
    >
      {!hidden && (
        <div
          ref={handleRef}
          data-position={position}
          className={classnames(
            "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
            " w-2 h-2 rounded-full ",
            !disabled && "background outline-primary-400 outline outline-1"
          )}
        />
      )}
    </div>
  );
};

export default Handle;
