import useQueryParamsWithPush from "utilities/hooks/useQueryParamsWithPush";
import React from "react";

import {
  BooleanParam,
  encodeQueryParams,
  NumberParam,
  stringify,
  StringParam,
} from "use-query-params";

const PARAMS = {
  all: BooleanParam,
  tag: NumberParam,
  module: NumberParam,
  exercise: NumberParam,
  exerciseIds: StringParam,
  value: StringParam,
  sort: StringParam,
  dir: StringParam,
  exam: NumberParam,
  test: NumberParam,
  query: StringParam,
  filter: StringParam,
};

const DEFAULT_PARAMS = {
  // all: undefined,
  // tag: undefined,
  // module: undefined,
  // exercise: undefined,
  sort: "score",
  dir: "asc",
};

const getPreviewRouteUrlParams = currentPreviewRoute => {
  // Check the preview link, if it is present it might some url params
  const params = new URLSearchParams(
    decodeURIComponent(currentPreviewRoute).split("?")[1]
  );

  const urlParams = {};
  for (const [key, value] of params) {
    urlParams[key] = value;
  }
  return urlParams;
};

const useCourseParams = () => {
  const {currentPreviewRoute, previewMode} = React.useContext(
    window.PreviewSidebarContext
  );

  const [urlState, setUrlState] = useQueryParamsWithPush(PARAMS);

  const {courseId, getCourseLink} = React.useContext(window.CourseContext);

  const params = previewMode
    ? getPreviewRouteUrlParams(currentPreviewRoute)
    : urlState;

  const {all, module, tag, exercise} = params;

  const selected = module || tag || exercise || all;

  const getLink = (state, newPath = null) => {
    let path = newPath || window.location.pathname;

    if (currentPreviewRoute && previewMode) {
      path = currentPreviewRoute.split("?")[0];
    }

    return `${path}?${stringify(
      encodeQueryParams(PARAMS, {...DEFAULT_PARAMS, ...state})
    )}`;
  };

  const unsetExercise = () => {
    // Ignore the call when in preview mode because exercise can not be selected in preview mode
    if (!previewMode) {
      setUrlState({
        ...urlState,
        exercise: undefined,
      });
    }
  };

  const getAllExercisesLink = () =>
    getLink({
      all: true,
    });

  const getModuleLink = moduleId =>
    getLink({
      module: moduleId,
    });

  const getTagLink = tagId => {
    return getCourseLink(`tag/${tagId}`);
  };

  const getSortAttributeLink = sort =>
    getLink({
      ...urlState,
      sort,
    });

  const getSortDirectionLink = dir =>
    getLink({
      ...urlState,
      dir,
    });

  const getFilterLink = filter => {
    return getLink({...urlState, filter});
  };

  const getExerciseLink = (exerciseId, tagId, moduleId) => {
    if (tagId && moduleId) {
      return getLink({
        ...urlState,
        exercise: exerciseId,
        tag: tagId,
        module: moduleId,
      });
    } else {
      return getLink({...urlState, exercise: exerciseId});
    }
  };

  const getExamLink = examId => getLink({exam: examId, sort: "number"});

  const getTestLink = (test, exerciseId) =>
    getLink({test, exercise: exerciseId});

  const getExerciseWithListLink = (exercise, exerciseIds, value) => {
    return getLink(
      {
        ...urlState,
        exercise,
        exerciseIds,
        value,
      },
      `/course/${courseId}/exercises`
    );
  };

  return {
    ...params,
    setUrlState,
    selected,
    getAllExercisesLink,
    getModuleLink,
    getTagLink,
    getExerciseLink,
    getSortAttributeLink,
    getSortDirectionLink,
    getFilterLink,
    getExamLink,
    getTestLink,
    unsetExercise,
    getExerciseWithListLink,
  };
};

export default useCourseParams;
