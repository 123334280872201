import React from "react";
import Trans from "../../../Components/Trans";

const useCourseExercisesData = () => {
  const sortingCriteriaOptions = [
    {
      value: "popularity",
      icon: "fire-flame-curved",
      label: <Trans>Popular</Trans>,
      sortFunction: exercises =>
        exercises.sort((a, b) => b.stats.total - a.stats.total),
    },
    {
      value: "recency",
      icon: "clock",
      label: <Trans>Recent</Trans>,
      sortFunction: exercises =>
        exercises.sort(
          (a, b) => new Date(b.source.date) - new Date(a.source.date)
        ),
    },
    {
      value: "all",
      label: <Trans>All</Trans>,
    },
    {
      value: "random",
      icon: "random",
      label: <Trans>Random</Trans>,
      sortFunction: exercises => {
        const selected = Array.from(exercises).map(exercise => ({
          ...exercise,
          sort: Math.random(),
        }));

        selected.sort((a, b) => a.sort - b.sort);

        return selected;
      },
    },
  ];

  return {
    sortingCriteriaOptions,
  };
};

export default useCourseExercisesData;
