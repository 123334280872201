import React from "react";
import { CourseParamsContext } from "routes/Course/CourseRouter";
import useRevokeExerciseTagVoteMutation from "API/mutations/exercise/useRevokeExerciseTagVoteMutation";
import useRemoveCategorisationMutation from "API/mutations/exercise/useRemoveCategorisation";
import useCastExerciseTagVoteMutation from "API/mutations/exercise/useCastExerciseTagVoteMutation";
import useValidateTagVoteMutation from "API/mutations/exercise/useValidateTagVoteMutation";
import useCategoriseExerciseMutation from "API/mutations/exercise/useCategoriseExerciseMutation";
import { UserContext } from "UserContextProvider";
import { CourseContext } from "routes/Course/CourseContextProvider";
import useCreateTagMutation from "API/mutations/tag/useCreateTag";

const useTagSuggestionActions = () => {
  const { user } = React.useContext(UserContext);
  const { exercise: exerciseId } = React.useContext(CourseParamsContext);
  const { courseId } = React.useContext(CourseContext);

  const [revokeTagVote] = useRevokeExerciseTagVoteMutation(exerciseId);
  const [castTagVote] = useCastExerciseTagVoteMutation(exerciseId);
  const [setValidated] = useValidateTagVoteMutation(exerciseId);

  const [removeCategorisation] = useRemoveCategorisationMutation(
    exerciseId,
    courseId
  );
  const [categoriseExercise] = useCategoriseExerciseMutation(
    exerciseId,
    courseId
  );

  const [createTag] = useCreateTagMutation(courseId, exerciseId);

  const onTagClick = (tagId, revoke) => {
    if (user) {
      if (user.admin || user.assistant) {
        // setValidated({
        //   variables: { tagId: tag.id, validated: true, exerciseId },
        // });
        categoriseExercise({
          variables: { tagId, exerciseId: exerciseId },
        });
      } else {
        if (revoke) {
          revokeTagVote({ variables: { tagId, exerciseId } });
        } else {
          castTagVote({ variables: { tagId, exerciseId } });
        }
      }
    }
  };

  const onTagClose = tagId => {
    removeCategorisation({
      variables: { tagId: tagId, exerciseId },
    });
  };

  const onAddTag = name => {
    createTag({
      variables: { courseId, name },
    }).then(({ data }) => {
      if (data.createTag.tag) {
        if (user.admin || user.assistant) {
          categoriseExercise({
            variables: {
              tagId: Number(data.createTag.tag.id),
              exerciseId: exerciseId,
            },
          });
        } else {
          castTagVote({
            variables: { exerciseId, tagId: data.createTag.tag.id },
          });
        }
      }
    });
  };

  return { onTagClick, onTagClose, onAddTag };
};

export default useTagSuggestionActions;
