import React from "react";
import useCreateChapterMutation from "API/mutations/chapter/useCreateChapterMutation";
import {useTranslation} from "react-i18next";
import moment from "moment";
import useGPTActionsUpdateChapter from "Components/GPT/GPTActionsSave/GPTActionsSaveChapter/useGPTActionsUpdateChapter";

const useGPTActionsSaveChapter = ({messages, courseId, existingChapterId}) => {
  const {t: trans} = useTranslation();

  const [state, setState] = React.useState({
    newChapterId: existingChapterId || null,
    error: null,
  });

  const chapterTitle = `${trans(
    "Conversation with Kollin-GPT"
  )}, ${moment().format("LLLL")}`;

  const {createChapter, loading: createLoading} = useCreateChapterMutation({
    title: chapterTitle,
    name: "Untitled user document",
    locked: true,
    chapter_type: "Kollin-GPT",
    courseId,
  });

  const {handleUpdate, loading} = useGPTActionsUpdateChapter({courseId});

  const initChapter = async () => {
    const response = await createChapter();

    const {data} = response;

    if (data.createChapter.ok) {
      const newChapter = data.createChapter.chapter;

      setState({
        ...state,
        newChapterId: newChapter.id,
      });

      return newChapter.id;
    }

    if (data.createChapter.errors) {
      setState({
        ...state,
        error: data.createChapter.errors,
        loading: false,
      });
    }
  };

  const onSave = React.useCallback(async () => {
    let chapterId = state.newChapterId;

    if (chapterId === null) {
      chapterId = await initChapter();
    }

    await handleUpdate({
      messages,
      chapterId,
      isNew: state.newChapterId === null,
    });
  }, [state.newChapterId, messages]);

  return {
    ...state,
    loading: createLoading || loading,
    onSave,
  };
};

export default useGPTActionsSaveChapter;
