import React from "react";

const INTERVAL_SECONDS = 10;
/**
 * A state that keeps track of the time spent on the exercise
 * @param {Object} evaluation - The existing user evaluation object
 * @returns
 */

const useExerciseEvaluationTimerState = evaluation => {
  const [timerState, setTimerState] = React.useState({
    timeSpent: evaluation.timeSpent,
    backgroundTimer: evaluation.backgroundTimer || evaluation.timeSpent, // fallback for evaluations that didn't have a background timer
  });

  // The user decides when to start the foreground timer
  const [userTimerStarted, setUserTimerStarted] = React.useState(
    evaluation.timeSpent > 0
  );

  const startUserTimer = () => setUserTimerStarted(true);

  const { timerPaused, toggleTimerPaused } = useTimerPauseStateAndEffect(
    evaluation
  );

  // The counter for timer, an effect that increases the timer's value
  React.useEffect(() => {
    const timer = setInterval(() => {
      // Increases timer each 10th second
      if (!timerPaused && !evaluation.completed) {
        // calculate new time spent, add INTERVAL_SECONDS only if the user has started the timer
        const timeSpent = userTimerStarted
          ? timerState.timeSpent + INTERVAL_SECONDS
          : timerState.timeSpent;

        const backgroundTimer = timerState.backgroundTimer + INTERVAL_SECONDS;

        setTimerState({
          timeSpent,
          backgroundTimer,
        });
      }
    }, 1000 * INTERVAL_SECONDS);

    return () => clearInterval(timer);
  }, [timerState, timerPaused]);

  const resetTimer = () =>
    setTimerState({
      timeSpent: 0,
      backgroundTimer: 0,
    });

  return {
    ...timerState,
    resetTimer,
    timerPaused,
    toggleTimerPaused,
    userTimerStarted,
    startUserTimer,
  };
};

const useTimerPauseStateAndEffect = evaluation => {
  const [timerPaused, setTimerPaused] = React.useState(
    // evaluation.completed // Pause the timer automatically if already completed
    // disable the timer by pausing it by default
    false
  );

  const toggleTimerPaused = () => setTimerPaused(!timerPaused);

  // // visibility tracker for automatically pausing timer if user loses focus or window is inactive:
  React.useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "hidden" && !timerPaused) {
        setTimerPaused(true);
      }
      if (document.visibilityState == "visible" && timerPaused) {
        setTimerPaused(false);
      }
    });
  }, [timerPaused]);

  return { timerPaused, toggleTimerPaused };
};

export default useExerciseEvaluationTimerState;
