import React from "react";
import {Typography} from "Components";
import GroupInfoAdmins from "Widgets/Group/GroupInfo/GroupInfoAdmins";
import useGetGroupInfo from "API/queries/groups/useGetGroupInfo";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";

const GroupInfo = () => {
  const {groupId} = useGroupContext();

  const {info, loading} = useGetGroupInfo({groupId});

  const introLines = info?.introduction?.split("\n\n");

  return (
    <div>
      {introLines?.map((line, index) => (
        <Typography
          type="tmd"
          color="text-gray-700"
          key={index}
          className="mb-4"
        >
          {line}
        </Typography>
      ))}

      <div className="mt-2" />

      <div className="mt-6" />
      <GroupInfoAdmins contacts={info?.contacts} />
    </div>
  );
};

export default GroupInfo;
