import React from "react";
import {Button, Trans} from "Components";
import classnames from "classnames";
import CourseChatGPTResultsMessage from "Widgets/Course/CourseChatGPT/CourseChatGPTResultsMessage/CourseChatGPTResultsMessage";

const CourseChatGPTResults = ({
  showAvatar = true,
  inverted = false,
  messagesContainerHeight = "h-[20rem]",
}) => {
  const {messages, lastCompletion, chatActive} = React.useContext(
    window.GPTContext
  );

  if (messages.length === 0) {
    return null;
  }

  const messagesSorted = React.useMemo(() => {
    return [...messages].reverse();
  }, [messages]);

  if (!chatActive > 0) {
    return null;
  }

  return (
    <div
      className={classnames(
        "w-full flex items-start flex-col-reverse gap-4 overflow-y-scroll py-2 pr-4",
        messagesContainerHeight
      )}
    >
      <span className="align-self-center -mt-2">
        <CourseChatGPTResultsActions />
      </span>
      {messagesSorted.map((result, index) => {
        return (
          <CourseChatGPTResultsMessage
            key={result.id}
            inverted={inverted}
            showAvatar={showAvatar}
            {...result}
          />
        );
      })}
    </div>
  );
};

const CourseChatGPTResultsActions = () => {
  const {
    lastCompletion,
    handleContinueClick,
    handleStopClick,
    settings,
  } = React.useContext(GPTContext);

  if (lastCompletion?.loading) {
    return (
      <Button
        iconLeft="stop"
        fluid={false}
        type="link-destructive"
        size="xs"
        onClick={handleStopClick}
      >
        <Trans>Stop</Trans>
      </Button>
    );
  }

  if (
    window.Math.abs(
      Number(lastCompletion?.tokens) - Number(settings.maxTokens)
    ) < 10
  )
    return (
      <Button
        onClick={handleContinueClick}
        iconLeft="chevron-down"
        fluid={false}
        type="link"
        size="xs"
      >
        <Trans>Continue</Trans>
      </Button>
    );

  // Add a fake button to keep the layout consistent
  return (
    <span className="opacity-0 pointer-events-none">
      <Button iconLeft="stop" fluid={false} type="link-destructive" size="xs">
        <Trans>ghost button</Trans>
      </Button>
    </span>
  );
};

export default CourseChatGPTResults;
