import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

export const GET_USER_EVALUATIONS = gql`
  query getUserEvaluations(
    $objectId: ID!
    $objectName: String!
    $source: String
    $limit: Int
    $offset: Int
  ) {
    getUserEvaluations(
      objectId: $objectId
      objectName: $objectName
      source: $source
      limit: $limit
      offset: $offset
    ) {
      id
      objectName
      objectId
      source
      evaluation
      createdAt
      updatedAt
    }
  }
`;

const useGetUserEvaluations = ({ objectId, objectName, source }) => {
  let results = [];

  const { loading, error, data } = useQuery(GET_USER_EVALUATIONS, {
    variables: { objectId, objectName, source },
    context: {
      batch: true,
    },
  });

  if (error) {
    console.log("Error in useGetUserEvaluations", error);
  }

  if (data && data.getUserEvaluations) {
    results = data.getUserEvaluations;
  }

  return [results, loading];
};

export default useGetUserEvaluations;
