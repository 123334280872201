import i18n from "i18n";

// noinspection JSUnusedGlobalSymbols
export const CHAPTER_TRANSLATION = {
  Theory: i18n.t("Theory"),
  Example: i18n.t("Example"),
  Appendix: i18n.t("Appendix"),
  Theorem: i18n.t("Theorem"),
  Video: i18n.t("Video"),
};

export const CHAPTER_SORT_ORDER = [
  "Theory",
  "Theorem",
  "Example",
  "Video",
  "Appendix",
];
