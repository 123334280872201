import React from "react";
import {CourseCard, File, Icon, Tabs, Trans, Typography} from "Components";
import useGetCourse from "API/queries/course/useGetCourse";
import useGetCoursePrerequisites from "API/queries/course/useGetCoursePrerequisites";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "utilities/text";
import Widget from "../../../Components/Widget/Widget";

const CourseInfo = () => {
  const {courseId} = React.useContext(CourseContext);
  const {t: trans} = useTranslation();
  const [course, loading] = useGetCourse(courseId, false, false, true);
  const [prerequisites, loadingPrerequisites] = useGetCoursePrerequisites(
    courseId
  );
  const [requiredPrerequisites, recommendedPrerequisites] = React.useMemo(
    () => groupPrerequisites(prerequisites),
    [prerequisites, loadingPrerequisites]
  );

  if (loading || loadingPrerequisites) return null;

  const metadata = course?.metadata?.schoolData;

  if (!metadata) return null;

  /**
   * The available tabs for the course info:
   * ---------------------------------------
   * 1. Course content: metadata.content
   * 2. Learning outcomes: metadata.learningOutcomes[]
   * 3. Course PM &  literature: metadata.coursePM, metadata.literature
   * 4. Prerequisites: prerequisites[], both required and recommended
   */

  return (
    <Widget
      widgetName="CourseInfo"
      title={<Trans>Course info</Trans>}
      background="bg-primary-25"
      borderColor="border-primary"
    >
      <div className="h-96 shrink-0 overflow-y-auto">
        <Tabs type="underline" fluid={false} animate={false}>
          <Tabs.Pane
            title={<Trans>Content</Trans>}
            key="content"
            hidden={!metadata?.content}
          >
            <Typography>{metadata?.content}</Typography>
          </Tabs.Pane>
          <Tabs.Pane
            title={<Trans>Objectives</Trans>}
            key="learningOutcomes"
            hidden={
              Array.isArray(metadata?.learningOutcomes)
                ? !metadata?.learningOutcomes?.length
                : !metadata?.learningOutcomes
            }
          >
            {Array.isArray(metadata?.learningOutcomes) ? (
              metadata?.learningOutcomes?.map(outcome => (
                <div className="flex items-start gap-2 mb-2">
                  <Icon
                    icon="fas fa-circle-small"
                    color="text-gray-light"
                    size="2xs"
                  />
                  <Typography> {capitalizeFirstLetter(outcome)}</Typography>
                </div>
              ))
            ) : (
              <Typography className="mb-2">
                {metadata?.learningOutcomes}
              </Typography>
            )}
          </Tabs.Pane>
          <Tabs.Pane
            title={<Trans>PM</Trans>}
            key="pm"
            hidden={!metadata?.coursePlanLink && !metadata?.literature}
          >
            <File
              size={null}
              fluid={false}
              name={trans("Course PM")}
              url={metadata?.coursePlanLink}
              previewButtonProps={{
                icon: "external-link-alt",
                type: "link",
                onClick: () => {
                  window.open(metadata?.coursePlanLink, "_blank");
                },
              }}
            />
            {metadata?.literature && (
              <div className="my-4">
                <Trans weight="md" className="mb-2">
                  Course literature
                </Trans>
                <Typography>{metadata?.literature}</Typography>
              </div>
            )}
          </Tabs.Pane>
          <Tabs.Pane
            title={<Trans>Prerequisite courses</Trans>}
            key="prerequisites"
            hidden={!prerequisites?.length}
          >
            {!!requiredPrerequisites?.length && (
              <Trans weight="md" className="mb-2">
                Required prerequisites
              </Trans>
            )}
            {requiredPrerequisites?.map(prerequisite => (
              <CourseCard
                size="sm"
                {...prerequisite.course}
                {...prerequisite.course?.currentUser}
                showPurchaseInfo={false}
                showActiveBadge={false}
              />
            ))}
            {!!recommendedPrerequisites?.length && (
              <Trans weight="md" className="mb-2">
                Recommended prerequisites
              </Trans>
            )}
            {recommendedPrerequisites?.map(prerequisite => (
              <CourseCard
                {...prerequisite.course}
                {...prerequisite.course?.currentUser}
                size="sm"
                showPurchaseInfo={false}
                showActiveBadge={false}
              />
            ))}
          </Tabs.Pane>
        </Tabs>
      </div>
    </Widget>
  );
};

const groupPrerequisites = prerequisites => {
  return prerequisites.reduce(
    (acc, curr) => {
      if (curr.required) {
        acc[0].push(curr);
      } else {
        acc[1].push(curr);
      }
      return acc;
    },
    [[], []]
  );
};

export default CourseInfo;
