import React from "react";
import UseOTPVerification from "Widgets/User/OTP/OTPVerification/useOTPVerification";
import {Button, InputCode, Modal, Trans} from "Components";
import {
  OTPTextPromptSubTitle,
  OTPTextPromptTitle,
} from "Widgets/User/OTP/OTPText";
import {motion} from "framer-motion";

const OTPVerification = ({
  requestSuccessful,
  showModal,
  onComplete,
  email,
  action,
  resend,
  transparent = false,
}) => {
  const {
    loading,
    values,
    setFormValue,
    errors,
    submit,
    verificationSuccessful,
  } = UseOTPVerification({
    email,
    action,
    onComplete,
  });

  // Nothing to do until OTP-request is successful
  if (!requestSuccessful) {
    return null;
  }

  const Wrapper = showModal ? Modal : "div";

  return (
    <Wrapper
      size="lg"
      // These props are only relevant for the Modal
      title={<OTPTextPromptTitle noStyling={true} />}
      icon="envelope"
      description={<OTPTextPromptSubTitle email={email} noStyling={true} />}
      defaultOpen={true}
      maskClosable={false}
    >
      <div className="mt-4 w-fit">
        <motion.div
          key="code"
          initial={{opacity: 0, x: 100}}
          animate={{opacity: 1, x: 0}}
        >
          <InputCode
            value={values.code}
            onChange={code => {
              setFormValue({code});
            }}
            digits={6}
            size="sm"
            error={errors.code}
            fluid={!showModal} // If modal, we want the input to be contained
            successMessage={
              verificationSuccessful ? (
                <Trans>Verification successful</Trans>
              ) : null
            }
            transparent={transparent}
          />
          <Button
            onClick={submit}
            loading={loading}
            type="primary"
            className="mt-4"
            fluid
            disabled={
              !values.code || values.code.length < 6 || verificationSuccessful
            }
          >
            <Trans>Verify</Trans>
          </Button>
          {resend && (
            <div className="flex gap-2 items-center mt-2">
              <Trans>Didn't get the code?</Trans>
              <Button onClick={resend} type="link" size="sm">
                <Trans>Send again</Trans>{" "}
              </Button>
            </div>
          )}
        </motion.div>
      </div>
    </Wrapper>
  );
};

export default OTPVerification;
