import React from "react";
import useSubmitForm from "API/sheets";
import {Button, Icon, Input, message} from "Components/_Elements";
import {Trans} from "Components/index";
import classnames from "classnames";

const WidgetFeedbackForm = ({
  widgetName,
  likeType: propsLikeType = null,
  handleClose = () => {},
  title = (
    <Trans type="tmd" weight="sb">
      Was this widget useful?
    </Trans>
  ),
  likeable = true,
}) => {
  const {courseId} = React.useContext(window.CourseContext);
  const {user} = React.useContext(window.UserContext);
  const [feedbackState, setFeedbackState] = React.useState({
    likeType: propsLikeType,
    feedbackText: "",
    widgetName: widgetName,
  });

  const handleLikeButtonClick = likeType => {
    setFeedbackState({...feedbackState, likeType: likeType});
  };

  const {submit} = useSubmitForm("WIDGET_FEEDBACK_FORM");

  const onSubmit = e => {
    e.preventDefault();
    submit({
      ...feedbackState,
      userId: user?.id,
      email: user?.email,
      courseId: courseId,
      createdAt: new Date().toISOString(),
    });

    message.success("Tack för din feedback!");

    handleClose();
  };

  return (
    <div className="flex flex-col gap-4 p-2 items-center">
      {title}
      {!propsLikeType && likeable && (
        <div className="flex gap-2">
          <Button
            type={feedbackState.likeType == "yes" ? "secondary" : "gray"}
            size="sm"
            onClick={() => {
              handleLikeButtonClick("yes");
            }}
          >
            <Icon icon="fa-light fa-thumbs-up"></Icon>
          </Button>
          <Button
            type={feedbackState.likeType == "no" ? "secondary" : "gray"}
            size="sm"
            onClick={() => {
              handleLikeButtonClick("no");
            }}
          >
            <Icon icon="fa-light fa-thumbs-down"></Icon>
          </Button>
        </div>
      )}
      <form
        className={classnames(
          "flex flex-col gap-2 w-full",
          likeable ? (feedbackState.likeType == null ? "hidden" : "") : ""
        )}
        onSubmit={onSubmit}
      >
        <Input
          onChange={e =>
            setFeedbackState({...feedbackState, feedbackText: e.target.value})
          }
          label={"Har du tips eller idéer?"}
          placeholder={"Skriv här"}
        />
        <div className="flex gap-2 justify-end w-full">
          <Button type="link-destructive" size="xs" onClick={handleClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button type="primary" size="xs" onClick={onSubmit}>
            <Trans type="tmd">Send</Trans>
          </Button>
        </div>
      </form>
    </div>
  );
};
export default WidgetFeedbackForm;
