import React from "react";
import {Button, DifficultyChip, Icon, Trans} from "Components";
import ExerciseCheckMark from "Components/ExerciseCheckMark/ExerciseCheckMark";
import {useHistory} from "react-router-dom";
import {CourseParamsContext} from "../../../routes/Course/CourseRouter";

const useCourseExercisesTable = exercises => {
  const {getCourseLink} = React.useContext(window.CourseContext);
  const history = useHistory();
  const {getExerciseWithListLink} = React.useContext(CourseParamsContext);

  const handleClick = exerciseId => {
    const exerciseLink = getExerciseWithListLink(
      exerciseId,
      exercises.map(e => e.id),
      "all"
    );
    history.push(exerciseLink);
  };

  const columns = [
    {
      key: "date",
      title: <Trans>Date</Trans>,
      dataIndex: "source",
      render: source => {
        return source.date;
      },
      truncate: false,
    },
    {
      key: "name",
      title: "#",
      dataIndex: "number",
    },
    // {
    //   key: "author",
    //   title: <Trans>Author</Trans>,
    //   dataIndex: "source",
    //   render: source => {
    //     return source.author != "" ? source.author : "-";
    //   },
    // },
    {
      key: "difficulty",
      title: <Trans>Difficulty</Trans>,
      dataIndex: "score",
      render: score => {
        return <DifficultyChip score={score} filled className="h-6" />;
      },
    },
    {
      key: "votes",
      title: <Icon icon="fa-solid fa-fire-flame-curved" size="xs" />,
      dataIndex: "stats",
      render: stats => stats?.total || 0,
    },
    {
      key: "self-evaluation",
      title: <Trans>Completed</Trans>,
      dataIndex: "self_evaluation",
      render: (self_evaluation, exercise) => {
        return <ExerciseCheckMark exercise={exercise} showIfUnrated />;
      },
    },
    {
      key: "bookmarked",
      title: <Trans>Bookmarked</Trans>,
      dataIndex: "bookmarked",
      render: bookmarked => {
        return bookmarked ? (
          <Icon icon="fas fa-bookmark" color="text-primary" />
        ) : (
          <Icon icon="bookmark" color="text-gray-light" />
        );
      },
    },
    // {
    //   key: "published",
    //   title: <Trans>Validated</Trans>,
    //   dataIndex: "published",
    //   render: published => {
    //     return published ? (
    //       <Icon icon="check-circle" color="text-success-light" />
    //     ) : (
    //       <Icon icon="times-circle" color="text-warning" />
    //     );
    //   },
    // },
    {
      key: "preview",
      dataIndex: "id",
      render: id => (
        <Button
          onClick={() => handleClick(id)}
          type="link"
          icon="chevron-right"
          size="sm"
        />
      ),
    },
  ];

  return columns;
};

export default useCourseExercisesTable;
