import React from "react";
import classnames from "classnames";
import {Trans, Typography} from "Components";
import PropTypes from "prop-types";
import {motion} from "framer-motion";
import ExamUploadStepTitle from "routes/Course/ExamUpload/ExamUploadStep/ExamUploadStepTitle";
import ExamUploadStepBody from "routes/Course/ExamUpload/ExamUploadStep/ExamUploadStepBody";

const labelSizeMapping = {
  "not started": 32,
  current: 48,
  completed: 24,
};

const ExamUploadStep = ({
  title,
  subTitle,
  activeComponent,
  inactiveComponent,
  completedMessage,
  currentMessage,
  status,
  index,
  currentStep,
  fullWidth,
  isLastStep,
  showSteps,
}) => {
  const showBody = currentStep === index;

  return (
    <div>
      {showSteps && (
        <div
          className={classnames(
            "flex p-4 gap-4 w-full",
            status === "completed" ? "surface" : "background",
            isLastStep ? "border-b border-gray" : "border-b-0"
          )}
        >
          <div className="w-14 flex flex-col items-center">
            <motion.div
              className={classnames(
                "rounded-full aspect-square relative shrink-0", // flex flex-col items-center justify-center",
                status === "current" ? "bg-primary-600" : "bg-gray-400"
              )}
              initial={{
                width: 32,
                height: 32,
              }}
              animate={{
                width: labelSizeMapping[status],
                height: labelSizeMapping[status],
              }}
            >
              <Typography
                color="text-white"
                weight="bold"
                className={classnames(
                  "text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
                  status === "current" ? "text-lg" : "text-xs"
                )}
              >
                {index + 1}
              </Typography>
            </motion.div>
            {status != "completed" && (
              <div
                className={classnames(
                  status === "current" ? "bg-primary-600" : "bg-gray-300",
                  "w-0.5 mt-1",
                  index === 2 ? "" : "-mb-6 h-[85%]"
                )}
              />
            )}
          </div>
          <div className="grow">
            <ExamUploadStepTitle
              title={title}
              status={status}
              subTitle={subTitle}
              completedMessage={completedMessage}
              currentMessage={currentMessage}
            />
            {!fullWidth && showBody && (
              <motion.div {...bodyMotionProps} key={"body-" + index}>
                <ExamUploadStepBody
                  activeComponent={activeComponent}
                  inactiveComponent={inactiveComponent}
                  status={status}
                />
              </motion.div>
            )}
          </div>
        </div>
      )}
      {fullWidth && showBody && (
        <motion.div {...bodyMotionProps} key={"body-" + index}>
          <ExamUploadStepBody
            activeComponent={activeComponent}
            inactiveComponent={inactiveComponent}
            status={status}
            fullWidth={fullWidth}
          />
        </motion.div>
      )}
    </div>
  );
};

const bodyMotionProps = {
  initial: {
    height: 0,
    opacity: 0,
  },
  animate: {
    height: "auto",
    opacity: 1,
  },
  exit: {
    height: 0,
    opacity: 0,
  },
  transition: {
    duration: 0.5,
  },
};

ExamUploadStep.propTypes = {
  /**
   * The title of the step
   */
  title: PropTypes.instanceOf(Trans).isRequired,
  /**
   * The subtitle of the step
   */
  subTitle: PropTypes.instanceOf(Trans).isRequired,
  /**
   * The component that should be shown when this step is active
   */
  activeComponent: PropTypes.instanceOf(React.Component).isRequired,

  /**
   * The component that should be shown when this step is not active and not completed
   */
  inactiveComponent: PropTypes.instanceOf(React.Component).isRequired,

  /**
   * Extra info shown when step is completed - shown on the right side of the title with the checkmark
   */
  completedMessage: PropTypes.instanceOf(Trans),

  /**
   * The status of the step
   */

  status: PropTypes.oneOf(["not started", "current", "completed"]).isRequired,
};

export default ExamUploadStep;
