import React from "react";
import classnames from "classnames";
import {Link, Logo} from "Components/_Elements";
import {Trans} from "Components/index";
import {UserContext} from "UserContextProvider";
import {useHistory} from "react-router-dom";

const UserMenuDesktopGlobal = ({inverted = false, logoComponentProps}) => {
  const {user} = React.useContext(UserContext);
  const history = useHistory();
  const premium = user && (user.premium || user.subscriber);
  return (
    <div
      id="user-menu-left"
      className={classnames("flex sm:gap-4 items-center")}
    >
      {/* Hide the logo when inside the course */}

      <Logo
        type={inverted ? "white" : "primary"}
        size="sm"
        showText={true}
        alt="logo"
        className={inverted && "mix-blend-soft-light"}
        onClick={() => {
          window.analytics.track("user_menu_logo_clicked", {
            screen_name: window.location.pathname,
          });

          history.push("/frontpage");
        }}
        {...logoComponentProps}
      />

      <Link
        to="/home"
        inline={false}
        onClick={() =>
          window.analytics.track("user_menu_dashboard_clicked", {
            screen_name: window.location.pathname,
          })
        }
      >
        <Trans>Dashboard</Trans>
      </Link>

      <Link
        inline={false}
        to="/courses"
        className="hidden md:block"
        onClick={() =>
          window.analytics.track("user_menu_find_courses_clicked", {
            screen_name: window.location.pathname,
          })
        }
      >
        <Trans>Courses</Trans>
      </Link>
      <Link
        inline={false}
        to="/pricing"
        className="hidden md:block"
        onClick={() =>
          window.analytics.track("user_menu_pricing_clicked", {
            screen_name: window.location.pathname,
          })
        }
      >
        <Trans>Pricing</Trans>
      </Link>

      <Link
        target="_blank"
        inline={false}
        href="https://iamkollin.notion.site/iamkollin/Jobba-med-Kollin-dff3809340924678aa5eec33108c7c21"
        className="hidden md:block"
        onClick={() =>
          window.analytics.track("user_menu_jobs_clicked", {
            screen_name: window.location.pathname,
          })
        }
      >
        <Trans>About Kollin</Trans>
      </Link>
    </div>
  );
};

export default UserMenuDesktopGlobal;
