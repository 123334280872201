import React from "react";
import useGetGroupInfo from "API/queries/groups/useGetGroupInfo";
import useGroupSettingsInfo from "routes/Group/GroupSettings/GroupSettingsInfo/useGroupSettingsInfo";
import {Section, SubSection, TextArea, Trans, Button} from "Components";
import GroupSettingsInfoLinks from "routes/Group/GroupSettings/GroupSettingsInfo/GroupSettingsInfoLinks/GroupSettingsInfoLinks";
import GroupInfoSettingsAdmins from "routes/Group/GroupSettings/GroupSettingsInfo/GroupInfoSettingsAdmins/GroupInfoSettingsAdmins";
import {motion} from "framer-motion";

const GroupSettingsInfo = ({groupInfo, refetch}) => {
  const {
    info,
    changedFields,
    handleLinksChange,
    handleIntroductionChange,
    handleSave,
  } = useGroupSettingsInfo({groupInfo, refetch});

  return (
    <div>
      <div className="flex flex-col gap-4">
        <SubSection
          title={<Trans>Introduction</Trans>}
          subTitle={
            <Trans>
              This is the introduction of your group. It will be shown to all
              members on the homepage.
            </Trans>
          }
        >
          <div>
            <TextArea
              className="w-full bg-slate-100 h-64"
              type="text"
              text={info.introduction}
              onChange={handleIntroductionChange}
            />
            {changedFields.includes("introduction") && (
              <Button type="primary" onClick={handleSave} fluid size="lg">
                <Trans>Save</Trans>
              </Button>
            )}
          </div>
        </SubSection>

        <GroupSettingsInfoLinks
          initialLinks={info?.links}
          handleLinksChange={handleLinksChange}
          handleSave={handleSave}
        />

        <GroupInfoSettingsAdmins />
      </div>
    </div>
  );
};

export default GroupSettingsInfo;
