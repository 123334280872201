import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_SCHOOL_PROGRAMS = gql`
  query getSchool($schoolId: ID!) {
    getSchool(id: $schoolId) {
      id
      programs {
        id
        name
        studentsCount
      }
    }
  }
`;

const useGetSchoolPrograms = schoolId => {
  let programs = [];

  const {data, loading, error} = useQuery(GET_SCHOOL_PROGRAMS, {
    variables: {schoolId},
  });

  if (error) {
    console.log(`Error in useGetSchoolPrograms ${error}`);
  }

  if (data && data.getSchool.programs) {
    programs = data.getSchool.programs.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
  }

  return [programs, loading];
};

export default useGetSchoolPrograms;
