import React from "react";
import ReactDOM from "react-dom";
import {AnimatePresence, motion} from "framer-motion";
import PreviewSidebarHeader from "Wrappers/PreviewSidebar/PreviewSidebarHeader";
import PreviewSidebarResizeHandle from "Wrappers/PreviewSidebar/PreviewSidebarResizeHandle";

const PreviewSidebar = ({
  route,
  handleClosePreview,
  sidebarWidth,
  resizeHandleRef,
  previewOpen,
  Router,
}) => {
  // TODO: Handle the case with Component and Title, instead of route
  // A sidebar that comes in from the right and overlays the content

  if (!previewOpen) return null;

  const [pathname, search] = route.split("?");

  return ReactDOM.createPortal(
    <AnimatePresence>
      <PreviewSidebarResizeHandle
        resizeHandleRef={resizeHandleRef}
        sidebarWidth={sidebarWidth}
      />
      <motion.div
        key="preview-sidebar"
        layoutId="preview-sidebar"
        className="h-full overflow-x-hidden bg-white fixed right-0 top-0 z-50 shadow-l-lg border-l-2 border-gray-200"
        initial={{opacity: 0, x: 100}}
        animate={{opacity: 1, x: 0, width: sidebarWidth}}
        exit={{opacity: 0, x: 100}}
        transition={{
          width: {duration: 0},
        }}
      >
        {/* Header */}

        <PreviewSidebarHeader
          handleClosePreview={handleClosePreview}
          expandLink={route}
        />

        <div className="p-4 z-50 relative" key="preview-sidebar-content">
          {React.cloneElement(Router, {
            previewMode: true,
            route: {
              pathname,
              search,
              hash: "",
              state: undefined,
            },
          })}
        </div>
      </motion.div>
    </AnimatePresence>,
    document.body
  );
};

export default PreviewSidebar;
