import {ProgressBar, Trans} from "Components";
import React from "react";
import {nCompleted} from "utilities/exerciseUtilities/exerciseListOperations";
import "./CourseLandingTopicTagProgress.scss";
import {UserContext} from "UserContextProvider";

const CourseLandingTopicTagProgress = ({exercises}) => {
  const {user} = React.useContext(UserContext);

  //   if (user === null) return null;

  const formattedExercises = exercises.map(ex => {
    return {selfEvaluation: ex.self_evaluation};
  });

  const completedExercises = nCompleted(formattedExercises);

  const nExercises = exercises.length;
  return (
    <div className="flex  gap-2 items-center flex-nowrap whitespace-nowrap">
      <ProgressBar
        progressRatio={completedExercises / exercises.length}
        style={{
          color: "#29B062",
        }}
      />

      <Trans
        completedExercises={completedExercises}
        nExercises={nExercises}
        type="tsm"
      >
        {{completedExercises}}/{{nExercises}} exercises
      </Trans>
    </div>
  );
};

export default CourseLandingTopicTagProgress;
