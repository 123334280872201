import {
  DifficultyChip,
  Skeleton,
  Trans,
  Icon,
  ErrorBoundary,
  Typography,
  Badge,
  Modal,
  Button,
} from "Components";
import React from "react";
import useCourseLandingTopicDetails from "./useCourseLandingTopicTag";
import "./CourseLandingTopicTag.scss";
import {AnimatePresence} from "framer-motion";
import CourseLandingTopicPreviewContextWrapper from "routes/Course/CourseLanding/CourseLandingDesktop/CourseLandingTopics/CourseLandingTopicPreview/CourseLandingTopicPreviewContext";
import classnames from "classnames";
import CourseLandingTopicTagProgress from "routes/Course/CourseLanding/CourseLandingDesktop/CourseLandingTopics/CourseLandingTopicDetails/CourseLandingTopicTag/CourseLandingTopicTagProgress/CourseLandingTopicTagProgress";
import useHandleCoursePurchaseClick from "utilities/hooks/useHandleCoursePurchaseClick";

const CourseLandingTopicTag = ({tag, tagIndex, freeTopic}) => {
  const {id: courseId} = React.useContext(window.CourseContext);
  const {
    loading,
    tagChapterContent,
    exerciseAvgScore,
  } = useCourseLandingTopicDetails(tag);

  const onPurchaseClick = useHandleCoursePurchaseClick(courseId);

  const [openModal, setOpenModal] = React.useState(false);

  if (loading) return <Skeleton style={{marginTop: "10px"}} />;

  if (tag?.exercises.length == 0 && tagChapterContent.length == 0) return null;
  const Wrapper = freeTopic && openModal ? Modal : "div";

  return (
    <div>
      <Wrapper
        className={classnames(
          "border-2 border-gray mb-2 rounded-xl p-4",
          freeTopic
            ? "bg-white cursor-pointer"
            : "surface cursor-not-allowed relative",
          openModal && "min-w-[80vw] h-[90vh] overflow-y-scroll"
        )}
        onClick={() => setOpenModal(!openModal)}
        defaultOpen={openModal}
        onClose={() => setOpenModal(false)}
        animate={false}
        size="lg"
        closable={true}
      >
        {openModal && freeTopic && (
          <div className="bg-primary-800  rounded-lg shadow-2xl px-4 py-2  w-full flex items-center justify-between">
            <div className="flex gap-2 items-start grow">
              <Icon icon="sparkles" color="text-amber-400" size="md" />

              <Trans
                type="tsm"
                weight="sb"
                // inline
                color="text-amber-100"
                // className="mb-0 leading-none"
              >
                This is a preview of the topic. To access the full topic unlock
                the course.
              </Trans>
            </div>
            <Button onClick={onPurchaseClick} size="xs" type="primary">
              <Trans>Unlock now</Trans>
            </Button>
          </div>
        )}

        <div
          className={classnames(
            "flex gap-4 items-center justify-between",
            openModal && freeTopic && "mt-8"
          )}
        >
          <div className="surface-primary rounded-full w-8 aspect-square flex items-center justify-center shrink">
            {freeTopic ? (
              <Typography type="tlg" weight="b" color="text-primary-light">
                {tagIndex + 1}
              </Typography>
            ) : (
              <i className="fas fa-lock" style={{margin: 0}} />
            )}
          </div>

          <div className="grow flex gap-2">
            <Typography type="tlg" weight="sb" color="text-gray-600">
              {tag.name}
            </Typography>
          </div>

          {exerciseAvgScore > 0 && (
            <DifficultyChip
              displayText="Svårighetsgrad"
              score={exerciseAvgScore}
              filled={true}
            />
          )}
        </div>

        <div className="grow w-full flex gap-4 mt-8 w-full items-center">
          <div className="rounded-full w-8" />
          {tagChapterContent.map((content, i) => (
            <Badge icon={getChapterIconName(content)} key={i} type="purple">
              <Trans>{content}</Trans>
            </Badge>
          ))}

          {tag?.exercises.length > 0 ? (
            <div className="w-64">
              <CourseLandingTopicTagProgress exercises={tag?.exercises} />
            </div>
          ) : null}
        </div>

        <ErrorBoundary>
          {openModal && freeTopic && (
            <div className="mt-8 order-t-2 border-gray">
              <CourseLandingTopicPreviewContextWrapper
                tag={tag}
                tagIndex={tagIndex}
                toggleOpenModal={() => setOpenModal(!openModal)}
              />
            </div>
          )}
        </ErrorBoundary>
      </Wrapper>
    </div>
  );
};

export default CourseLandingTopicTag;

export const getChapterIcon = chapterType => {
  switch (chapterType) {
    case "Theory":
    case "Theorem":
      return <Icon sharp icon="fa-light fa-function" />;
    case "Video":
      return <Icon sharp icon="fa-light fa-play-circle" />;
    case "Example":
      return <Icon sharp icon="fa-light fa-flask-vial" />;
    case "Appendix":
      return <Icon sharp icon="fa-light fa-puzzle-piece" />;
    default:
      return <Icon sharp icon="fa-light fa-file-lines" />;
  }
};

export const getChapterIconName = chapterType => {
  switch (chapterType) {
    case "Theory":
    case "Theorem":
      return "function";
    case "Video":
      return "play-circle";
    case "Example":
      return "flask-vial";
    case "Appendix":
      return "puzzle-piece";
    default:
      return "file-lines";
  }
};
