import React from "react";
import {Button, Link, TextArea, Trans} from "Components";
import classnames from "classnames";
import {GPTContext} from "./GPTProvider";
import GPTUserSettings from "Components/GPT/GPTUserSettings/GPTUserSettings";
import GPTPromptActions from "Components/GPT/GPTPromptActions";
import GPTCostCredits from "Components/GPT/GPTCostCredits/GPTCostCredits";

const GPTPromptInput = ({expandOnFocus = true, placeholder}) => {
  const {
    prompt,
    handlePromptChange,
    promptInputFocused,
    handlePromptInputFocus,
    handlePromptInputBlur,
    promptInputHasBeenFocused,
    loading,
  } = React.useContext(GPTContext);

  const {user} = React.useContext(window.UserContext);

  if (!user) {
    return (
      <Link to="/signup" asButton buttonType="gray" iconRight="arrow-right">
        <Trans>Sign up to get started</Trans>
      </Link>
    );
  }

  return (
    <div className="mt-2">
      <div
        className={classnames(
          "flex flex-col gap-2 items-start rounded-lg border border-2 background overflow-clip",
          "transition-border duration-200 ease-in-out delay-75",
          promptInputFocused ? "border-primary-200" : "border-gray-200"
        )}
      >
        <TextArea
          size="sm"
          className={classnames("w-full grow border-none focus:outline-none")}
          expandOnFocus={!promptInputHasBeenFocused}
          blurredHeight="h-12"
          placeholder={placeholder}
          onChange={handlePromptChange}
          text={prompt}
          onFocus={handlePromptInputFocus}
          onBlur={handlePromptInputBlur}
          disabled={loading}
        />

        <GPTCostCredits />
      </div>

      <GPTPromptActions />
    </div>
  );
};

export default GPTPromptInput;
