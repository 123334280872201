import * as Sentry from "@sentry/react";
import ErrorBoundary from "AppErrorBoundary";
import PageNavbar from "Components/Navbars/PageNavbar/PageNavbar";
import PageLoader from "Components/PageLoader";
import React, {Suspense} from "react";
import {Redirect, Route, Router, Switch, useLocation} from "react-router-dom";

import {QueryParamProvider} from "use-query-params";
import {UserContext} from "UserContextProvider";

/** CONTEXTS */
import UserContextProvider from "./UserContextProvider";
import GlobalModals from "GlobalModals";
import AppContextProvider from "AppContextProvider";
import PreviewSidebarWrapper from "Wrappers/PreviewSidebar/PreviewSidebarWrapper";
import {NotificationsContextProvider} from "Components";
import useGetErrorCodes from "utilities/hooks/useGetErrorCodes";
import RestPasswordUrlToken from "routes/Auth/ForgotPassword/RestPasswordUrlToken";
import Sandbox from "routes/Sandbox/Sandbox";
import useRoutes from "routes";
import UserMenu from "Components/Navbars/UserMenu/UserMenu";
import GroupProvider, {isInGroup} from "Providers/GroupProvider/GroupProvider";
import VersionUpdateBanner from "Components/VersionUpdateBanner/VersionUpdateBanner";

export const RouteSwitch = ({route}) => {
  let location = useLocation();
  const {user} = React.useContext(UserContext);

  const routes = useRoutes();
  const isInCourse = location.pathname.includes("/course/");

  return (
    <Suspense fallback={<PageLoader />}>
      {!isInGroup && <PageNavbar location={route || location} />}

      {user && !isInCourse && !isInGroup && <UserMenu rootMount={true} />}
      <VersionUpdateBanner />
      <GroupProvider routes={routes}>
        <Switch
          location={route || location}
          key={route?.pathname || location.pathname}
        >
          {routes.map(
            ({path, Component, componentProps, type, exact}, index) => {
              const RouteType = type || Route;

              if (Component === undefined || path === undefined) {
                return null;
              }

              return (
                <RouteType
                  key={index}
                  path={path}
                  exact={exact}
                  children={
                    <Suspense fallback={<PageLoader />}>
                      <Component {...componentProps} path={path} />
                    </Suspense>
                  }
                />
              );
            }
          )}
        </Switch>
      </GroupProvider>
    </Suspense>
  );
};

function App({history}) {
  useGetErrorCodes();

  return (
    //  Prevents horizontal scrolling on the app
    <UserContextProvider>
      <ErrorBoundary>
        <AppContextProvider>
          <Router history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <NotificationsContextProvider>
                <GlobalModals>
                  <PreviewSidebarWrapper Router={<RouteSwitch />} />
                </GlobalModals>
              </NotificationsContextProvider>
            </QueryParamProvider>
          </Router>
        </AppContextProvider>
      </ErrorBoundary>
    </UserContextProvider>
  );
}

export default App;
