import React from "react";
import {GET_COURSE_EXAMS} from "API/queries/exam/useGetCourseExams";
import useCreateSourceFromResources from "API/mutations/exam/useCreateSourceFromResources";

const useExamUploadSaveActionCreate = onResourceSaved => {
  const {courseId} = React.useContext(window.CourseContext);

  const refetchQueries = [
    {
      query: GET_COURSE_EXAMS,
      variables: {courseId, includeExerciseSolutions: false},
    },
  ];

  const {
    createSourceFromResources,
    loading: creatingExam,
    data,
  } = useCreateSourceFromResources({refetchQueries});

  const handleCreate = async ({
    date,
    exercisesResource,
    solutionsResource,
    author = null,
  }) => {
    const response = await createSourceFromResources({
      variables: {
        courseId,
        date,
        exercisesResourceId: exercisesResource?.id,
        solutionsResourceId: solutionsResource?.id,
        author,
      },
    });

    const {data} = response;

    if (data?.createSourceFromResources?.ok) {
      // Mark the exercise resource as saved
      if (exercisesResource) onResourceSaved(exercisesResource);
      //      // Mark the solution resource as saved
      if (exercisesResource !== solutionsResource && solutionsResource)
        onResourceSaved(solutionsResource);
    }
  };
  return handleCreate;
};

export default useExamUploadSaveActionCreate;
