import React from "react";
import { UserContext } from "UserContextProvider";

const NonSubscriber = ({ children }) => {
  const { user } = React.useContext(UserContext);

  if (user?.premium || user?.subscriber) return null;

  return children;
};

export default NonSubscriber;
