const getOverviewStats = documents => {
  const dates = Object.keys(documents.dates);

  const overview = {
    // File level stats
    totalFiles: documents["all"].length,
    existingFiles: 0,
    newFiles: 0,
    unknownTypeFiles: 0,
    unknownDateFiles: documents["unknown_date"]?.length || 0,
    // Exam level stats
    savedExams: 0,
    totalExams: 0,
  };

  /**
   * File properties:
   * - hidden: If the file is hidden because it was uploaded before
   * - isNew: If the file was just uploaded, Always false if hidden is true
   * - Saved: If the file was saved, Always true if isNew is false
   *
   *
   * EXISTING EXAMS:
   * ---------------
   * - Saved: Always true
   * - isNew: Always false
   * - hidden: Always true
   *
   * NEW EXAMS:
   * ----------
   * - Saved: When the user saves the exam
   * - isNew: true
   * - hidden: Never
   *
   * Document Properties:
   * --------------------
   *
   * - Saved: If all files are saved
   * - isNew: Not relevant
   * - Hidden: False if any file.hidden is false
   */

  for (const date of dates) {
    const doc = documents.dates[date];

    if (!doc.hidden) {
      overview["totalExams"] += 1;
      if (doc.saved) {
        overview["savedExams"] += 1;
      }
    }

    for (const file of doc.files) {
      // Ignore hidden files when calculating the file-level overview
      if (file.hidden) {
        overview["existingFiles"] += 1;
        continue;
      } else {
        overview["totalFiles"] += 1;
      }

      if (file.isNew) {
        overview["newFiles"] += 1;
      }

      if (file.type === "unknown") {
        overview["unknownTypeFiles"] += 1;
      } else {
        if (!overview[file.type]) {
          overview[file.type] = 0;
        }

        overview[file.type] += 1;
      }
    }
  }

  overview.existingFiles = overview.totalFiles - overview.newFiles;

  return overview;
};

export default getOverviewStats;
