import React, {useRef, useState} from "react";
import useLocalStorageState from "utilities/hooks/useLocalStorageState";

const useWorkboardWidget = ({
  collapsible = true,
  defaultOpen = true,
  title = "widget", // name for identifying the widget in local storage
  name: widgetName = null, // name for identifying the widget in local storage
}) => {
  const name =
    widgetName || React.useMemo(() => getNameFromTitle(title), [title]);

  const stateHook = collapsible ? useLocalStorageState : React.useState;

  const stateArgs = collapsible
    ? [`workboard-widget-${name.toLocaleLowerCase()}-open`, defaultOpen]
    : [defaultOpen];

  const [open, setOpen] = stateHook(...stateArgs);

  const [badgeContent, setBadgeContent] = useState(0);
  const [hidden, setHidden] = useState(false);

  const handleToggle = () => {
    if (collapsible) {
      setOpen(!open);
    }
  };

  return {
    open,
    handleToggle,
    badgeContent,
    setBadgeContent,
    hidden,
    setHidden,
  };
};

const getNameFromTitle = title => {
  let name;
  try {
    if (typeof title === "string") name = title;

    // If it's a react element, we need to get the text from it's children
    if (typeof title === "object") {
      name = title.props.children;
    }

    name = name.toLowerCase();
    return name;
  } catch (error) {
    console.log("Error in getNameFromTitle:", error);
    return JSON.stringify(title);
  }
};

export default useWorkboardWidget;
