import useGetCourseStudyPlans from "API/queries/StudyPlan/useGetCourseStudyPlans";
import React from "react";
import { Trans } from "Components";
import { useParams } from "react-router-dom";

const useStudyPlanRouteHandler = (routes, setRoutes) => {
  let { courseId } = useParams();

  const [studyPlans, loading] = useGetCourseStudyPlans(courseId);

  React.useEffect(() => {
    if (!loading) {
      const routesClone = [...routes];
      const studyPlanRoute = routesClone.find(
        route => route.path === "study-plans"
      );

      if (!studyPlanRoute) {
        return;
      }

      if (studyPlans?.length > 0) {
        studyPlanRoute.hideInNavigation = false;
        // If there is only one study plan
        if (studyPlans?.length === 1) {
          studyPlanRoute.text = <Trans>Studyplan</Trans>;
        }

        setRoutes(routesClone);
      }
      // We need to re-hide the study-plan route in the navigation when the user switches course
      else if (studyPlanRoute.hideInNavigation === false) {
        studyPlanRoute.hideInNavigation = true;
        setRoutes(routesClone);
      }
    }
  }, [studyPlans, loading]);
};

export default useStudyPlanRouteHandler;
