import React from "react";
import GroupHomeHeader from "./GroupHomeHeader";
import GroupHomeLinkCards from "./GroupHomeLinkCards";
import GroupHomeFeedbackCard from "./GroupHomeFeedbackCard";
import GroupInfo from "Widgets/Group/GroupInfo/GroupInfo";
import GroupHomeQuickActions from "routes/Group/GroupHome/GroupHomeQuickActions/GroupHomeQuickActions";
import GroupHomeLatestQuestions from "Widgets/Group/GroupHomeLatestQuestions/GroupHomeLatestQuestions";
import GroupDiscountsBanner from "Widgets/Group/GroupDiscounts/GroupDiscountsBanner/GroupDiscountsBanner";

const GroupHome = () => {
  return (
    <div className="p-4">
      <div className="grid grid-cols-12  gap-4 md:gap-8">
        <div className="col-span-12 md:col-span-7">
          <GroupHomeHeader />
          <div className="mt-8" />
          <GroupInfo />
          <div className="mt-8" />

          <div className="mt-8" />
          <GroupHomeLatestQuestions />
        </div>
        <div className="col-span-12 md:col-span-5 flex flex-col gap-4 md:gap-4 sticky top-0 h-screen overflow-y-auto">
          <GroupHomeLinkCards />
          <GroupDiscountsBanner
            showActivateButton={true}
            showDiscountPercentage={true}
          />
          <GroupHomeQuickActions />
          <GroupHomeFeedbackCard />
        </div>
      </div>
      {/*<img src="/assets/images/sektionspage-demo.png" className="w-full " />*/}
    </div>
  );
};

export default GroupHome;
