import React from "react";

import Trans from "Components/Trans";
import CourseLanding from "routes/Course/CourseLanding/CourseLanding";
import CourseDashboard from "./CourseDashboard/CourseDashboard";
import CourseExercises from "./CourseExercises/CourseExercises";
import CourseSandbox from "routes/CourseSandbox/CourseSandbox";

const CourseHome = React.lazy(() => import("./CourseHome/CourseHome"));
const CourseExams = React.lazy(() => import("./CourseExams/CourseExams"));
const Forum = React.lazy(() => import("./Forum/Forum"));
const Bookmarks = React.lazy(() => import("./Bookmarks/Bookmarks"));
const StudyPlan = React.lazy(() => import("./StudyPlan/StudyPlan"));
const CourseTag = React.lazy(() => import("./CourseTag/CourseTag"));

const StudyPlans = React.lazy(() => import("./StudyPlans/StudyPlans"));
const ExamMarking = React.lazy(() => import("./ExamWizard/ExamWizard"));
const ExamUpload = React.lazy(() => import("./ExamUpload/ExamUpload"));
const Theory = React.lazy(() => import("./Theory/Theory"));
const CourseExamView = React.lazy(() =>
  import("./CourseExamView/CourseExamView")
);
const ExamWorkboard = React.lazy(() => import("./ExamWorkboard/ExamWorkboard"));
const CourseProgress = React.lazy(() =>
  import("./CourseProgress/CourseProgress")
);
const UserTests = React.lazy(() => import("./UserTests/UserTests"));
const Chapter = React.lazy(() => import("./Chapter/Chapter"));
const CourseKollinGPT = React.lazy(() =>
  import("./CourseKollinGPT/CourseKollinGPT")
);

export const courseRoutes = [
  {
    path: "home",
    label: <Trans>Home</Trans>,
    icon: "house",
    component: <CourseDashboard />,
    mobile: true,
  },
  {
    path: "topics",
    label: <Trans>Topics</Trans>,
    icon: "chart-tree-map",
    component: <CourseHome />,
    mobile: true,
    activePaths: ["tag/:tagId"], // Shows this path as active when a tag is selected
  },
  {
    path: "stats",
    label: <Trans>My progress</Trans>,
    icon: "chart-line",
    component: <CourseProgress />,
    hideInNavigation: true,
    mobile: false,
  },
  {
    path: "theory",
    label: <Trans>Theory</Trans>,
    icon: "function",
    component: <Theory />,
    mobile: true,
  },
  {
    path: "exercises",
    label: <Trans>Exercises</Trans>,
    icon: "chevron-double-right",
    component: <CourseExercises />,
    mobile: true,
    hideInNavigation: true,
    rootPath: "home",
  },
  {
    path: "tag/:tagId",
    label: <Trans>Tag</Trans>,
    icon: "tag",
    component: <CourseTag />,
    mobile: true,
    hideInNavigation: true,
    rootPath: "topics",
  },
  {
    path: "study-plans",
    label: <Trans>Study plans</Trans>,
    icon: "rocket-launch",
    component: <StudyPlans />,
    mobile: true,
    hideInNavigation: true, // hidden by default, but can be unhidden automatically if the course has a study plan
    activePaths: ["study-plan/:studyPlanId"], // Shows this path as active when a study plan is selected
  },
  {
    path: "test-generator",
    label: <Trans>Generate exam</Trans>,
    icon: "bolt",
    component: <UserTests />,
    mobile: true,
  },
  {
    path: "exams",
    label: <Trans>Exam archive</Trans>,
    icon: "box-archive",
    component: <CourseExams />,
    mobile: false,
    activePaths: ["exams/marking", "exam/:examId", "exam-exercises/:examId"],
  },
  {
    path: "exam/:examId",
    label: <Trans>Exam</Trans>,
    icon: "box-archive",
    component: <CourseExamView />,
    mobile: false,
    hideInNavigation: true,
    rootPath: "exams",
  },
  {
    path: "exam-exercises/:examId",
    label: <Trans>Exam exercises</Trans>,
    icon: "box-archive",
    component: <ExamWorkboard />,
    mobile: false,
    hideInNavigation: true,
    rootPath: "exams",
  },
  {
    path: "upload",
    label: <Trans>Upload</Trans>,
    icon: "upload",
    component: <ExamUpload />,
    mobile: false,
    hideInNavigation: true,
    rootPath: "exams",
  },
  {
    path: "exams/marking",
    label: <Trans>Exam marking</Trans>,
    icon: "scissors",
    component: <ExamMarking />,
    mobile: false,
    hideInNavigation: true,
    hideSidebar: true,
    rootPath: "exams",
  },
  {
    path: "forum",
    label: <Trans>Forum</Trans>,
    icon: "comments",
    component: <Forum />,
    mobile: true,
  },
  {
    path: "bookmarks",
    label: <Trans>Bookmarks</Trans>,
    icon: "bookmark",
    component: <Bookmarks />,
    mobile: false,
  },
  {
    path: "study-plan/:studyPlanId",
    label: <Trans>Study plan</Trans>,
    component: <StudyPlan />,
    mobile: false,
    hideInNavigation: true,
    rootPath: "study-plans",
  },
  {
    path: "chapter/:chapterType/:chapterId",
    label: <Trans>Chapter</Trans>,
    component: <Chapter />,
    mobile: true,
    hideInNavigation: true,
    hideSidebar: true,
    rootPath: "topics",
  },
  {
    path: "sandbox",
    label: <Trans>Sandbox</Trans>,
    component: <CourseSandbox />,
    admin: true,
    hideInNavigation: true,
    mobile: false, // specifies which components are meant to render on mobile or not
  },
  {
    path: "kollin-gpt",
    label: <Trans>Kollin-GPT</Trans>,
    component: <CourseKollinGPT />,
    hideInNavigation: true,
    mobile: false,
    hideSidebar: true,
    hideUserMenu: true,
  },
];

// Mobile null was a solution that came to mind for routes that are independent
// of the view port since that is only for CourseLanding/CourseHome
export const courseLandingRoutes = [
  {
    path: "home",
    component: <CourseLanding />,
    mobile: false, // specifies which components are meant to render on mobile or not
  },

  {
    path: "study-plan/:studyPlanId",
    label: <Trans>Study plan</Trans>,
    icon: "fa-star",
    component: <StudyPlan />,
    mobile: null,
    hideInNavigation: false,
  },
];
