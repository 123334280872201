import React from "react";
import {motion} from "framer-motion";
import classnames from "classnames";
import {ErrorBoundary, SafeText} from "Components";

const SubSection = ({
  title,
  subTitle,
  children,
  className,
  disabled,
  id,
  animate,
  orientation = "auto",
}) => {
  const childrenArray = React.Children.toArray(children);
  const childrenCount = childrenArray.length;
  const [leftElement, rightElement] = childrenArray;

  return (
    <ErrorBoundary>
      <div className="w-full relative">
        <motion.div
          className={classnames(
            "items-start py-2 grid",
            orientation === "horizontal" && "grid-cols-12 gap-4",
            orientation === "vertical" && "grid-cols-1 gap-4",
            orientation === "auto" &&
              "grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-16",
            "mb-4 lg:mb-8",
            className
          )}
          id={id}
        >
          <motion.div
            className={classnames(
              orientation === "auto" && "col-span-1 lg:col-span-4",
              orientation === "vertical" && "col-span-1",
              orientation === "horizontal" && "col-span-4"
            )}
          >
            {childrenCount === 2 &&
              React.cloneElement(leftElement, {
                color: "text-gray-700",
                weight: "medium",
              })}

            <SafeText weight="medium" color="text-gray">
              {title}
            </SafeText>
            <SafeText color="text-gray-light" type="tsm">
              {subTitle}
            </SafeText>
          </motion.div>

          <motion.div
            className={classnames(
              orientation === "auto" && "col-span-1  lg:col-span-8",
              orientation === "vertical" && "col-span-1",
              orientation === "horizontal" && "col-span-8",
              disabled && "opacity-75"
            )}
            animate={{
              opacity: disabled ? 0.75 : 1, // Add animation for disabled changes
              height: "auto",
            }}
          >
            {childrenCount === 2 ? rightElement : children}
          </motion.div>
        </motion.div>
      </div>
    </ErrorBoundary>
  );
};

export default SubSection;
