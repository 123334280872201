import React from "react";
import {Button, Trans} from "Components";
import CheckoutSection from "routes/Checkout/CheckoutSection";
import CheckoutAuthenticationUser from "routes/Checkout/CheckoutAuthentication/CheckoutAuthenticationUser";
import SignUp from "routes/Auth/SignUp/SignUp";
import Login from "routes/Auth/Login/Login";

const CheckoutAuthentication = props => {
  const {user} = React.useContext(window.UserContext);

  const [showSignIn, setShowSignIn] = React.useState(false);

  const {openModal} = React.useContext(window.GlobalModalContext);

  if (user) {
    return <CheckoutAuthenticationUser />;
  }

  return (
    <CheckoutSection
      title={<Trans>Your information</Trans>}
      className="mt-0"
      action={
        <CheckoutAuthenticationActions
          showSignIn={showSignIn}
          setShowSignIn={setShowSignIn}
        />
      }
      subTitle={
        <CheckoutAuthenticationPrompt
          showSignIn={showSignIn}
          setShowSignIn={setShowSignIn}
        />
      }
    >
      {showSignIn ? (
        <Login
          wrapper={false}
          onComplete={() => {}} // To prevent a redirect after login
          showTitle={false}
          showSignupLink={false}
          grid={true}
          showForgotPasswordLink={false}
          socialButtonsCompact={true}
        />
      ) : (
        <SignUp
          showLoginLink={false}
          wrapper={false}
          onComplete={() => {}} // To prevent a redirect after signup
          showTitle={false}
          grid={true}
          socialButtonsCompact={true}
        />
      )}
    </CheckoutSection>
  );
};

const CheckoutAuthenticationPrompt = ({showSignIn, setShowSignIn}) => {
  if (showSignIn) {
    return (
      <Trans color="text-primary">Please sign in to complete your order</Trans>
    );
  }

  return (
    <Trans color="text-primary">
      Please create an account to complete your order
    </Trans>
  );
};

const CheckoutAuthenticationActions = ({showSignIn, setShowSignIn}) => {
  if (showSignIn) {
    return (
      <div>
        <Button onClick={() => setShowSignIn(false)} type="link">
          <Trans>Create a new account</Trans>
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Button onClick={() => setShowSignIn(true)} type="link">
        <Trans>Login instead</Trans>
      </Button>
    </div>
  );
};

export default CheckoutAuthentication;
