import React from "react";
import {Icon} from "Components";
import classnames from "classnames";
import useDatePickerCalendarState from "Components/_Elements/DatePicker/DatePickerCalendar/useDatePickerCalendarState";
import DatePickerCalendarHeader from "Components/_Elements/DatePicker/DatePickerCalendar/DatePickerCalendarHeader";

const DatePickerCalendar = ({currentDate, onDateClick, pickerRef}) => {
  const {
    currentPage,
    onPrevMonth,
    onNextMonth,
    daysToRender,
  } = useDatePickerCalendarState(currentDate);

  const cellClassNames =
    "w-12 h-12 p-4 rounded-full flex items-center justify-center";

  return (
    <div className="relative">
      <div
        ref={pickerRef}
        className="p-4 absolute z-50 top-2 left-0 w-96 background border-1 border-gray shadow-lg rounded-xl"
      >
        <DatePickerCalendarHeader
          currentPage={currentPage}
          onPrevMonth={onPrevMonth}
          onNextMonth={onNextMonth}
          cellClassNames={cellClassNames}
        />

        <div className="grid grid-cols-7 grid-rows-6">
          {daysToRender.map(({date, label, isToday}, index) => {
            const isCurrentPage = currentPage.isSame(date, "month");
            const isSelected = currentDate?.isSame(date, "day");

            const handleClick = () => {
              onDateClick(date);
            };

            return (
              <div
                key={index}
                onClick={handleClick}
                className={classnames(
                  cellClassNames,
                  "relative",
                  "hover:surface-primary",
                  "cursor-pointer",
                  isCurrentPage ? "text-gray" : "text-gray-400",
                  isSelected && "bg-primary-600 text-white hover:text-gray"
                )}
              >
                {label}

                {isToday && (
                  <span className="absolute inset-x-0 -bottom-1 flex justify-center  ">
                    <Icon
                      className=""
                      icon="fa-solid fa-circle-small"
                      size="xs"
                      color={isSelected ? "text-white" : "text-primary-600"}
                    />
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DatePickerCalendar;
