import React from "react";
import useExerciseViewSolutionState from "Components/Workboard/ExerciseView/useExerciseViewSolutionState";
import useExerciseViewTextModeState from "Components/Workboard/ExerciseView/useExerciseViewTextModeState";
import useLocalStorageState from "utilities/hooks/useLocalStorageState";
import {isMobile} from "react-device-detect";

const useExerciseViewState = (
  exerciseId,
  initSolutionVisibility = false,
  showForum
) => {
  const {
    solutionVisible,
    toggleSolutionVisible,
    setSolutionVisible,
  } = useExerciseViewSolutionState({
    initSolutionVisibility,
  });

  const [forumVisible, setForumVisible] = React.useState(false);

  const toggleForumVisible = () => setForumVisible(!forumVisible);

  React.useEffect(() => {
    setSolutionVisible(false);
    setForumVisible(false);
  }, [exerciseId]);

  return {
    exerciseId,
    solutionVisible,
    toggleSolutionVisible,
    forumVisible,
    showForum,
    toggleForumVisible,
  };
};

export default useExerciseViewState;
