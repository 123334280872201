import useCreateUser from "API/mutations/user/useCreateUser";
import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import {setAuthenticated} from "utilities/authentication";
import {useFormValidation} from "utilities/hooks/useFormValidation";
import normalizeErrors from "utilities/normalizeErrors";
import {
  emptyValidator,
  validateEmail,
  validateEmailWithExtension,
  validateRegisterWithFirstAndLastName,
  validateRegisterWithoutName,
  validateRegisterWithoutPassword,
} from "utilities/validators";
import useQuickSignup from "API/mutations/quickSignup/useQuickSignup";
import useSignUpStateSetUserProperties from "routes/Auth/SignUp/useSignUpStateSetUserProperties";
import {formatFullName} from "utilities/formatters/formatName";
import {formatEmailWithExtension} from "utilities/formatters/formatEmail";
import UseSignupStatePreferredEmailExtension from "routes/Auth/SignUp/UseSignupStatePreferredEmailExtension";
import useSignupStatePreferredEmailExtension from "routes/Auth/SignUp/UseSignupStatePreferredEmailExtension";
import useSignup from "API/mutations/quickSignup/useSignup";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";

/**
 * This hook is used to handle the state of the sign up form
 * @param propCouponId {string} The coupon id to use for the sign up, automatically redeemed
 * @param onComplete {function} What to do when the user completes the sign up
 * @param onLoginClick {function} What to do when the user clicks the login button
 * @param source {string} The source of the sign up
 * @param requireName {boolean} Require first and last name during registration or not
 * @param quickSignup  {boolean} Use quick sign up or not
 * @param userProperties {object} The user properties to set after sign up
 * @returns {{handleLoginClick: handleLoginClick, submit: function(*): Promise<void>, handleComplete: handleComplete, values: *, onDateChange: function(*): void, setErrors: (value: (((prevState: {}) => {}) | {})) => void, loading: (boolean|boolean), handleListValueChange: function(*, *): void, handleLowercaseInputChange: function(*): void, handleNameChange: handleNameChange, setFormValue: function(*): void, setValues: (value: (((prevState: *) => *) | *)) => void, handleChange: function(*): void, errors: {}}}
 */

const useSignUpState = ({
  couponId: propCouponId,
  onComplete,
  onLoginClick,
  source,
  requireName = true, // Require first and last name during registration or not
  quickSignup = false, // Use quick sign up or not
  userProperties = null,
  emailExtension = null,
  handleAuthResponse,
}) => {
  const groupContext = useGroupContext();

  let properties = userProperties || {};

  if (groupContext) {
    properties = {
      ...properties,
      programId: groupContext.program?.id || null,
      schoolId: groupContext.schoolId || null,
    };
  }

  const location = useLocation();
  const history = useHistory();

  // Get the coupon code from the query string 'coupon' or from the prop
  const couponId =
    propCouponId || new URLSearchParams(location.search).get("coupon");

  const {signup, loading} = useSignup({
    onCompleted: data =>
      handleAuthResponse(data, "signup", quickSignup ? "LOCAL_QUICK" : "LOCAL"),
  });

  /**
   * What to do when the user clicks the login link
   */
  const handleLoginClick = () => {
    if (onLoginClick) {
      onLoginClick();
    } else {
      history.push("/login" + window.location.search, {...location.state}); // Keep the location state
    }
  };

  const transformValues = values => {
    let transformedValues = values;
    if (quickSignup) {
      // Format the firstName into firstName and lastName fields
      transformedValues = {
        ...transformedValues,
        ...formatFullName(transformedValues.firstName),
      };
    }

    if (emailExtension) {
      // If email extension is provided, format the email with the custom extension
      transformedValues = {
        ...transformedValues,
        email: formatEmailWithExtension(
          transformedValues.email,
          emailExtension
        ),
      };
    }

    return transformedValues;
  };

  /**
   * State for the form
   *
   */
  const state = useFormValidation(
    {
      ...properties, // E.g. programId, schoolId & enrollmentYear
      firstName: "",
      lastName: quickSignup ? undefined : "",
      email: "",
      password: "",
      consent: false,
      phoneNumber: "",
      couponId,
      method: quickSignup ? "LOCAL_QUICK" : "LOCAL", // Strategy to use for the sign up
    },
    signup,
    getValidationFunction(quickSignup, requireName, emailExtension),
    "YYYY-MM-DD",
    transformValues
  );

  // const updateUserProperties = useSignUpStateSetUserProperties(userProperties);

  const preferredEmailExtension = useSignupStatePreferredEmailExtension(
    emailExtension,
    state.values
  );

  return {
    ...state,
    loading: loading || state.loading,
    handleLoginClick,
    preferredEmailExtension,
  };
};

const getValidationFunction = (quickSignup, requireName, emailExtension) => {
  if (emailExtension) {
    return emptyValidator;
  }

  if (quickSignup) {
    return validateEmail;
  }
  if (requireName) {
    return validateRegisterWithFirstAndLastName;
  }

  return validateRegisterWithoutName;
};
export default useSignUpState;
