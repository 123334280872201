import React from "react";

const CardFooter = ({action}) => {
  return (
    <div className="p-4 border-t-1 border-gray flex justify-end items-end flex-wrap">
      {action}
    </div>
  );
};

export default CardFooter;
