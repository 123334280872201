import React from "react";
import moment from "moment";
import {Typography} from "Components";

const CourseChatGptResultsMessageTimestamp = ({timestamp}) => {
  const momentTimestamp = moment(timestamp);
  let timeDisplay = momentTimestamp.format("llll");

  // IF it's today, show the time only:
  if (momentTimestamp.isSame(moment(), "day")) {
    timeDisplay = momentTimestamp.format("HH:mm");
  }

  return (
    <Typography type="tsm" color="text-gray-light">
      {timeDisplay}
    </Typography>
  );
};

export default CourseChatGptResultsMessageTimestamp;
