export const rowSpacingMap = {
  xs: "py-1",
  sm: "py-2",
  md: "py-4",
  lg: "py-6",
  xl: "py-8",
};

export const columnSpacingMap = {
  xs: "px-1",
  sm: "px-1",
  md: "px-2",
  lg: "px-4",
  xl: "px-8",
};
