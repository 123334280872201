import React from "react";
import {HighlightedText, Typography} from "Components";
import CDN from "utilities/constants/CDN";
import PropTypes from "prop-types";
import classnames from "classnames";
import CourseCardMenu from "Components/CourseCard/CourseCardMenu";
import CoursePriceTag from "Components/CoursePriceTag/CoursePriceTag";
import CourseCardBottomSection from "Components/CourseCard/CourseCardBottomSection";
import {useHistory} from "react-router-dom";
import {
  iconSizeMap,
  titleSizeMap,
} from "Components/CourseCard/CourseCard.constants";

const CourseCard = ({
  id,
  name,
  code,
  icon,
  schoolName,
  purchased,
  active,
  archived,
  onClick,
  size = "md",
  highlightText = null,
  showPurchaseInfo = true,
  showActiveBadge = true,
  showIcon = true,
  className,
}) => {
  const history = useHistory();

  const iconSrc = React.useMemo(() => getIcon(icon), [icon]);

  const courseName = React.useMemo(() => getCleanName(name), [name]);

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick(id);
    } else {
      history.push(`/course/${id}`);
    }
  });

  return (
    <div
      onClick={active ? null : handleClick}
      className={classnames(
        "background ",
        onClick && "hover:surface",
        "rounded-xl",
        "w-full @container",
        "flex",
        "p-2 md:p-4",
        "border-2 border-color",
        !onClick && "hover:border-gray-300",
        "mb-4",
        className,
        onClick ? "cursor-default" : "cursor-pointer",
        "transition-colors duration-200 ease-in-out"
      )}
    >
      {showIcon && (
        <div
          onClick={handleClick}
          className={classnames(
            "basis-1/6 shrink-0 flex justify-center items-center"
          )}
        >
          <img src={iconSrc} alt="Course icon" className={iconSizeMap[size]} />
        </div>
      )}

      <div className="px-2 md:px-4 grow">
        <div className="flex justify-between items-center">
          <div onClick={handleClick}>
            <HighlightedText type="marker" highlight={highlightText}>
              <Typography
                type={titleSizeMap[size]}
                className="cursor-pointer hover:underline mr-2"
                weight="md"
              >
                {courseName}
              </Typography>
            </HighlightedText>
          </div>
          {showPurchaseInfo && (
            <div>
              {active ? (
                <CourseCardMenu
                  {...{
                    courseId: id,
                    active,
                    archived,
                    purchased,
                  }}
                />
              ) : (
                <CoursePriceTag courseId={id} />
              )}
            </div>
          )}
        </div>

        <HighlightedText type="marker" highlight={highlightText}>
          <Typography className="text-body5 md:text-body3">{code}</Typography>
        </HighlightedText>
        {/*Hide school name on mobile version*/}
        <Typography className="text-body6 md:text-body3">
          {schoolName}
        </Typography>

        <div className="mt-2 md:mt-4 flex justify-between items-center">
          <CourseCardBottomSection
            courseId={id}
            purchased={purchased}
            active={active}
            archived={archived}
            showActiveBadge={showActiveBadge}
          />
        </div>
      </div>
    </div>
  );
};

const getIcon = icon => {
  if (!icon) return `${CDN}/course-icons/10.svg`;

  if (icon?.includes("http")) {
    return icon;
  }
  return `${CDN}/course-icons/${icon}.svg`;
};

const getCleanName = name => {
  try {
    const split = name.split(" - ");
    if (split.length == 1) {
      return split[0];
    }
    const cleanName = split[1].split(",")[0].split("(")[0];
    return cleanName;
  } catch (e) {
    return name;
  }
};

CourseCard.propTypes = {
  /** The id of the course */
  id: PropTypes.number.isRequired,
  /**
   * Name of the course - including the course code (as in the database)
   */
  name: PropTypes.string.isRequired,
  /**
   * The course code
   */
  code: PropTypes.string.isRequired,
  /**
   * The icon number or icon link
   */
  icon: PropTypes.string.isRequired,
  /**
   * The name of the school
   */
  schoolName: PropTypes.string.isRequired,
  /**
   * Whether the course has been purchased
   */
  purchased: PropTypes.bool.isRequired,
  /**
   * Whether the course is active (been activated by the user)
   */
  active: PropTypes.bool,
  /**
   * Whether the course is archived
   */
  archived: PropTypes.bool,
};

export default CourseCard;
