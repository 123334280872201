import React from "react";
import useGetCourseReviews from "API/queries/reivew/useGetCourseReviews";
import "./CourseReviewsSummary.scss";
import { integerRemoveDecimal } from "./helpers/CourseReviewsSummaryHelpers";
import {
  textSingularPluralTranslated,
} from "utilities/textSingularPlural";
import CoursePriceTag from "Components/CoursePriceTag/CoursePriceTag";

const CourseReviewsSummary = ({ handleRatingClick, school }) => {
  const { courseId } = React.useContext(window.CourseContext);

  const { averageRating, nReviews } = useGetCourseReviews(courseId, false);

  return (
    <>
      <h2>{school}</h2>
      <div className="course-reviews-summary">
        <CoursePriceTag courseId={courseId} />
        {nReviews !== 0 && (
          <>
            <p className="course-reviews-summary-ratings">
              <i className="fas fa-star"></i>
              {integerRemoveDecimal(averageRating)}
            </p>

            <p onClick={handleRatingClick}>
              {textSingularPluralTranslated(nReviews, "review")}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default CourseReviewsSummary;
