import React from "react";
import useDatePickerInputState from "Components/_Elements/DatePicker/DatePickerInput/useDatePickerInputState";
import {Input} from "Components/_Elements/index";

const DatePickerInput = ({
  currentDate,
  setShowPicker,
  format,
  fluid,
  onDateSelected,
}) => {
  const {inputValue, handleDateInputChange} = useDatePickerInputState({
    currentDate,
    format,
    onDateSelected,
  });

  return (
    <Input
      fluid={fluid}
      value={inputValue}
      onFocus={() => setShowPicker(true)}
      icon="calendar"
      onChange={handleDateInputChange}
    />
  );
};

export default DatePickerInput;
