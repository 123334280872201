import React from "react";
import { Carousel, Icon, Skeleton, Typography, StarRating } from "Components";
import { kebabCaseSplitter } from "utilities/text";
import {
  Summer2022CampaignProgramContext,
  Summer2022CampaignSectionTitle,
} from "routes/Campaign/Summer2022Campaign/Summer2022Campaign";
import useGetCourse from "API/queries/course/useGetCourse";
import CDN from "utilities/constants/CDN";
import { useHistory } from "react-router-dom";

const Summer2022CampaignCourses = ({ courseIds }) => {
  return (
    <div className="w-full h-72 text-center">
      <Summer2022CampaignSectionTitle>
        Vi har dina tuffaste kurser
      </Summer2022CampaignSectionTitle>
      <Carousel showDots key="courses-carousel" height="52">
        {courseIds.map(courseId => (
          <Summer2022CampaignCoursesCard key={courseId} courseId={courseId} />
        ))}
      </Carousel>
    </div>
  );
};

const Summer2022CampaignCoursesCard = ({ courseId }) => {
  const history = useHistory();

  const [course, loading] = useGetCourse(courseId);
  const [courseCode, courseName] = course?.name
    ? kebabCaseSplitter(course?.name)
    : ["", ""];

  let campaign = React.useContext(Summer2022CampaignProgramContext);

  // On course click we send the user to the course page /course/:id/home
  // Include the campaign information in the state so the CTA:s in the course can push the user to the campaign signup
  // Also includes the campaign data so some sections can be shown on CLP
  // Initiate onBoarding is not overriden because we can't clone states or hooks across locations
  const onCourseClick = () => {
    campaign.initiateOnBoarding = null;

    // Only do this for non-logged in users
    history.push(`/course/${course.id}/home`, {
      campaign,
      nextUrl: window.location.pathname + "?onBoarding=1",
    });
  };

  // Remove all text after "," and "(" from course name
  const courseNameWithoutExtras = courseName.split(",")[0].split("(")[0];

  return (
    <div className="flex justify-between h-32 border-2 border-gray-100">
      <div className="flex gap-4 items-center grow  surface  py-4 px-2  text-left shadow-sm overflow-hidden rounded-l-xl ">
        <img
          src={`${CDN}/course-icons/${course?.icon}.svg`}
          alt={courseName}
          className="w-20"
        />

        <div>
          <Typography
            type="tlg"
            color="text-primary-800"
            weight="sb"
            className="truncate"
          >
            {courseNameWithoutExtras}
          </Typography>

          <Typography type="tmd" color="text-gray-500" weight="sb">
            {courseCode}
          </Typography>
          {/*<Typography type="tmd" color="text-gray-500" className="truncate">*/}
          {/*  {course?.school?.name || <Skeleton />}*/}
          {/*</Typography>*/}

          {course?.averageRating ? (
            <div className="flex items-center gap-2">
              <Typography
                type="txl"
                weight="sb"
                color="text-warning-600"
                className="mt-1"
              >
                {course.averageRating.toFixed(1)}
              </Typography>
              <StarRating rating={course.averageRating} />
            </div>
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div
        className="bg-primary-50 h-full w-10 flex justify-center items-center px-4 rounded-r-xl cursor-pointer"
        onClick={onCourseClick}
      >
        <Icon icon="arrow-right" size="lg" color="text-primary-400" />
      </div>
    </div>
  );
};

export default Summer2022CampaignCourses;
