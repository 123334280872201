import React from "react";
import {Button, Trans} from "Components/index";
import useGPTActionsSaveChapter from "Components/GPT/GPTActionsSave/GPTActionsSaveChapter/useGPTActionsSaveChapter";

const GPTActionsSaveChapter = ({
  className,
  buttonType = "gray",
  buttonSize = "sm",
  label = null,
  existingChapterId = null,
}) => {
  const {messages, loading: parentLoading} = React.useContext(GPTContext);
  const {courseId} = React.useContext(CourseContext);

  const {onSave, loading, newChapterId} = useGPTActionsSaveChapter({
    messages,
    courseId,
    existingChapterId,
  });

  return (
    <div className="flex items-center">
      <Button
        className={className}
        type={buttonType}
        size={buttonSize}
        onClick={onSave}
        loading={loading}
        disabled={parentLoading}
        iconLeft="fa-solid fa-floppy-disk"
        icon={label ? null : "fa-solid fa-floppy-disk"}
        hint={<Trans>Save conversation</Trans>}
      ></Button>
    </div>
  );
};

export default GPTActionsSaveChapter;
