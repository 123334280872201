import React from "react";
import {Icon, Link, Typography} from "Components";
import useGetGroupInfo from "API/queries/groups/useGetGroupInfo";
import {useGroupContext} from "../../../Providers/GroupProvider/GroupProvider";

const GroupInfoLinks = () => {
  const {groupId} = useGroupContext();
  const {info, loading} = useGetGroupInfo({groupId});

  if (loading || !info?.links) return null;

  return (
    <div className="flex gap-2 mt-2 w-full flex-wrap">
      {info.links.map((link, index) => (
        <Link href={link.url} target="_blank">
          <div className="flex items-center py-1 px-1 gap-2 rounded-md bg-slate-100 ">
            <Icon icon={link.icon} size="sm" />
            <Typography type="txs" weight="sb">
              {link.title}
            </Typography>
            <Icon icon="arrow-up-right-from-square" size="xs" />
          </div>
        </Link>
      ))}
    </div>
  );
};

export default GroupInfoLinks;
