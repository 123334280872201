import React from "react";

const useResizeHandler = ({
  setCoordinates,
  callback,
  coordinates,
  coordinatesRef,
}) => {
  const direction = React.useRef(null);

  const onMouseMove = e => {
    e.preventDefault();
    e.stopPropagation();

    // TODO: Check if the coordinates are out of bounds and scroll the window if needed

    setCoordinates(prev => {
      const newCoordinates = { ...prev };

      const position = direction.current;

      switch (position) {
        case "top":
          newCoordinates.y1 += e.movementY;
          break;
        case "bottom":
          newCoordinates.y2 += e.movementY;
          break;
        case "left":
          newCoordinates.x1 += e.movementX;
          break;
        case "right":
          newCoordinates.x2 += e.movementX;
          break;
        case "top-left":
          newCoordinates.y1 += e.movementY;
          newCoordinates.x1 += e.movementX;
          break;
        case "bottom-left":
          newCoordinates.y2 += e.movementY;
          newCoordinates.x1 += e.movementX;
          break;
        case "top-right":
          newCoordinates.y1 += e.movementY;
          newCoordinates.x2 += e.movementX;
          break;
        case "bottom-right":
          newCoordinates.y2 += e.movementY;
          newCoordinates.x2 += e.movementX;
          break;
      }
      coordinatesRef.current = newCoordinates;
      return newCoordinates;
    });
  };

  const onMouseUp = e => {
    e.preventDefault();
    e.stopPropagation();
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
    direction.current = null;
    callback(coordinatesRef.current);
  };

  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    direction.current = e.target.getAttribute("data-position");
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const handleRef = React.useCallback(node => {
    if (node !== null) {
      node.addEventListener("mousedown", onMouseDown);
    }
  }, []);

  return handleRef;
};

export default useResizeHandler;
