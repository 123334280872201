import React from "react";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  alertPrimaryTextColor,
  sizeClasses,
  typeColors,
} from "Components/_Elements/Alert/Alert.classes";
import AlertIcon from "Components/_Elements/Alert/AlertIcon";
import AlertText from "Components/_Elements/Alert/AlertText";
import {Icon, Trans, Typography} from "Components";

/**
 * Alert: used to display a message to the user
 * - "type" prop is "color" in the design system
 * - Currently only supports the "text" type from the design system
 * @returns {JSX.Element}
 * @constructor
 */
const Alert = props => {
  const {
    type = "gray",
    orientation = "horizontal",
    subTitle,
    primaryAction,
    secondaryAction,
    onClose,
    closable,
    className,
    size = "lg",
    icon,
    children,
  } = props;

  const [closed, setClosed] = React.useState(false);

  const handleClose = () => {
    setClosed(true);
    onClose && onClose();
  };

  const smallScreens = useBreakPointSM();

  const orientationOverride = smallScreens("vertical", orientation);
  const vertical = orientationOverride === "vertical";

  if (closed) return null;

  return (
    <div
      className={classnames(
        "border-2 rounded-lg w-full",
        typeColors[type],
        sizeClasses[size],
        className
      )}
    >
      <div
        className={classnames(
          "flex flex-row justify-between",
          subTitle == null && primaryAction == null && secondaryAction == null
            ? "items-center"
            : "items-start"
        )}
      >
        <div className="mr-3">
          <AlertIcon type={type} icon={icon} size={size} />
        </div>
        {!vertical && (
          <div className="grow">
            <AlertText {...props} />
          </div>
        )}

        {(closable || onClose) && (
          <Icon
            type="icon"
            icon="xmark"
            onClick={handleClose}
            color={alertPrimaryTextColor[type]}
          />
        )}
      </div>

      {vertical && (
        <div className="mt-3">
          <AlertText {...props} />
        </div>
      )}
      {children && <div className="mt-4">{React.cloneElement(children)}</div>}
    </div>
  );
};

export const alertTypes = Object.keys(typeColors);
export const orientations = ["horizontal", "vertical"];

Alert.propTypes = {
  // The type of the alert
  type: PropTypes.oneOf(alertTypes),
  // The orientation of the alert - (always vertical  n small screens)
  orientation: PropTypes.oneOf(orientations),
  // The title of the alert
  title: PropTypes.node.isRequired,
  // The subtitle of the alert
  subTitle: PropTypes.node,
  // The size of the alert
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),

  // The primary action of the alert
  primaryAction: PropTypes.shape({
    // The text node of the action
    label: PropTypes.element.isRequired,
    // The function to call when the action is clicked
    onClick: PropTypes.func.isRequired,
  }),

  // The secondary action of the alert
  secondaryAction: PropTypes.shape({
    // The text of the action
    label: PropTypes.element.isRequired,
    // The function to call when the action is clicked
    onClick: PropTypes.func.isRequired,
  }),

  onClose: PropTypes.func,
  closable: PropTypes.bool,
};

export default Alert;
