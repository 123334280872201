import React from "react";
import {Section, Button, Trans, Typography, Alert} from "Components";
import useGetGroupInfo from "API/queries/groups/useGetGroupInfo";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import GroupSettingsInfo from "routes/Group/GroupSettings/GroupSettingsInfo/GroupSettingsInfo";

const GroupSettings = () => {
  const {
    groupId,
    currentUserMembership,
    currentUserIsAdmin,
  } = useGroupContext();

  const {info: groupInfo, loading, refetch} = useGetGroupInfo({
    groupId: groupId,
  });

  if (!currentUserIsAdmin)
    return (
      <Alert
        type="warning"
        title={<Trans>This page is only available to group admins.</Trans>}
      />
    );

  return (
    <div className=" p-4 md:p-8">
      <Section
        title={<Trans>Settings</Trans>}
        subTitle={
          <Trans>
            Manage your group settings here. You can change the group
            introductions and set members as group admins.
          </Trans>
        }
      >
        {groupInfo && (
          <GroupSettingsInfo refetch={refetch} groupInfo={groupInfo} />
        )}
      </Section>
    </div>
  );
};

export default GroupSettings;
