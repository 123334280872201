import {ExerciseEvaluation, ExerciseNotes} from "Widgets";
import React from "react";
import ExerciseViewTips from "../ExerciseView/ExerciseViewTips/ExerciseViewTips";
import ExerciseDiscussion from "../ExerciseDiscussion/ExerciseDiscussion";
import useGetExercise from "../../../API/queries/exercise/useGetExercise";
import Details from "../WorkboardWidgets/Details/Details";
import WorkboardWidget from "../WorkboardWidgets/WorkboardWidget";
import ExercisePlaylists from "../ExercisePlaylists/ExercisePlaylists";

import ExerciseViewTags from "../ExerciseView/ExerciseViewTags/ExerciseViewTags";
import {Button, Trans} from "Components";
import classnames from "classnames";
import GeogebraMenu from "Components/Geogebra/GeogebraMenu/GeogebraMenu";
import {Popover} from "../../index";
import Icon from "../../_Elements/Icon/Icon";
import WidgetFeedbackForm from "../../Widget/WidgetFeedback/WidgetFeedbackForm";
import ReactDOM from "react-dom";
import useLocalStorageState from "../../../utilities/hooks/useLocalStorageState";

const ActionPane = ({exerciseId, exerciseEvaluationRefetchQueries}) => {
  const {previewSidebarIsOpen} = React.useContext(window.PreviewSidebarContext);
  const minimized = previewSidebarIsOpen;
  const [collapsed, setCollapsed] = useLocalStorageState(
    "WORKBOARD_ACTION_PANE_COLLAPSED",
    false
  );

  // const [collapsed, setCollapsed] = React.useState(false);
  const {user} = React.useContext(window.UserContext);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const handleClick = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleClose = () => {
    setTooltipOpen(false);
  };
  const portalContainer = document.getElementById("course-main-panel");
  const triggerContainer = document.getElementById(
    "action-pane-trigger-container"
  );

  const [exercise, exerciseLoading] = useGetExercise(exerciseId, true);

  if (collapsed && !triggerContainer) {
    return null;
  }

  if (collapsed) {
    return ReactDOM.createPortal(
      <Button iconRight="sidebar-flip" onClick={() => setCollapsed(false)}>
        <Trans>Menu</Trans>
      </Button>,
      triggerContainer
    );
  }

  if (!portalContainer) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={classnames(
        "background md:border-l-1 md:border-gray-300 top-0 md:sticky md:h-screen overflow-y-auto pt-4 md:pt-0  -my-4 -mr-4 pb-4",
        minimized && "hidden"
      )}
    >
      <div className="border-b border-gray-200 pr-4 pt-6 pb-2 pl-6 hidden md:block w-full">
        <div className="flex w-full justify-between">
          <div className="flex gap-2 items-center">
            <Trans
              type="dxs"
              weight="md"
              className="leading-none"
              inline
              color="text-gray-light"
            >
              Menu
            </Trans>
            <div className="mb-1">
              <Popover
                onOpenChange={setTooltipOpen}
                content={
                  <WidgetFeedbackForm
                    title={
                      <Trans type="tmd" weight="sb">
                        Here we will add new functionalities continuously
                      </Trans>
                    }
                    setToolTipOpen={setTooltipOpen}
                    widgetName="workboard-action-pane"
                    likeType="neutral"
                    handleClose={handleClose}
                    likeable={false}
                  />
                }
                isOpen={tooltipOpen}
                placement="bottom"
                variant="light"
              >
                <Icon
                  icon="question-circle"
                  size="lg"
                  className="hover:text-gray-900 cursor-pointer"
                  onClick={handleClick}
                />
              </Popover>
            </div>
          </div>
          <Button
            icon="close"
            size="lg"
            type="link"
            color="text-gray-light"
            className="cursor-pointer hover:text-primary-900"
            onClick={() => {
              setCollapsed(true);
            }}
            hint={<Trans>Minimize</Trans>}
          />
        </div>
        {/*<div className="flex gap-2 items-center">*/}
        {/*  <Trans type="tsm" color="text-gray-light">*/}
        {/*    Settings*/}
        {/*  </Trans>*/}
        {/*  <Badge type="primary" size="xs">*/}
        {/*    <Trans type="txs">Coming soon</Trans>*/}
        {/*  </Badge>*/}
        {/*</div>*/}
      </div>
      <WorkboardWidget
        title={<Trans>Details</Trans>}
        icon={"info-circle"}
        defaultOpen={true}
        collapsible={false}
      >
        <Details exercise={exercise} />
      </WorkboardWidget>
      <WorkboardWidget title={<Trans>Topics</Trans>} icon="tag">
        <ExerciseViewTags exerciseId={exerciseId} />
      </WorkboardWidget>
      <WorkboardWidget title={<Trans>Tips</Trans>} icon="lightbulb">
        <ExerciseViewTips exerciseId={exerciseId} collapsible={false} />
      </WorkboardWidget>
      <ExerciseEvaluation
        exerciseId={exerciseId}
        source="Workboard"
        exerciseEvaluationRefetchQueries={exerciseEvaluationRefetchQueries}
      />

      <WorkboardWidget
        title={<Trans>Visualize functions</Trans>}
        icon="bezier-curve"
        collapsible={true}
        defaultOpen={false}
        isNew={true}
      >
        <GeogebraMenu />
      </WorkboardWidget>
      <WorkboardWidget title={<Trans>Notes</Trans>} icon="notes">
        <ExerciseNotes exerciseId={exerciseId} collapsible={false} />
      </WorkboardWidget>
      <WorkboardWidget title={<Trans>Lists</Trans>} icon="list">
        <ExercisePlaylists exerciseId={exerciseId} />
      </WorkboardWidget>
      <WorkboardWidget title={<Trans>Discussions</Trans>} icon="comment">
        <ExerciseDiscussion
          exerciseId={exerciseId}
          hideWhenEmpty={true}
          // loading={exerciseLoading}
        />
      </WorkboardWidget>
    </div>,
    portalContainer
  );
};

export default ActionPane;
