import React, {useState, useEffect} from "react";
import classnames from "classnames";
import {motion} from "framer-motion";
import {range} from "utilities/numbers";
import {Icon} from "Components/index";

const StarRating = ({rating = 0, clickable = false, onChange}) => {
  const [ratingState, setRatingState] = useState(null);
  useEffect(() => {
    setRatingState(rating);
  }, []);

  let filledStars = Math.floor(ratingState);
  let emptyStars = 5 - Math.ceil(ratingState);
  let partialFilledStar = ratingState - Math.floor(ratingState);

  const clickHandler = index => {
    setRatingState(index + 1);
    onChange(index + 1);
  };

  return (
    <div className="flex gap-1">
      {range(5).map((star, index) => {
        if (filledStars - (index + 1) >= 0) {
          return (
            <motion.div
              whileHover={clickable ? {scale: 1.25} : {}}
              className={classnames("w-4", clickable ? "cursor-pointer" : "")}
              onClick={clickable ? () => clickHandler(index) : undefined}
              key={index}
            >
              <Icon icon="fas fa-star" size="sm" color="text-warning-600" />
            </motion.div>
          );
        }
        if (filledStars + emptyStars !== 5 && index === filledStars) {
          return (
            <motion.div
              whileHover={clickable ? {scale: 1.25} : {}}
              className={classnames(
                "w-4 relative",
                clickable ? "cursor-pointer" : ""
              )}
              onClick={clickable ? () => clickHandler(index) : undefined}
              key={index}
            >
              <div
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-[2]"
                style={{
                  clipPath: `inset(0 0 0 ${Math.floor(
                    partialFilledStar * 10
                  )}0%)`,
                }}
              >
                <Icon icon="star" color="text-gray-300" size="sm" />
              </div>
              <div
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={clickable ? () => clickHandler(index) : undefined}
                key={index}
              >
                <Icon icon="fas fa-star" size="sm" color="text-warning-600" />
              </div>
            </motion.div>
          );
        }
        if (index + 1 > filledStars) {
          return (
            <motion.div
              whileHover={clickable ? {scale: 1.25} : {}}
              className={classnames("w-4", clickable ? "cursor-pointer" : "")}
              onClick={clickable ? clickHandler.bind(null, index) : undefined}
              key={index}
            >
              <Icon icon="star" size="sm" color="text-gray-300" />
            </motion.div>
          );
        }
      })}
    </div>
  );
};

export default StarRating;
