import { LAMBDA_BASE_URL } from "API/lambdas/index";

const Q_LIST = ["T_Q", "questions", "uppgifter", "tenta", "frågor"];

const A_LIST = ["T_A", "answers", "svar", "lösning", "losning"];

const M_LIST = ["T_M", "mix", "blandat", "blandning"];

const documentGetTypeLocal = docUrl => {
  // Crude solution until we have the lambda ready:
  const docUrlLower = docUrl.toLowerCase();

  // console.log("docUrlLower", docUrlLower);

  let isQuestion = false;
  let isAnswer = false;
  let isMixed = false;

  for (const p of Q_LIST) {
    // Check if RegExp matchesx
    if (new RegExp(p, "i").test(docUrlLower)) {
      isQuestion = true;
      break;
    }
  }

  for (const p of A_LIST) {
    // Check if RegExp matches
    if (new RegExp(p, "i").test(docUrlLower)) {
      isAnswer = true;
      break;
    }
  }

  for (const p of M_LIST) {
    // Check if RegExp matches
    if (new RegExp(p, "i").test(docUrlLower)) {
      isMixed = true;
      break;
    }
  }

  if ((isQuestion && isAnswer) || isMixed) {
    return "mixed";
  }

  if (isQuestion) {
    return "exam";
  }

  if (isAnswer) {
    return "exam_solutions";
  }

  return null;
};

const documentGetType = async (url1, url2 = null) => {
  // Try local solution first
  const localResult1 = documentGetTypeLocal(url1);

  // If local solution found a match, return it
  // However, if it is mixed, we can't be sure
  if (localResult1 != null && localResult1 !== "mixed" && url2 == null) {
    return [localResult1, null];
  }

  const response = await fetch(
    `${LAMBDA_BASE_URL}/document-classifier-prod-main?url1=${url1}${
      url2 ? "&url2=" + url2 : "" // If url2 is null, don't add it
    }`
  );

  const fullData = await response.json();
  const { course_code_match, ...data } = fullData;

  let output = {
    url1: null,
    url2: null,
  };

  Object.keys(data).forEach(key => {
    if (data[key].exercises === 1) {
      output[key] = "exam";
    }

    if (data[key].solutions === 1) {
      output[key] = "exam_solutions";
    }

    if (data[key].exercises === 1 && data[key].solutions === 1) {
      output[key] = "mixed";
    }
  });

  return [output.url1, output.url2];
};

export default documentGetType;
