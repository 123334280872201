import React from "react";
import useGetCurrentLocale from "utilities/hooks/useGetCurrentLocale";
import {loadStripe} from "@stripe/stripe-js";
import {stripeKey} from "utilities/stripe";
import useCreateSetupIntent from "API/mutations/stripeIntent/useCreateSetupIntent";
import {Alert, Loading, SafeText, Trans} from "Components";
import {Elements} from "@stripe/react-stripe-js";
import CreateStripeIntentForm from "./CreateStripeIntentForm/CreateStripeIntentForm";

/**
 * General widget for creating a Stripe Intent, such as a SetupIntent or a PaymentIntent
 * currently only supports SetupIntent
 *
 * It is recommended to send visible = false rather than conditionally rendering this component
 * this makes sure that the intent is created faster
 * @returns {JSX.Element}
 * @constructor
 */
const CreateStripeIntent = ({
  visible = true,
  loading = false,
  title = <Trans>Register payment method</Trans>,
  submitPrompt = <Trans>Submit</Trans>,
  onComplete = () => {},
}) => {
  const locale = useGetCurrentLocale();

  const {
    createSetupIntent,
    loading: createSetupIntentLoading,
    result,
  } = useCreateSetupIntent();

  React.useEffect(() => {
    // Create a SetupIntent as soon as the page loads
    createSetupIntent(result);
  }, []);

  if (!visible) return null;

  if (createSetupIntentLoading || loading) return <Loading />;

  if ((result.error && result.error?.code) || !result.clientSecret)
    return (
      <Alert
        title={<Trans>Oops! an unexpected error has occurred</Trans>}
        subtitle={
          <Trans>
            Please try again by refreshing the page or contact support if the
            problem persists
          </Trans>
        }
        primaryAction={{
          onClick: () => window.location.reload(),
          label: <Trans>Reload the page</Trans>,
        }}
        type="error"
      />
    );

  const stripePromise = loadStripe(stripeKey, {
    locale,
  });

  if (!stripePromise) return <Loading />;

  return (
    <div>
      <SafeText type="dsm" weight="md">
        {title}
      </SafeText>

      <div className="mt-8">
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: result.clientSecret,
          }}
        >
          <CreateStripeIntentForm
            loading={loading}
            onComplete={onComplete}
            submitPrompt={submitPrompt}
          />
        </Elements>
      </div>
    </div>
  );
};

export default CreateStripeIntent;
