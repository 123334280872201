export const sizes = {
  sm: "modal-sm",
  md: "modal-md",
  lg: "modal-lg",
  xl: "modal-xl",
  "2xl": "modal-2xl",
};

export const animationVariants = {
  backgroundFadeIn: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 0.7,
    },
    transition: {
      duration: 0.1,
    },
  },

  modalContentClosed: isSmallScreen => {
    return {
      // opacity: isSmallScreen ? 1 : 0,
      opacity: 0,
      x: "-50%",
      y: isSmallScreen ? "100%" : "-100%",
    };
  },

  modalContentOpen: isSmallScreen => {
    return {
      opacity: 1,
      x: "-50%",
      y: isSmallScreen ? "0%" : "-50%",
      transition: {
        type: "tween",
        x: {
          duration: 0,
        },
      },
    };
  },

  modalContentExit: isSmallScreen => {
    return {
      x: "-50%",
      y: isSmallScreen ? "100%" : "-100%",

      transition: {
        type: "tween",
        // Don't animate the x axis
        x: {
          duration: 0,
        },
      },
    };
  },
};
