import DifficultyBead from "Components/DifficultyBead/DifficultyBead";
import DifficultyChip from "Components/DifficultyChip/DifficultyChip";
import TagChip from "Components/TagChip/TagChip";
import React from "react";
import ProgressBead from "Components/ProgressBead";
import {removeEscapedText} from "utilities/text";

export const dateNum = (date, number) => date.replace(" ", "") + " #" + number;

export const comparator = (a, b) => {
  // Handle null values so they always come at the end:
  if (a == null) return 1;
  if (b == null) return 1;
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

const exerciseAttributes = {
  progressBead: {
    displayName: "Difficulty",
    renderAttribute: ex => (
      <ProgressBead height={36} exercises={[ex]} loading={!ex} />
    ),
    sortKey: "score",
    sortFunction: (a, b) => comparator(a.score, b.score),
  },
  number: {
    displayName: "Exercise",
    renderAttribute: ex => ex.number,
    sortKey: "number",
    sortFunction: (a, b) => {
      let numberA = a.number.replace(/[^0-9]/, ""); // remove all that is not numbers
      let numberB = b.number.replace(/[^0-9]/, ""); // remove all that is not numbers
      return parseInt(numberA) - parseInt(numberB);
    },
  },
  date: {
    displayName: "Date",
    sortKey: "date",
    renderAttribute: ex => ex.date,
    sortFunction: (a, b) => comparator(a.date, b.date),
  },
  dateNum: {
    displayName: "Date",
    sortKey: "date",
    renderAttribute: ex => ex.dateNum,
    sortFunction: (a, b) => comparator(a.dateNum, b.dateNum),
  },
  score: {
    displayName: "Level",
    sortKey: "score",
    renderAttribute: ex => <DifficultyChip score={ex.score}></DifficultyChip>,
    sortFunction: (a, b) => comparator(a.score, b.score),
  },
  selfEvaluation: {
    displayName: "Yor level",
    sortKey: "selfEvaluation",
    renderAttribute: ex => (
      <DifficultyBead active small score={ex.selfEvaluation}></DifficultyBead>
    ),
    sortFunction: (a, b) => comparator(a.selfEvaluation, b.selfEvaluation),
  },
  author: {
    displayName: "Author",
    sortKey: "author",
    renderAttribute: ex => (ex.author ? ex.author : "-"),
    sortFunction: (a, b) => {
      // Need to handle null first because otherwise replace function will cause error
      if (a.author === b.author) return 0;
      else if (a.author == null) return 1;
      else if (b.author == null) return -1;
      return comparator(a.author.replace("s", ""), b.author.replace("s", ""));
    },
  },
  maxTagCertainty: {
    displayName: "certainty",
    renderAttribute: ex => (ex.maxTagCertainty ? ex.maxTagCertainty : "-"),
    sortFunction: (a, b) => comparator(a.maxTagCertainty, b.maxTagCertainty),
  },

  tag: {
    sortable: a => a.tagNames[0],
    displayName: "Topic",
    renderAttribute: ex =>
      ex.tagNames[0] ? (
        <TagChip>{removeEscapedText(ex.tagNames[0])}</TagChip>
      ) : (
        "-"
      ),
    sortKey: "tag",
    sortFunction: (a, b) => comparator(a.tagNames[0], b.tagNames[0]),
  },
};

export default exerciseAttributes;
