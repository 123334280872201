import React from "react";
import Trans from "Components/Trans";
import Link from "Components/_Elements/Link/Link";

const SubscriptionSuccess = () => {
  window.analytics.track("subscription_activated", {
    monthly_price: 69,
    created_at: new Date(),
  });

  return (
    <div className="container flex flex-col items-center">
      <img src="https://d1mgntrf3vaj6d.cloudfront.net/images/payment-success.svg" />
      <h1 className="blue">
        <Trans>Congratulations</Trans>
      </h1>
      <h6>
        <Trans>
          You have unlocked kollin premium. This gives you unlimited access to
          all courses on the platform.
        </Trans>
      </h6>
      <Link to="/courses">
        <Trans>Start exploring courses</Trans>
      </Link>
    </div>
  );
};

export default SubscriptionSuccess;
