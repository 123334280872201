import React from "react";
import useUpdateUserEvaluation from "API/mutations/userEvaluation/useUpdateUserEvaluation";

const useExerciseEvaluationUpdate = ({
  userEvaluation,
  evaluation,
  timerState,
  completed,
  selfReflection,
  setCompleted,
}) => {
  const [updateUserEvaluation] = useUpdateUserEvaluation([]);

  // Update user evaluation on server whenever timer or completed state changes:
  React.useEffect(() => {
    updateUserEvaluation({
      variables: {
        id: userEvaluation.id,
        evaluation: {
          ...evaluation,
          timeSpent: timerState.timeSpent,
          backgroundTimer: timerState.backgroundTimer,
          completed,
          selfReflection: {
            ...selfReflection,
          },
        },
      },
    });
  }, [
    completed,
    timerState.timeSpent,
    timerState.backgroundTimer,
    selfReflection,
  ]);

  // For manually updating the score, commits immediately:
  const updateUserEvaluationScore = score => {
    updateUserEvaluation({
      variables: {
        id: userEvaluation.id,
        evaluation: {
          ...evaluation,
          score,
        },
      },
    });
    if (!completed) {
      setCompleted(true);
    }
  };

  return {updateUserEvaluationScore};
};

export default useExerciseEvaluationUpdate;
