import React from "react";
import {message} from "Components";
import useListenSwishStatus from "API/subscriptions/payments/useListenSwishStatus";

const usePaymentMethodSwishStatusModalState = (paymentRequestId, onSuccess) => {
  const [modalVisible, setModalVisible] = React.useState(
    // the default state is used for testing with the query params
    new URLSearchParams(window.location.search).get("swish")
  );

  const handleClose = () => setModalVisible(false);

  // Show the modal when paymentRequestId is set:
  React.useEffect(() => {
    if (paymentRequestId && !modalVisible) {
      setModalVisible(true);
    }
  }, [paymentRequestId]);

  // Listen to status updates:
  const [data, loading] = useListenSwishStatus(paymentRequestId);

  React.useEffect(() => {
    if (data) {
      if (data.swishPaymentStatus.ok) {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
          handleClose();
        } else {
          window.location.replace(`${window.location.href}&status=success`);
        }
      } else {
        if (modalVisible) {
          setModalVisible(false);
          message.error(data.swishPaymentStatus.error);
        }
      }
    }
  }, [data]);

  return {
    handleClose,
    modalVisible,
  };
};

export default usePaymentMethodSwishStatusModalState;
