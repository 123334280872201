import React from "react";

const useExamUploadContextPreview = updateResource => {
  const [previewResource, setPreviewResource] = React.useState(null);

  const togglePreview = resource => {
    if (previewResource?.id === resource.id) {
      setPreviewResource(null);
    } else {
      setPreviewResource(resource);
    }
  };

  // Update the preview resource when it's values change
  const updatePreviewResource = updatedResource => {
    setPreviewResource(updatedResource);
  };

  // Update the global resources state when the preview resource is updated
  React.useEffect(() => {
    if (previewResource) {
      updateResource(previewResource);
    }
  }, [previewResource]);

  return {
    togglePreview,
    previewResource,
    updatePreviewResource,
  };
};

export default useExamUploadContextPreview;
