import React from "react";
import { message, Trans } from "Components";

const useManualMarkingSelectionEvents = ({
  canvas,
  active,
  onSelectionComplete,
  toggleManualSelection,
}) => {
  /*
   * A state for tracking the interaction with the canvas
   * It does not need to re-render the component, so we use a ref instead of a state
   */

  const context = React.useRef();
  const initialValues = {
    isDirty: false,
    isDrag: false,
    startX: -1,
    startY: -1,
    curX: -1,
    curY: -1,
  };

  const stateRef = React.useRef(initialValues);

  const state = stateRef.current;

  const updateCanvas = () => {
    if (state.isDrag) {
      requestAnimationFrame(updateCanvas); // Request another frame (recursive)
    }

    // If the canvas  is not dirty, don't do anything (no need to re-render)
    if (!state.isDirty) {
      return;
    }

    // Clear the canvas
    context.current.clearRect(0, 0, canvas.width, canvas.height);

    if (state.isDrag) {
      shadowRect({
        ctx: context.current,
        canvas: canvas,
      });

      const rect = {
        x: state.startX,
        y: state.startY,
        w: state.curX - state.startX,
        h: state.curY - state.startY,
      };

      // clear the shadow rect at the rect position
      context.current.clearRect(rect.x, rect.y, rect.w, rect.h);

      canvas.style.backgroundColor = "transparent";
      context.current.fillStyle = "rgba(0,0,0,0)";

      context.current.strokeRect(rect.x, rect.y, rect.w, rect.h);
      // Render the shadow effect
    }

    state.isDirty = false;
  };

  const handleMouseDown = event => {
    if (state.isDrag) {
      return;
    }

    state.isDrag = true;
    state.shadowDrawn = false;
    state.curX = event.offsetX;
    state.curY = event.offsetY;
    state.startX = event.offsetX;
    state.startY = event.offsetY;

    requestAnimationFrame(updateCanvas);
  };

  const handleMouseMove = event => {
    if (!state.isDrag) {
      return;
    }

    // Set the state:
    state.curX = event.offsetX;
    state.curY = event.offsetY;
    state.isDirty = true;
  };

  const handleMouseUp = event => {
    // If the user is not dragging, don't do anything
    if (!state.isDrag) return;

    state.isDrag = false;
    state.isDirty = false;

    const x1 = Math.min(state.startX, state.curX);
    // const x2 = Math.abs(event.offsetX - state.startX) + x1;
    const x2 = event.offsetX;

    const y1 = Math.min(state.startY, state.curY);
    // const y2 = Math.abs(event.offsetY - state.startY) + start;
    const y2 = event.offsetY;

    const rect = {
      x1,
      x2,
      y1,
      y2,
      initialWidth: canvas.width,
    };

    // Check if the selection is valid, it should be at least 100x100 pixels
    if (Math.abs(x2 - x1) < 10 || Math.abs(y2 - y1) < 10) {
      message.error(
        <Trans>The selection is too small. Please select a larger area.</Trans>
      );
      // Reset the state:
      stateRef.current = initialValues;
      return;
    }

    // Set the marking:
    onSelectionComplete(rect);
    // Toggle the manual selection:
    toggleManualSelection();
  };

  const cleanup = () => {
    if (canvas) {
      // Remove event listeners
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
    }
  };

  React.useEffect(() => {
    if (canvas && active) {
      // Add event listeners
      canvas.addEventListener("mousedown", handleMouseDown);
      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseup", handleMouseUp);

      // Listen to escape key and cancel the selection
      document.addEventListener("keydown", event => {
        if (event.key === "Escape") {
          stateRef.current = initialValues;
          cleanup();
          toggleManualSelection();
        }
      });

      const ctx = canvas.getContext("2d");

      ctx.setLineDash([5.8, 3.2]);
      ctx.strokeStyle = "rgba(15,15,15,1)";
      ctx.lineWidth = 0.5;
      context.current = ctx;
    }

    if (canvas && !active) {
      cleanup();
    }

    return cleanup;
  }, [canvas, active]);
};

const shadowRect = ({ ctx, canvas }) => {
  // Create a shadow effect using rects on the unselected areas

  ctx.fillStyle = "rgba(29, 41, 57,0.5)";

  ctx.fillRect(0, 0, canvas.width, canvas.height);
};

export default useManualMarkingSelectionEvents;
