import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GET_EXERCISE_TAG_SUGGESTIONS } from "API/queries/exercise/useGetExerciseTagSuggestions";
import { CourseContext } from "routes/Course/CourseContextProvider";
import { UserContext } from "UserContextProvider";
import { GET_EXERCISE } from "API/queries/exercise/useGetExercise";

const REVOKE_VOTE_MUTATION = gql`
  mutation revokeTagVote($exerciseId: ID!, $tagId: ID!) {
    revokeTagVote(exercise_id: $exerciseId, tag_id: $tagId) {
      ok
      error
    }
  }
`;

const useRevokeExerciseTagVoteMutation = exerciseId => {
  const { user } = React.useContext(UserContext);
  const { courseId } = React.useContext(CourseContext);

  const [revokeTagVote, { loading, data, error }] = useMutation(
    REVOKE_VOTE_MUTATION,
    {
      refetchQueries: () => [
        {
          query: GET_EXERCISE_TAG_SUGGESTIONS,
          variables: { exerciseId, courseId, loggedIn: user !== null },
        },
        {
          query: GET_EXERCISE,
          variables: {
            id: exerciseId,
            withContent: false,
            withModule: false,
            courseId,
          },
        },
      ],
    }
  );

  return [revokeTagVote, loading];
};

export default useRevokeExerciseTagVoteMutation;
