import useGetProductPrices from "API/queries/product/useGetProductPrices";
import React from "react";

import {Icon, Link, Typography} from "Components";
import {useTranslation} from "react-i18next";
import coursePriceLevels from "utilities/constants/coursePriceLevels";

const useCoursePriceTag = courseId => {
  const {t: trans} = useTranslation();
  let details = {
    type: "...",
    color: "#475467",
    tooltipInfo: null,
  };

  let coursePrice;

  const [prices, loading] = useGetProductPrices("COURSE", courseId);

  if (!loading) {
    const defaultPrice = prices.find(price => price.accessDays == 360);

    if (defaultPrice) {
      coursePrice = defaultPrice.priceCents / 100;
    } else {
      coursePrice = prices[0]?.priceCents / 100;
    }
  }

  for (let i in coursePriceLevels) {
    if (!loading && (isNaN(coursePrice) || coursePrice === 0)) {
      details = {
        type: trans("Free"),
        color: "#027A48",
        tooltipInfo: (
          <TooltipInfo
            info={
              <Typography>
                You can active this course for free anytime
              </Typography>
            }
          />
        ),
      };

      break;
    }

    if (coursePrice <= Number(coursePriceLevels[i].minPrice)) {
      details = {
        type: coursePriceLevels[i].title,
        color: coursePriceLevels[i].color,
        tooltipInfo: <TooltipInfo info={coursePriceLevels[i].description} />,
      };

      break;
    }
  }

  return {...details};
};

export default useCoursePriceTag;

const TooltipInfo = ({info}) => (
  <>
    <Typography type="tsm">{info}</Typography>
    <Link
      basic
      to="/pricing"
      target="_blank"
      className="course-price-tag-tooltip-link"
      onClick={e => e.stopPropagation()}
    >
      <Typography type="tsm" color="text-primary-600">
        More info
        <Icon icon="arrow-right" className="mx-1" />
      </Typography>
    </Link>{" "}
  </>
);
