import React from "react";
import OnboardingLayout from "./OnboardingLayout/OnboardingLayout";
import useOnboarding from "routes/Onboarding/useOnboarding";
import {Redirect, Route} from "react-router-dom";
import ONBOARDING_STEPS from "routes/Onboarding/OnboardingStepsList";
import {PageLogoLoader} from "Components";

export const OnboardingContext = React.createContext({});

const Onboarding = props => {
  const {currentStepIndex, ...contextValues} = useOnboarding({
    ...props,
  });

  const currentStep = ONBOARDING_STEPS[currentStepIndex];

  if (currentStep === undefined) {
    if (props.compact) {
      return null;
    }

    return <PageLogoLoader />;
  }

  return (
    <OnboardingContext.Provider
      value={{
        currentStepIndex,
        currentStep,
        ...contextValues,
      }}
    >
      <OnboardingLayout key="onboarding-layout" {...props} />
    </OnboardingContext.Provider>
  );
};

export default Onboarding;
