import React from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {updateGroup} from "API/appsync/mutations";
import {message, Trans} from "Components";

const useGroupSettingsInfo = ({groupInfo, refetch}) => {
  const [updateGroupInfoMutation] = useMutation(gql(updateGroup), {
    context: {
      api: "appsync",
    },
  });

  const [info, setInfo] = React.useState({
    id: groupInfo?.id,
    introduction: groupInfo?.introduction || "",
    links: groupInfo?.links || [],
  });

  const handleIntroductionChange = e => {
    const {value} = e.target;

    setInfo(prev => ({
      ...prev,
      introduction: value,
    }));
  };

  const changedFields = React.useMemo(() => {
    if (!groupInfo) return [];
    // Check which fields have changed in the state

    return Object.keys(info)?.filter(key => info[key] !== groupInfo[key]);
  }, [info, groupInfo]);

  const handleSave = () => {
    const {id, links, introduction} = info;
    // Send the mutation to update the group info to the state
    updateGroupInfoMutation({
      variables: {
        input: {
          id: id,
          introduction: introduction,
          links: links.map(link => ({
            ...link,
            __typename: undefined,
          })),
        },
      },
    }).then(data => {
      if (data?.updateGroup?.id) {
        refetch();
        message.success(<Trans>Group info updated successfully</Trans>);
      }
    });
  };

  // Handler for updating the links Array
  const handleLinksChange = links => {
    setInfo(prev => ({
      ...prev,
      links: [...links],
    }));
  };

  React.useEffect(() => {
    // Auto save when links change
    handleSave();
  }, [JSON.stringify(info.links)]);

  return {
    info,
    setInfo,
    changedFields,
    handleLinksChange,
    handleIntroductionChange,
    handleSave,
  };
};

export default useGroupSettingsInfo;
