import React from "react";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import useAccountPersonalInformationEditState from "routes/Account/AccountPersonalInformation/useAccountPersonalInformationEditState";
import {message, Trans} from "Components";
import ONBOARDING_STEPS from "routes/Onboarding/OnboardingStepsList";
import {useHistory, useLocation} from "react-router-dom";
import useQueryParamsWithPush from "utilities/hooks/useQueryParamsWithPush";
import {NumberParam} from "use-query-params";
import useOnboardingStepsState from "routes/Onboarding/useOnboardingStepsState";

const UseOnboarding = ({hideStep = null}) => {
  const location = useLocation();
  const history = useHistory();
  const [user, loading] = useGetCurrentUser();

  const [currentStepIndex, setCurrentStepIndex] = useOnboardingStepsState({
    user,
    hideStep,
  });

  const state = useAccountPersonalInformationEditState(user, () => {}, false);

  const onStepClick = stepIndex => {
    setCurrentStepIndex(stepIndex);
  };

  const onSkipClick = () => {
    history.push("/home");
  };

  const onNextStepClick = async () => {
    try {
      await state.onSave();
      if (currentStepIndex === ONBOARDING_STEPS.length - 1) {
        history.push("/home");
      } else {
        setCurrentStepIndex(currentStepIndex + 1);
      }
    } catch (e) {
      // If there are errors, do not advance to the next step
      message.error(
        <Trans>Could not update, please check the form for errors</Trans>
      );
    }
  };

  const nextStepButtonDisabled = getNextStepDisabled(currentStepIndex, state);

  return {
    currentStepIndex,
    state,
    user,
    onNextStepClick,
    nextStepButtonDisabled,
    onStepClick,
    onSkipClick,
  };
};

const getNextStepDisabled = (currentStepIndex, state) => {
  const {values} = state;

  switch (currentStepIndex) {
    case 0:
      return values.firstName === null || values.lastName === null;
    case 1:
      return values.schoolId == null;
    case 2:
      return values.programId == null;
    case 3:
      return values.enrollmentYear == null;
    default:
      return false;
  }
};

export default UseOnboarding;
