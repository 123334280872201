import React from "react";
import {Avatar, Button, Section, SubSection, Trans} from "Components";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import AccountPersonalInformationProfilePicture, {
  AvatarFileUpload,
} from "./AccountPersonalInformationProfilePicture";
import AccountPersonalInformationDisplay from "./AccountPersonalInformationDisplay";
import AccountPersonalInformationEdit from "./AccountPersonalInformationEdit";
import moment from "moment/moment";
import classnames from "classnames";
import UseAccountPersonalInformationState from "routes/Account/AccountPersonalInformation/useAccountPersonalInformationEditState";

const AccountPersonalInformation = () => {
  const [user, userLoading] = useGetCurrentUser();

  const {
    onSave,
    onCancel,
    loading: stateLoading,
    edited,
    ...state
  } = UseAccountPersonalInformationState(user);

  const loading = userLoading || stateLoading;

  return (
    <Section
      title={<Trans>Details</Trans>}
      subTitle={
        <Trans>Here you can view and edit your personal information</Trans>
      }
      action={
        <AccountPersonalInformationAction
          edited={edited}
          onSave={onSave}
          onCancel={onCancel}
          loading={loading}
        />
      }
    >
      <SubSection>
        <Trans>Profile picture</Trans>
        <AvatarFileUpload
          showSuccessMessage={false}
          icon={<Avatar user={user} alt="profile picture" size="2xl" />}
        />
      </SubSection>

      <AccountPersonalInformationEdit
        loading={loading}
        {...state}
        schoolId={state.values.schoolId}
      />

      <SubSection>
        <Trans type="tmd" weight="medium" className="text-gray-700">
          Member since
        </Trans>
        <span>{moment(user.createdAt).format("dddd DD MMMM YYYY")}</span>
      </SubSection>
    </Section>
  );
};

const AccountPersonalInformationAction = ({
  edited,
  onSave,
  onCancel,
  loading,
}) => {
  if (!edited) {
    return null;
  }

  return (
    <div className="flex gap-2">
      <Button type="gray" onClick={onCancel}>
        <Trans>Cancel</Trans>
      </Button>

      <Button type="primary" onClick={onSave} loading={loading}>
        <Trans>Save</Trans>
      </Button>
    </div>
  );
};

export default AccountPersonalInformation;
