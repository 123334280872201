import React from "react";
import classnames from "classnames";
import {Icon, SafeText} from "Components";
import MetricChart from "Components/_Elements/Metric/MetricChart";
import {
  chartSizeMap,
  valueSizeMap,
} from "Components/_Elements/Metric/Metric.constants";
import MetricChangePercentage from "Components/_Elements/Metric/MetricChangePercentage";
import WidgetFeedback from "Components/Widget/WidgetFeedback/WidgetFeedback";

const Metric = ({
  title,
  value,
  icon = null,
  changePercentage = null,
  changeLabel = null,
  children,
  fluid = true,
  data = null,
  size = "md",
  feedbackName,
  ...chartProps
}) => {
  return (
    <div
      className={classnames(
        "relative background rounded-md border border-1 border-gray shadow-sm w-full p-4"
      )}
    >
      <div className={classnames("flex flex-row items-start gap-2")}>
        <div className="grow">
          <SafeText type="tsm sm:tmd" weight="md">
            {title}
          </SafeText>

          <div className="mt-4 flex items-end gap-2">
            <SafeText
              weight="sb"
              type={valueSizeMap[size]}
              className="leading-none whitespace-nowrap"
            >
              {value}
            </SafeText>
          </div>
        </div>
        {data && (
          <div className="-my-4">
            <div className={classnames(chartSizeMap[size], "aspect-square")}>
              <MetricChart data={data} {...chartProps} />
            </div>
          </div>
        )}
      </div>
      <MetricChangePercentage
        changePercentage={changePercentage}
        changeLabel={changeLabel}
        icon={icon}
      />
      <div className="hidden sm:block absolute top-2 right-2">
        <WidgetFeedback showAsDots={true} widgetName={feedbackName} />
      </div>
    </div>
  );
};

export default Metric;
