import React from "react";
import i18n from "i18n";
import classnames from "classnames";
import {Typography} from "Components";
import {motion} from "framer-motion";

export const types = {
  exam: i18n.t("Exam"),
  exam_solutions: i18n.t("Solutions"),
  mixed: i18n.t("Mixed"),
  unknown: i18n.t("Unknown"),
};

const colorMapping = {
  exam: "highlight-primary",
  exam_solutions: "highlight-secondary",
  mixed: "highlight-purple",
  unknown: "highlight-warning",
};

const ExamUploadDocumentsRowFilesTypes = ({
  type,
  onClick = null,

  fileId,

  disabled,
  selectable = false,
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <motion.div
      // layoutId={fileId + type}
      key={type}
      animate={{
        opacity: selectable ? 0.75 : 1,
      }}
      className={classnames(
        "p-2 flex gap-2 border-2 border-gray background items-center rounded-xl shadow-sm",
        selectable && "cursor-pointer hover:shadow-md",
        {
          surface: disabled,
        }
      )}
      onClick={handleClick}
    >
      <div className={classnames("w-2 h-2 rounded-full", colorMapping[type])} />
      <Typography type="tsm" weight="md">
        {types[type]}
      </Typography>
    </motion.div>
  );
};

export default ExamUploadDocumentsRowFilesTypes;
