import React from "react";
import CourseSidebarFooterUser from "Components/Sidebar/CourseSidebar/CourseSidebarFooter/CourseSidebarFooterUser";
import NotificationsDropDown from "Components/Notifications/NotificationsDropDown/NotificationsDropDown";
import CourseSidebarMenuItem from "Components/Sidebar/CourseSidebar/CourseSidebarMenuItem";
import {Badge, Trans, Typography} from "Components/index";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import {useHistory} from "react-router-dom";
import useLocalStorageState from "../../../../utilities/hooks/useLocalStorageState";
import {GlobalModalContext} from "GlobalModals";

const CourseSidebarFooter = ({toggleCollapsed, collapsed}) => {
  const history = useHistory();
  const {openModal} = React.useContext(GlobalModalContext);

  const [user] = useGetCurrentUser(false, false);

  return (
    <div key="footer" className="relative">
      <CourseSidebarMenuItem
        collapsed={collapsed}
        label={<Typography>Bjud in en vän</Typography>}
        icon="fas fa-gift"
        onClick={() => openModal("FRIEND_COUPON")}
        color="text-amber-light"
        iconColor="text-amber-light"
        background="bg-amber-50"
      />
      <CourseSidebarFooterCredits
        collapsed={collapsed}
        Context={window.CourseModalContext}
      />
      <NotificationsDropDown
        dropDownSide="right"
        trigger={unreadNotificationsCount => (
          <CourseSidebarMenuItem
            collapsed={collapsed}
            label={<Trans>Notifications</Trans>}
            icon="bell"
            badge={
              <Badge type="primary">
                <Typography>{unreadNotificationsCount}</Typography>
              </Badge>
            }
          />
        )}
      />

      <CourseSidebarMenuItem
        collapsed={collapsed}
        label={<Trans>My courses</Trans>}
        icon="book"
        onClick={() => history.push("/home")}
        color="text-primary-light"
        iconColor="text-primary-light"
      />

      <CourseSidebarFooterUser collapsed={collapsed} />
    </div>
  );
};
export const CourseSidebarFooterCredits = ({
  collapsed,
  Context,
  sidebarMenuItemProps = {},
}) => {
  const {openModal} = React.useContext(Context || GlobalModalContext);

  const [user] = useGetCurrentUser(false, false);

  const credits = user?.credits / 100;
  if (!user?.id) return null;

  return (
    <CourseSidebarMenuItem
      collapsed={collapsed}
      label={<Trans credits={credits}>{{credits}} Credits</Trans>}
      icon="coins"
      onClick={() => openModal("CREDITS")}
      {...sidebarMenuItemProps}
    />
  );
};

export default CourseSidebarFooter;
