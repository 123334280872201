import {useMutation} from "@apollo/react-hooks";
import {GET_BOOKMARKED} from "API/queries/bookmark/useGetExerciseBookmarked";
import gql from "graphql-tag";
import React from "react";
import {CourseContext} from "routes/Course/CourseContextProvider";
import {UserContext} from "UserContextProvider";

const TOGGLE_BOOKMARK = gql`
  mutation bookmarkExercise($exerciseId: ID!, $courseId: ID!) {
    bookmarkExercise(exercise_id: $exerciseId, course_id: $courseId) {
      ok
      error
    }
  }
`;

const useToggleBookmarkedMutation = exerciseId => {
  const {user} = React.useContext(UserContext);
  const {courseId} = React.useContext(CourseContext);

  const [mutate, {loading}] = useMutation(TOGGLE_BOOKMARK, {
    variables: {exerciseId, courseId},
    refetchQueries: [{query: GET_BOOKMARKED, variables: {exerciseId}}],
  });

  // if (!user) {
  //   setBookmark = () =>
  //     history.push({
  //       pathname: "/modal/login",
  //       state: {
  //         background: location,
  //         message:
  //           "Du måste logga in eller registrera dig för att kunna bokmärka uppgifter",
  //       },
  //     });
  // }

  return [mutate, loading];
};

export default useToggleBookmarkedMutation;
