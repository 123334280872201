import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import {UserContext} from "UserContextProvider";
import formatExercise from "utilities/exerciseUtilities/formatExercise";

const GET_EXAM_EXERCISES = gql`
  query(
    $examId: ID!
    $loggedIn: Boolean!
    $exerciseFilters: ExerciseFiltersInput
  ) {
    getExam(id: $examId) {
      id
      author
      date
      exercises_src # The EXERCISES_PNG file
      solutions_src # The SOLUTIONS_PNG file
      exam_exercises_src # The EXERCISES_PDF file
      exam_solutions_src # The SOLUTIONS_PDF file
      exercises(filters: $exerciseFilters) {
        id
        number
        score
        imageUrl
        tags {
          id
          name
        }
        stats {
          total
        }
        self_evaluation @include(if: $loggedIn) {
          id
          score
          date
        }
        source {
          id
          date
          author
        }
        bookmarked @include(if: $loggedIn)
        notes @include(if: $loggedIn) {
          id
          text
        }
      }
    }
  }
`;

const useGetExamExercises = ({
  examId,
  exerciseFilters = {
    published: true,
  },
}) => {
  const {user} = React.useContext(UserContext);

  const {loading, error, data} = useQuery(GET_EXAM_EXERCISES, {
    variables: {
      examId,
      loggedIn: user !== null,
      exerciseFilters,
    },
  });

  let exercises = [];

  if (error) console.log(`Error in useGetExamExercises: ${error}`);

  if (data && data.getExam && data.getExam.exercises) {
    exercises = data.getExam.exercises.map(ex => formatExercise(ex));
  }

  return [exercises, loading];
};

export default useGetExamExercises;
