import React from "react";

import {Trans, Modal, TextArea, Typography} from "Components";
import useProductPurchaseExpiryBannerExtensionFormState from "Components/ProductPurchaseExpiryBanner/ProductPurchaseExpiryBannerExtensionForm/useProductPurchaseExpiryBannerExtensionFormState";
import {AnimatedCheckbox} from "Components/index";
import {getCleanCourseName} from "utilities/formatCourseName";

const ProductPurchaseExpiryBannerExtensionForm = ({
  productName,
  productId,
  modalVisible,
  setModalVisible,
}) => {
  const {
    onSubmit,
    loading,
    hasApplied,
    ...hook
  } = useProductPurchaseExpiryBannerExtensionFormState({
    productId,
    productName,
  });

  const cleanProductName = getCleanCourseName(productName);

  return (
    <>
      <Modal
        title={
          <Trans cleanProductName={cleanProductName}>
            Application for extending your access to {{cleanProductName}}
          </Trans>
        }
        description={
          hook.success ? (
            <Trans>Your application has been submitted</Trans>
          ) : (
            <Typography>
              Vill du plugga vidare på kursen? Vi kan förlänga din tillgång till
              kursen i 6 månader. Ge oss en kort motivering till varför du
              önskar fortsätta plugga så återkommer vi inom kort.
            </Typography>
          )
        }
        defaultOpen={modalVisible}
        showCancelButton
        onClose={() => setModalVisible(false)}
        action={{
          label: <Trans>Submit</Trans>,
          onClick: onSubmit,
          loading,
          disabled: hook.state.reason == null || hook.state.reason == "",
        }}
      >
        <ProductPurchaseExpiryBannerExtensionFormBody {...hook} />
      </Modal>
    </>
  );
};

const ProductPurchaseExpiryBannerExtensionFormBody = ({
  success,
  onChange,
  state,
  submissionError,
}) => {
  if (success) {
    return <AnimatedCheckbox />;
  }

  return <TextArea onChange={onChange} name="reason" value={state.reason} />;
};

export default ProductPurchaseExpiryBannerExtensionForm;
