import React from "react";
import "./Skeleton.css";
import classnames from "classnames";

const Skeleton = ({
  style,
  size = "sm",
  fluid = true,
  autoWidth = false,
  circle = false,
  square = false,
  className,
  children = null,
  loading = false,
}) => {
  let width = null;

  if (fluid) width = "100%";

  if (autoWidth) width = Math.random() * 100 + "%";

  if (children !== null && !loading) return children;

  return (
    <div
      className={classnames(
        "skeleton",
        sizeMap[size],
        circle ? "rounded-full" : "rounded-md",
        square && "aspect-square w-auto",
        className
      )}
      style={{width, ...style}}
    >
      {children}
    </div>
  );
};

const sizeMap = {
  "3xs": "h-1",
  "2xs": "h-2",
  xs: "h-4",
  sm: "h-8",
  md: "h-12",
  lg: "h-16",
  xl: "h-20",
  "2xl": "h-24",
};
export default Skeleton;
