import React from "react";
import { UserContext } from "UserContextProvider";
import { useTranslation } from "react-i18next";

const useNotificationDisplayMessage = () => {
  const { user } = React.useContext(UserContext);
  const { t: trans } = useTranslation();

  const messages = {
    comment_added_on_following_thread: notification => {
      const owner = user.id === notification.data.creator;
      let tail = owner ? trans("started") : trans("are following");

      return {
        message:
          notification.sender.first_name +
          " " +
          trans("has replied to a thread you") +
          " " +
          tail,
        url: `/course/${notification.data.courseId}/forum/?thread=${notification.objectId}`,
      };
    },
  };

  return messages;
};

export default useNotificationDisplayMessage;
