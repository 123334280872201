import React from "react";
import useLocalStorageState from "utilities/hooks/useLocalStorageState";

const useDarkModeToggleState = () => {
  const [theme, setTheme] = useLocalStorageState("theme", "light");

  React.useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const toggleDarkMode = () => {
    if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  };
  const darkMode = theme === "dark";

  return { darkMode, toggleDarkMode };
};

export default useDarkModeToggleState;
