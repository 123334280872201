import React from "react";
import useGetTag from "API/queries/tag/useGetTag";
import {Icon, Link} from "../index";
import {capitalizeFirstLetter} from "../../../utilities/text";

const BreadcrumbTag = ({tagId, getTagLink}) => {
  if (!tagId) return null;

  const [tag, tagLoading] = useGetTag(tagId);

  if (tagLoading) return null;

  return (
    <div className="flex gap-1">
      <Icon icon="chevron-right" size="md" />
      <Link
        type="txl"
        weight="md"
        className="text-gray-500"
        to={getTagLink(tagId)}
      >
        {capitalizeFirstLetter(tag.name)}
      </Link>
    </div>
  );
};

export default BreadcrumbTag;
