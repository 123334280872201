import React from "react";
import {useFormValidation} from "utilities/hooks/useFormValidation";
import {validateExerciseNote} from "utilities/validators";
import useCreateExerciseNote from "API/mutations/exerciseNote/useCreateExerciseNote";

const useExerciseNotesNewNoteState = (exerciseId, text = "") => {
  const {
    createExerciseNote,
    createExerciseNoteLoading,
    response,
  } = useCreateExerciseNote(exerciseId);

  const {values, handleChange, errors, submit} = useFormValidation(
    {
      exerciseId,
      text,
    },
    createExerciseNote,
    validateExerciseNote
  );

  const onCancel = () => {
    // Reset the form
    handleChange({target: {name: "text", value: ""}});
  };

  // cancel after response.ok
  React.useEffect(() => {
    if (response?.ok) {
      onCancel();
    }
  }, [response]);

  const submitDisabled = values.text?.length < 1;

  return {
    values,
    handleChange,
    errors,
    submit,
    loading: createExerciseNoteLoading,
    submitDisabled,
    onCancel,
    response,
  };
};

export default useExerciseNotesNewNoteState;
