import React from "react";
import useOutsideAlerter from "utilities/hooks/useOutsideClickAlerter";
import useFocusedState from "utilities/hooks/useFocusedState";
import useSelectState from "Components/_Elements/Select/useSelectState";
import useSelectSearchState from "Components/_Elements/Select/useSelectSearchState";
import useKeyboardShortcuts from "utilities/hooks/useKeyboardShortcuts";

const useSelect = ({
  defaultSelectedValue,
  value,
  options,
  onSelect,
  onChange,
}) => {
  const selectRef = React.useRef(null);

  const {focused, handleBlur, handleFocus} = useFocusedState();

  useOutsideAlerter(selectRef, handleBlur); // Close the select when clicking outside

  const {items, handleSearch, searchTerm} = useSelectSearchState({
    options,
  });

  const {selectedItem, setSelectedItemByValue} = useSelectState({
    defaultSelectedValue,
    value,
    items,
    onSelect,
    onChange,
  });

  const handleItemClick = value => {
    handleBlur();
    setSelectedItemByValue(value);
  };

  useKeyboardShortcuts(
    [
      {
        key: "Escape",
        action: () => {
          const input = selectRef.current.querySelector("#select-input");

          if (input) {
            input.blur();
          }

          handleBlur();
        },
      },
    ],
    [focused],
    false
  );

  return {
    selectRef,
    selectedItem,
    handleItemClick,
    focused,
    handleFocus,
    handleBlur,
    handleSearch,
    searchTerm,
    items,
  };
};

export default useSelect;
