import React from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {GET_GROUP_MEMBER_INVITES} from "API/queries/groups/useGetGroupMemberInvites";

const CREATE_GROUP_MEMBER_INVITE = gql`
  mutation deleteGroupMemberInvite($inviteId: ID!, $groupId: ID!) {
    deleteGroupMemberInvite(inviteId: $inviteId, groupId: $groupId) {
      ok
      error {
        message
        code
      }
    }
  }
`;

const useDeleteGroupMemberInvite = ({groupId, refetchQueries = []}) => {
  const [deleteGroupMemberInvite, {data, loading, error}] = useMutation(
    CREATE_GROUP_MEMBER_INVITE,
    {
      errorPolicy: "all",
      refetchQueries: [
        {
          query: GET_GROUP_MEMBER_INVITES,
          errorPolicy: "all",
          variables: {
            id: groupId,
          },
        },
        ...refetchQueries,
      ],
    }
  );

  if (error) {
    console.log(`Error in useCreateGroupMemberInvite: ${error}`);
  }

  console.log(data);

  const response = data?.deleteGroupMemberInvite || null;

  return {
    deleteGroupMemberInvite,
    response,
    loading,
  };
};

export default useDeleteGroupMemberInvite;
