import React from "react";
import {Icon} from "Components/_Elements/index";
import classnames from "classnames";
/**
 * Icon is either a string, an Icon component or an image
 * @param icon
 * @param selected
 * @param size
 * @returns {JSX.Element}
 * @constructor
 */
const TabsPaneIcon = ({icon, selected, size}) => {
  const iconProps = {
    color: selected ? "text-primary-light" : "text-gray-light",
    size: iconSizesMap[size],
    ...icon?.props,
  };

  // If icon is a string, render Icon component
  if (typeof icon === "string") {
    return <Icon icon={icon} {...iconProps} />;
  }

  // If icon is an image, render img tag
  if (icon.type === "img") {
    return (
      <img
        src={icon.props.src}
        alt={icon.props.alt}
        className={classnames(icon.props.className, imageSizesMap[size])}
      />
    );
  }

  // If icon is an Icon component, clone it and add props
  if (icon.type === Icon) {
    return React.cloneElement(icon, iconProps);
  }

  return null;
};

const iconSizesMap = {
  xs: "xs",
  sm: "sm",
  md: "sm",
  lg: "md",
  xl: "lg",
  "2xl": "xl",
};

const imageSizesMap = {
  xs: "h-3",
  sm: "h-4",
  md: "h-5",
  lg: "h-6",
  xl: "h-8",
  "2xl": "h-10",
};

export default TabsPaneIcon;
