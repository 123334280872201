import React from "react";
import useGetCreditsBundles from "API/queries/creditsBundle/useGetCreditsBundles";
import useBuyCredits from "Widgets/Credits/BuyCredits/useBuyCredits";
import BuyCreditsBundle from "Widgets/Credits/BuyCredits/BuyCreditsBundle";
import {CreditCardLogo, Tabs, Trans} from "Components";
import CDN from "utilities/constants/CDN";
import classnames from "classnames";
import BuyCreditsPrompt from "Widgets/Credits/BuyCredits/BuyCreditsPrompt";
import BuyCreditsActions from "Widgets/Credits/BuyCredits/BuyCreditsActions";

const BuyCredits = () => {
  const {
    selectedBundle,
    selectedPaymentMethod,
    handleTabChange,
    creditsBundles,
    loading,
    ...buyCreditsActions
  } = useBuyCredits();

  return (
    <div className="p-4 background rounded-xl border-2 border-gray">
      <div className="flex justify-between items-center">
        <Tabs
          type="button-gray"
          defaultSelectedTabKey={selectedPaymentMethod}
          onTabChange={handleTabChange}
          size="lg"
        >
          <Tabs.Pane
            key="swish"
            title={
              <img
                src={CDN + `/icons/swish.svg`}
                alt="swish"
                className="w-16"
              />
            }
          />
          <Tabs.Pane
            title={<Trans>Card</Trans>}
            key="stripe"
            icon="credit-card"
          />
        </Tabs>
        <BuyCreditsPrompt />
      </div>
      <div className={classnames("grid  grid-cols-1  gap-2")}>
        {creditsBundles.map(bundle => {
          const selected = selectedBundle && selectedBundle?.id === bundle.id;
          return (
            <BuyCreditsBundle
              {...bundle}
              faded={selectedBundle && selectedBundle?.id !== bundle.id}
              selected={selected}
            >
              <BuyCreditsActions
                selected={selected}
                selectedPaymentMethod={selectedPaymentMethod}
                {...buyCreditsActions}
              />
            </BuyCreditsBundle>
          );
        })}
      </div>
    </div>
  );
};

export default BuyCredits;
