import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import {objectKeysSnakeToCamel} from "utilities/objects";

const GET_COUPON = gql`
  query(
    $id: ID
    $name: String
    $includeProgram: Boolean!
    $includeSchool: Boolean!
  ) {
    getCoupon(id: $id, name: $name) {
      id
      name
      amount
      issuer_name
      program @include(if: $includeProgram) {
        id
        name
      }
      school @include(if: $includeSchool) {
        id
        name
      }
      trial_days
      expiry_date
    }
  }
`;

/**
 *
 * @param {Object} params keys: name or ID
 * @param {Boolean} includeProgram: whether to include the program in the query
 */
const useGetCoupon = (params, includeSchool = true, includeProgram = true) => {
  const {loading, error, data} = useQuery(GET_COUPON, {
    variables: {...params, includeProgram, includeSchool},
  });

  if (error) {
    console.log("Error in useGetCoupon coupon", error);
    return [null, false];
  }

  let coupon = {};

  if (data) {
    coupon = objectKeysSnakeToCamel(data.getCoupon);
  }

  return [coupon, loading];
};

export default useGetCoupon;
