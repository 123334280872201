import React from "react";
import useRequestOTP from "API/mutations/OTP/useRequestOTP";
import {useFormValidation} from "utilities/hooks/useFormValidation";
import {validateEmail} from "utilities/validators";
import {useTranslation} from "react-i18next";

const useOTP = ({
  action,
  onVerificationComplete,
  initialEmail = "",
  autoSubmit,
}) => {
  const {t: trans} = useTranslation();

  const [hide, setHide] = React.useState(false);

  const {requestOTP, loading, error, response} = useRequestOTP(action);

  const requestSuccessful = response.ok;

  const {values, handleChange, submit, errors, setErrors} = useFormValidation(
    {email: initialEmail, action},
    requestOTP,
    validateEmail
  );

  // Transfer mutation errors to form errors
  React.useEffect(() => {
    if (response.error) {
      const errorMessage = trans(response.error.message);
      setErrors({email: errorMessage});
    }
  }, [response]);

  const onComplete = response => {
    setHide(true);
    if (onVerificationComplete) {
      setTimeout(() => {
        onVerificationComplete(response);
      }, 200); // only for animation
    }
  };

  React.useEffect(() => {
    if (autoSubmit && initialEmail !== "") {
      submit();
    }
  }, []);

  return {
    handleChange,
    values,
    errors,
    submit,
    requestSuccessful,
    onComplete,
    loading,
    hide,
  };
};

export default useOTP;
