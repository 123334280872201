import React from "react";
import Login from "routes/Auth/Login/Login";
import {Modal} from "Components";
import {useLocation} from "react-router-dom";
import RequestCourse from "Modals/RequestCourse/RequestCourse";
import FriendCoupon from "Modals/FriendCoupon/FriendCoupon";
import CourseProgressSuggestions from "Modals/CourseProgressSuggestions/CourseProgressSuggestions";
import RequestSchool from "Modals/RequestSchool/RequestSchool";
import SupportMailForm from "Components/SupportMailForm";
import CreditsModal from "Modals/CreditsModal/CreditsModal";
import SignUp from "./routes/Auth/SignUp/SignUp";
import StudentVerification from "./Widgets/User/StudentVerification/StudentVerification";
import ProfilePicture from "Widgets/User/ProfilePicture/ProfilePicture";
import GroupMembershipVerification from "Widgets/Group/GroupMembershipVerification/GroupMembershipVerification";

import GroupMembershipVerificationModal from "Widgets/Group/GroupMembershipVerification/GroupMembershipVerificationModal";

export const GlobalModalContext = React.createContext(null);
window.GlobalModalContext = GlobalModalContext;

const Modals = {
  LOGIN: {
    Component: <Login wrapper={false} socialButtonsCompact={true} />,
    renderComponentInModal: true,
  },
  SIGNUP: {Component: <SignUp wrapper={false} />, renderComponentInModal: true},
  REQUEST_COURSE: {Component: <RequestCourse />, renderComponentInModal: true},
  FRIEND_COUPON: {Component: <FriendCoupon />, renderComponentInModal: true},
  COURSE_PROGRESS_SUGGESTIONS: {
    Component: <CourseProgressSuggestions />,
    renderComponentInModal: true,
  },
  REQUEST_SCHOOL: {Component: <RequestSchool />, renderComponentInModal: false},
  SUPPORT: {
    Component: <SupportMailForm />,
    renderComponentInModal: true,
  },
  CREDITS: {
    Component: <CreditsModal />,
    renderComponentInModal: false,
  },
  REAL_ME: {
    Component: <StudentVerification />,
    renderComponentInModal: true,
  },
  PROFILE_PICTURE: {
    Component: <ProfilePicture />,
    renderComponentInModal: true,
  },
  GROUP_MEMBERSHIP_VERIFICATION: {
    Component: <GroupMembershipVerificationModal />,
    renderComponentInModal: false,
  },
};

const GlobalModals = ({children}) => {
  const location = useLocation();
  const [modalStack, setModalStack] = React.useState([]);

  const openModal = (key, onclose = null, props) => {
    // We don't want to open the same modal twice
    if (modalStack.filter(modal => modal.key === key).length > 0) {
      return;
    }

    // Check if the modal exists
    if (!Modals[key]) {
      return;
    }

    const callback = onclose || (() => {});

    // Add the modal to the stack
    setModalStack([{key, callback, props}, ...modalStack]);
  };

  const closeModal = () => {
    // When closing a modal, we want to remove it from the stack
    setModalStack(modalStack.slice(1));
  };

  // Close all modals when the route changes
  React.useEffect(() => {
    setModalStack([]);
  }, [location.pathname]);

  return (
    <GlobalModalContext.Provider
      value={{
        openModal,
        closeModal,
        modalStack,
      }}
    >
      <CurrentModal modalStack={modalStack} closeModal={closeModal} />
      {children}
    </GlobalModalContext.Provider>
  );
};

const CurrentModal = ({modalStack, closeModal}) => {
  if (modalStack.length === 0) return null;

  const currentModal = Modals[modalStack[0]?.key]; // The first modal in the stack is the current modal
  const currentModalProps = modalStack[0]?.props;

  if (!currentModal) return null;

  /**
   * Clone the modal component and add the onComplete prop with the closeModal function and the callback if it exists
   * @type {React.DetailedReactHTMLElement<{onComplete: onComplete}, HTMLElement>}
   */
  const callback = modalStack[0].callback;
  const Component = React.cloneElement(currentModal.Component, {
    onComplete: () => {
      closeModal();
      if (callback && typeof callback === "function") {
        callback();
      }
    },
    onClose: closeModal,
  });

  if (currentModal?.renderComponentInModal) {
    return (
      <Modal
        defaultOpen={true}
        onClose={closeModal}
        darkMode={currentModalProps?.darkMode}
        className={currentModalProps?.className}
      >
        {React.cloneElement(Component, {
          ...currentModalProps,
        })}
      </Modal>
    );
  }

  return React.cloneElement(Component, {
    ...currentModalProps,
  });
};

export default GlobalModals;
