import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

const useGetPersonalCoupon = () => {
  let coupon = "-";
  const {data, loading, error} = useQuery(GET_CURRENT_USER, {
    errorPolicy: "ignore", // If the user is not logged in, ignore the error
  });

  if (error) {
    console.log(`Error  in useGetPersonalCoupon: ${error}`);
  }

  if (data) {
    coupon = data.getPersonalCoupon;
  }

  return [coupon, loading];
};

export const GET_CURRENT_USER = gql`
  query getCurrentUserPersonalCoupon {
    getPersonalCoupon
  }
`;

export default useGetPersonalCoupon;
