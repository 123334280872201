import React from "react";
import {Icon} from "Components";
import classnames from "classnames";

const MyCoursesWidget = ({
  title,
  type = "gray",
  icon,
  subTitle,
  children,
  action,
  size = "lg",
}) => {
  return (
    <div
      className={classnames(
        " rounded-xl flex flex-col  w-full p-3  md:p-6 overflow-hidden",
        `bg-${type}-50`
      )}
    >
      <div className="flex justify-between items-center">
        {title &&
          React.cloneElement(title, {
            type: size === "lg" ? "dxs md:dlg" : "tlg md:txl",
            weight: "sb",
            color: `text-${type}-600`,
            className: "leading-none truncate",
          })}
        <Icon
          icon={icon}
          className="fa-xl md:fa-2xl"
          size={null}
          color={`text-${type}-600`}
        />
      </div>

      {subTitle &&
        React.cloneElement(subTitle, {
          type: "tsm md:tlg",
          weight: "sb",
          color: `text-${type}-600`,
          className: "mt-2",
        })}

      <div className="px-4 grow">{children}</div>

      <div className="flex justify-end">{action}</div>
    </div>
  );
};

export default MyCoursesWidget;
