import React from "react";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {Button, Trans, Typography} from "Components";
import useGroupOnboarding from "routes/Group/GroupOnboarding/useGroupOnboarding";
import GroupOnboardingSubGroups from "routes/Group/GroupOnboarding/GroupOnboardingSubGroups";
import GroupOnboardingEnrollmentYear from "routes/Group/GroupOnboarding/GroupOnboardingEnrollmentYear";

const GroupOnboarding = ({wrapper}) => {
  let Wrapper = wrapper || "div";

  const {
    selectedSubGroupId,
    selectedProgramId,
    enrollmentYear,
    handleSubGroupChange,
    handleEnrollmentYearChange,
    submitDisabled,
    handleSubmit,
    loading,
    requireProgram,
  } = useGroupOnboarding();

  return (
    <Wrapper>
      {requireProgram && (
        <div>
          <Trans type="tlg" weight="sb">
            Choose your program
          </Trans>

          <div className="mt-4" />
          <GroupOnboardingSubGroups
            selectedSubGroupId={selectedSubGroupId}
            selectedProgramId={selectedProgramId}
            handleSubGroupChange={handleSubGroupChange}
          />
        </div>
      )}
      <div className="mt-8" />
      <Trans type="tlg" weight="sb">
        Which year did you start?
      </Trans>
      <div className="mt-4" />
      <GroupOnboardingEnrollmentYear
        enrollmentYear={enrollmentYear}
        handleEnrollmentYearChange={handleEnrollmentYearChange}
        isDisabled={requireProgram && selectedSubGroupId === null}
      />
      <div className="mt-8" />
      <Button
        type="primary"
        fluid
        onClick={handleSubmit}
        disabled={submitDisabled}
        loading={loading}
      >
        <Trans>Join</Trans>
      </Button>
    </Wrapper>
  );
};

export default GroupOnboarding;
