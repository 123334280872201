import React from "react";
import {useTranslation} from "react-i18next";
import {message, Trans} from "Components";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {useHistory} from "react-router-dom";
import {SIGNUP} from "API/mutations/quickSignup/useSignup";

const trackEvent = {
  FACEBOOK: "login_with_facebook_clicked",
  GOOGLE: "login_with_google_clicked",
};

const useSocialLogin = ({
  provider,
  screenName,
  action = "login",
  handleAuthResponse,
}) => {
  const {t: trans} = useTranslation();
  const {history} = useHistory();

  const MUTATION = action === "signup" ? SIGNUP : LOGIN_SOCIAL;
  const returnObjectName = action === "signup" ? "signup" : "loginSocial";

  const [socialMutation, {loading, error}] = useMutation(MUTATION, {
    onCompleted: data => handleAuthResponse(data, returnObjectName, provider),
  });

  const onResolve = token => {
    if (!token) {
      console.log("no token");
      return;
    }

    // Track event
    window.analytics.track(trackEvent[provider], {
      screen_name: screenName,
    });

    socialMutation({
      variables: {
        token,
        provider,
        method: provider,
      },
    });
  };

  return {
    loading,
    onResolve,
  };
};

const LOGIN_SOCIAL = gql`
  mutation loginSocial($token: String!, $provider: LOGIN_SOCIAL_PROVIDER!) {
    loginSocial(token: $token, provider: $provider) {
      ok
      requiredAction {
        action
        email
      }
      token
      refreshToken
      errors {
        code
        path
        message
      }
    }
  }
`;

export default useSocialLogin;
