import React from "react";
import {Icon, Link, Typography} from "Components";

import ExerciseCheckMark from "../ExerciseCheckMark/ExerciseCheckMark";
import DifficultyChip from "../DifficultyChip/DifficultyChip";
import ExerciseThumbnailLoading from "./ExerciseThumbnailLoading";
import ExerciseTag from "../ExerciseTag/ExerciseTag";

const ExerciseThumbnail = ({exercise, showZoom = false, loading}) => {
  const {
    id,
    number,
    tags,
    imageUrl,
    stats,
    bookmarked,
    notes,
    selfEvaluation,
    score,
    source,
    author,
  } = exercise;
  const [expand, setExpand] = React.useState(false);
  const {courseId} = React.useContext(window.CourseContext);

  const urlPrefix = "https://kollin-exams.s3.eu-north-1.amazonaws.com/";

  const thumbnailImage = urlPrefix + imageUrl;

  // Get exam in date format DD Jan YYYY
  const examDate = new Date(source.date.substring(0, 10));
  const examExtension = source.date.slice(10).trim();

  const examDateFormatted = examDate.toLocaleDateString("sv-SE", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  if (loading) return <ExerciseThumbnailLoading />;

  return (
    <div className="cursor-pointer group w-full">
      <div className="aspect-[2/1] border-1 border-gray-300 rounded-lg bg-white flex flex-col justify-between gap-2 cursor-pointer group-hover:border-primary-strong group-hover:shadow-lg">
        <div className="h-full w-full m-auto flex overflow-hidden hover:overflow-y-scroll py-3 px-1">
          <img
            className="object-contain w-fit h-fit m-auto"
            src={thumbnailImage}
          />
        </div>
        <div className="w-full flex flex justify-between items-bottom gap-4  bg-gray-50 border-t-1 border-gray-300 p-4 rounded-b-lg">
          <div className="flex gap-2 items-center">
            <DifficultyChip score={score} />
            <Typography type="tsm" className="text-gray-500">
              {stats?.total || 0}
              <Icon icon="fa-solid fa-fire-flame-curved" className="mr-1" />
            </Typography>
          </div>
          <div className="flex gap-2 items-center">
            {showZoom && (
              <div
                onClick={() => {
                  setExpand(true);
                }}
              >
                <Icon
                  icon="fa-regular fa-magnifying-glass-plus hover:primary-300"
                  size="lg"
                />
              </div>
            )}
            <Icon
              color={bookmarked ? "text-primary-400" : "text-gray-300"}
              icon={bookmarked ? "fas fa-bookmark" : "fa-regular fa-bookmark"}
              size="lg"
            />
            <Icon
              color={notes?.length > 0 ? "text-primary-400" : "text-gray-300"}
              icon={notes?.length > 0 ? "fas fa-note" : "fa-regular fa-note"}
              size="lg"
            />
            <ExerciseCheckMark
              exercise={exercise}
              showIfUnrated={true}
              key={selfEvaluation?.score}
              size="lg"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-2 w-full pt-2 px-2 overflow-x-scroll">
        {tags.map((tag, index) => {
          return <ExerciseTag tagId={tag.id} />;
        })}
      </div>
      <div className="flex flex-col gap-2 px-2 my-2">
        <Typography type="tmd" color="text-gray-500" className="">
          Uppgift {number}
        </Typography>
        <div className="flex items-center gap-2">
          <Link
            to={`/course/${courseId}/exam-exercises/${source.id}?dir=asc&exercise=${id}&sort=number&displayMode=list`}
            // to={getExamLink(source.id, {exercise: id})}
            asButton
            inline
            type="link"
          >
            {examDateFormatted}
          </Link>
          {author && (
            <div className="flex items-center gap-2 overflow-hidden">
              <Icon
                icon="fa-solid fa-circle-small"
                size="xs"
                color="text-gray-500"
              />
              <div className="flex gap-1 overflow-hidden">
                <Typography
                  type="tmd"
                  color="text-gray-500"
                  className="truncate text-ellipsis"
                >
                  {author}
                </Typography>
                {examExtension && (
                  <Typography
                    type="tmd"
                    color="text-gray-500"
                    className="truncate text-ellipsis"
                  >
                    ({examExtension})
                  </Typography>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExerciseThumbnail;
