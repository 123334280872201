import React from "react";

export const textSizes = {
  d2xl: "text-h1",
  dxl: "text-h2",
  dlg: "text-h3",
  dmd: "text-h4",
  dsm: "text-h5",
  dxs: "text-h6",
  txl: "text-body1",
  tlg: "text-body2",
  tmd: "text-body3",
  tsm: "text-body4",
  txs: "text-body5",
};

export const textWeight = {
  thin: "font-thin",
  extralight: "font-extralight",
  light: "font-light",
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold",
  extrabold: "font-extrabold",
  black: "font-black",
  t: "font-thin",
  el: "font-extralight",
  l: "font-light",
  n: "font-normal",
  md: "font-medium",
  sb: "font-semibold",
  b: "font-bold",
  eb: "font-extrabold",
  bl: "font-black",
};

const headingTags = {
  d2xl: "h1",
  dxl: "h2",
  dlg: "h3",
  dmd: "h4",
  dsm: "h5",
  dxs: "h6",
};

const Typography = ({
  children,
  type, //= "tmd",
  weight, //= "normal",
  color,
  className,
  inline = false,
  block = false,
  props,
  style,
  Tag = "p",
}) => {
  const headingTag = React.useMemo(() => (type ? getHeadingTag(type) : null), [
    type,
  ]);

  const types = React.useMemo(() => (type ? getTypes(type) : null), [type]);

  if (headingTag !== null) {
    const Header = `${headingTag}`;
    return (
      <Header
        className={[
          inline ? "inline" : "",
          block ? "block mb-2" : "",
          color,
          types,
          textWeight[weight],
          className,
        ].join(" ")}
        style={style}
      >
        {children}
      </Header>
    );
  }
  return (
    <Tag
      className={[
        inline ? "inline" : "",
        block ? "block" : "",
        color,
        types,
        textWeight[weight],
        className,
      ].join(" ")}
      style={style}
      {...props}
    >
      {children}
    </Tag>
  );
};

/**
 * Types accept a string which may contain multiple types, e.g. for different screen sizes.
 * For example: "dxs md:dsm"
 * Should return: "text-h6 text-md:h5"
 */
const getTypes = type => {
  const types = type.split(" ");
  return types
    .map(t => {
      const elements = t.split(":"); // e.g. ["md", "dsm"] or ["dsm"]
      if (elements.length === 1) {
        return textSizes[elements[0]]; // no screen size specified, e.g. text-h6
      }
      return elements[0] + ":" + textSizes[elements[1]]; // screen size specified, e.g. md:text-h5
    })
    .join(" ");
};

/**
 * Types accept a string which may contain multiple types, e.g. for different screen sizes.
 * For example: "tsm md:dsm" actually means "text-body4 text-md:text-h5"
 * In this case it should return Header tag "h5" as the dominating type rather than a <p>
 *     since the tag is essentially a heading
 */
const getHeadingTag = type => {
  const types = type.split(" ");

  for (let i = 0; i < types.length; i++) {
    const elements = types[i].split(":"); // e.g. ["md", "dsm"] or ["dsm"]
    const textType = elements.length === 1 ? elements[0] : elements[1];
    if (Object.keys(headingTags).includes(textType)) {
      return headingTags[textType];
    }
  }

  return null;
};

Typography.propTypes = {};

export default Typography;
