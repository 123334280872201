import React from "react";
import useExamMarkingExistingSegments from "routes/Course/ExamWizard/ExamView/ExamMarking/useExamMarkingSegments/useExamMarkingExistingSegments";
import useExamMarkingSegmentNumbers from "routes/Course/ExamWizard/ExamView/ExamMarking/useExamMarkingSegments/useExamMarkingSegmentNumbers";
import useExamMarkingSegmentsKeyboardNavigation from "routes/Course/ExamWizard/ExamView/ExamMarking/useExamMarkingSegments/useExamMarkingSegmentsKeyboardNavigation";
import {message, Trans} from "Components";

const DEFAULT_SEGMENT = {
  localNumber: 1,
  number: 1,
  x1: null,
  y1: null,
  x2: null,
  y2: null,
  imageUrl: null,
  edited: true,
  suggested: false,
  saved: false,
  focused: true,
};

const useExamMarkingSegments = ({container, pdfUrl}) => {
  const [segments, setSegments] = React.useState([]);

  // Reset markings if the pdfUrl changes
  React.useEffect(() => {
    setSegments([]);
  }, [pdfUrl]);

  React.useEffect(() => {
    // Add the default segment if there are no segments
    if (segments.length === 0) {
      setSegments([
        {
          ...DEFAULT_SEGMENT,
        },
      ]);
    }
  }, [segments]);

  const addSegments = (newSegments = []) =>
    setSegments(prev => [...prev, ...newSegments]);

  const createSegment = () => {
    const lastSegment = segments[segments.length - 1];

    if (currentSegmentIsEmpty) {
      // The last segment is empty, so we don't need to create a new one
      message.warning(
        <Trans>Please mark current segment before adding a new one</Trans>
      );
      return;
    }

    if (lastSegment.y1 !== null) {
      const newSegment = {
        ...DEFAULT_SEGMENT,
        focused: false,
        localNumber: segments.length + 1,
        number: segments.length + 1,
        y1: lastSegment.y2 || 0,
      };

      addSegments([newSegment]);
      // Set the new segment focused:
      setSegmentFocus(newSegment);
    }
  };

  const updateCurrentSegment = updatedSegment => {
    setSegments(prev => {
      return prev.map(segment => {
        // Find the segment in the state based on the segment number
        if (segment.focused) {
          return {
            ...segment,
            ...updatedSegment,
          };
        }

        return segment;
      });
    });
  };

  const clearSegmentMarking = updatedSegment => {
    setSegments(prev => {
      return prev.map(s => {
        // Current segment
        if (s.localNumber === updatedSegment.localNumber) {
          return {
            ...s,
            x1: null,
            y1: null,
            x2: null,
            y2: null,
            edited: true,
            saved: false,
          };
        }
        // All other
        return {
          ...s,
        };
      });
    });
  };

  const removeSegment = segment => {
    setSegments(prev => {
      return prev
        .filter(s => s.localNumber !== segment.localNumber)
        .map(s => ({
          ...s,
        }));
    });
  };

  const setSegmentFocus = (targetSegment, focused = true) => {
    setSegments(prev => {
      const newSegments = prev.map(s => {
        // Current segment
        if (s.localNumber === targetSegment.localNumber) {
          return {
            ...s,
            focused: focused,
          };
        }
        // All other
        return {
          ...s,
          focused: false,
        };
      });

      return newSegments;
    });
  };

  const fixSegmentNumbers = () => {
    setSegments(prev =>
      prev.map((segment, index) => ({
        ...segment,
        localNumber: segment.saved ? segment.number : index + 1,
      }))
    );
  };

  useExamMarkingExistingSegments({
    segments,
    setSegments,
    container,
  });

  useExamMarkingSegmentNumbers({
    segments,
    setSegments,
  });

  const sortedSegments = React.useMemo(() => {
    return segments.sort((a, b) => a.localNumber - b.localNumber);
  }, [segments]);

  //  we need to make the last segment focused if there is no focused segment
  React.useEffect(() => {
    if (segments.length > 0 && !segments.some(s => s.focused)) {
      console.log("No focused segment, setting the last segment focused");
      setSegmentFocus(segments[segments.length - 1], true);
    }
  }, [segments]);

  const {
    currentSegment,
    currentSegmentIndex,
    currentSegmentIsEmpty,
  } = React.useMemo(() => {
    const currentSegment = segments.find(segment => segment.focused);
    const currentSegmentIndex = segments.indexOf(currentSegment);
    const currentSegmentIsEmpty =
      (currentSegment &&
        currentSegment.x1 === null &&
        currentSegment.x2 === null &&
        currentSegment.y2 === null) ||
      false;

    return {
      currentSegment,
      currentSegmentIndex,
      currentSegmentIsEmpty,
    };
  }, [segments]);

  useExamMarkingSegmentsKeyboardNavigation({
    segments,
    setSegmentFocus,
    currentSegmentIndex,
    createSegment,
  });

  return {
    segments: sortedSegments,
    currentSegment,
    currentSegmentIsEmpty,
    createSegment,
    addSegments,
    setSegments,
    removeSegment,
    setSegmentFocus,
    updateCurrentSegment,
    clearSegmentMarking,
    fixSegmentNumbers,
  };
};

export default useExamMarkingSegments;
