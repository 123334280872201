import {useContext} from "react";
import {CourseContext} from "routes/Course/CourseContextProvider";
import {WorkboardContext} from "Components/Workboard/Workboard";
import {UserContext} from "UserContextProvider";
import useGetPersonalCoupon from "../../API/queries/user/userGetPersonalCoupons";

const useEventTracking = () => {
  const courseContext = useContext(CourseContext);
  let course = courseContext ? courseContext : {};
  let exerciseContext = useContext(WorkboardContext);
  let exercise = exerciseContext ? exerciseContext.exercise : {};
  let userContext = useContext(UserContext);
  let user = userContext ? userContext.user : {};

  const logCourseEvent = (event, args) => {
    if (args && "course" in args) {
      course = args.course;
      course.schoolName = course.school && course.school.name;
      delete args.course;
    }

    window.analytics.track(event, {
      course_name: course.name,
      school_name: course.schoolName,
      course_price: course.price / 100,
      course_id: course.id,
      created_at: new Date(),
      ...args,
    });
  };

  const logExerciseEvent = (event, args) => {
    if (args && "exercise" in args) {
      exercise = args.exercise;
      delete args.exercise;
    }
    window.analytics.track(event, {
      course_name: course.name,
      school_name: course.schoolName,
      course_price: course.price / 100,
      course_id: course.id,
      tag_names: exercise?.tag_names,
      exercise_author: exercise?.author,
      exercise_id: exercise?.id,
      exercise_difficulty: exercise?.score,
      exercise_date: exercise?.date,
      exercise_number: exercise?.number,
      exercise_evaluation: exercise?.selfEvaluation,
      ...args,
    });
  };

  const logUserProperties = user => {
    window.analytics.identify(user.id, {
      assistant: user.assistant,
      credits: user.credits / 100,
      email: user.email,
      created_at: user.createdAt,
      enrollment_year: user.enrollment_year,
      first_name: user.first_name,
      last_name: user.last_name,
      premium: user.premium,
      program_id: user.program && user.program.id,
      program_name: user.program && user.program.name,
      school_id: user.school && user.school.id,
      school_name: user.school && user.school.name,
      student_email: user.student_email,
      current_url: window.location.href,
      // personal_code: coupon,
      version: process.env.REACT_APP_VERSION,
    });
  };

  return {
    logCourseEvent,
    logExerciseEvent,
    logUserProperties,
  };
};

export default useEventTracking;
