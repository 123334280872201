import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

import {objectKeysSnakeToCamel} from "utilities/objects";

const SEARCH_COURSE = gql`
  query searchCourse(
    $query: String!
    $schoolId: ID
    $subjectId: ID
    $offset: Int
    $limit: Int
  ) {
    searchCourse(
      searchQuery: $query
      school_id: $schoolId
      subject_id: $subjectId
      offset: $offset
      limit: $limit
    ) {
      id
      name
      icon
      code
      price
      purchased
      chapters_count
      exercises_count
      exams_count
      averageRating
      school {
        id
        name
      }
      subject {
        id
        name
      }
    }
  }
`;

// Finding the searched for course:
const useSearchCourse = (
  filters = {
    schoolId: null,
    query: "",
    subjectId: null,
    offset: 0,
    limit: 10,
  }
) => {
  const [canLoadMore, setCanLoadMore] = React.useState(true);

  const {data, loading, error, fetchMore} = useQuery(SEARCH_COURSE, {
    variables: {
      ...filters,
    },
  });

  // loadMore callback for pagination:
  const loadMore = () => {
    fetchMore({
      variables: {
        offset: data && data.searchCourse.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) {
          setCanLoadMore(false);
          return prev;
        }

        return Object.assign({}, prev, {
          searchCourse: [
            ...new Set([...prev.searchCourse, ...fetchMoreResult.searchCourse]),
          ],
        });
      },
    });
  };

  if (error) {
    console.log("error in useSearchCourse:", error);
  }

  let courses = [];

  if (data) {
    courses = objectKeysSnakeToCamel(data.searchCourse);
  }

  return {
    courses,
    loading,
    loadMore,
    canLoadMore,
  };
};

export default useSearchCourse;
