import React from "react";
import Icon from "Components/_Elements/Icon/Icon";
import Typography from "Components/_Elements/Typography/Typography";
import ExerciseSolutionHintsPromptSuggestions from "Components/Workboard/ExerciseSolutionHints/ExerciseSolutionHintsDisplay/ExerciseSolutionHintsPromptSuggestions";
import {AnimatePresence, motion} from "framer-motion";

const GptDisplayWorkboardHeader = ({}) => {
  const {chatActive} = React.useContext(GPTContext);

  return (
    <AnimatePresence>
      {!chatActive && (
        <motion.div
          initial={{opacity: 0, height: 0}}
          animate={{opacity: 1, height: "auto"}}
          exit={{opacity: 0, height: 0}}
          className="flex flex-col lg:flex-row gap-4 justify-between items-start"
        >
          <div className="flex gap-2 grow shrink-0  items-center">
            <Icon icon="fas fa-message-bot" color="text-gray-500" size="md" />
            <Typography type="tlg" weight="sb" color="text-gray-500">
              Kollin GPT
            </Typography>
          </div>

          <div className="w-full grow lg:w-fit lg:grow-0 self-end lg:self-start">
            <ExerciseSolutionHintsPromptSuggestions badgeSize="sm" />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default GptDisplayWorkboardHeader;
