import React from "react";
import GPTProvider from "../GPTProvider";
import CourseChatGPTResults from "../../../Widgets/Course/CourseChatGPT/CourseChatGPTResults";
import GPTPromptInput from "../GPTPromptInput";
import ExerciseSolutionHintsPromptSuggestions from "../../Workboard/ExerciseSolutionHints/ExerciseSolutionHintsDisplay/ExerciseSolutionHintsPromptSuggestions";
import GPTActions from "Components/GPT/GPTActions";
import {useTranslation} from "react-i18next";
import GptDisplayWorkboardHeader from "Components/GPT/GPTDisplay/GPTDisplayWorkboardHeader";

const GPTDisplayWorkboard = ({showHeader = false, ...props}) => {
  const {t: trans} = useTranslation();
  return (
    <GPTProvider {...props}>
      {showHeader && <GptDisplayWorkboardHeader />}

      <GPTActions saveFormat="note" />

      <CourseChatGPTResults
        showAvatar={true}
        resultsContainerHeight="h-[24rem]"
      />

      <GPTPromptInput
        placeholder={trans("Can you help me with this exercise?")}
      />

      {!showHeader && <ExerciseSolutionHintsPromptSuggestions />}
    </GPTProvider>
  );
};

export default GPTDisplayWorkboard;
