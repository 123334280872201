import React from "react";
import {ErrorBoundary} from "Components";
import useWorkboardWidget from "Components/Workboard/WorkboardWidgets/useWorkboardWidget";
import WorkboardWidgetTitle from "Components/Workboard/WorkboardWidgets/WorkboardWidgetTitle";
import {motion} from "framer-motion";
import classnames from "classnames";

const WorkboardWidget = ({
  title,
  name,
  icon,
  children,
  collapsible = true,
  defaultOpen = true,
  isNew = false,
  compact = false,
  action,
}) => {
  const {
    open,
    handleToggle,
    badgeContent,
    setBadgeContent,
    hidden,
    setHidden,
  } = useWorkboardWidget({
    collapsible,
    defaultOpen,
    title,
    name,
  });

  return (
    <ErrorBoundary hideOnError={true}>
      <div
        className={classnames(
          hidden
            ? "hidden"
            : "border-t border-gray-200  group background dark:border-gray-700 transition-all duration-100 ease-in-out ",
          collapsible && "hover:bg-gray-25 dark:hover:bg-gray-800",
          compact ? "py-2 px-4" : "py-4 px-4 "
        )}
      >
        <div className={`py-2 accordion_item ${open ? "active" : ""}`}>
          <WorkboardWidgetTitle
            title={title}
            icon={icon}
            badgeContent={badgeContent}
            collapsible={collapsible}
            open={open}
            handleToggle={handleToggle}
            isNew={isNew}
            action={action}
          />

          <motion.div
            className={open ? "" : "overflow-hidden"}
            initial={{
              height: open ? "auto" : 0,
            }}
            animate={{
              height: open ? "auto" : 0,
            }}
          >
            <div className="answer py-2">
              {React.cloneElement(children, {
                setCount: setBadgeContent,
                setHidden: setHidden,
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default WorkboardWidget;
