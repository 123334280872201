import React from "react";
import {StringParam, useQueryParams} from "use-query-params";

const useAccountUrlState = () => {
  const [urlState, setUrlState] = useQueryParams({
    tab: StringParam,
  });

  const currentTab = urlState.tab;

  const onTabChange = tabKey => {
    setUrlState({tab: tabKey});
  };

  return {
    currentTab,
    onTabChange,
  };
};

export default useAccountUrlState;
