import React from "react";
import {Icon, SafeText} from "../index";
import WidgetFeedback from "Components/Widget/WidgetFeedback/WidgetFeedback";
import classnames from "classnames";

const WidgetHeader = ({
  title,
  description,
  icon,
  iconProps = {},
  widgetName,
  feedbackEnabled = true,
}) => {
  return (
    <div className="flex justify-between items-start mb-4">
      <div
        className={classnames(
          "flex  justify-between gap-2",
          description ? "items-start" : "items-center"
        )}
      >
        {icon && (
          <Icon
            fixedWidth
            icon={icon}
            size="lg"
            color="text-secondary-light"
            {...iconProps}
          />
        )}
        <div>
          <SafeText type="tlg" weight="md" Tag="Trans">
            {title}
          </SafeText>
          <SafeText
            type="tsm"
            weight="normal"
            color="text-gray-light"
            className="mt-1"
          >
            {description}
          </SafeText>
        </div>
      </div>
      {feedbackEnabled && <WidgetFeedback widgetName={widgetName} />}
    </div>
  );
};

export default WidgetHeader;
