import React from "react";
import {Button, Link, Trans} from "Components";
import EarnCreditsSection from "Widgets/Credits/EarnCredits/EarnCreditsSection";
import StudentVerification from "Widgets/User/StudentVerification/StudentVerification";
import AccountCreditsPersonalCode from "routes/Account/AccountCredits/AccountCreditsPersonalCode";
import EarnCreditsWelcomeGift from "Widgets/Credits/EarnCredits/EarnCreditsWelcomeGift/EarnCreditsWelcomeGift";
import CourseReviewContainer from "routes/Course/CourseHome/CourseReviewContainer";
import EarnCreditsCourseReview from "Widgets/Credits/EarnCredits/EarnCreditsCourseReview/EarnCreditsCourseReview";
import CDN from "utilities/constants/CDN";

const EarnCredits = () => {
  // Get the courseId directly from the URL because the modal is rendered outside the router
  const courseContext = React.useContext(window.CourseContext);

  return (
    <div className="overflow-y-scroll">
      <EarnCreditsWelcomeGift />

      <EarnCreditsSection
        title={<Trans>Refer a friend to Kollin</Trans>}
        reward={50}
        imageSrc={CDN + "/images/penguin-telephone.jpeg"}
        description={
          <Trans>
            Share your personal code with a friend who is not on Kollin and earn
            both 50 credits
          </Trans>
        }
        defaultExpanded
      >
        <div className="-my-4">
          <AccountCreditsPersonalCode
            showTitle={false}
            showSubTitle={false}
            orientation="vertical"
          />
        </div>
      </EarnCreditsSection>

      {courseContext && (
        <EarnCreditsSection
          defaultExpanded
          title={<Trans>Upload exams or solutions in the course</Trans>}
          reward={50}
          description={
            <Trans>
              You get 50 credits per document you upload. We will validate and
              top your account within 24h.
            </Trans>
          }
          imageSrc={CDN + "/images/penguin-files-archive.jpeg"}
        >
          <Link
            asButton
            to={courseContext.getCourseLink("upload")}
            buttonType="gray"
            iconRight="arrow-right"
            trackParams={{
              event: "credits_modal_upload_exams",
            }}
          >
            <Trans>Upload exams</Trans>
          </Link>
        </EarnCreditsSection>
      )}

      {courseContext && <EarnCreditsCourseReview />}

      <EarnCreditsSection
        defaultExpanded
        title={<Trans>Send us feedback!</Trans>}
        reward={10}
        description={
          <Trans>
            Fill in our feedback form about this course and get 10 credits when
            we have reviewed your feedback, within 24h.
          </Trans>
        }
        imageSrc={CDN + "/images/penguin-feedback-form.jpeg"}
      >
        <Button
          onClick={() => {
            window.open("https://forms.gle/T45CCZsDQGtpBcbi6", "_blank");
            window.analytics.track("credits_modal_feedback_clicked", {
              screen_name: window.location.pathname,
            });
          }}
          iconRight="arrow-right"
        >
          <Trans>Send feedback</Trans>
        </Button>
      </EarnCreditsSection>
    </div>
  );
};

export default EarnCredits;
