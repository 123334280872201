import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  getDescriptionNode,
  getNameNode,
  getProgramAndYear,
  getSrc,
} from "Components/_Elements/Avatar/Avatar.helpers";
import {Badge, Logo} from "Components/_Elements/index";
import {Trans} from "Components/index";

export const sizes = ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl"];

const sizeMapping = {
  xs: "w-6 h-6",
  sm: "w-8 h-8",
  md: "w-10 h-10",
  lg: "w-12 h-12",
  xl: "w-14 h-14",
  "2xl": "w-16 h-16",
  "3xl": "w-24 h-24",
  "4xl": "w-32 h-32",
  "5xl": "w-40 h-40",
};

const Avatar = ({
  src = null,
  user,
  alt,
  size = "sm",
  onClick = null,
  name = null,
  description,
  showProgramInfo = false,
  fluid = false,
  showRole = true,
  ...props
}) => {
  let avatarImageSrc = React.useMemo(() => getSrc(src, user), [src, user]);

  const programAndYearNode = React.useMemo(
    () => getProgramAndYear(user, size),
    [user]
  );

  const ring = React.useMemo(() => getRing(user, showRole), [user]);

  return (
    <div
      className={classnames(
        "flex items-center relative",
        fluid && "w-full",
        name && "gap-2"
      )}
    >
      <div
        onClick={onClick}
        className={classnames(
          "rounded-full bg-gray-100 aspect-square overflow-clip",
          sizeMapping[size],
          "bg-cover",
          "bg-center",
          "bg-clip-padding",
          {"cursor-pointer": onClick !== null},
          ring,
          props.className
        )}
        style={{
          backgroundImage: `url(${avatarImageSrc})`,
        }}
      />
      <div className="overflow-hidden">
        <span className="w-full inline-flex gap-2 items-center truncate ...">
          {getNameNode(name, size)}
          {showProgramInfo ? programAndYearNode : null}
        </span>

        {getDescriptionNode(description, size)}
      </div>
    </div>
  );
};

const getRing = (user, showRole) => {
  if (!user) return null;
  if (!showRole) return null;

  let ring = "ring ring-2 ring-offset-1 ";
  if (user?.admin) return ring + " ring-primary-400";

  if (user?.premium) return ring + " ring-[#FFCE36]";

  return null;
};

Avatar.propTypes = {
  /**
   * The size of the avatar
   */
  size: PropTypes.oneOf(sizes),

  /**
   * The address of the image for an image avatar or image element
   */
  src: PropTypes.string,

  /**
   * The user object to get the avatar from, optionally pass this instead of src
   */
  user: PropTypes.object,

  /**
   * The alt text for the image
   */
  alt: PropTypes.string,
  /**
   * The name of the user
   */
  name: PropTypes.node,
  /**
   * Optional description
   */
  description: PropTypes.node,
};

export default Avatar;
