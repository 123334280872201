import React from "react";
import useVersionUpdateBanner from "Components/VersionUpdateBanner/useVersionUpdateBanner";
import Icon from "Components/_Elements/Icon/Icon";
import Button from "Components/_Elements/Button/Button";

const VersionUpdateBanner = () => {
  const {user} = React.useContext(UserContext);

  if (!user?.id) return null;

  const {version, handleToggle} = useVersionUpdateBanner();

  return (
    <div
      className="p-2 bg-primary-600 w-screen text-white flex gap-2 items-center justify-center"
      style={{background: "linear-gradient(135deg, #2C385A 0%, #491C96 100%)"}}
    >
      <Icon icon="sparkles" size="md" color="text-amber-300" />

      <Button
        name="version-update"
        size="xs"
        type="link"
        inverted
        onClick={handleToggle}
      >
        Aktivera nya versionen av Kollin
      </Button>
      <Icon icon="sparkles" size="md" color="text-amber-300" />
    </div>
  );
};

export default VersionUpdateBanner;
