import React from "react";

import {LAMBDA_BASE_URL} from "API/lambdas/index";
import CryptoJS from "crypto-js";

const useLambda = ({
  lambdaName,
  args = {},
  dependencies = [],
  autoRun = true,
  cachePeriod = 30, // in seconds
}) => {
  const [state, setState] = React.useState({
    loading: false,
    error: null,
    data: null,
  });

  const runLambda = async args => {
    setState({
      loading: true,
      error: null,
      data: null,
    });

    const params = new URLSearchParams(args).toString();
    const cacheKey = getCacheKey({lambdaName, args, cachePeriod});

    if (cachePeriod > 0) {
      const cachedResult = await getCachedResult(cacheKey, cachePeriod);

      if (cachedResult) {
        setState({
          loading: false,
          error: null,
          data: cachedResult,
        });

        return;
      }
    }

    try {
      const response = await fetch(
        `${LAMBDA_BASE_URL}/${lambdaName}?${params}`
      );

      const data = await response.json();

      writeCache(cacheKey, data);

      setState({
        loading: false,
        error: null,
        data: typeof data === "string" ? JSON.parse(data) : data,
      });
    } catch (error) {
      setState({
        loading: false,
        error,
        data: null,
      });
    }
  };

  React.useEffect(() => {
    if (autoRun) {
      runLambda(args);
    }
  }, [...dependencies, ...Object.values(args)]);

  return {...state, run: runLambda};
};

const getCacheKey = ({lambdaName, args = {}, cachePeriod}) => {
  const params = new URLSearchParams(args).toString();
  const key = CryptoJS.MD5(`${lambdaName}?${params}`).toString();

  return key;
};

const getCachedResult = (cacheKey, cachePeriod) => {
  const cachedResult = localStorage.getItem(cacheKey);

  if (!cachedResult) {
    return null;
  }

  const {timestamp, data} = JSON.parse(atob(cachedResult));

  if (Date.now() - timestamp > cachePeriod * 1000) {
    localStorage.removeItem(cacheKey);
    return null;
  }

  return data;
};

const writeCache = (cacheKey, data) => {
  const dataToCache = JSON.stringify({
    timestamp: Date.now(),
    data,
  });
  // Store as base64 to avoid encoding issues
  localStorage.setItem(cacheKey, btoa(dataToCache));
};

export default useLambda;
