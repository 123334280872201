import React from "react";
import TableCell from "Components/_Elements/Table/TableCell";
import classnames from "classnames";
import {rowSpacingMap} from "Components/_Elements/Table/Table.constants";

const TableRow = ({
  columnsTransposed,
  cellClassNames,
  rowClassNames,
  emptyRow,
  rowSpacing = "md",
  ...row
}) => {
  return (
    <tr className={classnames(rowClassNames, !emptyRow && "odd:surface")}>
      {Object.keys(columnsTransposed).map((key, index) => {
        const renderFunction = columnsTransposed[key].render;
        const dataIndex = columnsTransposed[key].dataIndex;

        const cellClasses = classnames(
          cellClassNames,
          rowSpacingMap[rowSpacing],
          "first:rounded-l-lg last:rounded-r-lg",
          emptyRow && "opacity-0 content-hidden"
        );

        if (emptyRow)
          return (
            <td className={cellClasses} key={index}>
              -
            </td>
          );

        let cellValue = getCellValue(row, dataIndex);

        // console.log(key, cellValue);

        if (cellValue === undefined || cellValue === null) {
          cellValue = "-";
        }

        return (
          <TableCell
            {...columnsTransposed[key]} // Column props
            row={row}
            key={index}
            cellClassNames={cellClasses}
            renderFunction={renderFunction}
            value={cellValue}
          />
        );
      })}
    </tr>
  );
};

const getCellValue = (row, dataIndex) => {
  if (typeof dataIndex == "string" && dataIndex?.split(".").length > 1) {
    return (
      dataIndex.split(".").reduce((acc, key) => {
        return acc[key];
      }, row) || null
    );
  }

  return row[dataIndex];
};

export default TableRow;
