import React from "react";
import useGetCurrentRoute from "utilities/hooks/course/useGetCurrentRoute";
import {courseRoutes} from "routes/Course/courseRoutesList";
import {CourseParamsContext} from "routes/Course/CourseRouter";
import useGetExerciseSolution from "API/queries/solution/useGetExerciseSolution";
import useGetTextData from "API/queries/textData/useGetTextData";
import useCurrentParams from "utilities/hooks/useCurrentParams";
import useGPTMetadataExercise from "Components/GPT/hooks/useGPTMetadata/useGPTMetadataExercise";

const useGPTSystemMessages = ({addMessage, removeSystemMessage}) => {
  const [systemMessageAdded, setSystemMessageAdded] = React.useState(false);

  const setSystemMessage = ({content, ...otherProperties}) => {
    // If system message is already added, remove it
    if (systemMessageAdded && content === null) {
      // Remove the existing system message(s)

      removeSystemMessage();

      return;
    }

    addMessage({
      id: new Date().getTime() - 2,
      content,
      role: "system",
      hidden: true, // Hide the system message
      loading: false,
      processed: true,
      ...otherProperties,
    });

    setSystemMessageAdded(true);
  };

  return {
    loading: false,
    setSystemMessage,
  };
};

export default useGPTSystemMessages;
