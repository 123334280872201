import React from "react";

const isReactElement = (value, elementName = null) => {
  const isElement = React.isValidElement(value);
  if (elementName === null) {
    return isElement;
  }

  return isElement && value.type === elementName;
};

export default isReactElement;
