import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

export const GET_CURRENT_CREDIT_TRANSACTIONS = gql`
  query getCurrentUserCreditTransactions {
    currentUser {
      id
      creditTransactions {
        id
        amount
        description
        relatedObjectId
        relatedObjectType
        createdAt
        updatedAt
      }
    }
  }
`;

const useGetCurrentUserCreditTransactions = () => {
  let creditTransactions = [];

  const { data, loading, error } = useQuery(GET_CURRENT_CREDIT_TRANSACTIONS);

  if (error) {
    console.error(`Error in useGetCurrentUserCreditTransactions: ${error}`);
  }

  if (data && data.currentUser) {
    creditTransactions = data.currentUser.creditTransactions || []; // Settings may be null
    // Go through each setting and initialize it if it doesn't exist
  }

  return [creditTransactions, loading];
};

export default useGetCurrentUserCreditTransactions;
