import React from "react";
import AuthLayoutWrapper from "routes/Auth/AuthLayout";
import AuthLayout from "routes/Auth/AuthLayout";
import useSignUpState from "./useSignUpState";
import {Button, Input, Trans} from "Components";
import SignupCouponBanner from "routes/Auth/SignUp/SignupCouponBanner";
import SignupConsentCheckbox from "routes/Auth/SignUp/SignupConsentCheckbox";
import classnames from "classnames";
import useAuthResponseHandler from "routes/Auth/useAuthResponseHandler";
import AuthError from "routes/Auth/AuthError";

/**
 * Sign up component
 * @param showLoginLink {boolean} - If true, the login link will be shown
 * @param wrapper {boolean} - If true, the component will be wrapped in an AuthLayout, otherwise it will be in a div with no layout styling
 * @param showTitle {boolean} - If true, the title "Sign up" will be shown
 * @param onComplete {function} - Function to run when the user has signed up - Default behaviour is to redirect to: location.state.nextUrl || "/onboarding"
 * @param grid {boolean} - If true, the input fields will be placed in a grid otherwise they will be placed in a flex column
 * @param onLoginClick {function} - Function to run when the user clicks the login link, default behaviour is to redirect to: /login if null
 * @param transparentInput {boolean} - If true, the input fields will be transparent
 * @param source {string} -  Where the user signed up from (IMPORTANT TO SET)
 * @param requireName {boolean} - Require first and last name for signup or not
 * @param footerPosition {string} - Where to place the footer (top or bottom) containing the Facebook button
 * @param quickSignup {boolean} - If true, only ask for name and email and no password. User receives a password reset email instead
 * @param userProperties  {object} - Additional user properties to right after signup on the user (using updateCurrentUser)
 * @param emailExtension {string} - If set, the user will be forced to use this email extension
 * @returns {JSX.Element}
 * @constructor
 */

const SignUp = ({
  showLoginLink = true,
  showTitle = true,
  onComplete = null,
  grid = false,
  onLoginClick,
  transparentInput = true,
  source = null,
  requireName = true,
  footerPosition = "bottom",
  quickSignup = false,
  userProperties = null,
  emailExtension = null,
  friendCouponField = false,
  socialButtonsCompact = false,
  wrapper = AuthLayout,
}) => {
  const {handleAuthResponse, error} = useAuthResponseHandler({
    onComplete,
    redirectLink: "/onboarding",
    trackingParams: {
      event: "signup",
      source,
    },
  });

  const {
    values,
    preferredEmailExtension,
    handleChange,
    errors,
    loading,
    setFormValue,
    submit,
    handleLowercaseInputChange,
    handleComplete,
    handleLoginClick,
  } = useSignUpState({
    handleAuthResponse,
    onComplete,
    requireName,
    onLoginClick,
    source,
    quickSignup,
    userProperties,
    emailExtension,
  });

  const Wrapper = wrapper || "div";

  return (
    <Wrapper>
      {showTitle && (
        <Trans type="dsm" weight="bold" className="text-gray-900 mb-4">
          Sign up
        </Trans>
      )}

      {footerPosition === "top" && (
        <div className="mt-4">
          <SignupFooter
            socialButtonsCompact={socialButtonsCompact}
            handleComplete={handleComplete}
            showLoginLink={showLoginLink}
            handleLoginClick={handleLoginClick}
            handleAuthResponse={handleAuthResponse}
          />
        </div>
      )}
      <SignupCouponBanner />

      <AuthError error={error} handleLoginClick={handleLoginClick} />
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
        className={classnames(
          "mt-4",
          grid ? "grid grid-cols-1 md:grid-cols-2 gap-4" : "flex flex-col gap-2"
        )}
      >
        {requireName && !quickSignup && (
          <Input
            id={"firstName"}
            name={"firstName"}
            type={"text"}
            required={true}
            label={<Trans>First name</Trans>}
            value={values.firstName}
            error={errors.firstName}
            onChange={handleChange}
            autoComplete="given-name"
            fluid
            transparent={transparentInput}
          />
        )}

        {requireName && !quickSignup && (
          <Input
            id={"lastName"}
            name={"lastName"}
            type={"text"}
            required={true}
            label={<Trans>Last name</Trans>}
            value={values.lastName}
            error={errors.lastName}
            onChange={handleChange}
            autoComplete="family-name"
            fluid
            transparent={transparentInput}
          />
        )}

        {quickSignup && (
          <Input
            id={"name"}
            name={"firstName"}
            type={"text"}
            required={true}
            label={<Trans>Name</Trans>}
            value={values.firstName}
            error={errors.lastName || errors.firstName}
            onChange={handleChange}
            autoComplete="name"
            fluid
            transparent={transparentInput}
          />
        )}

        <Input
          id={"email"}
          name={"email"}
          type={"email"}
          required={true}
          label={<Trans>Email</Trans>}
          value={values.email}
          error={errors.email}
          onChange={handleLowercaseInputChange}
          autoComplete="email"
          transparent={transparentInput}
          after={preferredEmailExtension ? `${emailExtension}` : null}
        />
        {!quickSignup && (
          <Input
            id={"password"}
            name={"password"}
            type={"password"}
            required={true}
            label={<Trans>Password</Trans>}
            value={values.password}
            error={errors.password}
            onChange={handleChange}
            transparent={transparentInput}
          />
        )}

        {friendCouponField && (
          <Input
            id={"coupon"}
            name={"coupon"}
            required={false}
            label={<Trans>Kupongkod</Trans>}
            value={values.friendCoupon}
            error={errors.friendCoupon}
            onChange={handleChange}
            transparent={transparentInput}
          />
        )}
      </form>
      <div
        className={classnames(
          "flex flex-col gap-4 my-2",
          grid ? "md:col-span-2" : "w-full"
        )}
      >
        <SignupConsentCheckbox
          onChange={consent => setFormValue({consent})}
          checked={values.consent}
        />

        <Button
          loading={loading}
          type="primary"
          name="submit"
          fluid
          disabled={!values.consent}
          onClick={e => {
            e.preventDefault();
            window.analytics.track("signup_submit_clicked");
            submit();
          }}
          error={Object.entries(errors).length}
        >
          <Trans>Sign up</Trans>
        </Button>
      </div>

      {footerPosition === "bottom" && (
        <SignupFooter
          socialButtonsCompact={socialButtonsCompact}
          handleComplete={handleComplete}
          showLoginLink={showLoginLink}
          handleLoginClick={handleLoginClick}
          handleAuthResponse={handleAuthResponse}
        />
      )}
    </Wrapper>
  );
};

const SignupFooter = ({
  handleComplete,
  showLoginLink,
  handleLoginClick,
  handleAuthResponse,
  socialButtonsCompact,
}) => {
  return (
    <AuthLayoutWrapper.Footer
      onComplete={handleComplete}
      socialLoginProps={{
        action: "signup",
        facebookButtonPrompt: <Trans>Sign up with Facebook</Trans>,
        googleButtonPrompt: <Trans>Sign up with Google</Trans>,
        handleAuthResponse,
        socialButtonsCompact,
      }}
    >
      {showLoginLink && (
        <div className="flex gap-2 items-center">
          <Trans inline>Already have an account?</Trans>
          <Button onClick={handleLoginClick} type="link">
            <Trans>Login</Trans>
          </Button>
        </div>
      )}
    </AuthLayoutWrapper.Footer>
  );
};

export default SignUp;
