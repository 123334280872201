import React from "react";
import {UserContext} from "UserContextProvider";
import {ExerciseViewContext} from "../ExerciseView";
import ExerciseViewToolbarSolutionButton from "./ExerciseViewToolbarSolutionButton";
import {Button, ErrorBoundary, Trans} from "Components";
import {motion} from "framer-motion";
import {WorkboardLayoutTransition} from "Components/Workboard/Workboard";
import {isMobile} from "react-device-detect";
import ExerciseViewForumToggleButton from "../ExerciseViewForum/ExerciseViewForumToggleButton";

const ExerciseViewToolbar = ({
  toggleFocusMode,
  focusMode,
  allowFocusMode,
  loading = false,
}) => {
  const {id: exerciseId, showUserTools} = React.useContext(ExerciseViewContext);
  const {user} = React.useContext(UserContext);

  let BookmarkIconClasses = "far fa-bookmark";
  if (!user) BookmarkIconClasses += "";

  return (
    <motion.div
      className="w-full flex justify-between md:flex-row md:items-center bg-slate-100 rounded-xl justify-between p-4 flex-wrap"
      // key="exercise-toolbar"
      // layout="workboard"
      transition={WorkboardLayoutTransition}
    >
      <div className="flex gap-4 flex justify-between w-full">
        <ErrorBoundary>
          <ExerciseViewToolbarSolutionButton />
        </ErrorBoundary>
        <div className="flex gap-4">
          {allowFocusMode && !isMobile && (
            <Button
              onClick={toggleFocusMode}
              type="link"
              size="lg"
              hint={<Trans>Focus mode</Trans>}
              icon={
                focusMode ? "fa-regular fa-compress" : "fa-regular fa-expand"
              }
            />
          )}
          {/*<ErrorBoundary>*/}
          {/*  <ExerciseViewToolbarBookmarkButton exerciseId={exerciseId} />*/}
          {/*</ErrorBoundary>*/}
        </div>

        <ErrorBoundary>
          <ExerciseViewForumToggleButton />
        </ErrorBoundary>
      </div>
    </motion.div>
  );
};

export default ExerciseViewToolbar;
