/**
 * Helper function to provide singular or plural word depending on the value
 *
 * @param value - @type {number}
 * @param singular - @type {string}
 * @param plural - @type {string}
 *
 * @returns Translated word in sigular or plural form
 */

import i18n from "i18n";

export default (value, singular, plural) => {
  if (value === 1) {
    return i18n.t(`${singular}`);
  } else {
    return i18n.t(`${plural}`);
  }
};

export const labels = {
  exam: [i18n.t("exam"), i18n.t("exams")],
  review: [i18n.t("review"), i18n.t("reviews")],
  "theory section": [i18n.t("theory section"), i18n.t("theory sections")],
  student: [i18n.t("student"), i18n.t("students")],
  exercise: [i18n.t("exercise"), i18n.t("exercises")],
  subtopic: [i18n.t("subtopic"), i18n.t("subtopics")],
  topic: [i18n.t("topic"), i18n.t("topics")],
  reply: [i18n.t("reply"), i18n.t("replies")],
  day: [i18n.t("day"), i18n.t("days")],
};

export const textSingularPluralTranslated = (value, key) => {
  try {
    if (value == 1) {
      return `${value} ${labels[key][0]}`;
    }
    return `${value} ${labels[key][1]}`;
  } catch (error) {
    return `${value} ${i18n.t(key)}`;
  }
};
