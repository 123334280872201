import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {CourseContext} from "routes/Course/CourseContextProvider";
import formatExercise from "utilities/exerciseUtilities/formatExercise";

const GET_EXERCISE = gql`
  query getExercises(
    $ids: [ID!]
    $courseId: ID!
    $includeTags: Boolean! = false
    $includeSelfEvaluation: Boolean! = false
    $includeBookmarked: Boolean! = false
    $includeNotes: Boolean! = false
    $includeStats: Boolean! = false
    $includeSource: Boolean! = false
    $includeModule: Boolean! = false
  ) {
    getExercises(ids: $ids) {
      id
      number
      score
      published
      imageUrl
      tags @include(if: $includeTags) {
        id
        name
        module(course_id: $courseId) @include(if: $includeModule) {
          id
          name
        }
      }
      bookmarked @include(if: $includeBookmarked)
      notes @include(if: $includeNotes) {
        id
        text
      }
      self_evaluation @include(if: $includeSelfEvaluation) {
        id
        score
        date
      }
      stats @include(if: $includeStats) {
        total
        easy
        medium
        hard
        nightmare
      }
      source @include(if: $includeSource) {
        id
        date
        author
      }
    }
  }
`;

const useGetExercises = ({
  ids,
  includeTags = false,
  includeSelfEvaluation = false,
  includeBookmarked = false,
  includeNotes = false,
  includeStats = false,
  includeSource = false,
  includeModule = false,
}) => {
  const {courseId} = React.useContext(CourseContext);

  let exercises = [];

  const {data, loading, error} = useQuery(GET_EXERCISE, {
    variables: {
      ids,
      courseId,
      includeTags,
      includeSelfEvaluation,
      includeBookmarked,
      includeNotes,
      includeStats,
      includeSource,
      includeModule,
    },
    fetchPolicy: "cache-first",
    context: {
      batch: true,
    },
  });

  if (error) {
    console.log("Error in useGetExercises, ", error);
  }

  if (data && data.getExercises) {
    exercises = data.getExercises.map(ex => formatExercise(ex));
  }

  return [exercises, loading];
};

export default useGetExercises;
