import React from "react";
import { useHistory } from "react-router-dom";
import { isEventFromBody } from "utilities/hooks/useKeyboardShortcuts";

const useExerciseListKeyboardActions = ({
  sortedExercises,
  selectedExerciseId,
  getExerciseLink,
}) => {
  const history = useHistory();

  // Find the index of the default selected exercise to set the initial state
  const defaultSelectedExerciseIndex = findIndexOfExercise(
    sortedExercises,
    selectedExerciseId
  );

  // Local state for the selected exercise (index in the sortedExercises array)
  const [selectedExerciseIndex, setSelectedExerciseIndex] = React.useState(
    defaultSelectedExerciseIndex
  );

  // When selectedExerciseId changes from the outside, we need to update the selectedExerciseIndex
  React.useEffect(() => {
    if (
      selectedExerciseId &&
      sortedExercises[selectedExerciseIndex] !== undefined &&
      selectedExerciseId !== sortedExercises[selectedExerciseIndex].id // To prevent infinite loop
    ) {
      setSelectedExerciseIndex(
        findIndexOfExercise(sortedExercises, selectedExerciseId)
      );
    }
  }, [selectedExerciseId]);

  // When sorted list changes but selectedExerciseId is the same, we need to update the index locally
  React.useEffect(() => {
    const actualIndex = findIndexOfExercise(
      sortedExercises,
      selectedExerciseId
    );
    if (actualIndex !== selectedExerciseIndex) {
      setSelectedExerciseIndex(actualIndex);
    }
  }, [sortedExercises]);

  // When the state changes, update the URL
  React.useEffect(() => {
    if (
      selectedExerciseId &&
      selectedExerciseIndex !== null &&
      sortedExercises[selectedExerciseIndex] !== undefined
    ) {
      // Update the URL when the selected exercise changes in the state
      const newSelectedExerciseId = sortedExercises[selectedExerciseIndex].id;
      if (newSelectedExerciseId !== selectedExerciseId) {
        history.push(getExerciseLink(newSelectedExerciseId));
      }
    }
  }, [selectedExerciseIndex]);

  // Handle keyboard navigation
  const handleKeyDown = React.useCallback(
    event => {
      if (!isEventFromBody(event)) {
        return;
      }

      if (event.key === "ArrowLeft") {
        setSelectedExerciseIndex(selectedExerciseIndex => {
          const prevIndex = Math.max(0, selectedExerciseIndex - 1);
          if (prevIndex !== selectedExerciseIndex) return prevIndex;
          return selectedExerciseIndex; // Fallback to the current index to avoid setting it to undefined
        });
      } else if (event.key === "ArrowRight") {
        setSelectedExerciseIndex(selectedExerciseIndex => {
          const nextIndex = Math.min(
            sortedExercises.length - 1,
            selectedExerciseIndex + 1
          );
          if (selectedExerciseIndex !== nextIndex) return nextIndex;
          return selectedExerciseIndex; // Fallback to the current index to avoid setting it to undefined
        });
      }
    },
    [selectedExerciseIndex, sortedExercises.length]
  );

  // Add event listener, the event listener must be reset when the sortedExercises list change
  // Otherwise the event listener will be called with the old sortedExercises list
  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return {
    selectedExerciseIndex,
    handleKeyDown,
  };
};

const findIndexOfExercise = (sortedExercises, selectedExerciseId) =>
  sortedExercises.findIndex(exercise => exercise.id === selectedExerciseId);

export default useExerciseListKeyboardActions;
