import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GET_EXERCISE_TAG_SUGGESTIONS } from "API/queries/exercise/useGetExerciseTagSuggestions";
import { GET_EXERCISE } from "API/queries/exercise/useGetExercise";

export const CATEGORISE_EXERCISE = gql`
  mutation categoriseExercise($exerciseId: ID!, $tagId: ID!) {
    categoriseExercise(exercise_id: $exerciseId, tag_id: $tagId) {
      ok
      error
    }
  }
`;

const useCategoriseExerciseMutation = (exerciseId, courseId) => {
  const [categoriseExercise, { loading, data, error }] = useMutation(
    CATEGORISE_EXERCISE,
    {
      onError: error => {
        console.log(error);
      },
      refetchQueries: [
        {
          query: GET_EXERCISE_TAG_SUGGESTIONS,
          variables: { exerciseId, courseId, loggedIn: true },
        },
        {
          query: GET_EXERCISE,
          variables: {
            id: exerciseId,
            withContent: false,
            withModule: false,
            courseId,
          },
        },
      ],
    }
  );

  // if (error) {
  //   console.log("Error in useCategoriseExerciseMutation: ", error);
  // }

  return [categoriseExercise, loading];
};

export default useCategoriseExerciseMutation;
