import { GET_CURRENT_USER } from "API/queries/user/useGetCurrentUser";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

const REDEEM_COUPON = gql`
  mutation sendStudentVerificationLink($email: String!) {
    sendStudentVerificationLink(email: $email) {
      ok
      error
    }
  }
`;

const useSendStudentEmailVerificationLink = onCompleted => {
  const [sendStudentVerificationLink, { loading, data }] = useMutation(
    REDEEM_COUPON,
    {
      onCompleted: onCompleted ? onCompleted : () => {},
      refetchQueries: () => [
        {
          query: GET_CURRENT_USER,
          variables: { includeStats: false, includeMembership: false },
        },
      ],
    }
  );


  let response = {};

  if (data && data.sendStudentVerificationLink) {
    response = data.sendStudentVerificationLink;
  }


  return { sendStudentVerificationLink, loading, data, response };
};

export default useSendStudentEmailVerificationLink;
