import React from "react";
import {useTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";
import {Button, Input, Toggle, Trans, Typography} from "Components";
import Editor from "Components/Editor/Editor";

import {UserContext} from "UserContextProvider";
import {useHistory, useLocation} from "react-router-dom";

const NewComment = ({state, placeholder, refetch, exerciseId}) => {
  const {user} = React.useContext(UserContext);
  const {values, handleChange, submit, errors, setFormValue} = state(
    refetch,
    exerciseId
  );
  const {t: trans} = useTranslation();
  const [showTips, setShowTips] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const current_path = window.location.pathname;

  return (
    <div className="new-comment-container">
      <Typography type="tlg" className="my-2">
        {placeholder}
      </Typography>

      <Editor
        state={values}
        updateText={text => setFormValue({text})}
        onFocus={() => setShowTips(true)}
        type="compact"
      />

      <div className="mt-2">
        <Toggle
          name="anonymous"
          defaultChecked={values.anonymous}
          label={<Trans>Post anonymously</Trans>}
          onChange={() => setFormValue({anonymous: !values.anonymous})}
        />
      </div>
      {values.anonymous && (
        <div className="mt-2">
          <Input
            errorMessageRelative
            name="username"
            autoComplete="off"
            error={errors.username}
            value={values.username}
            disabled={!values.anonymous}
            className={!values.anonymous ? "opacity-0" : ""}
            style={{margin: `${isMobile ? "16px 0" : 0}`}}
            onChange={handleChange}
            placeholder={trans("Anonymous username")}
          />
        </div>
      )}

      {!user && (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            window.analytics.track("login_comment_exercise_clicked", {
              screen_name: current_path,
            });
            history.push({
              pathname: "/modal/login",
              state: {background: location},
              replace: true,
            });
          }}
          className="mt-2"
        >
          <Trans>Log in to participate</Trans>
        </Button>
      )}
      {user && values.text && (
        <div className="mt-3">
          <Button type="primary" onClick={submit} fluid>
            <Trans>Post</Trans>
          </Button>
        </div>
      )}
    </div>
  );
};

export default NewComment;
