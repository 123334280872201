import React from "react";
import {Button, Icon, Trans, Input, Popover} from "Components";
import {useTranslation} from "react-i18next";
import classnames from "classnames";

const GroupSettingsInfoLink = ({
  link,
  onChange,
  handleRemoveLink,
  handleSave,
  hasChanged,
}) => {
  const {icon, title, url} = link;
  const {t: trans} = useTranslation();

  return (
    <div className="px-4 py-3  gap-4  flex items-end mb-6 last:mb-4 rounded-lg bg-slate-25">
      <div className="flex flex-col justify-center h-full p-2">
        <GroupSettingsInfoLinkIcon
          initialIcon={icon}
          onChange={icon => onChange({...link, icon})}
        />
      </div>

      <Input
        onChange={e => onChange({...link, title: e.target.value})}
        value={title}
        name="title"
        label={trans("Title")}
        size="sm"
      />

      <Input
        onChange={e => onChange({...link, url: e.target.value})}
        value={url}
        name="url"
        label={trans("URL")}
        size="sm"
      />

      <div className={classnames(hasChanged ? "opacity-100" : "opacity-0")}>
        <Button
          disabled={!link.title || !link.url || !link.icon}
          onClick={handleSave}
          type="ghost"
          size="xs"
        >
          <Trans>Save</Trans>
        </Button>
      </div>
      <Button onClick={handleRemoveLink} type="destructive" size="xs">
        <Trans>Delete</Trans>
      </Button>
    </div>
  );
};

const GroupSettingsInfoLinkIcon = ({initialIcon, onChange}) => {
  const [icon, setIcon] = React.useState(initialIcon || "fas fa-link");

  return (
    <Popover
      content={
        <div className="flex gap-2">
          <Input
            size="sm"
            onChange={e => setIcon(e.target.value)}
            name="icon"
          />
          <Button size="sm" onClick={() => onChange(icon)}>
            <Trans>Update</Trans>
          </Button>
        </div>
      }
    >
      <div className="p-4 bg-primary-800  w-12 h-12 rounded-full flex flex-col items-center justify-center">
        <Icon icon={initialIcon} size="xl" fixedWidth color="text-white" />
      </div>
    </Popover>
  );
};

export default GroupSettingsInfoLink;
