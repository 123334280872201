import React from "react";
import {PageLoader} from "Components";
import useCheckoutState from "./useCheckoutState";

import CheckoutProductsNotFound from "routes/Checkout/CheckoutAuxillaryPages/CheckoutProductsNotFound/CheckoutProductsNotFound";
import useGetProductsFromUrl from "routes/Checkout/CheckoutContextWrapper/useGetProductsFromUrl";

export const CheckoutContext = React.createContext(null);
window.CheckoutContext = CheckoutContext; // So we don't need to import it

const CheckoutWrapper = ({
  children,
  status,
  productsInput,
  toggleLifeTimeSelected,
  lifeTimeSelected,
  setSelectedRecommendedProducts,
  selectedRecommendedProducts,
}) => {
  const {
    products,
    loading,
    unPurchasedProducts,
    recommendedProducts,
  } = useGetProductsFromUrl(productsInput, selectedRecommendedProducts);

  if (loading) return <PageLoader />;

  if (!products || products.length === 0) {
    return <CheckoutProductsNotFound />;
  }

  return (
    <CheckoutContextWrapper
      children={children}
      recommendedProducts={recommendedProducts}
      products={products}
      toggleLifeTimeSelected={toggleLifeTimeSelected}
      lifeTimeSelected={lifeTimeSelected}
      setSelectedRecommendedProducts={setSelectedRecommendedProducts}
      selectedRecommendedProducts={selectedRecommendedProducts}
      status={status}
    />
  );
};

const CheckoutContextWrapper = ({
  children,
  products,
  recommendedProducts,
  lifeTimeSelected,
  toggleLifeTimeSelected,
  setSelectedRecommendedProducts,
  selectedRecommendedProducts,
  status,
}) => {
  const state = useCheckoutState({
    products,
    recommendedProducts,
    lifeTimeSelected,
    toggleLifeTimeSelected,
    setSelectedRecommendedProducts,
    selectedRecommendedProducts,
  });

  return (
    <CheckoutContext.Provider value={{...state}}>
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutWrapper;
