import React from "react";
import {maybeString} from "utilities/text";
import CDN from "utilities/constants/CDN";

const useGptRequestUsers = () => {
  const {user} = React.useContext(window.UserContext);

  const senderUser = React.useMemo(() => {
    if (user && user?.id) {
      return {
        id: user.id,
        name: maybeString(user.firstName) + " " + maybeString(user.lastName),
        avatar: user.avatar,
      };
    }

    return {
      id: -3, // just to avoid conflicts with other users
      name: "You",
      avatar:
        "http://kollin.s3.eu-north-1.amazonaws.com/user-images/default.png",
    };
  }, [user?.id]);

  const senderAssistant = React.useMemo(
    () => ({
      id: 20541, // just to avoid conflicts with other users
      name: "Kollin-GPT",
      avatar: CDN + "/logo/logo.svg",
    }),
    []
  );

  return {
    senderUser,
    senderAssistant,
  };
};

export default useGptRequestUsers;
