import React from "react";

const useSelectState = ({
  defaultSelectedValue,
  value,
  items,
  onSelect,
  onChange,
}) => {
  const [selectedItem, setSelectedItem] = React.useState(null);

  // Set the defaultSelectedOption if it's not already selected
  React.useEffect(() => {
    // Only do this if no option is selected at all, otherwise infinite loop
    if (defaultSelectedValue && !selectedItem) {
      /**
       * Find the object in the options array that matches the defaultSelectedValue
       */
      const defaultSelectedItem = findItemByValue(items, defaultSelectedValue);

      setSelectedItem(defaultSelectedItem);
    }
  }, [defaultSelectedValue]);

  // React.useEffect(() => {
  //   // If no value is selected, set the
  //   if (value === null) {
  //     setSelectedOption(options.find(option => option.value === value));
  //   }
  // }, [value]);

  React.useEffect(() => {
    if (
      selectedItem?.value !== undefined &&
      selectedItem?.value !== defaultSelectedValue // Don't run onSelect when defaultValue is selected
    ) {
      if (onChange) {
        onChange(selectedItem?.value);
      }
      if (onSelect) {
        onSelect(selectedItem?.value);
      }
    }
  }, [selectedItem]);

  const setSelectedItemByValue = value => {
    setSelectedItem(findItemByValue(items, value));
  };

  return {
    selectedItem, // The selected option object
    setSelectedItemByValue, // The function to set the selected option
  };
};

const findItemByValue = (items, value) => {
  return items.find(item => item.value === value);
};

export default useSelectState;
