import React from "react";
import {StarRating, Typography} from "Components";
import moment from "moment";

const CourseLandingFeatureReviewCard = ({review}) => {
  return (
    <div className="flex flex-col py-3">
      <div className="flex items-center gap-2">
        <div>
          <Typography color="text-gray-900" weight="md">
            {review.user.first_name} {review.user.last_name}
          </Typography>
          <Typography type="txs">
            {moment(review.createdAt)
              .locale("se")
              .fromNow()}
          </Typography>
        </div>
      </div>

      <StarRating rating={review.score} />

      <Typography className="py-2">{review.text}</Typography>
      <div className="w-full h-px bg-gray-200" />
    </div>
  );
};

export default CourseLandingFeatureReviewCard;
