import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";

export const GET_COURSE_TAGS = gql`
  query getCourseTags(
    $courseId: ID!
    $includeModule: Boolean = false
    $includeExerciseIds: Boolean = false
    $exercisesFilter: ExerciseFiltersInput = {}
  ) {
    getCourse(id: $courseId) {
      id
      tags {
        id
        name
        module(course_id: $courseId) @include(if: $includeModule) {
          id
          name
        }

        exercises(filters: $exercisesFilter) @include(if: $includeExerciseIds) {
          id
        }
      }
    }
  }
`;

const useGetCourseTags = (
  courseId,
  {includeModule = false, includeExerciseIds = false} = {}
) => {
  let tags = [];

  const {data, loading, error} = useQuery(GET_COURSE_TAGS, {
    variables: {
      courseId,
      includeModule,
      includeExerciseIds,
      exercisesFilter: {courseIds: [courseId]},
    },
  });

  if (error) {
    console.log(error);
  }

  if (data && data.getCourse.tags) {
    tags = data.getCourse.tags;
  }

  return [tags, loading];
};

export default useGetCourseTags;
