import React from "react";

const useGPTFreeTierQuota = ({sessionId}) => {
  const [loading, setLoading] = React.useState(true);
  const [freeTierQuota, setFreeTierQuota] = React.useState(null);

  const getFreeTierQuota = async () => {
    const response = await fetch(
      `https://jaif7yyllse2tzj75z5fz54zgi0xakso.lambda-url.eu-north-1.on.aws/`,
      {
        method: "POST",
        redirect: "follow",
        body: JSON.stringify({
          action: "getFreeTierQuota",
          userToken: localStorage.getItem("AUTH_TOKEN"),
        }),
      }
    );

    const data = await response.json();

    setFreeTierQuota(data.quota);

    setLoading(false);
  };

  React.useEffect(() => {
    getFreeTierQuota();
  }, [sessionId]);

  return {loading, freeTierQuota};
};

export default useGPTFreeTierQuota;
