import React from "react";
import classnames from "classnames";
import {Button, Lottie, Trans} from "Components";
import {isSafari} from "react-device-detect";

const CourseChatGPTHeader = ({
  parentRef,
  inverted,
  pauseVideoOnHover = false,
}) => {
  const {chatActive, toggleModal, showAsModal} = React.useContext(GPTContext);
  const videoRef = React.useRef(null);
  const [paused, setPaused] = React.useState(null);

  const pauseOnHover = () => {
    setPaused(true);
  };

  const resumeOnLeave = () => {
    setPaused(false);
  };

  React.useEffect(() => {
    if (parentRef && parentRef.current && pauseVideoOnHover) {
      parentRef.current.addEventListener("mouseenter", pauseOnHover);

      parentRef.current.addEventListener("mouseleave", resumeOnLeave);

      return () => {
        if (parentRef && parentRef.current) {
          parentRef.current.removeEventListener("mouseenter", pauseOnHover);
          parentRef.current.removeEventListener("mouseleave", resumeOnLeave);
        }
      };
    }
  }, [parentRef]);

  React.useEffect(() => {
    if (videoRef.current) {
      const func = paused ? "pause" : "play";
      try {
        videoRef.current[func]();
      } catch (e) {
        console.log(e);
      }
    }
  }, [paused]);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
    }
  }, [videoRef]);

  if (chatActive || showAsModal) return null;

  return (
    <div className=" inset-0 h-full w-full z-10 p-4">
      <div className="flex justify-between items-start">
        <div className={classnames("flex items-start")}>
          <div className="w-20 -mt-4 -ml-4 opacity-80">
            <Lottie animation="orb" speed={0.5} loop={true} />
          </div>
          <div className="grow">
            <Trans
              type="tlg"
              weight="md"
              color={inverted ? "text-white" : "text-gray-900"}
            >
              Struggling with your studies?
            </Trans>
            {!chatActive && (
              <Trans type="tsm" color="text-gray-300">
                Ask Kollin-GPT for help
              </Trans>
            )}
          </div>
        </div>

        <Button
          size="lg"
          icon={showAsModal ? "compress" : "expand"}
          type="link"
          inverted={inverted}
          onClick={toggleModal}
        />
      </div>
    </div>
  );
};

export default CourseChatGPTHeader;
