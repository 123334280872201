import React from "react";
import {Button} from "Components";
import {safeCloneTextTag} from "utilities/safeClone";

const NotificationElementBody = ({
  title = "",
  subTitle = "",
  message = "",
  body,
  primaryAction,
  secondaryAction,
}) => {
  return (
    <div className="grow">
      <div className="flex gap-2">
        {safeCloneTextTag(title, {type: "tsm", weight: "md"})}
        {safeCloneTextTag(subTitle, {
          type: "tsm",
          weight: "normal",
          color: "text-gray-light",
        })}
      </div>
      {// If there is a message, show it
      safeCloneTextTag(message, {
        type: "tsm",
        weight: "normal",
        className: "mt-1",
      })}
      {body && <div className="my-4">{body}</div>}
      <div className="flex gap-4 py-2">
        {secondaryAction && (
          <Button
            type="link-gray"
            onClick={secondaryAction.onClick}
            size="md"
            {...secondaryAction}
          >
            {secondaryAction.label}
          </Button>
        )}
        {primaryAction && (
          <Button
            type="link"
            onClick={primaryAction.onClick}
            size="md"
            {...primaryAction}
          >
            {primaryAction.label}
          </Button>
        )}
      </div>
    </div>
  );
};

export default NotificationElementBody;
