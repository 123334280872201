import React from "react";
import useGetCurrentUserGroups from "API/queries/groups/useGetCurrentUserGroups";
import subdomains from "Providers/GroupProvider/subdomains";
import {Badge, Link, Trans, Typography} from "Components";
import {isInGroup} from "Providers/GroupProvider/GroupProvider";

const GroupSubdomainBanner = () => {
  const {currentUserGroups, loading} = useGetCurrentUserGroups();

  if (loading || !currentUserGroups.length || isInGroup) return null;

  const subs = Object.values(subdomains);

  const group = currentUserGroups.find(g =>
    subs.some(
      s => s.released && (s.id === g.id || s.subGroupIds?.includes(g.id)) // Find the first group that is released and matches the user's groups
    )
  );

  if (!group) return null;

  const subdomain = Object.keys(subdomains).find(
    k =>
      subdomains[k].id === group.id ||
      subdomains[k].subGroupIds?.includes(group.id)
  );

  const {name, imageUrl} = group;

  const [link, site] = getLink(subdomain, group);

  return (
    <div className="p-4 bg-primary-25 rounded-lg flex flex-col md:flex-row gap-4 md:items-start border-0 border-primary">
      <img className="h-16 rounded-full -mt-px" src={imageUrl} />

      <div>
        <div className="rbc">
          <Typography type="txl" weight="sb" color="text-primary-800">
            {name} på Kollin
          </Typography>
          <Badge type="success" size="md">
            <Trans>Nytt!</Trans>
          </Badge>
        </div>
        <Typography type="tmd" weight="md" color="text-primary-800">
          Du kan nu gå in på {name}s interna grupp på Kollin här:{" "}
          <Link href={link} target="_blank" iconRight="external-link" inline>
            {site}
          </Link>
        </Typography>
        <Typography
          type="tmd"
          weight="md"
          color="text-primary-800"
          className="mt-2"
        >
          Där kan ni samla och organisera sektionens kollektiva kunskap! Sidan
          kommer utvecklas mycket under tiden!
        </Typography>
      </div>
    </div>
  );
};

const getLink = (subdomain, group) => {
  const location = window.location;
  const site = `${subdomain}.${location.host}`;
  const fullLink = `${location.protocol}//${subdomain}.${location.host}`;

  return [fullLink, site];
};

export default GroupSubdomainBanner;
