import React from "react";
import useGetModule from "API/queries/module/useGetModule";
import {Icon, Link} from "../index";
import {capitalizeFirstLetter} from "../../../utilities/text";

const BreadCrumbModule = ({moduleId, getModuleLink}) => {
  if (!moduleId) return null;

  const [module, moduleLoading] = useGetModule(moduleId);
  if (moduleLoading) return null;

  return (
    <div className="flex gap-1">
      <Icon icon="chevron-right" size="md" />
      <Link
        type="txl"
        weight="md"
        className="text-gray-500"
        to={getModuleLink(moduleId)}
      >
        {capitalizeFirstLetter(module.name)}
      </Link>
    </div>
  );
};

export default BreadCrumbModule;
