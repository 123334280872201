import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";

export const GET_CURRENT_USER_CREDITS = gql`
  query getCurrentUserCredits {
    currentUser {
      id
      credits
    }
  }
`;

const useGetCurrentUserSettings = () => {
  let credits = 0;

  const {data, loading, error, refetch} = useQuery(GET_CURRENT_USER_CREDITS);

  if (error) {
    console.error(`Error in useGetCurrentUserSettings: ${error}`);
  }

  if (data && data.currentUser) {
    credits = data.currentUser.credits;
  }

  return {
    credits,
    loading,
    refetch,
  };
};

export default useGetCurrentUserSettings;
