import React from "react";
import EmptyState from "Components/EmptyState/EmptyState";
import { Button, Trans } from "Components";
import UserMenu from "Components/Navbars/UserMenu/UserMenu";
import PageNavbar from "Components/Navbars/PageNavbar/PageNavbar";
import { useHistory } from "react-router-dom";

const CourseNotFound = () => {
  const history = useHistory();

  return (
    <div>
      <PageNavbar />
      <UserMenu />
      <div className="flex flex-col justify-center p-8 h-full w-full">
        <EmptyState
          title="Course not found"
          subTitle="The course you are looking for does not exist or has been deleted. Please contact us if the problem persists"
          illustration="cloud"
          size="lg"
          fluidActions
          primaryAction={
            <Button onClick={() => history.push("/")}>
              <Trans>Home</Trans>
            </Button>
          }
          secondaryAction={
            <Button onClick={() => history.push("/contact")}>
              <Trans>Contact form</Trans>
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default CourseNotFound;
