import React from "react";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {Trans} from "Components";
import CourseSidebarRoute from "Components/Sidebar/CourseSidebar/CourseSidebarRoute";
import CourseSidebarMenuItem from "Components/Sidebar/CourseSidebar/CourseSidebarMenuItem";
import CourseSidebarFooterUser from "Components/Sidebar/CourseSidebar/CourseSidebarFooter/CourseSidebarFooterUser";
import GroupNavigationSidebarHeader from "routes/Group/GroupNavigation/GroupNavigationSidebar/GroupNavigationSidebarHeader";
import {motion} from "framer-motion";
import classnames from "classnames";
import {CourseSidebarFooterCredits} from "Components/Sidebar/CourseSidebar/CourseSidebarFooter/CourseSidebarFooter";

const GroupNavigationSidebar = ({
  routes,
  collapsed,
  width,
  menuItemProps = {},
}) => {
  const {key, imageUrl, name} = useGroupContext();
  const [showMenu, setShowMenu] = React.useState(false); // For mobile

  const currentPath = window.location.pathname;

  React.useEffect(() => {
    setShowMenu(false);
  }, [currentPath]);

  const currentRoute = React.useMemo(() => {
    return routes.find(route => {
      return currentPath.includes(route.path);
    });
  }, [currentPath]);

  return (
    <div
      className={classnames(
        "border-right  grow top-0 flex flex-col justify-between p-2 md:pb-8",
        "relative md:fixed md:h-screen", // height
        "px-4 md:px-2 md:pt-4",
        "bg-gray-700 md:bg-transparent",
        width
      )}
    >
      <div>
        <GroupNavigationSidebarHeader
          collapsed={collapsed}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
        <div
          className={classnames(
            "mt-4 md:mt-8",
            showMenu ? "block" : "hidden md:block"
          )}
        >
          {routes.map((route, i) => {
            if (route.hidden) return null;
            return (
              <CourseSidebarRoute
                collapsed={collapsed}
                toggleCollapsed={null}
                expandOnHover={false}
                key={i}
                url={currentPath}
                currentPath={currentPath}
                currentRoute={currentRoute}
                {...route}
                to={route.path}
                icon={route.icon}
                {...{
                  ...menuItemProps,
                  color: route.admin ? "text-gray-400" : menuItemProps.color,
                  iconColor: route.admin
                    ? "text-gray-400"
                    : menuItemProps.color,
                }}
                showLabelOnHover={false}
              />
            );
          })}
        </div>
      </div>

      <div
        className={classnames("dark", showMenu ? "block" : "hidden md:block")}
      >
        <CourseSidebarFooterCredits
          collapsed={collapsed}
          sidebarMenuItemProps={menuItemProps}
        />
        <CourseSidebarFooterUser
          collapsed={collapsed}
          showProgram={false}
          showEmail={true}
          avatarSize={collapsed ? "sm" : "md"}
          expand={false}
          showCog={false}
        />
      </div>
    </div>
  );
};

export default GroupNavigationSidebar;
