import React from "react";
import {Button, Trans, Typography} from "../../../Components";
import {useHistory} from "react-router-dom";
import MyCoursesWidget from "routes/MyCourses/MyCoursesWidget";
import useGroupHome from "./useGroupHome";

const GroupHomeMembersCard = () => {
  const history = useHistory();
  const {getGroupAllMembers} = useGroupHome();
  return (
    <MyCoursesWidget
      size="sm"
      title={<Trans>Members</Trans>}
      icon="users"
      type="fuchsia"
      subTitle={
        <Typography className="p-2" type="dmd" weight="bold">
          {getGroupAllMembers().length}
        </Typography>
      }
      action={
        <Button
          type="link"
          size="xs"
          onClick={() => {
            history.push("members");
          }}
        >
          <Trans>See all</Trans>
        </Button>
      }
    ></MyCoursesWidget>
  );
};

export default GroupHomeMembersCard;
