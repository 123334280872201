import React from "react";
import {Icon, Section, SubSection, Trans, Typography} from "Components";
import {useTranslation} from "react-i18next";

const GroupAdminUploadResult = ({result, fileMembers, matchLoading}) => {
  // const membersToValidate = outputBlocks[1].members.filter(
  //   member => member.verified === false
  // );
  const {t: trans} = useTranslation();

  const {matchedMembers, unmatchedMembers, nonMembers} = result;

  const blocks = [
    {
      title: trans("People in CSV file"),
      icon: "fas fa-file-alt",
      color: "text-primary-light",
      weight: "md",
      value: fileMembers.length,
    },
    {
      title: trans("Group members found in CSV"),
      icon: "fas fa-link",
      color: "text-success-light",
      weight: "sb",
      value: matchedMembers.length,
    },
    {
      title: trans("Group members not found in CSV"),
      icon: "fas fa-ghost",
      color: "text-gray-light",
      weight: "sb",
      value: unmatchedMembers.length,
    },
    {
      title: trans("Students to be invited"),
      icon: "fas fa-user-plus",
      color: "text-purple-light",
      weight: "sb",
      value: matchedMembers.length,
    },
  ];

  return (
    <div className="max-w-2xl">
      <Trans className="mt-4" color="text-gray-light" weight="b">
        Result of matching
      </Trans>

      <div className="w-full mt-2 border-b-1 border-gray-light" />

      <div className="w-full grid grid-cols-2 grid-rows-4 place-content-start items-start justify-start gap-y-4 mt-4">
        {blocks.map(block => {
          return (
            <>
              <div className="flex items-center gap-2">
                <Icon icon={block.icon} color={block.color} />
                <Typography weight="sb" color={block.color}>
                  {block.title}
                </Typography>
              </div>
              {matchLoading ? (
                <Icon
                  spin
                  icon="fas fa-spinner fa-spin"
                  color="text-gray-light"
                />
              ) : (
                <Typography weight="sb" color={block.color}>
                  {block.value}
                </Typography>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default GroupAdminUploadResult;
