import React from "react";
import useGetCourseExercises from "../../../API/queries/exercise/useGetCourseExercises";
import useGetCourseExams from "../../../API/queries/exam/useGetCourseExams";

const useCourseExamsList = () => {
  const {courseId} = React.useContext(window.CourseContext);
  const [exercises, exercisesLoading] = useGetCourseExercises({
    courseId,
    includeSelfEvaluation: true,
    includeStats: true,
    includeSource: true,
  });
  const [exams, loading] = useGetCourseExams(courseId);

  const examsByDate = exams?.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  const latestExercisesCompletedByExam = React.useMemo(() => {
    if (exercisesLoading) {
      return [];
    }
    // filter exercises by self_evaluation not empty and sort by date of self evaluation descending
    const latestExercisesDone = exercises
      .filter(exercise => exercise.selfEvaluation !== null)
      .sort((a, b) => {
        return (
          new Date(b.self_evaluation.date) - new Date(a.self_evaluation.date)
        );
      })
      .map(exercise => {
        return exercise.source.id;
      });
    return Array.from(new Set(latestExercisesDone));
  }, [exercises, exercisesLoading]);

  const sortedExams = size => {
    if (exercisesLoading) {
      return [];
    }

    const topPersonalExams = latestExercisesCompletedByExam.slice(0, size);
    if (topPersonalExams.length < size) {
      //   get most recent exams and filter out by exams already in topPersonalExams
      const topCourseExams = examsByDate
        .filter(exam => !topPersonalExams.includes(exam.id))
        .slice(0, size - topPersonalExams.length)
        .map(exam => exam.id);
      return topPersonalExams.concat(topCourseExams);
    }
    return topPersonalExams;
  };

  return {
    sortedExams,
    exercisesLoading,
  };
};

export default useCourseExamsList;
