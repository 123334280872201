import React from "react";
import {Typography} from "Components/_Elements/index";

const gradients = {
  "fuchsia-amber-light": "linear-gradient(135deg, #f0abfc 0%, #fdba74 100%)",
  "fuchsia-amber-dark": "linear-gradient(135deg, #c026d3 0%, #d97706 100%)",
};

const TypographyGradient = ({
  children,
  gradientType = "fuchsia-amber-light",
  ...props
}) => {
  return (
    <Typography
      style={{
        color: "transparent",
        background: gradients[gradientType],
        backgroundClip: "text",
        textFillColor: "transparent",
        WebkitBackgroundClip: "text",
        MozBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        MozTextFillColor: "transparent",
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default TypographyGradient;
