import React from "react";
import {Icon, SafeText} from "Components/_Elements/index";

const MetricChangePercentage = ({changePercentage, changeLabel, icon}) => {
  if (changePercentage === null) return null;

  return (
    // only render change if changePercentage is not null
    <span className="inline items-start gap-1">
      <Icon
        icon={getIcon(icon, changePercentage)}
        color={getColor(changePercentage)}
        size="sm"
      />

      <SafeText
        type="txs sm:tsm"
        weight="md"
        inline
        color={getColor(changePercentage)}
      >
        {changePercentage.toString() + "%"}
      </SafeText>

      <SafeText
        inline
        className="ml-1"
        color="text-gray-light"
        type="txs sm:tsm"
        weight="md"
      >
        {changeLabel}
      </SafeText>
    </span>
  );
};

const getIcon = (icon, changePercentage) => {
  if (icon) {
    return icon;
  }
  if (changePercentage > 0) {
    return "arrow-up";
  }
  if (changePercentage < 0) {
    return "arrow-down";
  }

  if (changePercentage === 0) return "arrow-right";
};

const getColor = changePercentage => {
  if (changePercentage > 0) {
    return "text-success-light";
  }
  if (changePercentage < 0) {
    return "text-error-light";
  }

  if (changePercentage === 0) return "text-gray-light";
};

export default MetricChangePercentage;
