import useGetContentfulStudyPlan from "API/cms/StudyPlan/useGetContentfulStudyPlan";
import React from "react";

const useStudyPlanCardPurchased = studyPlan => {
  const [courseStudyPlan, loading] = useGetContentfulStudyPlan(
    studyPlan?.contentfulId
  );

  const [completedBlocks, setCompletedBlocks] = React.useState(new Set());

  const addCompletedBlock = newBlock =>
    setCompletedBlocks(new Set([...completedBlocks, newBlock]));

  const totalBlocks = courseStudyPlan?.blocksCollection?.items.length || 1;
  const completedBlocksCount = completedBlocks.size;

  return {
    addCompletedBlock,
    completedBlocks,
    courseStudyPlan,
    loading,
    completedBlocksCount,
    totalBlocks,
  };
};

export default useStudyPlanCardPurchased;
