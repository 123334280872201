import React from "react";
import classnames from "classnames";
import { Typography } from "Components";
import ExamUploadDocumentsRowFiles from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsRow/ExamUploadDocumentsRowFiles/ExamUploadDocumentsRowFiles";
import { ExamUploadContext } from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContext";
import { AnimateSharedLayout, motion } from "framer-motion";
import moment from "moment";
import useExamUploadDocumentsRowState from "./ExamUploadDocumentsRowState/useExamUploadDocumentsRowState";
import ExamUploadDocumentsRowActions from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsRow/ExamUploadDocumentsRowActions";

const ExamUploadDocumentsRow = ({ date, document, index, isLast }) => {
  const ref = React.useRef();
  const { previewResource } = React.useContext(ExamUploadContext);
  const state = useExamUploadDocumentsRowState({
    date,
    document,
  });

  const { error, author, documentFocused } = state;

  const dateObj = moment(date);

  return (
    <AnimateSharedLayout>
      <motion.div
        ref={ref}
        className={classnames(
          "grid grid-cols-12 py-4 rounded-lg w-full gap-2",
          document.errors
            ? "surface-warning border-warning-300 dark:border-warning-700 border-2"
            : "background"
        )}
        whileHover={{
          opacity: 1,
        }}
        layout="position"
        initial={{ opacity: 0, x: 300 }}
        animate={{
          opacity: previewResource && previewResource?.date !== date ? 0.5 : 1,
          scale: 1,
          x: 0,
        }}
        exit={{
          opacity: 0,
          scale: 0.5,
        }}
      >
        <div className="col-span-2 flex flex-col items-center text-center relative">
          <Typography weight="b" type="tlg" color="text-gray-light">
            {dateObj.year()}
          </Typography>
          <Typography type="tmd" className="whitespace-nowrap">
            {dateObj.format("Do")} {dateObj.format("MMM")}.
          </Typography>

          <div
            className={classnames(
              "w-0.5 bg-gray-300 mt-2 h-full ",
              isLast ? "mb-2" : "-mb-4"
            )}
          />
        </div>
        <motion.div className={classnames("col-span-10")} key={date}>
          <div className="flex grow gap-2 items-start justify-between min-h-[3.75em]">
            <Typography type="tlg" weight="md" className="whitespace-nowrap ">
              {author}
            </Typography>
            {
              <ExamUploadDocumentsRowActions
                document={document}
                focused={documentFocused}
                {...state}
                error={error}
              />
            }
          </div>

          <ExamUploadDocumentsRowFiles
            documentFocused={documentFocused}
            document={document}
          />
          {/*</AnimateSharedLayout>*/}
        </motion.div>
      </motion.div>
    </AnimateSharedLayout>
  );
};

export default ExamUploadDocumentsRow;
