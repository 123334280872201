import React from "react";
import {Modal} from "Components";
import {useLocation} from "react-router-dom";
import CreditsModal from "Modals/CreditsModal/CreditsModal";

export const CourseModalContext = React.createContext(null);
window.CourseModalContext = CourseModalContext;

const Modals = {
  CREDITS: {
    Component: <CreditsModal />,
    renderComponentInModal: false,
  },
};

const CourseModalsProvider = ({children}) => {
  const location = useLocation();
  const [modalStack, setModalStack] = React.useState([]);

  const openModal = (key, onclose = null, props) => {
    // We don't want to open the same modal twice
    if (modalStack.filter(modal => modal.key === key).length > 0) {
      return;
    }

    // Check if the modal exists
    if (!Modals[key]) {
      return;
    }

    const callback = onclose || (() => {});

    // Add the modal to the stack
    setModalStack([{key, callback, props}, ...modalStack]);
  };

  const closeModal = () => {
    // When closing a modal, we want to remove it from the stack
    setModalStack(modalStack.slice(1));
  };

  // Close all modals when the route changes
  React.useEffect(() => {
    setModalStack([]);
  }, [location.pathname]);

  return (
    <CourseModalContext.Provider
      value={{
        openModal,
        closeModal,
        modalStack,
      }}
    >
      <CurrentModal modalStack={modalStack} closeModal={closeModal} />
      {children}
    </CourseModalContext.Provider>
  );
};

const CurrentModal = ({modalStack, closeModal}) => {
  if (modalStack.length === 0) return null;

  const currentModal = Modals[modalStack[0]?.key]; // The first modal in the stack is the current modal
  const currentModalProps = modalStack[0]?.props;

  if (!currentModal) return null;

  /**
   * Clone the modal component and add the onComplete prop with the closeModal function and the callback if it exists
   * @type {React.DetailedReactHTMLElement<{onComplete: onComplete}, HTMLElement>}
   */
  const callback = modalStack[0].callback;
  const Component = React.cloneElement(currentModal.Component, {
    onComplete: () => {
      closeModal();
      if (callback && typeof callback === "function") {
        callback();
      }
    },
    onClose: closeModal,
  });

  if (currentModal?.renderComponentInModal) {
    return (
      <Modal defaultOpen={true} onClose={closeModal}>
        {React.cloneElement(Component, {
          ...currentModalProps,
        })}
      </Modal>
    );
  }

  return React.cloneElement(Component, {
    ...currentModalProps,
  });
};

export default CourseModalsProvider;
