import React from "react";
import Trans from "Components/Trans";
import "./ErrorBoundary.scss";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    console.log(error, errorInfo);
  }

  render() {
    const FallbackComponent = (
      <div className="error-boundary">
        <p className="error-boundary-warning-message">
          {this.props.errorMessage || (
            <Trans>Oops, an error occurred. Please try again later!</Trans>
          )}
        </p>
        <img
          src={"images/warning.svg"}
          alt="warning"
          className="error-boundary-warning-img"
        />
      </div>
    );

    let fallback = null;

    if (this.state.hasError) {
      if (this.props.hideOnError) return null;
      if (this.props.fallbackComponent) return this.props.fallbackComponent;

      return FallbackComponent;
    }

    // If the child returns null, don't render anything

    return this.props.children || fallback;
    // <Sentry.ErrorBoundary fallback={fallback}>
    //   {this.props.children}
    // </Sentry.ErrorBoundary>
  }
}

ErrorBoundary.defaultProps = {
  hideOnError: true,
  fallbackComponent: <div />,
};

ErrorBoundary.propTypes = {
  /**
   * If true, show nothing if component crashes
   */
  hideOnError: PropTypes.bool,
  fallbackComponent: PropTypes.node,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ErrorBoundary;
