import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Input, SubSection, Trans, Typography} from "Components";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import UseAccountCreditsRedeemCouponState from "./useAccountCreditsRedeemCouponState";
import {accountSubSectionTitleProps} from "../../Account";
import {motion} from "framer-motion";

const AccountCreditsRedeemCoupon = ({disabled, orientation = "auto"}) => {
  const {t: trans} = useTranslation();
  const [user, userLoading] = useGetCurrentUser();

  const {
    submit,
    handleChange,
    value,
    message,
    error,
    loading,
  } = UseAccountCreditsRedeemCouponState();

  // Get coupon from the url query string
  const couponId = new URLSearchParams(window.location.search).get("coupon");

  // If couponId is set, scroll to the redeem coupon section

  React.useEffect(() => {
    if (couponId) {
      const element = document.getElementById("account-credits");
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      }
    }
  }, [userLoading]);

  if (userLoading) return null;

  return (
    <SubSection
      className="py-5"
      disabled={disabled || userLoading || !user?.student_email}
      id="redeem-coupon"
      animate={couponId}
      orientation={orientation}
      title={<Trans>Redeem a coupon</Trans>}
      subTitle={
        user?.student_email ? (
          <Trans type="tsm" color="text-gray">
            You can use your credits to buy or get discount on courses and
            subscription
          </Trans>
        ) : (
          <Trans type="tsm" color="text-warning">
            Please verify your student status above before redeeming a coupon
          </Trans>
        )
      }
    >
      <div>
        <form onSubmit={submit} className="max-w-52 flex gap-1">
          <Input
            type="text"
            name="coupon"
            placeholder={trans("Coupon code")}
            disabled={disabled}
            value={value}
            onChange={handleChange}
            fluid={false}
            transparent={false}
          />
          <Button
            type="secondary"
            disabled={loading || disabled}
            loading={loading}
            onClick={submit}
          >
            <Trans>Redeem</Trans>
          </Button>
        </form>

        {!loading && (message || error) && (
          // Only show the message/error if it's not loading because we are running two mutations on submit
          <motion.div
            initial={{
              opacity: 0,
              y: -10,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
          >
            <Typography color="text-success-500">{message}</Typography>
            <Typography color="text-error-500">{error}</Typography>
          </motion.div>
        )}
      </div>
    </SubSection>
  );
};

export default AccountCreditsRedeemCoupon;
