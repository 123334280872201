import React from "react";
import useSubscriptionCancellationStateSubmission from "./useSubscriptionCancellationStateSubmission";

const INITIAL_STATE = {
  selectedOptionName: null,
  explanation: "",
};

const useSubscriptionCancellationState = subscriptionId => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [state, setState] = React.useState(INITIAL_STATE);

  const onOptionChange = selectedOptionName => {
    setState({...state, selectedOptionName});
  };

  const onExplanationChange = e => {
    setState({...state, explanation: e.target.value});
  };

  React.useEffect(() => setState(INITIAL_STATE), [modalVisible]);

  // Auxiliary hook used for submission handling to make the code more readable and modular
  const {onSubmit, loading, error} = useSubscriptionCancellationStateSubmission(
    {
      setModalVisible,
      state,
      subscriptionId,
    }
  );

  return {
    modalVisible,
    setModalVisible,
    state,
    onOptionChange,
    onExplanationChange,
    onSubmit,
    loading,
    error,
  };
};

export default useSubscriptionCancellationState;
