import React from "react";
import {Skeleton} from "Components/index";
import {range} from "../../../utilities/numbers";
import classnames from "classnames";

const SkeletonListWithCircle = ({count, fluid = false, height = null}) => {
  return range(count).map(i => (
    <div
      className={classnames(
        "py-3 flex gap-3 items-center border-b-1 border-gray last:border-b-0",
        height ? height : ""
      )}
    >
      <Skeleton size="sm" circle square fluid={false} />
      <div className="w-full grow flex flex-col gap-2">
        <Skeleton size="2xs" autoWidth />
        <Skeleton size="xs" autoWidth fluid={fluid} />
      </div>
    </div>
  ));
};

export default SkeletonListWithCircle;
