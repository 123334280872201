import React from "react";
import {Trans, Typography} from "Components";
import GroupDiscountsPriceWrapper from "../Widgets/Group/GroupDiscounts/GroupDiscountsPriceWrapper";
import classnames from "classnames";
import {getDiscountedPriceCents} from "../Components/ProductPrice/ProductPrice";
import i18n from "i18next";

export const getTitle = interval => {
  switch (interval) {
    case "monthly":
      return <Trans>Monthly</Trans>;
    case "quarterly":
      return <Trans>3 months at a time</Trans>;
    case "semester":
      return <Trans>6 months at a time</Trans>;
    case "yearly":
      return <Trans>Yearly subscription</Trans>;
    // Just in case, should not happen:
    default:
      return <Trans>{interval}</Trans>;
  }
};

export const displayDiscount = (price, monthlyPlanPrice) => {
  const discount = getDiscountPercentage(price, monthlyPlanPrice);
  return <Trans discount={discount}>You save {{discount}}%</Trans>;
};

export const displayMonthlyCost = price => {
  const textProps = {
    type: "tmd",
    color: "text-gray-700",
    className: "inline",
    weight: "md",
  };
  return (
    <span className="">
      <GroupDiscountsPriceWrapper
        defaultPriceCents={price}
        textProps={textProps}
        gradient={false}
        monthly={true}
      />
    </span>
  );
};

export const displayPeriodicCost = (
  interval,
  price,
  discount,
  discountApplicable
) => {
  const discountedPriceCents =
    getDiscountedPriceCents({
      priceCents: price,
      discountPercentage: discount?.discountPercentage,
    }) || price;

  return (
    <div>
      {discount && discountApplicable && (
        <Typography
          className={classnames(
            "text-gray-light line-through",
            discount ? "opacity-100" : "opacity-0 select-none"
          )}
        >
          {getDescription(interval, price)}
        </Typography>
      )}

      <Typography className="text-gray" weight="sb">
        {getDescription(interval, discountedPriceCents)}
      </Typography>
    </div>
  );
};

export const getDescription = (interval, price) => {
  const months = getMonths(interval);
  const charge = Math.round((price * months) / 100);
  const intervalLabel = getIntervalTrans(interval);

  return (
    <Trans charge={charge} intervalLabel={intervalLabel}>
      Charged {{charge}} SEK {{intervalLabel}}.
    </Trans>
  );
};

export const getMonths = interval => {
  if (interval === "yearly") return 12;
  if (interval === "semester") return 6;
  if (interval === "quarterly") return 3;
  if (interval === "monthly") return 1;
};

export const getDiscountPercentage = (price, monthlyPlanPrice) =>
  Math.round((1 - price / monthlyPlanPrice) * 100);

export const getIntervalTrans = interval => {
  switch (interval) {
    case "monthly":
      return i18n.t("every month");
    case "quarterly":
      return i18n.t("every 3 months");
    case "semester":
      return i18n.t("every 6 months");
    case "yearly":
      return i18n.t("every year");
  }
};
