import React from "react";
import {StarRating} from "Components";
import "./CourseLandingAllReviewsStats.scss";
import {textSingularPluralTranslated} from "utilities/textSingularPlural";

const CourseLandingAllReviewsStats = ({averageRating, nReviews}) => (
  <div className="course-landing-all-reviews-stats max-h-8">
    <h1>{averageRating}</h1>
    <StarRating rating={Number(averageRating)} />
    <p>{textSingularPluralTranslated(nReviews, "review")}</p>
  </div>
);

export default CourseLandingAllReviewsStats;
