import React from "react";
import {ExamMarkingContext} from "routes/Course/ExamWizard/ExamView/ExamMarking/useExamMarkingContext";
import {motion} from "framer-motion";
import {Icon, Key, Trans} from "Components";
import {ExamViewContext} from "routes/Course/ExamWizard/ExamView/useExamViewContext";

const ExamMarkingSegmentActionsPrompt = () => {
  const {label} = React.useContext(ExamViewContext);
  const {
    currentSegment: segment,
    toggleManualSelection,
    manualSelectionActive,
    currentSegmentIsEmpty,
  } = React.useContext(ExamMarkingContext);

  if (currentSegmentIsEmpty) {
    if (manualSelectionActive) {
      return (
        <PromptWrapper pointerDir="left">
          <Trans>Select the area in the document</Trans>
        </PromptWrapper>
      );
    }
    return (
      <PromptWrapper
        pointerDir="down"
        shortcuts={[
          {
            label: <Trans>Shortcut</Trans>,
            keys: ["N"],
          },
        ]}
      >
        <Trans>
          Press the 'Create new marking' button to select the exercise
        </Trans>
      </PromptWrapper>
    );
  }

  // If current segment is not empty

  if (segment.saved) {
    return (
      <PromptWrapper
        pointerDir="up"
        shortcuts={[
          {
            label: <Trans>Shortcut</Trans>,
            keys: ["T"],
          },
        ]}
      >
        <Trans label={label}>
          Click the button above to add a new {{label}}
        </Trans>
      </PromptWrapper>
    );
  }

  return (
    <PromptWrapper
      pointerDir="down"
      shortcuts={[
        {
          label: <Trans>Save</Trans>,
          keys: ["S"],
        },
        {
          label: <Trans>Save & next</Trans>,
          keys: ["Enter", "S"],
        },
      ]}
    >
      <Trans label={label}>
        Click the "Save" button below to finish creating the {{label}}
      </Trans>
    </PromptWrapper>
  );
};

export const PromptWrapper = ({
  children,
  pointerDir,
  shortcuts = [],
  size = "md",
}) => (
  <div className="flex flex-col p-4 surface-warning rounded-xl border-2 border-warning">
    <div className="flex items-center gap-2">
      <Pointer direction={pointerDir} />
      {React.cloneElement(children, {color: "text-warning-light"})}
    </div>

    <motion.div
      className="text-warning align-self-end mt-2 flex gap-4 items-center "
      animate={{opacity: !!shortcuts.length ? 1 : 0}}
    >
      {shortcuts.map((shortcut, i) => (
        <PromptShortcut key={i} {...shortcut} />
      ))}
    </motion.div>
  </div>
);

const PromptShortcut = ({label = <Trans>Shortcut</Trans>, keys}) => (
  <span className="inline-flex items-end gap-0.5">
    {React.cloneElement(label, {color: "text-warning-light", type: "txs"})}
    {keys.map((key, i) => (
      <span>
        {i > 0 && "+"}
        <Key className="bg-warning-50 border-warning-500">{key || " "}</Key>
      </span>
    ))}
  </span>
);

export const Pointer = ({
  direction = "left",
  size = "xl",
  color = "text-warning-light",
}) => {
  const getRotation = () => {
    switch (direction) {
      case "left":
        return 0;
      case "right":
        return 180;
      case "up":
        return 90;
      case "down":
        return -90;
    }
  };

  const animation = {
    left: {x: [0, -5, 0, -5, 0]},
    right: {x: [0, -5, 0, -5, 0]},
    up: {y: [0, 5, 0, 5, 0]},
    down: {y: [0, 5, 0, 5, 0]},
  }[direction];

  return (
    <motion.span
      initial={{rotate: getRotation()}}
      animate={{
        opacity: 1,
        rotate: getRotation(),
        ...animation,
      }}
      transition={{
        duration: 5,
        repeat: Infinity,
        repeatType: "mirror",
        rotate: {
          repeat: 0,
          duration: 0.5,
        },
      }}
    >
      <Icon icon="hand-point-left" size={size} color={color} />
    </motion.span>
  );
};

export default ExamMarkingSegmentActionsPrompt;
