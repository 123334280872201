export const typeColors = {
  primary: "surface-primary border-primary-300 dark:border-primary-700",
  gray: "surface-gray border-gray-300 dark:border-gray-700",
  success: "surface-success border-success-300 dark:border-success-700",
  warning: "surface-warning border-warning-300 dark:border-warning-700",
  error: "surface-error border-error-300 dark:border-error-700",
};

export const sizeClasses = {
  xs: "p-1",
  sm: "p-2",
  md: "p-4",
  lg: "p-6",
};

export const hoverBorderColors = {
  primary: "hover:border-primary-700",
  gray: "hover:border-gray-700",
  success: "hover:border-success-700",
  warning: "hover:border-warning-700",
  error: "hover:border-error-700",
};

export const alertPrimaryTextColor = {
  primary: "text-primary",
  gray: "text-gray",
  success: "text-success",
  warning: "text-warning",
  error: "text-error",
};

export const alertSecondaryTextColor = {
  primary: "text-primary-600 dark:text-primary-400",
  gray: "text-gray-600 dark:text-gray-400",
  success: "text-success-600 dark:text-success-400",
  warning: "text-warning-600 dark:text-warning-400",
  error: "text-error-600 dark:text-error-400",
};
