import React from "react";
import classnames from "classnames";
import {Badge, Icon, SafeText} from "Components/_Elements";
import {motion} from "framer-motion";
import Trans from "Components/Trans";

const CourseSidebarMenuItem = ({
  icon,
  label,
  badge = null,
  onClick,
  color = "text-gray",
  selectedColor = "text-primary",
  background = "background",
  selectedBackground = "surface-primary",
  outlineColor = "outline-primary",
  iconColor = "text-gray-light",
  iconSelectedColor = "text-primary-light",
  disabled = false,
  selected,
  collapsed,
}) => {
  return (
    <motion.div
      className={classnames(
        "w-full grow flex justify-between p-2 gap-2 rounded-md relative group my-px hover:outline hover:outline-1",
        selected ? selectedBackground : background,
        collapsed ? "hover:shadow-sm" : "",
        outlineColor,
        disabled ? "cursor-default" : "cursor-pointer"
      )}
      initial={false}
      animate={{
        width: collapsed ? "fit-content" : "",
      }}
      onClick={onClick}
    >
      <div className="flex items-center gap-2">
        <Icon
          icon={icon}
          size="md"
          fixedWidth={true}
          color={selected ? iconSelectedColor : iconColor}
        />

        <SafeText
          weight="md"
          color={selected ? selectedColor : color}
          className={classnames(
            "whitespace-nowrap ",
            collapsed ? "hidden group-hover:block" : "block"
          )}
        >
          {label}
        </SafeText>
      </div>
      {!collapsed && badge ? badge : <span />}
    </motion.div>
  );
};

export default CourseSidebarMenuItem;
