import useGetProductPrices from "API/queries/product/useGetProductPrices";

/**
 * Get the default (1 year) access price for a product
 * @param {String} productType
 * @param {Integer} productId
 * @returns {Integer} - 0 if the product is free, null if the price is unknown and the actual price in SEK otherwise
 */
const useGetDefaultProductPrice = (productType, productId) => {
  let price = null;

  const [prices, loading] = useGetProductPrices(productType, productId);

  if (prices.length) {
    const defaultPrice = prices.find(pr => pr.accessDays == 360);

    if (defaultPrice) {
      price = defaultPrice;
    }
  }

  if (!loading && prices.length == 0) {
    price = 0; // set it as free;
  }

  return price;
};

export default useGetDefaultProductPrice;
