import React from "react";

const useCourseLink = path => {
  const courseContext = React.useContext(window.CourseContext);

  if (!courseContext) {
    return path;
  }
  return courseContext.getCourseLink(path);
};

export default useCourseLink;
