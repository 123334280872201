import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

export const GET_MANY_USER_EVALUATIONS = gql`
  query getManyUserEvaluations($userEvaluationArgs: [UserEvaluationArgs!]!) {
    getManyUserEvaluations(userEvaluationArgs: $userEvaluationArgs) {
      id
      objectName
      objectId
      source
      evaluation
      createdAt
      updatedAt
    }
  }
`;

const useGetManyUserEvaluations = (userEvaluationArgs, filterEmpty = false) => {
  let results = [];

  const { loading, error, data } = useQuery(GET_MANY_USER_EVALUATIONS, {
    variables: { userEvaluationArgs },
    context: {
      batch: true,
    },
  });

  if (error) {
    console.log("Error in useGetManyUserEvaluations", error);
  }

  if (data && data.getManyUserEvaluations) {
    results = data.getManyUserEvaluations;
    if (filterEmpty) {
      results = results.filter(result => result.length > 0);
    }
  }

  return [results, loading];
};

export default useGetManyUserEvaluations;
