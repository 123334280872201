import React from "react";
import {motion} from "framer-motion";
import CDN from "utilities/constants/CDN";
import {Trans} from "Components/index";
import useSubscriptionTrialBanner from "Components/SubscriptionTrialBanner/useSubscriptionTrialBanner";
import SubscriptionTrialBannerActions from "Components/SubscriptionTrialBanner/SubscriptionTrialBannerActions";
import CTASection from "../CTASection/CTASection";
import {Alert, Logo} from "../_Elements";
import PropTypes from "prop-types";
import classnames from "classnames";
const SubscriptionTrialBanner = ({
  showImage = true,
  Component = "CtaSection",
  type = "primary",
  icon = false,
  className,
}) => {
  const {
    hideBanner,
    loggedIn,
    studentEmailVerified,
    userLoading,
  } = useSubscriptionTrialBanner();

  if (userLoading || hideBanner) return null;

  const Tag = Component === "CtaSection" ? CTASection : Alert;

  return (
    <div className={classnames("overflow-hidden relative", className)}>
      <Tag
        type={type}
        imageComponent={
          showImage && (
            <motion.img
              src={CDN + "/images/logo-gold.svg"}
              className="translate-x-20 mt-4 scale-[200%] h-20 w-full md:h-56"
              style={{
                "-webkit-mask-image":
                  "linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.5) 50%, rgba(0,0,0,1) 75% )",
                maskImage:
                  "linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.5) 50%, rgba(0,0,0,1) 75% )",
              }}
            />
          )
        }
        inverted={true}
        title={
          <Trans className="white-space-nowrap z-10">
            Try Kollin free for a month
          </Trans>
        }
        subTitle={getSubTitle(loggedIn, studentEmailVerified)}
        icon={icon && <Logo size="md" type="gold" showText={false} />}
        closable={true}
      >
        <SubscriptionTrialBannerActions
          studentEmailVerified={studentEmailVerified}
          loggedIn={loggedIn}
        />
      </Tag>
    </div>
  );
};

export const getSubTitle = (loggedIn, studentEmailVerified) => {
  if (!loggedIn)
    return (
      <Trans className="white-space-nowrap z-50">
        Start with logging in or creating an account and then verify your
        student email address
      </Trans>
    );

  // If logged in and already verified the email
  if (studentEmailVerified) {
    return (
      <Trans className="white-space-nowrap z-50">
        Congratulations! You have verified your student email address already.
      </Trans>
    );
  }

  // If logged in and not verified the email
  return (
    <Trans className="w-full white-space-nowrap z-50">
      As a student, you get 1 month for free. Just verify your student status.
    </Trans>
  );
};

SubscriptionTrialBanner.propTypes = {
  showImage: PropTypes.bool,
  Component: PropTypes.oneOf(["CtaSection", "Alert"]),
  /**
   * Type of the Alert
   */
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ]),
  /**
   *  Only for Alert
   */
  icon: PropTypes.bool,
};

export default SubscriptionTrialBanner;
