import React from "react";
import EarnCreditsSection from "Widgets/Credits/EarnCredits/EarnCreditsSection";
import {Trans} from "Components";
import {useCreateReviewState} from "routes/Course/CourseHome/CourseReviewContainer";
import useGetCurrentUserCourseReview from "API/queries/reivew/useGetCurrentUserCourseReview";
import CourseReviewForm from "Components/CourseReviewForm/CourseReviewForm";
import CDN from "utilities/constants/CDN";

const EarnCreditsCourseReview = () => {
  const {user} = React.useContext(UserContext);

  const {courseId, price, courseName, isCommunityCourse} = React.useContext(
    CourseContext
  );

  const state = useCreateReviewState({id: courseId});
  const [currentUsersReview, loading] = useGetCurrentUserCourseReview();

  if (isCommunityCourse) {
    return null;
  }

  const descriptionNotCompleted = (
    <Trans>You get 10 credits for your review.</Trans>
  );
  const descriptionCompleted = (
    <Trans>You have gotten 10 credits. Thanks for your review!</Trans>
  );

  return (
    <EarnCreditsSection
      title={<Trans>Write a review for the course</Trans>}
      reward={10}
      description={
        currentUsersReview ? descriptionCompleted : descriptionNotCompleted
      }
      completed={currentUsersReview}
      loading={loading}
      imageSrc={CDN + "/images/penguin-letter-heart.jpeg"}
    >
      <CourseReviewForm state={state} />
    </EarnCreditsSection>
  );
};

export default EarnCreditsCourseReview;
