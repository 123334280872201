import React from "react";
import classnames from "classnames";

const ExamUploadStepBody = ({
  activeComponent,
  inactiveComponent,
  status,
  fullWidth,
}) => {
  switch (status) {
    case "not started":
      return <div className="py-8">{inactiveComponent}</div>;
    case "current":
      return (
        <div className={classnames(fullWidth ? "py-2" : "py-8")}>
          {activeComponent}
        </div>
      );
    case "completed":
      return <div />;
    default:
      return null;
  }
};

export default ExamUploadStepBody;
