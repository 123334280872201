import React from "react";
import EmptyState from "Components/EmptyState/EmptyState";
import {Button, Trans} from "Components";
import {useHistory} from "react-router-dom";

const MyCoursesEmpty = () => {
  const history = useHistory();

  return (
    <EmptyState
      size="sm"
      type="gray"
      illustration="cloud"
      title={<Trans>Du har inga aktiva kurser</Trans>}
      subTitle={
        <Trans>
          Du har inte aktiverat några kurser än. Du kan se dina kurser under
          fliken "Programplan" till vänster eller klicka på knappen nedan för
          hitta fler kurser.
        </Trans>
      }
      primaryAction={
        <Button
          iconLeft="telescope"
          onClick={() => history.push("/find-courses")}
        >
          Hitta kurser
        </Button>
      }
    />
  );
};

export default MyCoursesEmpty;
