import React from "react";
import EarnCreditsSection from "Widgets/Credits/EarnCredits/EarnCreditsSection";
import {Button, Icon, Trans, Typography} from "Components";
import StudentVerification from "Widgets/User/StudentVerification/StudentVerification";
import CDN from "utilities/constants/CDN";
import useEarnCreditsWelcomeGift from "Widgets/Credits/EarnCredits/EarnCreditsWelcomeGift/useEarnCreditsWelcomeGift";

const EarnCreditsWelcomeGift = () => {
  const {
    user,
    completed,
    loading,
    handleRedeem,
    handleRedeemLoading,
  } = useEarnCreditsWelcomeGift();

  return (
    <EarnCreditsSection
      imageSrc={CDN + "/images/penguin-gift.jpeg"}
      loading={loading}
      title={<Trans>Redeem your welcome gift</Trans>}
      description={
        <Trans>Verify your student email and redeem your welcome gift</Trans>
      }
      reward={10}
      completed={completed}
      defaultExpanded
    >
      {user?.student_email ? (
        <div className="flex items-center gap-4">
          <div className="flex gap-2">
            <Icon icon="far fa-check-circle" color="text-primary-500" />
            <Typography inline>{user.student_email} </Typography>
          </div>
          <Button
            onClick={handleRedeem}
            type="secondary"
            size="sm"
            loading={handleRedeemLoading}
          >
            <Trans>Redeem your gift</Trans>
          </Button>
        </div>
      ) : (
        <StudentVerification
          inlineForm
          transparent={false}
          showModal={false}
          inputHint={
            <Trans type="txs">
              Enter your university e-email address and we will send you a
              verification link
            </Trans>
          }
        />
      )}
    </EarnCreditsSection>
  );
};

export default EarnCreditsWelcomeGift;
