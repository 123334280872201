import React from "react";
import {useHistory, useParams} from "react-router-dom";
import useGetGroupMemberInvite from "API/queries/groupMemberInvite/useGetGroupMemberInvite";
import {PageLogoLoader} from "Components";
import subdomains from "Providers/GroupProvider/subdomains";
import useAcceptGroupMemberInvite from "API/mutations/groups/useAcceptGroupMemberInvite";

const useGroupInvitation = () => {
  const {token} = useParams();

  const [invite, loading] = useGetGroupMemberInvite(token);

  // Check what the group is
  const key = invite?.group?.key || "";

  const subdomain = Object.keys(subdomains).find(
    s => subdomains[s].key === key
  );

  const site = window.location.host;

  const currentSubdomain = window.location.hostname.split(".")[0];

  // If the subdomain is not the same as the current subdomain, redirect to the correct subdomain
  React.useEffect(() => {
    if (subdomain && currentSubdomain !== subdomain) {
      const redirectUrl = window.location.href.replace(
        site,
        subdomain + "." + site
      );

      window.location.href = redirectUrl;
    }
  }, [currentSubdomain, subdomain]);

  return {
    loading,
    invite,
  };
};

export default useGroupInvitation;
