import React from "react";
import classnames from "classnames";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {useHistory} from "react-router-dom";
import {Button, Typography} from "Components";
import {CourseContext} from "routes/Course/CourseContextProvider";
import {kebabCaseSplitter, truncateText} from "utilities/text";
import Link from "Components/_Elements/Link/Link";

const GroupNavigationSidebarHeader = ({collapsed, showMenu, setShowMenu}) => {
  const {imageUrl, key, name} = useGroupContext();
  const history = useHistory();

  return (
    <div
      className={classnames(
        "relative flex justify-between items-center rounded-lg cursor-pointer w-full md:pt-4"
      )}
    >
      <div
        className={classnames(
          "gap-2 surface  overflow-hidden rounded-full",
          collapsed ? "p-0 mt-2 ml-1" : "p-1"
        )}
        onClick={() => {
          history.push("/");
        }}
      >
        <img
          className={classnames(
            "rounded-full",
            "w-8",
            collapsed ? "md:w-8" : "md:w-16"
          )}
          src={imageUrl}
        />
      </div>

      <div className="block md:hidden">
        <Button
          onClick={() => setShowMenu(!showMenu)}
          icon={showMenu ? "close" : "bars"}
          type="link"
          size="xl"
          iconColor="text-white"
        />
      </div>
    </div>
  );
};

export default GroupNavigationSidebarHeader;
