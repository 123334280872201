import React from "react";
import useStudyPlanCardActions from "./useStudyPlanCardActions";
import { Button, Trans, Link } from "Components";
import { useLocation } from "react-router";

const StudyPlanCardActions = ({ studyPlan, backUrl }) => {
  const location = useLocation();

  const { price, onClick, courseId } = useStudyPlanCardActions(studyPlan);

  return (
    <div className="flex items-center justify-center flex-col mt-3 mb-6">
      {price?.priceCents > 0 && !studyPlan.purchased && (
        <Button type="primary" onClick={onClick} size="sm">
          <Trans>Unlock</Trans>
        </Button>
      )}
      <Link
        to={{
          pathname: `/course/${courseId}/study-plan/${studyPlan.id}`,
          state: backUrl ? { backUrl } : { backUrl: location.pathname },
        }}
        basic
      >
        {/*<span>
          {studyPlan.purchased ? <Trans>View</Trans> : <Trans>Preview</Trans>}
        </span>*/}
      </Link>
    </div>
  );
};

export default StudyPlanCardActions;
