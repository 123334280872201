import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const SEND_SUPPORT_MAIL = gql`
  mutation sendSupportMail($email: String!, $message: String!) {
    sendSupportMail(email: $email, message: $message) {
      ok
      error
    }
  }
`;

const useSendSupportEmail = callback => {
  const [
    sendSupportMail,
    { data, error, loading },
  ] = useMutation(SEND_SUPPORT_MAIL, { onCompleted: callback });

  if (error) {
    console.log(`Error in useSendSupportEmail: ${error}`);
  }
  return { sendSupportMail, data, loading };
};

export default useSendSupportEmail;
