import {Link, Trans, Typography} from "Components";
import React from "react";
import ExamCardProgress from "./ExamCardProgress";

const ExamCard = ({
                    date,
                    author,
                    exam_exercises_src,
                    exam_solutions_src,
                    showProgress,
                    id,
                  }) => {
  return (
    <div className="background p-3 rounded-lg">
      <div className="flex items-start justify-between">
        <div className="flex flex-col items-start">
          <div className="bg-primary-50 px-2 py-1 rounded-lg">
            <Typography type="tsm" weight="sb" color="text-primary-600">
              {date}
            </Typography>
          </div>
          <Typography className="mt-2">{author}</Typography>
        </div>
        <div className="flex gap-2">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            disabled={exam_exercises_src == null}
            href={exam_exercises_src}
            iconRight="fa-regular fa-external-link-alt"
            size="2xs"
          >
            <Trans>Exercises</Trans>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            disabled={exam_solutions_src == null}
            href={exam_solutions_src}
            iconRight="fa-regular fa-external-link-alt"
            size="2xs"
          >
            <Trans>Solutions</Trans>
          </Link>
        </div>
      </div>
      {showProgress && <ExamCardProgress examId={id}/>}
    </div>
  );
};

export default ExamCard;
