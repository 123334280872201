import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {GET_CURRENT_USER_MEMBERSHIP} from "API/queries/user/useGetCurrentUserSubscription";

const UPDATE_MEMBERSHIP_CANCEL_AT_PERIOD_END = gql`
  mutation updateMembershipCancelAtPeriodEnd($cancelAtPeriodEnd: Boolean!) {
    updateMembershipCancelAtPeriodEnd(cancelAtPeriodEnd: $cancelAtPeriodEnd) {
      ok
      error {
        code
        message
      }
      membership {
        id
        stripeData {
          cancelAtPeriodEnd
          cancelAt
        }
      }
    }
  }
`;

const useUpdateMembershipCancelAtPeriodEnd = onCompleted => {
  const [updateMembershipCancelAtPeriodEnd, {data, loading}] = useMutation(
    UPDATE_MEMBERSHIP_CANCEL_AT_PERIOD_END,
    {
      onCompleted,
    }
  );

  return {
    updateMembershipCancelAtPeriodEnd,
    data,
    loading,
  };
};

export default useUpdateMembershipCancelAtPeriodEnd;
