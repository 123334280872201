import React from "react";
import {Button, TextArea, Trans} from "Components";
import {useTranslation} from "react-i18next";
import useExerciseNotesNewNoteState from "./useExerciseNotesNewNoteState";
import classnames from "classnames";
import {motion} from "framer-motion";

const ExerciseNotesNewNote = ({exerciseId}) => {
  const {t: trans} = useTranslation();

  const {
    values,
    handleChange,
    submit,
    submitDisabled,
    loading,
    onCancel,
    error,
  } = useExerciseNotesNewNoteState(exerciseId);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (!submitDisabled) {
          submit();
        }
      }}
    >
      <TextArea
        text={values.text}
        onChange={handleChange}
        name="text"
        expandOnFocus={true}
        className={classnames("w-full border-gray-300 bg-warning-50 mt-4")}
        placeholder={trans("Write a new note")}
      />

      <motion.div
        initial={{opacity: 0, height: 0}}
        animate={{
          opacity: submitDisabled ? 0 : 1,
          height: submitDisabled ? 0 : "auto",
        }}
        exit={{opacity: 0}}
        className={classnames("flex items-center justify-end mt-2 gap-4")}
      >
        <Button type="link" onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="secondary"
          disabled={submitDisabled}
          loading={loading}
          onClick={submit}
        >
          <Trans>Save</Trans>
        </Button>
      </motion.div>
    </form>
  );
};

export default ExerciseNotesNewNote;
