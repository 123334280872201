import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import _ from "lodash";

const GET_COURSE = gql`
  query getCourse(
    $courseId: ID!
    $includeTags: Boolean!
    $includeTagExercises: Boolean!
    $includeTopicChapters: Boolean!
  ) {
    getCourse(id: $courseId) {
      id
      students
      modules {
        order
        id
        name
        has_flashcards
        tags @include(if: $includeTags) {
          id
          name
          exercises(course_id: $courseId) @include(if: $includeTagExercises) {
            id
            score
            self_evaluation {
              id
              score
            }
          }
          chapters @include(if: $includeTopicChapters) {
            id
            chapter_type
          }
        }
      }
    }
  }
`;

const useGetCourseModules = (
  courseId,
  includeTags = false,
  includeTagExercises = false,
  includeTopicChapters = false,
  fetchPolicy = "cache-and-network"
) => {
  const {loading, error, data} = useQuery(GET_COURSE, {
    variables: {
      courseId,
      includeTags,
      includeTagExercises,
      includeTopicChapters,
    },
    fetchPolicy,
  });

  if (error) return `Error in useGetCourseModules ${error.message}`;

  let modules = [];
  let students = null;

  if (data) {
    modules = data.getCourse.modules;
    modules = _.sortBy(modules, "order");
  }

  return [modules, loading];
};

export default useGetCourseModules;
