import React from "react";
import useGetAllExamAuthors from "API/queries/exam/useGetallExamAuthors";

const useExamUploadGlobalAuthors = () => {
  const [authors, setAuthors] = React.useState(new Set([]));

  // Get the authors from the
  const [allAuthors, loading] = useGetAllExamAuthors();

  React.useEffect(() => {
    // When the authors are loaded, set the global authors
    if (!loading && authors.size === 0) {
      setAuthors(new Set([...allAuthors]));
    }
  }, [allAuthors, loading]);

  // Add an author to the list of authors
  const addGlobalAuthor = newAuthor => {
    setAuthors(authors => new Set([...authors, newAuthor]));
  };

  return {
    globalAuthors: authors,
    addGlobalAuthor,
  };
};

export default useExamUploadGlobalAuthors;
