import React from "react";
import {ExamUploadContext} from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContext";
import ExamUploadDocumentsRow from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsRow/ExamUploadDocumentsRow";
import {motion} from "framer-motion";
import classnames from "classnames";
import ExamUploadDocumentsPreview from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsPreview/ExamUploadDocumentsPreview";
import ExamUploadDocumentsOverview from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsOverview/ExamUploadDocumentsOverview";
import ExamUploadSaveBanner from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadSaveBanner";

const ExamUploadDocuments = ({showOutput}) => {
  const {documents, previewResource} = React.useContext(ExamUploadContext);

  const dates = React.useMemo(() => Object.keys(documents["dates"]) || [], [
    documents,
  ]);

  return (
    <div>
      <ExamUploadDocumentsOverview key="overview" />
      <div
        className={classnames(
          "grid  grid-cols-2 gap-4 items-start w-full h-full pb-16"
          // If there is a preview resource, show the preview resource on the right and the table on the left
        )}
        key="container"
      >
        <motion.div
          className={classnames(previewResource ? "col-span-1" : "col-span-2")}
          key="documents"
        >
          {dates.map((date, index) => {
            // The unknown_date and unknown_type are arrays of documents and are not shown in this table.

            if (documents.dates[date].hidden) {
              return <div key={date} />;
            }

            return (
              <ExamUploadDocumentsRow
                key={date}
                index={index}
                date={date}
                document={documents.dates[date]}
                isLast={index === dates.length - 1}
              />
            );
          })}
        </motion.div>

        {/*<AnimatePresence>*/}
        {// If there is a preview resource, show the preview resource on the right and the table on the left
        previewResource && (
          <motion.div
            key="preview"
            className="col-span-1 h-screen sticky top-0 mt-2"
            initial={{opacity: 0, x: 100}}
            animate={{opacity: 1, x: 0}}
            exit={{opacity: 0, x: 100}}
          >
            <ExamUploadDocumentsPreview />
            <ExamUploadSaveBanner />
          </motion.div>
        )}
        {/*</AnimatePresence>*/}
      </div>
    </div>
  );
};

export default ExamUploadDocuments;
