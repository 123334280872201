import React from "react";
import {Trans} from "Components";
import {sheets} from "API/sheets/index";

/**
 * Generic hook for submitting a form to a google sheet. The parameters to the form must be passed in as a variable to the submit function
 * @param {String} sheetKey - The key of the google sheet to submit to (defined in API/sheets/index.js => sheets{})
 * @returns {Object} state - The state of the form
 * @returns {boolean} state.loading - Whether the form is loading
 * @returns {boolean} state.success - Whether the form was submitted successfully
 * @returns {string} state.error - Whether there was an error submitting the form
 * @returns {useSubmitForm~submit} state.submit - The function to submit the form
 */
const useSubmitForm = (sheetKey, id, name) => {
  const [response, setResponse] = React.useState({
    success: false,
    error: null,
    loading: false,
  });

  let spreadSheetId;
  let sheetName;

  if (sheets[sheetKey]) {
    spreadSheetId = sheets[sheetKey].spreadSheetId;
    sheetName = sheets[sheetKey].sheetName;
  } else {
    spreadSheetId = id;
    sheetName = name;
  }

  /**
   * Function to submit the form to the google sheet
   * @param {Object} variables - Key value pairs of the variables to be submitted
   * @returns {Promise} - A promise that resolves when the form is submitted
   */

  const submit = async variables => {
    const body = getBody(variables, spreadSheetId, sheetName);

    try {
      setResponse({
        success: false,
        error: null,
        loading: true,
      });

      const response = await fetch(
        "https://script.google.com/macros/s/AKfycby4tOk9JuS_BfWnaNaSTfBLDLF5Pwy4Qq6WZEJ56pjyDTnhUUDkLj_ZENiuqMXT0gmqFg/exec",
        {
          method: "POST",
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          redirect: "follow", // manual, *follow, error
          body,
          headers: {
            Accept: "*/*",
          },
        }
      );

      const json = await response.json();

      if (json.result == "success") {
        setResponse({
          success: true,
          error: null,
          loading: false,
        });
      } else if (json.result == "error") {
        setResponse({
          success: false,
          error: json.error,
          loading: false,
        });
      } else {
        setResponse({
          success: false,
          error: "Unknown error",
          loading: false,
        });
      }
    } catch (error) {
      setResponse({
        success: false,
        error: <Trans>Unexpected error occurred</Trans>,
        loading: false,
      });
    }
  };

  return {
    ...response,
    submit,
  };
};

const getBody = (variables, spreadSheetId, sheetName) => {
  const body = new FormData();

  for (var key in variables) {
    body.append(key, variables[key]);
  }
  body.append("spreadSheetId", spreadSheetId);
  body.append("sheetName", sheetName);
  body.append("timestamp", new Date().toISOString());

  return body;
};

export default useSubmitForm;
