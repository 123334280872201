import documentFindAuthors from "API/lambdas/documentFindAuthor";

const findExaminerInDocument = ({ trie, authorOptionsSet, docUrl, text }) =>
  new Promise(async (resolve, reject) => {
    // Check if any of the authors are in the text
    // Convert set to array
    const authorsArray = Array.from(authorOptionsSet);

    const foundAuthors = authorsArray.filter(author => {
      try {
        // Regex match the author name in the text
        const regex = new RegExp(`\\b${author}\\b`, "g");
        return regex.test(text);

        // Take surname(s) only i >= 1, split on space or dash
        let surnames = author
          .split(/[\s-]/g)
          .slice(1)
          .join(" ");

        if (!surnames || surnames.length === 1) return false;

        // Replace 'von ' with '' because the surname trie not handle words with spaces
        surnames = surnames.replace("von ", "");

        // Check if the surname(s) are in the document
        return trie.search(surnames);

        // const regex = new RegExp(`\\b${surname}\\b`, "g");
        // return trie.search(surnameWithSpaces);
      } catch (err) {
        console.log("Error in findExaminerInDocument: ", err);
        return false;
      }
    });

    if (foundAuthors.length > 0) {
      // Clean up the found authors, strip out extra spaces
      const cleanedFoundAuthors = foundAuthors.map(author => {
        return author.replace(/^\s+/g, "").replace(/\s+$/g, "");
      });

      // Convert to set to remove duplicates
      const uniqAuthors = [...new Set(cleanedFoundAuthors)];

      // join with comma
      return resolve(uniqAuthors.join(", "));
    }

    // If no authors found try with the author finder lambda
    const options = await documentFindAuthors(docUrl);

    // Get the text from the document
    return resolve(options);
  });

export default findExaminerInDocument;
