import React from "react";
import {Button, Icon, Trans, Typography} from "Components";
import useGroupInvitesCreate from "routes/Group/GroupInvites/GroupInvitesCreate/useGroupInvitesCreate";
import {motion} from "framer-motion";
import i18n from "i18next";

const GroupInvitesCreate = ({
  email,
  buttonType = "gray",
  triggerMutation,
  triggerDelay,
}) => {
  const {response, loading, handleInviteCreate} = useGroupInvitesCreate({
    email,
    triggerMutation,
    triggerDelay,
  });

  return (
    <div className="flex items-center justify-end">
      {response?.ok && <GroupInvitesCreateSuccess />}
      {response?.error && <GroupInvitesCreateError {...response?.error} />}
      {!response && (
        <Button
          size="sm"
          type={buttonType}
          onClick={handleInviteCreate}
          loading={loading}
          disabled={response?.ok || response?.error}
        >
          <Trans>Invite member</Trans>
        </Button>
      )}
    </div>
  );
};

const GroupInvitesCreateSuccess = () => {
  return (
    <motion.div
      className="flex items-center gap-2"
      initial={{opacity: 0, x: 300}}
      animate={{opacity: 1, x: 0}}
    >
      <Icon size="sm" icon="check-circle" color="text-success-light" />
      <Trans type="tsm" weight="normal" color="text-success-light">
        Invite sent
      </Trans>
    </motion.div>
  );
};

const GroupInvitesCreateError = ({code, message}) => {
  const translatedMessage = ErrorCodes[code] || message;

  let icon = "circle-x";
  let color = "text-error-light";

  if (code === "GMI05" || code === "GMI06") {
    icon = "triangle-exclamation";
    color = "text-warning-light";
  }

  return (
    <motion.div
      className="flex items-center gap-2"
      initial={{opacity: 0, x: 300}}
      animate={{opacity: 1, x: 0}}
    >
      <Icon size="sm" icon={icon} color={color} />
      <Typography type="tsm" weight="normal" color={color}>
        {translatedMessage}
      </Typography>
    </motion.div>
  );
};

export default GroupInvitesCreate;
