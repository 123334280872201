import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

import useSelect from "./useSelect";
import {sizesMap} from "Components/_Elements/Select/Select.constants";
import SelectInput from "Components/_Elements/Select/SelectInput";
import SelectItemsList from "Components/_Elements/Select/SelectItemsList";

const Select = ({
  inverted,
  loading,
  transparent,
  borderless,
  disabled,
  placeholder,
  options,
  className,
  onChange,
  overrideCallback,
  defaultSelectedValue = null,
  onSelect,
  value,
  size = "md",
  showSearch = false,
  fluid = true,
  showIconThreshold = 10,
  ...props
}) => {
  const {
    selectRef,
    selectedItem,
    handleItemClick,
    focused,
    handleFocus,
    handleBlur,
    handleSearch,
    searchTerm,
    items,
  } = useSelect({
    onChange,
    overrideCallback,
    defaultSelectedValue,
    onSelect,
    options,
    value,
  });

  return (
    <div
      ref={selectRef}
      className={classnames(
        "select-none",
        fluid && "w-full",

        disabled && "cursor-not-allowed pointer-events-none"
      )}
    >
      <SelectInput
        {...{
          size: size,
          placeholder: placeholder,
          disabled: disabled,
          borderless: borderless,
          inverted: inverted,
          selectedItem: selectedItem,
          transparent: transparent,
          focused: focused,
          handleFocus,
          handleBlur,
          showSearch,
          fluid,
          handleSearch,
          searchTerm,
        }}
      />
      <div className="relative">
        <SelectItemsList
          {...{
            showIconThreshold,
            items,
            size,
            focused,
            handleItemClick,
            handleSearch,
            searchTerm,
            showSearch,
          }}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line react/no-typos
Select.propTypes = {
  label: PropTypes.string,
  styles: PropTypes.object,
  loading: PropTypes.bool,
  defaultSelectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      text: PropTypes.string.isRequired,
      image: PropTypes.string,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.keys(sizesMap)),
};

export default Select;
