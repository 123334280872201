import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_CURRENT_USER_COUPON_USAGES = gql`
  query getCurrentUserCouponUsages($couponId: Int) {
    currentUserCouponUsages(couponId: $couponId) {
      id
      coupon {
        id
        name
      }
    }
  }
`;

const useGetCurrentUserCouponUsages = ({couponId = null}) => {
  let couponUsages = [];

  const {loading, error, data} = useQuery(GET_CURRENT_USER_COUPON_USAGES, {
    variables: {couponId},
  });

  if (error) {
    console.log("useGetCurrentUserCouponUsages error: ", error);
  }

  if (data && data.currentUserCouponUsages) {
    couponUsages = data.currentUserCouponUsages;
  }

  return [couponUsages, loading];
};

export default useGetCurrentUserCouponUsages;
