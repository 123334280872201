import React from "react";
import classnames from "classnames";
import {Avatar, Badge, Button, Icon, Trans, Typography} from "Components";
import {useTranslation} from "react-i18next";
import moment from "moment";
import GroupInvitesDelete from "routes/Group/GroupInvites/GroupInvitesDelete";

const GroupInvitesUser = ({
  id,
  user,
  email,
  accepted,
  toBeInvited = null,
  updatedAt,
  createdAt,
  action = null,
  showAvatar = true,
  showStatus = true,
  backgroundColor = "surface",
  grid = true,
}) => {
  const {t: trans} = useTranslation();

  return (
    <div
      className={classnames(
        " p-4 rounded-xl w-full mb-2 last:mb-0",
        grid ? "grid grid-cols-2 gap-2 md:grid-cols-5" : "flex gap-4",
        "items-center justify-between",
        accepted ? "surface-purple" : backgroundColor
      )}
    >
      {showAvatar && (
        <Avatar
          user={
            user
              ? user
              : {
                  id: email,
                }
          }
          size="sm"
          showRole={true}
          showProgramInfo={true}
          name={
            user ? (
              <Typography className="truncate" type="tsm" weight="sb">
                {user?.firstName + (" " + user?.lastName || "")}
              </Typography>
            ) : (
              <Trans className="truncate" type="tsm" weight="sb">
                Unknown user
              </Trans>
            )
          }
        />
      )}
      <div className="flex gap-4 items-center col-span-2">
        <Icon icon="envelope" size="sm" color="text-gray-light" />
        <Typography color="text-gray" weight="md" className="whitespace-nowrap">
          {email}
        </Typography>
      </div>

      {showStatus && (
        <GroupInvitesUserStatus {...{id, accepted, toBeInvited}} />
      )}
      {action || (
        <GroupInvitesUserActions {...{id, accepted, updatedAt, createdAt}} />
      )}
    </div>
  );
};

const GroupInvitesUserStatus = ({id, accepted, toBeInvited}) => {
  if (toBeInvited) {
    return (
      <Badge type="darkGray" size="md" icon="clock" border={false}>
        <Trans type="tsm" weight="sb">
          Not invited
        </Trans>
      </Badge>
    );
  }

  if (!accepted) {
    return (
      <Badge type="purple" size="md" icon="clock" border={false}>
        <Trans type="tsm" weight="sb">
          Pending
        </Trans>
      </Badge>
    );
  }

  return (
    <Badge type="success" size="md" icon="check-circle" border={false}>
      <Trans type="tsm" weight="sb">
        Joined
      </Trans>
    </Badge>
  );
};

const GroupInvitesUserActions = ({id, accepted, createdAt, updatedAt}) => {
  const {t: trans} = useTranslation();

  if (!accepted) {
    return (
      <div className="flex gap-4 items-center">
        <GroupInvitesDelete id={id} />
        <Typography
          type="tsm"
          weight="sb"
          color="text-gray-light"
          className="whitespace-nowrap"
        >
          {trans("Invited:")} {moment(createdAt).fromNow()}
        </Typography>
      </div>
    );
  }
  return (
    <div className="flex gap-4 items-center">
      <Typography
        type="tsm"
        weight="sb"
        color="text-success-light "
        className="whitespace-nowrap"
      >
        {trans("Joined:")} {moment(createdAt).fromNow()}
      </Typography>
    </div>
  );
};

export default GroupInvitesUser;
