import React from "react";
import {Trans} from "react-i18next";
import {Button, Typography} from "Components";
import Stats from "Components/Stats/Stats";
import useGetAllStats from "API/queries/stats/useGetAllStats";
import "./LandingPagePhotoBanner.scss";
import {useHistory} from "react-router-dom";

const LandingPagePhotoBanner = () => {
  const history = useHistory();
  const {user} = React.useContext(window.UserContext);

  const [stats, loading] = useGetAllStats();
  const content = window.content.homepage || {heroImage: {}};

  return (
    <div
      className="row lading-page-photo-banner"
      style={{backgroundImage: `url(${content.heroImage.url})`}}
    >
      <div className="col-xl-5 col-md-6 col-xs-12 pt-xs-2 lading-page-photo-banner-text">
        <Typography type="dlg" weight="sb">
          {content.title}
        </Typography>
        <Typography className="mt-4" type="dsm">
          {content.subtitle}
        </Typography>
        {!user && (
          <div className="mt-4">
            <Button
              type="primary"
              size="xl"
              onClick={() => {
                history.push({
                  pathname: "/signup",
                  state: {
                    nextUrl: "/trial",
                  },
                });
                window.analytics.track("homepage_free_trial_clicked");
              }}
            >
              <Trans>Try free for a month</Trans>
            </Button>
          </div>
        )}
        <img src={content.heroImage.url} alt={content.heroImage.title} />
        <div className="lading-page-photo-banner-stats">
          <Stats stats={stats}></Stats>
        </div>
      </div>
    </div>
  );
};

export default LandingPagePhotoBanner;
