import React from "react";
import classNames from "classnames";
import WidgetHeader from "./WidgetHeader";
import {Link} from "../_Elements";

const Widget = ({
  widgetName,
  title,
  description,
  icon,
  children,
  background = "bg-white",
  borderColor = "border-gray",
  action = null,
  feedbackEnabled = true,
}) => {
  return (
    <div
      className={classNames(
        "mt-2",
        "px-3 py-4 sm:px-4 ",
        "border-1 rounded-md sm:rounded-lg sm:shadow-sm",
        borderColor,
        background
      )}
    >
      <WidgetHeader
        title={title}
        description={description}
        icon={icon}
        widgetName={widgetName}
        feedbackEnabled={feedbackEnabled}
      />

      <div>{children}</div>
      {action && action.type === "link" && (
        <div className="mt-4 text-right">
          <Link to={action.to}>{action.label}</Link>
        </div>
      )}
    </div>
  );
};

export default Widget;
