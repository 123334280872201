import React from "react";
import { Icon, Input, Typography } from "Components";

import { motion } from "framer-motion";
import { Summer2022CampaignOnBoardingEnrollmentInfoContainer } from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignOnBoarding/Summer2022CampaignOnBoardingEnrollment/Summer2022CampaignOnBoardingEnrollment";

const Summer2022CampaignOnBoardingEnrollmentYear = ({ state, setState }) => {
  const { enrollmentYear } = state;

  if (enrollmentYear == null || enrollmentYear != new Date().getFullYear()) {
    return (
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
      >
        <Input
          onChange={e => setState({ enrollmentYear: e.target.value })}
          name="enrollmentYear"
          value={enrollmentYear}
          type="number"
        />
      </motion.div>
    );
  }

  return (
    <Summer2022CampaignOnBoardingEnrollmentInfoContainer
      label="Startår"
      onClick={() => {
        setState({
          enrollmentYear: null,
        });
      }}
    >
      <Icon icon="fa-duotone fa-graduation-cap" size="2xl" />
      <Typography type="tsm" weight="sb" color="text-primary-800">
        {enrollmentYear}
      </Typography>
    </Summer2022CampaignOnBoardingEnrollmentInfoContainer>
  );
};

export default Summer2022CampaignOnBoardingEnrollmentYear;
