import i18n from "i18next";

export default {
  GEN01: i18n.t("The request object was not found"),
  GEN02: i18n.t("Unexpected error occurred"),
  AUTH00: i18n.t(
    "Invalid input for authentication strategy or missing strategy"
  ),
  AUTH01: i18n.t("Password or email is incorrect"),
  AUTH02: i18n.t("User has not set a password"),
  AUTH03: i18n.t("Social login user does not exist"),
  AUTH04: i18n.t("Social login failed, token is invalid"),
  AUTH05: i18n.t("Internal error please try again later"),
  AUTH10: i18n.t("Invalid signup method"),
  AUTH11: i18n.t("Invalid arguments for signup method"),
  AUTH12: i18n.t(
    "An account with this email already exists, please login instead"
  ),
  AUTH13: i18n.t(
    "Unexpected error in signup method, please try again later or contact support"
  ),
  USR01: i18n.t("User could not be found"),
  USR02: i18n.t("Error when uploading avatar"),
  USR03: i18n.t("Invalid format for avatar, must be one of: .jpg, .png, .svg"),
  ORD01: i18n.t("Order not found"),
  ORD02: i18n.t("Order cancelled"),
  ORD03: i18n.t("Unhandled error when creating order"),
  ORD04: i18n.t("Unhandled payment method"),
  ORD05: i18n.t("Amount too low to pay for the selected payment method"),
  ORD20: i18n.t("Error when trying to finalize swish order"),
  ORD21: i18n.t("Phone number not provided or invalid"),
  ORD22: i18n.t("Unexpected error when creating swish payment request"),
  ORD23: i18n.t("Amount too low to pay with Swish"),
  ORD30: i18n.t(
    "Success url and cancel url is required for STRIPE payment method"
  ),
  PRD01: i18n.t("Product type does not exist"),
  PRD02: i18n.t("Product id does not exist"),
  PRD03: i18n.t("Product price could not be found"),
  PRD04: i18n.t("Product price cannot be applied to the given product"),
  PNV01: i18n.t("Verification could not be created"),
  PNV02: i18n.t("Verification code could not be validated"),
  PNV03: i18n.t("Invalid verification code"),
  PNV04: i18n.t("This phone number has already been verified"),
  PRG01: i18n.t("Failed to create program course"),
  STC01: i18n.t("Not eligible for coupon, user already has a membership"),
  STC02: i18n.t("Subscription trial coupon has expired"),
  STC03: i18n.t("Failed to create subscription"),
  STC04: i18n.t("Could not find subscription plan"),
  STC05: i18n.t("Could not find coupon"),
  STC06: i18n.t(
    "Could not find coupon or it has already been attached to a user"
  ),
  STC07: i18n.t("User already has a membership"),
  STC08: i18n.t("The coupon has already been redeemed"),
  STC09: i18n.t("The coupon could not be created"),
  MEM01: i18n.t("Membership could not be found"),
  MEM02: i18n.t(
    "A membership cannot be created right now because there is already an alive membership"
  ),
  MEM03: i18n.t("No active or trialing membership found"),
  MEM04: i18n.t("Unexpected error when cancelling membership"),
  MEM05: i18n.t("Unexpected error when updating membership"),
  SPL01: i18n.t("Subscription plan could not be found"),
  TDT01: i18n.t("Text data could not be found"),
  SRC01: i18n.t("Source could not be found"),
  SRC02: i18n.t("Source already exists for this date"),
  SRC03: i18n.t("Source could not be created"),
  SRC04: i18n.t("Source could not be updated"),
  SRC05: i18n.t("Source could not be deleted"),
  SRC06: i18n.t("Delete source requires owner or admin privileges"),
  SRC12: i18n.t("At least the exercises resource must exist"),
  EXC01: i18n.t("Exercise could not be found"),
  EXC03: i18n.t("Exercise could not be created"),
  EXC04: i18n.t("Exercise image extraction failed"),
  EXC05: i18n.t("Exercise could not be updated"),
  EXC06: i18n.t("Exercise could not be deleted"),
  EXC07: i18n.t("Exercise already exists"),
  SOL01: i18n.t("Solution could not be found"),
  SOL03: i18n.t("Solution could not be created"),
  SOL04: i18n.t("Solution image extraction failed"),
  SOL05: i18n.t("Solution could not be updated"),
  SOL06: i18n.t("Solution could not be deleted"),
  SOL07: i18n.t("Solution already exists"),
  RES01: i18n.t("Resource could not be found"),
  RES02: i18n.t("Resource could not be created"),
  RES03: i18n.t("Resource could not be updated"),
  RES04: i18n.t("Resource could not be deleted"),
  RES05: i18n.t("Resource already exists"),
  RES06: i18n.t("Resource can only be deleted by the uploader"),
  CMD01: i18n.t("Course metadata could not be found"),
  CMD02: i18n.t("Error when setting course metadata"),
  UTS01: i18n.t("User test could not be found"),
  UTS02: i18n.t("User test could not be created"),
  UTS03: i18n.t("User test could not be updated"),
  UTS04: i18n.t("Number of exercises must be greater than 0"),
  UTS05: i18n.t("Not enough exercises to create a test"),
  OTP01: i18n.t("Verification code could not be requested"),
  OTP02: i18n.t(
    "Verification code already requested, please wait at least 30 seconds before requesting a new one"
  ),
  OTP03: i18n.t("Verification code request requires authentication"),
  OTP04: i18n.t("Invalid request"),
  OTP05: i18n.t(
    "The email has already been associated with another account, if this was not done by you please contact support"
  ),
  OTP06: i18n.t("The given email is not associated with any school"),
  OTP07: i18n.t("You have already performed this action"),
  OTP08: i18n.t(
    "The verification code entered is invalid or it may have expired"
  ),
  OTP09: i18n.t("Failed to complete your request, please try again"),
  OTP10: i18n.t("The account does not exist on Kollin"),
  GRP01: i18n.t("Group does not exist"),
  GRP02: i18n.t("Cannot create a public group"),
  GRP04: i18n.t("Group could not be created"),
  GRP05: i18n.t("Group could not be updated"),
  GRP06: i18n.t("Group could not be deleted"),
  GRP07: i18n.t("You are not a member of this group"),
  GRP08: i18n.t("A group with the same key already exists"),
  GMB01: i18n.t("Group member does not exist"),
  GMB02: i18n.t("Group membership could not be created"),
  GMB03: i18n.t("Group membership could not be deleted"),
  GMB04: i18n.t("Group membership could not be updated"),
  GMB05: i18n.t("The group membership requires validation"),
  GMB06: i18n.t("You are already a member of this group"),
  GMB07: i18n.t("You are not a member of this group"),
  GMB08: i18n.t("Could not add group member, maybe the user does not exist"),
  GJR01: i18n.t("Group join request does not exist"),
  GJR02: i18n.t("Group join request could not be accepted"),
  GJR03: i18n.t("Group join request could not be rejected"),
  GJR04: i18n.t("Group join request could not be updated"),
  GJR05: i18n.t("You have already sent a join request to this group"),
  GJR06: i18n.t(
    "The group does not require membership validation, you can join directly"
  ),
  GJR07: i18n.t("The group join request has already been accepted"),
  PER01: i18n.t("You do not have permission to perform this action"),
  PER02: i18n.t("The permission already exists"),
  PER03: i18n.t("The permission does not exist"),
  ACH01: i18n.t("Achievement could not be found"),
  ACH02: i18n.t("Achievement could not be created"),
  ACH03: i18n.t("Achievement could not be updated"),
  ACH04: i18n.t("Achievement could not be deleted"),
  ACH05: i18n.t("User Achievement already exists"),
  ACH06: i18n.t("User Achievement could not be created"),
  GMI01: i18n.t("Group member invite does not exist"),
  GMI02: i18n.t("Group member invite could not be created"),
  GMI03: i18n.t("Group member invite could not be deleted"),
  GMI04: i18n.t("Group member invite could not be updated"),
  GMI05: i18n.t("This user is already a member of this group"),
  GMI06: i18n.t("This user is already invited to this group"),
  GMI07: i18n.t("The invite is invalid or has expired"),
  GMI08: i18n.t("Unexpected error when handling acceptGroupMemberInvite"),
  GMI09: i18n.t("Group member invited but failed to send email"),
};
