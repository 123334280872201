import React from "react";
import {range} from "utilities/numbers";
import {Icon} from "Components";
import useInputCode from "Components/_Elements/Input/InputCode/useInputCode";
import classnames from "classnames";
import InputErrorMessage from "Components/_Elements/Input/InputErrorMessage";
import InputSuccessMessage from "Components/_Elements/Input/InputSuccessMessage";

const sizeMap = {
  sm: "w-10 px-1 py-0.5 text-h5  sm:w-16 sm:px-2 sm:py-1 sm:text-h4 ",
  md: "w-12 px-1 py-0.5 text-h4  sm:w-20 sm:px-2 sm:py-1 sm:text-h3 ",
  lg: "w-14 px-1 py-0.5 text-h3  sm:w-24 sm:px-2 sm:py-1 sm:text-h2 ",
};

const InputCode = ({
  digits = 6,
  onChange,
  size = "sm",
  error = null,
  successMessage = null,
  className,
  fluid = false,
  onlyNumbers = true,
  transparent = false,
}) => {
  const {
    code,
    currentFocusIndex,
    handleChange,
    handlePaste,
    setCurrentFocusIndex,
  } = useInputCode({digits, onChange, onlyNumbers});

  return (
    <div>
      <form
        className={classnames(
          "flex gap-1 items-center mx-auto",
          fluid ? "justify-between" : "justify-start",
          className
        )}
      >
        {range(digits).map(i => (
          <>
            {digits === 6 && i === 3 && (
              <Icon icon="dash" size="sm" className="mx-1" />
            )}
            <input
              type="number"
              onChange={handleChange.bind(null, i)}
              value={code[i]}
              onPaste={handlePaste}
              onKeyDown={e => {
                if (e.key === "Backspace") {
                  // Simulate a change event to clear the input
                  handleChange(i, {
                    target: {value: ""},
                  });
                }
              }}
              disabled={successMessage}
              // autoFocus={i === currentFocusIndex}
              onFocus={() => setCurrentFocusIndex(i)}
              maxLength={2}
              ref={i === currentFocusIndex ? input => input?.focus() : null}
              className={classnames(
                "input-field text-center justify-center focus:input-base-focused",
                error && "border-error-500",
                successMessage &&
                  "!border-success-500 focus:border-success-500",
                sizeMap[size],
                "aspect-square",
                "font-medium text-gray-500",
                transparent ? "bg-transparent" : ""
              )}
            />
          </>
        ))}
      </form>
      <InputErrorMessage error={error} errorMessageRelative={true} />
      <InputSuccessMessage successMessage={successMessage} />
    </div>
  );
};

export default InputCode;
