import React from "react";
import {CourseUserStatsExercisesCompleted} from "Widgets";
import CourseUserStatsExercisesCompletedToday from "Widgets/Course/CourseUserStats/CourseUserStatsExercisesCompletedToday";
import CourseUserStatsTimeSpentToday from "Widgets/Course/CourseUserStats/CourseUserStatsTimeSpentToday";
import CourseUserStatsSelfEvaluationDistribution from "Widgets/Course/CourseUserStats/CourseUserStatsSelfEvaluationDistribution";
import {Carousel, ErrorBoundary} from "Components";
import {isMobile} from "react-device-detect";

const CourseDashboardUserStats = () => {
  const {user} = React.useContext(window.UserContext);

  if (!user?.id) {
    return null;
  }

  return (
    <div className="gap-2 md:gap-4 w-full grid grid-cols-1 md:grid-cols-2">
      <ErrorBoundary hideOnError={true}>
        <CourseUserStatsExercisesCompleted size="sm" />
      </ErrorBoundary>
      <ErrorBoundary hideOnError={true}>
        <CourseUserStatsExercisesCompletedToday size="sm" />
      </ErrorBoundary>
      <ErrorBoundary hideOnError={true}>
        <CourseUserStatsTimeSpentToday size="sm" />
      </ErrorBoundary>
      <ErrorBoundary hideOnError={true}>
        <CourseUserStatsSelfEvaluationDistribution size="sm" />
      </ErrorBoundary>
    </div>
  );
};

export default CourseDashboardUserStats;
