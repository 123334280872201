import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Icon,
  Lottie,
  Table,
  Typography,
} from "Components";
import Trans from "Components/Trans";

import GroupAdminUploadMemberVerifiedToggle from "routes/Group/GroupAdminUpload/GroupAdminUploadMemberVerifiedToggle";
import useGroupAdminUploadMembersValidate from "routes/Group/GroupAdminUpload/GroupAdminUploadMembersValidate/useGroupAdminUploadMembersValidate";

const GroupAdminUploadMembersValidate = ({
  fileMembers,
  group,
  result,
  handleNextStep,
}) => {
  // const members = matchMembersWithCsv({fileMembers, group});

  const {
    bulkValidate,
    bulkValidateLoading,
  } = useGroupAdminUploadMembersValidate({
    group,
  });

  const membersToValidate = React.useMemo(() => {
    return result.matchedMembers.filter(member => {
      return member.verified === false;
    });
  }, [result]);

  const columns = [
    ...userColumns,
    {
      title: <Typography>Godkänn</Typography>,
      dataIndex: "id",
      key: "verifiable",
      render: (id, member) => {
        return (
          <GroupAdminUploadMemberVerifiedToggle
            groupId={group.id}
            memberId={member.id}
            verified={member.verified}
          />
        );
      },
    },
  ];

  return (
    <div className="flex flex-col gap-2 w-full mt-4 p-4 surface">
      <div className="flex justify-between items-center">
        <Typography type="txl" weight="sb">
          Medlemmar som behöver valideras
        </Typography>

        <Button
          type="primary"
          size="sm"
          loading={bulkValidateLoading}
          disabled={membersToValidate.length === 0}
          onClick={() => {
            bulkValidate(membersToValidate);
          }}
        >
          <Trans>Bulk validera alla medlemmar</Trans>
        </Button>
      </div>
      <div className="mt-4">
        {membersToValidate.length > 0 && (
          <Table data={membersToValidate} columns={columns} fixed={true} />
        )}
        {membersToValidate.length === 0 && (
          <div className="flex p-4 gap-4 items-center surface-success rounded-lg">
            <Lottie loop={false} animation="checkMarkSparkles" />
            <Typography type="txl" weight="sb" color="text-success">
              Samtliga medlemmar från listan är validerade
            </Typography>
          </div>
        )}
      </div>
      <div className="align-self-end mt-4">
        <Button onClick={handleNextStep} type="primary">
          <Trans>Next step</Trans>
        </Button>
      </div>
    </div>
  );
};

export const userColumns = [
  {
    key: "member",
    dataIndex: "user",
    title: <Trans>Medlem</Trans>,
    render: user => {
      return (
        <div className="flex gap-2 items-center">
          <Avatar user={user} alt={user.firstName} size="sm" />
          <Typography className="ml-2">
            {user.firstName + " " + (user.lastName || "")}
          </Typography>
        </div>
      );
    },
  },
  {
    key: "Status",
    dataIndex: "verified",
    title: <Trans>Status</Trans>,
    render: verified => {
      return (
        <Badge type={verified ? "success" : "error"}>
          <Typography>{verified ? "Verifierad" : "Ej verifierad"}</Typography>
        </Badge>
      );
    },
  },
  {
    key: "Startår",
    dataIndex: "user.enrollmentYear",
    title: <Trans>Startår</Trans>,
  },
  {
    key: "createdAt",
    dataIndex: "createdAt",
    title: <Trans>Joinat sedan</Trans>,
    render: createdAt => {
      // TODO: return the field createdAt in swedish format get the first 10 characters of the string
      return <Typography>{createdAt.slice(0, 10)}</Typography>;
    },
  },
];

export default GroupAdminUploadMembersValidate;
