import React from "react";
import {Button, SubSection, Trans} from "Components";
import {accountSubSectionTitleProps} from "routes/Account/Account";
import LanguagePicker from "Components/LanguagePicker";

import {useHistory} from "react-router-dom";
import AccountSettingsSubscriptionCredits from "routes/Account/AccountSettings/AccountSettingsSubscriptionCredits";
import DarkModeToggle from "Components/DarkModeToggle/DarkModeToggle";
import {Authorization} from "Wrappers";

const AccountSettings = () => {
  const history = useHistory();
  return (
    <>
      <SubSection>
        <Trans {...accountSubSectionTitleProps}>Language</Trans>
        <div className="w-80">
          <LanguagePicker />
        </div>
      </SubSection>
      <AccountSettingsSubscriptionCredits />
      <Authorization.Admin>
        <DarkModeToggle showLabel={true} />
      </Authorization.Admin>
    </>
  );
};

export default AccountSettings;
