import {useQuery} from "@apollo/react-hooks";

import gql from "graphql-tag";
import React from "react";

export const GET_EXAM = gql`
  query getExam(
    $id: ID!
    $includeExercises: Boolean!
    $includeSolutions: Boolean!
    $exerciseFilters: ExerciseFiltersInput
    $includeSegments: Boolean!
  ) {
    getExam(id: $id) {
      id
      date
      author
      exercises_src # The EXERCISES_PNG file
      solutions_src # The SOLUTIONS_PNG file
      exam_exercises_src # The EXERCISES_PDF file
      exam_solutions_src # The SOLUTIONS_PDF file
      exercisesMarkingCompleted
      solutionsMarkingCompleted
      exercises(filters: $exerciseFilters) @include(if: $includeExercises) {
        id
        imageUrl
        number
        segments @include(if: $includeSegments) {
          id
          imageUrl
          x1
          x2
          y1
          y2
          initialWidth
        }
        solution @include(if: $includeSolutions) {
          id
          imageUrl
          segments @include(if: $includeSegments) {
            id
            imageUrl
            x1
            x2
            y1
            y2
            initialWidth
          }
        }
      }
    }
  }
`;

const useGetExam = ({
  id,
  includeExercises = false,
  includeSolutions = false,
  includeSegments = false, // Include all segments for exercises and solutions
  exerciseFilters = {
    published: true,
  },
}) => {
  const {loading, error, data, refetch} = useQuery(GET_EXAM, {
    variables: {
      id,
      includeExercises,
      includeSolutions,
      exerciseFilters,
      includeSegments,
    },
  });

  if (error) return `Error! ${error.message}`;

  let exam = {};

  if (data) {
    exam = data.getExam;
  }

  return [exam, loading, refetch];
};

export default useGetExam;
