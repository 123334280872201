import {LAMBDA_BASE_URL} from "API/lambdas/index";

const documentFindDates = async docUrl => {
  const response = await fetch(
    `${LAMBDA_BASE_URL}/document-find-dates-dev-main?docUrl=${docUrl}`
  );
  const data = await response.json();
  return data.result;
};

export default documentFindDates;
