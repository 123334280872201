import React from "react";
import {Button, Typography} from "Components";
import DatePickerInput from "Components/_Elements/DatePicker/DatePickerInput/DatePickerInput";

const DatePickerTrigger = ({
  currentDate,
  showInput,
  format,
  showPicker,
  setShowPicker,
  fluid = false,
  onDateSelected,
}) => {
  if (showInput) {
    return (
      <DatePickerInput
        currentDate={currentDate}
        format={format}
        onDateSelected={onDateSelected}
        fluid={fluid}
        setShowPicker={setShowPicker}
      />
    );
  }

  return (
    <Button
      fluid={fluid}
      onClick={() => setShowPicker(!showPicker)}
      iconLeft="calendar"
      focused={showPicker}
      className={
        "focus:border-1 focus:border-primary-300 focus:shadow-xs-primary"
      }
    >
      <Typography>{currentDate?.format(format)}</Typography>
    </Button>
  );
};

export default DatePickerTrigger;
