import React from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {getGroup, groupsByGroupId} from "API/appsync/queries";
import {createGroup} from "API/appsync/mutations";

export const groupInfo = {
  clsektionen: {
    links: [
      {
        title: "Hemsida",
        link: "https://www.cl-sektionen.se/",
        icon: "fas fa-globe",
      },
      {
        title: "Studiebevakning",
        link: "https://www.cl-sektionen.se/studiebevakning",
        icon: "fas fa-book",
      },
      {
        title: "Facebook",
        link: "https://www.facebook.com/clsektionen",
        icon: "fab fa-facebook",
      },
      {
        title: "Instagram",
        link: "https://www.instagram.com/clsektionen/",
        icon: "fab fa-instagram",
      },
    ],
    contacts: [
      {
        role: "Ordförande näringslivsnämnden",
        name: "Frida Sundberg",
        email: "ordf.naringsliv@cl-sektionen.se",
      },

      {
        role: "SNO - Studienämndens Ordförande",
        name: "Moa Wettby - CL21",
        email: "sno@cl-sektionen.se",
      },
      {
        role: "PAS - Programansvarig student",
        name: "Viktor Hallberg - CL22",
        email: "pas@cl-sektionen.se",
      },
      {
        role: "Styrelsen",
        email: "styrelsen@cl-sektionen.se",
      },

      {
        role: "Studienämnden",
        email: "studienamnden@cl-sektionen.se",
      },
      {
        role: "UBBE -  Utbildningsbevakare",
        name: "Svante Holgersson - CL20",
        email: "ubbe@cl-sektionen.se",
      },
    ],
  },
};

const useGetGroupInfo = ({groupId, key}) => {
  const {data, loading, error, refetch} = useQuery(gql(groupsByGroupId), {
    variables: {
      groupId: groupId,
      limit: 1,
    },
    // fetchPolicy: "cache-first",
    context: {
      api: "appsync",
    },
  });

  const [createGroupMutation] = useMutation(gql(createGroup), {
    context: {
      api: "appsync",
    },
  });

  const initializeGroup = async () => {
    // Initialize group
    createGroupMutation({
      variables: {
        input: {
          groupId,
          links: [],
          contacts: [],
          introduction: "",
        },
      },
    }).then(() => {
      refetch();
    });
  };

  React.useEffect(() => {
    if (data?.groupsByGroupId?.items.length === 0) {
      initializeGroup();
    }
  }, [data?.groupsByGroupId?.items]);

  const info = data?.groupsByGroupId?.items[0];
  return {info, loading, refetch};
};
export default useGetGroupInfo;
