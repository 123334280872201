import React from "react";
import classnames from "classnames";
import {Button, Trans, Icon, Typography} from "Components";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";

const AuthError = ({error, handleSignUpClick, handleLoginClick}) => {
  const {t: trans} = useTranslation();

  let m = error?.message || ErrorCodes["GEN02"];
  let action = null;

  if (error?.code === "AUTH02") {
    m = trans(
      "The account you logged in is currently disabled, please try to reset your password or contact us for further assistance."
    );

    action = null;
  }

  if (error?.code === "AUTH03") {
    m = trans(
      "The account you logged in with is not registered at Kollin. Please create an account first."
    );

    action = handleSignUpClick ? (
      <Button
        size="xs"
        type="link"
        color="text-primary-500"
        onClick={handleSignUpClick}
        className="ml-2"
      >
        <Trans>Create account</Trans>
      </Button>
    ) : null;
  }

  if (error?.code === "AUTH12") {
    action = handleLoginClick ? (
      <Button
        size="xs"
        type="link"
        color="text-primary-500"
        onClick={handleLoginClick}
        className="ml-2"
      >
        <Trans>Login</Trans>
      </Button>
    ) : null;
  }

  if (!error?.code) return null;

  return (
    <motion.div
      className={classnames(
        "flex items-center gap-2 p-2 rounded-md bg-error-50 border-2 border-error-300"
        // error ? "opacity-100" : "opacity-0"
      )}
      initial={{opacity: 0, scale: 0}}
      animate={{opacity: 1, scale: 1}}
    >
      <Icon
        icon="fas fa-triangle-exclamation"
        size="lg"
        color="text-error-600"
        fixedWidth
      />
      <span>
        <Trans
          type="tsm"
          color="text-error-600"
          weight="sb"
          inline
          className="mb-0 leading-none"
        >
          {m}
        </Trans>
        {action}
      </span>
    </motion.div>
  );
};

export default AuthError;
