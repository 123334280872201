import * as React from "react";
import "Components/DifficultyBead/DifficultyBead.css";
import {scoreToDifficultyColor} from "utilities/exerciseUtilities/formatScore";
import PropTypes from "prop-types";

const DifficultyBead = ({onClick, active, score, small, children, ...rest}) => {
  const beadColor = scoreToDifficultyColor(score);

  let beadDiam;
  if (small) {
    beadDiam = "1.2rem";
  } else {
    beadDiam = "2rem";
  }
  return (
    <div
      {...rest}
      className={`bead ${active ? "selected" : ""}`}
      style={{
        overflow: "hidden",
        cursor: "pointer",
        boxSizing: "border-box",
        width: beadDiam,
        height: beadDiam,
        position: "relative",
        borderRadius: "50%",
        backgroundColor: active ? beadColor : "transparent",
        borderColor: beadColor,
      }}
      onClick={onClick}
    >
      <div
        className="inner"
        style={{
          transform: "scale(1.1)",
          margin: "auto",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0px",
          left: "0px",
          borderRadius: "50%",
          backgroundColor: beadColor,
        }}
      >
        {children}
      </div>
    </div>
  );
};

DifficultyBead.propTypes = {
  score: PropTypes.any,
  small: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DifficultyBead;
