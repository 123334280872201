import React from "react";
import useGetCourseExercises from "API/queries/exercise/useGetCourseExercises";
import Skeleton from "Components/_Elements/Skeleton/Skeleton";
import ProgressBar from "Components/_Elements/ProgressBar/ProgressBar";
import {Trans, Typography} from "Components/index";
import {nCompleted} from "utilities/exerciseUtilities/exerciseListOperations";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";

const CourseCardProgress = ({courseId, thin}) => {
  const smallScreens = useBreakPointSM();

  const [exercises, loading] = useGetCourseExercises({
    courseId,
    includeSelfEvaluation: true,
  });

  const completedExercises = React.useMemo(() => nCompleted(exercises), [
    loading,
  ]);

  const totalExercises = exercises.length;

  if (loading)
    return (
      <Skeleton
        style={{
          maxWidth: "200px",
          height: "14px",
        }}
      />
    );

  return (
    <div className="w-full flex gap-1 items-center truncate">
      <ProgressBar
        thin={smallScreens(true, false)}
        progressRatio={completedExercises / exercises.length}
      />
      <Typography type="txs" className="ml-2 mr-1">
        {`${completedExercises}/${exercises.length} `}
      </Typography>
      <Trans type="txs">exercises</Trans>
    </div>
  );
};

export default CourseCardProgress;
