class Trie {
  text = "";
  root = null;

  constructor(text) {
    this.root = new TrieNode(null, null);
    const lines = text.split("\n"); // Split the text into lines
    this.text = lines.join(" ").split(" "); // Join the lines with spaces and split the text into words

    this.buildTrie();
  }

  buildTrie() {
    for (let i = 0; i < this.text.length; i++) {
      this.insert(this.text[i], i);
    }
  }

  insert(word) {
    let current = this.root; // start at the root
    for (let char of word) {
      // if the character is not in the current node's children map
      if (current.children[char] === undefined) {
        current.children[char] = new TrieNode(char, current);
      }

      current = current.children[char];
    }

    // mark the end of the word
    current.isEndOfWord = true;
  }

  search(word, caseSensitive = true) {
    let search = word;
    if (!caseSensitive) {
      search = word.toLowerCase();
    }

    let current = this.root; // start at the root

    /**
     * iterate over the word: 'Q', 'u', 'i', 'c', 'k'
     *
     * if the character is 'Q', we need to check if the current node has a child with the value 'Q'
     * if the character is 'q' we need to check if lower(child.value) === 'q'
     */

    for (let char of search) {
      let child = current.children[char];
      /**
       * If we are doing non-case-sensitive search, we can't just get the child with the key 'q'
       * because the key may actually be 'Q'.
       */
      if (!caseSensitive) {
        let childKey = Object.keys(current.children).find(
          key => key.toLowerCase() === char
        );

        child = current.children[childKey];
      }

      // if the character is not in the current node's children map
      if (child === undefined) {
        return false;
      }

      current = child;
    }

    // if the current node is the end of a word, return true
    return current.isEndOfWord;
  }
}

class TrieNode {
  // the "key" value will be the character in sequence (value)
  key = null;

  // we keep a reference to parent
  parent = null;

  // we have hash of children, key: char, value: TrieNode
  children = {};

  // check to see if the node is at the end
  isEndOfWord = false;

  constructor(key, p) {
    this.key = key;
    this.parent = p;
  }
}

export default Trie;
