import {useQuery} from "@apollo/react-hooks";

import gql from "graphql-tag";
import React from "react";

export const GET_COURSE_EXAMS = gql`
  query($courseId: ID!, $includeExerciseSolutions: Boolean!) {
    getAllExams(course_id: $courseId) {
      id
      date
      author
      exercisesMarkingCompleted
      solutionsMarkingCompleted
      exercises {
        id
        solution @include(if: $includeExerciseSolutions) {
          id
        }
      }
      exam_exercises_src
      exam_solutions_src
    }
  }
`;

const useGetCourseExams = (courseId, includeExerciseSolutions = false) => {
  const {loading, error, data} = useQuery(GET_COURSE_EXAMS, {
    variables: {courseId, includeExerciseSolutions},
    fetchPolicy: "cache-first",
  });

  if (error) return `Error! ${error.message}`;

  let exams = [];

  if (data && data.getAllExams) {
    exams = data.getAllExams.filter(e => e.exercises);
    // Sort by date, but some exams have text in them so that needs to be stripped out before converting to date
    exams.sort(
      (a, b) =>
        new Date(a.toString().substring(9)) -
        new Date(b.toString().substring(9))
    );
  }

  return [exams, loading];
};

export default useGetCourseExams;
