import React from "react";
import {useTranslation} from "react-i18next";
import {Icon, Numerical, Typography} from "Components";
import CourseLandingTopicsModuleTag from "routes/Course/CourseLanding/CourseLandingTopics/CourseLandingTopicsModuleTag";

/**
 *
 * @param topic - Module has been renamed to topic because it is a reserved word in javascript
 * @returns {JSX.Element}
 * @constructor
 */

const CourseLandingTopicsModule = ({topic, nTagsToShow}) => {
  const {t: trans} = useTranslation();

  const {totalTags, totalExercises, totalChapters} = React.useMemo(
    () => getStats(topic),
    []
  );

  let tags = topic.tags;

  // Check if the number of Tags to show is greater then the amount of tags in the module:
  const tooManyTags = totalTags > nTagsToShow;

  if (tooManyTags) {
    // Take only the tags that are needed to show
    tags = tags.slice(0, nTagsToShow);
  }

  return (
    <div className="border-2 border-gray-300 rounded-xl">
      <div
        id="clp-tm-heading"
        className="p-2 surface border-b-2 border-gray-300 rounded-t-xl truncate"
      >
        <Typography type="tmd" weight="md">
          {topic.name}
        </Typography>
        <div className="mt-1 flex gap-2 items-center ">
          <Numerical val={totalTags} type="txs" weight="md">
            topic
          </Numerical>
          <Icon icon="circle-small" size="xs" color="text-gray-300" />
          <Numerical val={totalExercises} type="txs" weight="md">
            exercise
          </Numerical>
          <Icon icon="circle-small" size="xs" color="text-gray-300" />
          <Numerical val={totalChapters} type="txs" weight="md">
            theory chapter
          </Numerical>
        </div>
      </div>
      <div id="clp-tm-tags-list" className="px-3 py-2 background rounded-b-xl">
        {tags.map((tag, i) => (
          <CourseLandingTopicsModuleTag key={i} tag={tag} />
        ))}

        {tooManyTags && (
          <Typography type="txs" weight="md" color="text-gray-500">
            + {totalTags - nTagsToShow} more
          </Typography>
        )}
      </div>
    </div>
  );
};

const getStats = topic => {
  return {
    totalTags: topic.tags?.length,
    totalChapters: topic.tags.reduce(
      (prev, curr) => prev + curr.chapters?.length,
      0
    ),
    totalExercises: topic.tags.reduce(
      (prev, curr) => prev + curr.exercises?.length,
      0
    ),
  };
};

export default CourseLandingTopicsModule;
