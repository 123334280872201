import React from 'react';
import {useHistory, useLocation} from "react-router-dom";
import Summer2022CampaignCta from "routes/Campaign/Summer2022Campaign/Summer2022CampaignCTA";

const CourseLandingCampaignCta = () => {
    const location = useLocation()
    const history = useHistory();


    if (!location.state?.campaign || !location.state?.nextUrl) return null;

    const {
        campaign,
        nextUrl
    } = location.state


    return <div className="mt-4 -mx-8 "><Summer2022CampaignCta
        {...campaign}
        onClick={() => history.push(nextUrl)}
    /></div>


};

export default CourseLandingCampaignCta;