import React from "react";
import { Document, Trans } from "Components";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import useExamViewDocument from "routes/Course/CourseExamView/ExamViewDocument/useExamViewDocument";

const ExamViewDocument = ({
  exam,
  pdfUrl,
  pdfLoading,
  showProgress,

}) => {
  const location = useLocation();

  const {
    pageCount,
    setPageCount,
    container,
    progressRatio,
  } = useExamViewDocument(pdfUrl);


  if (pdfLoading) return null;

  return (
    <div>
      {showProgress && (
        <div className="p-4 surface rounded-lg sticky top-0 z-20 flex gap-2 items-center">
          <Trans pageCount={pageCount} className="whitespace-nowrap">
            {{ pageCount }} pages
          </Trans>
          <motion.div
            className="w-full h-4 highlight-primary rounded-lg overflow-hidden"
            initial={{ width: 0 }}
            animate={{
              width: `${progressRatio * 100}%`,
              opacity: progressRatio < 0.1 ? 0 : 1,
            }}
          />
        </div>
      )}
      <div
        ref={container}
        key={`${location}-document-${pdfUrl}`}
        className="max-w-4xl mx-auto"
      >
        <Document
          container={container}
          url={pdfUrl}
          fileType={"pdf"}
          onPagesLoaded={setPageCount}
          showAllPages
        />
      </div>
    </div>
  );
};

export default ExamViewDocument;
