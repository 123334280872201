import React from "react";
import ChapterDisplayVideo from "routes/Course/Chapter/ChapterDisplay/ChapterDisplayVideo";
import {Trans} from "Components";
import Math from "Components/Math/Math";
import {restyleChapterHTML} from "utilities/chapter";

const ChapterDisplay = ({chapter}) => {
  // Check if html contains <iframe> tag then it's a video chapter

  // Special case for video chapters
  if (chapter.chapterType === "Video" || chapter?.html?.includes("<iframe")) {
    return <ChapterDisplayVideo html={chapter.html} />;
  }
  if (!chapter.html) return <Trans>Content is missing</Trans>;

  let styledHtml = restyleChapterHTML(chapter.html, chapter.title);

  return (
    <div className="pb-8 math">
      <Math html={styledHtml} />
    </div>
  );
};

export default ChapterDisplay;
