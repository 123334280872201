import React from "react";
import {Checkbox, Link, Typography, Trans} from "Components";
import {useTranslation} from "react-i18next";

const SignupConsentCheckbox = ({onChange, checked}) => {
  const {t: trans} = useTranslation();

  const iAccept = trans("I accept Kollin's");
  const termsLinkLabel = trans("Terms of services");
  const and = trans("and");
  const pivacyLinkLabel = trans("Privacy policy");

  return (
    <div className="mt-2">
      <Checkbox
        onChange={onChange}
        checked={checked}
        name="consent"
        label={
          <Typography
            weight="md"
            className="text-gray-900 dark:text-gray-25"
            inline
          >
            {iAccept}
            <Link
              target="_blank"
              href="/static/terms"
              onClick={e => e.stopPropagation()}
              className="mx-1"
            >
              {termsLinkLabel}
            </Link>
            {and}
            <Link
              href="/static/privacy"
              target="_blank"
              className="mx-1"
              onClick={e => e.stopPropagation()}
            >
              {pivacyLinkLabel}
            </Link>
          </Typography>
        }
      />
    </div>
  );
};

export default SignupConsentCheckbox;
