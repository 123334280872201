import React from "react";
import Trans from "Components/Trans";
import NotificationsListElement from "./NotificationsListElement";
import SkeletonList from "Components/_Elements/Skeleton/SkeletonList";

const NotificationsList = ({notifications, loading}) => {
  if (loading) {
    return <SkeletonList count={5} />;
  }

  if (!notifications.length) {
    return <Trans>There are no new notifications right now</Trans>;
  }

  return notifications.map(notification => (
    <NotificationsListElement notification={notification} />
  ));
};

export default NotificationsList;
