import React from "react";
import classnames from "classnames";

const Page = ({title, actions, image, children, className}) => {
  return (
    <div>
      {title && (
        <div
          id="page-header"
          className={classnames(
            "w-full",
            image ? "" : "bg-primary-800",
            "px-3 md:px-8 lg:px-16  py-5",
            "relative",
            className
          )}
          style={{
            background:
              image &&
              "linear-gradient(to right,  #2C385A 50%, rgba(0,0,0,0) 100%)",
          }}
          // Create a linear gradient from primary-800 to transparent
        >
          <div className="mb-8 md:mb-28">{title}</div>
          {image && (
            <>
              {/**
                             // TODO: Create the triangle shape, see the following CSS
                             top: 0;
                             height: 0;
                             width: 0;
                             border-right: 484px solid transparent;
                             border-top: 217px solid #212a43;
                             right: 10%;
                             z-index: 2;
                             background: transparent;

                             <span
                             className="block bg-primary-900 border-l-[10em] border-r-[10em]  border-b-[5em] border-primary-800 border-l-transparent"
                             aria-hidden="true"
                             id="page-header-image-overlay"
                             />
                             */}
              <div className="absolute -z-10 top-0 h-full w-full flex justify-end  bg-primary-900">
                <img
                  src={image}
                  alt="page-header"
                  className="h-full object-cover "
                  style={{
                    "-webkit-mask-image":
                      "linear-gradient(to left, #161C2D 0%, transparent 100%)",
                    "mask-image":
                      "linear-gradient(to left, #161C2D 0%, transparent 100%)",
                  }}
                />
              </div>
            </>
          )}
          {actions && <div className="mt-4 md:mt-14">{actions}</div>}
        </div>
      )}
      <div className={classnames("px-2 md:px-8 lg:px-20 py-5", className)}>
        {children}
      </div>
    </div>
  );
};

export const pageTitleProps = {
  type: "dmd",
  weight: "sb",
  color: "text-gray-50",
};
export const pageSubTitleProps = {
  type: "tlg",
  color: "text-gray-200",
};

export default Page;
