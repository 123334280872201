import React from "react";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import PropTypes from "prop-types";

const Dropdown = ({
  children,
  triggerAsChild = false,
  content,
  side = "bottom",
  open,
  modal = false,
}) => {
  return (
    <RadixDropdownMenu.Root open={open} modal={modal}>
      <RadixDropdownMenu.Trigger asChild={triggerAsChild}>
        {children}
      </RadixDropdownMenu.Trigger>
      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content
          sideOffset={5}
          className="shadow-lg p-4 rounded-md bg-white z-[500] border-1 border-gray"
          side={side}
        >
          {content}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.instanceOf(React.element),
  ]).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.instanceOf(React.element),
  ]),
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  modal: PropTypes.bool,
};

export default Dropdown;
