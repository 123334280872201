import React from "react";

const usePanHandler = ({
  setCoordinates,
  disablePan,
  callback,
  coordinatesRef,
}) => {
  const onMouseMove = e => {
    e.preventDefault();
    e.stopPropagation();

    setCoordinates(prev => {
      const newCoordinates = { ...prev };

      // Move box according to mouse movement

      newCoordinates.y1 += e.movementY;
      newCoordinates.y2 += e.movementY;
      newCoordinates.x1 += e.movementX;
      newCoordinates.x2 += e.movementX;
      coordinatesRef.current = newCoordinates;
      return newCoordinates;
    });
  };

  const onMouseUp = e => {
    e.preventDefault();
    e.stopPropagation();
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
    callback(coordinatesRef.current);
  };

  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const container = React.useCallback(
    node => {
      if (node !== null && !disablePan) {
        node.addEventListener("mousedown", onMouseDown);
      }
    },
    [disablePan]
  );

  return container;
};

export default usePanHandler;
