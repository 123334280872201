import React from "react";

import {ApolloLink} from "apollo-boost";
import {setContext} from "apollo-link-context";
import {onError} from "apollo-link-error";
import {logout} from "utilities/authentication";
import {message, Trans} from "Components";

const tokens = {};

const middlewareLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  if (!tokens.token) {
    tokens.token = localStorage.getItem("AUTH_TOKEN");
  }

  if (!tokens.refreshToken) {
    tokens.refreshToken = localStorage.getItem("REFRESH_TOKEN");
  }

  return {
    headers: {
      ...headers,
      "x-token": tokens.token,
      "x-refresh-token": tokens.refreshToken,
    },
  };
});

const afterWareLink = new ApolloLink((operation, forward) => {
  const {headers} = operation.getContext();

  if (headers) {
    const token = headers.get("x-token");
    const refreshToken = headers.get("x-refresh-token");

    if (token === "unset") {
      message.warning(
        <Trans>You have been logged out. Please log in again.</Trans>
      );

      logout("/login");
    }

    if (token) {
      localStorage.setItem("AUTH_TOKEN", token);
    }

    if (refreshToken) {
      localStorage.setItem("REFRESH_TOKEN", refreshToken);
    }
  }

  return forward(operation);
});

const errorHandling = onError(error => {
  const {graphQLErrors, networkError, operation, forward, response} = error;

  if (networkError) {
    console.log(`[NET-ERR]: ${networkError}`);
    // Sentry.captureException(networkError); // Capture the error in Sentry
  }

  if (graphQLErrors) {
    const notAuthenticated = graphQLErrors.some(
      ({message}) => message === "not authenticated"
    );

    if (notAuthenticated) {
      // If the user is logged in locally, log them out
      const hasAuthToken = localStorage.getItem("AUTH_TOKEN");
      if (hasAuthToken) {
        logout();
      }
    }
  }
});

export default errorHandling.concat(afterWareLink.concat(middlewareLink));
