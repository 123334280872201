import React from "react";
import {Button, Dropdown, Icon, Popover} from "Components";
import UseCourseCardMenu from "Components/CourseCard/useCourseCardMenu";

const CourseCardMenu = props => {
  const {menuOptions} = UseCourseCardMenu({
    ...props,
  });

  return (
    <Popover
      modal={false}
      content={
        <div>
          {menuOptions.map((option, index) => (
            <Button key={index} type="link" onClick={option.onClick}>
              {option.label}
            </Button>
          ))}
        </div>
      }
    >
      <Icon size="xl" icon="ellipsis" />
    </Popover>
  );
};

export default CourseCardMenu;
