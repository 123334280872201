import React from "react";
import CourseExamsListCard from "./CourseExamsListCard";
import useCourseExamsList from "./useCourseExamsList";
import Widget from "../../../Components/Widget/Widget";
import {Trans} from "../../../Components";
import SkeletonListWithCircle from "../../../Components/_Elements/Skeleton/SkeletonListWithCircle";

const CourseExamsList = ({size}) => {
  const {sortedExams, exercisesLoading} = useCourseExamsList();
  const topExamIds = sortedExams(size);
  if (!exercisesLoading && !topExamIds.length) return null;

  return (
    <Widget
      widgetName="CourseExamsList"
      title={"Exams"}
      action={{label: <Trans>Se mer</Trans>, type: "link", to: "exams"}}
    >
      {exercisesLoading ? (
        <SkeletonListWithCircle count={5} fluid={true} height="h-[63px]" />
      ) : (
        topExamIds.map((examId, index) => (
          <div className="w-full border-b-1 border-gray-300 last:border-b-0 w-full cursor-pointer hover:surface hover:text-gray-700">
            <CourseExamsListCard examId={examId} key={index} />
          </div>
        ))
      )}
    </Widget>
  );
};

export default CourseExamsList;
