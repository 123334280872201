import React from "react";
import classnames from "classnames";
import {Typography} from "Components";
import ProductCardPrice from "Components/ProductCard/ProductCardPrice";
import ProductCardActions from "Components/ProductCard/ProductCardActions.jsx";
import ProductCardDescription from "Components/ProductCard/ProductCardDescription";
import ProductCardImage from "Components/ProductCard/ProductCardImage";
import ProductCardStats from "Components/ProductCard/ProductCardStats";

const ProductCard = ({
  name,
  label,
  description,
  imageUrl,
  price,
  __typename,
  selected: defaultSelected,
  selectable,
  onSelect,
  stats = [],
  orientation = "horizontal",
}) => {
  const [selected, setSelected] = React.useState(defaultSelected);

  // TODO: Automatically set vertical on mobile
  const vertical = orientation === "vertical";

  const handleSelect = () => {
    setSelected(!selected);
    onSelect();
  };

  return (
    <div class="@container w-full">
      <div
        className={classnames(
          "background border-2 border-gray rounded-lg grid items-start overflow-clip p-4",
          "grid-cols-1 @sm:grid-cols-12 @md:gap-4"
        )}
      >
        <ProductCardImage imageUrl={imageUrl} name={name} />

        {/* Text section */}

        <div className={classnames("@sm:col-span-6 ", "flex flex-col gap-2")}>
          <Typography type="tsm" weight="sb" color="text-primary-700">
            {name}
          </Typography>
          <Typography weight="md" type="tmd">
            {label}
          </Typography>
          <ProductCardDescription description={description} />
          <ProductCardStats stats={stats} />
        </div>

        <div
          className={classnames(
            "mt-4 col-span-1  @sm:col-span-3 h-full flex flex-col justify-between items-end"
          )}
        >
          <ProductCardActions
            vertical={vertical}
            selectable={selectable}
            selected={selected}
            handleSelect={handleSelect}
          />

          <ProductCardPrice price={price} selected={selected} name={name} />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
