import {ApolloClient, ApolloLink, split} from "apollo-boost";
import {getMainDefinition} from "apollo-utilities";
import batchHttpLink from "client/batchHttpLink";
import cache from "client/cache";
import contentfulLink from "client/contenfulLink";
import middleware from "client/middleware";
import uploadLink from "client/uploadLink";
import wsLink from "client/wsLink";
import {SentryLink} from "apollo-link-sentry";
import appsyncLink from "client/appsyncLink";

const httpLink = uploadLink;

// regular httpLink
const http = middleware.concat(httpLink);

// Batch HTTP link
const httpBatch = middleware.concat(batchHttpLink);

// Split based on batching
const batchSplitLink = split(
  operation => operation.getContext().batch,
  httpBatch,
  http
);

// Split link based on http or ws
const protocolSplitBatchSplitLink = split(
  // split based on operation type
  ({query}) => {
    const {kind, operation} = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  batchSplitLink
);

// Split link based on api target
const apiSplitLink = split(
  operation => operation.getContext().api === "contentful",
  contentfulLink, // <= apollo will send to this if clientName is "content"
  split(
    operation => operation.getContext().api === "appsync",
    appsyncLink,
    protocolSplitBatchSplitLink
  )
);

const client = new ApolloClient({
  link: ApolloLink.from([
    new SentryLink({
      attachBreadcrumbs: {
        includeQuery: true,
        includeError: true,
      },
    }),
    apiSplitLink,
  ]),
  cache,
  connectToDevTools: process.env.NODE_ENV !== "production",
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "cache-and-network",
    },
    mutate: {},
    errorPolicy: "all",
  },
});

export default client;
