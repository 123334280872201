import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { message } from "Components";

const CREATE_SUBSCRIPTION = gql`
  mutation stripeCreateSubscription($planName: String!) {
    stripeCreateSubscription(planName: $planName) {
      ok
      session_id
      error
    }
  }
`;

const useCreateSubscriptionMutation = subscriptionPlanName => {
  let sessionId = null;
  const [stripeCreateSubscription, { data, loading, error }] = useMutation(
    CREATE_SUBSCRIPTION,
    {
      variables: { planName: subscriptionPlanName || "kollin_gold_monthly" },
    }
  );

  if (data && data.stripeCreateSubscription) {
    if (data.stripeCreateSubscription.ok) {
      sessionId = data.stripeCreateSubscription.session_id;
    } else {
      message.error(
        <p>
          {data.stripeCreateSubscription.error}
          <br />
          Contact us at contact@kollin.io for support
        </p>
      );
    }
  }

  return {
    stripeCreateSubscription,
    sessionId,
    loading,
    error,
  };
};

export default useCreateSubscriptionMutation;
