import React from "react";
import GeogebraDisplayView from "Components/Geogebra/GeogebraDisplay/GeogebraDisplayView";
import useGeogebraDisplayEquations from "Components/Geogebra/GeogebraDisplay/GeogebraDisplayEquations/useGeogebraDisplayEquations";
import GeogebraDisplayEquationsItem from "Components/Geogebra/GeogebraDisplay/GeogebraDisplayEquations/GeogebraDisplayEquationsItem";

const GeogebraDisplayEquations = ({width}) => {
  const {
    equations,
    selectedEquation,

    ...state
  } = useGeogebraDisplayEquations();

  return (
    <div>
      <div className="flex flex-row gap-4 mb-2">
        {equations.map((equation, index) => {
          const current = equation === selectedEquation;
          if (state.editing && !current) return null;
          return (
            <GeogebraDisplayEquationsItem
              current={current}
              equation={equation}
              key={index}
              index={index}
              hasMultipleEquations={equations.length > 1}
              {...state}
            />
          );
        })}
      </div>

      {width && (
        <GeogebraDisplayView
          key={selectedEquation} // Force re-render when equation changes
          equation={selectedEquation}
          width={width}
        />
      )}
    </div>
  );
};

export default GeogebraDisplayEquations;
