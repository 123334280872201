import React from "react";
import PropTypes from "prop-types";
import {Badge, Icon, Typography} from "Components";
import classnames from "classnames";
import {AnimatePresence, motion} from "framer-motion";

const CheckboxGroup = ({
  options,
  selectedValues,
  onChange,
  asGrid = false,
  inline = false,
  allowMultiple = true,
  badgeBorder = false,
  badgeType = "primary",
}) => {
  const [state, setState] = React.useState(selectedValues || []);

  const handleChange = value => {
    const currentState = [...state];

    if (!allowMultiple) {
      return setState([value]);
    }
    // 1. Check if the value is already in the array
    const index = currentState.indexOf(value);
    if (index > -1) {
      // 2. If it is, remove it
      currentState.splice(index, 1);
    } else {
      // 3. If it's not, add it
      currentState.push(value);
    }
    setState(currentState);
  };

  // use an effect to call onChange when the state changes
  React.useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <div
      className={classnames(
        asGrid ? "grid grid-cols-2 gap-2" : "w-full",
        inline && "flex gap-2"
      )}
    >
      {options.map(
        ({
          text,
          value,
          icon,
          description,
          selected: defaultSelected,
          disabled,
          badge,
        }) => {
          const selected = state.includes(value) || defaultSelected;
          const onClick = disabled ? () => {} : handleChange.bind(null, value);

          let backgroundStyle = "bg-white";

          if (selected) {
            backgroundStyle = "bg-primary-50 border-primary-300";
          }

          if (disabled) {
            backgroundStyle = "bg-gray-100 border-gray-300";
          }

          return (
            <motion.div
              key={`check-box-group-option-${value}`}
              onClick={onClick}
              className={classnames(
                "checkbox-group-option",
                disabled && "hover:border-gray-300 cursor-default",
                "group",
                description ? "items-start" : "items-center",
                backgroundStyle
              )}
            >
              <div className="flex">
                {icon && (
                  <div className="mr-2">
                    <Icon icon={icon} filled />
                  </div>
                )}
                <div className="flex flex-col justify-center pr-1">
                  <div className="flex gap-2">
                    <Typography type="tsm" weight="md" block={true}>
                      {text}
                    </Typography>
                    {badge && (
                      <Badge border={badgeBorder} type={badgeType}>
                        {badge}
                      </Badge>
                    )}
                  </div>
                  {description && (
                    <Typography type="tsm" color="text-gray-500" block={true}>
                      {description}
                    </Typography>
                  )}
                </div>
              </div>
              {/*  TODO: icon stroke blue on hover*/}
              <AnimatePresence initial={false}>
                <CheckboxGroupIcon selected={selected} value={value} />
              </AnimatePresence>
            </motion.div>
          );
        }
      )}
    </div>
  );
};

const CheckboxGroupIcon = ({selected, value}) => {
  if (selected) {
    return (
      <motion.div
        animate={{scale: 1}}
        initial={{scale: 0}}
        exit={{scale: 0}}
        key={value + "selected"}
      >
        <Icon
          className="checkbox-group-option-icon"
          color="text-primary-600"
          icon="check-circle"
        />
      </motion.div>
    );
  }
  return (
    <motion.div
      animate={{scale: 1}}
      initial={{scale: 0}}
      exit={{scale: 0}}
      key={value + "unselected"}
    >
      <Icon className="checkbox-group-option-icon" icon="far fa-circle" />
    </motion.div>
  );
};

CheckboxGroup.propTypes = {
  selectedValues: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default CheckboxGroup;
