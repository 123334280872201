import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { CourseContext } from "routes/Course/CourseContextProvider";

export const GET_CURRENT_USER_REVIEW = gql`
  query getCurrentUserReviews($courseId: ID!) {
    getCourse(id: $courseId) {
      id
      currentUsersReview {
        id
        user {
          id
          first_name
          last_name
          email
          avatar
        }
        title
        score
        text
      }
    }
  }
`;

const useGetCurrentUserCourseReview = () => {
  const { courseId } = React.useContext(CourseContext);
  let currentUsersReview = {};

  const { data, loading, error } = useQuery(GET_CURRENT_USER_REVIEW, {
    variables: { courseId },
  });

  if (data) {
    currentUsersReview = data.getCourse.currentUsersReview;
  }
  if (error) {
    console.log("useGetCurrentUserCourseReview error: ", error.message);
  }

  return [currentUsersReview, loading];
};

export default useGetCurrentUserCourseReview;
