import React from "react";
import {Logo, PageLogoLoader, Typography} from "Components";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";

const GroupAuthWrapper = ({children}) => {
  const group = useGroupContext();
  const subdomainLink = window.location.href.split("//")[1].split("/")[0];

  if (!group?.id || !group?.key) return <PageLogoLoader />;

  return (
    <div className="md:h-screen w-screen grid grid-cols-5">
      <div className="bg-slate-200 md:h-screen flex flex-col gap-2 items-center justify-center col-span-5 md:col-span-2 p-4 md:max-w-2xl">
        <div className="flex flex-col px-2 md:px-8">
          <div className="p-2 bg-white rounded-full shadow-lg w-24 h-24  md:w-32 md:h-32 aspect-square ">
            <img
              src={group?.imageUrl}
              alt="Group"
              className="w-full object-contain rounded-full"
            />
          </div>
          <div className="flex flex-wrap gap-2 items-center mt-4 whitespace-break-">
            <Typography
              type="dxs md:dmd"
              weight="b"
              block
              color="text-fuchsia-900"
            >
              {group.name}
            </Typography>
            <Typography type="dxs md:dmd" weight="b" block>
              på Kollin
            </Typography>
          </div>
          <Typography
            type="tlg md:txl"
            weight="b"
            color="text-gray-500"
            className="underline underline-offset-2"
          >
            {subdomainLink}
          </Typography>
          <div className="hidden md:block ">
            <Logo size="md" type="primary" className="grayscale mt-4 md:mt-8" />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center h-full col-span-5 md:col-span-3 w-full md:max-w-2xl mx-auto p-4">
        <div className="w-full">{children}</div>
      </div>
      <div />
    </div>
  );
};

export default GroupAuthWrapper;
