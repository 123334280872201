import React from "react";
import CourseLandingAllReviews from "routes/Course/CourseLanding/CourseLandingAllReviews/CourseLandingAllReviews";
import CourseLandingDiscussions from "routes/Course/CourseLanding/CourseLandingDiscussions/CourseLandingDiscussions";
import CourseLandingExams from "routes/Course/CourseLanding/CourseLandingDesktop/CourseLandingExams/CourseLandingExams";
import CourseLandingTopics from "routes/Course/CourseLanding/CourseLandingTopics/CourseLandingTopics";
import CourseStudyPlanBanner from "routes/Course/CourseLanding/CourseLandingDesktop/CourseStudyPlanBanner/CourseStudyPlanBanner";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useCourseLandingNavigation = pageComponents => {
  const topicsRef = React.useRef(null);
  const studyPlansRef = React.useRef(null);
  const allReviewsRef = React.useRef(null);
  const examsRef = React.useRef(null);
  const threadsRef = React.useRef(null);
  const { t: trans } = useTranslation();

  let courseLandingSections = [];

  const generateSections = pageComponents => {
    const headers = Object.keys(pageComponents);
    const values = Object.values(pageComponents);

    headers.forEach((header, i) => {
      // eslint-disable-next-line default-case
      switch (header) {
        case "modules":
          if (values[i].length > 0) {
            courseLandingSections = [
              ...courseLandingSections,
              {
                name: trans("Overview"),
                ref: topicsRef,
                component: CourseLandingTopics,
              },
            ];
          }
          break;

        case "studyPlans":
          if (values[i].length > 0) {
            courseLandingSections = [
              ...courseLandingSections,
              {
                name:
                  values[i].length == 1
                    ? trans("Study plan")
                    : trans("Study Plans"),
                ref: studyPlansRef,
                component: CourseStudyPlanBanner,
              },
            ];
          }
          break;

        case "exams":
          if (values[i].length > 0) {
            courseLandingSections = [
              ...courseLandingSections,
              {
                name: trans("Past exams"),
                ref: examsRef,
                component: CourseLandingExams,
              },
            ];
          }
          break;

        case "threads":
          if (
            values[i].length > 0 &&
            moment().diff(moment(values[i][0].created_at), "months") < 4
          ) {
            courseLandingSections = [
              ...courseLandingSections,
              {
                name: trans("Discussions"),
                ref: threadsRef,
                component: CourseLandingDiscussions,
              },
            ];
          }
          break;

        case "reviews":
          if (values[i] > 0) {
            courseLandingSections = [
              ...courseLandingSections,
              {
                name: trans("Reviews"),
                ref: allReviewsRef,
                component: CourseLandingAllReviews,
              },
            ];
          }
          break;
      }
    });
  };

  generateSections(pageComponents, courseLandingSections);

  return {
    courseLandingSections,
    ratingsRef: allReviewsRef,
    topicsRef,
    allReviewsRef,
    studyPlansRef,
    examsRef,
    threadsRef,
  };
};

export default useCourseLandingNavigation;
