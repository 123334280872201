import React from "react";
import useCourseStats from "./useCourseStats";
import { Icon, Typography, WaveBorderBox } from "Components";

import textSingularPlural, {
  textSingularPluralTranslated,
} from "utilities/textSingularPlural";
import classnames from "classnames";

const CourseStats = () => {
  const {
    exercisesTotal,
    theoryChapters,
    studentsTotal,
    videosTotal,
  } = useCourseStats();

  const nStats =
    (exercisesTotal > 0 || 0) +
    (theoryChapters > 0 || 0) +
    (videosTotal > 0 || 0) +
    (studentsTotal > 0 || 0);

  return (
    <WaveBorderBox
      topVariant={2}
      bottomVariant={2}
      fillColor="fill-primary-25"
      bgColor="bg-primary-25"
      containerClassName="-mx-8 md:mx-0 "
      className={classnames(
        "px-10 py-2",
        nStats === 4
          ? "grid grid-cols-2 gap-y-4 "
          : `grid grid-rows-${nStats} grid-cols-1 gap-y-4 `
      )}
    >
      {exercisesTotal > 0 && (
        <div className="flex items-center">
          <Icon
            size="lg"
            fixedWidth
            color="text-primary-700"
            icon="fa-regular fa-dumbbell"
          />
          <Typography className="pl-4">
            {textSingularPluralTranslated(exercisesTotal, "exercise")}
          </Typography>
        </div>
      )}

      {studentsTotal > 0 && (
        <div className="flex items-center ">
          <Icon
            size="lg"
            fixedWidth
            color="text-primary-700"
            icon="fa-regular fa-user-graduate"
          />
          <Typography className="pl-4">
            {textSingularPluralTranslated(studentsTotal, "student")}
          </Typography>
        </div>
      )}

      {theoryChapters > 0 && (
        <div className="flex flex-row items-center ">
          <Icon
            size="lg"
            fixedWidth
            color="text-primary-700"
            icon="fa-regular fa-function"
          />
          <Typography className="pl-4">
            {textSingularPluralTranslated(theoryChapters, "theory section")}
          </Typography>
        </div>
      )}

      {videosTotal > 0 && (
        <div className="flex flex-row items-center ">
          <Icon
            fixedWidth
            color="text-primary-700"
            icon="fa-regular fa-circle-play"
            size="lg"
          />
          <Typography className="pl-4">
            {videosTotal} {textSingularPlural(videosTotal, "video", "videos")}
          </Typography>
        </div>
      )}
    </WaveBorderBox>
  );
};

export default CourseStats;
