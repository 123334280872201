import * as React from "react";
import {motion} from "framer-motion";
import PropTypes from "prop-types";
import {Icon, Tooltip} from "Components/_Elements";
import useButtonClickTracking from "./useButtonClickTracking";

export const buttonTypes = {
  primary: "button-primary",
  gradient: "button-gradient",
  submit: "button-primary",
  secondary: "button-secondary",
  gray: "button-gray",
  ghost: "button-ghost",
  destructive: "button-destructive",
  link: "button-link",
  "link-success": "button-link-success",
  "link-destructive": "button-link-destructive",
  "link-gray": "button-link-gray",
};

const iconColorMapping = (type, disabled) =>
  ({
    primary: "text-primary-50 dark:text-primary-800",
    gradien: "text-white",
    submit: "text-primary-50 dark:text-primary-800",
    secondary: "text-primary-700 dark:text-primary-300",
    gray: disabled
      ? "text-gray-300 dark:text-gray-600"
      : "text-gray-600 dark:text-gray-200",
    ghost: disabled
      ? "text-gray-300 dark:text-gray-600"
      : "text-gray-600 dark:text-gray-200",
    destructive: "text-error-600 dark:text-error-400",
    link: "text-primary-700 dark:text-primary-200",
    "link-success": "text-success-600 dark:text-success-400",
    "link-destructive": "text-error-600 dark:text-error-400",
    "link-gray": "text-gray-400 dark:text-gray-600",
  }[type]);

export const buttonSizes = {
  "2xs": "button-2xs",
  xs: "button-xs",
  sm: "button-sm",
  md: "button-md",
  lg: "button-lg",
  xl: "button-xl",
  "2xl": "button-2xl",
};

const buttonTextColors = {
  primary: "text-primary-25",
  gradient: "text-white",
  submit: "text-primary-25",
  secondary: "text-primary-700",
  gray: "text-gray-700",
  ghost: "text-gray-600",
  destructive: "text-error-700",
  link: "text-primary-700",
  "link-inverted": "text-primary-50",
  "link-destructive": "text-error-700",
  "link-gray": "text-gray-600",
};

const buttonTextSizes = {
  "2xs": "text-body5",
  xs: "text-body4",
  sm: "text-body4",
  md: "text-body4",
  lg: "text-body4",
  xl: "text-body3",
};

const buttonTextWeights = {
  "2xs": "font-medium",
  xs: "font-medium",
  sm: "font-normal",
  md: "font-normal",
  lg: "font-normal",
  xl: "font-normal",
};

export const fluid = {
  fluid: "w-full",
};

const Button = ({
  size = "md",
  type = "gray",
  active,
  iconColor,
  loading,
  children,
  onClick,
  disabled = false,
  focused = false,
  icon = "",
  iconLeft = "",
  iconRight = "",
  className,
  fluid = false,
  id,
  inverted,
  inline = true,
  hint = null, // shown as tooltip on hover
  iconProps = {},
  trackParams = null,
  confirm = null,
}) => {
  const isLink = type?.includes("link");
  const {trackClick} = useButtonClickTracking();

  let iconColorCopy = getIconColor(iconColor, type, inverted, disabled);
  const iconSize = size === "md" ? "sm" : size;

  const content = children ? (
    <span
      className={
        loading
          ? "opacity-0 flex justify-center"
          : "flex justify-center items-center"
      }
    >
      {iconLeft && (
        <span className="pr-2">
          <Icon
            icon={iconLeft}
            color={iconColorCopy}
            size={iconSize}
            disabled={disabled}
            {...iconProps}
          />
        </span>
      )}
      <span className="w-full">{children}</span>
      {iconRight && (
        <span className="pl-2">
          <Icon
            icon={iconRight}
            color={iconColorCopy}
            size={iconSize}
            disabled={disabled}
            {...iconProps}
          />
        </span>
      )}
    </span>
  ) : (
    <span className={loading ? "opacity-0" : ""}>
      {icon && (
        //Size is forwarded to the Icon, so the Icon size is adapted to the button's size. However, the 'fa-md' size does not exist so we pass in 'sm' instead
        <Icon
          icon={icon}
          color={iconColorCopy}
          size={iconSize}
          disabled={disabled}
          {...iconProps}
        />
      )}
    </span>
  );

  const loadingContent = (
    <motion.span
      className="absolute left-0 w-full"
      animate={{
        scale: [0.5, 1.2, 1],
        opacity: loading ? 1 : 0,
      }}
      transition={{
        duration: 0.2,
      }}
    >
      <Icon icon="spinner" spin color={iconColorCopy} />
    </motion.span>
  );

  const Wrapper = hint ? Tooltip : React.Fragment;

  const WrapperProps = hint
    ? {content: hint, placement: "bottom", delayDuration: 0}
    : {};

  const handleClick = e => {
    if (trackParams) {
      trackClick(trackParams);
    }

    if (
      onClick &&
      typeof onClick === "function" &&
      !disabled &&
      confirm === null
    ) {
      onClick(e);
    }
  };

  return (
    <Wrapper {...WrapperProps}>
      <button
        id={id}
        className={[
          "button-base",
          inverted && "button-inverted",
          inline && "whitespace-nowrap inline",
          buttonSizes[size],
          buttonTypes[type],
          fluid && "w-full",
          // Only for links, underline when active is true
          isLink &&
            active &&
            "underline decoration-2 decoration-secondary-400 underline-offset-4",
          isLink && inline && "p-0",
          className,
        ].join(" ")}
        onClick={handleClick}
        disabled={disabled || loading}
      >
        {loading && loadingContent}
        {content}
      </button>
    </Wrapper>
  );
};

const getIconColor = (iconColor, type, inverted, disabled) => {
  if (iconColor) {
    return iconColor;
  }

  if (inverted) {
    return "text-primary-50";
  }

  return iconColorMapping(type, disabled);
};

Button.propTypes = {
  type: PropTypes.oneOf(Object.keys(buttonTypes)),
  size: PropTypes.oneOf(Object.keys(buttonSizes)),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
