import React from "react";

import {Maybe, Typography} from "Components/_Elements";
import useGetLandingPageSectionContent from "API/cms/landingPage/useGetLandingPageSectionContent";
import classnames from "classnames";
import ExplainerSection from "Components/LandingPageSection/ExplainerSection";

// Force compile tailwind classes
const themeTextColors = {
  primary: "text-primary text-primary-light",
  amber: "text-amber text-amber-light",
  success: "text-success text-success-light",
  purple: "text-purple text-purple-light",
};

const LandingPageSection = ({sectionId, ...props}) => {
  const [section, loading] = useGetLandingPageSectionContent(sectionId);

  if (loading || section === null) return null;

  const {h1, h2, contentsCollection, illustrationCollection} = section;

  return (
    <LandingPageSectionBody
      h1={h1}
      h2={h2}
      contentsCollection={contentsCollection}
      illustrationCollection={illustrationCollection}
      {...props}
    />
  );
};

export const LandingPageSectionBody = ({
  h1,
  h2,
  contentsCollection,
  illustrationCollection,
  children,
  className,
  ...props
}) => {
  const contentHasImage = contentsCollection?.items.some(
    content => content.illustration?.url
  );

  return (
    <div className={classnames("mt-8", className)}>
      <Maybe dep={h1}>
        <Typography
          type="dmd"
          weight="sb"
          color={`text-${props.theme}`}
          className="mt-3 text-left"
        >
          {h1}
        </Typography>
      </Maybe>

      <Maybe dep={h2}>
        <Typography
          type="tlg"
          color={`text-${props.theme}-light`}
          className="mt-3 text-left"
        >
          {h2}
        </Typography>
      </Maybe>

      <div
        className={classnames(
          !contentHasImage &&
            "flex flex-col md:flex-row gap-4 items-start justify-between"
        )}
      >
        {contentsCollection?.items.map((content, index) => {
          return <ExplainerSection content={content} key={index} {...props} />;
        })}
      </div>

      {illustrationCollection?.items.map((illustration, index) => (
        <img
          src={illustration.url}
          alt={illustration.title}
          key={index}
          {...props.illustrationProps}
        />
      ))}

      <div className="mt-6">{children}</div>
    </div>
  );
};

export default LandingPageSection;
