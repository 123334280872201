import React from "react";
import useGetTag from "API/queries/tag/useGetTag";
import useGetTagExercises from "API/queries/exercise/useGetTagExercises";
import {Loading, Tabs, Trans} from "Components";
import CourseTagExercises from "./CourseTagExercises";
import {useParams} from "react-router-dom";
import CourseTagHeader from "./CourseTagHeader";
import PreviewButton from "Components/PreviewButton/PreviewButton";
import Chapter from "routes/Course/Chapter/Chapter";
import {
  getChapterIcon
} from "routes/Course/CourseLanding/CourseLandingDesktop/CourseLandingTopics/CourseLandingTopicDetails/CourseLandingTopicTag/CourseLandingTopicTag";
import useWorkboardState from "../../../Components/Workboard/useWorkboardState";

const CourseTag = ({
  tagId: propsTagId,
  allowTabsPreview = true,
  showHeader = true,
  showExerciseViewHeader = false,
}) => {
  const {tagId: paramsTagId} = useParams();

  const tagId = propsTagId || paramsTagId;
  const state = useWorkboardState();

  const {previewMode, currentPreviewRoute} = React.useContext(
    window.PreviewSidebarContext
  );

  const {id: courseId, getCourseLink} = React.useContext(window.CourseContext);

  const [tag, tagLoading] = useGetTag(tagId, false, true);
  const [exercises, exercisesLoading] = useGetTagExercises(tagId, courseId);

  if (exercisesLoading || tagLoading) {
    return <Loading />;
  }

  return (
    <div>
      {showHeader && <CourseTagHeader tag={tag} loading={tagLoading} />}
      <div className="mt-2">
        <Tabs type="button" size="lg" persistCurrentTabInUrl={true}>
          <Tabs.Pane
            title={<Trans>Exercises</Trans>}
            badge={exercises.length}
            key="exercises"
            icon="fa-light fa-bolt"
          >
            <CourseTagExercises
              exercises={exercises}
              tag={tag}
              loading={tagLoading}
              showExerciseViewHeader={showExerciseViewHeader}
            />
          </Tabs.Pane>

          {tag?.chapters?.map((chapter, index) => {
            const chapterLink = getCourseLink(
              `chapter/${chapter.chapter_type}/${chapter.id}`
            );

            return (
              <Tabs.Pane
                title={<Trans>{chapter.chapter_type}</Trans>}
                key={getChapterKey(chapter, tag.chapters)}
                action={
                  !previewMode &&
                  allowTabsPreview && (
                    <PreviewButton route={chapterLink} type="gray" size="xs" />
                  )
                }
                icon={getChapterIcon(chapter.chapter_type)}
                active={currentPreviewRoute === chapterLink}
              >
                <Chapter
                  chapterType={chapter.chapter_type}
                  chapterId={chapter.id}
                  showHeader={false}
                />
              </Tabs.Pane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

const getChapterKey = (chapter, allChapters) => {
  const conflictingType =
    allChapters.find(other => other.chapter_type === chapter.chapter_type)
      .length > 1;

  if (conflictingType) {
    return `${chapter.chapter_type.toLocaleLowerCase()}-${chapter.id}`;
  }

  return `${chapter.chapter_type.toLocaleLowerCase()}`;
};

export default CourseTag;
