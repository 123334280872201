import React from "react";
import ExamUploadDropzone from "routes/Course/ExamUpload/ExamUploadDropzone/ExamUploadDropzone";
import useExamUploadContext, {
  ExamUploadContext,
} from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContext";
import useExamUploadGlobalAuthors from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadGlobalAuthors";
import ExamUploadStep from "routes/Course/ExamUpload/ExamUploadStep/ExamUploadStep";
import {Button, Trans} from "Components";
import ExamUploadDocuments from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocuments";
import ExamUploadProcessing from "routes/Course/ExamUpload/ExamUploadProcessing/ExamUploadProcessing";
import {AnimatePresence, AnimateSharedLayout} from "framer-motion";
import classnames from "classnames";

const ExamUpload = ({
  showSteps = true,
  hideIfExistingExams = false,
  showOutput = true,
}) => {
  const {user} = React.useContext(window.UserContext);

  const contextValues = useExamUploadContext();
  const globalAuthorsState = useExamUploadGlobalAuthors();

  const {
    loading,
    filesAdded,
    examsCount,
    currentStep,
    processingProgress,
    startReview,
    previewResource,
    documentsOverview,
    existingExamsCount,
  } = contextValues;

  if (loading) return null;

  if (!user?.id) return null;

  if (hideIfExistingExams && existingExamsCount > 0 && filesAdded === 0) {
    return null;
  }

  // Create a step view
  // 1. Dropzone and overview
  // 2. Processing loader
  // 3. Table with documents for validation

  /**
   The steps for the upload process
   - Title: Translated title of the step
   - Subtitle: Translated subTitle of the step
   - activeComponent: The component that should be shown when this step is active
   **/
  const steps = [
    {
      title: <Trans>Upload files</Trans>,
      subTitle: <Trans>Drag and drop your files or folders below</Trans>,
      activeComponent: (
        <ExamUploadDropzone
          resources={contextValues.resources}
          disabled={contextValues.loading}
        />
      ),
      inactiveComponent: null,
      completedMessage: (
        <Trans filesAdded={filesAdded}>
          {{filesAdded}} files have been added
        </Trans>
      ),
      currentMessage: <div />,
    },
    {
      title: <Trans>Kollin does some magic</Trans>,
      subTitle: <Trans>Kollin matches the exams and solutions per date</Trans>,
      activeComponent: <ExamUploadProcessing progress={processingProgress} />,
      inactiveComponent: <div>TEST TEST TEST</div>,
      completedMessage: (
        <Trans examsCount={examsCount}>
          {{examsCount}} exams have been identified
        </Trans>
      ),
      currentMessage: <div />,
    },
    {
      title: <Trans>Review and save</Trans>,
      subTitle: <Trans>Go through the results and complete your upload</Trans>,
      inactiveComponent: null,
      activeComponent: (
        <ExamUploadDocuments
          showOutput={showOutput}
          resources={contextValues.resources}
          disabled={contextValues.loading}
        />
      ),
      completedMessage: <div />,
      fullWidth: true,
      currentMessage: documentsOverview["savedExams"] !==
        documentsOverview["totalExams"] && (
        <Button type="primary" onClick={startReview} disabled={previewResource}>
          <Trans>Start review</Trans>
        </Button>
      ),
    },
  ];

  return (
    <ExamUploadContext.Provider
      value={{...contextValues, ...globalAuthorsState}}
    >
      <div
        className={classnames(
          showOutput === false &&
            currentStep === 2 &&
            "h-0 opacity-0 overflow-hidden"
        )}
      >
        <AnimatePresence presenceAffectsLayout exitBeforeEnter={true}>
          <AnimateSharedLayout type="crossfade">
            {steps.map((step, index) => {
              let status = "not started"; // default status
              if (currentStep === index) status = "current";
              if (currentStep > index) status = "completed";

              return (
                <ExamUploadStep
                  {...step}
                  status={status}
                  key={index}
                  index={index}
                  currentStep={currentStep}
                  isLastStep={index === steps.length - 1}
                  showSteps={showSteps}
                  fullWidth={!showSteps} // When showSteps is false, the step should be full width automatically
                />
              );
            })}
          </AnimateSharedLayout>
        </AnimatePresence>
      </div>
    </ExamUploadContext.Provider>
  );
};

export default ExamUpload;
