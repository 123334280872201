import { useMutation } from "@apollo/react-hooks";
import { GET_EXERCISE_TAG_SUGGESTIONS } from "API/queries/exercise/useGetExerciseTagSuggestions";
import { GET_COURSE_TAGS } from "API/queries/tag/useGetCourseTags";
import gql from "graphql-tag";
import React from "react";
import { CourseContext } from "routes/Course/CourseContextProvider";
import { UserContext } from "UserContextProvider";

const CAST_VOTE_MUTATION = gql`
  mutation castTagVote($exerciseId: ID!, $tagId: ID, $newTagName: String) {
    castTagVote(
      exercise_id: $exerciseId
      tag_id: $tagId
      new_tag_name: $newTagName
    ) {
      ok
      error
    }
  }
`;

const useCastExerciseTagVoteMutation = exerciseId => {
  const { courseId } = React.useContext(CourseContext);
  const { user } = React.useContext(UserContext);

  const [castTagVote, { loading, data, error }] = useMutation(
    CAST_VOTE_MUTATION,
    {
      refetchQueries: () => [
        {
          query: GET_EXERCISE_TAG_SUGGESTIONS,
          variables: { exerciseId, courseId, loggedIn: user !== null },
        },
        {
          query: GET_COURSE_TAGS,
          variables: { exerciseId, courseId },
        },
      ],
    }
  );

  return [castTagVote, loading];
};

export default useCastExerciseTagVoteMutation;
