import React from "react";

const useGroupAdminUploadMatchUsers = ({
  groupMembers,
  fileMembers,
  groupLoading,
}) => {
  const [loading, setLoading] = React.useState(false);

  const [result, setResult] = React.useState({
    matchedMembers: [], // Members in CSV and in group => to be updated
    unmatchedMembers: [], // Members in CSV but not in group => to be invited
    nonMembers: [], // Members in group but not in CSV
  });

  const matchMembers = () => {
    // Matched members: CSV && group
    // Unmatched members: CSV && !group
    // Non-members: !CSV && group
    setLoading(true);

    const matchedMembers = [];
    const unmatchedMembers = [];

    for (const fileMember of fileMembers) {
      const matchedMember = groupMembers.find(
        member => compareMember(fileMember, member.user) // If member is null, skip
      );

      // Push to correct array
      if (matchedMember) {
        matchedMembers.push({
          ...matchedMember,
        });
      } else {
        unmatchedMembers.push(fileMember);
      }
    }

    // Find non-members which are in the group but not in the CSV
    const nonMembers = groupMembers.filter(
      groupMember =>
        !fileMembers.find(fileMember =>
          compareMember(fileMember, groupMember.user)
        )
    );

    setResult({
      matchedMembers,
      unmatchedMembers,
      nonMembers,
    });

    // Minor delay to show loading
    setTimeout(() => {
      setLoading(false);
    }, 1000 + Math.random() * 1000);
  };

  React.useEffect(() => {
    if (!groupLoading && fileMembers.length > 0) {
      matchMembers();
    }
  }, [groupMembers, fileMembers, groupLoading]);

  return {
    loading,
    result,
  };
};

const compareMember = (fileMember, groupMember) => {
  // Cross compare fileMember[emailHash, secondEmailHash] with groupMember[emailHash, studentEmailHash] if any match return true
  // We have 4 possible combinations of email addresses:

  let gmFields = [groupMember.emailHash, groupMember.studentEmailHash];
  let fmFields = [fileMember.emailHash, fileMember.secondEmailHash];

  // Remove nulls since they will five false positives
  gmFields = gmFields.filter(field => field !== null);
  fmFields = fmFields.filter(field => field !== null);

  // Check overlap
  const overlap = fmFields.filter(field => gmFields.includes(field));
  return overlap.length > 0;
};

export default useGroupAdminUploadMatchUsers;
