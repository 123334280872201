import React from "react";
import CDN from "utilities/constants/CDN";
import {Button, Link, Trans, Typography} from "Components/index";
import {AnimatePresence, motion} from "framer-motion";
import ReactDOM from "react-dom";
import {animationVariants} from "Components/_Elements/Modal/Modal.constants";
import useOutsideClickAlerter from "utilities/hooks/useOutsideClickAlerter";
import useGetCurrentUserActiveCourses from "API/queries/course/useGetCurrentUserActiveCourses";
import useCourseSidebarHeaderPicker from "Components/Sidebar/CourseSidebar/CourseSidebarHeader/CourseSidebarHeaderPicker/useCourseSidebarHeaderPicker";
import {Icon} from "Components/_Elements";
const CourseSidebarHeaderPicker = ({currentCourseId}) => {
  const {
    ref,
    showCoursePicker,
    activateCoursePicker,
    hideCoursePicker,
    loading,
    courseList,
  } = useCourseSidebarHeaderPicker(currentCourseId);

  if (loading || courseList.length < 2) return null; // no other course to show

  return (
    <AnimatePresence>
      <Button
        icon="repeat"
        className="ml-2"
        type="link"
        onClick={activateCoursePicker}
        hint={<Trans>Switch Course</Trans>}
      />
      {showCoursePicker && (
        <div>
          <motion.div
            ref={ref}
            initial={{opacity: 0, width: 0, height: 0, left: "80%"}}
            animate={{opacity: 1, width: "auto", height: "auto", left: "100%"}}
            exit={{
              opacity: 0,
              width: 0,
              height: 0,
              left: "80%",
            }}
            className="z-50 absolute left-full top-0 background border-2 border-color rounded-lg shadow-lg"
          >
            {courseList.map(course => (
              <Link basic to={`/course/${course.id}`} key={course.id}>
                <div
                  key={course.id}
                  className=" flex gap-2 items-center p-3 rounded-lg cursor-pointer hover:surface-primary "
                >
                  <img
                    className="w-8"
                    src={`${CDN}/course-icons/${course.icon}.svg`}
                  />
                  <div className="overflow-hidden">
                    <Typography
                      color="text-gray"
                      type="tsm"
                      className="truncate"
                      weight="md"
                    >
                      {course.name}
                    </Typography>
                  </div>
                </div>
              </Link>
            ))}
          </motion.div>
          {ReactDOM.createPortal(
            <motion.div
              className="modal-background z-0"
              {...animationVariants["modalBackgroundOpen"]}
            />,
            document.body
          )}
        </div>
      )}
    </AnimatePresence>
  );
};

export default CourseSidebarHeaderPicker;
