import React from "react";
import { CourseContext } from "routes/Course/CourseContextProvider";

const useLastExerciseInCourse = exerciseId => {
  const { courseId } = React.useContext(CourseContext);

  // If an exerciseId was given it means we want to set it in local storage:
  if (exerciseId) {
    localStorage.setItem(`LAST_EXERCISE_IN_COURSE_${courseId}`, exerciseId);
  }

  return localStorage.getItem(`LAST_EXERCISE_IN_COURSE_${courseId}`);
};

export default useLastExerciseInCourse;
