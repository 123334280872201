import React from "react";
import {Loader, SafeText, Trans} from "Components";
import PropTypes from "prop-types";
import classnames from "classnames";
import {LoaderTypes} from "Components/_Elements/Loader/Loader";

const Loading = ({
  loading = true,
  fluid = true,
  title = <Trans>Loading</Trans>,
  type = "gray",
  loaderType = "Pulse",
}) => {
  return (
    <div
      className={classnames(
        "flex flex-col items-center justify-center gap-8 p-8 rounded-lg ",
        fluid && "w-full",
        typesBackgroundColors[type]
      )}
    >
      <Loader type={loaderType} color={loaderColors[type]} />
      <SafeText color={typesTextColors[type]} weight="b" type="tlg">
        {title}
      </SafeText>
    </div>
  );
};

Loading.propTypes = {
  /**
   * If true, the loading component is shown (true by default)
   */
  loading: PropTypes.bool,
  /**
   * If true, the loading component takes the full width of its container (true by default)
   */
  fluid: PropTypes.bool,
  /**
   * The title of the loading component
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The type of the loading component
   */
  type: PropTypes.oneOf(["primary", "gray", "success", "warning", "error"]),
  /**
   * The type of the loader
   */
  loaderType: PropTypes.oneOf(LoaderTypes),
};

const typesTextColors = {
  primary: "text-primary",
  gray: "text-gray",
  success: "text-success",
  warning: "text-warning",
  error: "text-error",
};

const typesBackgroundColors = {
  primary: "surface-primary",
  gray: "surface-gray",
  success: "surface-success",
  warning: "surface-warning",
  error: "surface-error",
};

export const loaderColors = {
  primary: "#6E8BE2",
  gray: "#667085",
  success: "#12B76A",
  warning: "#F79009",
  error: "#F04438",
};

export default Loading;
