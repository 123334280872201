import React from "react";
import { UserContext } from "UserContextProvider";

const Admin = ({ children, fallback }) => {
  const { user } = React.useContext(UserContext);

  if (user?.admin) return children;

  return fallback || null;
};

export default Admin;
