import React from "react";
import {pdfjs} from "react-pdf";
import {range} from "utilities/numbers";
import PropTypes from "prop-types";
import classnames from "classnames";
import {PageLoader} from "Components/index";
const PDFDocument = React.lazy(() => import("react-pdf/dist/Document"));
const Page = React.lazy(() => import("react-pdf/dist/Page"));

const Document = ({
  url,
  fileType,
  container,
  onPagesLoaded = () => {},
  showAllPages,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc =
    "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.min.js";

  const [state, resetState] = React.useState({
    pages: 0,
    width: 0,
    loading: true,
  });

  React.useEffect(() => {
    if (fileType === "pdf") {
      onPagesLoaded(state.pages);
    }
  }, [state.pages]);

  const {pages, width, loading} = state;
  const setState = keyVal => resetState({...state, ...keyVal});

  React.useEffect(() => {
    if (!container.current) return;

    setState({width: container.current.offsetWidth});
  }, [container.current]);

  if (!container.current || width === 0) return null;

  if (fileType === "pdf") {
    return (
      <React.Suspense fallback={<PageLoader />}>
        <PDFDocument
          file={url}
          onLoadError={console.error}
          onLoadSuccess={({numPages, ...rest}) => {
            setState({
              pages: numPages,
              loading: false,
            });
          }}
        >
          <Page
            width={width}
            pageNumber={1}
            renderAnnotationLayer={true}
            className={classnames("")}
          />
          {showAllPages &&
            !loading &&
            range(pages, 1).map(p => (
              <Page
                key={p}
                pageNumber={p + 1}
                width={width}
                renderAnnotationLayer={false}
              />
            ))}
        </PDFDocument>
      </React.Suspense>
    );
  }

  if (fileType === "png" || fileType === "jpg" || fileType === "jpeg") {
    return <img src={url} alt="upload preview" style={{width: "100%"}} />;
  }

  return <i className="fas fa-file-alt blue w-100" />;
};

Document.propTypes = {
  /**
   * The url of the file to display
   */
  url: PropTypes.string.isRequired,
  /**
   * The type of the file to display
   */
  fileType: PropTypes.oneOf(["pdf", "png", "jpg", "jpeg"]).isRequired,
  /**
   * Reference (ref) to the container of the document
   */
  container: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  /**
   * Callback to set the number of pages in the document
   */
  setPagesCount: PropTypes.func,
  /**
   * Whether to show all pages or just the first one
   */
  showAllPages: PropTypes.bool,
};

export default Document;
