import React from "react";
import ErrorCodes from "ErrorCodes";

const FETCH_ERROR_CODES = false;

const useGetErrorCodes = () => {
  // Fetches error codes from the server and stores them in the window storage:
  React.useEffect(() => {
    const asyncErrorHandler = async () => {
      if (FETCH_ERROR_CODES) {
        try {
          const API_URL = process.env.REACT_APP_API_URL;

          const rawResponse = await fetch(`${API_URL}/errorcodes`);
          const result = await rawResponse.json();

          window.ErrorCodes = result;
        } catch (error) {
          console.log("Error when running useGetErrorCodes: ", error);
        }
      } else {
        window.ErrorCodes = ErrorCodes;
      }
    };

    asyncErrorHandler();
  }, []);
};

export default useGetErrorCodes;
