import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_CURRENT_USER_MEMBERSHIP = gql`
  query currentUserMemberships(
    $includeInvoices: Boolean!
    $includeSubscriptionPlan: Boolean!
    $includeStripeData: Boolean!
  ) {
    currentUser {
      id
      memberships {
        id
        status
        expirationDate # Same as current_period_end in Stripe
        createdAt
        updatedAt
        subscriptionPlan @include(if: $includeSubscriptionPlan) {
          id
          name
          price
          interval
          trialDays
          pricingId
        }
        invoices @include(if: $includeInvoices) {
          id
          status
          number
          period_end
          currency
          total
          tax
          hosted_invoice_url
          invoice_pdf
          next_payment_attempt
          coupons {
            id
            amountOff
            currency
          }
        }
        stripeData @include(if: $includeStripeData) {
          cancelAtPeriodEnd
          cancelAt
          trialEnd
        }
      }
    }
  }
`;

const useGetCurrentUserSubscription = (
  includeInvoices = false,
  includeSubscriptionPlan = false,
  includeStripeData = false
) => {
  let subscription = null;
  let allSubscriptions = [];

  const {data, loading, error} = useQuery(GET_CURRENT_USER_MEMBERSHIP, {
    variables: {includeInvoices, includeSubscriptionPlan, includeStripeData},
    fetchPolicy: "cache-first",
  });

  if (error) {
    console.log(`Error  in useGetCurrentUserSubscription: ${error}`);
  }

  if (data && data.currentUser) {
    const memberships = data.currentUser.memberships.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    // Since we are sorting by createdAt, the first item in the array will be the most recent which should be the current subscription
    subscription = memberships.length > 0 ? memberships[0] : null;
    allSubscriptions = memberships;
  }

  let subscriptionActive = false;

  if (subscription) {
    subscriptionActive =
      subscription.status === "active" ||
      subscription.status === "trialing" ||
      subscription.status === "past_due";
  }

  return {
    subscription,
    allSubscriptions,
    subscriptionActive,
    loading,
  };
};

export default useGetCurrentUserSubscription;
