import React from "react";
import examDocumentsMatching from "utilities/examDocumentsMatching/examDocumentsMatching";
import { sortObjectKeys } from "utilities/objects";
import getOverviewStats from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsOverview/getOverviewStats";

/**
 *  Tracks the provided documents state
 *  When a document is added it needs to be matched to it's corresponding pair-set (exam, solutions)
 *
 *  INPUT
 *  -----------------
 *  The document will have the following properties:
 *  - [D]: date(s) - One or more dates found in the document for identification
 *  - A: Author - The author of the document
 *  - [T]: Type(s): One or more types of the document (exam, solutions, mixed)
 *  - H: Hash: The hash of the document
 *
 *  INITIAL APPROACH
 *  -----------------
 *  The initial matching algorithm is based on the "best-case" scenario
 *  where the assumption is that the document has exactly one date and one type.
 *
 *  1. £ <- Exams[D]
 *  2. Check each £.T'
 *      2.A if £.T' != T and £.H' == H then set £.mixed = true
 *      2.B if £.T' == T then set £ = Input
 *  3. If Input.T == 'mixed' then add Input to Exams and set Input.mixed = true
 *  4. Update state
 *
 *
 *  EDGE CASES
 *  -----------------
 *  Following is a case-by-case analysis of the edge cases:
 */
const useExamUploadDocumentMatching = ({ resources, examsByDate }) => {
  const INITIAL_STATE = {
    all: [],
    unknown_date: [],
    dates: {},
  };
  const [documents, setDocuments] = React.useState({
    ...INITIAL_STATE,
  });

  const removeDocumentByDate = date => {
    const newDocuments = {
      ...documents,
    };

    // Remove the date key
    delete newDocuments["dates"][date];

    newDocuments.dates = sortDates(newDocuments.dates);

    // Update the state
    setDocuments(newDocuments);
  };

  const updateDocumentByDate = (date, updatedDocument) => {
    let newDocuments = {
      ...documents,
    };

    // Update the document
    newDocuments["dates"][date] = updatedDocument;

    newDocuments.dates = sortDates(newDocuments.dates);

    // Update the state
    setDocuments(newDocuments);
  };

  React.useEffect(() => {
    let output = {
      ...INITIAL_STATE,
    };

    // Run the matching algorithm
    examDocumentsMatching(output, resources).then(() => {
        // Update the state
        output.dates = sortDates(output.dates);
        setDocuments(output);
    });

  }, [resources]);

  const examsCount = React.useMemo(() => {
    return Object.values(documents["dates"]).filter(d => !d.hidden).length;
  }, [documents]);

  const unsavedDocumentsCount = React.useMemo(() => {
    return Object.values(documents["dates"]).filter(d => !d.saved).length;
  }, [documents]);

  const documentsOverview = React.useMemo(() => getOverviewStats(documents), [
    documents,
  ]);

  return {
    documents,
    documentsOverview,
    examsCount,
    unsavedDocumentsCount,
    removeDocumentByDate,
    updateDocumentByDate,
  };
};

const sortDates = dateObjs =>
  sortObjectKeys(dateObjs, (a, b) => b.localeCompare(a));

export default useExamUploadDocumentMatching;
