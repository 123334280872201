import React from "react";
import * as CheckboxRadix from "@radix-ui/react-checkbox";
import classnames from "classnames";
import {Icon, Trans} from "Components";
import PropTypes from "prop-types";

const Checkbox = ({
  onChange,
  disabled,
  label = <Trans>Remember me?</Trans>,
  checked,
  name,
}) => {
  return (
    <span className="flex items-start justify-start gap-2">
      <CheckboxRadix.Root
        name={name}
        disabled={disabled}
        checked={checked}
        onCheckedChange={checked => onChange(checked)}
        defaultChecked={checked}
        className={classnames(
          "align-middle h-5 w-5 aspect-square flex shrink-0 items-center justify-center rounded overflow-clip",
          checked
            ? " border-1 border-primary-600"
            : " border-2 border-gray dark:border-gray-600 bg-white"
        )}
      >
        <CheckboxRadix.Indicator className=" w-full bg-primary-100 dark:bg-primary-600">
          <Icon
            icon="fa-solid fa-check"
            color="text-primary-600 dark:text-primary-300"
            size="sm"
          />
        </CheckboxRadix.Indicator>
      </CheckboxRadix.Root>
      <label
        className="flex flex-col items-center justify-start select-none"
        onClick={() => onChange(!checked)}
        htmlFor={name}
      >
        {label &&
          React.cloneElement(label, {
            weight: "md",
            color: "text-gray-700 dark:text-gray-400",
            className: "-mt-[2px]", // to align the text with the checkbox
          })}
      </label>
    </span>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.instanceOf(Element).isRequired,
  subTitle: PropTypes.instanceOf(Element),
  checked: PropTypes.bool,
  name: PropTypes.string,
};

export default Checkbox;
