import React from "react";

import useHandleCoursePurchaseClick from "utilities/hooks/useHandleCoursePurchaseClick";
import {UserContext} from "UserContextProvider";
import {CourseLandingContext} from "routes/Course/CourseLanding/useCourseLandingContext";
import {WaveBorderBox} from "Components";
import SubscriptionTrialBanner from "../../../../Components/SubscriptionTrialBanner/SubscriptionTrialBanner";
import {GroupDiscountsFreeWrapper} from "Widgets/Group/GroupDiscounts/GroupDiscountsPriceWrapper";

const CourseLandingUnlockCard = () => {
  const {course, productPrice} = React.useContext(CourseLandingContext);
  const {user} = React.useContext(UserContext);

  const onPurschaseClick = useHandleCoursePurchaseClick(
    course.id,
    course.price
  );

  if (productPrice === 0 || user?.premium || user?.subscriber) return null;

  return (
    <div className="-mx-10 px-8">
      <WaveBorderBox
        bottomVariant={1}
        topVariant={1}
        fillColor="fill-primary-800"
      >
        <GroupDiscountsFreeWrapper>
          <SubscriptionTrialBanner />
        </GroupDiscountsFreeWrapper>
        {/*<KollinGoldBanner closable={false} compact={true} />*/}
      </WaveBorderBox>
    </div>
  );
};

export default CourseLandingUnlockCard;
