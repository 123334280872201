import React from "react";

const usePreviewSidebarSizeState = () => {
  const screenWidth = window.innerWidth;

  const initialWidth = screenWidth * 0.45;

  const MIN_WIDTH = screenWidth * 0.2;
  const MAX_WIDTH = screenWidth - MIN_WIDTH;

  const [sidebarWidth, setSidebarWidth] = React.useState(initialWidth); // percentage of the screen

  const onMouseMove = e => {
    e.preventDefault();
    e.stopPropagation();

    // TODO: Check if the coordinates are out of bounds and scroll the window if needed
    setSidebarWidth(prev => {
      return Math.max(MIN_WIDTH, Math.min(MAX_WIDTH, screenWidth - e.pageX));
    });
  };

  const onMouseUp = e => {
    e.preventDefault();
    e.stopPropagation();
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
    document.body.style.cursor = "default";
  };

  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    // direction.current = e.target.getAttribute("data-position");
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    // Set cursor to grabbing
    document.body.style.cursor = "col-resize";
  };

  const resizeHandleRef = React.useCallback(node => {
    if (node !== null) {
      node.addEventListener("mousedown", onMouseDown);
    }
  }, []);

  const mainBarWidth = screenWidth - sidebarWidth;

  return {
    sidebarWidth,
    mainBarWidth,
    resizeHandleRef,
  };
};

export default usePreviewSidebarSizeState;
