import React from "react";
import classnames from "classnames";
import { Icon, Typography } from "Components";
import { truncateText } from "utilities/text";
import ExamUploadDocumentsRowFilesTypes from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsRow/ExamUploadDocumentsRowFiles/ExamUploadDocumentsRowFilesTypes";
import { motion } from "framer-motion";

const ExamUploadDocumentsRowFilesItem = ({ file }) => {
  const { togglePreview, previewResource } = React.useContext(
    window.ExamUploadContext
  );

  const selected = previewResource?.id === file.id;
  const previewOpen = previewResource !== null;
  const disabled = file.saved;
  const handleClick = togglePreview.bind(null, file);

  return (
    <motion.div
      initial={{
        opacity: 0,
        scale: 0,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      key={file.id}
      className={classnames(
        " p-4  border-2 rounded-lg  background w-full flex items-center justify-between cursor-pointer",
        selected
          ? "border-primary-strong shadow-lg"
          : "border-primary-50 dark:border-primary-800"
        // file.saved && "surface border-success-50 dark:border-success-800"
      )}
      onClick={handleClick}
    >
      <div className="flex gap-4 items-center overflow-hidden">
        <Icon
          fixedWidth
          size="md"
          icon={file.saved ? "far fa-check-circle" : "fa-solid fa-file"}
          fillType={file.saved ? "success" : "purple"}
          filled
        />
        <Typography
          type="tsm"
          weight="medium"
          color="text-primary"
          className="truncate"
        >
          {truncateText(file.name, 100)}
        </Typography>
      </div>

      <div className={classnames(previewOpen && "align-self-end")}>
        <ExamUploadDocumentsRowFilesTypes
          type={file.type}
          fileId={file.id}
          disabled={file.saved}
        />
      </div>
    </motion.div>
  );
};

export default ExamUploadDocumentsRowFilesItem;
