/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      introduction
      groupId
      links {
        title
        url
        icon
        imageUrl
        color
        __typename
      }
      contacts {
        name
        role
        email
        phone
        groupMemberId
        __typename
      }
      feedbackFormId
      posts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      introduction
      groupId
      links {
        title
        url
        icon
        imageUrl
        color
        __typename
      }
      contacts {
        name
        role
        email
        phone
        groupMemberId
        __typename
      }
      feedbackFormId
      posts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      introduction
      groupId
      links {
        title
        url
        icon
        imageUrl
        color
        __typename
      }
      contacts {
        name
        role
        email
        phone
        groupMemberId
        __typename
      }
      feedbackFormId
      posts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGroupPost = /* GraphQL */ `
  mutation CreateGroupPost(
    $input: CreateGroupPostInput!
    $condition: ModelGroupPostConditionInput
  ) {
    createGroupPost(input: $input, condition: $condition) {
      id
      title
      groupMemberId
      content
      relatedCourseId
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      groupPostsId
      __typename
    }
  }
`;
export const updateGroupPost = /* GraphQL */ `
  mutation UpdateGroupPost(
    $input: UpdateGroupPostInput!
    $condition: ModelGroupPostConditionInput
  ) {
    updateGroupPost(input: $input, condition: $condition) {
      id
      title
      groupMemberId
      content
      relatedCourseId
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      groupPostsId
      __typename
    }
  }
`;
export const deleteGroupPost = /* GraphQL */ `
  mutation DeleteGroupPost(
    $input: DeleteGroupPostInput!
    $condition: ModelGroupPostConditionInput
  ) {
    deleteGroupPost(input: $input, condition: $condition) {
      id
      title
      groupMemberId
      content
      relatedCourseId
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      groupPostsId
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      content
      groupMemberId
      createdAt
      updatedAt
      groupPostCommentsId
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      content
      groupMemberId
      createdAt
      updatedAt
      groupPostCommentsId
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      content
      groupMemberId
      createdAt
      updatedAt
      groupPostCommentsId
      __typename
    }
  }
`;
