import React from "react";

const Debug = ({ children }) => {
  const params = new URLSearchParams(window.location.search);

  if (params.get("debug")) {
    return children;
  }

  return null;
};

export default Debug;
