import React from "react";
import {Button, Confirm, Icon, Trans, Typography} from "Components";
import GroupInvitesUser from "routes/Group/GroupInvites/GroupInvitesUser";
import GroupInvitesCreate from "routes/Group/GroupInvites/GroupInvitesCreate/GroupInvitesCreate";
import GroupAdminUploadPanesInviteExport from "routes/Group/GroupAdminUpload/GroupAdminUploadPanes/GroupAdminUploadPanesInvite/GroupAdminUploadPanesInviteExport";
import GroupAdminUploadPanesInviteLink from "routes/Group/GroupAdminUpload/GroupAdminUploadPanes/GroupAdminUploadPanesInvite/GroupAdminUploadPanesInviteLink";

const GroupAdminUploadPanesInvite = ({fileMembers, result}) => {
  const membersToInvite = result.unmatchedMembers;

  const [inviteAllTriggered, setInviteAllTriggered] = React.useState(false);

  return (
    <div className="w-full mt-4 ">
      <Typography type="txl" weight="sb">
        Folk som inte finns med i gruppen
      </Typography>

      <div className="flex justify-between items-center">
        <Typography className="mt-2 max-w-xl">
          <Icon icon="info-circle" className="mr-1" />
          Du kan exportera en lista med alla medlemmar som inte finns med i
          gruppen. Eller så kan du bjuda in de direkt via Kollin då skickar vi
          ett mail till dem med en personlig länk.
        </Typography>
      </div>

      <div className="my-6 border-t-2 border-gray-100" />
      <GroupAdminUploadPanesInviteExport membersToInvite={membersToInvite} />
      <div className="my-6 border-t-2 border-gray-200" />
      <div className="p-4 bg-gray-50 rounded-xl">
        <div className="mb-4 flex items-center justify-between">
          <Typography type="txl" weight="md">
            Bjud in via Kollin
          </Typography>
          <Confirm onConfirm={() => setInviteAllTriggered(true)}>
            <Button type="primary">
              <Trans>Invite all members below</Trans>
            </Button>
          </Confirm>
        </div>
        {membersToInvite.map((member, index) => {
          return (
            <GroupInvitesUser
              user={{
                ...member,
                firstName: member.firstName || "",
                lastName: member.lastName || "",
              }}
              showAvatar={false}
              createdAt={null}
              accepted={false}
              toBeInvited={true}
              email={member.email}
              id={member.email + index}
              backgroundColor="bg-slate-100"
              action={
                <div className="col-span-2 flex justify-end">
                  <GroupInvitesCreate
                    email={member.email}
                    triggerMutation={inviteAllTriggered}
                    triggerDelay={index * 500}
                  />
                </div>
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default GroupAdminUploadPanesInvite;
