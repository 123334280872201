import React from "react";
import { Button, Icon, message, TextArea, Trans } from "Components";
import useSubmitForm from "API/sheets";

const CourseProgressSuggestions = ({ onComplete }) => {
  const { user } = React.useContext(window.UserContext);
  const [state, setState] = React.useState({
    suggestion: "",
  });

  const handleChange = event =>
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });

  const { success, error, loading, submit } = useSubmitForm(
    "COURSE_PROGRESS_SUGGESTION_FORM"
  );

  React.useEffect(() => {
    if (success) {
      message.success("Tack för din feedback!");
      onComplete();
    }
  }, [success]);

  const onSubmit = event => {
    event.preventDefault();
    submit({
      ...state,
      userId: user?.id,
      email: user?.email,
      programName: user?.program?.name,
      schoolName: user?.school?.name,
    });
  };

  return (
    <div>
      <Icon
        icon="fa-regular fa-chart-mixed"
        size="lg"
        filled
        color="text-primary-600"
        outlined
      />
      <form onSubmit={onSubmit} className="mt-4 flex flex-col gap-4">
        <Trans type="dxs">Förslag på statistik</Trans>
        <TextArea
          name="suggestion"
          onChange={handleChange}
          placeholder={"Ange dina förslag här"}
          errorMessageRelative
          errorMessage={error}
        />
        <Button
          type="primary"
          iconRight="fa-light fa-paper-plane"
          onClick={onSubmit}
          loading={loading}
          error={error}
          disabled={state.suggestion === ""}
        >
          <Trans>Skicka</Trans>
        </Button>
      </form>
    </div>
  );
};

export default CourseProgressSuggestions;
