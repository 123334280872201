import useSubmitForm from "API/sheets/useSubmitForm";

export const sheets = {
  MEMBERSHIP_CANCELLATION_FORM: {
    spreadSheetId: "1WV3J4CEcMoQldyHV76Bu1iGXRaAoQz1xN7owjEFlEr0",
    sheetName: "responses",
  },
  COURSE_REQUEST_FORM: {
    spreadSheetId: "1jlStaJryjb6RBx2sLNPRXvtIFG6G-DsDE0QnpyFcFjk",
    sheetName: "responses",
  },
  COURSE_PROGRESS_SUGGESTION_FORM: {
    spreadSheetId: "1lJc1ctjzIPCh-Dh8rFf6L9r20rfs2-VDTYefAhoXszg",
    sheetName: "responses",
  },
  SCHOOL_REQUEST_FORM: {
    spreadSheetId: "1qJSQEkIQxiCWvgZa5n3hxn7-vd8eIBIePXsCFNwE_LU",
    sheetName: "responses",
  },
  WIDGET_FEEDBACK_FORM: {
    spreadSheetId: "1lUXCj3fm0S9lUHG3gXi45tnVd2w2H1LqsZXCy8lzNpk",
    sheetName: "responses",
  },
  JUL_KAMPANJ_2023: {
    spreadSheetId: "1_-8uOa27DuYO6xc_HtBK7iBet4sf4Sjnk3UaoCW3XOk",
    sheetName: "responses",
  },
};

export default useSubmitForm;
