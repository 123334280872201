import React from "react";
import {Button, ButtonGroup, Typography} from "Components/_Elements";
import {GPTContext} from "Components/GPT/GPTProvider";
import Trans from "Components/Trans";

const AllowedLengths = {
  100: "S",
  500: "M",
  1000: "L",
};

const GPTUserSettingsLength = () => {
  const {settings, setMaxTokens} = React.useContext(GPTContext);

  return (
    <ButtonGroup>
      {Object.keys(AllowedLengths).map((length, index) => (
        <Button
          key={index}
          size="sm"
          onClick={() => setMaxTokens(Number(length))}
          type={settings.maxTokens === Number(length) ? "primary" : "gray"}
          hint={<Typography>{`${Number(length)} tokens`}</Typography>}
        >
          <Typography type="tsm">{AllowedLengths[length]}</Typography>
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default GPTUserSettingsLength;
