import React from "react";
import classnames from "classnames";
import {
  circleBorderWidth,
  circleSizeClasses,
  circleTextSize,
} from "Components/_Elements/ProgressSteps/ProgressSteps.constants";
import {Icon, Typography} from "Components";

const ProgressStepCircle = ({status, selected, size, numbered, index}) => {
  return (
    <div
      className={classnames(
        "shrink-0 flex items-center justify-center rounded-full",
        circleSizeClasses[size],
        status === "complete" && "bg-success-500",
        status === "current" && `surface-primary `,
        status === "incomplete" && "bg-slate-100",
        selected && `ring ring-primary-200 ${circleBorderWidth[size]} `
      )}
    >
      <ProgressStepCircleText
        size={size}
        status={status}
        numbered={numbered}
        index={index}
      />
    </div>
  );
};

const ProgressStepCircleText = ({size, numbered, status, index}) => {
  const iconSize = {
    sm: "xs",
    md: "md",
    lg: "lg",
  };

  if (status === "complete") {
    // Always returns a checkmark
    return (
      <Icon
        icon="fas fa-check"
        fixedWidth
        size={iconSize[size]}
        color="text-success-50"
      />
    );
  }

  if (status === "current") {
    if (numbered) {
      return (
        <Typography
          weight="b"
          color="text-primary-light"
          type={circleTextSize[size]}
        >
          {index + 1}
        </Typography>
      );
    }

    // returns an empty circle
    return (
      <Icon
        icon="fa-solid fa-circle"
        size={iconSize[size]}
        color="text-primary-light"
      />
    );
  }

  if (status === "incomplete") {
    if (numbered) {
      return (
        <Typography
          weight="sb"
          color="text-slate-500"
          type={circleTextSize[size]}
        >
          {index + 1}
        </Typography>
      );
    }

    // returns an empty circle
    return (
      <Icon
        icon="fa-solid fa-circle"
        size={iconSize[size]}
        color="text-slate-300"
      />
    );
  }

  return null;
};

export default ProgressStepCircle;
