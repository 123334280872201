import React from "react";
import {Icon} from "Components/_Elements/index";

const InputIconRight = ({
  loading,
  error,
  type,
  iconRight,
  passwordIcon,
  togglePasswordVisible,
  handleChange,
}) => {
  if (error) {
    return <Icon icon="exclamation-circle" color="text-error-500" />;
  }

  if (loading) {
    return <Icon icon="spinner-third" spin color="text-primary-600" />;
  }

  if (type === "password") {
    return <Icon icon={passwordIcon} onClick={togglePasswordVisible} />;
  }

  if (iconRight) {
    return <Icon icon={iconRight} size="sm" />;
  }

  return <Icon icon="check" size="sm" className="opacity-0" />; // an invisible icon to keep the input height
};

export default InputIconRight;
