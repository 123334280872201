import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_GROUP_MEMBER_INVITES = gql`
  query groupMemberInvites($id: ID!) {
    group(id: $id) {
      id
      memberInvites {
        id
        email
        user {
          id
          firstName
          lastName
          avatar
        }
        accepted
        invitedBy {
          id
          firstName
          lastName
          avatar
        }
        createdAt
        updatedAt
      }
    }
  }
`;
const useGetGroupMemberInvites = ({id}) => {
  let invites = [];

  const {data, loading, error} = useQuery(GET_GROUP_MEMBER_INVITES, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    variables: {
      id,
    },
  });

  if (data) {
    invites = data.group.memberInvites;
  }

  return [invites, loading];
};

export default useGetGroupMemberInvites;
