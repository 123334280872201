import React from "react";
import {Comment, Reactions, Trans} from "Components";
import useGetCourseThreads from "API/queries/thread/useGetCourseThreads";
import moment from "moment";

const CourseLandingDiscussions = () => {
  const {navigationState} = React.useContext(window.CourseLandingContext);

  const {threads, loading} = useGetCourseThreads(3);

  if (loading) return null;

  if (threads.length === 0) return null;

  if (moment().diff(moment(threads[0].created_at), "months") > 4) return null;

  return (
    <div
      ref={navigationState.threadsRef}
      className="course-landing-discussions my-5"
    >
      <h2>
        <Trans>Latest discussions</Trans>
      </h2>
      {threads.map(thread => (
        <div className="my-1">
          <Comment
            createdAt={thread.created_at}
            content={thread.comments.html}
            author={thread.comments.asker}
            extra={
              <Reactions parentId={thread.comments.id} parentType="Comment" />
            }
            replies={thread.comments.comments.map(comment => ({
              content: comment.html,
              author: comment.asker,
              createdAt: comment.created_at,
              extra: <Reactions parentId={comment.id} parentType="Comment" />,
            }))}
            expandable={true}
            exerciseId={thread.exercise.id}
            showExerciseInfo={true}
          />
        </div>
      ))}
    </div>
  );
};

export default CourseLandingDiscussions;
