import React from "react";
import {Table, Trans} from "Components";

import useAccountSubscriptionInvoicesData from "routes/Account/AccountSubscription/AccountSubscriptionInvoices/useAccountSubscriptionInvoicesData";
import useGetCurrentUserSubscription from "API/queries/user/useGetCurrentUserSubscription";

const AccountSubscriptionInvoices = () => {
  const {
    loading,
    allSubscriptions: subscriptions,
  } = useGetCurrentUserSubscription(true, true, true);

  const {columns, onChange} = useAccountSubscriptionInvoicesData();

  if (loading || subscriptions.length === 0) {
    return null;
  }

  console.log("allSubscriptions", subscriptions);

  let invoices = subscriptions.reduce((acc, subscription) => {
    acc.push(
      ...subscription.invoices.map(invoice => ({...invoice, subscription}))
    );
    return acc;
  }, []);

  console.log("invoices", invoices);

  return (
    <Table
      columns={columns}
      data={invoices}
      fixed={true}
      card={{
        title: <Trans>Past invoices</Trans>,
      }}
    />
  );
};

export default AccountSubscriptionInvoices;
