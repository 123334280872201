import React from "react";
import classnames from "classnames";
import {Badge, Button, Trans, Typography} from "Components";
import TypographyGradient from "Components/_Elements/Typography/TypographyGradient";
import useGroupDiscounts from "Widgets/Group/GroupDiscounts/useGroupDiscounts";
import GroupDiscountFreeActivation from "Widgets/Group/GroupDiscounts/GroupDiscountFreeActivation";

const getTitleSize = size => {
  if (size === "sm") return "tmd";
  if (size === "md") return "tlg";
  else return "dxs";
};
const GroupDiscountsBanner = ({
  onClick = null,
  padding = "p-4",
  showDiscountPercentage = false,
  subTitle = null,
  showActivateButton = false,
  size = "md",
  className = null,
}) => {
  const {openModal} = React.useContext(window.GlobalModalContext);
  const {group, discount, loading, discountApplicable} = useGroupDiscounts();

  if (group === null || discount === null) return null;

  return (
    <div className="w-full @container">
      <div
        className={classnames(
          "rounded-xl",
          "transition-colors ease-in-out",
          onClick && !loading && "cursor-pointer",
          "bg-primary-800 border-b-0 rounded-lg",
          loading && "opacity-50",
          padding,
          className
        )}
      >
        <div
          className={classnames(
            "flex",
            size === "sm" && "gap-2",
            size === "md" && "gap-4",
            size === "lg" && "gap-6",
            "items-start"
          )}
        >
          <div className="bg-purple-50 rounded-full w-fit shrink-0 mt-1">
            <img
              className={classnames(
                size === "sm" && "w-10",
                size === "md" && "w-12",
                size === "lg" && "w-20",
                "aspect-square rounded-full",
                !discountApplicable && "opacity-50"
              )}
              src={group.imageUrl}
              alt="Profile"
            />
          </div>

          <div className={"flex flex-col justify-between w-full grow"}>
            <div className="flex flex-col md:flex-row md:gap-2 w-full md:items-center justify-between">
              <div className="flex gap-2 w-full flex-row  items-center ">
                <Typography
                  type={getTitleSize(size)}
                  weight="sb"
                  color="text-purple-50"
                  className={classnames(
                    "whitespace-nowrap",
                    !discountApplicable && "opacity-75"
                  )}
                >
                  {discount.name}
                </Typography>
                <GroupDiscountsBannerStatusBadge
                  discountApplicable={discountApplicable}
                />
              </div>
              {showDiscountPercentage && (
                <div className="shrink-0">
                  <TypographyGradient type={getTitleSize(size)} weight="b">
                    {discount.discountPercentage}% rabatt
                  </TypographyGradient>
                </div>
              )}
            </div>

            {subTitle === null && (
              <Typography
                color="text-purple-50"
                type="tsm"
                className={classnames(
                  "w-full grow",
                  !discountApplicable && "opacity-75"
                )}
              >
                {discount.description}{" "}
                {showActivateButton && !discountApplicable && (
                  <Button
                    type="link"
                    size="sm"
                    iconRight="arrow-right"
                    iconColor="text-amber-300"
                    onClick={() => openModal("GROUP_MEMBERSHIP_VERIFICATION")}
                  >
                    <Trans className="text-amber-300">Activate</Trans>
                  </Button>
                )}
              </Typography>
            )}
            {subTitle && <div className="mt-2">{subTitle}</div>}
          </div>
        </div>
        {discountApplicable && (
          <GroupDiscountFreeActivation group={group} discount={discount} />
        )}
      </div>
    </div>
  );
};
const GroupDiscountsBannerStatusBadge = ({discountApplicable}) => {
  if (discountApplicable)
    return (
      <Badge type="primary" size="xs" icon="check-circle">
        <Trans color="text-primary-600">Applicable</Trans>
      </Badge>
    );

  return (
    <div className="light">
      <Badge type="purple" size="xs" icon={"exclamation-triangle"}>
        <Trans color="text-purple-500">Not activated</Trans>
      </Badge>
    </div>
  );
};
export default GroupDiscountsBanner;
