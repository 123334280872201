import React from "react";
import {Input, Section, Trans} from "Components";
import FindCoursesSchoolPicker from "routes/FindCourses/FindCoursesSchoolPicker";
import useFindCourses from "routes/FindCourses/useFindCourses";
import CoursesList from "routes/Courses/CoursesList/CoursesList";

const FindCourses = () => {
  const {
    selectedSchoolId,
    setSelectedSchoolId,
    searchQuery,
    handleSearchQueryChange,
    setSearchQuery,
  } = useFindCourses();

  return (
    <div>
      <Section title={<Trans>Find Courses</Trans>}>
        <div className="grid grid-cols-12 gap-4 md:gap-8">
          <div className="col-span-4">
            <FindCoursesSchoolPicker
              selectedSchoolId={selectedSchoolId}
              setSelectedSchoolId={setSelectedSchoolId}
            />
          </div>
          <div className="col-span-8">
            <div className="mb-4">
              <Input
                size="lg"
                placeholder="Search course name, codes"
                icon="fa-regular fa-search"
                onChange={handleSearchQueryChange}
                value={searchQuery}
                clearable
              />
            </div>
            <CoursesList
              filtersState={{
                query: searchQuery,
                schoolId: selectedSchoolId,
                setQuery: setSearchQuery,
              }}
            />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default FindCourses;
