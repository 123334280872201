import React from "react";
import {groupBy, sortObjectKeys} from "utilities/objects";
import moment from "moment/moment";
import {Trans} from "Components";

const useCourseUserStatsHistoricalData = ({
  data: sourceData,
  groupByProperty,
  groupAggregateFunction = null,
  days = 7,
}) => {
  const groupedData = React.useMemo(
    () =>
      sortObjectKeys(
        groupBy(sourceData, groupByProperty, value =>
          moment
            .utc(value)
            .startOf("day")
            .format("YYYY-MM-DD")
        ),
        (a, b) => new Date(b) - new Date(a) // sort by date, descending
      ),
    [sourceData, groupByProperty]
  );

  // Creates an array of objects with the date as key and an array of values as value

  if (groupAggregateFunction) {
    Object.keys(groupedData).forEach(key => {
      groupedData[key] = groupAggregateFunction(groupedData[key]);
    });
  }

  const data = React.useMemo(
    () =>
      Object.keys(groupedData)
        .map(key => ({
          x: key,
          y: groupedData[key],
        }))
        .slice(0, days - 1), // Only show the last n days
    [groupedData, days]
  );

  // The data is sorted so the first 'x' is the most recent date

  // If the first element's x is not today, add empty data for today
  if (data.length > 0 && data[0].x !== moment().format("YYYY-MM-DD")) {
    data.unshift({
      x: moment().format("YYYY-MM-DD"),
      y: 0,
    });
  }

  // If there are too few elements, add empty data for the missing days
  if (data.length < days) {
    const missingDays = days - data.length;
    const lastDate =
      data.length > 0 ? moment(data[data.length - 1].x) : moment();

    for (let i = 0; i < missingDays; i++) {
      data.push({
        x: lastDate.subtract(i + 1, "days").format("YYYY-MM-DD"),
        y: 0,
      });
    }
  }

  const valueToday = data.length > 0 ? Math.round(data[0].y) : 0;

  const valuePreviousDay = data.length > 1 ? data[1].y : 0;

  const valueChangePercentage = React.useMemo(() => {
    // avoid division by zero
    if (valuePreviousDay === 0) {
      return 0;
    }

    return Math.round(
      ((valueToday - valuePreviousDay) / valuePreviousDay) * 100
    );
  }, [valueToday, valuePreviousDay]);

  const previousDayLabel = data[1]?.x ? moment(data[1].x).fromNow() : null;

  let chartColorScheme = getColorScheme(valueChangePercentage);

  const valueChangeLabel = getValueChangeLabel(
    valueChangePercentage,
    previousDayLabel
  );

  const allValuesEmpty = data.every(({y}) => y === 0);

  return {
    data: data.reverse(), // ascending order, oldest first
    valueToday,
    valuePreviousDay,
    valueChangePercentage,
    previousDayLabel,
    chartColorScheme,
    valueChangeLabel,
    allValuesEmpty,
  };
};

const getColorScheme = valueChangePercentage => {
  if (valueChangePercentage === null) {
    return {scheme: "paired"};
  }

  return {
    scheme: valueChangePercentage > 0 ? "set2" : "set1",
  };
};

const getValueChangeLabel = (valueChangePercentage, previousDayLabel) => {
  if (valueChangePercentage === null) {
    return null;
  }

  if (valueChangePercentage === 0) {
    return (
      <Trans previousDayLabel={previousDayLabel}>
        Same as {{previousDayLabel}}
      </Trans>
    );
  }

  return (
    <Trans previousDayLabel={previousDayLabel}>vs {{previousDayLabel}}</Trans>
  );
};

export default useCourseUserStatsHistoricalData;
