import React from "react";
import {Modal, Numbers, Tabs, Trans, Typography} from "Components";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";
import AccountCreditsRedeemCoupon from "routes/Account/AccountCredits/AccountCreditsRedeemCoupon/AccountCreditsRedeemCoupon";
import AccountCreditsTransactionsTable from "routes/Account/AccountCredits/AccountCreditsTransactionsTable";
import EarnCredits from "Widgets/Credits/EarnCredits/EarnCredits";
import AccountCreditsStudentVerification from "routes/Account/AccountCredits/AccountCreditsStudentVerification/AccountCreditsStudentVerification";
import BuyCredits from "Widgets/Credits/BuyCredits/BuyCredits";

const CreditsModal = ({onClose}) => {
  const smallScreens = useBreakPointSM();
  const [user, loading] = useGetCurrentUser();
  const userCredits = user?.credits / 100 || 0;

  return (
    <Modal
      defaultOpen
      escapeClosable
      title={<Trans userCredits={userCredits}>Credits: {{userCredits}}</Trans>}
      subTitle={
        <Trans>
          Here you can see your available credits and redeem coupons.
        </Trans>
      }
      footer={null}
      onClose={onClose}
      closable={true}
      className="min-h-[75vh] min-w-[75vw] overflow-y-auto"
    >
      <Tabs
        // orientation={smallScreens("horizontal", "vertical")}
        persistCurrentTabInUrl={true}
        orientation="vertical"
        fluid={smallScreens(true, false)} // force fluid on small screens otherwise the tabs are not scrollable
        type="button"
        size="lg"
        contentClassName="md:ml-4 lg:ml-8"
        urlParamName="cmt"
      >
        <Tabs.Pane
          key="buy"
          title={<Trans>Buy credits</Trans>}
          icon="cart-shopping"
        >
          <BuyCredits />
        </Tabs.Pane>
        <Tabs.Pane key="earn" title={<Trans>Earn credits</Trans>} icon="gift">
          <EarnCredits />
        </Tabs.Pane>
        <Tabs.Pane
          key="coupon"
          title={<Trans>Redeem coupon</Trans>}
          icon="ticket"
        >
          <AccountCreditsStudentVerification />
          <AccountCreditsRedeemCoupon disabled={!user.student_email} />
        </Tabs.Pane>

        <Tabs.Pane
          title={<Trans>History</Trans>}
          icon="credit-card"
          key="transactions"
        >
          <AccountCreditsTransactionsTable />
        </Tabs.Pane>
      </Tabs>
    </Modal>
  );
};

export default CreditsModal;
