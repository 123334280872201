import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CommentContent from "Components/Comment/CommentContent";
import CommentReplies from "Components/Comment/CommentReplies";
import CommentReplyForm from "Components/Comment/CommentReplyForm/CommentReplyForm";
import CommentAuthor from "Components/Comment/CommentAuthor";
import CommentFooter from "Components/Comment/CommentFooter";
import CommentExerciseInfo from "Components/Comment/CommentExerciseInfo";

export const CommentContext = React.createContext();

const Comment = ({
  author,
  createdAt,
  content,
  replies = [],
  extra,
  className,
  repliesClassNames,
  expandable = true,
  currentUser = null,
  onNewCommentSubmit,
  showExerciseInfo = false,
  exerciseId = null,
  onExpandClick = null,
  rounded = true,
  size = "md",
  showCourseTag = false,
  exerciseData = null,
  ...props
}) => {
  const key = props.key || "comment-" + createdAt;
  const [expanded, setExpanded] = React.useState(props.expanded || false);

  return (
    <CommentContext.Provider
      value={{
        author,
        content,
        replies,
        extra,
        expandable,
        currentUser,
        onNewCommentSubmit,
      }}
    >
      <div
        // layoutId={key}

        className={classnames(
          "p-4 border-2 rounded-xl w-full",
          expanded ? "border-primary-400" : "border-color",
          className
        )}
        animate={{
          height: expanded ? "fit-content" : "fit-content",
          borderColor: expanded ? "#8BA2E8" : "#EAECF0",
        }}
      >
        <CommentAuthor
          author={author}
          createdAt={createdAt}
          size={size}
          showCourseTag={showCourseTag}
          exerciseData={exerciseData}
        />

        {/*TODO: Implement actions on comment, e.g. report, flag, delete, edit etc. */}
        {/*<div className="col-span-1">*/}
        {/*  <Button type="link" icon="ellipsis" />*/}
        {/*</div>*/}

        <div className="mt-4" layoutId={key + "-content"}>
          <CommentContent content={content} />
        </div>

        <CommentExerciseInfo
          showExerciseInfo={showExerciseInfo}
          exerciseId={exerciseId}
        />

        <CommentFooter
          expanded={expanded}
          setExpanded={setExpanded}
          expandable={expandable}
          replies={replies}
          extra={extra}
          onExpandClick={onExpandClick}
        />

        <CommentReplies
          replies={replies}
          expanded={expanded}
          className={className}
          w
          repliesClassNames={repliesClassNames}
        />

        {expanded && (
          <CommentReplyForm
            currentUser={currentUser}
            onNewCommentSubmit={onNewCommentSubmit}
            expanded={expanded}
          />
        )}
      </div>
    </CommentContext.Provider>
  );
};

Comment.propTypes = {
  author: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    // Optionally show program of author if available
    program: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    // enrollment year of author if available
    enrollmentYear: PropTypes.number,

    premium: PropTypes.bool,
  }),
  createdAt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  extra: PropTypes.node,
  replies: PropTypes.arrayOf(PropTypes.shape(Comment.propTypes)),
  className: PropTypes.string,
  expandable: PropTypes.bool,
  currentUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  }),
  /**
   * Callback function to be called when a new comment is submitted
   * @param {Object} state - The content of the new comment
   * @param {string} state.text - The text of the new comment
   * @param {string} state.anonymous - Whether the new comment is anonymous
   * @param {string} state.username - The username of the new comment
   * @param {function} callback - Callback function to be called when the new comment is successfully submitted
   */
  onNewCommentSubmit: PropTypes.func,
};

export default Comment;
