import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_EXERCISE = gql`
  query getExercise($id: ID!) {
    getExercise(id: $id) {
      id
      imageUrl
      segments {
        id
        revision
        imageUrl
        createdAt
      }
    }
  }
`;

const useGetExerciseContent = id => {
  let content = null;

  const {data, loading, error} = useQuery(GET_EXERCISE, {
    variables: {id},
    fetchPolicy: "cache-first",
  });

  if (error) {
    console.log("Error in useGetExerciseContent, ", error);
  }

  if (data) {
    content = data.getExercise;
  }

  return [content, loading];
};

export default useGetExerciseContent;
