import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import ExamUploadDocumentsRowFilesItem from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsRow/ExamUploadDocumentsRowFiles/ExamUploadDocumentsRowFilesItem/ExamUploadDocumentsRowFilesItem";
import ExamUploadDocumentsRowFilesTypes from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsRow/ExamUploadDocumentsRowFiles/ExamUploadDocumentsRowFilesTypes";
import {ExamUploadContext} from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContext";
import {Trans} from "Components";
import useExamUploadDocumentsRowFiles from "./useExamUploadDocumentsRowFiles";

const ExamUploadDocumentsRowFiles = ({document, documentFocused}) => {
  const {
    previewResource,
    updatePreviewResource,
    updateResource,
  } = React.useContext(ExamUploadContext);

  const {getPossibleTypes, handleClick} = useExamUploadDocumentsRowFiles({
    updatePreviewResource,
    updateResource,
  });
  const {files} = document;

  // Sort alphabetically: Exam, mixed, solution, unknown
  const sortedFiles = files.sort((a, b) => {
    return b.name ? b.name.localeCompare(a.name) : 0;
  });

  return sortedFiles.map((file, index) => {
    const selected = previewResource?.id === file.id;
    const typeOptions = getPossibleTypes(file, document);

    return (
      // <AnimateSharedLayout>
      <div className="mb-2" key={file.id}>
        <ExamUploadDocumentsRowFilesItem file={file} />

        <AnimatePresence presenceAffectsLayout>
          {!file.saved && documentFocused && (
            <motion.div
              className="flex gap-2 items-center justify-end mt-2 mb-4"
              initial={{opacity: 0, y: -10}}
              animate={{opacity: selected ? 1 : 0, y: 0}}
              exit={{opacity: 0, y: -10}}
            >
              <motion.span layout="position">
                <Trans type="tsm" color="text-gray-light" weight="sb">
                  Change type:
                </Trans>
              </motion.span>
              {// Allow for picking a type
              typeOptions.map(t => {
                return (
                  <ExamUploadDocumentsRowFilesTypes
                    type={t}
                    fileId={file.id}
                    onClick={() => {
                      handleClick(sortedFiles, file, t);
                    }}
                    key={t}
                    selectable={true}
                  />
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      // </AnimateSharedLayout>
    );
  });
};

export default ExamUploadDocumentsRowFiles;
