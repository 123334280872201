import React from "react";
import {range} from "utilities/numbers";

const usePagination = ({items, pageSize, defaultPageIndex = 0}) => {
  const [currentPage, setCurrentPage] = React.useState(defaultPageIndex);

  const pages = React.useMemo(() => {
    // Max page index: e.g. 4
    const pageCount = Math.ceil(items.length / pageSize);

    return range(pageCount); // All page indexes: e.g. [0, 1, 2, 3, 4]
  }, [items, pageSize]);

  /**
   * An array of items for the current page
   */
  const currentPageItems = React.useMemo(() => {
    const start = currentPage * pageSize;
    const end = start + pageSize;
    return items.slice(start, end);
  }, [currentPage, items, pageSize]);

  /**
   * Callback for when a page index is clicked
   */

  const onPageIndexClick = React.useCallback(pageIndex => {
    setCurrentPage(pageIndex);
  }, []);

  /**
   * Callback for when the previous page button is clicked
   */

  const onPreviousPageClick = React.useCallback(() => {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 0));
  }, []);

  /**
   *
   * Callback for when the next page button is clicked
   */

  const onNextPageClick = React.useCallback(() => {
    setCurrentPage(currentPage => Math.min(currentPage + 1, pages.length - 1));
  }, [pages.length]);

  const previousPageButtonDisabled = currentPage === 0;
  const nextPageButtonDisabled = currentPage === pages.length - 1;

  /**
   * If there is only one page, there is no need to show pagination
   * @type {boolean}
   */
  const paginationUnnecessary = pages.length <= 1;

  return {
    pages,
    currentPage,
    currentPageItems,
    onPageIndexClick,
    onPreviousPageClick,
    onNextPageClick,
    previousPageButtonDisabled,
    nextPageButtonDisabled,
    paginationUnnecessary,
  };
};

export default usePagination;
