import React from "react";
import { checkAllWhite } from "Components/ImageWizard/Tools/Vacuum";

/**
 * Detects the margins of a canvas inside the container
 * Note that the right margin is not the position from the right side of the document but from the left side
 * since the x-coordinate is 0 at the left side of the document
 *
 * @param container - The container element that contains the canvas
 * @param dependencies - The dependencies that should trigger a recalculation of the margins
 * @returns {Object} - The margins of the canvas
 * @returns {Number} - The margins of the canvas.left
 * @returns {Number} - The margins of the canvas.right
 */

const STEP = 2;

const useDocumentMarginDetector = ({
  container,
  dependencies,
  initialWidth,
}) => {
  const [margins, setMargins] = React.useState(null);

  const triggerMarginDetection = canvas => {
    const ctx = canvas.getContext("2d");
    const { width, height } = canvas;

    const leftMargin = detectMargins({
      ctx,
      start: 0,
      step: STEP,
    });

    const rightMargin = detectMargins({
      ctx,
      start: width,
      step: -STEP,
    });

    setMargins({
      left: leftMargin,
      right: rightMargin,
    });
  };

  React.useEffect(() => {
    if (container.current && margins === null && initialWidth > 0) {
      // Try to get the canvas, it may not be there yet
      const canvas = container.current?.querySelector(
        "[class='react-pdf__Page__canvas']"
      );

      if (canvas) {
        triggerMarginDetection(canvas);
      }
    }
  }, [initialWidth, container, margins, dependencies]);

  return margins;
};
/**
 * Detects the margins of the document by scanning 10xheight pixels from the left and right side of the document
 * @param ctx
 */

const detectMargins = ({ ctx, start, step }) => {
  if (!ctx) return start;

  const { height } = ctx.canvas;

  // Check ctx color

  const imageData = ctx.getImageData(start, 0, step, height, {
    colorSpace: "srgb",
  });

  const allWhite = checkAllWhite(imageData.data, 255 * 3);

  if (allWhite) {
    return detectMargins({
      ctx,
      start: start + step,
      step,
    });
  }

  return start;
};

export default useDocumentMarginDetector;
