import React from "react";
import {Trans, Button} from "Components";
import "./CheckoutProductsNotFound.scss";
import {useHistory} from "react-router";

const CheckoutProductsNotFound = () => {
  const history = useHistory();
  return (
    <div className="checkout-products-not-found">
      <img src="https://d1mgntrf3vaj6d.cloudfront.net/images/products_not_found.svg"></img>
      <h1>
        <Trans>Entered products not found</Trans>
      </h1>

      <Button onClick={history.goBack} type="primary">
        <Trans>Go back</Trans>
      </Button>
    </div>
  );
};

export default CheckoutProductsNotFound;
