import React from "react";
import {Badge, Typography} from "Components/_Elements";
import Trans from "Components/Trans";
import useKeyboardShortcuts from "utilities/hooks/useKeyboardShortcuts";
import CourseSidebarMenuItem from "Components/Sidebar/CourseSidebar/CourseSidebarMenuItem";
import {useTranslation} from "react-i18next";
import GPTDisplay from "../../GPT/GPTDisplay/GPTDisplay";
import GPTDisplayDashboard from "Components/GPT/GPTDisplay/GPTDisplayDashboard";
import GPTDisplayWorkboard from "Components/GPT/GPTDisplay/GPTDisplayWorkboard";

const CourseSidebarChatGptButton = ({collapsed}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const {user} = React.useContext(window.UserContext);

  const handleClick = () => {
    setModalOpen(true);
  };

  useKeyboardShortcuts([
    {
      key: "k",
      ctrl: true,
      action: handleClick,
    },
  ]);

  if (!user) {
    return null;
  }

  return (
    <div>
      <CourseSidebarMenuItem
        icon="fa-solid fa-message-bot"
        // iconColor="text-purple-600"
        collapsed={collapsed}
        label={
          <Typography>
            Kollin-GPT
            <span className="ml-2 text-gray-light">⌘K</span>
          </Typography>
        }
        onClick={handleClick}
      />
      {modalOpen && (
        <GPTDisplayWorkboard
          inverted={false}
          modal
          onModalClose={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};

export default CourseSidebarChatGptButton;
