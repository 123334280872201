import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { GET_REACTIONS } from "API/queries/reaction/useGetReactions";

const ADD_REACTION = gql`
  mutation addReaction($parentId: ID!, $parentType: String!, $name: String!) {
    addReaction(parentId: $parentId, parentType: $parentType, name: $name) {
      ok
      error
    }
  }
`;

const useAddReaction = args => {
  const result = {};

  const [addReaction, { data, loading, error }] = useMutation(ADD_REACTION, {
    refetchQueries: [{ query: GET_REACTIONS, variables: { ...args } }],
  });

  if (error) {
    console.log("Error in useAddReaction", error);
  }

  return [addReaction, loading];
};

export default useAddReaction;
