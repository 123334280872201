import React from "react";
import classnames from "classnames";
import {Icon, ProgressSteps, Typography} from "Components";
import {OnboardingContext} from "routes/Onboarding/Onboarding";
import OnboardingLayoutHeader from "routes/Onboarding/OnboardingLayout/OnboardingLayoutHeader";
import ONBOARDING_STEPS from "routes/Onboarding/OnboardingStepsList";
import OnboardingLayoutStep from "routes/Onboarding/OnboardingLayout/OnboardingLayoutStep";
import OnboardingLayoutActions from "routes/Onboarding/OnboardingLayout/OnboardingLayoutActions";

const OnboardingLayout = ({
  customHeader = null,
  stacked = false,
  showFooter = true,
  progressStepsOrientation = "vertical",
  progressStepsSize = "lg",
  hideStep = null,
  compact = false,
}) => {
  const {currentStepIndex, onStepClick, user} = React.useContext(
    OnboardingContext
  );

  const steps = React.useMemo(
    () => getSteps(ONBOARDING_STEPS, currentStepIndex, user, hideStep),
    [currentStepIndex]
  );

  return (
    <div
      className={classnames(
        "grid  w-full",
        stacked ? "grid-cols-1" : "grid-cols-1 md:grid-cols-3"
      )}
    >
      <div
        className={classnames(
          "w-full py-4",
          "md:sticky md:top-0",
          "bg-gray-200",
          "cols-span-1",
          "flex flex-col items-center",
          stacked ? "p-4 md:p-6" : " md:h-screen  md:py-16"
        )}
      >
        <div
          className={classnames(
            " flex flex-col h-full justify-between items-start",
            stacked ? "w-full" : "w-4/5"
          )}
        >
          <>
            {customHeader ? customHeader : <OnboardingLayoutHeader />}
            <div
              className={classnames(
                "mt-2 w-full grow",
                customHeader === null && "md:mt-8"
              )}
            >
              <ProgressSteps
                vertical={progressStepsOrientation === "vertical"}
                numbered
                size={progressStepsSize}
                steps={steps}
                onStepClick={onStepClick}
              />
            </div>
          </>

          {showFooter && (
            <div className="hidden md:block">
              <div className="flex gap-2 items-center">
                <Icon icon="copyright" />
                <Typography color="text-gray-light">
                  Crash Course Sverige AB
                </Typography>
              </div>

              <div className="flex gap-2 items-center">
                <Icon icon="envelope" />
                <Typography color="text-gray-light">
                  support@kollin.io
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className={classnames(
          stacked ? "col-span-1" : "col-span-1 md:col-span-2",
          compact ? "py-2 md:py-4" : "py-2 md:py-16"
        )}
      >
        <OnboardingLayoutStep compact={compact} />
        <OnboardingLayoutActions stacked={stacked} />
      </div>
    </div>
  );
};

const getSteps = (steps, currentStepIndex, user, hideStep) => {
  const getStatus = index => {
    if (index < currentStepIndex) {
      return "complete";
    }
    if (index === currentStepIndex) {
      return "current";
    }
    return "incomplete";
  };

  const getDescription = key => {
    if (key === "name") {
      if (user?.firstName && user?.lastName) {
        return user?.firstName + " " + user?.lastName;
      }

      return null;
    }

    if (key === "school") {
      return user?.school?.name;
    }

    if (key === "program") {
      return user?.program?.name;
    }

    return undefined;
  };

  steps = steps.filter((step, index) => {
    if (hideStep === null) return true;
    return index !== hideStep;
  });

  return steps.map((step, index) => {
    return {
      title: step.name,
      description: getDescription(step.key),
      status: getStatus(index),
    };
  });
};

export default OnboardingLayout;
