import React from "react";
import moment from "moment";
import {range} from "utilities/numbers";

const CALENDAR_SIZE = 42;

const useDatePickerCalendarState = currentDate => {
  const [currentPage, setCurrentPage] = React.useState(moment(currentDate));

  React.useEffect(() => {
    setCurrentPage(moment(currentDate));
  }, [currentDate]);

  const onPrevMonth = () => {
    setCurrentPage(currentPage.clone().subtract(1, "month"));
  };

  const onNextMonth = () => {
    setCurrentPage(currentPage.clone().add(1, "month"));
  };

  // Get all days in the current month
  const daysToRender = getDaysToRender(currentPage);

  return {
    currentPage,
    onPrevMonth,
    onNextMonth,
    daysToRender,
  };
};

const getDaysToRender = currentPage => {
  const previousMonthDayCount = currentPage
    .clone()
    .subtract(1, "month")
    .daysInMonth();

  const currentMonthDaysCount = currentPage.daysInMonth();

  const nextMonthDayCount = currentPage
    .clone()
    .add(1, "month")
    .daysInMonth();

  const daysInMonth = getListOfDays(currentMonthDaysCount);

  // We show CALENDAR_SIZE days in the calendar, so we need to add some days from the previous month and the next month

  const nDaysBefore = (CALENDAR_SIZE - currentMonthDaysCount) / 2;
  const nDaysAfter = (CALENDAR_SIZE - currentMonthDaysCount) / 2;

  const daysFromPrevMonth = getListOfDays(previousMonthDayCount).slice(
    -nDaysBefore
  );

  const daysFromNextMonth = getListOfDays(nextMonthDayCount).slice(
    0,
    nDaysAfter
  );

  return [
    ...daysFromPrevMonth.map(d => ({
      label: d,
      date: moment(currentPage)
        .subtract(1, "month")
        .set("date", d),
    })),
    ...daysInMonth.map(d => ({
      label: d,

      date: moment(currentPage).set("date", d),
      isToday: currentPage.isSame(moment()) && d == moment().format("D"),
    })),
    ...daysFromNextMonth.map(d => ({
      label: d,
      date: moment(currentPage)
        .add(1, "month")
        .set("date", d),
    })),
  ];
};

const getListOfDays = nDays =>
  range(nDays).map(i => {
    return i + 1;
  });

export default useDatePickerCalendarState;
