import React from "react";
import { ProgramPicker, Typography } from "Components";

import { motion } from "framer-motion";
import { Summer2022CampaignOnBoardingEnrollmentInfoContainer } from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignOnBoarding/Summer2022CampaignOnBoardingEnrollment/Summer2022CampaignOnBoardingEnrollment";

const Summer2022CampaignOnBoardingEnrollmentProgram = ({
  state,
  setState,
  program,
  school,
  programLogo,
  allowedPrograms,
}) => {
  const { programId, schoolId } = state;

  if (
    programId == null ||
    schoolId == null ||
    program?.id !== programId ||
    school?.id !== schoolId
  ) {
    return (
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
      >
        <ProgramPicker
          allowedPrograms={allowedPrograms}
          disabled={schoolId == null}
          schoolId={schoolId}
          onSelect={programId => setState({ programId })}
          selectedProgramId={programId}
          placeholder={schoolId == null ? "Välj skola först" : "Välj program"}
        />
      </motion.div>
    );
  }

  return (
    <Summer2022CampaignOnBoardingEnrollmentInfoContainer
      label="Program"
      onClick={() => {
        setState({
          programId: null,
        });
      }}
    >
      <img src={programLogo?.url} className="w-8" />
      <Typography type="tsm" weight="sb" color="text-primary-800">
        {program.name}
      </Typography>
    </Summer2022CampaignOnBoardingEnrollmentInfoContainer>
  );
};

export default Summer2022CampaignOnBoardingEnrollmentProgram;
