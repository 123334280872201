import React from "react";
import useGetCurrentUserSubscription from "API/queries/user/useGetCurrentUserSubscription";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";

const useSubscriptionTrialBanner = () => {
  const [user, userLoading] = useGetCurrentUser();
  const loggedIn = user?.id;
  const studentEmailVerified = loggedIn && user.student_email !== null;

  // Check if user is eligible for trial
  const {
    subscription,
    loading: subscriptionLoading,
  } = useGetCurrentUserSubscription();

  let hideBanner = subscriptionLoading || subscription !== null;

  return {
    hideBanner,
    loggedIn,
    studentEmailVerified,
    userLoading,
  };
};

export default useSubscriptionTrialBanner;
