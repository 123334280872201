// Pre-defined animation variants for motion components

/**
 * This is to reduce the amount of code needed to create a motion component
 * and code-duplication when using the same variants in multiple places
 */

const variants = {
  // Pop in animation
  popIn: {
    initial: {
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      opacity: 1,
      scale: 1,
    },
  },
};

export default variants;
