import React from "react";
import {Button, Icon, Math, Tooltip, Trans, Typography} from "Components";
import relativeTime from "utilities/relativeTime";
import {AnimatePresence, motion} from "framer-motion";
import useDeleteExerciseNote from "API/mutations/exerciseNote/useDeleteExerciseNote";
import classnames from "classnames";

const ExerciseNotesListItem = ({
  id,
  text,
  updatedAt,
  exerciseId,
  transparent = false,
  index,
}) => {
  const [removed, setRemoved] = React.useState(false);

  const [deleteExerciseNote, deleteExerciseNoteLoading] = useDeleteExerciseNote(
    exerciseId
  );

  const isFromGPT =
    text.includes("# Svar från Chat-GPT") ||
    text.includes("# Svar från Kollin-GPT");

  return (
    <AnimatePresence
      presenceAffectsLayout
      onExitComplete={() => {
        deleteExerciseNote({
          variables: {
            id,
          },
        });
      }}
    >
      {!removed && (
        <motion.div
          initial={{
            opacity: 0,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          exit={{
            scale: 0,
            opacity: 0,
          }}
          className={classnames(
            "w-full py-2 relative",
            transparent
              ? "border-b-1 border-gray-200 last:border-b-0"
              : "border-2 border-warning-100 surface-warning rounded-xl"
          )}
          key={"note-" + id}
          layoutId={"note-" + id}
          index={index}
        >
          <div>
            <div className="">
              {isFromGPT && (
                <span className="mr-1">
                  <Icon
                    icon="fa-duotone fa-message-bot"
                    size="md"
                    fillType="purple"
                    outlined={false}
                  />
                </span>
              )}
              <Math className="inline">
                <FormattedNoteText text={text} />
              </Math>
            </div>

            <div className="flex items-center justify-between mt-2">
              <Typography type="tsm" color="text-gray-light">
                {relativeTime(updatedAt)}
              </Typography>
              <Tooltip content={<Trans>Delete</Trans>}>
                <Button
                  loading={deleteExerciseNoteLoading}
                  type="link"
                  size="sm"
                  icon="trash"
                  onClick={() => setRemoved(true)}
                />
              </Tooltip>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
/**
 * Turns markdown into formatted text
 * @param text
 */

export const FormattedNoteText = ({text}) => {
  // First level: #\s, second level ##\s ...
  const styling = [{type: "txl", weight: "md"}, {weight: "sb"}];
  return text.split("\n").map((line, index) => {
    const [markers, ...rest] = line.split(" ");

    if (markers.includes("#")) {
      const level = markers.length - 1;
      return (
        <Typography
          key={index}
          {...styling[level]}
          inline={index === 0}
          className="mt-2"
        >
          {rest.join(" ")}
          <br />
        </Typography>
      );
    }

    return <Typography key={index}>{line}</Typography>;
  });
};

export default ExerciseNotesListItem;
