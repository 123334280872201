import React from "react";
import {Button, Link, Logo, Trans, Typography} from "Components";

import classnames from "classnames";
import {useHistory, useLocation} from "react-router-dom";
import {Authorization} from "../../Wrappers";
import useGetLandingPageSectionContent from "API/cms/landingPage/useGetLandingPageSectionContent";
import PageLoader from "Components/PageLoader";
import SocialLogin from "routes/Auth/SocialLogin/SocialLogin";
import SubscriptionTrialTimeline from "../Trial/SubscriptionTrial/SubscriptionTrialTimeline";

const AuthLayout = ({
  children,
  background = null,
  type = "white",
  isTrial = false,
  InfoComponent = null,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [content, loading] = useGetLandingPageSectionContent(
    "09LjGmQbsyxpvaz5cDFG3"
  );

  if (loading) return <PageLoader />;

  let imageDesc = content.illustrationCollection?.items[0]?.description;
  let imageSrc = content.illustrationCollection?.items[0]?.url;

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 w-full">
      <div
        className={classnames(
          "w-full md:h-screen",
          "cols-span-1 md:col-span-2 lg:col-span-2",
          "flex flex-col justify-center items-center",
          background
        )}
        style={{
          background: background
            ? ""
            : "linear-gradient(180deg, #9f5e72 0%, #425388 100%)", // fallback some times tailwind doesn't work for gradient
        }}
      >
        <div className="w-4/5 flex flex-col justify-center items-start py-4 md:py-0">
          <Link
            className="mb-4 md:mb-12"
            inverted
            onClick={history.goBack}
            iconLeft="chevron-left"
          >
            <Trans>Back</Trans>
          </Link>
          <div className={classnames(!isTrial && "hidden md:block")}>
            {InfoComponent !== null ? (
              InfoComponent
            ) : (
              <div>
                <Typography
                  type="dmd lg:dxl"
                  color="text-white"
                  weight="md"
                  className="mt-8"
                >
                  {content.h1 || "Börja plugga - utan att tänka"}
                </Typography>
                <Typography
                  type="tlg"
                  weight="md"
                  color="text-gray-200"
                  className="mt-6"
                >
                  {content.h2 ||
                    "Skapa ett gratiskonto och börja ta fart på plugget och må mycket bättre"}
                </Typography>

                <div className="flex gap-4 items-center mt-8">
                  <img src={imageSrc} alt="students" />
                  <Typography type="tmd" weight="md" color="text-gray-200">
                    {imageDesc}
                  </Typography>
                  {/*TODO: Check if we can use the AuthLayoutTestimonial component with the stlideshow without having arbitrary truncated text */}
                  {/*<AuthLayoutTestimonials />*/}
                </div>
              </div>
            )}
          </div>
          <div
            className="mt-20 cursor-pointer hidden sm:block"
            onClick={() => history.push("/")}
          >
            <Logo
              type={type}
              showText={true}
              size="lg"
              className="mix-blend-soft-light"
            />
          </div>
        </div>
      </div>

      <div className="col-span-1 md:col-span-2 lg:col-span-3">
        <Authorization.Debug>
          <Button
            onClick={() => {
              history.push(location.state?.origin || location.state?.nextUrl);
            }}
          >
            next
          </Button>
        </Authorization.Debug>
        <div className="p-4 md:p-0 md:w-4/5 lg:w-3/5 h-full flex flex-col justify-center mx-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export const AuthLayoutTitleProps = {
  type: "dsm",
  weight: "md",
  color: "text-gray",
};

const Footer = ({children, onComplete, socialLoginProps}) => (
  <div className="my-1">
    <SocialLogin {...socialLoginProps} />
    <div className="my-3">{children}</div>
  </div>
);

AuthLayout.Footer = Footer;

export default AuthLayout;
