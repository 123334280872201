import React from "react";
import {Avatar, Trans, Typography} from "Components";
import useCourseContributions from "Widgets/Course/CourseContributions/useCourseContributions";
import CourseContributionsDescription from "Widgets/Course/CourseContributions/CourseContributionsDescription";
import CourseContributionsCredits from "Widgets/Course/CourseContributions/CourseContributionsCredits";
import Widget from "../../../Components/Widget/Widget";
import SkeletonListWithCircle from "Components/_Elements/Skeleton/SkeletonListWithCircle";

const CourseContributions = () => {
  const {contributions, loading} = useCourseContributions();

  if (contributions.length === 0) {
    return null;
  }

  return (
    <Widget
      widgetName="CourseContributions"
      title={
        <Trans type="tlg" weight="md">
          Latest contributions
        </Trans>
      }
    >
      {loading ? (
        <SkeletonListWithCircle count={10} />
      ) : (
        contributions.map((contribution, index) => {
          // Contributions are grouped by userId
          const {user, date, ...objectKeys} = contribution;

          return (
            <div
              key={index}
              className="py-3 flex gap-3 items-center border-b-1 border-gray last:border-b-0"
            >
              <Avatar user={user} size="sm" />

              <div>
                <div className="flex gap-2 items-center">
                  <Typography weight="md" type="tsm" color="text-gray-700">
                    {user.firstName}
                  </Typography>
                  <Typography type="txs" color="text-gray-500">
                    {date}
                  </Typography>
                </div>

                <div className="flex gap-2 items-center">
                  <CourseContributionsDescription objectKeys={objectKeys} />
                  <CourseContributionsCredits objectKeys={objectKeys} />
                </div>
              </div>
            </div>
          );
        })
      )}
    </Widget>
  );
};

export default CourseContributions;
