import React from "react";
import {useTranslation} from "react-i18next";
import useSendSupportEmail from "API/mutations/useSendSupportEmail";

import {useFormValidation} from "utilities/hooks/useFormValidation";
import {validateContactForm} from "utilities/validators";
import {Button, Icon, Input, TextArea} from "Components";
import AnimatedCheckbox from "./Animated/AnimatedCheckbox/AnimatedCheckbox";
import {UserContext} from "UserContextProvider";
import Trans from "./Trans";

const SupportMailForm = ({onClose, showHeader = true}) => {
  const userContext = React.useContext(UserContext);
  const {t: trans} = useTranslation();

  const [sent, setSent] = React.useState(false);

  const {sendSupportMail, loading} = useSendSupportEmail(data => {
    if (data.sendSupportMail.ok) {
      setSent(true);
    }
  });

  const mutation = ({variables}) => {
    let message = [
      variables.message,
      "---",
      "",
      "Error occurred at: " + window.location.href,
      navigator.platform,
      navigator.userAgent,
    ];

    if (userContext && userContext.user) {
      message.push("User ID: " + userContext.user.id);
    }

    message = message.join("\n");

    sendSupportMail({variables: {...variables, message}});
  };

  const {values, errors, handleChange, submit} = useFormValidation(
    {
      email: userContext && userContext.user ? userContext.user.email : "",
      message: "",
    },
    mutation,
    validateContactForm
  );

  if (!sent) {
    return (
      <div className="w-full p-4">
        {showHeader && (
          <div>
            <Icon
              icon="fa-regular fa-envelope"
              outlined
              filled
              fillType="primary"
              size="sm"
            />
            <Trans type="dsm" weight="sb" className="mt-2">
              Contact us for support
            </Trans>

            <div className="mt-4" />
          </div>
        )}
        <form onSubmit={submit} className="flex flex-col gap-4">
          <Input
            label={trans("Enter your email so we can contact you")}
            placeholder={"Email"}
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            prefix={<i className="fas fa-user" />}
          />

          <TextArea
            placeholder={trans("Enter your message ")}
            rows={3}
            name="message"
            text={values.message}
            error={errors.message || null}
            onChange={handleChange}
            fluid
          />

          <Button type="primary" onClick={submit} fluid>
            <Trans>Send</Trans>
          </Button>
        </form>
      </div>
    );
  }

  return (
    <div className="container p-5 centered">
      <AnimatedCheckbox />
      <h3 className="green">Sent!</h3>
      <p>
        <Trans>
          Thank you for your message, We'll get back to you as soon as possible
        </Trans>
      </p>
      <a href="/" className="green">
        <u>
          <Trans>Go to homepage</Trans>
        </u>
      </a>
    </div>
  );
};

export default SupportMailForm;
