import React from "react";
import useUpdateExamMutation from "API/mutations/exam/useUpdateExamMutation";
import { GET_COURSE_EXAMS } from "API/queries/exam/useGetCourseExams";

const UseExamUploadSaveActionUpdate = onResourceSaved => {
  const { courseId } = React.useContext(window.CourseContext);

  const refetchQueries = [
    {
      query: GET_COURSE_EXAMS,
      variables: { courseId, includeExerciseSolutions: false },
    },
  ];

  const {
    updateExam,
    loading: updatingExam,
    data: updatedExam,
  } = useUpdateExamMutation(refetchQueries);

  const handleUpdate = async ({
    exam,
    exercisesResource,
    solutionsResource,
    author = null,
  }) => {
    console.log("Updating exam with date: ", exam.date);

    const response = await updateExam({
      variables: {
        id: exam.id,
        date: exam.date,
        author: exam.author || author,
        exam_exercises_src: exercisesResource?.url,
        exam_solutions_src: solutionsResource?.url,
      },
    });

    const { data } = response;

    if (data?.updateSource?.ok) {
      if (exercisesResource) onResourceSaved(exercisesResource);

      if (exercisesResource !== solutionsResource && solutionsResource)
        onResourceSaved(solutionsResource);
    }
  };

  return handleUpdate;
};

export default UseExamUploadSaveActionUpdate;
