import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_SCHOOLS = gql`
  query allSchools {
    allSchools {
      id
      name
      nickname
      coursesCount
    }
  }
`;

const useGetAllSchools = () => {
  let schools = [];

  const { data, loading, error } = useQuery(GET_SCHOOLS);

  if (error) {
    console.log(`Error in useGetAllSchools: ${error}`);
  }

  if (data) {
    schools = data.allSchools.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  return [schools, loading];
};

export default useGetAllSchools;
