import React from "react";
import PropTypes from "prop-types";
import {Trans as Translate} from "react-i18next";
import {Typography} from "Components";

const Trans = ({
  weight,
  type = "tmd",
  className,
  inline,
  color,
  children,
  ...props
}) => {
  const text = children;
  if (text === undefined) return null;

  if (type) {
    return (
      <Typography
        weight={weight}
        type={type}
        className={className}
        inline={inline}
        color={color}
        block={true}
        Tag="span"
        {...props}
      >
        <Translate {...props}>{text}</Translate>
      </Typography>
    );
  }

  return (
    <span className={className}>
      <Translate {...props}>{text}</Translate>
    </span>
  );
};

Trans.propTypes = {
  weight: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
};

export default Trans;
