import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const SUBSCRIBE_TO_COURSE = gql`
  mutation subscribeToCourse($courseId: ID!) {
    subscribeToCourse(course_id: $courseId) {
      ok
      error
    }
  }
`;

const useSubscribeToCourseMutation = ({ courseId }) => {
  const [subscribeToCourse] = useMutation(SUBSCRIBE_TO_COURSE, {
    variables: { courseId },
  });

  //  subscribe to this course:

  return subscribeToCourse;
};

export default useSubscribeToCourseMutation;
