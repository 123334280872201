import React from "react";
import useGetProgramCourses from "API/queries/course/useGetProgramCourses";
import SkeletonList from "Components/_Elements/Skeleton/SkeletonList";
import GroupCoursesTimelineCourse from "routes/Group/GroupCourses/GroupCoursesTimeline/GroupCoursesTimelineCourse";
import {range} from "utilities/numbers";
import classNames from "classnames";
import moment from "moment/moment";

export const schoolYearStart = moment()
  .set("month", 7)
  .set("date", 15);

const GroupCoursesTimeline = ({programId}) => {
  const [programCourses, programCoursesLoading] = useGetProgramCourses({
    programId,
    includeSchool: false,
    includeCurrentUserData: false,
    includeEmptyCourses: true,
  });

  if (programCoursesLoading) return <SkeletonList count={5} />;

  // Create a timeline with 26 columns (one for each two-week)

  const nWeeks = 13;

  const todayOffsetPercentage =
    (Math.abs(moment().diff(schoolYearStart, "weeks")) / nWeeks) * 100;

  return (
    <div className="mt-16 pt-8 grid grid-cols-[repeat(13,minmax(0,1fr))] gap-x-1 gap-y-2 relative z-20 ">
      {// for each course return GroupCoursesTimelineCourse whihc adjusts based on the course start and end date
      programCourses.map((programCourse, index) => {
        return (
          <GroupCoursesTimelineCourse
            {...programCourse}
            key={programCourse.courseCode}
            index={index}
            weekSpanDivider={4} // number of weeks per col-span
          />
        );
      })}
      {/* Wrapper for overlays */}
      <div className="absolute top-0 left-0 w-full h-full z-10">
        <div
          className="w-0.5 h-full bg-red-200"
          style={{
            marginLeft: `${todayOffsetPercentage}%`,
          }}
        />
      </div>

      {/*
        Colored background for the timeline every second week
      */}
      <div className="absolute top-0 left-0 w-full h-full grid grid-cols-[repeat(13,minmax(0,1fr))] grid-rows-1 gap-x-1 gap-y-2 ">
        {range(nWeeks).map((_, index) => {
          const weekNumber = moment(schoolYearStart)
            .add(index * 2, "weeks")
            .week();

          return (
            <div
              key={index}
              className={classNames(
                "h-full rounded-lg relative",
                index % 2 === 0 ? "bg-gray-25" : "bg-white"
              )}
            >
              <span className="absolute -top-6 left-1 text-sm font-semibod text-gray-400">
                V{weekNumber}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupCoursesTimeline;
