import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";

export const GET_BOOKMARKED = gql`
  query($exerciseId: ID!) {
    getExercise(id: $exerciseId) {
      id
      bookmarked
    }
  }
`;

const useGetExerciseBookmarked = exerciseId => {
  const {loading, error, data} = useQuery(GET_BOOKMARKED, {
    variables: {exerciseId},
  });

  if (error) {
    console.log("Error in useGetExerciseBookmarked ", error);
  }

  return data?.getExercise?.bookmarked === null
    ? false
    : data?.getExercise?.bookmarked;
};

export default useGetExerciseBookmarked;
