import React from "react";
import Summer2022CampaignAuthRecurringRedeem from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignAuthRecurring/Summer2022CampaignAuthRecurringRedeem";
import Summer2022CampaignAuthRecurringSignIn from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignAuthRecurring/Summer2022CampaignAuthRecurringSignIn";
import {Alert, Trans} from "Components";

const Summer2022CampaignAuthRecurring = () => {
  // Check if user is logged in
  const userContext = React.useContext(window.UserContext);
  const loggedIn = userContext?.user?.id;

  // If/When logged in: go directly to Redeem page
  if (loggedIn) {
    return <Summer2022CampaignAuthRecurringRedeem />;
  }
  // Otherwise: Show login form
  // return <Summer2022CampaignAuthRecurringSignIn />;
  return (
    <Alert
      type="warning"
      title={<Trans>Something went wrong</Trans>}
      subTitle={
        <Trans>
          This should not happen. Please try again or contact support if the
          problem persists.
        </Trans>
      }
    />
  );
};

export default Summer2022CampaignAuthRecurring;
