import React from "react";
import classnames from "classnames";

const ProgressStepLine = ({orientation, status, selected, size}) => {
  const lineColor = {
    complete: "bg-success-500",
    incomplete: "bg-slate-200",
    current: "bg-primary-200",
  };

  if (orientation === "vertical") {
    return (
      <ProgressStepLineVertical
        status={status}
        size={size}
        lineColor={lineColor}
      />
    );
  }

  return (
    <ProgressStepLineHorizontal
      status={status}
      size={size}
      lineColor={lineColor}
    />
  );
};

const ProgressStepLineVertical = ({status, size, lineColor}) => {
  const heightClasses = {
    sm: "h-[calc(100%-24px/2)]",
    md: "h-[calc(100%-32px/2)]",
    lg: "h-[calc(100%-44px/2)]",
  };

  const widthClasses = {
    sm: "w-0.5",
    md: "w-1",
    lg: "w-1",
  };

  const offset = {
    sm: "left-3 top-6",
    md: "left-4 top-8",
    lg: "left-[1.6rem] top-12",
  };

  const paddingT = {
    sm: "pt-0.5",
    md: "pt-1",
    lg: "pt-2",
  };

  return (
    <div
      className={classnames(
        "absolute -translate-x-0.5",
        heightClasses[size],
        offset[size],
        paddingT[size]
      )}
    >
      <div
        className={classnames("h-full", widthClasses[size], lineColor[status])}
      />
    </div>
  );
};

const ProgressStepLineHorizontal = ({status, size, lineColor}) => {
  // 100 % is the width of the parent container so we need to subtract the width of the circle
  const widthClasses = {
    sm: "w-[calc(100%-24px/2)]",
    md: "w-[calc(100%-32px/2)]",
    lg: "w-[calc(100%-48px/2)]",
  };

  const heightClasses = {
    sm: "h-0.5",
    md: "h-1",
    lg: "h-1.5",
  };
  const offset = {
    sm: "left-6 top-3",
    md: "left-8 top-4",
    lg: "left-12 top-6",
  };

  const paddingL = {
    sm: "pl-0.5",
    md: "pl-1",
    lg: "pl-2",
  };

  return (
    <div
      className={classnames(
        "absolute -translate-y-0.5",
        widthClasses[size],
        offset[size],
        paddingL[size]
      )}
    >
      <div
        className={classnames("w-full", heightClasses[size], lineColor[status])}
      />
    </div>
  );
};

export default ProgressStepLine;
