import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

export const GET_EXERCISE_EVALUATION = gql`
  query getExercise($exerciseId: ID!) {
    getExercise(id: $exerciseId) {
      id
      self_evaluation {
        id
        score
        date
      }
    }
  }
`;

const useGetExerciseEvaluation = exerciseId => {
  let evaluation = {};

  const { data, loading, error } = useQuery(GET_EXERCISE_EVALUATION, {
    variables: { exerciseId },
  });

  if (error) {
    console.log("Error in useGetExerciseEvaluation, ", error);
  }

  if (data) {
    evaluation = data.getExercise.self_evaluation;
  }

  if (!evaluation) return [null, null];

  return [evaluation.score, evaluation.date];
};

export default useGetExerciseEvaluation;
