import React from "react";
import {Carousel, DifficultyChip, Link, Tabs, Trans} from "Components";
import useCoursePopularExercisesFiltersState from "./useCoursePopularExercisesFiltersState";
import ExerciseThumbnail from "../../../Components/ExerciseThumbnail/ExerciseThumbnail";
import Widget from "Components/Widget/Widget";
import {isMobileOnly} from "react-device-detect";
import classnames from "classnames";
import {CourseParamsContext} from "../../../routes/Course/CourseRouter";
import {range} from "../../../utilities/numbers";
import ExerciseThumbnailLoading from "../../../Components/ExerciseThumbnail/ExerciseThumbnailLoading";

const CoursePopularExercises = () => {
  const difficulties = [1, 2, 3, 4];
  const {getExerciseWithListLink} = React.useContext(CourseParamsContext);

  const {
    selectedDifficulty,
    setSelectedDifficulty,
    topExercisesData,
    topExercisesDataLoading,
    sortingCriteria,
    setSortingCriteria,
    sortingCriteriaOptions,
    value,
  } = useCoursePopularExercisesFiltersState();

  if (!topExercisesDataLoading && topExercisesData.length === 0) return null;

  const Wrapper = isMobileOnly ? Carousel : "div";

  return (
    <Widget title={<Trans>Exercises</Trans>} widgetName="ExerciseThumbnails">
      <Tabs
        type="underline"
        defaultSelectedTabKey={sortingCriteria}
        onTabChange={setSortingCriteria}
        animate={false}
      >
        {sortingCriteriaOptions.map((option, key) => (
          <Tabs.Pane title={option.label} key={option.value} icon={option.icon}>
            <Wrapper
              className="flex flex-nowrap gap-4 overflow-x-scroll overflow-hidden mt-2 snap-x touch-pan-x w-screen sm:w-full"
              key={option.value}
              showDots={true}
              showArrows={true}
              loop
            >
              {topExercisesDataLoading
                ? range(10).map(i => <ExerciseThumbnailLoading key={i} />)
                : topExercisesData.map((exercise, index) => (
                    <div
                      key={exercise.id}
                      className={classnames(
                        "snap-start sm:w-[24rem] shrink-0 ",
                        topExercisesDataLoading && "opacity-50"
                      )}
                    >
                      <Link
                        basic
                        key={exercise.id}
                        to={getExerciseWithListLink(
                          exercise.id,
                          topExercisesData.map(e => e.id),
                          value
                        )}
                        fluid
                      >
                        <ExerciseThumbnail
                          exercise={exercise}
                          key={exercise.id}
                          loading={topExercisesDataLoading}
                        />
                      </Link>
                    </div>
                  ))}
            </Wrapper>
          </Tabs.Pane>
        ))}
      </Tabs>
      <div className="mt-2 border-gray flex justify-between items-center">
        <div className="flex gap-2 mb-4">
          {difficulties.map((difficulty, key) => (
            <DifficultyChip
              score={difficulty}
              displayScore={false}
              faded={selectedDifficulty != difficulty}
              className="cursor-pointer"
              onClick={() => setSelectedDifficulty(difficulty)}
              icon={
                difficulty === selectedDifficulty
                  ? "fa-solid fa-circle"
                  : "fa-regular fa-circle"
              }
            />
          ))}
        </div>
      </div>
    </Widget>
  );
};

export default CoursePopularExercises;
