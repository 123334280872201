import React from "react";
import useGroupAdminUploadMatchUsers from "routes/Group/GroupAdminUpload/useGroupAdminUploadMatchUsers";

const useGroupAdminUpload = ({group, groupMembers, groupLoading}) => {
  const [fileMembers, setFileMembers] = React.useState([]);

  const clearFileMembers = () => setFileMembers([]);

  const {result, loading: matchLoading} = useGroupAdminUploadMatchUsers({
    groupMembers,
    fileMembers,
    groupLoading,
  });

  const [currentStep, setCurrentStep] = React.useState(null);
  const handleNextStep = () => setCurrentStep(currentStep + 1);

  const handleFinish = () => {
    // Clear file members
    clearFileMembers();
    // Clear current step
    setCurrentStep(null);
  };

  return {
    result,
    matchLoading,
    fileMembers,
    setFileMembers,
    clearFileMembers,

    currentStep,
    handleNextStep,
    setCurrentStep,
    handleFinish,
  };
};

export default useGroupAdminUpload;
