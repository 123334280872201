import React from "react";

import  {CourseLandingContext} from "routes/Course/CourseLanding/useCourseLandingContext";

const useCourseStats = () => {
  const { course } = React.useContext(CourseLandingContext);

  const collectVideoChapter = chapters => {
    const videoChapters = chapters.filter(
      chapter => chapter.chapter_type === "Video"
    );
    return videoChapters.length;
  };

  const totalVideos = () => {
    if (course?.tags && course.tags.length > 0) {
      let counter = 0;
      course.tags.map(tag => {
        const videoChapters = collectVideoChapter(tag.chapters);
        counter = counter + videoChapters;
      });

      return counter;
    } else return 0;
  };

  totalVideos();

  return {
    exercisesTotal: course?.exercises_count,
    theoryChapters: course?.chapters_count,
    studentsTotal: course?.students,
    videosTotal: totalVideos(),
  };
};

export default useCourseStats;
