import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const QUERY = gql`
  query getCampaignProgramData($id: String!) {
    summer2022CampaignTestimonials(id: $id) {
      sys {
        id
      }
      program
      programReception
      nStudents
      allowRegisteredUsers
      programLogo {
        url
        width
        height
      }
      heroImage {
        url
        width
        height
      }
      courses
      programs # Allowed programs for signup
      testimonialsCollection {
        items {
          name
          school
          body
          image {
            url
          }
        }
      }
    }
  }
`;

const useGetSummer2022CampaignProgramData = id => {
  let result = {};

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id },
    context: {
      api: "contentful",
    },
  });

  if (error) {
    console.log("Error in useGetSummer2022CampaignProgramData", error);
    result = null;
  }

  if (data && data.summer2022CampaignTestimonials) {
    result = data.summer2022CampaignTestimonials;
  } else {
    // Fallback if the data is not returned. We store a JSON snapshot so at least we can show something
  }

  return [result, loading];
};

export default useGetSummer2022CampaignProgramData;
