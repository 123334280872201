import React from "react";
import {Alert, FileUpload, ProgressBar, Trans} from "Components";
import useExamUploadDropzoneState from "routes/Course/ExamUpload/ExamUploadDropzone/useExamUploadDropzoneState";
import {ExamUploadContext} from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContext";

const ExamUploadDropzone = ({resources, disabled}) => {
  const {
    errors,
    onFilesAdded,
    onFilesFailed,
    files,
    loading,
  } = useExamUploadDropzoneState();

  let title =
    files.length > 0 ? (
      <Trans>Add more files</Trans>
    ) : (
      <Trans>Click to upload all exams</Trans>
    );

  return (
    <div>
      <div className="flex gap-2">
        {/*<ExamUploadDropzoneProgress files={files} />*/}
        <FileUpload
          multiple
          disabled={disabled}
          accept="application/pdf" // Accept only pdf files
          onFilesAccepted={onFilesAdded}
          onError={onFilesFailed}
          title={title}
          showSubTitle={files.length === 0}
          // compact={files.length > 0}
          fluid={files.length === 0}
          maxSize={10e6} // 15 MB
        />
      </div>
      {errors.length > 0 && (
        <div className="mt-2">
          {errors.map((error, index) => (
            <Alert title={error} type="error" key={index} closable />
          ))}
        </div>
      )}
    </div>
  );
};

const ExamUploadDropzoneProgress = ({files}) => {
  const {resources} = React.useContext(ExamUploadContext);

  const total = files.length;
  const uploaded = resources.length;
  let progressRatio = uploaded === 0 ? 0.1 / total : uploaded / total;
  const animated = uploaded < total;

  if (files.length === 0) return null;

  return (
    <div className="surface-primary p-4 w-full rounded-lg ">
      <Trans total={total} uploaded={uploaded}>
        {{uploaded}}/{{total}} Files uploaded successfully
      </Trans>

      <ProgressBar progressRatio={progressRatio} thin animated={animated} />
    </div>
  );
};

export default ExamUploadDropzone;
