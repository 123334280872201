import React from "react";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {Icon, Link, Trans} from "Components";
import classnames from "classnames";
import GroupHomeQuickActionsLastViewedExercise from "routes/Group/GroupHome/GroupHomeQuickActions/GroupHomeQuickActionsLastViewedExercise";

const GroupHomeQuickActions = ({}) => {
  const {currentUserIsAdmin} = useGroupContext();

  return (
    <>
      {currentUserIsAdmin && (
        <Action
          title={<Trans>Invite members</Trans>}
          icon="user-plus"
          to="/upload"
          color="primary"
        />
      )}

      <GroupHomeQuickActionsLastViewedExercise />
    </>
  );
};

const Action = ({title, icon, onClick, to, color}) => {
  return (
    <Link basic to={to}>
      <div
        className={classnames(
          "flex items-center justify-between",
          "p-4 rounded-lg",
          "transition-colors duration-200 ease-in-out",
          "border-2 border-slate-100 hover:border-slate-300"
          // `bg-${color}-50 `
        )}
      >
        <div className="flex items-center gap-4">
          <Icon
            icon={icon}
            size="lg"
            color={`text-${color}-500`}
            filled
            fillType={color}
            outlined={false}
          />
          {React.cloneElement(title, {
            color: `text-${color}-500`,
            type: "tlg",
            weight: "md",
          })}
        </div>
      </div>
    </Link>
  );
};

export default GroupHomeQuickActions;
