import React from "react";
import {useFormValidation} from "utilities/hooks/useFormValidation";
import {emptyValidator} from "../../../utilities/validators";
import useUpdateCurrentUserMutation from "API/mutations/user/useUpdateCurrentUserMutation";
import {message, Trans} from "Components";
import {GET_CURRENT_USER} from "API/queries/user/useGetCurrentUser";
import parseEnrollmentYearToInt from "utilities/parseEnrollmentYearToInt";

const useAccountPersonalInformationEditState = (
  user,
  toggleEditing,
  showMessageOnComplete = true
) => {
  const onComplete = () => {
    if (showMessageOnComplete) {
      message.success(<Trans>Account updated successfully</Trans>);
    }
  };

  const [updateUserMutation, loading] = useUpdateCurrentUserMutation(
    onComplete,
    GET_CURRENT_USER
  );

  const INITIAL_VALUES = {
    ...user,
  };

  const {
    values,
    setValues,
    setFormValue,
    errors,
    setErrors,
    submit,
    handleChange,
  } = useFormValidation(
    {
      ...INITIAL_VALUES,
    },
    updateUserMutation,
    emptyValidator
  );

  const onCancel = () => {
    // Reset form values to original values
    setValues({
      ...user,
    });
  };

  // When the schoolId changes, reset the programId
  React.useEffect(() => {
    if (values.schoolId !== user.schoolId) {
      setFormValue({programId: null});
    }
  }, [values.schoolId]);

  const onSave = () => {
    const enrollmentYear = parseEnrollmentYearToInt(values.enrollmentYear);

    return updateUserMutation({
      variables: {
        ...values,
        enrollmentYear,
      },
    });
  };

  const edited = React.useMemo(() => {
    return Object.keys(values).some(key => {
      return values[key] !== INITIAL_VALUES[key];
    });
  }, [values]);

  return {
    loading,
    values,
    handleChange,
    setFormValue,
    onCancel,
    onSave,
    edited,
  };
};

export default useAccountPersonalInformationEditState;
