import React from "react";
import classnames from "classnames";

import {
  gapSizes,
  imageSizes,
  imageUrls,
  textColors,
  textSizes,
} from "Components/_Elements/Logo/Logo.constants";
import {Typography} from "Components/_Elements/index";
import LogoSVG from "Components/_Elements/Logo/LogoSVG";

const Logo = ({
  type = "primary",
  size = "md",
  showText = true,
  vertical = false,
  onClick = null,
  className,
  fillColor = null,
  textColor = null,
}) => {
  return (
    <div
      className={classnames(
        "flex items-center",
        gapSizes[size],
        vertical ? "flex-col" : "flex-row",
        onClick && "cursor-pointer",
        className
      )}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {type === "transparent" || fillColor !== null ? (
        <LogoSVG fillColor={fillColor} sizeClasses={imageSizes[size]} />
      ) : (
        <img
          src={imageUrls[type]}
          className={classnames(imageSizes[size], fillColor)}
          alt="logo"
        />
      )}

      {showText && (
        <Typography
          className="kollin-font"
          type={textSizes[size]}
          weight="md"
          color={textColor || textColors[type]}
        >
          Kollin
        </Typography>
      )}
    </div>
  );
};

export default Logo;
