import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {objectKeysSnakeToCamel} from "utilities/objects";

const GET_REFERRALS = gql`
  query getCurrentUserReferrals {
    getCurrentUserReferrals {
      id
      coupon {
        id
        amount
      }
      referee {
        id
        first_name
        avatar
        email
      }
      created_at
    }
  }
`;

const useGetUserReferrals = () => {
  let coupon = [];

  const {loading, error, data} = useQuery(GET_REFERRALS);

  if (error) return [[], false];

  if (data) {
    coupon = objectKeysSnakeToCamel(data.getCurrentUserReferrals);
  }

  return [coupon, loading];
};

export default useGetUserReferrals;
