const toCamel = s => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace("-", "")
      .replace("_", "");
  });
};

export const isArray = function(a) {
  return Array.isArray(a);
};

export const isObject = function(o) {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};

export const isEmptyObject = function(o) {
  return o === Object(o) && Object.keys(o).length === 0;
};

export const objectKeysSnakeToCamel = o => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = objectKeysSnakeToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map(i => {
      return objectKeysSnakeToCamel(i);
    });
  }

  return o;
};

/**
 * Filters a list of objects so it only has one entry per object's property
 */
export const uniqueBy = (collection, uniqueKey) => {
  let objects = {};
  collection.forEach(element => (objects[element[uniqueKey]] = element));
  return Object.keys(objects).map(key => objects[key]);
};

/**
 *
 * @param {Object} obj
 * Takes all the values of the keys and put them in a single list
 */
export const reduceObject = (obj, key) =>
  Object.keys(obj)
    .map(k => obj[k].map(elem => ({...elem, [key]: k})))
    .reduce((a, b) => [...a, ...b], []);

/**
 * Sorts the keys of an object by a given sort function
 * @param obj
 * @param keySortFunction
 * @returns {{}}
 */

export const sortObjectKeys = (obj, keySortFunction) => {
  const keys = Object.keys(obj).sort(keySortFunction);
  const newObj = {};

  keys.forEach(key => {
    newObj[key] = obj[key];
  });

  return newObj;
};

/**
 * Groups an array of objects by a given key
 * @param array
 * @param {String} key - use "." to access nested keys, uses the last key's values as the group's key
 * @param {Function} valueFormatFunction - a function that formats the value
 */

export const groupBy = (array, key, valueFormatFunction = null) => {
  return array.reduce((result, currentObject) => {
    let value = getNestedKeyValue(currentObject, key);

    // Skip if the value is null or undefined
    if (!value) {
      return result;
    }
    // Apply a format function to the value
    if (valueFormatFunction) {
      value = valueFormatFunction(value);
    }

    if (!result[value]) {
      result[value] = [];
    }

    result[value].push({
      ...JSON.parse(JSON.stringify(currentObject)),
    });

    return result;
  }, {});
};

const getNestedKeyValue = (object, key) => {
  const keys = key.split(".");

  let value = object;
  try {
    keys.forEach(k => {
      value = value[k];
    });
  } catch (e) {
    return null;
  }
  return value;
};
