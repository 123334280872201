import React from "react";
import {useLocation} from "react-router-dom";
import {StringParam, useQueryParams} from "use-query-params";

const usePreviewSidebarState = () => {
  const location = useLocation();

  const [urlParam, setUrlParam] = useQueryParams({
    preview: StringParam,
  });

  const [previewState, setPreviewState] = React.useState({
    route: null,
    title: null,
    Component: null,
    urlParams: null,
  });

  const previewOpen = previewState.route || previewState.Component;

  const setPreview = ({
    route = null,
    urlParams = null,
    title = null,
    Component = null,
  }) => {
    if (!route && !Component)
      console.log("Provide a route or a Component to setPreview");

    setPreviewState({
      route,
      Component,
      title,
    });
  };

  const handleClosePreview = () => {
    setPreviewState({
      route: null,
      title: null,
      Component: null,
      urlParams: null,
    });
  };

  // Close the preview if the location pathname changes
  // const currentPath = location.pathname + getUrlParams(location.search);
  const currentPath = location.pathname;

  React.useEffect(() => {
    if (previewOpen) {
      handleClosePreview();
    }
  }, [currentPath]);

  // Interaction between the previewRoute query param and the previewState
  React.useEffect(() => {
    if (urlParam.preview) {
      setPreview({
        route: decodeURIComponent(urlParam.preview),
      });
    }
  }, [urlParam.preview]);

  React.useEffect(() => {
    // When the previewState.route changes, update the previewRoute query param to match
    if (previewState.route !== urlParam.preview) {
      setUrlParam({
        preview: previewState.route,
      });
    }
  }, [previewState.route]);

  return {
    previewOpen,
    setPreview,
    handleClosePreview,
    previewState,
  };
};

const getUrlParams = search => {
  //  Return all params except the preview param
  const params = new URLSearchParams(search);
  const urlParams = {};
  for (let param of params) {
    if (param[0] !== "preview") {
      urlParams[param[0]] = param[1];
    }
  }

  return urlParams.toString();
};

/**
 * Sets the object of params in the query string without removing the existing params
 * @param params
 */

const addUrlParams = params => {
  const urlParams = new URLSearchParams(window.location.search);

  for (let param in params) {
    urlParams.set(param, params[param]);
  }

  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${urlParams.toString()}`
  );
};

export default usePreviewSidebarState;
