import React from "react";
import {CourseInfo} from "Widgets";
import useGetCourseExercises from "API/queries/exercise/useGetCourseExercises";
import ExamUpload from "routes/Course/ExamUpload/ExamUpload";
import {ErrorBoundary, Section, Trans} from "Components";
import CoursePopularExercises from "../../../Widgets/Course/CoursePopularExercises/CoursePopularExercises";
import {isMobile} from "react-device-detect";
import CourseReviewContainer from "../CourseHome/CourseReviewContainer";
import CourseDashboardUserStats from "routes/Course/CourseDashboard/CourseDashboardUserStats";
import CourseDashboardDevelopmentStats from "routes/Course/CourseDashboard/CourseDashboardDevelopmentStats";
import CourseContributions from "Widgets/Course/CourseContributions/CourseContributions";

import CourseKollinGPTSaved from "routes/Course/CourseKollinGPT/CourseKollinGPTSaved/CourseKollinGPTSaved";
import CourseTopicsList from "Widgets/Course/CourseTopicsList/CourseTopicsList";
import CourseExamsList from "Widgets/Course/CourseExamsList/CourseExamsList";
import GPTDisplayDashboard from "../../../Components/GPT/GPTDisplay/GPTDisplayDashboard";
import GroupSubdomainBanner from "Widgets/Group/GroupSubdomainBanner/GroupSubdomainBanner";
import {useHistory} from "react-router-dom";

const CourseDashboard = () => {
  const {user} = React.useContext(window.UserContext);
  const {courseId, purchased} = React.useContext(window.CourseContext);

  const [exercises] = useGetCourseExercises({
    courseId,
    includeTags: true,
  });

  return (
    <div className="background bg-stone-50 -m-2 md:-m-4 p-2 sm:p-4 w-screen min-h-screen overflow-x-hidden sm:w-auto">
      <div className="hidden md:block">
        <Section
          icon="home"
          title={<Trans>Home</Trans>}
          secondary={true}
          className=" pb-0"
        />
      </div>

      <div className="grid grid-cols-12 gap-2 md:gap-4">
        <div className="col-span-12 lg:col-span-8 space-y-2 md:space-y-4 overflow-clip">
          <ErrorBoundary hideOnError>
            <GroupSubdomainBanner />
          </ErrorBoundary>

          <CourseDashboardUserStats exercises={exercises} />

          <ErrorBoundary hideOnError>
            <CoursePopularExercises />
          </ErrorBoundary>

          {user?.id && (
            <ExamUpload hideIfExistingExams={true} showOutput={false} />
          )}

          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
            <CourseTopicsList size={5} />
            <CourseExamsList size={5} />
          </div>

          <CourseDashboardDevelopmentStats />
          <CourseKollinGPTSaved />
          {/*
        Right side of the page
      */}
        </div>
        <div className="col-span-12 lg:col-span-4 space-y-2 md:space-y-4  overflow-y-auto  border-gray overflow-x-clip ">
          <div className="hidden md:block">
            <ErrorBoundary hideOnError>
              <GPTDisplayDashboard />
            </ErrorBoundary>
          </div>

          <ErrorBoundary hideOnError>
            <CourseContributions />
          </ErrorBoundary>
          <ErrorBoundary hideOnError>
            <CourseInfo />
          </ErrorBoundary>
          {purchased && !isMobile && (
            <ErrorBoundary>
              <CourseReviewContainer />
            </ErrorBoundary>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseDashboard;
