import {scoreToDifficultyIndex} from "./formatScore";

export const avgScore = exercises => {
  return exercises
    .reduce((acc, curr) => acc + Number(curr.score) / exercises.length, 0)
    .toFixed(1);
};

export const segments = exercises => {
  let exercisesWithSelfEvaluation = exercises.filter(ex => ex.selfEvaluation);
  if (exercisesWithSelfEvaluation.length === 0) {
    return [];
  }
  return exercisesWithSelfEvaluation.reduce(
    (acc, curr) => (
      (acc[scoreToDifficultyIndex(curr.selfEvaluation) - 1].nCompleted += 1),
      acc
    ),
    [
      {selfEvaluation: 1, nCompleted: 0},
      {selfEvaluation: 2, nCompleted: 0},
      {selfEvaluation: 3, nCompleted: 0},
      {selfEvaluation: 4, nCompleted: 0},
    ]
  );
};

export const avgSelfEvaluation = exercises => {
  if (exercises.length === 0) return 0;
  let exercisesWithSelfEvaluation = exercises.filter(ex => ex.selfEvaluation);
  if (exercisesWithSelfEvaluation.length === 0) {
    return null;
  }
  return exercisesWithSelfEvaluation
    .reduce((acc, curr) => {
      return (
        acc + Number(curr.selfEvaluation / exercisesWithSelfEvaluation.length)
      );
    }, 0)
    .toFixed(1);
};

export const nCompleted = exercises => {
  return exercises.filter(ex => ex.selfEvaluation).length;
};

// noinspection JSUnusedGlobalSymbols
/**
 * Sorts the given collection of exercises by the given attribute
 * @param {[Exercise]} exercises
 * @param {String} attribute  The attribute to sort by
 * @param {String} dir "asc" | "desc"
 *
 */
export const sortExercisesList = (exercises, attribute, dir) => {
  exercises.sort((a, b) => a[attribute] - b[attribute]);
};
