import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {UserContext} from "UserContextProvider";

export const GET_COURSE = gql`
  query getCourse(
    $course_id: ID!
    $includeStats: Boolean!
    $includeModules: Boolean!
    $includeMetadata: Boolean!
    $loggedIn: Boolean!
  ) {
    getCourse(id: $course_id) {
      subject {
        id
      }
      icon
      id
      name
      code
      price
      averageRating
      public_tagging
      tags @include(if: $includeStats) {
        chapters {
          chapter_type
        }
      }
      exercises_count @include(if: $includeStats)
      exams_count @include(if: $includeStats)
      chapters_count @include(if: $includeStats)
      students @include(if: $includeStats)
      modules @include(if: $includeModules) {
        id
        name
        tags {
          id
          name
        }
      }
      color
      purchased @include(if: $loggedIn)
      currentUser @include(if: $loggedIn) {
        active
        archived
      }
      school {
        id
        name
        nickname
      }
      subject {
        id
        name
      }
      metadata @include(if: $includeMetadata) {
        id
        schoolData
        createdAt
        updatedAt
      }
    }
  }
`;

const useGetCourse = (
  courseId,
  includeStats = false,
  includeModules = false,
  includeMetadata = false
) => {
  const {user} = React.useContext(UserContext);
  let course = {};

  const {loading, error, data} = useQuery(GET_COURSE, {
    fetchPolicy: "cache-first",
    variables: {
      course_id: courseId,
      includeStats,
      includeModules,
      includeMetadata,
      loggedIn: !!user?.id,
    },
    context: {
      batch: true,
    },
  });

  if (data) {
    course = data.getCourse || {};
  }

  if (error) {
    console.log("Error in getCourse-hook: ", error);
  }

  return [course, loading];
};

export default useGetCourse;
