import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";

export const GET_CURRENT_USER_ORDERS = gql`
  query getCurrentUserOrders {
    currentUserOrders {
      id
      status
      currency
      paymentMethod
      createdAt
      status
      discount
      items {
        id
        productId
        productType
        price
        amountCents
        quantity
        expiryDate
      }
    }
  }
`;

const useGetCurrentUserOrders = () => {
  let orders = [];

  const {loading, error, data} = useQuery(GET_CURRENT_USER_ORDERS, {});

  if (error) {
    console.log("Error in useGetCurrentUserOrders", error);
  }
  orders = data?.currentUserOrders || [];

  return [orders, loading];
};

export default useGetCurrentUserOrders;
