import React from "react";
import {Icon} from "Components/_Elements/index";
import {safeCloneTextTag} from "utilities/safeClone";
import {motion} from "framer-motion";

const iconMapping = {
  info: "circle-info",
  warning: "triangle-exclamation",
  error: "circle-xmark",
  success: "circle-check",
  loading: "circle-notch",
};

const MessageComponent = ({message, index, onExit}) => {
  const isTrigger = message.type === "trigger";
  React.useEffect(() => {
    if (message.timeout) {
      const timeout = setTimeout(() => {
        onExit();
      }, message.timeout);

      return () => clearTimeout(timeout);
    }
  }, [message.timeout, message.type]);

  return (
    <motion.div
      key={"message-" + message.id}
      initial={{opacity: 0, y: -100}}
      animate={{opacity: 1, y: 0}}
      exit={{opacity: 0, y: -100}}
      layout={true}
      className={
        isTrigger // trigger has no styling
          ? null
          : "bg-gray-50 w-10/12 md:max-w-6/12 md:w-fit mb-2 mx-auto rounded-lg border-2 border-color shadow-xl py-3 px-4 flex items-center justify-start gap-4"
      }
    >
      {!isTrigger && (
        <Icon
          size="md"
          icon={`fa-regular fa-${iconMapping[message.type]}`}
          color="text-primary"
          filled={message.type !== "loading"}
          fillType={message.type === "info" ? "primary" : message.type}
          spin={message.type === "loading"}
        />
      )}

      {safeCloneTextTag(message.content, {
        weight: "md",
        color: "text-primary",
      })}
    </motion.div>
  );
};

export default MessageComponent;
