import React from "react";
import {Icon, Loader, Lottie, SafeText, Typography} from "Components";
import classnames from "classnames";

const EarnCreditsSection = ({
  imageSrc = "/assets/images/_9b4bdba3-cd10-4898-9c79-1d15438f7c95.jpeg",
  title,
  description,
  reward = 0,
  children,
  completed = false,
  loading,
}) => {
  return (
    <div className="pb-2 border-b-1 border-gray last:border-b-0 mt-4 first:mt-0">
      <div
        className={classnames(
          "grid grid-cols-12 gap-8 p-4 w-full items-start "
        )}
      >
        <div
          className={classnames(
            "col-span-2 relative",
            completed && "opacity-50"
          )}
        >
          <img
            src={imageSrc}
            className={classnames(
              " rounded-xl sepia-[.5]",
              completed ? "w-1/2 mx-auto" : "w-full"
            )}
          />
        </div>
        <div className={classnames("col-span-9", completed && "opacity-50")}>
          <SafeText type="tlg" weight="md" color="text-gray">
            {title}
          </SafeText>

          <SafeText
            type="tsm"
            weight="normal"
            color="text-gray-light"
            className="mt-2"
          >
            {description}
          </SafeText>

          {children && !loading && !completed && (
            <div className="w-full mt-4">{children}</div>
          )}
        </div>
        <div className="col-span-1 justify-self-end flex gap-2 items-center">
          <EarnCreditsSectionStatus
            loading={loading}
            completed={completed}
            reward={reward}
          />
        </div>
      </div>
    </div>
  );
};

const EarnCreditsSectionStatus = ({loading, completed, reward}) => {
  if (loading) return <Loader type="Bar" size={8} />;

  if (completed) {
    return (
      <div className="scale-150">
        <Lottie animation="checkMarkSparkles" loop={false} />
      </div>
    );
  }

  return (
    <Typography
      type="dxs"
      weight="sb"
      className="whitespace-nowrap"
      color={completed ? "text-gray-light" : "text-success"}
    >
      <span className="mx-1">{reward}</span>
      <Icon
        icon="coins"
        color={completed ? "text-gray-light" : "text-success"}
      />
    </Typography>
  );
};

export default EarnCreditsSection;
