import React from "react";
import {Icon, Tabs, Trans} from "Components";
import AccountPersonalInformation from "./AccountPersonalInformation/AccountPersonalInformation";
import AccountCredits from "./AccountCredits/AccountCredits";
import AccountSubscription from "./AccountSubscription/AccountSubscription";
import AccountSettings from "routes/Account/AccountSettings/AccountSettings";
import {logout} from "utilities/authentication";
import PageClassic from "Components/_Elements/Page/PageClassic";
import useAccountUrlState from "./useAccountUrlState";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";
import AccountOrders from "routes/Account/AccountOrders/AccountOrders";

const Account = () => {
  const {currentTab, onTabChange} = useAccountUrlState();
  const smallScreens = useBreakPointSM();

  return (
    <PageClassic
      showFooter={false}
      showUserMenu={false}
      className="background min-h-screen"
    >
      <Tabs
        orientation={smallScreens("horizontal", "vertical")}
        fluid={smallScreens(true, false)} // force fluid on small screens otherwise the tabs are not scrollable
        type="button"
        size={smallScreens("sm", "lg")}
        contentClassName="max-w-5xl md:ml-4 lg:ml-16"
        defaultSelectedTabKey={currentTab}
        onTabChange={onTabChange}
        persistCurrentTabInUrl={false}
        urlParamName="page"
      >
        <Tabs.Pane
          key="personal-info"
          icon="user"
          title={<Trans>Details</Trans>}
        >
          <AccountPersonalInformation />
        </Tabs.Pane>

        <Tabs.Pane
          key="subscription"
          title={<Trans>Subscription</Trans>}
          icon="credit-card"
        >
          <AccountSubscription />
        </Tabs.Pane>

        <Tabs.Pane key="credits" title={<Trans>Credits</Trans>} icon="coins">
          <AccountCredits />
        </Tabs.Pane>

        <Tabs.Pane
          key="invoice-history"
          title={<Trans>Payment history</Trans>}
          icon="receipt"
        >
          <AccountOrders />
        </Tabs.Pane>

        <Tabs.Pane key="settings" title={<Trans>Settings</Trans>} icon="cog">
          <AccountSettings />
        </Tabs.Pane>
        <Tabs.Pane
          key="logout"
          icon={<Icon icon="power-off" color="text-error" />}
          title={<Trans color="text-error">Logout</Trans>}
          onClick={logout} // override default behavior
        >
          <div />
        </Tabs.Pane>
      </Tabs>
    </PageClassic>
  );
};

export const accountSubSectionTitleProps = {
  weight: "md",
  color: "text-gray",
};

export default Account;
