import React from "react";
import CDN from "utilities/constants/CDN";
import classnames from "classnames";
import {Icon} from "Components";
import PropTypes from "prop-types";

export const sizeMap = {
  xs: "h-6 rounded-md",
  sm: "h-8 rounded-md",
  md: "h-10 rounded-lg",
  lg: "h-12 rounded-lg",
  xl: "h-14 rounded-xl",
};

const CreditCardLogo = ({
  size = "md",
  type = "visa",
  dark = false,
  className = "",
}) => {
  const logoIndex = types.indexOf(type);
  let url = `${CDN}/credit-card-logos/${dark ? "dark" : "light"}/${logoIndex +
    1}.png`;

  if (logoIndex === -1) {
    return (
      <Icon size={size} icon={`fa-brands fa-cc`} className="text-gray-light" />
    );
  }

  return (
    <img
      src={url}
      className={classnames(sizeMap[size], className)}
      alt={type}
    />
  );
};

export const types = [
  "visa",
  "mastercard",
  "maestro",
  "cirrus",
  "paypal",
  "western-union",
  "visa-electron",
  "amazon",
  "worldpay",
  "diners-club",
  "google-wallet",
  "skrill",
  "sage",
  "discover",
  "moneybookers",
  "jcb",
  "ebay",
  "eway",
  "sss",
  "solo",
  "direct-debit",
  "american-express",
  "shopify",
];

CreditCardLogo.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizeMap)).isRequired,
  type: PropTypes.oneOf(types).isRequired,
  dark: PropTypes.bool,
  className: PropTypes.string,
};

export default CreditCardLogo;
