import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

export const GET_CHAPTER = gql`
  query(
    $chapterId: ID!
    $chapterType: String!
    $includeText: Boolean!
    $includeTag: Boolean!
    $includeAuthor: Boolean!
  ) {
    getChapter(id: $chapterId, chapter_type: $chapterType) {
      id
      title
      chapter_type
      published
      createdAt
      html @include(if: $includeText)
      markdown @include(if: $includeText)
      author @include(if: $includeAuthor) {
        id
        avatar
        firstName
        lastName
        #        admin
        #        premium
        assistant
        program {
          id
          name
        }
        enrollmentYear
      }
      tag @include(if: $includeTag) {
        id
        name
      }
    }
  }
`;

const useGetChapter = ({
  chapterId,
  chapterType,
  includeText = false,
  includeTag = false,
  includeAuthor = false,
}) => {
  const {user} = React.useContext(UserContext);
  let chapter = {};

  const {loading: loadingChapter, error: errorChapter, data} = useQuery(
    GET_CHAPTER,
    {
      variables: {
        chapterId,
        chapterType,
        includeText,
        includeTag,
        includeAuthor,
      },
    }
  );

  if (data) {
    chapter = {...data.getChapter, chapterType: data.getChapter.chapter_type};
  }

  return [chapter, loadingChapter];
};

export default useGetChapter;
