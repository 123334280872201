import React from "react";
import CourseReviewsSummary from "./CourseReviewsSummary/CourseReviewsSummary";

import CourseStats from "./CourseStats/CourseStats";
import {ErrorBoundary, Typography} from "Components";
import CDN from "utilities/constants/CDN";
import {formatCourseName} from "utilities/formatCourseName";
import {CourseLandingContext} from "routes/Course/CourseLanding/useCourseLandingContext";

const CourseLandingTitleBanner = () => {
    const {user} = React.useContext(window.UserContext);

    const {course, navigationState, productPrice} = React.useContext(
        CourseLandingContext
    );

    const {name, school, id, code} = course;

    const handleRatingClick = () =>
        navigationState.allReviewsRef.current.scrollIntoView();

    const activateCourse =
        productPrice === 0 || user?.premium || user?.subscriber;

    return (
        <div>
            <div className="pb-6">
                <img
                    src={`${CDN}/course-icons/${course?.icon}.svg`}
                    alt={course?.name}
                    className="w-20 m-auto py-2 md:w-40"
                />
                <Typography type="dmd" color="text-primary-800">
                    {formatCourseName(name)}
                </Typography>
                <Typography type="tlg" color="text-primary-800" weight="sb">
                    {code}
                </Typography>
                <Typography type="tlg" color="text-gray-500" weight="md">
                    {school.name}
                </Typography>
                <ErrorBoundary>
                    <div className="my-2">
                        <CourseReviewsSummary handleRatingClick={handleRatingClick} courseId={id}/>
                    </div>
                </ErrorBoundary>
            </div>

            <ErrorBoundary>
                <CourseStats/>
            </ErrorBoundary>

            {/*{activateCourse && <CourseLandingActivateBtn courseId={id} />}*/}
        </div>
    );
};

export default CourseLandingTitleBanner;
