import React from "react";
import useExamViewDocumentScrollProgress from "routes/Course/CourseExamView/ExamViewDocument/useExamViewDocumentScrollProgress";

const useExamViewDocument = pdfUrl => {
  const container = React.useRef(pdfUrl);
  const [pageCount, setPageCount] = React.useState(0);

  const progressRatio = useExamViewDocumentScrollProgress({
    pdfUrl,
    container,
  });

  return {
    pageCount,
    setPageCount,
    container,
    progressRatio,
  };
};

export default useExamViewDocument;
