import React from "react";
import useGetGroupInfo from "API/queries/groups/useGetGroupInfo";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {updateGroup} from "API/appsync/mutations";

const useGroupInfoSettingsAdmins = () => {
  const {groupId} = useGroupContext();

  const {info: groupInfo, loading, refetch} = useGetGroupInfo({
    groupId: groupId,
  });

  const [updateGroupInfoMutation] = useMutation(gql(updateGroup), {
    context: {
      api: "appsync",
    },
  });

  // STATE
  const [contacts, setContacts] = React.useState(groupInfo?.contacts || []);

  React.useEffect(() => {
    setContacts(groupInfo?.contacts || []);
  }, [groupInfo]);

  // Handlers
  const handleSave = () => {
    // Send the mutation to update the group info to the state
    updateGroupInfoMutation({
      variables: {
        input: {
          id: groupInfo.id,
          contacts: contacts.map(contact => {
            delete contact.__typename; // Remove the __typename from the object
            return contact;
          }),
        },
      },
    }).then(refetch);
  };

  const handleRemoveContact = email => {
    setContacts(prevState => {
      return prevState.filter(
        ({email: contactEmail}) => email !== contactEmail
      );
    });
  };

  const handleAddContact = newContact => {
    setContacts(prevState => {
      return [...prevState, newContact];
    });
  };

  // save when contacts change
  React.useEffect(() => {
    if (loading) return;

    if (JSON.stringify(contacts) === JSON.stringify(groupInfo?.contacts || []))
      return;

    handleSave();
  }, [contacts]);

  return {contacts, handleSave, handleRemoveContact, handleAddContact};
};

export default useGroupInfoSettingsAdmins;
