import React from "react";

const useCheckoutTotalCalculations = ({
  productsState,
  discount: creditsDiscount,
  groupDiscountCoupon,
}) => {
  /**
   * The total price of all the products - based on the default price
   * - BEFORE the discount is applied
   * @type {unknown}
   */
  return React.useMemo(() => {
    let defaultPriceTotal = 0; // The total price of all the products - based on the default price
    let selectedPriceTotal = 0; // The total price of all the products - based on the selected price which currently is either the same as the default price or the lifetimeAccess price
    let total = 0;

    const allProductsSelected = [
      ...productsState.products,
      // Take only the recommended products that are selected
      ...productsState.recommendedProducts?.filter(
        recommendedProduct => recommendedProduct.selected
      ),
    ];

    allProductsSelected.forEach(product => {
      defaultPriceTotal += product.defaultPrice.priceCents;
      selectedPriceTotal += product.selectedPrice.priceCents;
    });

    total = selectedPriceTotal;

    // Apply the group discount if it exists
    if (
      groupDiscountCoupon &&
      groupDiscountCoupon.discountPercentage > 0 &&
      groupDiscountCoupon.applicable
    ) {
      // Calculate the group discount amount
      total = total * (1 - groupDiscountCoupon.discountPercentage / 100);
    }

    total = Math.max(total - creditsDiscount, 0);

    return {
      defaultPriceTotal,
      selectedPriceTotal,
      lifeTimeAccessAddonCost: selectedPriceTotal - defaultPriceTotal,
      total,
    };
  }, [productsState, creditsDiscount, groupDiscountCoupon]);
};

export default useCheckoutTotalCalculations;
