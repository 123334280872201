import React from "react";
import { Button } from "Components/_Elements";
import { useTranslation } from "react-i18next";
import { Trans } from "Components/index";

const CommentFooter = ({
  expanded,
  setExpanded,
  replies,
  expandable,
  extra,
  onExpandClick,
}) => {
  const handleClick = () => {
    if (onExpandClick !== null) {
      onExpandClick();
    } else {
      setExpanded(!expanded);
    }
  };

  // Get button prompt
  const buttonPrompt = useGetButtonPrompt(expanded, replies);

  return (
    <div
      className="mt-4 flex justify-between items-center"
      // layoutId={key + "-footer"}
    >
      {expandable ? (
        <Button
          type="link"
          iconRight={expanded ? "chevron-up" : "chevron-down"}
          size="sm"
          onClick={handleClick}
        >
          {buttonPrompt}
        </Button>
      ) : (
        <span />
      )}
      {extra}
    </div>
  );
};

const useGetButtonPrompt = (expanded, replies) => {
  const { t: trans } = useTranslation();

  return React.useMemo(() => {
    if (replies.length === 0) {
      return trans("Reply");
    }

    if (expanded) {
      return trans("Hide replies");
    }

    const repliesCount = replies.length;

    return (
      <Trans repliesCount={repliesCount}>Show {{ repliesCount }} replies</Trans>
    );
  }, [expanded, replies]);
};

export default CommentFooter;
