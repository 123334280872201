import React from "react";
import {ProductCard, Trans} from "Components";
import "./CourseStudyPlanBanner.scss";
import {useLocation} from "react-router-dom";
import useGetCourseStudyPlans from "API/queries/StudyPlan/useGetCourseStudyPlans";
import StudyPlanCard from "Components/StudyPlanCard/StudyPlanCard";

const CourseStudyPlanBanner = () => {
  const location = useLocation();

  const {navigationState} = React.useContext(window.CourseLandingContext);
  const {courseId} = React.useContext(window.CourseContext);

  // const [studyPlan, loading] = useGetCourseStudyPlan(courseId);
  const [studyPlans, loading] = useGetCourseStudyPlans(courseId);

  if (loading || studyPlans.length === 0) return <></>;

  return (
    <div ref={navigationState.studyPlansRef} className="course-study-plan">
      <h1 className="course-study-plan-title">
        {studyPlans.length == 1 ? (
          <Trans type="txl" weight="sb">
            Studyplan
          </Trans>
        ) : (
          <Trans type="txl" weight="sb">
            Studyplans
          </Trans>
        )}
      </h1>

      <div className="flex flex-col gap-2">
        {studyPlans.map((studyPlan, i) => (
          <StudyPlanCard
            key={i}
            name={studyPlan.name}
            description={studyPlan.description}
            author={studyPlan.author}
            studyPlan={studyPlan}
            backUrl={location.pathname}
          />
        ))}
      </div>
    </div>
  );
};

export default CourseStudyPlanBanner;
