const types = {
  primary: "bg-primary-50",
  blue: "bg-primary-50",
  secondary: "bg-secondary-50",
  gray: "bg-gray-50",
  darkGray: " bg-gray-200",
  destructive: "bg-destructive-50",
  error: "bg-error-50",
  warning: "bg-warning-50",
  yellow: "bg-warning-50",
  success: "bg-success-50",
  green: "bg-success-50",
  purple: "bg-purple-50",
  amber: "bg-amber-50",
};

const sizes = {
  xs: "badge-base-xs",
  sm: "badge-base-small",
  md: "badge-base-medium",
  lg: "badge-base-large",
};

const textSize = {
  xs: "body6",
  sm: "body6",
  md: "body4",
  lg: "body3",
};

const textTypes = {
  xs: "txs",
  sm: "txs",
  md: "tsm ms:tmd",
  lg: "tmd sm:tlg",
};

const textColors = {
  primary: "text-primary-700 dark:text-primary-100",
  blue: "text-primary-700 ark:text-secondary-100",
  secondary: "text-secondary-700 dark:text-secondary-100",
  gray: "text-gray-700 dark:text-gray-100",
  darkGray: "text-gray-700 dark:text-gray-100",
  destructive: "text-destructive-700 dark:text-destructive-100",
  error: "text-error-700 dark:text-error-100",
  warning: "text-warning-700 dark:text-warning-100",
  yellow: "text-warning-700 dark:text-warning-100",
  success: "text-success-700 dark:text-success-100",
  green: "text-success-700 dark:text-success-100",
  purple: "text-purple-700 dark:text-purple-100",
  amber: "text-amber-700 dark:text-amber-100",
};

const borderColors = {
  primary: "border-primary-400 dark:border-primary-100",
  blue: "border-primary-400 ark:border-secondary-100",
  secondary: "border-secondary-400 dark:border-secondary-100",
  gray: "border-gray-400 dark:border-gray-100",
  darkGray: "border-gray-400 dark:border-gray-100",
  destructive: "border-destructive-400 dark:border-destructive-100",
  error: "border-error-400 dark:border-error-100",
  warning: "border-warning-400 dark:border-warning-100",
  yellow: "border-warning-400 dark:border-warning-100",
  success: "border-success-400 dark:border-success-100",
  green: "border-success-400 dark:border-success-100",
  purple: "border-purple-400 dark:border-purple-100",
  amber: "border-amber-400 dark:border-amber-100",
};

const iconColors = {
  primary: "text-primary-500",
  blue: "text-primary-500",
  secondary: "text-secondary-500",
  gray: "text-gray-500",
  darkGray: "text-gray-500",
  destructive: "text-destructive-500",
  error: "text-destructive-500",
  warning: "text-warning-500",
  yellow: "text-warning-500",
  success: "text-success-500",
  green: "text-success-500",
  purple: "text-purple-500",
  amber: "text-amber-500",
};

const constants = {
  types,
  sizes,
  textSize,
  textTypes,
  textColors,
  borderColors,
  iconColors,
};

export default constants;
