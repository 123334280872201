import React from "react";
import useGetPersonalCoupon from "API/queries/user/userGetPersonalCoupons";
import {Button, ButtonGroup, SubSection, Trans, Typography} from "Components";
import {accountSubSectionTitleProps} from "routes/Account/Account";
import useGetUserReferrals from "API/queries/coupon/useGetUserReferrals";

const AccountCreditsPersonalCode = ({
  showTitle = true,
  showSubTitle = true,
  orientation = "auto",
}) => {
  const [coupon] = useGetPersonalCoupon();
  const [copied, setCopied] = React.useState(false);
  const [referrals, loading] = useGetUserReferrals();

  if (!coupon) return null;

  const referredCount = referrals?.length || 0;
  const creditsEarned = referredCount * 50;

  return (
    <SubSection
      orientation={orientation}
      title={showTitle && <Trans>Your personal code</Trans>}
      subTitle={
        showSubTitle && (
          <Trans>Share with a friend and you both receive 50 SEK.</Trans>
        )
      }
    >
      <div className="flex gap-4 items-center">
        <ButtonGroup>
          <Button disabled>
            <Typography color="text-gray-700">{coupon}</Typography>
          </Button>
          <Button
            iconLeft={copied ? "far fa-circle-check" : "far fa-clone"}
            onClick={() => {
              navigator.clipboard.writeText(coupon);
              setCopied(true);
            }}
          >
            {copied ? <Trans>Copied</Trans> : <Trans>Copy</Trans>}
          </Button>
        </ButtonGroup>
        {referredCount > 0 && (
          <Trans referredCount={referredCount} creditsEarned={creditsEarned}>
            You have invited {{referredCount}} people and earned{" "}
            {{creditsEarned}} credits.
          </Trans>
        )}
      </div>
    </SubSection>
  );
};

export default AccountCreditsPersonalCode;
