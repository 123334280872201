import React from "react";
import {Trans, Typography} from "Components";
import useGetCoupon from "API/queries/coupon/useGetCoupon";
import i18n from "i18next";

const AccountCreditsTransactionsTableDescription = ({transaction}) => {
  if (transaction.relatedObjectType === "Coupon") {
    return (
      <Coupon
        id={transaction.relatedObjectId}
        transactionDescription={transaction.description}
      />
    );
  }
  return <DefaultHandler description={transaction.description} />;
};

const Coupon = ({id, transactionDescription}) => {
  const [coupon, loading] = useGetCoupon({id}, false, false);

  if (loading) return <Typography>-</Typography>;

  const couponName = coupon?.name;

  // If coupon is null it's because there was no issuerName on the coupon because Friend coupons don't have issuerName
  if (coupon == null || (couponName?.includes("KOL-") && !coupon.issuerName)) {
    // Then it's a friend referral coupon
    return <DefaultHandler description={transactionDescription} />;
  }

  const issuerName = coupon.issuerName;
  return <Trans issuerName={issuerName}>Coupon from {{issuerName}}</Trans>;
};

const DefaultHandler = ({description}) => {
  return (
    <Typography type="tmd">
      {descriptionsTranslations[description] || description}
    </Typography>
  );
};

const descriptionsTranslations = {
  friend_coupon_referred: i18n.t("Shared personal coupon"),
  friend_coupon_received: i18n.t("Friend coupon received"),
  course_review_reward: i18n.t("Course review"),
  subscription_invoice_credits_deduced: i18n.t("Subscription invoice"),
  credit_bundle_purchase: i18n.t("Purchased credits"),
};

export default AccountCreditsTransactionsTableDescription;
