import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GET_EXERCISE_TAG_SUGGESTIONS } from "API/queries/exercise/useGetExerciseTagSuggestions";
import { GET_EXERCISE } from "API/queries/exercise/useGetExercise";

const REMOVE_CATEGORISATION = gql`
  mutation removeCategorisation($exerciseId: ID!, $tagId: ID!) {
    removeCategorisation(exercise_id: $exerciseId, tag_id: $tagId) {
      ok
      error
    }
  }
`;

const useRemoveCategorisationMutation = (exerciseId, courseId) => {
  const [removeCategorisation, { loading, data, error }] = useMutation(
    REMOVE_CATEGORISATION,
    {
      refetchQueries: [
        {
          query: GET_EXERCISE_TAG_SUGGESTIONS,
          variables: { exerciseId, courseId, loggedIn: true },
        },
        {
          query: GET_EXERCISE,
          variables: {
            id: exerciseId,
            withContent: false,
            withModule: false,
            courseId,
          },
        },
      ],
    }
  );

  return [removeCategorisation, loading];
};

export default useRemoveCategorisationMutation;
