import React from "react";
import {Button, Trans} from "Components";

const CourseSidebarToggle = ({collapsed, toggleCollapsed}) => {
  return (
    <div
      className="flex justify-between items-center surface px-4 -mx-2 mb-4 pb-8 py-2 gap-2 relative group  cursor-pointer"
      onClick={toggleCollapsed}
    >
      {!collapsed && (
        <img
          src="https://d1mgntrf3vaj6d.cloudfront.net/KOLLIN+Logo.svg"
          className="h-3 grayscale opacity-75"
        />
      )}

      <Button
        size="md"
        type="link"
        icon={collapsed ? "chevrons-right" : "chevrons-left"}
        iconColor="text-gray-light"
        hint={
          collapsed ? (
            <Trans>Expand sidebar</Trans>
          ) : (
            <Trans>Collapse sidebar</Trans>
          )
        }
      />
    </div>
  );
};

export default CourseSidebarToggle;
