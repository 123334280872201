import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Alert, Button, SafeText, Trans} from "Components";
import React from "react";
import {UserContext} from "UserContextProvider";
import useCreateStripeIntentForm from "Widgets/Payment/Stripe/CreateStripeIntent/CreateStripeIntentForm/useCreateStripeIntentForm";

const CreateStripeIntentForm = ({
  loading: parentLoading,
  onComplete,
  submitPrompt,
}) => {
  const {
    errorMessage,
    handleSubmit,
    loading: submitLoading,
    message,
  } = useCreateStripeIntentForm({onComplete});

  const loading = parentLoading || submitLoading;

  return (
    <form onSubmit={handleSubmit} className="subscription-trial-checkout-form">
      <PaymentElement
        options={{
          readOnly: loading,
          fields: {
            // billingDetails: {
            //   address: "never", // don't show address fields
            // },
          },
        }}
      />

      {errorMessage && (
        <Alert title={errorMessage} type="error" className="mt-4" />
      )}
      {message && <Alert title={message} type="success" className="mt-4" />}

      <Button
        type="primary"
        size="md"
        className="mt-4"
        fluid
        onClick={handleSubmit}
        loading={loading}
      >
        <SafeText>{submitPrompt}</SafeText>
      </Button>
    </form>
  );
};

export default CreateStripeIntentForm;
