import React from "react";
import classnames from "classnames";
import {Typography} from "Components/_Elements/index";

const InputErrorMessage = ({error, errorMessageRelative}) => {
  if (!error) return null;

  return (
    <div className="flex justify-end">
      <Typography
        type="tsm"
        color="text-error-light"
        className={classnames("mt-1.5")}
      >
        {error}
      </Typography>
    </div>
  );
};

export default InputErrorMessage;
