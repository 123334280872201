import React from "react";
import useGetExerciseBookmarked from "../../../API/queries/bookmark/useGetExerciseBookmarked";
import {Checkbox, Icon, Lottie, message} from "../../_Elements";
import useToggleBookmarkedMutation from "../../../API/mutations/bookmark/useToggleBookmarkedMutation";
import {UserContext} from "../../../UserContextProvider";
import {useTranslation} from "react-i18next";
import Badge from "../../_Elements/Badge/Badge";
import Trans from "../../Trans";

const ExercisePlaylists = ({
  exerciseId,
  setCount = () => {},
  activeLabel = <Trans>Bookmarks</Trans>,
  inactiveLabel = <Trans>Bookmarks</Trans>,
}) => {
  const {t: trans} = useTranslation();
  const {user} = React.useContext(UserContext);
  const isBookmarked = useGetExerciseBookmarked(exerciseId);
  const [mutate, mutationLoading] = useToggleBookmarkedMutation(exerciseId);

  React.useEffect(() => {
    if (isBookmarked) {
      setCount(1);
    } else {
      setCount(0);
    }
  }, [isBookmarked]);

  const onClick = async () => {
    const content = isBookmarked
      ? trans("Bookmarked removed")
      : trans("Bookmarked exercise");
    const response = await mutate();

    message.success(content);
  };

  if (user == null) return null;

  return (
    <div className="flex justify-between items-center gap-4 cursor-pointer p-2 py-3 transition-colors duration-200 ease-in-out">
      {/*<Trans weight="sb">Bookmarks</Trans>*/}
      <Checkbox
        onChange={onClick}
        label={isBookmarked ? activeLabel : inactiveLabel}
        checked={isBookmarked}
        disabled={mutationLoading}
      />
      {/*<Icon*/}
      {/*  icon={isBookmarked ? "circle-check" : "circle"}*/}
      {/*  color={isBookmarked ? "text-success-500" : "text-gray-400"}*/}
      {/*/>*/}
      {isBookmarked && (
        <span className="scale-150">
          <React.Suspense fallback={<></>}>
            <Lottie animation="bookmark" loop={false} />
          </React.Suspense>
        </span>
      )}
    </div>
  );
};

export default ExercisePlaylists;
