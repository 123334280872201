import React from "react";

import {isMobile} from "react-device-detect";
import "./ExerciseViewHeader.scss";
import {useTranslation} from "react-i18next";
import {ErrorBoundary, Icon, Link, Typography} from "Components";

import ExerciseViewHeaderTextModeToggle from "Components/Workboard/ExerciseView/ExerciseViewHeader/ExerciseViewHeaderTextModeToggle";
import ExerciseViewToolbarBookmarkButton from "../ExerciseViewToolbar/ExerciseViewToolbarBookmarkButton";

const ExerciseViewHeader = ({exercise, loading}) => {
  const {t: trans} = useTranslation();
  const {courseId} = React.useContext(window.CourseContext);

  let checkIcon = "far fa-circle";
  if (exercise?.selfEvaluation > 0) {
    checkIcon = "fas fa-check-circle";
  }
  const exerciseAbbrv = trans("Ex");
  let author = exercise?.source?.author || trans("Unknown author");

  if (loading) {
    author = "....";
  }

  let score = exercise?.score || 0;
  let number = exercise?.number | 0;
  let date = exercise?.source?.date || "YYYY-MM-DD";
  const examDate = new Date(date.substring(0, 10));
  const examDateFormatted = examDate.toLocaleDateString("sv-SE", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  // On mobile, we don't show the header because it's too much information and we want to save space
  if (isMobile) {
    return (
      <div className="flex justify-end items-start gap-2">
        <ErrorBoundary>
          <ExerciseViewToolbarBookmarkButton exerciseId={exercise.id} />
        </ErrorBoundary>
        <ExerciseViewHeaderTextModeToggle />
      </div>
    );
  }

  return (
    <div className="exercise-view-header p-3">
      <div className="flex justify-between items-center ">
        <div className="flex justify-between">
          <div className="flex gap-2 pl-2 ">
            <Typography type="sm" weight="md" color="text-gray-500" inline>
              {exerciseAbbrv} {number}
            </Typography>
            <Icon
              icon="fa-solid fa-circle-small"
              size="xs"
              color="text-gray-500"
            />
            <Link
              asButton
              inline
              type="link"
              to={`/course/${courseId}/exam-exercises/${exercise.source.id}?dir=asc&exercise=${exercise.id}&sort=number&displayMode=list`}
            >
              {examDateFormatted}
            </Link>
            <Icon
              icon="fa-solid fa-circle-small"
              size="xs"
              color="text-gray-500"
            />

            <Typography type="md" weight="sb" color="text-gray-500" inline>
              {author}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExerciseViewHeader;
