import React from "react";
import PropTypes from "prop-types";
import {motion} from "framer-motion";
import classnames from "classnames";

const Toggle = ({
  defaultChecked = false,
  label,
  onChange,
  name,
  size,
  disabled = false,
  ...props
}) => {
  const [checked, setChecked] = React.useState(defaultChecked);

  React.useEffect(() => {
    // Only call it if the value has changed from the defaultChecked prop
    if (checked !== defaultChecked) {
      onChange(checked);
    }
  }, [checked, defaultChecked]);

  return (
    <div
      onClick={() => {
        setChecked(!checked);
      }}
    >
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className="hidden"
        disabled={disabled}
      />
      <label
        htmlFor={name}
        className={classnames("toggle-base group", {
          "toggle-base-disabled": disabled,
        })}
      >
        <motion.span
          className="toggle-switch-container"
          animate={{
            // TODO: Use tailwind to get the correct color for the toggle switch
            backgroundColor: checked ? "#586FB5" : "#EAECF0",
          }}
        >
          <motion.span
            className="toggle-switch"
            initial={{scale: 0.5, opacity: 0, left: 4}}
            animate={{
              scale: 1,
              opacity: 1,
              left: checked ? 16 : 4,
            }}
          />
        </motion.span>
        {label && <span className="toggle-label">{label}</span>}
      </label>
    </div>
  );
};

Toggle.propTypes = {
  defaultChecked: PropTypes.bool,
  label: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Toggle;
