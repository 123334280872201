import React from "react";
import useGetProgramCourses from "API/queries/course/useGetProgramCourses";
import DashboardCoursesProgramEmptyState from "routes/Dashboard/DashboardCourses/DashboardCoursesProgram/DashboardCoursesProgramEmptyState";
import SkeletonList from "Components/_Elements/Skeleton/SkeletonList";
import groupCourseListByYear from "utilities/groupCourseListByYear";
import {CourseCard, Section, Trans} from "Components";
import {groupBy} from "utilities/objects";
import {Typography} from "Components";

const GroupCoursesListByYear = ({programId}) => {
  const [programCourses, programCoursesLoading] = useGetProgramCourses({
    programId,
    includeSchool: true,
    includeCurrentUserData: false,
    includeEmptyCourses: true,
  });

  const programCoursesByYear = React.useMemo(
    () => groupCourseListByYear(programCourses),
    [programCourses]
  );

  if (programCoursesLoading) return <SkeletonList count={5} />;

  if (!programCoursesLoading && programCourses.length === 0)
    return <DashboardCoursesProgramEmptyState />;

  return (
    <div className="mt-4 p-2 md:p-4">
      {Object.keys(programCoursesByYear).map((year, i) => {
        // Group by period

        const coursesByPeriod = groupBy(programCoursesByYear[year], "period");

        return (
          <Section
            secondary
            key={i}
            title={<Trans year={year}>Year {{year}}</Trans>}
          >
            <div className="md:grid md:grid-cols-4 gap-4 w-full max-w-screen">
              {[1, 2, 3, 4].map((period, index) => {
                const periodCourses = coursesByPeriod[period];

                return (
                  <div className="w-full odd:surface rounded-xl flex grow flex-col gap-2 items-start p-4 overflow-scroll">
                    <Typography type="tmd" weight="b" color="text-gray-light">
                      P{period}
                    </Typography>

                    {periodCourses &&
                      periodCourses.map((programCourse, index) => {
                        if (programCourse.courseData)
                          return (
                            <CourseCard
                              {...programCourse.courseData}
                              showPurchaseInfo={false}
                              showActiveBadge={false}
                              showIcon={false}
                              className="bg-primary-25 border-primary "
                            />
                          );

                        return (
                          // For empty Courses:
                          <CourseCard
                            onClick={() => {}}
                            showPurchaseInfo={false}
                            showActiveBadge={false}
                            name={programCourse?.courseName}
                            courseName={programCourse?.courseName}
                            code={programCourse?.courseCode}
                            showIcon={false}
                          />
                        );
                      })}
                  </div>
                );
              })}
            </div>
          </Section>
        );
      })}
    </div>
  );
};

export default GroupCoursesListByYear;
