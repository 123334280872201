import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const QUERY = gql`
  query {
    summer2022CampaingProgramMappingCollection {
      items {
        sys {
          id
        }
        mapping
      }
    }
  }
`;

const useGetSummer2022CampaignProgramMapping = id => {
  let result = {};

  const { loading, error, data } = useQuery(QUERY, {
    context: {
      api: "contentful",
    },
  });

  if (error) {
    console.log("Error in useGetSummer2022CampaignProgramMapping", error);
    result = null;
  }

  if (data && data.summer2022CampaingProgramMappingCollection) {
    result = data.summer2022CampaingProgramMappingCollection.items[0].mapping;
  } else {
    // Fallback if the data is not returned. We store a JSON snapshot so at least we can show something
  }

  return [result, loading];
};

export default useGetSummer2022CampaignProgramMapping;
