import React from "react";
import {BreadCrumb, Skeleton, Typography} from "Components";

import {removeEscapedText} from "../../../utilities/text";

const CourseTagHeader = ({tag, loading}) => {
  if (loading) {
    return <Skeleton size="lg" />;
  }

  return (
    <div className="w-full flex justify-between -mr-4 -ml-4 pl-4 -pr-4">
      <div className="flex grow flex-col justify-between gap-1 pb-2">
        <Typography type="dxs" weight="md">
          {removeEscapedText(tag.name)}
        </Typography>
        {/*  Add actions here if needed*/}
        <BreadCrumb />
      </div>
      <div id="action-pane-trigger-container"></div>
    </div>
  );
};

export default CourseTagHeader;
