import React from "react";
import useGetCurrentRoute from "utilities/hooks/course/useGetCurrentRoute";
import {courseRoutes} from "routes/Course/courseRoutesList";
import {CourseParamsContext} from "routes/Course/CourseRouter";

const useCurrentParams = () => {
  const {currentParams} = useGetCurrentRoute(courseRoutes);
  const {...urlParams} = React.useContext(CourseParamsContext);

  const examId = currentParams.examId || urlParams?.exam || null;
  const moduleId = currentParams.moduleId || urlParams?.module || null;
  const tagId = currentParams.tagId || urlParams?.tag || null;
  const exerciseId = currentParams.exerciseId || urlParams?.exercise || null;
  const chapterId = currentParams.chapterId || urlParams?.chapter || null;

  return {
    examId,
    moduleId,
    tagId,
    exerciseId,
    chapterId,
  };
};

export default useCurrentParams;
