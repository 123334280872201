import React from "react";
import {Button, Trans} from "Components";
import {GPTContext} from "./GPTProvider";

const GPTActionsReset = ({className}) => {
  const {resetConversation} = React.useContext(GPTContext);

  return (
    <Button
      size="sm"
      type="gray"
      onClick={resetConversation}
      icon="power-off"
      iconColor="text-error"
      hint={<Trans>End conversation</Trans>}
      className={className}
    />
  );
};

export default GPTActionsReset;
