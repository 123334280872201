import { useQuery } from "@apollo/react-hooks";

import gql from "graphql-tag";

export const GET_AUTHORS = gql`
  query getAllAuthors {
    resourcesAuthors
  }
`;

const useGetAllExamAuthors = () => {
  const { data, loading, error } = useQuery(GET_AUTHORS);

  let authors = data?.resourcesAuthors || [];

  return [authors, loading];
};

export default useGetAllExamAuthors;
