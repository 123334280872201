import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_EXERCISE_THREADS = gql`
  query($exerciseId: ID!) {
    getExerciseThreads(exercise_id: $exerciseId) {
      id
      solved
      created_at
      exercise {
        id
      }
      comments {
        id
        html
        votes
        accepted
        created_at
        asker {
          id
          avatar
          first_name
          last_name
          firstName
          lastName
          admin
          assistant
          enrollmentYear
          program {
            name
          }
        }
        comments {
          id
          html
          votes
          accepted
          created_at
          asker {
            id
            avatar
            first_name
            firstName
            last_name
            lastName
            admin
            assistant
            premium
            enrollmentYear
            program {
              name
            }
          }
          created_at
        }
      }
      exercise {
        id
        number
        score
        source {
          id
          date
        }
        tags {
          name
        }
      }
      comments_count
    }
  }
`;

const useGetExerciseThreads = exerciseId => {
  let threads = [];

  const { loading, error, data } = useQuery(GET_EXERCISE_THREADS, {
    variables: { exerciseId },
  });

  if (error) {
    console.log("error at useGetExerciseThreads ", error);
  }

  if (data) {
    threads = data.getExerciseThreads.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  }

  return { threads, loading };
};

export default useGetExerciseThreads;
