import React from "react";

const isEmpty = value => value === null || value === "";

const useInputCode = ({digits, onChange, onlyNumbers}) => {
  const [currentFocusIndex, setCurrentFocusIndex] = React.useState(0);

  const initialCode = Array(digits).fill(null);

  const [code, setCode] = React.useState(initialCode);

  /**
   *  Handle change on input
   * @param value
   * @param index
   */
  const handleChange = (index, e) => {
    const previousValue = code[index];

    const newValue = e.target.value?.split("")?.pop() || "";

    setCode(
      code.map((c, i) => (i === index ? cleanInput(newValue, onlyNumbers) : c))
    );
    // Auto focus on next input if the value was not empty and the input is not the last one

    handleAutoFocus(index, previousValue, newValue);
  };

  const handleAutoFocus = (index, previousValue, newValue) => {
    const nextIndex = Math.min(index + 1, digits - 1);
    const previousIndex = Math.max(index - 1, 0);

    /**
     * Cases:
     * ----------------
     * A. previousValue is empty
     * ----------------
     * " " -> "1" => Focus on next input
     * " " -> " " => Focus on previous input double backspace
     *
     * ----------------
     * B. previousValue is not empty
     * ----------------
     * "1" -> " " => Stay on the same input
     * "1" -> "2" => Focus on next input
     */

    if (isEmpty(previousValue)) {
      if (isEmpty(newValue)) {
        setCurrentFocusIndex(previousIndex);
      } else {
        setCurrentFocusIndex(nextIndex);
      }
      return;
    }

    if (!isEmpty(previousValue)) {
      if (isEmpty(newValue)) {
        //nothing
        return;
      }

      // Focus on next input
      setCurrentFocusIndex(nextIndex);
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedCode = e.clipboardData.getData("text");
    const newCode = pastedCode.split("").slice(0, digits);
    setCode(newCode);
  };

  // Call onChange function if the code is complete
  React.useEffect(() => {
    const isComplete = code.every(c => !isEmpty(c)) && code.length === digits;
    if (isComplete && onChange) {
      onChange(code.join(""));
    }
  }, [code]);

  return {
    code,
    currentFocusIndex,
    handleChange,
    handlePaste,
    setCurrentFocusIndex,
  };
};

const cleanInput = (value, onlyNumbers) => {
  if (onlyNumbers) {
    return value.replace(/\D/g, "");
  }
  return value;
};

export default useInputCode;
