import React from "react";
import {Button, File, Icon, Trans} from "Components";
import GroupAdminUploadFileDropZone from "routes/Group/GroupAdminUpload/GroupAdminUploadFile/GroupAdminUploadFileDropZone";
import useGroupAdminUploadFile from "routes/Group/GroupAdminUpload/GroupAdminUploadFile/useGroupAdminUploadFile";

const GroupAdminUploadFile = ({
  handleNextStep,
  children,
  setFileMembers,
  clearFileMembers,
  currentStep,
}) => {
  const {file, setFile, removeFile} = useGroupAdminUploadFile({
    setFileMembers,
    clearFileMembers,
    currentStep,
  });

  return (
    <div className="max-w-2xl flex flex-col gap-4 justify-center rounded-lg surface p-4 rounded-lg ">
      {!file ? (
        <GroupAdminUploadFileDropZone setFile={setFile} />
      ) : (
        <div>
          {file && (
            <div>
              <Trans type="tlg" weight="sb" color="text-gray-light">
                Your uploaded file
              </Trans>
              <div className="w-96 flex flex-col gap-2 items-end mt-2">
                <div className="w-full flex gap-2 items-center">
                  <File
                    fluid
                    name={file.name}
                    size={file.size}
                    icon={
                      <Icon
                        icon="circle-check"
                        size="lg"
                        color="text-success-light"
                      />
                    }
                    storedInCloud={false}
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <Button size="sm" type="destructive" onClick={removeFile}>
                    <Trans>Remove</Trans>
                  </Button>
                  <Button size="sm" type="gray" onClick={removeFile}>
                    <Trans>Replace</Trans>
                  </Button>
                </div>
              </div>
              {children}
              <div className="flex justify-end mt-2">
                <Button onClick={handleNextStep} type="primary">
                  <Trans>Next step</Trans>
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GroupAdminUploadFile;
