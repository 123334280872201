import React from "react";
import {MAX_TOKENS} from "Components/GPT/hooks/useGPTRequest/useGPTRequest";

const useGPTSettings = () => {
  const [settings, setSettings] = React.useState({
    maxTokens: 500,
    includeExerciseText: true,
    includeSolutionText: true,
  });

  const setMaxTokens = maxTokens => {
    setSettings({...settings, maxTokens});
  };

  const setIncludeExerciseText = includeExerciseText => {
    setSettings({...settings, includeExerciseText});
  };

  const setIncludeSolutionText = includeSolutionText => {
    setSettings({...settings, includeSolutionText});
  };

  return {
    settings,
    setMaxTokens,
    setIncludeExerciseText,
    setIncludeSolutionText,
  };
};

export default useGPTSettings;
