import React from "react";
import {message, Typography} from "Components";

import useActivateUserCourse from "API/mutations/user/useActivateUserCourse";
import {useTranslation} from "react-i18next";
import useUpdateUserCourse from "API/mutations/user/useUpdateUserCourse";

const UseCourseCardMenu = ({courseId, active, archived, purchased}) => {
  const {t: trans} = useTranslation();

  // Activate course
  const onCompletedActivate = data =>
    message.success(trans("The course has been activated"));

  const {activateCourseForCurrentUser} = useActivateUserCourse(
    onCompletedActivate,
    courseId
  );

  // Course update: when archived/unarchived
  const onCompletedUpdate = data =>
    message.success(trans("The course has been updated"));

  const {updateUserCourse} = useUpdateUserCourse(onCompletedUpdate);

  const menuOptions = [];

  if (purchased) {
    if (!active) {
      menuOptions.push({
        name: "activate",
        label: <Typography>Activate</Typography>,
        onClick: activateCourseForCurrentUser,
      });
    }

    if (!archived) {
      menuOptions.push({
        name: "archive",
        label: <Typography>Mark as completed</Typography>,
        onClick: () =>
          updateUserCourse({
            variables: {
              courseId,
              archiveCourse: true,
            },
          }),
      });
    }

    if (archived) {
      menuOptions.push({
        name: "unarchive",
        label: <Typography>Mark as incompleted</Typography>,
        onClick: () =>
          updateUserCourse({
            variables: {
              courseId,
              archiveCourse: false,
            },
          }),
      });
    }
  }

  return {
    menuOptions,
  };
};
export default UseCourseCardMenu;
