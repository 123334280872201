import React from "react";
import {useLocation} from "react-router-dom";
import {useWindowSize} from "utilities/hooks/useWindowSize";
import useLocalStorageState from "utilities/hooks/useLocalStorageState";
import useDocumentTitle from "utilities/hooks/useDocumentTitle";
import {useTranslation} from "react-i18next";
import useGetCurrentRoute from "../../../utilities/hooks/course/useGetCurrentRoute";

const useCourseSidebarState = routes => {
  const {t} = useTranslation();
  const {name: courseName} = React.useContext(window.CourseContext);
  const {pathname} = useLocation();
  const {currentPath, currentRoute} = useGetCurrentRoute(routes);

  // Set the document title based on the current route

  useDocumentTitle(`${getRouteTitle(currentRoute, t)} ${courseName}`, [
    currentRoute,
  ]);

  const currentPathHideSidebar = React.useMemo(
    () => currentRoute?.hideSidebar || false,
    [currentRoute]
  );

  const [windowWidth] = useWindowSize();

  const [collapsed, setCollapsed] = useLocalStorageState(
    "COURSE_SIDEBAR_COLLAPSED",
    0
  );

  const toggleCollapsed = () => setCollapsed(prev => (prev === 0 ? 1 : 0));

  React.useEffect(() => {
    if (windowWidth < 720) {
      setCollapsed(1);
    }
  }, [windowWidth]);

  return {
    currentPath,
    currentPathHideSidebar,
    collapsed,
    toggleCollapsed,
    currentRoute,
  };
};

const getRouteTitle = (currentRoute, t) => {
  let title;
  try {
    title = currentRoute?.label?.props.children;

    return t(title) + " | ";
  } catch (e) {
    console.log(e);
  }

  return "";
};

export default useCourseSidebarState;
