import initSentry from "Plugins/Sentry";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {ApolloProvider} from "@apollo/react-hooks";
import client from "./client";
import "./tailwind.css";
import {createBrowserHistory} from "history";
import "./i18n";

require("dotenv").config();

const history = createBrowserHistory();

initSentry(history);

window.triggerIntercom = () => {
  // find intercom-launcher
  const intercomLauncher = document.querySelector(".intercom-launcher");
  if (intercomLauncher) {
    // trigger click event
    intercomLauncher.click();
  }
};

window.debug = (...args) => {
  if (process.env.NODE_ENV === "development") {
    console.log(...args);
  }
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <App history={history} />
  </ApolloProvider>,
  document.getElementById("root")
);
