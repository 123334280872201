import React from "react";
import {useGroupContext} from "../../../Providers/GroupProvider/GroupProvider";
import useGetProgramCourses from "../../../API/queries/course/useGetProgramCourses";

const useGroupHome = () => {
  const group = useGroupContext();

  const getGroupCourses = () => {
    const groups = React.useMemo(() => {
      if (group.subGroups.length > 0) {
        if (group?.program?.id) {
          return [group, ...group.subGroups];
        }
        return group.subGroups;
      }
      return [group];
    }, [group?.id]);

    let allGroupsProgramCourses = [];
    groups.forEach(group => {
      //     add programCourses to group
      const [programCourses, loading] = useGetProgramCourses({
        programId: group.program?.id,
        includeSchool: true,
        includeCurrentUserData: false,
        includeEmptyCourses: false,
      });

      if (!loading) {
        allGroupsProgramCourses.push(programCourses);
      }
    });

    const flattenedProgramCourses = allGroupsProgramCourses.flat();
    const uniqueProgramCourses = [
      ...new Set(flattenedProgramCourses.map(course => course.courseData.id)),
    ];
    return uniqueProgramCourses;
  };

  const getGroupAllMembers = () => {
    let allMembers = [];
    if (group.subGroups.length > 0) {
      allMembers = group.subGroups.flatMap(subGroup => subGroup.members);
    }
    allMembers = [...allMembers, ...group.members];
    return [...new Set(allMembers)];
  };

  return {getGroupCourses, getGroupAllMembers};
};

export default useGroupHome;
