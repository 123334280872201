import React from "react";
import {Link as ReactRouterLink} from "react-router-dom";
import {Button} from "Components";
import classnames from "classnames";

const Link = ({
  type,
  buttonType,
  disabled,
  inverted,
  inline,
  danger,
  children,
  id,
  className,
  size = "sm",
  iconRight,
  iconLeft,
  icon,
  iconColor,
  basic,
  fluid,
  asButton,
  highlightSelected = true,
  trackParams,
  ...restOfProps
}) => {
  const isCurrentPath =
    restOfProps.to === window.location.pathname + window.location.search;

  const HTMLTag = restOfProps.href ? "a" : ReactRouterLink;

  restOfProps.active = restOfProps.href ? isCurrentPath.toString() : undefined;

  return (
    <HTMLTag
      id={id}
      {...restOfProps}
      className={classnames({
        "w-full": fluid,
      })}
    >
      {basic ? (
        children
      ) : (
        <Button
          inline={inline}
          fluid={fluid}
          type={buttonType || "link"}
          size={size}
          disabled={disabled}
          className={classnames(className)}
          active={isCurrentPath && highlightSelected}
          inverted={inverted}
          iconRight={iconRight}
          iconLeft={iconLeft}
          icon={icon}
          trackParams={trackParams}
          iconColor={iconColor}
        >
          {children}
        </Button>
      )}
      {}
    </HTMLTag>
  );
};

export default Link;
