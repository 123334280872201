import React from "react";
import useCreateExercise from "API/mutations/exercise/useCreateExercise";
import useCreateSolution from "API/mutations/solution/useCreateSolution";
import {useTranslation} from "react-i18next";
import useUpdateExercise from "API/mutations/exercise/useUpdateExercise";
import useUpdateSolution from "API/mutations/solution/useUpdateSolution";

export const ExamViewContext = React.createContext(null);

const useExamViewContext = (exam, refetch, showSolutions) => {
  // Get the exam data from graphql including the exercises
  const {t: trans} = useTranslation();

  const getSourceImageUrl = React.useCallback(
    (imageUrl, pdfUrl) => {
      if (imageUrl === null) return pdfUrl?.replaceAll("pdf", "png");
      return imageUrl;
    },
    [exam]
  );

  // The props that are passed to the Segmentation component
  let examViewContextValues = {
    refetchExam: refetch,
    exercises: exam.exercises,
    sourceId: exam.id,
    blacklistedText: [],
    // Mutable props depend on the state of the component
    pdfUrl: exam?.exam_exercises_src + "?q=exam_exercises_src",
    sourceImageUrl: getSourceImageUrl(
      exam.exercises_src,
      exam.exam_exercises_src
    ),
    initialSegments: exam.exercises,
    createMutationHook: useCreateExercise,
    createMutationFunction: "createExercise",
    updateMutationHook: useUpdateExercise,
    updateMutationFunction: "updateExercise",
    label: trans("Exercise"),
    labelPlural: trans("Exercises"),
    returnObjectName: "exercise",
    documentType: "exam", // Used to get the historical data
  };

  if (showSolutions) {
    examViewContextValues = {
      ...examViewContextValues,
      pdfUrl: exam?.exam_solutions_src + "?q=exam_solutions_src",
      sourceImageUrl: getSourceImageUrl(
        exam.solutions_src,
        exam.exam_solutions_src
      ),
      initialSegments: exam.exercises?.map(exercise =>
        exercise.solution
          ? {
              ...exercise.solution,
              number: exercise.number,
            }
          : null
      ),
      /**
       * Include the exercise text as blacklisted words when creating solutions
       * This is to prevent that the exercise text is included when auto-marking solutions
       */
      blacklistedText: exam.exercises.map(exercise => exercise?.text || ""),
      createMutationHook: useCreateSolution,
      createMutationFunction: "createSolution",
      updateMutationHook: useUpdateSolution,
      updateMutationFunction: "updateSolution",
      label: trans("Solution"),
      labelPlural: trans("Solutions"),
      returnObjectName: "solution",
      documentType: "exam_solutions", // Used to get the historical data
    };
  }

  return examViewContextValues;
};

export default useExamViewContext;
