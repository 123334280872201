import React from "react";
import CourseContributions from "Widgets/Course/CourseContributions/CourseContributions";

const CourseSandbox = () => {
  return (
    <div className="flex gap-4 items-start">
      <CourseContributions />
    </div>
  );
};

export default CourseSandbox;
