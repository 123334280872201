import React from "react";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";

const REQUEST_OTP = gql`
  mutation RequestOTP($email: String!, $action: OTPAction!) {
    requestOTP(email: $email, action: $action) {
      ok
      error {
        code
        message
      }
    }
  }
`;

const useRequestOTP = action => {
  const [requestOTP, {loading, error, data}] = useMutation(REQUEST_OTP);

  const response = data?.requestOTP || {};

  return {
    requestOTP,
    loading,
    error,
    response,
  };
};

export default useRequestOTP;
