import React from "react";
import moment from "moment";

/**
 * A hook for handling form validation
 * @param initialState {object} The initial state of the form
 * @param action {function} The function to call when the form is submitted
 * @param validator {function} The function to call to validate the form - This function receives the values at submit and should return an object with the errors where each key is the name of the field and the value is the error message
 * @param dateFormat  {string} The date format to use for date fields (defaults to YYYY-MM-DD)
 * @param transformValuesBeforeSubmit  {function}  (Optional) A function to transform the values before submitting them, e.g. merge or split fields
 * @returns {{setFormValue: setFormValue, submit: ((function(*): Promise<void>)|*), setValues: (value: unknown) => void, handleChange: handleChange, values: unknown, onDateChange: onDateChange, setErrors: (value: (((prevState: {}) => {}) | {})) => void, loading: boolean, handleListValueChange: handleListValueChange, errors: {}, handleLowercaseInputChange: handleLowercaseInputChange}}
 */
export const useFormValidation = (
  initialState,
  action,
  validator,
  dateFormat = "YYYY-MM-DD",
  transformValuesBeforeSubmit = null
) => {
  const [values, setValues] = React.useState({...initialState});
  const valuesRef = React.useRef({...initialState});
  const [loading, setLoading] = React.useState(false);

  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    // Reset form values to new values if the initial state changes
    setValues({...initialState});
  }, [JSON.stringify(initialState)]);

  // Update the ref to the current values
  React.useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  /**
   *  A flexible function to set the values of the state whenever a change occurs
   * @param obj - An object with the values to be set {name: value}
   */
  const setFormValue = obj => {
    setValues(prev => ({...prev, ...obj}));
    setErrors({});
  };

  /**
   * function for changing values of input fields onChange callback
   * @param event - The event object
   */
  const handleChange = event => {
    const updatedValues = {
      [event.target.name]: event.target.value,
    };

    setValues(prev => ({...prev, ...updatedValues}));
    setErrors({});
  };

  // Function for handling inputs that enforce lowercase text:
  const handleLowercaseInputChange = event => {
    const updatedValues = {
      [event.target.name]: event.target.value.toLowerCase(),
    };

    setValues(prev => ({
      ...prev,
      ...updatedValues,
    }));

    setErrors({});
  };

  // A flexible function for handling list values changing (i.e. for checkboxes)
  const handleListValueChange = (name, value) => {
    let list = [...values[name]];
    let idx = list.indexOf(value);
    if (idx == -1) {
      // The element is not in the list, so we need to add it:
      list.push(value);
    } else {
      // The element is already in the list so we remove it
      list.splice(idx, 1);
    }

    setValues({...values, [name]: list});
    setErrors({});
  };

  const onDateChange = date => {
    // If the date is a moment object we need to format it to a string
    if (moment.isMoment(date)) {
      setFormValue({
        date: date.format(dateFormat),
      });
      return;
    }
    setFormValue({
      date,
    });
  };

  // TODO: Fix this function, it takes the old values from the state instead of the latest ones
  const submit = async e => {
    if (e) e.preventDefault();
    setLoading(true);
    // if there is a validator defined we can check if the input data is correct
    if (validator) {
      const validationResult = await validator(values);
      // If the validation failed:
      if (Object.keys(validationResult).length !== 0) {
        setLoading(false);
        setErrors(validationResult);
        return;
      }
    }

    let variables = {...valuesRef.current};

    if (
      transformValuesBeforeSubmit !== null &&
      typeof transformValuesBeforeSubmit === "function"
    ) {
      variables = transformValuesBeforeSubmit(variables);
    }

    // Validation has passed:
    // The action has to be a graphql mutation but can be undefined/null
    if (action) {
      action({
        variables,
      });
    }

    setLoading(false);
  };

  return {
    values,
    setValues, // DO NOT USE THIS UNLESS YOU WANT TO RESET THE FORM
    setFormValue,
    errors,
    setErrors,
    submit,
    handleChange,
    handleListValueChange,
    handleLowercaseInputChange,
    onDateChange,
    loading,
  };
};
