import React from "react";
import useEventTracking from "../../../utilities/hooks/useEventTracking";
import useGetCurrentRoute from "../../../utilities/hooks/course/useGetCurrentRoute";

const useButtonClickTracking = () => {
  const {logCourseEvent, logExerciseEvent} = useEventTracking();

  const trackClick = props => {
    const {event, ...args} = props;
    const url = window.location.href;

    if (url.includes("exercise=")) {
      logExerciseEvent(event + "_clicked", args);
    } else if (url.includes("course")) {
      logCourseEvent(event + "_clicked", args);
    } else window.analytics.track(event + "_clicked", args);
  };
  return {trackClick};
};

export default useButtonClickTracking;
