import useGetExercise from "API/queries/exercise/useGetExercise";
import useTagSuggestionActions from "Components/Workboard/ActionPane/useTagSuggestionActions";
import React from "react";
import ExerciseTag from "../../../ExerciseTag/ExerciseTag";
import {Skeleton} from "Components/_Elements";
import ActionPaneTagSuggestions from "../../ActionPane/ActionPaneTagSuggestions";

const ExerciseViewTags = ({exerciseId, setCount = () => {}}) => {
  const {user} = React.useContext(window.UserContext);
  const [exercise, exerciseLoading] = useGetExercise(exerciseId, false, false);

  // Mutation for removing tag:
  const {onTagClose} = useTagSuggestionActions();

  React.useEffect(() => {
    if (exercise) {
      setCount(exercise.tags.length);
    }
  }, [exercise]);

  if (exerciseLoading || !exercise) {
    return (
      <div className="p-2">
        <Skeleton
          style={{
            maxWidth: "200px",
            height: "24.5px",
          }}
          className="p-2"
        ></Skeleton>
      </div>
    );
  }

  const closable = user?.admin || user?.assistant;

  return (
    <div className="flex flex-start flex-wrap gap-2 p-2">
      <div className="flex flex-start flex-wrap gap-2 p-2">
        {exercise.tags.map(tag => {
          return <ExerciseTag tagId={tag.id} tab="theory" previewMode={true} />;
        })}
      </div>
      <ActionPaneTagSuggestions />
    </div>
  );
};

export default ExerciseViewTags;
