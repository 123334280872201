import React from "react";
import classnames from "classnames";
import Icon from "Components/_Elements/Icon/Icon";
import {SafeText, Typography} from "Components/_Elements";
import Badge from "Components/_Elements/Badge/Badge";
import Trans from "Components/Trans";

const WorkboardWidgetTitle = ({
  title,
  icon,
  badgeContent,
  collapsible,
  handleToggle,
  minimized,
  open,
  isNew,
  action = null,
}) => {
  return (
    <div
      className={classnames(
        "flex items-center justify-between w-full hover:text-primary-700",
        collapsible && "cursor-pointer"
      )}
      onClick={handleToggle}
    >
      <div className="flex gap-2">
        <Icon
          icon={icon}
          size="md"
          color={
            "text-gray-light" +
            (collapsible ? " group-hover:text-primary-light" : "")
          }
        />
        {React.cloneElement(title, {
          type: "tmd",
          weight: "sb",
          color: "text-gray-light",
          className: classnames(
            "uppercase select-none",
            collapsible && "group-hover:text-primary-light"
          ),
        })}
        {badgeContent > 0 && (
          <Badge type="darkGray">
            <Typography>{badgeContent}</Typography>
          </Badge>
        )}
      </div>

      <div className="flex gap-2 items-center">
        {isNew && (
          <Badge type="primary">
            <Trans>Beta</Trans>
          </Badge>
        )}
        {action}
        {collapsible && (
          <Icon
            icon={open ? "chevron-up" : "chevron-down"}
            className="mr-4"
            color={"text-gray-light group-hover:text-primary-light"}
          />
        )}
      </div>
    </div>
  );
};

export default WorkboardWidgetTitle;
