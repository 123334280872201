import React from "react";
import {Button, Trans, Typography} from "Components";
import SignUp from "routes/Auth/SignUp/SignUp";
import Login from "routes/Auth/Login/Login";
import ForgotPassword from "routes/Auth/ForgotPassword/ForgotPassword";

const GroupPageJoinAuth = ({
  registerTitle = "Börja med att skapa ett konto",
  loginTitle = "Logga in med ditt konto",
  requireName = true,
  transparentInput = false,
  signupFooterPosition,
  quickSignup = false,
  signupGrid = true,
  signupSource = "sektionskampen 2023",
  userProperties = null,
  emailExtension = null,
}) => {
  const [showSignup, setShowSignup] = React.useState(true);
  const [showForgotPassword, setShowForgotPassword] = React.useState(false);

  if (showSignup)
    return (
      <div className="w-full">
        <div className="w-full gap-2 flex flex-col sm:flex-row justify-between items-start">
          <Typography type="txl" weight="sb" color="text-primary ">
            {registerTitle}
          </Typography>
          <Button
            onClick={() => setShowSignup(false)}
            type="link"
            iconRight="fas fa-right-to-bracket"
          >
            <Trans>Login instead</Trans>
          </Button>
        </div>
        <SignUp
          showLoginLink={false}
          wrapper={false}
          onComplete={() => {}} // To prevent a redirect after signup
          showTitle={false}
          grid={signupGrid}
          transparentInput={transparentInput}
          source={signupSource}
          requireName={requireName}
          footerPosition={signupFooterPosition}
          quickSignup={quickSignup}
          userProperties={userProperties}
          emailExtension={emailExtension}
        />
      </div>
    );

  return (
    <div className="w-full">
      {!showForgotPassword && (
        <div className="w-full gap-2 flex flex-col sm:flex-row justify-between items-start ">
          <Typography type="txl" weight="sb" color="text-primary ">
            {loginTitle}
          </Typography>
          <Button
            onClick={() => setShowSignup(true)}
            type="link"
            iconRight="fas fa-user-plus"
          >
            <Trans>Create a new account</Trans>
          </Button>
        </div>
      )}

      {showForgotPassword ? (
        <div className="">
          <ForgotPassword
            wrapper={false}
            onBackClick={() => setShowForgotPassword(false)}
            onComplete={() => setShowForgotPassword(false)} // To prevent a redirect after login
            showTitle={false}
            transparentInput={transparentInput}
          />
        </div>
      ) : (
        <div className="w-full">
          <Login
            wrapper={false}
            onComplete={() => {}} // To prevent a redirect after login
            showTitle={false}
            showSignupLink={false}
            grid={true}
            showForgotPasswordLink={false}
            transparentInput={transparentInput}
            footer={
              <Button
                type="link"
                onClick={e => {
                  e.preventDefault();
                  setShowForgotPassword(true);
                }}
              >
                Har du glömt ditt lösenord?
              </Button>
            }
          />
        </div>
      )}
    </div>
  );
};

export default GroupPageJoinAuth;
