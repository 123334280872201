import React from "react";

const useCheckoutStateRecommendedProducts = ({
  productsState,
  setProductsState,
  setSelectedRecommendedProducts,
}) => {
  const addRecommendedProductToCartByIndex = index => {
    const recommendedProducts = [...productsState.recommendedProducts];
    recommendedProducts[index].selected = true;

    setProductsState({
      ...productsState,
      recommendedProducts,
    });
  };

  const removeRecommendedProductByIndex = index => {
    const recommendedProducts = [...productsState.recommendedProducts];
    recommendedProducts[index].selected = false;

    setProductsState({
      ...productsState,
      recommendedProducts,
    });
  };

  React.useEffect(() => {
    setSelectedRecommendedProducts(
      productsState.recommendedProducts
        .filter(p => p.selected)
        .map(p => ({
          id: p.id,
        }))
    );
  }, [productsState.recommendedProducts]);

  return {
    addRecommendedProductToCartByIndex,
    removeRecommendedProductByIndex,
  };
};

export default useCheckoutStateRecommendedProducts;
