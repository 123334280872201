import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

import {UserContext} from "UserContextProvider";

export const GET_PRODUCTS = gql`
  query getProducts($productInputs: [ProductInput], $auth: Boolean!) {
    getProducts(products: $productInputs) {
      ... on Course {
        id
        name
        code
        school {
          id
          name
        }

        prices {
          id
          priceCents
          discountPercentage
          accessDays
        }

        purchased @include(if: $auth)
        icon

        studyPlans {
          contentfulId
          id
          name
          description
          imageUrl
          prices {
            id
            priceCents
            discountPercentage
            accessDays
          }
          studentsCount

          author {
            id
            first_name
            last_name
            avatar
          }
          purchased @include(if: $auth)
          courseId
        }
      }

      ... on StudyPlan {
        id
        name
        description
        prices {
          id
          priceCents
          discountPercentage
          accessDays
        }
        imageUrl
        purchased @include(if: $auth)
        courseId
        author {
          id
          first_name
          last_name
          avatar
        }
      }

      ... on CreditsBundle {
        id
        name
        description
        prices {
          id
          priceCents
          discountPercentage
          accessDays
        }
        imageUrl
      }
    }
  }
`;

/**
 *
 * @param {Object} products
 * @param {Integer} products.productId - Id of the product
 * @param {String} products.productType - Type of the product (see server api docs)
 * @returns
 */
const useGetProducts = productInputs => {
  const {user} = React.useContext(UserContext);
  let products = [];

  const {loading, error, data} = useQuery(GET_PRODUCTS, {
    variables: {productInputs, auth: user !== null},
    context: {
      batch: true,
    },
  });

  if (error) {
    console.log("Error in useGetProducts ", error);
  }

  if (data) {
    products = data.getProducts;
  }

  return [products, loading];
};

export default useGetProducts;
