import React from "react";
import {motion} from "framer-motion";

const A4_WIDTH_300DPI = 2480;

const ExerciseViewImage = ({url, style, initialWidth}) => {
  return (
    <motion.div className="relative" key="exercise-view-image">
      <img
        alt={url}
        src={url}
        style={{
          width: "auto",
          imageResolution: "300dpi",
          ...style,
        }}
        className="dark:invert"
      />
    </motion.div>
  );
};

export default ExerciseViewImage;
