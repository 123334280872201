const isProduction = window.location.hostname.includes("kollin");

let stripeKey = isProduction
  ? "pk_live_fHo5j1iz9NUs5qtGRBbDUS67"
  : "pk_test_OMYG1QhukI2BwKqL6IcmLQiP";

export { stripeKey };

const stripe = window.Stripe ? window.Stripe(stripeKey) : null;

export default stripe;
