import React from "react";
import {CourseContext} from "routes/Course/CourseContextProvider";
import useGetCourseReviews from "API/queries/reivew/useGetCourseReviews";
import CourseLandingAllReviewsStats from "./CourseLandingAllReviewsStats/CourseLandingAllReviewsStats";
import {Trans} from "Components";
import "./CourseLandingAllReviews.scss";

import Masonry from "react-responsive-masonry";
import CourseLandingFeatureReviewCard from "routes/Course/CourseLanding/CourseLandingDesktop/CourseLandingFeatureReviews/CourseLandingFeatureReviewCard/CourseLandingFeatureReviewCard";

const CourseLandingAllReviews = () => {
  const {courseId} = React.useContext(CourseContext);
  const {navigationState} = React.useContext(window.CourseLandingContext);

  const {averageRating, nReviews, reviews} = useGetCourseReviews(
    courseId,
    false
  );

  if (averageRating == 0 || nReviews == 0) {
    return null;
  }

  let reviewsSorted = reviews.sort(
    // convert createdAt into a date and sort in descending order
    (firstEl, secondEl) =>
      new Date(secondEl.createdAt) - new Date(firstEl.createdAt)
  );

  reviewsSorted = reviewsSorted.slice(0, 100);

  return (
    <div
      className="course-landing-all-reviews mb-4"
      ref={navigationState.ratingsRef}
    >
      <Trans type="txl" weight="sb">
        Reviews
      </Trans>

      <Masonry columnsCount={3} gutter="10px" className="my-4">
        <CourseLandingAllReviewsStats
          averageRating={averageRating}
          nReviews={nReviews}
        />
        {reviewsSorted.map(review => {
          return (
            <CourseLandingFeatureReviewCard key={review.id} review={review} />
          );
        })}
      </Masonry>
    </div>
  );
};

export default CourseLandingAllReviews;
