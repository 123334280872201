import React from "react";
import {Button, Input, Trans, Typography} from "Components";
import useSignUpState from "routes/Auth/SignUp/useSignUpState";

import {Summer2022CampaignProgramContext} from "routes/Campaign/Summer2022Campaign/Summer2022Campaign";

const Summer2022CampaignOnBoardingSignUp = ({
  couponId,
  onComplete,
  setRecurringUser,
}) => {
  const {allowRegisteredUsers} = React.useContext(
    Summer2022CampaignProgramContext
  );

  const {
    state: {values, handleChange, errors, submit},
    loading,
  } = useSignUpState({couponId, onComplete, source: "summer_2022_campaign"});

  return (
    <div>
      <div className="flex items-center">
        {allowRegisteredUsers === true && (
          <Button
            onClick={() => setRecurringUser(true)}
            type="link"
            color="primary"
            inverted
            iconRight={"fas fa-arrow-right"}
          >
            Har du redan ett Kollin konto? Logga in
          </Button>
        )}
      </div>
      <form className="flex flex-col gap-4 w-full mt-2" onSubmit={submit}>
        <Input
          onChange={handleChange}
          name="fullName"
          value={values.fullName}
          error={errors.fullName}
          label={
            <Typography color="text-gray-50" className="mb-2">
              Namn
            </Typography>
          }
          fluid
          errorMessageRelative
          autoComplete="off"
        />
        <Input
          onChange={handleChange}
          name="email"
          value={values.email}
          error={errors.email}
          label={
            <Typography color="text-gray-50" className="mb-2">
              E-postaddress
            </Typography>
          }
          fluid
          errorMessageRelative
          autoComplete="off"
        />
        <Input
          onChange={handleChange}
          name="password"
          type="password"
          value={values.password}
          error={errors.password}
          label={
            <Typography color="text-gray-50" className="mb-2">
              Lösenord
            </Typography>
          }
          fluid
          errorMessageRelative
          autoComplete="off"
        />
        <Button type="primary" fluid onClick={submit} loading={loading}>
          Nästa
        </Button>
        <div className="flex items-center justify-center gap-2">
          <Typography className="line-through" color="text-gray-200">
            ————
          </Typography>

          <Trans type="txs" color="text-gray-200">
            Or
          </Trans>
          <Typography className="line-through" color="text-gray-200">
            ————
          </Typography>
        </div>
      </form>
    </div>
  );
};

export default Summer2022CampaignOnBoardingSignUp;
