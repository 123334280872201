import React from "react";
import i18n from "i18n";
import { Typography } from "Components";

// Please keep this in alphabetical order
const labels = {
  "theory chapter": [i18n.t("theory chapter"), i18n.t("theory chapters")],
  exam: [i18n.t("exam"), i18n.t("exams")],
  exercise: [i18n.t("exercise"), i18n.t("exercises")],
  reply: [i18n.t("reply"), i18n.t("replies")],
  review: [i18n.t("review"), i18n.t("reviews")],
  subtopic: [i18n.t("subtopic"), i18n.t("subtopics")],
  student: [i18n.t("student"), i18n.t("students")],
  tag: [i18n.t("tag"), i18n.t("tags")],
  "theory section": [i18n.t("theory section"), i18n.t("theory sections")],
  topic: [i18n.t("topic"), i18n.t("topics")],
};

const Numerical = ({ val, children, ...props }) => {
  const key = React.useMemo(() => getKeyFromChildren(children), [children]);

  let Content = React.useMemo(() => {
    try {
      if (val == 1) {
        return `${val} ${labels[key][0]}`;
      }
      return `${val} ${labels[key][1]}`;
    } catch (error) {
      return `${val} ${i18n.t(key)}`;
    }
  }, [val, key]);

  return <Typography {...props}>{Content}</Typography>;
};

const getKeyFromChildren = children => {
  let key = "";

  React.Children.map(children, child => {
    if (typeof child === "string") {
      key += child;
    }
  });

  return key;
};

Numerical.propTypes = {};

export default Numerical;
