import React from "react";
import {useHistory} from "react-router-dom";
import {Button, ButtonGroup, ErrorBoundary, Trans} from "Components";
import SubscriptionCancellation from "./SubscriptionCancellation/SubscriptionCancellation";
import SubscriptionReactivation from "./SubscriptionReactivation/SubscriptionReactivation";
import SubscriptionChangePlan from "./SubscriptionChangePlan/SubscriptionChangePlan";

const AccountSubscriptionCurrentSubscriptionActions = ({
  id,
  status,
  stripeData: {cancelAtPeriodEnd},
  subscriptionPlan,
}) => {
  const history = useHistory();

  if (status === "canceled" || status === "incomplete_expired") {
    return (
      <div className="account-subscription-status-actions">
        <Button
          type="primary"
          onClick={() => history.push("/pricing/subscription")}
        >
          <Trans>Reactivate</Trans>
        </Button>
      </div>
    );
  }

  // If the subscription will cancel but is still ALIVE, the user can't regret that and keep their subscription
  if (cancelAtPeriodEnd) {
    return <SubscriptionReactivation suscriptionId={id} />;
  }

  // Default actions
  return (
    <ErrorBoundary>
      <ButtonGroup>
        <SubscriptionCancellation subscriptionId={id} />
        <SubscriptionChangePlan
          subscriptionId={id}
          currentPlan={subscriptionPlan}
        />
      </ButtonGroup>
    </ErrorBoundary>
  );
};

export default AccountSubscriptionCurrentSubscriptionActions;
