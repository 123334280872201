import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";

const GET_GROUP_MEMBER_INVITE = gql`
  query getGroupMemberInvite($token: String!) {
    groupMemberInvite(token: $token) {
      id
      group {
        id
        key
        name
        imageUrl
        description
      }
      email
      accepted
      invitedBy {
        id
        firstName
        lastName
        avatar
      }
      createdAt
      updatedAt
    }
  }
`;

const useGetGroupMemberInvite = token => {
  let invite = null;

  const {data, loading, error} = useQuery(GET_GROUP_MEMBER_INVITE, {
    variables: {
      token,
    },
  });

  if (error) {
    console.error(`Error in getGroupMemberInvite: ${error}`);
  }

  if (data) {
    invite = data.groupMemberInvite;
  }

  return [invite, loading];
};

export default useGetGroupMemberInvite;
