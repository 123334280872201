import React from "react";
import useGetCourseExercises from "API/queries/exercise/useGetCourseExercises";
import Metric from "Components/_Elements/Metric/Metric";

import {Trans} from "Components";
import useCourseUserStatsHistoricalData from "Widgets/Course/CourseUserStats/useCourseUserStatsHistoricalData";
import MetricLoading from "Components/_Elements/Metric/MetricLoading";

const CourseUserStatsExercisesCompletedToday = ({size = "md"}) => {
  const {courseId} = React.useContext(window.CourseContext);

  const [exercises, loading] = useGetCourseExercises({
    courseId,
    includeSelfEvaluation: true,
  });

  const {
    data,
    valueToday,
    valueChangePercentage,
    chartColorScheme,
    valueChangeLabel,
    allValuesEmpty,
  } = useCourseUserStatsHistoricalData({
    data: exercises,
    groupByProperty: "self_evaluation.date",
    groupAggregateFunction: group => group.length,
  });

  if (loading && !exercises?.length) return <MetricLoading size={size} />;

  if (allValuesEmpty) return null;

  return (
    <Metric
      size={size}
      title={<Trans>Exercises completed today</Trans>}
      value={valueToday.toString()}
      changePercentage={valueChangePercentage}
      changeLabel={valueChangeLabel}
      chartType="line"
      colors={chartColorScheme}
      feedbackName="CourseUserStatsExercisesCompletedToday"
      gradient={true}
      data={[
        {
          id: "metric-chart-data",
          data,
        },
      ]}
    />
  );
};

export default CourseUserStatsExercisesCompletedToday;
