import React from "react";
import {CourseParamsContext} from "routes/Course/CourseRouter";
import useExerciseViewTextModeState from "Components/Workboard/ExerciseView/useExerciseViewTextModeState";

const useWorkboardState = defaultSelectedExerciseId => {
  const {previewMode} = React.useContext(window.PreviewSidebarContext);

  const {exercise: urlExerciseId} = React.useContext(CourseParamsContext);

  const [exerciseId, setExerciseId] = React.useState(
    previewMode ? null : defaultSelectedExerciseId || urlExerciseId
  );

  /* State for handling exercist list expanded */
  const [exerciseListOrientation, setExerciseListOrientation] = React.useState(
    "horizontal"
  );

  React.useEffect(() => {
    setExerciseId(urlExerciseId);
  }, [urlExerciseId]);

  const defaultSidebarState = new URLSearchParams(window.location.search).get(
    "theory"
  );

  const [sidebarOpen, setSidebarOpen] = React.useState(
    defaultSidebarState || false
  );

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const textModeState = useExerciseViewTextModeState();

  return {
    exerciseId,
    exerciseListOrientation,
    setExerciseListOrientation,
    sidebarOpen,
    toggleSidebar,
    ...textModeState,
  };
};

export default useWorkboardState;
