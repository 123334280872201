import React from "react";
import {Trans} from "../../../index";
import ExerciseViewHeaderTextModeToggle from "Components/Workboard/ExerciseView/ExerciseViewHeader/ExerciseViewHeaderTextModeToggle";
import formatExamDate from "utilities/formatExamDate";
import {Link, Typography} from "Components/_Elements";
import PreviewButton from "Components/PreviewButton/PreviewButton";
import useCourseLink from "routes/Course/useCourseLink";

const Details = ({exercise}) => {
  const {courseId} = React.useContext(window.CourseContext);
  const examLink = useCourseLink(`exam/${exercise?.source?.id}`);

  const source = exercise?.source || {};
  const number = exercise?.number || "-";

  return (
    <div className="flex gap-6">
      <div className="flex flex-col gap-2">
        <Trans weight="md" color="text-gray-500" inline>
          Number
        </Trans>
        <Trans weight="md" color="text-gray-500" inline>
          Source
        </Trans>
        <Trans weight="md" color="text-gray-500" inline>
          Author
        </Trans>
        <Trans weight="md" color="text-gray-500" inline>
          Text mode
        </Trans>
      </div>
      <div className="flex flex-col gap-2">
        <Trans type="sm" color="text-gray-500" inline>
          {number}
        </Trans>
        <div className="flex gap-4">
          <Link
            asButton
            inline
            basic
            type="link"
            to={`/course/${courseId}/exam-exercises/${exercise?.source?.id}?dir=asc&exercise=${exercise?.id}&sort=number&displayMode=list`}
          >
            <Typography
              weight="md"
              color="text-gray-light"
              className="hover:text-primary-light"
            >
              {source?.date ? formatExamDate(source?.date) : "--"}
            </Typography>
          </Link>
          <PreviewButton route={examLink} type="link" size="xs" />
        </div>
        <Trans
          type="sm"
          color="text-gray-500"
          inline
          className="truncate text-ellipsis overflow-scroll"
        >
          {source.author ? source.author : "-"}
        </Trans>
        <div className="flex gap-2 items-center">
          <ExerciseViewHeaderTextModeToggle />
        </div>
      </div>
    </div>
  );
};

export default Details;
