import React from "react";
import moment from "moment/moment";
import {Trans} from "Components";
import {useLocalDate} from "utilities/dates";
import {SubscriptionStatusDescriptionProps} from "./SubscriptionStatusDescription";

const SubscriptionStatusDescriptionCancelled = ({cancelAt, invoices}) => {
  // Date of when the subscription will or was cancelled
  const cancellationDate = useLocalDate(cancelAt, "Do MMMM YYYY");

  const cancelIsInFuture = moment().isBefore(cancelAt);

  return cancelIsInFuture ? (
    <Trans {...SubscriptionStatusDescriptionProps}>No future invoices</Trans>
  ) : (
    <Trans
      {...SubscriptionStatusDescriptionProps}
      cancellationDate={cancellationDate}
    >
      Canceled on {{cancellationDate}}
    </Trans>
  );
};

export default SubscriptionStatusDescriptionCancelled;
