import React from "react";
import useLocalStorageState from "utilities/hooks/useLocalStorageState";
import {isMobile} from "react-device-detect";

const useExerciseViewTextModeState = () => {
  const [textMode, setTextModeActive] = useLocalStorageState(
    "EXERCISE_VIEW_TEXT_MODE",
    isMobile
  );

  const toggleTextMode = () => setTextModeActive(!textMode);

  const [textModeDisabled, setTextModeDisabled] = React.useState(false);

  const disableTextMode = () => {
    // If text mode is active, disable it
    if (textMode) {
      setTextModeActive(false);
    }
    // Disable text mode
    setTextModeDisabled(true);
  };

  const forceActivateTextMode = () => {
    setTextModeActive(true);
  };

  return {
    textMode,
    toggleTextMode,
    textModeDisabled,
    disableTextMode,
    forceActivateTextMode,
  };
};

export default useExerciseViewTextModeState;
