import React from "react";
import {Button, Icon, Loader, Lottie, Trans} from "Components";
import PaymentMethodSwish from "routes/Checkout/CheckoutPayment/PaymentMethods/PaymentMethodSwish/PaymentMethodSwish";
import PaymentMethodStripe from "routes/Checkout/CheckoutPayment/PaymentMethods/PaymentMethodStripe/PaymentMethodStripe";
import client from "client";
import {GET_CURRENT_USER_CREDITS} from "API/queries/user/useGetCurrentUserCredits";

const BuyCreditsActions = ({
  id,
  price,
  handleBuyCreditsClick,
  defaultPrice,
  resetSelectedBundle,
  selected,
  selectedPaymentMethod,
  action,
  actionLoading,
  order,
  handleCancelClick,
}) => {
  const urlSuccess =
    new URL(window.location.href).searchParams.get("success") === "1";
  const [successful, setSuccessful] = React.useState(urlSuccess || false);

  const onSuccess = () => {
    client.query({
      query: GET_CURRENT_USER_CREDITS,
      fetchPolicy: "network-only", // Force network fetch
    });
    setSuccessful(true);
  };

  React.useEffect(() => {
    if (!successful) return;

    setTimeout(() => {
      setSuccessful(false);
      resetSelectedBundle(); // Reset selected bundle
    }, 2000);
  }, [successful]);

  if (successful) {
    return (
      <div className="w-full flex gap-2 items-center justify-end">
        <Lottie
          loop={false}
          style={{
            transform: "scale(2)",
          }}
          animation="goldCoin"
        />
        <Trans
          type="tlg"
          inline
          className="leading-none mb-0"
          color="text-success"
        >
          Purchase successful
        </Trans>
        <Icon icon="fas fa-check-circle" color="text-success-light" size="md" />
      </div>
    );
  }

  if (selected && selectedPaymentMethod === "swish") {
    return (
      <PaymentMethodSwish
        inlineForm
        showTitle={false}
        loading={actionLoading}
        action={action}
        paymentRequestId={order?.chargeId}
        showStatusModal={false}
        onCancel={handleCancelClick}
        onSuccess={onSuccess}
      />
    );
  }

  if (selected && selectedPaymentMethod === "stripe") {
    return (
      <div>
        <PaymentMethodStripe
          showAction={false}
          stripeSessionId={order?.chargeId}
        />
        <Loader type="BarLoader" width="100%" />
      </div>
    );
  }

  return (
    <Button
      fluid
      type="gray"
      size="sm"
      onClick={() => {
        handleBuyCreditsClick(id, defaultPrice.id);
      }}
    >
      <Trans price={price}>{{price}} SEK</Trans>
    </Button>
  );
};

export default BuyCreditsActions;
