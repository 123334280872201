import translationEN from "./en.json";
import translationSV from "./sv.json";
import translationFR from "./fr.json";
import translationUR from "./ur.json";
import translationES from "./es.json";
import translationDE from "./de.json";
import translationPT from "./pt.json";

export default {
  en: translationEN,
  sv: translationSV,
  fr: translationFR,
  ur: translationUR,
  es: translationES,
  pt: translationPT,
  de: translationDE,
};
