import React from "react";
import useGetProducts from "API/queries/product/useGetProducts";

const useGetProductsFromUrl = (productsInput, selectedRecommendedProducts) => {
  const [products, loading] = useGetProducts(productsInput);

  let formattedProducts = React.useMemo(() => {
    return products?.map(p => {
      if (p.__typename == "Course") {
        return {
          ...p,
          imageUrl: `https://d1mgntrf3vaj6d.cloudfront.net/course-icons/${p.icon}.svg`,
          description: p.school.name,
        };
      }
      return p;
    });
  }, [products]);

  // Filter our the products that are purchased:
  const unPurchasedProducts = React.useMemo(() => {
    return products?.filter(p => !p.purchased);
  }, [products]);

  const recommendedProducts = React.useMemo(
    findRecommendedProducts.bind(null, products, selectedRecommendedProducts),
    [products]
  );

  return {
    products: formattedProducts,
    loading,
    unPurchasedProducts,
    recommendedProducts,
  };
};

const findRecommendedProducts = (products, selectedRecommendedProducts) => {
  const recommended = [];

  for (let i = 0; i < products.length; i++) {
    const studyPlans = products[i].studyPlans;

    if (!studyPlans || studyPlans.length == 0) continue;

    for (let j = 0; j < studyPlans.length; j++) {
      const studyPlan = studyPlans[j];

      if (studyPlan.prices.length == 0) continue;

      const existingStudyPlanInProducts = products.find(
        p => p.__typename == "StudyPlan" && p.id == studyPlan.id
      );

      if (existingStudyPlanInProducts || studyPlan.purchased) continue;

      let selected = false;

      // Check if the product is selected in the url
      if (selectedRecommendedProducts) {
        selectedRecommendedProducts.forEach(selectedRecommendedProduct => {
          if (selectedRecommendedProduct.id == studyPlan.id) {
            selected = true;
          }
        });
      }

      recommended.push({
        ...studyPlan,
        recommended: true,
        selected,
      });
    }
  }

  return recommended;
};

export default useGetProductsFromUrl;
