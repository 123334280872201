import React from "react";
import {UserContext} from "UserContextProvider";
import useGetCourseTags from "API/queries/tag/useGetCourseTags";
import {CourseContext} from "routes/Course/CourseContextProvider";
import {CourseParamsContext} from "routes/Course/CourseRouter";
import Input from "Components/_Elements/Input/Input";
import Select from "Components/_Elements/Select/Select";
import useTagSuggestionActions from "./useTagSuggestionActions";
import {removeEscapedText} from "utilities/text";
import {useTranslation} from "react-i18next";

const ActionPaneTagSuggestions = () => {
  const {exercise: exerciseId} = React.useContext(CourseParamsContext);
  const {user} = React.useContext(UserContext);
  const {courseId, public_tagging: publicTagging} = React.useContext(
    CourseContext
  );
  let [tags, loading] = useGetCourseTags(courseId, exerciseId);
  const {onTagClick, onAddTag, onTagClose} = useTagSuggestionActions();

  const [tagFilter, setTagFilter] = React.useState("");
  const [query, setQuery] = React.useState("");

  const [isExpanded, setIsExpanded] = React.useState(false);
  React.useEffect(() => setIsExpanded(false), [exerciseId]);

  const {t: trans} = useTranslation();

  if (loading) {
    return null;
  }

  // if the user is not logged in, we don't want to show the tag suggestions
  if (!user) {
    return null;
  }

  // If not assistant or admin or publicTagging is not active for the course return nothing
  if (user.assistant || user.admin || publicTagging) {
    tags = tags
      .filter(tag => (tag.isValidated ? false : true))
      .map(tag => ({
        ...tag,
        value: tag.id,
        text: removeEscapedText(tag.name),
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
      .filter(
        (tag, index, self) => self.findIndex(t => t.text === tag.text) === index
      );

    return (
      <div className="container-fluid h-auto position-absolute z-50">
        {isExpanded && (
          <Input
            fluid
            placeholder={trans("Search...")}
            onChange={e => setTagFilter(e.target.value)}
            autofocus="true"
          />
        )}
        <hr className="thin" />
        {
          <Select
            className="z-50"
            placeholder={trans("Add more tags")}
            options={tags}
            overrideCallback
            onSelect={tagId => onTagClick(tagId)}
            // onDeselect={tagId => onTagClose(tagId)}
            showSearch
            autoClearSearchValue
            onSearch={setQuery}
            // dropdownRender={menu => (
            //   <div>
            //     {menu}
            //     {query && (
            //       <div className="p-2 flex">
            //         <Button
            //           size="small"
            //           fluid
            //           onClick={() => {
            //             onAddTag(query);
            //             return setQuery("");
            //           }}
            //         >
            //           <b>Add tag "{query}"</b>
            //         </Button>
            //       </div>
            //     )}
            //   </div>
            // )}
          ></Select>
        }
      </div>
    );
  }

  return null;
};

export default ActionPaneTagSuggestions;
