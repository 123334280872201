import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";

const GET_COURSE_CONTRIBUTIONS = gql`
  query courseContributions($courseId: ID!, $pagination: PaginationInput) {
    courseContributions(courseId: $courseId, pagination: $pagination) {
      id
      createdAt
      objectType
      user {
        id
        avatar
        name
        firstName
        admin
        assistant
        premium
        enrollmentYear
        program {
          name
        }
      }
    }
  }
`;

/**
 *
 */
const useGetCourseContributions = ({
  courseId,
  pagination = {
    limit: 10,
    offset: 0,
    orderBy: "created_at",
    orderDir: "desc",
  },
}) => {
  const {loading, error, data} = useQuery(GET_COURSE_CONTRIBUTIONS, {
    variables: {
      courseId,
      pagination,
    },
  });
  let contributions = [];

  if (error) {
    console.log("Error in useGetCourseContributions", error);
    return [contributions, false];
  }

  if (data) {
    contributions = data.courseContributions;
  }

  return [contributions, loading];
};

export default useGetCourseContributions;
