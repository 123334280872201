import React from "react";
import {Carousel, Typography} from "Components";
import classnames from "classnames";
import {Summer2022CampaignSectionTitle} from "routes/Campaign/Summer2022Campaign/Summer2022Campaign";

const Summer2022CampaignTestimonials = ({testimonials}) => {
  return (
    <div className="w-full h-[36rem]">
      <Summer2022CampaignSectionTitle>
        Vad äldre kursare säger
      </Summer2022CampaignSectionTitle>
      <Carousel showDots>
        {testimonials.map((testimonial, index) => (
          <Summer2022CampaignTestimonialsCard
            {...testimonial}
            key={"testimonial-" + index}
          />
        ))}
      </Carousel>
    </div>
  );
};

export const Summer2022CampaignTestimonialsCard = ({
  body,
  name,
  school,
  image,
  fluidHeight,
  darkBackground = true,
}) => {
  return (
    <div
      className={classnames(
        "w-full max-w-lg mx-auto flex-col overflow-hidden rounded-2xl",
        fluidHeight ? "h-fit" : "h-[32rem]"
      )}
    >
      {/* TEXT SECTION */}
      <div
        className={classnames(
          "p-8",
          darkBackground ? "bg-primary-800" : "bg-gray-50"
        )}
      >
        <div
          className={classnames(
            "overflow-y-auto pr-2",
            fluidHeight ? "h-fit" : "h-[7rem]"
          )}
        >
          <Typography
            type="tlg"
            color={darkBackground ? "text-white" : "text-gray"}
          >
            {body}
          </Typography>
        </div>
        <div className="mt-8">
          <Typography
            type="tlg"
            weight="md"
            color={darkBackground ? "text-white" : "text-gray"}
          >
            — {name}
          </Typography>
          <Typography
            type="tmd"
            color={darkBackground ? "text-white" : "text-gray-light"}
          >
            {school}
          </Typography>
        </div>
      </div>

      {/* IMAGE SECTION*/}
      <div className={classnames("w-full grow")}>
        <img
          src={
            image.url + "?fm=jpg&fl=progressive&f=face&fit=thumb&w=1200&h=1000"
          }
          alt={image.title}
          className="w-full rounded-b-2xl"
        />
      </div>
    </div>
  );
};

const Summer2022CampaignTestimonialsCardGlass = ({
  body,
  name,
  school,
  image,
}) => {
  return (
    <div
      className="p-4 mx-auto h-[32rem] flex-col overflow-hidden rounded-2xl bg-cover justify-end"
      style={{
        backgroundImage: `url(${image.url}?fm=jpg&fl=progressive&f=face&fit=thumb&w=900&h=1600)`,
      }}
    >
      <div className="p-2 bg-gray-25 opacity-50 backdrop-blur-sm">
        <div className="h-[7rem] overflow-y-scroll shadow-inner pr-2">
          <Typography type="tmd" weight="md" color="text-black">
            <i>{body}</i>
          </Typography>
        </div>
        <div className="mt-8">
          <Typography type="tsm" weight="md" color="text-black">
            — {name}
          </Typography>
          <Typography type="tsm" color="text-primary-200">
            {school}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Summer2022CampaignTestimonials;
