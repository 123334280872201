import React from "react";
import useGetCourseReviews from "API/queries/reivew/useGetCourseReviews";
import CoursePriceTag from "Components/CoursePriceTag/CoursePriceTag";
import { Maybe, Typography, StarRating } from "Components";
import { useBreakPointSM } from "utilities/hooks/useWindowSize";

const CourseReviewsSummary = ({
  handleRatingClick,
  white = false,
  showCourseTag = true,
  showNbReviews = true,
  courseId,
  textSizeClassName = "text-body4",
}) => {
  const smallScreens = useBreakPointSM();
  const { averageRating, nReviews } = useGetCourseReviews(courseId, false);

  return (
    <div className="flex flex-row items-center gap-2 md:gap-4">
      {showCourseTag && <CoursePriceTag courseId={courseId} />}
      <Maybe dep={nReviews}>
        <Typography
          type={smallScreens("tsm", "txl")}
          weight="sb"
          color="text-warning-600"
          className={textSizeClassName}
        >
          {averageRating}
        </Typography>
        <StarRating rating={averageRating} />
        {showNbReviews && (
          <Typography
            type={smallScreens("txs", "tmd")}
            color={white && "white"}
            onClick={handleRatingClick}
            className={textSizeClassName}
          >{`(${nReviews})`}</Typography>
        )}
      </Maybe>
    </div>
  );
};

export default CourseReviewsSummary;
