import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";

const SETTINGS = {
  useCreditsForSubscription: {
    type: "boolean",
    default: false,
  },
};

export const GET_CURRENT_USER_SETTINGS = gql`
  query getCurrentUserSettings {
    currentUser {
      id
      settings {
        # Add more settings here as they are created
        useCreditsForSubscription
      }
    }
  }
`;

const useGetCurrentUserSettings = () => {
  let settings = {};

  const {data, loading, error} = useQuery(GET_CURRENT_USER_SETTINGS);

  if (error) {
    console.error(`Error in useGetCurrentUserSettings: ${error}`);
  }

  if (data && data.currentUser) {
    settings = data.currentUser.settings || {}; // Settings may be null
    // Go through each setting and initialize it if it doesn't exist
    Object.keys(SETTINGS).forEach(key => {
      if (!settings[key]) {
        settings[key] = SETTINGS[key].default;
      }
    });
  }

  return [settings, loading];
};

export default useGetCurrentUserSettings;
