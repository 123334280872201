import ReactDOM from "react-dom";
import React from "react";
import ErrorBoundary from "Components/ErrorBoundary/ErrorBoundary";

const ID = "message-queue-container";

const getContainer = () => {
  const container = document.getElementById(ID);
  if (container) {
    return container;
  }
  const newContainer = document.createElement("div");

  newContainer.id = ID;
  newContainer.style.position = "fixed";
  newContainer.style.top = "0";
  newContainer.style.right = "0";
  // newContainer.style.transform = "translateX(50%)";
  newContainer.style.width = "100%";
  newContainer.style.zIndex = "9999";
  document.body.appendChild(newContainer);
  return newContainer;
};
/**
 * Renders the message queue container component in the DOM
 * The message queue container is unmounted when the queue is empty to avoid
 * unnecessary elements in the DOM
 * @param Component
 */
export const mountMessageQueueContainer = Component => {
  if (!window.messageQueueContainerMounted) {
    const container = getContainer();

    ReactDOM.render(
      <ErrorBoundary hideOnError>
        <Component />
      </ErrorBoundary>,
      container
    );

    window.messageQueueContainerMounted = true;
  }
};

export const hideMessageContainer = () => {
  const container = document.getElementById(ID);
  if (container) {
    // Hide the container
    container.style.display = "none";
  }
};

export const showMessageContainer = () => {
  const container = document.getElementById(ID);

  if (container) {
    // Show the container
    container.style.display = "block";
  }
};
