import React from "react";
import {useTranslation} from "react-i18next";
import StudyPlanCardStats from "./StudyPlanCardStats/StudyPlanCardStats";
import StudyPlanCardActions from "./StudyPlanCardActions/StudyPlanCardActions";
import StudyPlanCardPurchased from "Components/StudyPlanCard/StudyPlanCardPurchased/StudyPlanCardPurchased";
import {Typography} from "Components";

const StudyPlanCard = ({studyPlan, showUnpurchased}) => {
  const {name, description, author, imageUrl} = studyPlan;

  const {t: trans} = useTranslation();

  if (studyPlan.purchased) {
    return <StudyPlanCardPurchased studyPlan={studyPlan} />;
  }

  return (
    <div className="border-2 border-color rounded-lg background">
      <div>
        <img
          src={imageUrl}
          alt={name}
          className="bg-primary-800 rounded-t-lg w-full h-32 object-contain"
        />
        <Typography weight="sb" className="px-3 py-2 h-16">
          {name}
        </Typography>
        <Typography type="sm" color="text-gray-500" className="px-2">
          {description.slice(0, 200)}
          {description.length > 200 ? "..." : ""}
        </Typography>
      </div>
      <div>
        <StudyPlanCardStats studyPlan={studyPlan} />
        {/*<StudyPlanCardPrice studyPlan={studyPlan} />*/}
        <StudyPlanCardActions studyPlan={studyPlan} />
      </div>
    </div>
  );
};

export default StudyPlanCard;
