import React from "react";

import classnames from "classnames";
import useGetAllPrograms from "API/cms/program/useGetAllPrograms";

export const sizeMap = {
  sm: "w-8",
  md: "w-12 ",
  lg: "w-16 ",
  xl: "w-20 ",
  "2xl": "w-24 ",
  "3xl": "w-32 ",
};

const ProgramLogo = ({
  programId, // Program ID
  size = "md", // Size of the logo
}) => {
  const [allPrograms, loading] = useGetAllPrograms();

  const program = React.useMemo(
    () => allPrograms.find(program => program.id === programId),
    [allPrograms, programId]
  );

  if (loading || !program || program?.logo === null) return null;

  return <img className={classnames(sizeMap[size])} src={program.logo.url} />;
};

export default ProgramLogo;
