import { Button, Trans } from "Components/index";
import { ExerciseViewContext } from "../ExerciseView";
import React from "react";

const ExerciseViewForumToggleButton = () => {
  const { forumVisible, showForum, toggleForumVisible } = React.useContext(
    ExerciseViewContext
  );

  if (!showForum) return null;

  const text = forumVisible ? (
    <Trans>Hide discussion</Trans>
  ) : (
    <Trans>Show discussion</Trans>
  );
  const icon = forumVisible ? "up" : "down";

  return (
    <Button
      onClick={toggleForumVisible}
      type="link"
      size="sm"
      iconRight={`fa-solid fa-angle-${icon}`}
    >
      {text}
    </Button>
  );
};

export default ExerciseViewForumToggleButton;
