import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BooleanParam, useQueryParams } from "use-query-params";
import useGetSummer2022CampaignProgramData from "API/cms/campaignSummer2022/useGetSummer2022CampaignProgramData";
import useGetLandingPageSections from "API/cms/landingPage/useGetLandingPageSections";
import LANDING_PAGES_CONTENFUL_IDS from "utilities/constants/LANDING_PAGES_CONTENFUL_IDS";

const paramConfigMap = {
  onBoarding: BooleanParam,
};

const useSummer2022Campaign = ({ disabled, programDataContentfulId }) => {
  const history = useHistory();
  const location = useLocation();
  const [urlState, setUrlState] = useQueryParams(paramConfigMap);

  // Don't let the user proceed if it's disabled (already logged in)
  let initiateOnBoarding = () => {};

  if (!disabled)
    initiateOnBoarding = () => setUrlState({ onBoarding: true }, "pushIn");

  const [
    campaignData,
    campaignDataLoading,
  ] = useGetSummer2022CampaignProgramData(programDataContentfulId);

  const [
    landingPageSections,
    landingPageSectionsLoading,
  ] = useGetLandingPageSections(
    LANDING_PAGES_CONTENFUL_IDS["campaignSummer2022"]
  );

  return {
    campaignData,
    campaignDataLoading,
    landingPageSections,
    landingPageSectionsLoading,
    onBoarding: urlState.onBoarding,
    initiateOnBoarding,
  };
};

export default useSummer2022Campaign;
