import React from "react";
import {motion} from "framer-motion";
import {Button, Icon, Trans} from "Components";
import {safeCloneTextTag} from "utilities/safeClone";
import {OnboardingContext} from "routes/Onboarding/Onboarding";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";
import classnames from "classnames";

const OnboardingLayoutStep = ({children, compact = false}) => {
  const {currentStep, currentStepIndex} = React.useContext(OnboardingContext);

  const titleRef = React.useRef(null);

  React.useEffect(() => {
    // Scroll to top of the step title
    if (!titleRef.current) return;
    titleRef.current.scrollIntoView({behavior: "smooth", block: "start"});
  }, [currentStepIndex]);

  return (
    <div
      className={classnames(
        "flex flex-col mx-auto",
        "p-4",
        !compact && "md:p-0 md:w-4/5"
      )}
    >
      {/*Header for each step */}
      <motion.div
        className="flex items-center gap-4 mt-2"
        key={currentStepIndex}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        ref={titleRef}
      >
        <Icon
          icon={currentStep.icon}
          size={compact ? "md" : "lg"}
          filled
          fillType="primary"
        />
        <div>
          {safeCloneTextTag(currentStep.title, {
            type: compact ? "dxs" : "dsm",
            weight: "md",
          })}
          {safeCloneTextTag(currentStep.description, {
            type: compact ? "tsm" : "tmd",
            weight: "normal",
          })}
        </div>
      </motion.div>
      {/* Step body,  */}

      <div className={classnames(compact ? "mt-2 md:mt-4" : "mt-8 md:mt-12")}>
        {React.cloneElement(currentStep?.Component, {
          compact,
        })}
      </div>
    </div>
  );
};

export default OnboardingLayoutStep;
