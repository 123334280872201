import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import SupportMailForm from "Components/SupportMailForm";
import {Button, Link, Modal, Trans} from "Components";
import {motion} from "framer-motion";

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      showMessage: true,
      showSupportForm: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    console.log("App error boundary: ", error, errorInfo);

    // Send the event to segment
    window.analytics.track("app-crash", {
      error: JSON.stringify(error),
      errorInfo: errorInfo,
      screen_name: window.location.pathname + window.location.search,
    });

    // Send error and errorInfo to Sentry
    Sentry.withScope(scope => {
      scope.setTag("screen", "crash");

      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      scope.setExtra("error_location", "App error boundary");
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError && this.state.showMessage) {
      return (
        <Modal
          defaultOpen
          closable={false}
          title={
            <Trans>Oops! Looks like something is not working properly</Trans>
          }
          subTitle={
            <Trans>
              The Kollin team has been notified about this and they will try to
              fix the issue as soon as possible. We are very sorry for the
              inconvenience.
            </Trans>
          }
        >
          {this.state.showSupportForm && <SupportMailForm showHeader={false} />}

          <div className="flex gap-2 mt-8">
            <Button
              onClick={() => {
                this.setState({...this.state, showSupportForm: true});
              }}
              fluid
              iconLeft="envelope"
            >
              <Trans>Contact support</Trans>
            </Button>

            <Link
              href="/"
              iconLeft="home"
              fluid
              asButton
              buttonType="secondary"
            >
              <Trans>Go to homepage</Trans>
            </Link>
          </div>
        </Modal>
      );
    }
    return this.props.children;
  }
}

export default AppErrorBoundary;
