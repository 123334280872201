import React from "react";
import {range} from "utilities/numbers";
import Skeleton from "./Skeleton";

const SkeletonList = props => {
  if (props.children) {
    if (props.loading) {
      return range(props.count).map(i => (
        <Skeleton key={"skeleton-" + i} {...props} fluid={false} />
      ));
    }

    return props.children;
  }

  return range(props.count).map(i => (
    <Skeleton key={"skeleton-" + i} {...props} fluid={false} />
  ));
};

SkeletonList.defaultProps = {
  count: 5,
};

export default SkeletonList;
