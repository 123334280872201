import React from "react";
import {Icon, Link, Typography} from "Components";
import ProgressBead from "../../../Components/ProgressBead";
import CompoundProgressBar from "../../../Components/CompoundProgressBar";
import {segments} from "../../../utilities/exerciseUtilities/exerciseListOperations";
import useGetTag from "../../../API/queries/tag/useGetTag";
import useGetTagExercises from "../../../API/queries/exercise/useGetTagExercises";
import {CourseParamsContext} from "../../../routes/Course/CourseRouter";
import SkeletonListWithCircle from "../../../Components/_Elements/Skeleton/SkeletonListWithCircle";

const CourseTopicsListCard = ({tagId, courseId, key}) => {
  const [tag, tagLoading] = useGetTag(tagId, false, false, true);
  const [exercises, exercisesLoading] = useGetTagExercises(tagId, courseId);
  const {user} = React.useContext(window.UserContext);
  const {getTagLink} = React.useContext(CourseParamsContext);
  const seg = segments(exercises);
  const count = exercises.length;

  if (tagLoading || exercisesLoading)
    return <SkeletonListWithCircle count={1} fluid={true} height="h-[63px]" />;

  return (
    <Link
      to={getTagLink(tagId)}
      fluid
      highlightSelected={false}
      key={key}
      basic
    >
      <div className="py-4 flex gap-4">
        <div className="w-fit">
          <ProgressBead height={34} exercises={exercises} user={user} />
        </div>
        <div className="flex flex-col gap-2 w-full overflow-hidden">
          <Typography type="tmd" className="truncate text-ellipsis">
            {tag.name}
          </Typography>
          <div className="w-3/4">
            <CompoundProgressBar nExercises={count} segments={seg} />
          </div>
        </div>
        <Icon
          icon="fa-solid fa-chevron-right"
          size="sm"
          color="text-gray-500"
        />
      </div>
    </Link>
  );
};

export default CourseTopicsListCard;
