import React from "react";
import {motion} from "framer-motion";
import classnames from "classnames";

import {Trans, Typography} from "Components";
import HighlightedText from "Components/_Elements/HighlightedText/HighlightedText";

const SelectItemsList = ({
  items,
  focused,
  handleItemClick,
  size,
  searchTerm,
  showSearch,
  showIconThreshold,
}) => {
  if (!focused) return null;

  return (
    <motion.div
      initial={{y: -30, opacity: 0}}
      animate={{y: 0, opacity: 1}}
      className="background w-full min-w-fit mt-2 shadow-lg rounded-lg absolute z-20 max-h-60 overflow-y-auto border-1 border-gray"
    >
      {items?.map(({text, icon, value, image}, index) => (
        <motion.div
          id={value}
          onClick={() => {
            handleItemClick(value);
          }}
          animate={{opacity: 1}}
          initial={{opacity: 0}}
          transition={{duration: 0.15}}
          className={classnames(
            "select-option w-full p-3 cursor-pointer background text-gray-800 hover:surface flex items-center gap-2",
            icon && "py-4"
          )}
        >
          {icon && showIconThreshold > index && <div>{icon}</div>}
          {text && (
            <Typography type="tmd">
              <HighlightedText highlight={searchTerm}>{text}</HighlightedText>
            </Typography>
          )}
        </motion.div>
      ))}
      {showSearch && items?.length === 0 && (
        <div className="select-option w-full p-3  background text-gray-800 ">
          <i>
            <Trans type="tmd">No results found</Trans>
          </i>
        </div>
      )}
    </motion.div>
  );
};

export default SelectItemsList;
