import React from "react";
import useGetCurrentUserGroups from "API/queries/groups/useGetCurrentUserGroups";

const useGroupDiscounts = () => {
  const {currentUserGroups, loading} = useGetCurrentUserGroups();

  const groupDiscounts = React.useMemo(() => {
    return currentUserGroups.filter(group => group.discounts.length > 0) || [];
  }, [currentUserGroups]);

  if (groupDiscounts.length === 0 || loading)
    return {
      group: null,
      discount: null,
      loading: false,
    };

  // Take the discount with the highest percentage
  groupDiscounts.sort((a, b) => {
    b.discounts[0]?.discountPercentage - a.discounts[0]?.discountPercentage;
  });

  const group = groupDiscounts[0];
  const discount = group.discounts[0];
  const discountApplicable = group.currentUserMembership.verified;
  discount.applicable = discountApplicable;

  const groupMembershipId = group.currentUserMembership.id;

  return {
    group,
    discount,
    loading,
    discountApplicable,
    groupMembershipId,
  };
};

export default useGroupDiscounts;
