import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_PRODUCT_PRICES = gql`
  query getProducts($productInputs: [ProductInput]) {
    getProducts(products: $productInputs) {
      id # get the id so it can be cached
      prices {
        id
        priceCents
        accessDays
        discountPercentage
      }
    }
  }
`;

const useGetProductPrices = (productType, productId) => {
  // const { user } = React.useContext(UserContext);
  let prices = [];

  const {loading, error, data} = useQuery(GET_PRODUCT_PRICES, {
    variables: {
      productInputs: [{productType, productId}],
    },
    context: {
      batch: true,
    },
  });

  if (data && data.getProducts.length) {
    prices = data.getProducts[0].prices;
  }

  if (error) {
    console.log("Error in useGetProductPrices-hook: ", error);
  }

  return [prices, loading];
};

export default useGetProductPrices;
