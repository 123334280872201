import React from "react";
import DatePickerCalendar from "./DatePickerCalendar/DatePickerCalendar";
import DatePickerTrigger from "Components/_Elements/DatePicker/DatePickerTrigger";
import useDatePicker from "Components/_Elements/DatePicker/useDatePicker";

const DatePicker = ({
  value = null,
  onChange,
  fluid = false,
  format = "YYYY-MM-DD",
  showInput = false,
  defaultOpen = false,
}) => {
  const {
    currentDate,
    onDateSelected,
    showPicker,
    setShowPicker,
    pickerRef,
  } = useDatePicker({
    value,
    onChange,
    defaultOpen,
    format,
    showInput,
  });

  return (
    <div>
      <DatePickerTrigger
        fluid={fluid}
        showInput={showInput}
        format={format}
        setShowPicker={setShowPicker}
        showPicker={showPicker}
        currentDate={currentDate}
        onDateSelected={onDateSelected}
      />

      {showPicker && currentDate !== null && (
        <DatePickerCalendar
          onDateClick={onDateSelected}
          currentDate={currentDate}
          pickerRef={pickerRef}
        />
      )}
    </div>
  );
};

export default DatePicker;
