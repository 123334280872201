import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { orderBy } from "lodash";

const GET_SCHOOLS = gql`
  query allSchoolsWithCourse($includeStudentsCount: Boolean!) {
    allSchoolsWithCourse {
      id
      name
      nickname
      coursesCount
      studentsCount @include(if: $includeStudentsCount)
    }
  }
`;

/*
  // Possibility of this prop going into the query
  // to filter the subjects in the school selected 
  // by the user within CoursesList

  courses {
    id
    name
    subject {
      id
      name
    }
  }
*/

const useGetSchools = includeStudentsCount => {
  const { loading, error, data } = useQuery(GET_SCHOOLS, {
    variables: { includeStudentsCount: includeStudentsCount ? true : false },
  });

  if (error) {
    console.log("Error useGetSchools: ", error);
  }

  let schools = [];

  if (data) {
    schools = orderBy(data.allSchoolsWithCourse, "name", "asc");
  }

  return [schools, loading];
};

export default useGetSchools;
