import React from "react";
import {Button, Trans} from "Components";
import useGroupInvitationHandler from "routes/Group/GroupInvitation/useGroupInvitationHandler";

const GroupInviteAcceptButton = ({openInvitation}) => {
  const {
    handleAcceptInvite,
    response,
    loading: acceptLoading,
  } = useGroupInvitationHandler(openInvitation);

  return (
    <Button
      type="primary"
      size="2xl"
      fluid
      onClick={handleAcceptInvite}
      loading={acceptLoading}
    >
      <Trans>Accept invitation</Trans>
    </Button>
  );
};

export default GroupInviteAcceptButton;
