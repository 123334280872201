import React from "react";
import useGetCurrentUserSubscription from "API/queries/user/useGetCurrentUserSubscription";
import {Alert, Skeleton, SubSection, Trans} from "Components";
import SubscriptionTrialBanner from "Components/SubscriptionTrialBanner/SubscriptionTrialBanner";
import SubscriptionStatus from "Components/SubscriptionStatus/SubscriptionStatus";
import AccountSubscriptionCurrentSubscriptionActions from "./AccountSubscriptionCurrentSubscriptionActions/AccountSubscriptionCurrentSubscriptionActions";

const AccountSubscriptionCurrentSubscription = ({}) => {
  const {
    subscription,
    loading,
    subscriptionActive,
    allSubscriptions,
  } = useGetCurrentUserSubscription(true, true, true);

  if (loading) return <Skeleton />;

  // If the user's subscription is not active, we show a message banner

  if (!subscriptionActive)
    return (
      <SubSection title={<Trans>Subscription status</Trans>}>
        <div>
          <Alert
            title={
              <Trans>
                You don't have any active subscriptions at this moment
              </Trans>
            }
          />
          <SubscriptionTrialBanner Component="Alert" className="mt-4" />
        </div>
      </SubSection>
    );

  return (
    <SubSection title={<Trans>Subscription status</Trans>}>
      <SubscriptionStatus
        {...subscription}
        showMessageBanner={true}
        actions={
          <AccountSubscriptionCurrentSubscriptionActions {...subscription} />
        }
      />
    </SubSection>
  );
};

export default AccountSubscriptionCurrentSubscription;
