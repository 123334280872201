import React from "react";
import {Button, Lottie} from "Components";

import {scoreToLabel} from "utilities/exerciseUtilities/formatScore";
import {motion} from "framer-motion";

const soundEffect = new Audio("/assets/sounds/sparkle.mp3");

const ExerciseEvaluationDifficultyAssessmentButton = ({
  type,
  score,
  onClick,
  disabled,
  className,
}) => {
  const [clicked, setClicked] = React.useState(false);

  const handleClick = () => {
    if (!disabled) {
      onClick();
      setClicked(true);
      if (!clicked) {
        soundEffect.volume = 0.5;
        soundEffect.play();
      }
    }
  };

  return (
    <Button
      fluid
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className={className}
    >
      <span className="relative">
        <motion.span>{scoreToLabel(score)}</motion.span>
        {clicked && score < 3 && (
          <motion.span
            key={clicked}
            className="absolute left-4 inset-y-0 transform -translate-y-1/2 -translate-x-1/2 w-32 aspect-square z-10"
            initial={{
              display: "block",
            }}
            animate={{
              display: "none",
            }}
            transition={{
              duration: 1,
              delay: 1,
            }}
          >
            <React.Suspense fallback={<></>}>
              <Lottie animation="confetti" loop={false} />
            </React.Suspense>
          </motion.span>
        )}
      </span>
    </Button>
  );
};

export default ExerciseEvaluationDifficultyAssessmentButton;
