import React from "react";

const UseSignupStatePreferredEmailExtension = (emailExtension, values) => {
  const [preferredEmailExtension, setPreferredEmailExtension] = React.useState(
    emailExtension
  );

  // If values include a different email extension, set the preferred email extension to null
  React.useEffect(() => {
    if (emailExtension === null) return;

    const inputtedEmailExtension = values.email.split("@")[1];

    if (
      values.email === null ||
      values.email === "" ||
      inputtedEmailExtension === undefined
    ) {
      setPreferredEmailExtension(emailExtension);
      return;
    }

    if (
      values.email &&
      inputtedEmailExtension !== undefined &&
      inputtedEmailExtension !== emailExtension
    ) {
      setPreferredEmailExtension(null);
    }
  }, [values.email, emailExtension]);

  return preferredEmailExtension;
};

export default UseSignupStatePreferredEmailExtension;
