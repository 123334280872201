import React from "react";
import useKeyboardShortcuts from "utilities/hooks/useKeyboardShortcuts";
import useGPTMessagesState from "./hooks/useGPTMessagesState";
import useGPTRequest from "./hooks/useGPTRequest/useGPTRequest";
import useGPTSystemMessages from "./hooks/useGPTSystemMessages/useGPTSystemMessages";
import useFocusedState from "../../utilities/hooks/useFocusedState";
import useGptProviderState from "./hooks/useGPTProviderState";
import useGetCurrentUserCredits from "API/queries/user/useGetCurrentUserCredits";
import useGPTMetadata from "Components/GPT/hooks/useGPTMetadata/useGPTMetadata";
import useGPTSettings from "Components/GPT/hooks/useGPTSettings/useGPTSettings";
import useGPTEstimateCost from "Components/GPT/hooks/useGPTEstimateCost";
import useGPTDisplayModalState from "Components/GPT/GPTDisplay/useGPTDisplayModalState";
import Trans from "Components/Trans";
import useGPTFreeTierQuota from "Components/GPT/hooks/useGPTFreeTierQuota";

export const GPTContext = React.createContext(null);

window.GPTContext = GPTContext;

const GPTProvider = ({
  children,
  dependencies = [],
  initialMessages = [],
  initialPrompt,
  key = "default",
  modal = false,
  onModalClose,
  className,
}) => {
  /**
   * State for tracking if the prompt input is focused
   */
  const {
    focused: promptInputFocused,
    handleFocus: handlePromptInputFocus,
    handleBlur: handlePromptInputBlur,
    hasBeenFocused: promptInputHasBeenFocused,
  } = useFocusedState();

  const {
    credits,
    loading: creditsLoading,
    refetch: refetchCredits,
  } = useGetCurrentUserCredits();

  /**
   * General state for the chat
   */
  const {
    error,
    submitted,
    prompt,
    promptEmpty,
    sessionId,
    resetState,
    resetPrompt,
    handlePromptChange,
  } = useGptProviderState({initialPrompt, key});

  /**
   * State for settings
   */

  const {settings, ...settingsActions} = useGPTSettings();

  /**
   * State for the storing and managing messages
   */

  const {
    messages,
    addMessage,
    resetMessages,
    setMessages,
    setMessageById,
    lastPrompt,
    lastCompletion,
    messagesNotEmpty,
    messagesLoading,
    removeSystemMessage,
  } = useGPTMessagesState({initialMessages});

  /**
   * Hook for managing the system messages
   */

  const {
    loading: setSystemMessageLoading,
    setSystemMessage,
  } = useGPTSystemMessages({
    removeSystemMessage,
    addMessage,
  });

  /**
   * Hook for managing the metadata of the chat, e.g. exerciseId and text
   */

  const {metadata, loading: metadataLoading} = useGPTMetadata();

  /**
   * Hook for managing the GPT request
   */
  const {generateHint: handleSubmit, stopRequest} = useGPTRequest({
    prompt,
    messages,
    addMessage,
    setMessageById,
    resetPrompt,
    sessionId,
    refetchCredits,
    metadata,
    settings,
  });

  const {estimatedCost, hasEnoughCredits} = useGPTEstimateCost({
    prompt,
    metadata,
    settings,
    credits,
    messages,
  });

  const {loading: freeTierQuotaLoading, freeTierQuota} = useGPTFreeTierQuota({
    sessionId,
  });

  /**
   * Handlers dependent on multiple states
   */

  const handleContinueClick = () => {
    //  Add a system message to the chat with the content "Continue"
    if (lastCompletion?.id) {
      setMessageById({
        id: lastCompletion.id,
        loading: true,
        tokens: 0, // reset the tokens so we can count the number of tokens in the next request
        processed: false,
      });
    }
  };

  const handleStopClick = () => {
    stopRequest();
    if (lastCompletion) {
      setMessageById({
        id: lastCompletion.id,
        loading: false,
      });
    }
  };

  const resetConversation = () => {
    resetMessages();
    resetState();
  };

  /**
   * variables dependent on multiple states
   * @type {boolean}
   */

  const loading =
    messagesLoading ||
    setSystemMessageLoading ||
    creditsLoading ||
    metadataLoading ||
    freeTierQuotaLoading;

  let disabled =
    loading ||
    promptEmpty ||
    (!hasEnoughCredits && freeTierQuota?.available === 0);

  const chatActive = messagesNotEmpty || submitted || error;

  /**
   * Keyboard shortcuts
   */

  useKeyboardShortcuts(
    [
      {
        key: "Enter",
        ctrl: true,
        action: () => {
          if (promptInputFocused && !disabled) {
            handleSubmit();
          }
        },
      },
    ],
    [prompt, disabled]
  );

  const {Wrapper, toggleModal, showAsModal} = useGPTDisplayModalState({
    modal,
  });

  return (
    <GPTContext.Provider
      value={{
        prompt,
        promptEmpty,
        error,
        submitted,
        chatActive,
        loading,
        disabled,
        messages,
        handleSubmit,
        lastPrompt,
        lastCompletion,
        handlePromptChange,
        resetConversation,
        setSystemMessage,
        removeSystemMessage,
        handleContinueClick,
        handleStopClick,
        promptInputFocused,
        promptInputHasBeenFocused,
        handlePromptInputFocus,
        handlePromptInputBlur,
        metadata,
        settings,
        ...settingsActions,
        estimatedCost,
        hasEnoughCredits,
        freeTierQuota,
        toggleModal: modal ? onModalClose : toggleModal,
        showAsModal: modal ? true : showAsModal,
      }}
    >
      <Wrapper
        defaultOpen
        className={className}
        closable={modal}
        maskClosable={false}
        onClose={onModalClose}
      >
        {children}
      </Wrapper>
    </GPTContext.Provider>
  );
};

export default GPTProvider;
