import React from "react";
import LandingPageSection from "Components/LandingPageSection/LandingPageSection";
import { ErrorBoundary } from "Components";

const Summer2022CampaignIntro = ({ landingPageSections }) => {
  let section = React.useMemo(
    () => landingPageSections.find(section => section.order === 1),
    []
  );

  return (
    <ErrorBoundary>
      <div className="px-10 relative">
        <img
          className="absolute top-0 left-1/3 w-full h-full opacity-10 "
          src="https://d1mgntrf3vaj6d.cloudfront.net/images/logo.svg"
          alt=""
        />
        <LandingPageSection sectionId={section.sys.id} />
      </div>
    </ErrorBoundary>
  );
};

export default Summer2022CampaignIntro;
