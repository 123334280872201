import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

const QUERY = gql`
  query getAllPrograms {
    programCollection {
      total
      items {
        sys {
          id
        }
        id
        name
        nickname
        logo {
          title
          url
        }
      }
    }
  }
`;

const useGetAllPrograms = () => {
  let result = [];

  const {loading, error, data} = useQuery(QUERY, {
    fetchPolicy: "cache-first",
    context: {
      api: "contentful",
    },
  });

  if (error) {
    console.log("Error in useGetAllPrograms", error);
    result = null;
  }

  if (data && data.programCollection) {
    if (data.programCollection.items.length > 0) {
      result = data.programCollection.items;
    }
  }

  return [result, loading];
};

export default useGetAllPrograms;
