import React from "react";
import {useHistory} from "react-router-dom";
import {message, Trans} from "Components";
import {logout} from "utilities/authentication";

const Logout = () => {
  React.useEffect(() => {
    if (window.FB) {
      window.FB.logout(); // Logout from Facebook as well
    }

    message.info(<Trans>Logged out!</Trans>);

    logout("/");
  }, []);

  return null;
};

export default Logout;
