import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { message, Trans } from "Components";
import { GET_CURRENT_USER_PAYMENT_METHODS } from "API/queries/paymentMethods/useGetCurrentUserPaymentMethods";

const DETACH_PAYMENT_METHOD_FROM_CURRENT_USER = gql`
  mutation detachPaymentMethodFromCurrentUser($paymentMethodId: String!) {
    detachPaymentMethodFromCurrentUser(paymentMethodId: $paymentMethodId) {
      ok
      error
    }
  }
`;

const useDetachPaymentMethodFromCurrentUser = paymentMethodId => {
  const [
    detachPaymentMethodFromCurrentUser,
    { data, loading, error },
  ] = useMutation(DETACH_PAYMENT_METHOD_FROM_CURRENT_USER, {
    variables: { paymentMethodId },
    refetchQueries: [
      {
        query: GET_CURRENT_USER_PAYMENT_METHODS,
      },
    ],
    onCompleted: data => {
      if (data.detachPaymentMethodFromCurrentUser?.ok) {
        message.success(<Trans>Payment method has been removed</Trans>);
      }
    },
  });

  if (loading) {
    message.loading(<Trans>Removing payment method...</Trans>);
  }

  if (error || data?.detachPaymentMethodFromCurrentUser?.error) {
    message.error(<Trans>An error occurred. Please try again</Trans>);
  }

  return {
    detachPaymentMethodFromCurrentUser,
    data,
    loading,
  };
};

export default useDetachPaymentMethodFromCurrentUser;
