/**
 * Looks at strips of 10px in y-directions and removed pixels that are all white (255,255,255)
 * @param context
 * @constructor
 */
const Vacuum = (context, step) => {
  const width = context.canvas.width;
  const height = context.canvas.height;
  let offset = 0;

  let newImageData = new Uint8ClampedArray(width * height * 4);

  // Loop through strips of 10px in y-direction
  for (let y = 0; y < height; y += step) {
    const imageData = context.getImageData(0, y, width, step);
    const data = [...imageData.data];

    // Get the average color of the strip and check if it is all white
    const allWhite = checkAllWhite(data);

    if (allWhite) {
      // Set the strip to red
      for (let i = 0; i < data.length; i += 4) {
        data[i] = 255;
        data[i + 1] = 0;
        data[i + 2] = 0;
        data[i + 3] = 50;
      }
      continue;
    } else {
      // append the strip to the new image data
      let currentOffset = width * 4 * offset;

      if (offset + data.length > newImageData.length) {
        continue;
      }

      newImageData.set(data, offset);
      offset += data.length;
    }
  }

  // Image data from array

  const newImageDataObject = new ImageData(newImageData, width, height);

  // Put the modified strip back on the canvas
  context.putImageData(newImageDataObject, 0, 0);
  // Rerender
  // context.canvas.dispatchEvent(new Event("render"));
};

export const checkAllWhite = (data, threshold = 765) => {
  const avgColor =
    data.reduce((acc, curr, index) => {
      if (index % 4 === 3) return acc; // Skip alpha channel
      return acc + curr;
    }, 0) /
    (data.length / 4);

  return avgColor >= threshold;
};

export default Vacuum;
