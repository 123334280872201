import React from "react";
import useGptRequestUsers from "Components/GPT/hooks/useGPTRequest/useGPTRequestUsers";
import useGptRequestCallApi from "Components/GPT/hooks/useGPTRequest/useGPTRequestCallAPI";

export const MAX_TOKENS = 200;

const useGPTRequest = ({
  prompt,
  messages,
  addMessage,
  setMessageById,
  resetPrompt,
  sessionId,
  refetchCredits,
  metadata,
  settings,
}) => {
  const {senderUser, senderAssistant} = useGptRequestUsers();

  const generateHint = React.useCallback(
    async (promptOverride = null) => {
      let content = prompt;

      if (promptOverride !== null && typeof promptOverride === "string") {
        content = promptOverride;
      }

      // Push prompt to messages
      addMessage({
        id: new Date().getTime() - 1,
        content,
        role: "user",
        processed: true, // no need to process the prompt
        loading: false, // no need to show loading indicator
        sender: senderUser,
      });

      // Clear prompt
      resetPrompt();

      // Add an empty completion which will be the container for the resqponse from the API
      const loadingResult = messages.find(result => result.loading);

      // Make sure there is not already a loading result
      if (loadingResult === undefined) {
        // create empty result with loading state which will trigger the effect for the result
        addMessage({
          content: "",
          loading: true, // set loading to true to show loading indicator
          processed: false,
          role: "assistant",
          sender: senderAssistant,
        });
      }
    },
    [prompt, messages]
  );

  const {stopRequest} = useGptRequestCallApi({
    messages,
    setMessageById,
    sessionId,
    refetchCredits,
    metadata,
    settings,
  });

  return {generateHint, stopRequest};
};

export default useGPTRequest;
