import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { GET_CURRENT_USER_NOTIFICATIONS } from "../../queries/notification/useGetCurrentUserNotifications"

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($id: ID!) {
    markNotificationAsRead(id: $id) {
      ok
      error
    }
  }
`;

const useMarkNotificationAsRead = () => {
  const [
    markNotificationAsRead,
    { data, error, loading },
  ] = useMutation( MARK_NOTIFICATION_AS_READ, {
    refetchQueries: [ { query: GET_CURRENT_USER_NOTIFICATIONS } ],
    awaitRefetchQueries: true,
  } );

  if ( error ) {
    console.log( "error at mark notification as read: ", error );
  }
  return [ markNotificationAsRead, data, error, loading ];
};

export default useMarkNotificationAsRead;
