import MD5 from "crypto-js/md5";

const examDocumentsMatching = (output, resources) => new Promise(async resolve => {
  for (const r of resources) {
    // Quickfix for the case where the resource has no hash but the hash is in the url

    // Take the first suggested date
    // TODO: Handle resources with multiple dates

    let date = r.date;

    if (r.dates?.length > 0) {
      date = r.dates[0];
    }

    const resource = {
      ...r,
      date,
      type: r.type || "unknown",
    }; // Use a copy of the resource to avoid mutating the original

    //  Get the suggested dates of the document
    const { type } = resource;

    output["all"].push(resource); // to keep track of all the resources

    if (!date) {
      output["unknown_date"].push(resource);
      continue;
    }

    if (!output["dates"][date]) {
      // If not, create a new document and set according to the type
      output["dates"][date] = {
        files: [],
        saved: true,
        hidden: true,
      };
    }

    const targetBin = output["dates"][date];

    // If the resource is not saved, set the saved field to false for the whole row
    if (!resource.saved) {
      targetBin.saved = false;
    }

    // If the resource is not hidden, set the hidden field to false to show the row
    if (!resource.hidden) {
      targetBin.hidden = false;
    }

    // if the resource is already saved, no need to check if it exists because it definitely does
    if (resource.saved) {
      targetBin.files.push(resource);
      continue;
    }

    await addIfNotExists(targetBin["files"], resource);
  }

  // Unhide hidden files in non-hidden groups and set the group to saved if all files are saved
  for (const date in output["dates"]) {
    const { hidden, files } = output["dates"][date];
    if (!hidden) {
      for (const file of files) {
        file.hidden = false;
      }
    }
    if (files.every(f => f.saved)) {
      output["dates"][date].saved = true;
    }
  }

  resolve(output);
});

const getNewHash = url => new Promise ((resolve, reject) => {
  let request = new XMLHttpRequest();
  request.open("GET", url, true);
  request.responseType = "blob";
    request.onload = function() {
      let reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = function(e) {
          const data = e.target.result
          const hash = MD5(data).toString();
          console.log("new hash", hash)
          resolve(hash);
        }
    }
    request.send();
})

const getHash = async file => {
  if(file.hash) {
    return hash
  }
  if (!file.url) {
    return null;
  }
  const urlParts = file.url.split("/");
  if (urlParts.includes("resources")) {
    return urlParts
        .pop()
        .split("-")[0];
  }

  return getNewHash(file.url);
}

const addIfNotExists = async (arr, item) => {
  // check if an item exists with the same hash
  const hash = await getHash(item);
  for (const i of arr) {
    const iHash = await getHash(i);
    if (iHash === hash) {
      return;
    }
  }
    arr.push(item);
};

export default examDocumentsMatching;
