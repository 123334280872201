import React from "react";
import useUpdateCurrentUserMutation from "API/mutations/user/useUpdateCurrentUserMutation";
import useJoinGroup from "API/mutations/groups/useJoinGroup";
import {GET_GROUP} from "API/queries/groups/useGetGroup";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {useHistory} from "react-router-dom";
import {userContext} from "UserContextProvider";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";

const useGroupOnboarding = () => {
  const {id, subGroups, currentUserIsMember} = useGroupContext();

  const [updateUser, updateUserLoading] = useUpdateCurrentUserMutation();
  const history = useHistory();

  const [state, setState] = React.useState({
    selectedSubGroupId: subGroups.length === 0 ? id : null,
    selectedProgramId: null,
    enrollmentYear: null,
  });

  const {joinGroup, loading} = useJoinGroup({
    refetchQueries: [
      {
        query: GET_GROUP,
        errorPolicy: "all",
        variables: {
          id,
          includeSubGroups: true,
          includeMembers: false,
        },
      },
    ],
  });

  const handleSubGroupChange = (subGroupId, programId) => {
    setState({
      ...state,
      selectedSubGroupId: subGroupId,
      selectedProgramId: programId,
    });
  };

  const handleEnrollmentYearChange = year => {
    setState({
      ...state,
      enrollmentYear: year,
    });
  };

  const handleSubmit = async () => {
    // update user properties with new programId and enrollmentYear

    const updateResponse = await updateUser({
      variables: {
        programId: state.selectedProgramId,
        enrollmentYear: Number(state.enrollmentYear),
      },
    });

    // join the subgroup
    const joinResponse = await joinGroup({
      variables: {
        groupId: state.selectedSubGroupId,
      },
    });

    if (joinResponse?.data?.currentUserCreateGroupMembership?.ok) {
      history.push(`/home`);
    }
  };

  React.useEffect(() => {
    if (currentUserIsMember) {
      history.replace(`/home`);
    }
  }, []);

  const requireProgram = subGroups?.length > 0;
  const submitDisabled =
    requireProgram && Object.values(state).some(value => value === null);

  return {
    ...state,
    handleSubGroupChange,
    handleEnrollmentYearChange,
    submitDisabled,
    handleSubmit,
    loading: loading || updateUserLoading,
    requireProgram,
  };
};

export default useGroupOnboarding;
