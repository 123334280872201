import React from "react";
import useSubmitForm from "API/sheets";
import {message, Trans} from "Components";

const useRequestSchool = onComplete => {
  const {user} = React.useContext(window.UserContext);

  const [state, setState] = React.useState({
    schoolName: "",
  });

  const handleChange = event =>
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });

  const {success, error, loading, submit} = useSubmitForm(
    "SCHOOL_REQUEST_FORM"
  );

  React.useEffect(() => {
    if (success) {
      // Clear the form
      setState({
        schoolName: "",
      });

      setTimeout(onComplete, 3000);
    }
  }, [success]);

  const onSubmit = event => {
    event.preventDefault();
    submit({
      ...state,
      userId: user?.id,
      email: user?.email,
    });
  };

  return {
    onSubmit,
    handleChange,
    loading,
    error,
    success,
    state,
  };
};

export default useRequestSchool;
