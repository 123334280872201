import React from "react";
import PropTypes from "prop-types";

const useCourseRouterLink = link => {
  const { id } = React.useContext(window.CourseContext);

  if (link) {
    return `/course/${id}${link}`;
  } else {
    // Return a function that returns the link
    return link => `/course/${id}${link}`;
  }
};

useCourseRouterLink.propTypes = {
  /**
   * The link to redirect to (e.g. /overview)
   */
  link: PropTypes.string.isRequired,
};

export default useCourseRouterLink;
