import React from "react";
import {Icon, Typography} from "Components";

const ProductCardStats = ({stats}) => {
  return (
    <div className="mt-2 flex gap-4 w-full">
      {stats.map((stat, index) => (
        <div className="flex items-center gap-1" key={"stat-" + index}>
          <Icon icon={stat.icon} size="md" />
          <Typography type="md:tmd tsm" weight="md" color="text-gray-light">
            {stat.text}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default ProductCardStats;
