import React from "react";
import PropTypes from "prop-types";
import { kollinBlue } from "../../utilities/constants/COLORS";
import "./TagChip.css";

const TagChip = ({ children, style, onClick, active, ellipsis }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  let defaultStyle = {};

  if (active || isHovered) {
    defaultStyle = {
      opacity: 1,
      transform: "scale(1.01)",
      background: kollinBlue,
      color: "white",
    };
  }

  return children ? (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      className="tagchip"
      style={{
        ...defaultStyle,
        ...style,
      }}
    >
      {children}
    </div>
  ) : null;
};

TagChip.propTypes = {
  children: PropTypes.instanceOf( Element ).isRequired,
  style: PropTypes.objectOf( PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number,
  ] ) ),
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
}

export default TagChip;
