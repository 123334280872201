import { useMutation } from "@apollo/react-hooks";
import { GET_COURSE_REVIEWS } from "API/queries/reivew/useGetCourseReviews";
import gql from "graphql-tag";

const UPDATE_COURSE_REVIEW = gql`
  mutation updateCourseReview(
    $score: Int!,
    $title: String,
    $text: String,
    $courseId: ID!
  ) {
    updateCourseReview(
      score: $score,
      title: $title,
      text: $text,
      id: $courseId
    ) {
      ok
      error
    }
  }
`;

const useUpdateCourseReviewMutation = ( courseId, callback ) => {
  const [ updateCourseReview, { data, error, loading } ] = useMutation(
    UPDATE_COURSE_REVIEW, {
    refetchQueries: () => [
      {
        query: GET_COURSE_REVIEWS, variables: { courseId, loggedIn: true }
      },
    ],
    onCompleted: callback
  }
  );

  if ( error ) {
    console.log( `Error in useUpdateCourseReviewMutation: ${error}` );
  }

  return { updateCourseReview, loading };
};

export default useUpdateCourseReviewMutation;
