/**
 * Compares two paths regardless of variables,
 * e.g. /courses/:courseId and /courses/10 should return true
 *
 * @param pathDefinition
 * @param currentPath
 */

const paramAgnosticPathCompare = (pathDefinition, currentPath) => {
  // Fallback for empty args
  if (!currentPath || !pathDefinition) return false;

  // We split the path definition and the path into an array of strings
  const pathDefinitionArray = pathDefinition.split("/");
  const currentPathArray = currentPath.split("/");

  // If the length of the two arrays are not the same, the paths are not the same
  if (pathDefinitionArray.length !== currentPathArray.length) return false;

  // Traverse the arrays and compare each element

  for (let i = 0; i < pathDefinitionArray.length; i++) {
    if (pathDefinitionArray[i].startsWith(":")) continue; // Skip variables
    if (pathDefinitionArray[i] !== currentPathArray[i]) return false;
  }

  return true;
};

export default paramAgnosticPathCompare;
