import React from "react";
import { Button, Trans } from "Components";

const ProductCardActions = ({
  selectable,
  handleSelect,
  selected,
  vertical,
}) => {
  if (!selectable) return null;

  if (selected) {
    return (
      <Button
        size="sm"
        fluid
        type="destructive"
        onClick={handleSelect}
        iconLeft="cart-shopping"
      >
        <Trans>Remove</Trans>
      </Button>
    );
  }

  return (
    <Button
      size="sm"
      type="secondary"
      onClick={handleSelect}
      fluid
      iconLeft="cart-shopping"
    >
      <Trans>Add</Trans>
    </Button>
  );
};

export default ProductCardActions;
