import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const CREATE_USER_EVALUATION = gql`
  mutation createUserEvaluation(
    $objectId: ID!
    $objectName: String!
    $source: String!
    $evaluation: JSON!
  ) {
    createUserEvaluation(
      objectId: $objectId
      objectName: $objectName
      source: $source
      evaluation: $evaluation
    ) {
      ok
      error
      userEvaluation {
        id
        objectName
        objectId
        source
        evaluation
        createdAt
        updatedAt
      }
    }
  }
`;

const useCreateUserEvaluation = update => {
  const [createUserEvaluation, { data, loading, error }] = useMutation(
    CREATE_USER_EVALUATION,
    {
      // write it to cache as it is loaded
      update,
    }
  );

  if (error) {
    console.log(`Error  in useCreateUserEvaluation ${error}`);
  }

  return [createUserEvaluation, loading];
};

export default useCreateUserEvaluation;
