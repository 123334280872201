import React from "react";
import {Button, Input, Trans} from "Components";
import PaymentMethodSwishStatusModal from "./PaymentMethodSwishStatusModal/PaymentMethodSwishStatusModal";
import usePaymentMethodSwishState from "./usePaymentMethodSwishState";
import classnames from "classnames";

const SwishPaymentMethod = ({
  action,
  paymentRequestId,
  loading,
  disabled,
  inlineForm = false,
  showTitle = true,
  showStatusModal = true,
  onCancel,
  onSuccess,
  actionFailed,
}) => {
  const {
    error,
    submitted,
    phoneNumber,
    onChange,
    submit,
    handleCancel,
  } = usePaymentMethodSwishState({action, onCancel, actionFailed});

  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (disabled || loading) return;
          submit();
        }}
      >
        <div
          className={classnames(
            "flex gap-4",
            inlineForm ? "flex-row" : "flex-col"
            // submitted && "hidden"
          )}
        >
          {showTitle && (
            <Trans>Enter your phone number to pay with swish</Trans>
          )}

          <Input
            disabled={disabled || submitted}
            id={"phoneNumber"}
            name={"phoneNumber"}
            type={"text"}
            placeholder="0712345678"
            required={true}
            value={phoneNumber}
            error={error}
            onChange={onChange}
          />
          <Button
            disabled={disabled || submitted}
            loading={loading}
            type="primary"
            name="submit"
          >
            <Trans>Swish now</Trans>
          </Button>
        </div>
      </form>
      {paymentRequestId && (
        <PaymentMethodSwishStatusModal
          modal={showStatusModal}
          paymentRequestId={paymentRequestId}
          inline={inlineForm}
          onCancel={handleCancel}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default SwishPaymentMethod;
