import i18n from "i18next";

const contributionDescriptions = {
  Source: {
    verb: i18n.t("Has uploaded"),
    noun: {
      plural: i18n.t("documents"),
      singular: i18n.t("document"),
    },
    credits: 50, // Points per contribution
  },
  TagVote: {
    verb: i18n.t("Has tagged"),
    noun: {
      plural: i18n.t("exercises"),
      singular: i18n.t("exercise"),
    },
    credits: 0, // Points per contribution
  },
  Comment: {
    verb: i18n.t("Has posted"),
    noun: {
      plural: i18n.t("comments"),
      singular: i18n.t("comment"),
    },
    credits: 0, // Points per contribution
  },
  Segment: {
    verb: i18n.t("Has created"),
    noun: {
      plural: i18n.t("exercises"),
      singular: i18n.t("exercise"),
    },
    credits: 5, // Points per contribution
  },
  ExerciseNote: {
    verb: i18n.t("Has published"),
    noun: {
      plural: i18n.t("notes"),
      singular: i18n.t("note"),
    },
    credits: 0, // Points per contribution
  },
};

export default contributionDescriptions;
