import Trans from "Components/Trans";
import GroupHome from "routes/Group/GroupHome/GroupHome";
import GroupMembers from "routes/Group/GroupMembers/GroupMembers";
import GroupCourses from "routes/Group/GroupCourses/GroupCourses";
import GroupAdminUpload from "routes/Group/GroupAdminUpload/GroupAdminUpload";
import React from "react";
import MyCourses from "routes/MyCourses/MyCourses";
import FindCourses from "routes/FindCourses/FindCourses";
import GroupInvitation from "routes/Group/GroupInvitation/GroupInvitation";
import GroupSettings from "routes/Group/GroupSettings/GroupSettings";

const groupRoutes = {
  "/home": {
    path: "/home",
    label: <Trans>Home</Trans>,
    icon: "house",
    Component: GroupHome,
    mobile: true,
    group: "group-routes",
  },
  "/members": {
    path: "/members",
    label: <Trans>Members</Trans>,
    icon: "user",
    Component: GroupMembers,
    mobile: true,
    group: "group-routes",
  },
  "/courses": {
    path: "/courses",
    label: <Trans>Program plan</Trans>,
    icon: "book",
    Component: GroupCourses,
    mobile: true,
    group: "group-routes",
  },
  "/my-courses": {
    path: "/my-courses",
    label: <Trans>My courses</Trans>,
    icon: "bookmark",
    Component: MyCourses,
    componentProps: {},
    mobile: true,
    group: "group-routes",
  },
  "/find-courses": {
    path: "/find-courses",
    label: <Trans>Find courses</Trans>,
    icon: "telescope",
    Component: FindCourses,
    mobile: true,
    group: "group-routes",
    hideInNavigation: true,
  },
  "/upload": {
    path: "/upload",
    label: <Trans>Invite members</Trans>,
    icon: "user-plus",
    Component: GroupAdminUpload,
    mobile: true,
    group: "group-routes",
    admin: true,
  },
  "/invite": {
    path: "/invite",
    label: <Trans>Welcome</Trans>,
    icon: "user-plus",
    Component: GroupInvitation,
    componentProps: {
      openInvitation: true,
    },
    mobile: true,
    hideInNavigation: true,
    hidden: true,
    group: "auth", // route type
  },
  "/settings": {
    path: "/settings",
    label: <Trans>Settings</Trans>,
    icon: "cog",
    Component: GroupSettings,
    mobile: false,
    group: "group-routes",
    admin: true,
  },
  "/": {
    path: "/",
    Component: GroupHome,
    exact: true,
    group: "group-routes",
  },
};

export default groupRoutes;
