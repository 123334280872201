import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import formatExercise from "utilities/exerciseUtilities/formatExercise";
import {uniqueBy} from "utilities/objects";

export const GET_TAG_EXERCISES = gql`
  query getTagExercises($tagId: ID!, $courseId: ID!) {
    getTag(id: $tagId) {
      id
      name
      exercises(course_id: $courseId) {
        id
        number
        score
        imageUrl
        tags {
          id
          name
        }
        self_evaluation {
          id
          score
          date
        }
        bookmarked
        notes {
          id
          text
        }
        stats {
          total
        }
        source {
          id
          date
          author
        }
      }
    }
  }
`;

const useGetTagExercises = (tagId, courseId) => {
  let exercises = [];

  const {loading, error, data} = useQuery(GET_TAG_EXERCISES, {
    variables: {tagId, courseId},
  });

  if (error) {
    console.log("Error in useGetCourseExercises: ", error);
  }

  if (data) {
    exercises = uniqueBy(
      data.getTag.exercises.map(ex => formatExercise(ex)),
      "id"
    );
  }

  return [exercises, loading];
};

export default useGetTagExercises;
