import React from "react";
import useGetProduct from "API/queries/product/useGetProduct";
import {Icon, Skeleton, Typography} from "Components";
import CDN from "utilities/constants/CDN";
import {getCleanCourseName} from "utilities/formatCourseName";
import {useTranslation} from "react-i18next";

const AccountOrdersProduct = ({productId, productType}) => {
  const {t: trans} = useTranslation();
  const [product, loading] = useGetProduct(productType, productId);

  if (loading) return <Skeleton />;

  let cleanName =
    productType === "COURSE"
      ? getCleanCourseName(product.name, true)
      : product.name.split(" - ")[0];

  if (productType === "CREDITS_BUNDLE") {
    cleanName = cleanName.replace("credits", trans("credits"));
  }
  return (
    <div className="flex gap-2 items-center">
      <img src={getImageUrl(product)} className="w-8" />
      <Typography
        type="tsm"
        weight="md"
        color="text-gray-light"
        className="truncate"
      >
        {cleanName}
      </Typography>
    </div>
  );
};

const getImageUrl = product => {
  if (product.icon) return `${CDN}/course-icons/${product.icon}.svg`;

  if (product.imageUrl) return product.imageUrl;

  return null;
};

export default AccountOrdersProduct;
