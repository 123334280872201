import React from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";

const UPDATE_EXAM = gql`
  mutation updateSource(
    $id: ID!
    $date: String
    $author: String
    $exercises_src: String
    $solutions_src: String
    $exam_exercises_src: String
    $exam_solutions_src: String
    $exercisesFile: Upload
    $solutionsFile: Upload
    $exercisesMarkingCompleted: Boolean
    $solutionsMarkingCompleted: Boolean
  ) {
    updateSource(
      id: $id
      date: $date
      author: $author
      exercises_src: $exercises_src
      solutions_src: $solutions_src
      exam_exercises_src: $exam_exercises_src
      exam_solutions_src: $exam_solutions_src
      exercisesFile: $exercisesFile
      solutionsFile: $solutionsFile
      exercisesMarkingCompleted: $exercisesMarkingCompleted
      solutionsMarkingCompleted: $solutionsMarkingCompleted
    ) {
      source {
        id
        date
        author
        exercisesMarkingCompleted
        solutionsMarkingCompleted
        exercises_src
        solutions_src
        exam_exercises_src
        exam_solutions_src
      }
      ok
      errors {
        path
        message
      }
    }
  }
`;

const useUpdateExamMutation = (refetchQueries, onCompleted) => {
  const [updateExam, {loading, data, error}] = useMutation(UPDATE_EXAM, {
    refetchQueries,
    onCompleted,
  });

  if (error) {
    console.log("Error in useUpdateExamMutation: ", error);
  }

  return {updateExam, data, loading};
};

export default useUpdateExamMutation;
