import React from "react";
import { Summer2022CampaignAuthContainer } from "routes/Campaign/Summer2022Campaign/Summer2022CampaignAuth/Summer2022CampaignAuth";
import {
  AnimatedCheckbox,
  Button,
  Icon,
  Input,
  Trans,
  Typography,
} from "Components";
import useAccountCreditsStudentVerificationState from "routes/Account/AccountCredits/AccountCreditsStudentVerification/useAccountCreditsStudentVerificationState";
import useEmailVerificationStatusSubscription from "API/subscriptions/user/useEmailVerificationStatusSubscription";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";

const Summer2022CampaignAuthRecurringRedeemStudentVerification = () => {
  const {
    values,
    submit,
    errors,
    handleChange,
    loading,
    emailSent,
  } = useAccountCreditsStudentVerificationState();

  if (emailSent) {
    return <Summer2022CampaignAuthRecurringRedeemStudentVerificationStatus />;
  }

  return (
    <Summer2022CampaignAuthContainer title="Verifiera att du är student">
      <Typography color="text-gray-50">
        För att ta del av erbjudandet måste du först verifiera att du är
        student. Ange din universitets e-postadress så skickar vi en
        verifieringslänk till dig
      </Typography>
      <form className="flex flex-col gap-4 w-full mt-2" onSubmit={submit}>
        <Input
          type="Email"
          name="email"
          placeholder="namn@student.se"
          error={errors.email}
          onChange={handleChange}
          value={values.email}
          errorMessageRelative
        />
        <Button
          type="secondary"
          className="mt-4"
          onClick={submit}
          disabled={loading}
        >
          <Trans>Verify</Trans>
        </Button>
      </form>
    </Summer2022CampaignAuthContainer>
  );
};

const Summer2022CampaignAuthRecurringRedeemStudentVerificationStatus = () => {
  const [status, loading] = useEmailVerificationStatusSubscription();
  const [_user, _loading, refetch] = useGetCurrentUser();

  if (!status) {
    return (
      <Summer2022CampaignAuthContainer title="Verifiera att du är student">
        <Trans color="text-gray-50">
          A verification link has been sent to your email. Click on the link to
          verify your student account.
        </Trans>
        <div className="flex justify-center my-4">
          <Icon icon="spinner" spin size="2xl" color="text-gray-50" />
        </div>
      </Summer2022CampaignAuthContainer>
    );
  }

  if (status.ok) {
    refetch({
      includeMembership: false,
      includeStats: false,
    });

    return (
      <div>
        <div className="flex justify-center my-4">
          <AnimatedCheckbox />
        </div>
        <Trans>You have been verified as a student!</Trans>
      </div>
    );
  } else {
    return (
      <div>
        <div className="flex justify-center my-4">
          <Icon icon="exclamation-circle" color="text-error-500" size="2xl" />
        </div>
        <Trans>An unexpected error has occurred, please try again</Trans>
      </div>
    );
  }
};

export default Summer2022CampaignAuthRecurringRedeemStudentVerification;
