import useUpdateMembershipSubscriptionPlan from "API/mutations/membership/useUpdateMembershipSubscriptionPlan";
import useGetSubscriptionPlans from "API/queries/subscription/useGetSubscriptionPlans";
import React from "react";

import {message, Trans} from "Components";

const useSubscriptionChangePlan = ({currentPlan, subscriptionId}) => {
  // States ------------------------------------------------------------------
  const [showModal, setShowModal] = React.useState(false);
  const [selectedPlanId, setSelectedPlanId] = React.useState(null);

  // Queries -----------------------------------------------------------------
  const [
    subscriptionPlans,
    subscriptionPlansLoading,
  ] = useGetSubscriptionPlans();

  // Mutations ---------------------------------------------------------------
  const onCompleted = ({updateMembershipSubscriptionPlan}) => {
    if (updateMembershipSubscriptionPlan.ok) {
      setShowModal(false);
      message.success(<Trans>Your payment plan has been updated</Trans>);
    } else if (updateMembershipSubscriptionPlan.error) {
      message.error(updateMembershipSubscriptionPlan.error.message);
    }
  };

  const {
    updateMembershipSubscriptionPlan,
    loading: mutationLoading,
  } = useUpdateMembershipSubscriptionPlan(onCompleted);

  // Functions ---------------------------------------------------------------
  const onPlanChange = () => {
    updateMembershipSubscriptionPlan({
      variables: {
        subscriptionPlanId: selectedPlanId[0],
        membershipId: subscriptionId,
      },
    });
  };

  // Constructing data -------------------------------------------------------
  const currentPlanPrice = currentPlan.price;
  // allSubscriptionPlans includes the current plan, so we need to filter it out

  return {
    subscriptionPlans,
    subscriptionPlansLoading,
    mutationLoading,
    showModal,
    setShowModal,
    currentPlanPrice,
    selectedPlanId,
    setSelectedPlanId,
    onPlanChange,
  };
};

export default useSubscriptionChangePlan;
