import React from "react";
import {uniqBy} from "lodash";
import useCreateResourceMutation from "API/mutations/resource/useCreateResourceMutation";
import {Trans} from "Components";
import MD5 from "crypto-js/md5";
import {ExamUploadContext} from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContext";
import renameInputFile from "utilities/renameInputFile";

const useExamUploadDropzoneState = () => {
  const {id: courseId} = React.useContext(window.CourseContext);
  const {addResource, setFilesAdded, filesAdded} = React.useContext(
    ExamUploadContext
  );

  // const [courseResources, loading] = useGetCourseResources(courseId);

  const [errors, setErrors] = React.useState([]);

  const addError = error => {
    setErrors([...errors, error]);
  };

  const onResourceCreated = data => {
    const response = data.uploadResource;

    if (response.ok) {
      addResource(response.resource);
    } else if (response.error?.code === "RES05") {
      addResource(response.resource);
    } else {
      addError(response.error);
    }
  };

  const {createResource, loading} = useCreateResourceMutation(
    onResourceCreated
  );

  const [files, setFiles] = React.useState([]);

  const onFilesAdded = newFiles => {
    // Filter out files that are already in the list
    const newFilesArray = uniqBy([...files, ...newFiles], "path");
    setFiles(newFilesArray);
  };

  const onFilesFailed = files => {
    const newErrors = files.map(file => {
      const fileName = file.name;

      return (
        <Trans fileName={fileName}>
          File {{fileName}} failed to upload. Maximum allowed file size is 15 MB
        </Trans>
      );
    });

    setErrors(prev => [...prev, ...newErrors]);
  };

  // When the list of files change, create a resource for each file

  React.useEffect(() => {
    if (files.length > 0) {
      if (filesAdded === 0) {
        setFilesAdded(files.length);
      }

      files.forEach(inputFile => {
        const fileReader = new FileReader();

        /**
         *  Read the file as a binary string
         *  Compute the hash of the file
         *  Create a resource with the file and the hash
         */
        fileReader.onload = async event => {
          const data = event.target.result;
          const hash = MD5(data).toString();
          let [newName, extension] = inputFile.name.split(".");
          // Remove all non-alphanumeric characters from the file name
          newName = newName.replace(/[^a-zA-Z0-9_-]/g, "");
          const file = renameInputFile(inputFile, newName + "." + extension);

          await createResource({
            variables: {
              file,
              hash,
              courseId,
            },
          });
        };

        fileReader.readAsDataURL(inputFile);
      });
    }
  }, [files]);

  return {
    files,
    errors,
    onFilesAdded,
    onFilesFailed,
    loading: false,
  };
};

export default useExamUploadDropzoneState;
