import React from "react";
import {Trans} from "Components";
import {useTranslation} from "react-i18next";

const SubscriptionStatusPlan = ({name, price, interval}) => {
  const {t: trans} = useTranslation();

  let plan;

  switch (interval) {
    case "monthly":
      plan = trans("monthly");
      break;
    case "quarterly":
      plan = trans("3 months");

      break;
    case "semester":
      plan = trans("6 months");
      break;
    case "yearly":
      plan = trans("yearly");
    // Just in case, should not happen:
    default:
      plan = interval;
  }

  return (
    <Trans type="tlg" weight="md" plan={plan}>
      Kollin Gold {{plan}}
    </Trans>
  );
};

export default SubscriptionStatusPlan;
