import React from "react";
import { Button, Typography } from "Components";
import { Summer2022CampaignProgramContext } from "routes/Campaign/Summer2022Campaign/Summer2022Campaign";
import Masonry from "react-responsive-masonry";
import classnames from "classnames";

const Summer2022CampaignBottomCTA = ({ testimonials }) => {
  // Take all the images from the testimonials and put them in an array
  const { initiateOnBoarding, disabled } = React.useContext(
    Summer2022CampaignProgramContext
  );

  const testimonialImages = testimonials.map(
    testimonial => testimonial.image.url
  );

  if (disabled) {
    return null;
  }

  let columns = 3;
  if (testimonialImages.length % 3 !== 0) {
    columns = 2;
  }

  return (
    <div className="bg-primary-800 pt-8 pb-8 ">
      <div className="px-4">
        <Typography
          type="dsm"
          weight="sb"
          className="mb-3 mt-8 text-center"
          color="text-white"
        >
          Redo att joina dina kursare på Kollin?
        </Typography>

        <div className="mt-4">
          <Button
            key="submit"
            type="secondary"
            fluid
            onClick={initiateOnBoarding}
            iconRight="arrow-right-long"
          >
            Sätt igång gratis
          </Button>
        </div>
      </div>
      <div className="mt-8 -mx-8">
        {/*Create a mosaic of images */}
        {testimonialImages.length > 2 && (
          <Masonry columnsCount={columns} gutter="12px">
            {testimonialImages.map((imageUrl, index) => {
              let [w, h] = getAspectRatios(index);

              return (
                <div
                  key={index}
                  className={classnames(
                    "bg-top bg-cover",
                    "shadow-xl rounded-xl sepia-[.2]"
                  )}
                  style={{
                    aspectRatio: `${w}/${h}`,
                    backgroundImage: `url(${imageUrl}?f=face&fit=thumb&w=900&h=1600)`,
                  }}
                />
              );
            })}
          </Masonry>
        )}
      </div>
    </div>
  );
};

const getAspectRatios = index => AspectRatios[index % AspectRatios.length];
// Mixed size divs for the mosaic
const AspectRatios = [
  [2, 3],
  [9, 16],
  [2, 3],
  [1, 2],
  [2, 3],
  [9, 16],
];

export default Summer2022CampaignBottomCTA;
