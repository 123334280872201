import React from "react";
import {Badge, Button, TextArea, Toggle, Trans} from "../../../Components";
import {Icon} from "Components";

const GroupHomeFeedbackCard = () => {
  return (
    <div className="w-full bg-primary-50 rounded-lg p-4 mt-2">
      <div className="flex justify-start gap-2 items-center">
        <Icon
          icon="fas fa-comment-smile"
          size="xl"
          color="text-primary-300"
          inline
        />
        <Trans
          type="tlg"
          weight="sb"
          color="text-primary-600"
          className="leading-none"
        >
          You have some feedback to the SNO?
        </Trans>
        <Badge type="secondary" className="ml-auto" border={true}>
          <Trans>Coming soon</Trans>
        </Badge>
      </div>
      <div className="mt-4" />

      <TextArea className="w-full" disabled={true} />
      <div className="mt-2">
        <Toggle
          name="anonymous"
          defaultChecked={false}
          label={<Trans>Post anonymously</Trans>}
          onChange={() => {}}
        />
      </div>

      <div className="mt-4" />
      <Button className="w-full" type="primary">
        <Trans>Send</Trans>
      </Button>
    </div>
  );
};

export default GroupHomeFeedbackCard;
