import React from "react";

const useFocusedState = () => {
  const [focused, setFocused] = React.useState(false);

  const handleBlur = () => {
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const [hasBeenFocused, setHasBeenFocused] = React.useState(false);

  React.useEffect(() => {
    if (focused) {
      setHasBeenFocused(true);
    }
  }, [focused]);

  return {
    focused,
    handleBlur,
    handleFocus,
    hasBeenFocused,
  };
};

export default useFocusedState;
