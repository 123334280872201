import { useMutation } from "@apollo/react-hooks";
import { message, Trans } from "Components";
import gql from "graphql-tag";
import React from "react";

const CREATE_ORDER = gql`
  mutation createOrder(
    $discount: Int
    $products: [ProductInput!]
    $paymentMethod: PaymentMethod!
    $phoneNumber: String
    $stripeSuccessUrl: String
    $stripeCancelUrl: String
  ) {
    createOrder(
      discount: $discount
      products: $products
      paymentMethod: $paymentMethod
      phoneNumber: $phoneNumber
      stripeSuccessUrl: $stripeSuccessUrl
      stripeCancelUrl: $stripeCancelUrl
    ) {
      ok
      error
      order {
        id
        paymentMethod
        chargeId
      }
    }
  }
`;

const useCreateOrder = () => {
  const [createOrder, { data, loading, error }] = useMutation(CREATE_ORDER);

  if (error) {
    console.log(`Error in useCreateOrder`, error);
    message.error(<Trans>Unexpected error occurred! Please try again</Trans>);
  }

  if (loading) {
    message.loading(<Trans>Creating your order</Trans>);
  }

  return {
    createOrder,
    data,
    loading,
    error,
  };
};

export default useCreateOrder;
