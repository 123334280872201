import React from "react";
import {Button, ProductPrice, Trans, Typography} from "Components";
import useHandleCoursePurchaseClick from "utilities/hooks/useHandleCoursePurchaseClick";
import {UserContext} from "UserContextProvider";
import SubscriptionTrialBanner from "../../../../../Components/SubscriptionTrialBanner/SubscriptionTrialBanner";
import GroupDiscountsBanner from "Widgets/Group/GroupDiscounts/GroupDiscountsBanner/GroupDiscountsBanner";
import {GroupDiscountsFreeWrapper} from "Widgets/Group/GroupDiscounts/GroupDiscountsPriceWrapper";

const CourseLandingUnlockCard = () => {
  const {course, productPrice} = React.useContext(window.CourseLandingContext);
  const {user} = React.useContext(UserContext);

  const onPurchaseClick = useHandleCoursePurchaseClick(course.id, course.price);

  if (productPrice === 0 || user?.premium || user?.subscriber) return null;

  return (
    <div className="absolute top-8 right-12 h-full w-1/4">
      <div className="sticky top-2">
        <div className="bg-primary-800 p-6 rounded-xl ">
          <div className="flex flex-col items-start gap-2">
            <GroupDiscountsBanner
              showDiscountPercentage={false}
              padding="p-0"
              showActivateButton={true}
            />
            <div className="mt-2">
              <ProductPrice
                productType="COURSE"
                productId={course.id}
                showGroupDiscounts
                textProps={{
                  color: "text-gray-300",
                  type: "dmd",
                  weight: "b",
                }}
              />
            </div>{" "}
            <GroupDiscountsFreeWrapper>
              <Button fluid type="primary" size="xl" onClick={onPurchaseClick}>
                <Trans>Unlock course</Trans>
              </Button>
            </GroupDiscountsFreeWrapper>
          </div>
          <GroupDiscountsFreeWrapper>
            <div className="-mx-4">
              <SubscriptionTrialBanner showImage={false} />
            </div>
          </GroupDiscountsFreeWrapper>
        </div>
      </div>
    </div>
  );
};

export default CourseLandingUnlockCard;
