import React from "react";
import {Button, Icon, Popover} from "Components/index";
import WidgetFeedbackForm from "Components/Widget/WidgetFeedback/WidgetFeedbackForm";

const WidgetFeedback = ({widgetName, showAsDots = true}) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [likeType, setLikeType] = React.useState(null);

  const handleClick = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleClose = () => {
    setTooltipOpen(false);
    setLikeType(null);
  };

  if (showAsDots)
    return (
      <Popover
        onOpenChange={setTooltipOpen}
        content={
          <WidgetFeedbackForm
            setToolTipOpen={setTooltipOpen}
            widgetName={widgetName}
            likeType={null}
            handleClose={handleClose}
          />
        }
        isOpen={tooltipOpen}
        placement="bottom"
        variant="light"
      >
        <Icon
          icon="fa-regular fa-ellipsis-vertical"
          size="lg"
          className="hover:text-gray-900 cursor-pointer"
          onClick={handleClick}
        />
      </Popover>
    );

  return (
    <Popover
      content={
        <WidgetFeedbackForm
          setToolTipOpen={setTooltipOpen}
          widgetName={widgetName}
          likeType={likeType}
          handleClose={handleClose}
        />
      }
      open={likeType !== null}
      placement="bottom"
      variant="light"
    >
      <div className="flex gap-1">
        <Button
          type={"link"}
          size="sm"
          onClick={() => {
            setLikeType("yes");
          }}
          icon={
            likeType === "yes"
              ? "fa-solid fa-thumbs-up"
              : "fa-light fa-thumbs-up"
          }
        ></Button>
        <Button
          type={"link"}
          size="sm"
          onClick={() => {
            setLikeType("no");
          }}
          icon={
            likeType === "no"
              ? "fa-solid fa-thumbs-up"
              : "fa-light fa-thumbs-down"
          }
        ></Button>
      </div>
    </Popover>
  );
};

export default WidgetFeedback;
