import React from "react";
import Link from "Components/_Elements/Link/Link";
import {Avatar, Button, Logo, Typography} from "Components";
import {kebabCaseSplitter, truncateText} from "utilities/text";
import UserMenuSidebar from "./UserMenuSidebar/UserMenuSidebar";
import {CourseContext} from "routes/Course/CourseContextProvider";
import {useHistory, useLocation} from "react-router-dom";
import checkInCourse from "utilities/checkInCourse";
import classnames from "classnames";

const UserMenuMobile = ({
  user,
  transparent = false,
  inverted = false,
  logoComponentProps = {},
}) => {
  const location = useLocation();
  const inCourse = checkInCourse(location);

  const [open, setOpen] = React.useState(false);
  let course = React.useContext(CourseContext);
  const history = useHistory();

  // Close menu when route changes
  React.useEffect(() => setOpen(false), [location]);
  const icon = open ? "times" : "bars";

  let Title = (
    <Link to="/" basic className="title">
      <h5 className="my-1">
        <Logo
          type={inverted ? "white" : "primary"}
          size="sm"
          className={inverted && "mix-blend-soft-light"}
          {...logoComponentProps}
        />
      </h5>
    </Link>
  );

  if (course) {
    const [courseCode, courseName] = kebabCaseSplitter(course.name);
    Title = (
      <Link basic>
        <Typography
          type="txs"
          className="centered"
          weight="sb"
          color="text-gray-700"
        >
          {courseCode}
        </Typography>
        <Typography className="centered" weight="sb" color="text-gray-700">
          {truncateText(courseName, 25)}
        </Typography>
      </Link>
    );
  }

  return (
    <div className={classnames(inverted && "dark")}>
      <UserMenuSidebar setOpen={setOpen} open={open} />
      <div
        className={classnames(
          "w-full flex justify-between items-center py-3 px-4",
          !transparent && "shadow-md  border-b-1 border-gray"
        )}
      >
        <Button
          type="link"
          onClick={() => setOpen(!open)}
          size="xl"
          icon="bars"
        />

        <div className="title-container">{Title}</div>
        <Avatar
          className="avatar-container"
          user={user}
          fluid={false}
          onClick={() => history.push("/account/profile")}
        />
      </div>
    </div>
  );
};

export default UserMenuMobile;
