import React from "react";
import useManualMarkingSelectionEvents from "routes/Course/ExamWizard/ExamView/ExamMarking/ExamMarkingManualMarking/useManualMarkingSelectionEvents";
import useKeyboardShortcuts from "utilities/hooks/useKeyboardShortcuts";
import useOutsideClickAlerter from "utilities/hooks/useOutsideClickAlerter";

const CANVAS_ID = "marking-canvas";

const useManualMarking = ({initialWidth, container, onSelectionComplete}) => {
  /**
   * ******************************************************
   * A state for tracking manual marking action
   * ******************************************************
   */

  const [manualSelectionActive, setManualSelectionActive] = React.useState(
    false
  );

  const toggleManualSelection = (value = null) => {
    if (value) {
      setManualSelectionActive(value);
      return;
    }

    setManualSelectionActive(prev => !prev);
  };

  useOutsideClickAlerter(container, () => {
    if (manualSelectionActive) toggleManualSelection();
  });

  // Update the cursor when the manualSelectionActive changes
  React.useEffect(() => {
    if (container.current) {
      if (manualSelectionActive) {
        container.current.style.cursor = "crosshair";
      } else {
        container.current.style.cursor = "default";
      }
    }
    return () => {
      if (container.current) {
        container.current.style.cursor = "default";
      }
    };
  }, [manualSelectionActive]);

  // Use keyboard events to toggle manual selection
  useKeyboardShortcuts(
    [
      {
        key: "n",
        action: toggleManualSelection,
      },
    ],
    [manualSelectionActive]
  );

  /**
   * ******************************************************
   * Code for manual segmentation functionalities
   * ******************************************************
   */

  /**
   * The canvas is rendered on top of the container
   * This will be used to render the selected boxes and to
   * keep track of the mouse events on top of the canvas.
   *
   * It is initialized once the container has been rendered and manualSelectionActive == true
   * @type {React.MutableRefObject<null>}
   */

  // TODO: Move canvas a step up in the hierarchy, so it can be reused for displaying the selected boxes as-well
  const [canvas, setCanvas] = React.useState(null);

  React.useLayoutEffect(() => {
    if (
      container.current &&
      canvas === null &&
      initialWidth > 0 &&
      manualSelectionActive
    ) {
      const newCanvas = createCanvas(
        container.current.offsetWidth,
        container.current.offsetHeight
      );

      // Append the canvas to the container
      container.current.appendChild(newCanvas);
      // Set the canvas
      setCanvas(newCanvas);
    }

    // Remove the canvas when turning off the manual selection
    if (canvas && !manualSelectionActive) {
      // Remove the canvas
      canvas.remove();
      setCanvas(null);
    }

    // Called when the component unmounts
    return () => {
      if (container.current && canvas) {
        canvas.remove();
      }
    };
  }, [container, manualSelectionActive]);

  // Handles the mouse events on the canvas
  useManualMarkingSelectionEvents({
    active: manualSelectionActive,
    canvas,
    onSelectionComplete,
    toggleManualSelection,
  });

  return {
    manualSelectionActive,
    toggleManualSelection,
  };
};

const createCanvas = (width, height) => {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  canvas.style.position = "absolute";
  canvas.style.height = height;
  canvas.style.top = "0";
  canvas.style.left = "0";
  canvas.style.zIndex = "10";
  canvas.id = CANVAS_ID;
  // canvas.style.backgroundColor = "rgba(0,0,155,0.2)";

  return canvas;
};

export default useManualMarking;
