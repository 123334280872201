import React from "react";
import { AnimateSharedLayout, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import "./CourseLandingNavigation.scss";
import classNames from "classnames";

const CourseLandingNavigation = () => {
  const { t: trans } = useTranslation();

  const { navigationState } = React.useContext(window.CourseLandingContext);

  const [currentSection, setCurrentSection] = React.useState(null);

  const logit = () => {
    const sections = navigationState.courseLandingSections;
    let currentSectionName = null;

    for (let i = 0; i < sections.length; i++) {
      if (
        sections[i].ref?.current &&
        sections[i].ref?.current.getBoundingClientRect().top <
          window.innerHeight / 2
      ) {
        currentSectionName = sections[i].name;
      }
    }

    setCurrentSection(currentSectionName);
  };

  React.useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }

    watchScroll();

    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, []);

  return (
    <div className="course-landing-navigation">
      <div className="course-landing-navigation-links">
        <AnimateSharedLayout>
          {navigationState.courseLandingSections.map((section, i) => {
            return (
              <div
                className={classNames("course-landing-navigation-link", {
                  currentLink: section.name == currentSection,
                })}
              >
                <p key={i} onClick={() => section.ref.current.scrollIntoView()}>
                  {trans(`${section.name}`)}
                </p>
                {section.name == currentSection && (
                  <motion.div {...motionProps} />
                )}
              </div>
            );
          })}
        </AnimateSharedLayout>
      </div>
    </div>
  );
};

export default CourseLandingNavigation;

const motionProps = {
  layoutId: "outline",
  className: "outline",
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { type: "spring", stiffness: 500, damping: 30 },
};
