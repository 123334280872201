import React from "react";
import useGetExamExercises from "API/queries/exercise/useGetExamExercises";
import ExerciseListProgress from "../ExerciseListProgress/ExerciseListProgress";
import {Trans, Link} from "Components";
import {Authorization} from "Wrappers";
import {CourseParamsContext} from "routes/Course/CourseRouter";
import useCourseRouterLink from "utilities/hooks/useCourseRouterLink";

const ExamCardProgress = ({examId}) => {
  const {getExamLink} = React.useContext(CourseParamsContext);
  const [exercises, exercisesLoading] = useGetExamExercises({examId});
  const markingLink = useCourseRouterLink(`/exams/marking?exam=${examId}`);

  if (exercises?.length === 0) {
    return (
      <div className="mt-2">
        <Authorization.Admin
          fallback={
            <Trans type="tsm" className="text-center">
              No exercises available
            </Trans>
          }
        >
          <Link to={markingLink}>
            <Trans>Create exercises</Trans>
          </Link>
        </Authorization.Admin>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-5 items-center">
      <div className="col-span-3">
        <ExerciseListProgress
          exercises={exercises}
          loading={exercisesLoading}
          segmented
        />
      </div>
      <div className="col-span-2 justify-self-end">
        <Link to={getExamLink(examId)} iconRight="">
          <Trans>View exercises</Trans>
        </Link>
      </div>
    </div>
  );
};

ExamCardProgress.propTypes = {};

export default ExamCardProgress;
