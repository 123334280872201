import React from "react";
import {Avatar, Button, Trans, Typography} from "Components";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {motion} from "framer-motion";
import useGetGroupMembers from "API/queries/groups/useGetGroupMembers";

const GroupInfoAdmins = ({
  contacts,
  showEmail = false,
  showTitle = true,
  actions = null,
}) => {
  const {id, imageUrl} = useGroupContext();
  const [members, _, loading] = useGetGroupMembers({id});

  if (!contacts || contacts.length === 0) return null;

  return (
    <motion.div>
      {showTitle && (
        <Trans type="tlg" weight="sb">
          Contacts
        </Trans>
      )}

      <div className="mt-4" />

      <div className="flex flex-col gap-4">
        {contacts?.map((contact, index) => {
          const memberUser = members.find(
            member => member.id === contact.groupMemberId
          )?.user;

          return (
            <motion.div
              className="flex flex-col sm:flex-row flex-wrap  items-start sm:items-center justify-between gap-4 p-4 bg-slate-100 rounded-lg group"
              initial={{opacity: 0, scale: 0}}
              animate={{opacity: 1, scale: 1}}
              transition={{
                delay: index * 0.2,
              }}
            >
              <div className="flex gap-4">
                <Avatar
                  user={memberUser}
                  src={memberUser ? null : imageUrl}
                  size="lg"
                />

                <div className="flex flex-col">
                  <div className="flex gap-2 items-center">
                    <Typography type="tmd" weight="md">
                      {contact.name || contact.email}
                    </Typography>
                    {showEmail && (
                      <Typography type="tmd">{contact.email}</Typography>
                    )}
                  </div>
                  <Typography type="tmd" weight="sb" color="text-primary-600">
                    {contact.role}
                  </Typography>
                </div>
              </div>
              <div className="self-end sm:self-auto">
                {actions ? (
                  typeof actions === "function" ? (
                    actions({...contact, index})
                  ) : (
                    actions
                  )
                ) : (
                  <GroupInfoAdminsActions email={contact.email} />
                )}
              </div>
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
};

const GroupInfoAdminsActions = ({email}) => {
  return (
    <div className="flex gap-4">
      <Button
        variant="outlined"
        type="ghost"
        iconLeft="envelope"
        onClick={() => window.open(`mailto:${email}`)}
        size="sm"
      >
        <Trans>Contact</Trans>
      </Button>
    </div>
  );
};
export default GroupInfoAdmins;
