import React from "react";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";

const UPDATE_CHAPTER_MUTATION = gql`
  mutation updateChapter(
    $id: ID!
    $chapter_type: String!
    $name: String
    $title: String
    $markdown: String
    $html: String
    $published: Boolean
  ) {
    updateChapter(
      id: $id
      chapter_type: $chapter_type
      name: $name
      title: $title
      markdown: $markdown
      html: $html
      published: $published
    ) {
      ok
      error
    }
  }
`;

const useUpdateChapterMutation = ({
  onCompleted = () => {},
  refetchQueries = [],
}) => {
  const [
    updateChapter,
    {data: updateChaptersData, error, loading},
  ] = useMutation(UPDATE_CHAPTER_MUTATION, {
    onCompleted,
    refetchQueries,
  });

  if (error) {
    console.log("Error in useUpdateChapter: ", error);
  }

  return {updateChapter, loading};
};

export default useUpdateChapterMutation;
