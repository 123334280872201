import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {useTranslation} from "react-i18next";
const QUERY = gql`
  query($id: String!, $locale: String) {
    courseStudyPlan(id: $id, locale: $locale) {
      title
      introduction {
        json
      }
      authorImage {
        title
        description
        fileName
        url
      }
      blocksCollection {
        total
        items {
          ...studyPlanBlocks
        }
      }
    }
  }

  fragment studyPlanBlocks on StudyplanBlock {
    sys {
      id
    }
    name
    introduction {
      json
    }
    stepsCollection {
      total
      items {
        ...stepsCollectionFields
      }
    }
  }

  fragment stepsCollectionFields on StudyplanStep {
    sys {
      id
    }
    title
    exerciseIds
  }
`;

const useGetContentfulStudyPlan = id => {
  const {i18n} = useTranslation();
  const locale = i18n.language.split("-")[0] == "sv" ? "sv" : "en";
  let result = {};

  const {loading, error, data} = useQuery(QUERY, {
    variables: {
      id,
      locale,
    },
    context: {
      api: "contentful",
    },
  });

  if (error) {
    console.log("Error in useGetContentfulStudyPlan", error);
    result = null;
  }

  if (data && data.courseStudyPlan) {
    result = data.courseStudyPlan;
  } else {
    // Fallback if the data is not returned. We store a JSON snapshot so at least we can show something
  }

  return [result, loading];
};

export default useGetContentfulStudyPlan;
