import React from "react";
import {Button, Select, Trans, Input, Avatar} from "Components";
import useGetGroupMembers from "API/queries/groups/useGetGroupMembers";
import {useGroupContext} from "Providers/GroupProvider/GroupProvider";
import {useTranslation} from "react-i18next";

/**
 * Schema
 * name: String!
 *    role: String!
 *    email: String!
 *    phone: String
 *    groupMemberId: ID
 * @type {{role: string, groupMemberId: null, name: string, email: string}}
 */
const INITIAL_CONTACT = {
  name: "",
  role: "",
  email: "",
  groupMemberId: null,
};

const GroupInfoSettingsAdminsCreate = ({handleAddContact}) => {
  const {allMembers, subGroups} = useGroupContext();

  const {t: trans} = useTranslation();
  const [newContact, setNewContact] = React.useState({
    ...INITIAL_CONTACT,
  });

  const [showEditor, setShowEditor] = React.useState(false);

  const handleSave = () => {
    handleAddContact(newContact);
    setShowEditor(false);

    setNewContact({...INITIAL_CONTACT});
  };

  if (!showEditor) {
    return (
      <div>
        <Button fluid onClick={() => setShowEditor(true)}>
          <Trans>Add Admin/Contact</Trans>
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 bg-indigo-50 rounded-lg p-4">
      <div className="flex flex-row gap-2 items-center justify-between">
        <Trans tag="txl" weight="sb" color="text-indigo-700">
          New Contact
        </Trans>

        <Button
          type="destructive"
          size="sm"
          onClick={() => setShowEditor(false)}
          iconLeft="xmark"
        >
          Cancel
        </Button>
      </div>
      <div className="flex flex-col gap-2  items-start">
        <Trans type="tsm" weight="sb" color="text-indigo-600">
          Choose a group member
        </Trans>
        <Select
          className="bg-white"
          transparent={false}
          onSelect={value =>
            setNewContact({
              ...newContact,
              groupMemberId: value,
              name:
                allMembers.find(member => member.id === value)?.user
                  ?.firstName +
                " " +
                (allMembers.find(member => member.id === value)?.user
                  ?.lastName || ""),
            })
          }
          showSearch={true}
          label={trans("Choose a group member")}
          showIconThreshold={10}
          options={allMembers.map(member => ({
            id: member.id,
            value: member.id,
            text: member.user?.firstName + " " + (member.user?.lastName || ""),
            icon: <Avatar user={member.user} size="sm" />,
          }))}
        />
      </div>

      <div className="flex flex-col gap-2 items-start ">
        <Trans type="tsm" weight="sb" color="text-indigo-600">
          Choose role (e.g. board member, contact person, etc.)
        </Trans>
        <Input
          className="bg-white"
          transparent={false}
          onChange={e => {
            setNewContact({
              ...newContact,
              role: e.target.value,
            });
          }}
          name="role"
          value={newContact.role}
          showSearch={true}
        />
      </div>
      <div className="flex flex-col gap-2 items-start ">
        <Trans type="tsm" weight="sb" color="text-indigo-600">
          Enter contact email
        </Trans>
        <Input
          className="bg-white"
          transparent={false}
          onChange={e => {
            setNewContact({
              ...newContact,
              email: e.target.value,
            });
          }}
          name="email"
          value={newContact.email}
          showSearch={true}
        />
      </div>
      <Button type="primary" fluid size="lg" onClick={handleSave}>
        <Trans>Save</Trans>
      </Button>
    </div>
  );
};

export default GroupInfoSettingsAdminsCreate;
