import React from "react";
import {ExamUploadContext} from "routes/Course/ExamUpload/ExamUploadContext/useExamUploadContext";
import useExamUploadDocumentsRowAuthorState from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsRow/ExamUploadDocumentsRowState/useExamUploadDocumentsRowAuthorState";
import useExamUploadDocumentsRowConflictResolver from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsRow/ExamUploadDocumentsRowState/useExamUploadDocumentsRowConflictResolver";
import useExamUploadDocumentsRowStateActions from "routes/Course/ExamUpload/ExamUploadDocuments/ExamUploadDocumentsRow/ExamUploadDocumentsRowState/useExamUploadDocumentsRowStateActions";

const useExamUploadDocumentsRowState = ({document, date}) => {
  const {
    removeDocumentByDate,
    updateResource,
    previewResource,
  } = React.useContext(ExamUploadContext);

  let examResource = document.files?.find(
    file =>
      (file.type === "exam") |
      (file.type === "mixed") |
      (file.type === "exam_solutions") |
      (file.type === "unknown")
  );

  const documentFocused = document.files?.find(
    file => file.id === previewResource?.id
  );

  // Check if multiple files have the same type, in which case there is a conflict which needs to be resolved
  const conflictsResolved = useExamUploadDocumentsRowConflictResolver(
    document.files
  );

  // Author related state
  const {author} = useExamUploadDocumentsRowAuthorState({
    resource: examResource,
  });

  const {
    saveLoading,
    onSave,
    saveDisabled,
    error,
  } = useExamUploadDocumentsRowStateActions({
    document,
    date,
    author,
    conflictsResolved,
  });

  /**
   * ACTIONS RELATED TO REMOVAL
   * ==========================
   */

  const [removed, setRemoved] = React.useState(false);

  // Actually remove the document from the context so the row disappears
  const onDelete = () => {
    removeDocumentByDate(date);
  };

  // Set removed to true when the document is saved

  React.useEffect(() => {
    if (document.saved) {
      setRemoved(true);
    }
  }, [document]);

  return {
    author,
    onDelete,
    documentFocused,
    removed,
    examResource,

    // State from useExamUploadDocumentsRowStateActions
    saveLoading,
    onSave,
    saveDisabled,
    error,
  };
};

export default useExamUploadDocumentsRowState;
