import React from "react";
import {
  Button,
  Icon,
  Input,
  Modal,
  TextArea,
  Trans,
  Typography,
} from "Components";
import useGroupInvitesAddMembers from "routes/Group/GroupInvites/GroupInvitesAddMembers/useGroupInvitesAddMembers";
import GroupInvitesUser from "routes/Group/GroupInvites/GroupInvitesUser";
import {motion} from "framer-motion";
import GroupInvitesCreate from "routes/Group/GroupInvites/GroupInvitesCreate/GroupInvitesCreate";

const GroupInvitesAddMembers = () => {
  const {
    emails,
    emailsString,
    handleEmailsChange,
    handleRemoveEmail,
  } = useGroupInvitesAddMembers();

  const [triggerAll, setTriggerAll] = React.useState(false);

  const validEmailsCount = emails.filter(email => email.isValid).length;

  return (
    <Modal
      size="lg"
      trigger={
        <Button color="primary" rounded>
          <Trans>Add members</Trans>
        </Button>
      }
      title={<Trans>Invite users</Trans>}
      subTitle={
        <Trans>
          Enter the email addresses of the users you want to invite to this
          group.
        </Trans>
      }
      action={
        validEmailsCount > 1
          ? {
              onClick: () => setTriggerAll(true),
              label: <Trans>Invite all</Trans>,
              disabled: triggerAll === true,
            }
          : null
      }
    >
      <div className="flex flex-col gap-4">
        <TextArea
          onChange={handleEmailsChange}
          value={emailsString}
          placeholder="Emails"
        />
        {emails.map((email, index) => {
          return (
            <motion.div
              key={index}
              initial={{opacity: 0, y: -100}}
              animate={{opacity: 1, y: 0}}
            >
              <GroupInvitesUser
                email={email.text}
                showAvatar={false}
                showStatus={false}
                backgroundColor={
                  email.isValid ? "bg-primary-50" : "bg-error-100"
                }
                grid={false}
                action={
                  email.isValid ? (
                    <GroupInvitesCreate
                      email={email.text}
                      triggerMutation={triggerAll}
                      triggerDelay={index * 500}
                    />
                  ) : (
                    <div className="flex items-center gap-1">
                      <Icon
                        icon="exclamation-triangle"
                        size="sm"
                        color="text-error-500"
                      />
                      <Trans type="tsm" color="text-error-500">
                        Invalid
                      </Trans>
                    </div>
                  )
                }
              />
            </motion.div>
          );
        })}
      </div>
    </Modal>
  );
};

export default GroupInvitesAddMembers;
