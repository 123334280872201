import React from "react";

const useNotificationsProvider = () => {
  /**
   *  Notifications are stored in stack as objects.
   *  For the properties of the object, see Notification.jsx
   */

  const [notificationsStack, setNotificationsStack] = React.useState([]);

  const removeNotification = key => {
    setNotificationsStack(prevState => prevState.filter(n => n.key !== key));
  };

  const addNotification = notification => {
    if (!notification) return;

    if (!notification.key) {
      console.log("Notification must have a key");
    }

    if (!notification.title) {
      console.log("Notification must have a title");
    }

    // Check if notification already exists by key
    const notificationExists = notificationsStack.some(
      n => n.key === notification.key
    );

    if (notificationExists) {
      // The notification already exists, so we don't need to add it again
      return;
    }

    // If the message has an onClose function, call it when the message is removed
    const onClose = () => {
      if (notification.onClose) {
        notification.onClose(notification.key);
      }
      removeNotification(notification.key);
    };

    setNotificationsStack([
      {
        ...notification,
        notificationId: notification.key,
        onClose,
      },
      ...notificationsStack,
    ]);
  };

  return {
    notifications: notificationsStack,
    addNotification,
    removeNotification,
    closeNotification: removeNotification, // alias
    notify: addNotification, // alias
  };
};

export default useNotificationsProvider;
