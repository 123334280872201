import {useMutation} from "@apollo/react-hooks";
import {GET_USER_ACTIVE_COURSES} from "API/queries/course/useGetCurrentUserActiveCourses";
import gql from "graphql-tag";
import {GET_COURSE} from "API/queries/course/useGetCourse";

export const ACTIVATE_COURSE_FOR_CURRENT_USER = gql`
  mutation activateCourseForCurrentUser($courseId: ID!) {
    activateCourseForCurrentUser(courseId: $courseId) {
      ok
      error
      course {
        id
        currentUser {
          active
          archived
        }
      }
    }
  }
`;

const useActivateUserCourse = (onCompleted, courseId) => {
  const [activateCourseForCurrentUser, {data, loading, error}] = useMutation(
    ACTIVATE_COURSE_FOR_CURRENT_USER,
    {
      onCompleted,
      errorPolicy: "all",
      refetchQueries: [
        {
          query: GET_USER_ACTIVE_COURSES,
          errorPolicy: "all",
          variables: {
            includeSchool: false,
            includeSubject: false,
            includePurchased: false,
          },
        },
        {
          query: GET_COURSE,
          errorPolicy: "all",
          variables: {
            course_id: courseId,
            includeStats: false,
            includeModules: false,
            loggedIn: true,
          },
        },
      ],
    }
  );

  return {
    activateCourseForCurrentUser,
    loading,
  };
};

export default useActivateUserCourse;
