import React from "react";
import {Button, Logo} from "Components";
import Link from "Components/_Elements/Link/Link";
import Trans from "Components/Trans";
import UserMenu from "../UserMenu/UserMenu";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import {useBreakPointXS} from "utilities/hooks/useWindowSize";
import {useTranslation} from "react-i18next";

//profilepage

const PageNavbarMobile = ({
  user,
  location,
  logoComponentProps,
  transparent,
  dark,
}) => {
  const {t: trans} = useTranslation();
  const {openModal} = React.useContext(window.GlobalModalContext);
  const [open, setOpen] = React.useState(false);

  // Close menu when route changes
  React.useEffect(() => setOpen(false), [location]);

  return (
    <div className="w-full py-3 px-3 border-b-1 border-gray shadow-md flex justify-between  items-center">
      <div className="flex gap-2">
        <Button
          type="link"
          onClick={() => setOpen(!open)}
          size="lg"
          icon="fa-solid fa-bars"
          iconColor="text-primary-600 dark:text-primary-200"
        />

        <Link to="/" basic>
          <Logo size="sm" {...logoComponentProps} />
        </Link>
      </div>

      <div className="flex items-center gap-3">
        <Button size="sm" type="link" onClick={() => openModal("LOGIN")}>
          {trans("Login")}
        </Button>

        <Link
          to={{pathname: "/signup"}}
          replace
          size="sm"
          asButton={true}
          buttonType="primary"
        >
          <Trans>Register</Trans>
        </Link>
      </div>

      <SidebarMenu open={open} setOpen={setOpen} dark={dark}>
        {[
          <Link to="/courses" key="courses">
            <Trans>All courses</Trans>
          </Link>,

          <Link
            key="login"
            to={{
              pathname: "/login",
              state: {
                nextUrl: location.pathname,
              },
            }}
            replace
          >
            <Trans>Login</Trans>
          </Link>,

          <Link
            key="signup"
            to={{
              pathname: "/signup",
              state: {
                nextUrl: "/onboarding",
              },
            }}
          >
            {/* color: colors.kollinGreen, */}
            <Trans>Register</Trans>
          </Link>,
        ]}
      </SidebarMenu>
    </div>
  );
};

export default PageNavbarMobile;
