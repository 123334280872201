import React from "react";
import {useBreakPointSM} from "utilities/hooks/useWindowSize";
import {Icon, Trans, Typography} from "Components";
import PropTypes from "prop-types";
import classnames from "classnames";

const Section = ({
  title,
  subTitle = null,
  children = null,
  action = null,
  secondary = false,
  id,
  hideTitle = false,
  separator = false,
  className,
}) => {
  const smallScreens = useBreakPointSM();

  if (hideTitle) {
    return children;
  }

  const getTitleSize = () => {
    if (title.props.type) {
      return title.props.type;
    }

    if (secondary) {
      return smallScreens("dxs", "dsm");
    }

    return smallScreens("dsm", "dmd");
  };

  return (
    <div className="mb-4" id={id}>
      <div
        className={classnames(
          "flex flex-col gap-2 mb-4 md:flex-row justify-between items-start md:items-center",
          !secondary && "border-b-1 border-gray pb-4"
        )}
      >
        <div className="grow">
          <div className="w-full flex items-start gap-2">
            <div>
              {React.cloneElement(title, {
                type: getTitleSize(),
                className: classnames("mb-0"),
              })}
              {subTitle !== null &&
                React.cloneElement(subTitle, {
                  type: "tsm",
                  color: "text-gray-light",
                  className: "mt-2",
                })}
            </div>
          </div>
        </div>
        {action !== null && action}
      </div>
      {separator && <div className="separator" />}
      {children}
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.instanceOf([Trans, Typography]).isRequired,
  subTitle: PropTypes.instanceOf([Trans, Typography]),
  children: PropTypes.element.isRequired,
  action: PropTypes.element,
};

export default Section;
