import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_PROGRAM_COURSES = gql`
  query programCourses(
    $programId: Int!
    $includeSchool: Boolean!
    $includeCurrentUserData: Boolean = false
  ) {
    program(id: $programId) {
      id
      courses {
        id
        courseName
        courseCode
        period
        year
        includedInProgramsCount
        optional
        courseData {
          id
          name
          code
          icon
          purchased
          published
          currentUser @include(if: $includeCurrentUserData) {
            active
            archived
          }
          subject {
            id
            name
          }
          school @include(if: $includeSchool) {
            id
            name
          }
        }
      }
    }
  }
`;

// Note: Although this is placed in the /course domain, it actually returns a [ProgramCourse]
// This is highly confusing when actually using the data so be aware!

const useGetProgramCourses = ({
  programId,
  includeSchool = false,
  includeCurrentUserData = true,
  includeEmptyCourses = false,
}) => {
  let courses = [];

  const {loading, error: errorCourse, data} = useQuery(GET_PROGRAM_COURSES, {
    variables: {
      programId,
      includeSchool,
      includeCurrentUserData,
    },
    fetchPolicy: "cache-first",
  });

  if (errorCourse) {
    console.log("Error in useGetProgramCourses hook: ", errorCourse);
  }

  if (data) {
    courses = data.program.courses;

    if (!includeEmptyCourses) {
      // Filter out the courses that exist, e.g. they have courseData and they are published
      courses = courses.filter(
        course => course.courseData && course.courseData.published
      );
    }
  }

  return [courses, loading];
};

export default useGetProgramCourses;
