import React from "react";
import classnames from "classnames";

const CornerHandle = ({ position, disabled, handleRef }) => {
  const positionClasses = React.useMemo(() => {
    switch (position) {
      case "top-left":
        return "-top-1.5 -left-1.5 cursor-nwse-resize";

      case "bottom-left":
        return "-bottom-1.5 -left-1.5 cursor-nesw-resize";

      case "top-right":
        return "-top-1.5 -right-1.5 cursor-nesw-resize";

      case "bottom-right":
        return "-bottom-1.5 -right-1.5 cursor-nwse-resize";
      default:
        return null;
    }
  }, []);

  // if (positionClasses === null) return null;

  return (
    <div
      ref={handleRef}
      className={classnames(
        "absolute w-2 h-2 rounded-full",
        !disabled &&
          positionClasses !== null &&
          `${positionClasses} background outline outline-1 outline-primary-400`
      )}
      data-position={position}
    />
  );
};

export default CornerHandle;
