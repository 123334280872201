import React from "react";
import {useHistory} from "react-router-dom";
import {Button, Trans} from "Components";
import useGetCourseExercises from "API/queries/exercise/useGetCourseExercises";
import Metric from "Components/_Elements/Metric/Metric";
import MetricLoading from "Components/_Elements/Metric/MetricLoading";

const CourseUserStatsExercisesCompleted = ({size = "md"}) => {
  const {courseId, getCourseLink} = React.useContext(window.CourseContext);
  const history = useHistory();
  const exerciseLink = getCourseLink(`home?all=1&dir=asc&sort=score`);

  const [exercises, loading] = useGetCourseExercises({
    courseId,
    includeTags: true,
    includeSelfEvaluation: true,
  });

  if (loading) return <MetricLoading size={size} />;

  if (!exercises?.length) return null;

  const exercisesCount = exercises?.length;

  const completedExerciseCount = exercises?.filter(
    exercise => !!exercise?.selfEvaluation
  )?.length;

  const changePercentage =
    Math.round((completedExerciseCount / exercisesCount) * 100) || 0;

  return (
    <Metric
      size={size}
      title={<Trans>Exercises completed</Trans>}
      action={
        <Button
          type="link"
          size="xs"
          onClick={() => history.push(exerciseLink)}
        >
          See more exercises
        </Button>
      }
      value={`${completedExerciseCount}`}
      chartType="pie"
      changePercentage={changePercentage}
      icon={changePercentage > 0 ? "check" : "empty-set"}
      changeLabel={<Trans>of course</Trans>}
      colors={{datum: "data.color"}}
      feedbackName="CourseUserStatsExercisesCompleted"
      data={[
        {
          id: "total",
          value: exercisesCount,
          color: "#C5D1F3",
        },
        {
          id: "completed",
          value: completedExerciseCount,
          color: "#586FB5",
        },
      ]}
    />
  );
};

export default CourseUserStatsExercisesCompleted;
