import React from "react";
import PropTypes from "prop-types";
import {Icon} from "Components";
import constants from "Components/_Elements/Badge/Badge.constants";
import isReactElement from "utilities/isReactElement";
import classnames from "classnames";

const borderSize = {
  sm: "border-1",
  md: "border-2",
  lg: "border-3",
  xl: "border-4",
};

const Badge = ({
  size = "sm",
  type = "primary",
  className,
  icon,
  iconColor,
  children,
  closable = false,
  onClose,
  onClick,
  style,
  capitalize = false,
  uppercase = false,
  border = false,
  faded = false,
  asChild = false,
}) => {
  return (
    <div
      onClick={onClick}
      className={classnames(
        "badge-base",
        "select-none",
        onClose ? "cursor-pointer" : "",
        border ? borderSize[size] + " border-solid" : "",
        faded ? "opacity-50" : "",
        constants.sizes[size],
        constants.types[type],
        constants.textColors[type],
        constants.borderColors[type],
        capitalize ? "capitalize" : "",
        uppercase ? "uppercase" : "",
        icon && "pr-2.5",
        className
      )}
      style={style}
    >
      {icon &&
        typeof icon !== "string" &&
        React.cloneElement(icon, {
          size: size,
          icon: icon,
          className: "px-1",
          color: iconColor || constants.iconColors[type],
        })}

      {icon && typeof icon === "string" && (
        <Icon
          size={size}
          icon={icon}
          className="px-1"
          fixedWidth
          color={iconColor || constants.iconColors[type]}
        />
      )}

      {isReactElement(children) && !asChild
        ? React.cloneElement(children, {
            size: constants.textSize[size],
            type: constants.textTypes[size],
            weight: "md",
            className: capitalize ? "capitalize" : "",
          })
        : children}

      {closable && (
        <Icon
          size="sm"
          icon="xmark"
          className="px-1"
          color={constants.iconColors[type]}
          onClick={closable ? onClose : null}
        />
      )}
    </div>
  );
};

Badge.propTypes = {
  /**
   * Size of the badge
   */
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  /**
   * Type of the badge
   */
  type: PropTypes.oneOf(Object.keys(constants.types)).isRequired,
  /**
   * Class name to add to the badge
   */
  className: PropTypes.string,
  /**
   *  Icon to add to the badge
   */
  icon: PropTypes.string,
  /**
   * Color of the icon
   *
   */
  iconColor: PropTypes.string,
  /**
   * Content of the badge
   *
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  /**
   * If the badge is closable
   *
   */
  closable: PropTypes.bool,
  /**
   * Function to call when the badge is closed
   *
   */
  onClose: PropTypes.func,
  /**
   * Function to call when the badge is clicked
   */
  onClick: PropTypes.func,
};

export default Badge;
