import React from "react";
import classnames from "classnames";
import CardHeader from "./CardHeader";
import CardFooter from "./CardFooter";

// The action is a react element that is sent from the parent
const Card = ({
  children,
  action,
  title = null,
  subtitle = null,
  icon,
  iconSize,
  type,
  fluid = true,
}) => {
  return (
    <div
      className={classnames(
        "background rounded-md border border-1 border-gray shadow-sm flex flex-col  ",
        fluid ? "w-full" : "w-fit"
      )}
    >
      <CardHeader
        iconSize={iconSize}
        title={title}
        subtitle={subtitle}
        icon={icon}
        type={type}
      />
      <div className="px-4 grow">{children}</div>
      {action && <CardFooter action={action} />}
    </div>
  );
};

Card.propTypes = {};

export default Card;
