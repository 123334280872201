import React from "react";
import NotificationsContainer from "./NotificationsContainer";
import useNotificationsProvider from "./useNotificationsProvider";

export const NotificationsContext = React.createContext(null);
window.NotificationsContext = NotificationsContext;

const NotificationsContextProvider = ({children}) => {
  const values = useNotificationsProvider();

  return (
    <NotificationsContext.Provider
      value={{
        ...values,
      }}
    >
      <NotificationsContainer />
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContextProvider;
