export default {
  "Handelshögskolan i Stockholm":
    "https://upload.wikimedia.org/wikipedia/en/thumb/d/d0/Stockholm_School_Of_Economics_Logo.svg/1200px-Stockholm_School_Of_Economics_Logo.svg.png",
  "Kungliga Tekniska högskolan":
    "https://seeklogo.com/images/K/kth-logo-2D6316D414-seeklogo.com.png",
  "Chalmers tekniska högskola":
    "https://seeklogo.com/images/C/Chalmers_University_of_Technology-logo-C071889428-seeklogo.com.png",
  "Linköpings universitet":
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLByFknRmfiQW8N35m0vPQGbQj6vCwpX3WxMqiU58MOZQgq-mdhoCwqZQBLIl1CMo4xt8&usqp=CAU",
  "Luleå tekniska universitet":
    "https://seeklogo.com/images/L/lulea-university-of-technology-logo-63351BBA1F-seeklogo.com.png",
  "Lunds universitet":
    "https://d1mgntrf3vaj6d.cloudfront.net/images/school-logos/lund.png",
  "Stockholms universitet":
    "https://pbs.twimg.com/profile_images/1083695089694769152/f8dpUmgK.jpg",
  "Uppsala universitet":
    "https://upload.wikimedia.org/wikipedia/en/thumb/0/03/Uppsala_University_logo.svg/1200px-Uppsala_University_logo.svg.png",
};
