import { dateNum } from "./exerciseAttributes";

const formatExercise = rawEx =>
  rawEx && {
    ...rawEx,
    // maxTagCertainty: rawEx.max_tag_certainty
    //   ? Number(rawEx.max_tag_certainty).toFixed(2)
    //   : null,
    id: Number(rawEx.id),
    number: rawEx.number,
    tagNames: rawEx.tags?.length ? rawEx.tags?.map(t => t.name) : [],
    score: rawEx.score ? Number(rawEx.score).toFixed(1) : 0,
    author: rawEx.source?.author,
    date: rawEx.source?.date,
    selfEvaluation: rawEx.self_evaluation
      ? Number(rawEx.self_evaluation?.score)
      : null,
    dateNum: rawEx.source ? dateNum(rawEx.source?.date, rawEx.number) : null,
  };

export default formatExercise;
