import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const QUICK_SIGNUP = gql`
  mutation quickSignup($email: String!, $lastName: String, $firstName: String) {
    quickSignup(email: $email, lastName: $lastName, firstName: $firstName) {
      ok
      token
      refreshToken
      errors {
        message
        path
      }
    }
  }
`;

const useQuickSignup = onCompleted => {
  const [quickSignup, { data, error, loading }] = useMutation(QUICK_SIGNUP, {
    onCompleted,
  });

  if (error) {
    console.error(error);
  }

  return { quickSignup, data, loading };
};

export default useQuickSignup;
