import React from "react";
import useGroupInfoSettingsAdmins from "routes/Group/GroupSettings/GroupSettingsInfo/GroupInfoSettingsAdmins/useGroupInfoSettingsAdmins";
import GroupInfoSettingsAdminsMember from "routes/Group/GroupSettings/GroupSettingsInfo/GroupInfoSettingsAdmins/GroupInfoSettingsAdminsMember";
import GroupInfoSettingsAdminsCreate from "routes/Group/GroupSettings/GroupSettingsInfo/GroupInfoSettingsAdmins/GroupInfoSettingsAdminsCreate";
import GroupInfoAdmins from "Widgets/Group/GroupInfo/GroupInfoAdmins";
import {Button, Confirm, SubSection, Trans} from "Components";
import GroupInfoSettingsAdminsToggle from "routes/Group/GroupSettings/GroupSettingsInfo/GroupInfoSettingsAdmins/GroupInfoSettingsAdminsToggle";

const GroupInfoSettingsAdmins = ({}) => {
  const {
    contacts,
    handleRemoveContact,
    handleAddContact,
  } = useGroupInfoSettingsAdmins();

  return (
    <SubSection
      title={<Trans>Contacts & Admins</Trans>}
      subTitle={
        <div>
          <Trans>
            Provide contact information for people in the board of your group.
            You can also set some of them as group admins.
          </Trans>
        </div>
      }
    >
      <GroupInfoAdmins
        contacts={contacts}
        showEmail={true}
        showTitle={false}
        actions={({email, groupMemberId}) => (
          <div className="flex gap-4">
            <GroupInfoSettingsAdminsToggle groupMemberId={groupMemberId} />
            <Confirm
              title={<Trans>Are your sure</Trans>}
              description={
                <Trans>This contact will be removed from the group.</Trans>
              }
              onConfirm={() => handleRemoveContact(email)}
              onCancel={() => {}}
            >
              <Button type="destructive">
                <Trans>Remove</Trans>
              </Button>
            </Confirm>
          </div>
        )}
      />
      <div className="mt-2" />
      <GroupInfoSettingsAdminsCreate handleAddContact={handleAddContact} />
    </SubSection>
  );
};

export default GroupInfoSettingsAdmins;
