import React from "react";
import useGetCurrentUser from "API/queries/user/useGetCurrentUser";

const useStudentVerification = () => {
  const [user, loading, refetch] = useGetCurrentUser(false, true);

  const isVerified = React.useMemo(() => {
    return !!(user && user.student_email);
  }, [user]);

  const showVerification = !loading && !isVerified;

  return {
    showVerification,
    loading,
    refetch,
  };
};

export default useStudentVerification;
