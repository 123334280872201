import React from "react";
import {Button, Icon, Link, Tooltip, Trans, Typography} from "Components/index";
import useGetCurrentUserCredits from "API/queries/user/useGetCurrentUserCredits";
import classnames from "classnames";
import {motion} from "framer-motion";
import GPTCostFreeTier from "Components/GPT/GPTCostCredits/GPTCostFreeTier";

const GPTCostCredits = () => {
  const {
    estimatedCost,
    hasEnoughCredits,
    loading,
    prompt,
    chatActive,
    focused,
    freeTierQuota,
  } = React.useContext(window.GPTContext);

  const {openModal} = React.useContext(window.CourseModalContext);

  if (!loading && !hasEnoughCredits && freeTierQuota?.available > 0) {
    return <GPTCostFreeTier />;
  }

  return (
    <div
      className={classnames(
        "flex w-full justify-end",
        loading ? "opacity-0" : "opacity-100"
      )}
    >
      <motion.div
        className="flex gap-2 items-start px-2 py-1"
        initial={{backgroundColor: "transparent"}}
        animate={{
          backgroundColor: hasEnoughCredits ? "transparent" : "#FEF3F2",
          width: hasEnoughCredits ? "4rem" : "100%",
        }}
        transition={{
          type: "tween",
          delay: 0.2,
          duration: 0.2,
        }}
      >
        <Tooltip content={<Trans>Estimated credit usage</Trans>} asChild={true}>
          <motion.div
            className="flex gap-1 items-center rounded-xl mt-1"
            initial={{backgroundColor: "transparent", padding: 0}}
            animate={{
              backgroundColor: hasEnoughCredits ? "transparent" : "#FEE4E2",
              padding: hasEnoughCredits ? 0 : "0rem 0.5rem",
            }}
          >
            <Icon
              icon="coins"
              size="xs"
              color={hasEnoughCredits ? "text-gray-light" : "text-error-light"}
            />
            <Typography
              type="tsm"
              color={hasEnoughCredits ? "text-gray-light" : "text-error-light"}
              weight="sb"
              className="leading-none"
            >
              {Math.round((estimatedCost / 100) * 10) / 10}
            </Typography>
          </motion.div>
        </Tooltip>

        <motion.div
          initial={{
            opacity: 0,
            display: hasEnoughCredits ? "none" : "block",
          }}
          animate={{
            opacity: hasEnoughCredits ? 0 : 1,
            display: hasEnoughCredits ? "none" : "block",
          }}
          transition={{
            delay: hasEnoughCredits ? 0 : 0.5,
          }}
        >
          <Trans type="tsm" color="text-error" className="leading-none " inline>
            You don't have enough credits or free conversations remaining today,
            try again tomorrow or
          </Trans>

          <Button
            type="link"
            size="sm"
            className="ml-2"
            onClick={() => openModal("CREDITS")}
          >
            <Trans type="tsm">get credits</Trans>
          </Button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default GPTCostCredits;
