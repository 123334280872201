import React from "react";
import {Logo} from "Components";
import SubscriptionStatusLabel from "./SubscriptionStatusLabel";
import SubscriptionStatusDescription from "./SubscriptionStatusDescription/SubscriptionStatusDescription";
import SubscriptionStatusPlan from "./SubscriptionStatusPlan";
import classnames from "classnames";
import SubscriptionStatusMessageBanner from "Components/SubscriptionStatus/SubscriptionStatusMessageBanner";

const SubscriptionStatus = ({
  status,
  expirationDate,
  subscriptionPlan,
  stripeData,
  invoices = [], // Used to determine the cancellation date when the subscription is past_due
  actions = [],
  showMessageBanner = false,
}) => {
  const {cancelAtPeriodEnd, cancelAt, trialEnd} = stripeData;

  const subscriptionIsActive =
    status === "active" || status === "trialing" || status === "past_due";

  return (
    <>
      <div className="shadow-sm border-1 border-gray rounded-lg background">
        <div className="p-4 flex gap-4 justify-between items-start">
          <div className="flex gap-4 items-start grow">
            <div
              className={classnames(
                "p-4 rounded-md",
                subscriptionIsActive ? "bg-primary-800" : "bg-gray-100"
              )}
            >
              <Logo showText={false} type="gold" size="sm" />
            </div>

            <div className="grow">
              <div className="flex gap-2 items-center">
                <SubscriptionStatusPlan {...subscriptionPlan} />
                <SubscriptionStatusLabel
                  status={status}
                  cancelAtPeriodEnd={cancelAtPeriodEnd}
                  trialEnd={trialEnd}
                  cancelAt={cancelAt}
                />
              </div>
              <SubscriptionStatusDescription
                status={status}
                expirationDate={expirationDate}
                cancelAtPeriodEnd={cancelAtPeriodEnd}
                trialEnd={trialEnd}
                invoices={invoices}
              />
            </div>
          </div>
          {/*    Actions container */}
          {actions}
        </div>
      </div>
      {showMessageBanner && (
        <div className="mt-2">
          <SubscriptionStatusMessageBanner
            status={status}
            cancelAtPeriodEnd={cancelAtPeriodEnd}
            invoices={invoices}
            expirationDate={expirationDate}
          />
        </div>
      )}
    </>
  );
};

export default SubscriptionStatus;
