import React from "react";
import {Logo, Typography} from "Components";
import {useHistory} from "react-router-dom";

const OnboardingLayoutHeader = () => {
  const history = useHistory();
  return (
    <div
      className="flex items-center gap-2 cursor-pointer"
      onClick={() => history.push("/")}
    >
      <Logo size="lg" />
    </div>
  );
};
export default OnboardingLayoutHeader;
