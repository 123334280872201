import React from "react";
import {Button, Icon, TextArea, Typography} from "Components";
import useGroupAdminUploadPanesInviteExport from "routes/Group/GroupAdminUpload/GroupAdminUploadPanes/GroupAdminUploadPanesInvite/useGroupAdminUploadPanesInviteExport";
import GroupAdminUploadPanesInviteLink from "routes/Group/GroupAdminUpload/GroupAdminUploadPanes/GroupAdminUploadPanesInvite/GroupAdminUploadPanesInviteLink";
import {userContext} from "UserContextProvider";

const GroupAdminUploadPanesInviteExport = ({membersToInvite}) => {
  const {
    emails,
    handleCopyEmail,
    handleSaveEmail,
    emailsCopied,
    emailsSaved,
    csv,
    csvCopied,
    csvSaved,
    handleCopyCsv,
    handleSaveCsv,
  } = useGroupAdminUploadPanesInviteExport({membersToInvite});

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
      <div className="flex flex-col gap-2 justify-between  border-r-2 pr-2">
        <div className="flex items-center w-full gap-2 mb-2">
          <Icon icon="envelope" size="md" color="text-primary-light" />
          <Typography type="tmd" weight="sb" color="text-primary-700">
            E-postadresser till personerna
          </Typography>
        </div>

        <div className="w-full relative">
          <TextArea
            text={emails}
            className="w-full bg-gray-100 text-gray-500 font-medium border-none"
            rows={5}
            readOnly={true}
            disabled
            placeholder="Inga e-postadresser"
          />
          <Button
            type="link"
            className="absolute right-2 bottom-2"
            iconLeft={emailsCopied ? "check-circle" : "copy"}
            disabled={emailsCopied || !emails?.length}
            onClick={handleCopyEmail}
          >
            {emailsCopied ? (
              <Typography>Kopierat!</Typography>
            ) : (
              <Typography>Kopiera</Typography>
            )}
          </Button>
        </div>

        <div className="flex items-center justify-between gap-2">
          <Button
            type="secondary"
            iconLeft={csvSaved ? "check-circle" : "download"}
            loading={csvSaved}
            fluid
            onClick={handleSaveCsv}
            disabled={!csv?.length}
          >
            <Typography>Ladda ned alla som .csv</Typography>
          </Button>
        </div>
      </div>
      <GroupAdminUploadPanesInviteLink />
    </div>
  );
};

export default GroupAdminUploadPanesInviteExport;
