import React from "react";
import {Link} from "Components";
import PropTypes from "prop-types";
import {CourseParamsContext} from "routes/Course/CourseRouter";
import useSortExercises from "./useSortExercises";
import {CourseContext} from "routes/Course/CourseContextProvider";
import useHandleCoursePurchaseClick from "utilities/hooks/useHandleCoursePurchaseClick";
import useEventTracking from "utilities/hooks/useEventTracking";
import "./ExerciseList.scss";
import classnames from "classnames";
import useExerciseListKeyboardActions from "Components/Workboard/ExerciseList/useExerciseListKeyboardActions";
import ExerciseThumbnail from "../../ExerciseThumbnail/ExerciseThumbnail";
import ExerciseCard from "../../ExerciseCard/ExerciseCard";
import {CourseExercisesTable} from "../../../Widgets";

const ExercisesList = ({
  columns: defaultColumns,
  exercises,
  hierarchyLevel,
  displayMode: parentDisplayMode,
}) => {
  const {query, exercise: selectedExercise, getExerciseLink} = React.useContext(
    CourseParamsContext
  );

  const {
    purchased,
    courseId,
    price,
    exerciseListDisplayMode: localStorageDisplayMode,
  } = React.useContext(CourseContext);

  const displayMode = parentDisplayMode || localStorageDisplayMode;
  const handlePurchase = useHandleCoursePurchaseClick(courseId, price);
  const {logExerciseEvent} = useEventTracking();

  const handleClick = (disabled, exercise) => {
    logExerciseEvent("exercise_clicked", {
      screen_name: window.location.pathname,
      source: hierarchyLevel,
      exercise: exercise,
    });
    disabled && handlePurchase();
  };
  const sortedExercises = useSortExercises(exercises);

  useExerciseListKeyboardActions({
    sortedExercises,
    selectedExerciseId: selectedExercise,
    getExerciseLink,
  });

  let columns = defaultColumns;

  if (!exercises.length) {
    return null;
  }

  if (displayMode === "table") {
    return (
      <div className="@container w-full">
        <CourseExercisesTable data={sortedExercises} columns={columns} />
      </div>
    );
  }

  return (
    <div className="@container w-full">
      <div
        className={classnames(
          "snap-x snap-start",
          displayMode === "grid" &&
            "grid @3xl:grid-cols-2 grid-cols-1 @6xl:grid-cols-3 gap-4",
          displayMode === "list" && "gap-2 overflow-x-scroll group",
          displayMode === "list" && selectedExercise && "flex gap-2"
        )}
      >
        {sortedExercises.map((exercise, key) => {
          const disabled = query && !purchased && key > 4; // Disable search result after 4 if the user has not purchased the exercise

          return (
            <Link
              basic
              onClick={() => handleClick(disabled, exercise)}
              to={disabled ? "#" : getExerciseLink(exercise.id)}
              key={key}
              className={classnames(displayMode === "grid" && "col-span-1")}
            >
              <div className="mt-2">
                {displayMode === "list" ? (
                  <ExerciseCard
                    exercise={exercise}
                    selectedExercise={selectedExercise}
                  />
                ) : (
                  <ExerciseThumbnail exercise={exercise} />
                )}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

ExercisesList.propTypes = {
  columns: PropTypes.array.isRequired,
  exercises: PropTypes.array.isRequired,
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
};

export default ExercisesList;
