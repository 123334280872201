import React from "react";
import {message, Trans} from "Components";
import autoSegmentation from "API/lambdas/autoSegmentation";
import {ExamViewContext} from "routes/Course/ExamWizard/ExamView/useExamViewContext";
import refineSegmentsBounds, {
  correctSegmentsOverlap,
} from "routes/Course/ExamWizard/ExamView/ExamMarking/ExamMarkingAutoSegmentationOld/refineSegmentsBounds";
import useExamMarkingCommitLog from "routes/Course/ExamWizard/ExamView/ExamMarking/ExamMarkingAutoSegmentationOld/useExamMarkingAutoSegmentationCommitLog";
import useLocalStorageState from "utilities/hooks/useLocalStorageState";

const useExamMarkingAutoSegmentation = ({
  segments,
  setSegments,
  margins,
  initialWidth,
}) => {
  const {courseId} = React.useContext(window.CourseContext);

  const {pdfUrl, documentType, exam} = React.useContext(ExamViewContext);

  const [state, setState] = React.useState({
    called: false,
    message: null,
    error: null,
    loading: false,
  });

  const updateState = newState =>
    setState(prevState => ({...prevState, ...newState}));

  const [patterns, setPatterns] = React.useState(null);

  const [
    autoSegmentationEnabled,
    setAutoSegmentationEnabled,
  ] = useLocalStorageState("AUTO_SEGMENTATION_ENABLED", false);

  const toggleAutoSegmentationEnabled = val => {
    if (val !== autoSegmentationEnabled) {
      setAutoSegmentationEnabled(val);
    }
  };

  const logAutoSegmentation = useExamMarkingCommitLog({
    totalSegmentsCount: segments.length,
    patterns,
  });

  const runAutoSegmentation = async () => {
    const lastMarking = segments[segments.length - 1];

    // only run when:
    if (
      segments.length !== 1 || // there is only one segment
      !lastMarking || // the last marking is not empty
      !lastMarking.y1 || // the last marking has a y1
      !lastMarking.y2 || // the last marking has a y2
      lastMarking.suggested || // the last marking is not suggested already
      margins == null || // margins are available
      !initialWidth || // initial width is available
      state.called // the function has not been called before
    ) {
      return;
    }

    console.log("Triggered auto segmentation");

    updateState({loading: true});

    const autoBotResponse = await autoSegmentation({
      docUrl: pdfUrl,
      segment: lastMarking?.y1 ? lastMarking : null,
      containerWidth: initialWidth,
      documentType,
      courseId,
      sourceId: exam.id,
    });

    updateState({called: true});

    if (autoBotResponse == null) {
      updateState({
        loading: false,
        error: <Trans>Auto segmentation failed</Trans>,
      });
      return;
    }

    setPatterns(autoBotResponse.patterns);

    let suggestedSegments = autoBotResponse.segments;

    if (!suggestedSegments || !suggestedSegments.length) {
      updateState({
        loading: false,
        error: <Trans>No segments found</Trans>,
      });
      return;
    }

    const suggestedSegmentsCount = suggestedSegments.length;
    try {
      suggestedSegments = refineSegmentsBounds(suggestedSegments);
      suggestedSegments = correctSegmentsOverlap(suggestedSegments);
    } catch (e) {
      console.log(e);
    }

    suggestedSegments = suggestedSegments.map((s, i) => ({
      localNumber: segments.length + i,
      edited: true,
      suggested: true,
      focused: i === 0,
      initialWidth,
      x1: margins?.left || 0, // Default to 0 if margins are not available
      x2: margins?.right || initialWidth, // Default to the initial width if margins are not available
      ...s,
    }));

    setSegments(suggestedSegments);
    updateState({
      message: (
        <Trans suggestedSegmentsCount={suggestedSegmentsCount}>
          AutoBot found {{suggestedSegmentsCount}} segments
        </Trans>
      ),
      loading: false,
    });
  };

  React.useEffect(() => {
    if (autoSegmentationEnabled) {
      runAutoSegmentation();
    }

    return () => {};
  }, [state.called, segments, autoSegmentationEnabled, initialWidth]);

  React.useEffect(() => {
    // AutoBot is called automatically on load
    // If we get 0 segments, we need to call it again after the user has added a segment manually
    const lastMarking = segments[segments.length - 1];
    if (
      segments.length === 1 && // Only 1 segment
      lastMarking && // Segment exists
      lastMarking.y1 && // Segment has a y1
      lastMarking.y2 && // Segment has a y2
      !lastMarking.suggested && // Segment is not suggested
      state.called // AutoBot has been called already
    ) {
      // Set called to false so that AutoBot can be called again with the new segment as reference
      updateState({called: false});
    }
  }, [documentType]);

  return {
    logAutoSegmentation,
    autoSegmentationEnabled,
    toggleAutoSegmentationEnabled,
    autoSegmentationState: state,
  };
};

export default useExamMarkingAutoSegmentation;
