import React from "react";
import { useViewportScroll } from "framer-motion";

const useExamViewDocumentScrollProgress = ({ pdfUrl, container }) => {
  const [height, setHeight] = React.useState(0);
  const [progressRatio, setProgressRatio] = React.useState(0);

  const { scrollY } = useViewportScroll();

  React.useEffect(() => {
    if (container.current) {
      setTimeout(() => {
        setHeight(container.current.offsetHeight - window.innerHeight);
      }, 500);
    }
  }, [pdfUrl, container.current]);

  const handleScroll = () => {
    const ratio = scrollY.get() / height;

    setProgressRatio(Math.min(ratio, 1));
  };

  React.useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [height, pdfUrl]);

  return progressRatio;
};

export default useExamViewDocumentScrollProgress;
