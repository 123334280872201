import React from "react";
import {Alert, Trans} from "Components";
import OTP from "Widgets/User/OTP/OTP";
import {useHistory} from "react-router-dom";
import AuthRequiredActionVerifyDevice from "routes/Auth/AuthRequiredAction/AuthRequiredActionVerifyDevice";
import AuthRequiredActionPasswordReset from "routes/Auth/AuthRequiredAction/AuthRequiredActionPasswordReset";

const AuthRequiredAction = ({
  requiredAction,
  transparentInput,
  handleActionCompleted,
  actionOTPProps = {
    showModal: false,
    inlineForm: false,
  },
}) => {
  const {action, email} = requiredAction;

  if (action === "VERIFY_DEVICE") {
    return (
      <AuthRequiredActionVerifyDevice
        email={email}
        action={action}
        transparentInput={transparentInput}
        handleActionCompleted={handleActionCompleted}
        actionOTPProps={actionOTPProps}
      />
    );
  }

  if (action === "PASSWORD_RESET") {
    return (
      <AuthRequiredActionPasswordReset
        handleActionCompleted={handleActionCompleted}
        actionOTPProps={actionOTPProps}
        action={action}
        email={email}
        transparentInput={transparentInput}
      />
    );
  }

  return (
    <div>
      <Alert
        type="warning"
        title={<Trans>Something went wrong</Trans>}
        subTitle={
          <Trans>
            This should not happen. Please try again or contact support if the
            problem persists.
          </Trans>
        }
      />
    </div>
  );
};

export default AuthRequiredAction;
