import React from "react";
import classnames from "classnames";
import CDN from "utilities/constants/CDN";
import {Icon, Typography} from "Components/_Elements";
import {getCleanCourseName} from "utilities/formatCourseName";
import CourseSidebarHeaderPicker from "./CourseSidebarHeaderPicker/CourseSidebarHeaderPicker";
import {ErrorBoundary} from "Components/index";
import {useHistory} from "react-router-dom";

const CourseSidebarHeader = ({collapsed}) => {
  const history = useHistory();
  const {name, icon, code, id} = React.useContext(window.CourseContext);
  const courseName = getCleanCourseName(name);

  return (
    <div
      className={classnames(
        "relative flex justify-between items-center rounded-lg cursor-pointer px-2"
      )}
    >
      <div
        className="flex items-center gap-2 overflow-hidden"
        onClick={() => {
          history.push(`/course/${id}/home`);
        }}
      >
        <img className="w-8" src={`${CDN}/course-icons/${icon}.svg`} />

        <div className="overflow-hidden">
          <div className="flex items-start">
            <Typography color="text-gray" weight="sb" className="truncate ...">
              {collapsed ? "." : courseName}
            </Typography>

            {!collapsed && (
              <ErrorBoundary hideOnError={true}>
                <CourseSidebarHeaderPicker currentCourseId={id} />
              </ErrorBoundary>
            )}
          </div>

          <Typography color="text-gray-light" type="tsm" weight="sb">
            {collapsed ? "." : code}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CourseSidebarHeader;
