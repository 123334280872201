import React from "react";
import CompoundProgressBar from "Components/CompoundProgressBar";
import ProgressBar from "Components/_Elements/ProgressBar/ProgressBar";
import { useTranslation } from "react-i18next";
import {
  avgSelfEvaluation,
  nCompleted,
  segments,
} from "utilities/exerciseUtilities/exerciseListOperations";

import "./ExerciseListProgress.css";
import Skeleton from "Components/_Elements/Skeleton/Skeleton";
import Trans from "Components/Trans";
import { Typography } from "Components/_Elements";

const ExerciseListProgressMock = () => {
  const { t: trans, i18n } = useTranslation();
  return (
    <div className="selection-progress">
      <div>
        <Skeleton style={{ height: 4 }} />
      </div>
      <span>
        ...<Trans>ex.</Trans>
      </span>
    </div>
  );
};

const ExerciseListProgress = ({ exercises, loading, segmented }) => {
  const { t: trans } = useTranslation();
  if (loading) return <ExerciseListProgressMock />;

  let count = exercises.length;
  let completed = nCompleted(exercises);

  if (segmented) {
    return (
      <div className="flex justify-between gap-2 items-center w-full">
        <div className="grow">
          <CompoundProgressBar
            nExercises={count}
            segments={segments(exercises)}
            thin
            animated
          />
        </div>
        <Typography>
          {completed + "/" + count} {trans("ex.")}
        </Typography>
      </div>
    );
  }

  let color = avgSelfEvaluation(exercises);
  return (
    <div className="selection-progress">
      <div>
        <ProgressBar
          animated
          thin
          style={{ color }}
          progressRatio={completed ? completed / count : 0}
        ></ProgressBar>
      </div>

      <span>
        {completed + "/" + count} {trans("ex.")}
      </span>
    </div>
  );
};

export default ExerciseListProgress;
