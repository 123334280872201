import React from "react";
import useTabsUrlState from "./useTabsUrlState";

const useTabs = ({
  defaultSelectedTabKey,
  children,
  persistCurrentTabInUrl,
  onTabChange,
  urlParamName,
}) => {
  const {currentTabInUrl, setCurrentTabInUrl} = useTabsUrlState(urlParamName);

  const [selectedTabKey, setSelectedTabKey] = React.useState(
    persistCurrentTabInUrl ? currentTabInUrl : defaultSelectedTabKey
  );

  React.useEffect(() => {
    let initialSelectedTabKey = null;

    if (defaultSelectedTabKey) {
      // If a defaultSelectedTabKey is passed, use that
      initialSelectedTabKey = defaultSelectedTabKey;
    } else {
      // Find the first non-hidden child and set it as the selected tab
      const firstNonHiddenChild = React.Children.toArray(children).find(
        child => child.props.hidden !== true
      );

      try {
        if (firstNonHiddenChild) {
          // Remove the .$ from the key since React adds that to the key when it's an array
          initialSelectedTabKey = getCleanTabKey(firstNonHiddenChild.key);
        }
      } catch (e) {
        console.log(e);
      }
    }

    // If the currentTabInUrl is set, use that
    if (persistCurrentTabInUrl && currentTabInUrl) {
      const content = getContent(children, currentTabInUrl);

      if (content) {
        initialSelectedTabKey = currentTabInUrl;
      } else {
        console.log("No content found for tab", currentTabInUrl);
      }
    }

    // If the initialSelectedTabKey is different than the selectedTabKey, set the selectedTabKey
    if (initialSelectedTabKey && initialSelectedTabKey !== selectedTabKey) {
      setSelectedTabKey(initialSelectedTabKey);
    }
  }, [defaultSelectedTabKey, children]);

  const SelectedTabContent = getContent(children, selectedTabKey);

  // Update the url when the selectedTabKey changes
  React.useEffect(() => {
    if (onTabChange) {
      onTabChange(selectedTabKey);
    }

    if (persistCurrentTabInUrl) {
      setCurrentTabInUrl(selectedTabKey);
    }
  }, [selectedTabKey]);

  return {
    selectedTabKey,
    setSelectedTabKey,
    SelectedTabContent,
  };
};

const getContent = (children, selectedTabKey) => {
  const childrenArray = React.Children.toArray(children);

  // Find the selected tab by key
  const selectedTab = childrenArray.find(
    child => getCleanTabKey(child.key) === selectedTabKey
  );

  if (selectedTab) {
    return selectedTab.props.children;
  }
};

export const getCleanTabKey = key => key.split("$")[1];

export default useTabs;
