export const difficultyColors = [
  "grey",
  "#039855",
  "#FDB022",
  "#F04438",
  "#9B8AFB",
  "white",
];

export const difficultyColorsLight = [
  "#47546780",
  "#03985580",
  "#DC680380",
  "#D92D2080",
  "#7839EE80",
  "white",
];

export const difficultyColorsToTextColor = [
  "text-gray-600",
  "text-success-500",
  "text-warning-400",
  "text-red-500",
  "text-purple-500",
];

export const difficultyColorsToBorderColor = [
  "border-gray-900",
  "border-success-400",
  "border-warning-400",
  "border-red-400",
  "border-purple-400",
];

export const difficultyColorsToBackgroundColor = [
  "bg-gray-900 opacity-50",
  "bg-success-400  opacity-50",
  "bg-warning-400  opacity-50",
  "bg-red-400  opacity-50",
  "bg-purple-400  opacity-50",
];

export const kollinGreen = "#6eccb0";

export const kollinRed = "#FD7E7F";

export const kollinYellow = "#F8DB66";

export const kollinGrey = "#e0e0e0";

export const kollinBlue = "#6E8BE2";
// export const kollinBlue = "#2D3E54NULL";

export const darkBlue = "#6E8BE2";
// export const darkBlue = "#1a346a";
