import React from "react";
import PropTypes from "prop-types";
import useGetSchoolPrograms from "API/queries/program/useGetSchoolPrograms";
import {Select, Skeleton} from "Components";
import {useTranslation} from "react-i18next";

ProgramPicker.propTypes = {
  schoolId: PropTypes.object.isRequired, // Which school's programs to fetch
  onSelect: PropTypes.func.isRequired, // Callback when a program is selected
};

export const programNameCleaner = programName => {
  let replaced = programName
    .replace("Civilingenjörsutbildning i ", "")
    .replace("Civilingenjörsprogram i ", "")
    .replace("Civilingenjörsutbildning ", "");

  // Capitalize first letter
  replaced = replaced.charAt(0).toUpperCase() + replaced.slice(1);
  return replaced;
};

function ProgramPicker({
  schoolId,
  selectedProgramId,
  onSelect,
  allowedPrograms,
  allowNoProgram = false,
  ...props
}) {
  const {t: trans} = useTranslation();
  const [programs, programsLoading] = useGetSchoolPrograms(schoolId);

  if (programsLoading) return <Skeleton size={"lg"} />;

  let selectedPrograms = programs;

  if (allowedPrograms?.length > 0) {
    selectedPrograms = programs.filter(program =>
      // TODO: make flexible to handle both when allowedPrograms.id is string or number
      allowedPrograms.includes(program.id.toString())
    );
  }

  const options = selectedPrograms.map(program => ({
    value: program.id,
    text: programNameCleaner(program.name),
  }));

  if (allowNoProgram) {
    options.unshift({
      value: null,
      text: trans("No program"),
    });
  }

  return (
    <Select
      options={options}
      placeholder={props.placeholder || trans("Select program")}
      defaultSelectedValue={selectedProgramId}
      onSelect={onSelect}
      showSearch
      transparent={false}
    />
  );
}

export default ProgramPicker;
