import React from "react";
import classnames from "classnames";
import i18n from "i18next";

const highlightTypes = {
  marker: "bg-primary-50",
  markerYellow: "bg-yellow-100",
  underline: "bg-transparent border-b-2 border-secondary-400",
};

const HighlightedText = ({highlight, type = "marker", ...props}) => {
  const text = getTextFromElement(props.children);

  if (text === null || typeof text !== "string") {
    return props.children;
  }

  if (!highlight) return text;

  try {
    const sections = getSections(text, highlight);

    if (!sections) return text;

    return (
      <span>
        {sections[0]}
        <mark
          className={classnames(
            "ais-Snippet-highlighted px-0",

            highlightTypes[type]
          )}
        >
          {sections[1]}
        </mark>
        {sections[2]}
      </span>
    );
  } catch (e) {
    return text;
  }
};

export const getTextFromElement = children => {
  if (typeof children === "string") return i18n.t(children);
  // If it's an array of children, we need to join them together
  if (Array.isArray(children)) {
    return children.map(t => getTextFromElement(t)).join(" ");
  }

  if (typeof children == "object") {
    return getTextFromElement(children.props.children);
  }

  return null;
};

const getSections = (text, highlight) => {
  let index = -1;
  let highlightLength = 0;

  if (typeof highlight === "string") {
    index = text.toLowerCase().indexOf(highlight.toLowerCase());
    highlightLength = highlight.length;
  }
  // If it's a regex, we need to find the first match
  else if (highlight instanceof RegExp) {
    const match = text.match(highlight);

    if (match) {
      index = match.index;
      highlightLength = match[0].length;
    }
  }

  if (index === -1) return null;
  const before = text.slice(0, index);
  const after = text.slice(index + highlightLength);
  const highlighted = text.slice(index, index + highlightLength);
  return [before, highlighted, after];
};

export default HighlightedText;
