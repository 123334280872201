import React from "react";
import { CourseContext } from "routes/Course/CourseContextProvider";
import useEventTracking from "utilities/hooks/useEventTracking";
import useHandleStudyPlanUnlockAction from "routes/Course/StudyPlan/StudyPlanUnlockBanner/useHandleStudyPlanUnlockAction";
import { UserContext } from "UserContextProvider";
import useGetDefaultProductPrice from "Components/ProductPrice/useGetDefaultProductPrice";
import { useLocation } from "react-router";

const useStudyPlanCardActions = studyPlan => {
  const location = useLocation();

  const { user } = React.useContext(UserContext);
  const { id: courseId } = React.useContext(CourseContext);

  const price = useGetDefaultProductPrice("STUDY_PLAN", studyPlan.id);

  const { logCourseEvent } = useEventTracking();

  const handleClick = useHandleStudyPlanUnlockAction(studyPlan);
  const onClick = () => {
    logCourseEvent("purchase_study_plan_clicked", {
      screen_name: location.pathname,
      source: "Study Plan Unlock Banner",
      logged_in: user ? true : false,
    });

    handleClick();
  };

  return {
    price,
    onClick,
    courseId,
  };
};

export default useStudyPlanCardActions;
