import React from "react";
import {Badge, Trans} from "Components";
import {useLocalDate} from "utilities/dates";

const SubscriptionStatusLabel = ({
  status,
  cancelAtPeriodEnd,
  trialEnd,
  cancelAt,
}) => {
  let icon = null;
  let label = null;
  let type = null;
  const getCancelDate = date => {
    const currentYear = new Date().getFullYear();
    const dateYear = new Date(date).getFullYear();
    if (dateYear === currentYear) {
      return useLocalDate(date, "Do MMM");
    }
    return useLocalDate(date, "Do MMM YYYY");
  };

  if (trialEnd) {
    trialEnd = getCancelDate(trialEnd);
  }

  if (cancelAt) {
    cancelAt = getCancelDate(cancelAt);
  }

  // eslint-disable-next-line default-case
  switch (status) {
    case "active":
      icon = "fas fa-circle-small";
      label = <Trans>Active</Trans>;
      type = "success";
      break;
    case "past_due":
      icon = "times-circle";
      label = <Trans>Past due</Trans>;
      type = "warning";
      break;
    case "trialing":
      icon = "hourglass-half";
      label = <Trans trialEnd={trialEnd}>Trial ends {{trialEnd}}</Trans>;
      type = "primary";
      break;
    case "canceled":
      icon = "power-off";
      label = <Trans>Canceled</Trans>;
      type = "darkGray";
      break;
    case "incomplete":
      icon = "credit-card";
      label = <Trans>Incomplete</Trans>;
      type = "purple";
      break;
    case "incomplete_expired":
      icon = "credit-card";
      label = <Trans>Expired</Trans>;
      type = "error";
      break;
    case "unpaid":
      icon = "credit-card";
      label = <Trans>Unpaid</Trans>;
      type = "error";
      break;
    default:
      icon = "times";
  }

  if (cancelAtPeriodEnd && status === "trialing") {
    icon = "hourglass-half";
    label = <Trans>Trialing</Trans>;
    type = "primary";
  }

  return (
    <div className="flex gap-2">
      <Badge type={type} icon={icon} size="sm">
        {label}
      </Badge>
      {cancelAtPeriodEnd && (
        <Badge type="darkGray" icon="power-off" size="sm">
          <Trans cancelAt={cancelAt}>Cancels {{cancelAt}}</Trans>
        </Badge>
      )}
    </div>
  );
};

export default SubscriptionStatusLabel;
