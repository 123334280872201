import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {CourseContext} from "routes/Course/CourseContextProvider";

export const GET_TAG = gql`
  query getTag(
    $tagId: ID!
    $withModule: Boolean!
    $courseId: ID!
    $withChapters: Boolean!
    $withExercises: Boolean!
  ) {
    getTag(id: $tagId) {
      id
      name
      ordering
      module(course_id: $courseId) @include(if: $withModule) {
        id
        name
      }
      chapters @include(if: $withChapters) {
        id
        name
        title
        chapter_type
        published
      }
      exercises @include(if: $withExercises) {
        id
      }
    }
  }
`;

const useGetTag = (
  tagId,
  withModule,
  withChapters,
  withExercises,
  courseId
) => {
  const {courseId: contextCourseId} = React.useContext(CourseContext);

  let tag = null;

  const {loading, error, data} = useQuery(GET_TAG, {
    variables: {
      tagId,
      withModule: withModule || false,
      withChapters: withChapters || false,
      withExercises: withExercises || false,
      courseId: courseId || contextCourseId,
    },
    fetchPolicy: "cache-first",
  });

  if (error) return `Error in useGetTag: ${error.message}`;

  if (data) {
    tag = data.getTag;
  }

  return [tag, loading];
};

export default useGetTag;
