import React from "react";

import useExerciseEvaluationTimerState from "Widgets/Exercise/ExerciseEvaluation/useExerciseEvaluationState/useExerciseEvaluationTimerState";
import useExerciseEvaluationUpdate from "Widgets/Exercise/ExerciseEvaluation/useExerciseEvaluationState/useExerciseEvaluationUpdate";

const useExerciseEvaluationState = userEvaluation => {
  const {evaluation} = userEvaluation;

  // State for tracking if completed
  const [completed, setCompleted] = React.useState(evaluation.completed);
  const toggleCompleted = () => setCompleted(!completed);

  // State for self reflection:
  const [selfReflection, setSelfReflection] = React.useState({
    ...evaluation.selfReflection,
  });

  const updateSelfReflection = (questionId, value) =>
    setSelfReflection({
      ...selfReflection,
      [questionId]: value, // convert the variable to a key
    });

  // State for timer
  const timerState = useExerciseEvaluationTimerState(evaluation);

  const {updateUserEvaluationScore} = useExerciseEvaluationUpdate({
    userEvaluation,
    evaluation,
    timerState,
    completed,
    selfReflection,
    setCompleted,
  });

  return {
    ...timerState,
    toggleCompleted,
    completed,
    selfReflection,
    updateSelfReflection,
    updateUserEvaluationScore,
  };
};

export default useExerciseEvaluationState;
