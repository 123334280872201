import React from "react";
import useCourseStats from "./useCourseStats";
import "./CourseStats.scss";
import textSingularPlural, {
  textSingularPluralTranslated,
} from "utilities/textSingularPlural";

const CourseStats = () => {
  const {
    exercisesTotal,
    theoryChapters,
    studentsTotal,
    videosTotal,
  } = useCourseStats();

  return (
    <div className="course-stats">
      <ol>
        {exercisesTotal > 0 && (
          <li>
            <img
              src="https://d1mgntrf3vaj6d.cloudfront.net/icons/dumbbell.svg"
              alt="active courses icon"
            />
            <span>
              {textSingularPluralTranslated(exercisesTotal, "exercise")}
            </span>
          </li>
        )}

        {studentsTotal > 0 && (
          <li>
            <img
              src="https://d1mgntrf3vaj6d.cloudfront.net/icons/avatar.svg"
              alt="statistics icon"
            />
            <span>
              {textSingularPluralTranslated(studentsTotal, "student")}
            </span>
          </li>
        )}

        {theoryChapters > 0 && (
          <li>
            <img
              src="https://d1mgntrf3vaj6d.cloudfront.net/icons/function.svg"
              alt="theory chapters icon"
            />
            <span>
              {textSingularPluralTranslated(theoryChapters, "theory section")}
            </span>
          </li>
        )}

        {videosTotal > 0 && (
          <li>
            <img
              src="https://d1mgntrf3vaj6d.cloudfront.net/icons/video.svg"
              alt="video icon"
            />
            <span>
              {videosTotal} {textSingularPlural(videosTotal, "video", "videos")}
            </span>
          </li>
        )}
      </ol>
    </div>
  );
};

export default CourseStats;
