import React from "react";
import {difficultyColorsToTextColor} from "../../utilities/constants/COLORS";
import {scoreToDifficultyIndex} from "utilities/exerciseUtilities/formatScore";
import {Icon} from "Components/_Elements";

const ExerciseCheckMark = ({exercise, showIfUnrated = false, size = "md"}) => {
  const selfEvaluation = exercise.self_evaluation?.score || 0;

  const difficultyIndex = scoreToDifficultyIndex(selfEvaluation);
  const difficultyColor = difficultyColorsToTextColor[difficultyIndex];

  if (selfEvaluation > 0) {
    return (
      <Icon
        icon="fa-solid fa-check-circle"
        className={difficultyColor}
        size={size}
      />
    );
  }

  if (showIfUnrated) {
    return <Icon icon="circle" color="text-gray-300" size={size} />;
  }
  return null;
};

export default ExerciseCheckMark;
