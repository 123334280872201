import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import TabsPaneIcon from "Components/_Elements/Tabs/TabsPane/TabsPaneIcon";
import TabsPaneSelectedMarker from "Components/_Elements/Tabs/TabsPane/TabsPaneSelectedMarker";
import TabsPaneBadge from "./TabsPaneBadge";

const sizesMap = {
  xs: "px-2 py-1 rounded-sm",
  sm: "px-3 py-2 rounded-sm",
  md: "px-4 py-2.5 rounded-md ",
  lg: "px-4.5 py-2.5 rounded-md ",
  xl: "px-5 py-3 rounded-lg",
  "2xl": "px-5 py-4 rounded-lg",
};

const textTypeMapping = {
  xs: "txs",
  sm: "tsm",
  md: "tmd",
  lg: "tmd",
  xl: "tlg",
  "2xl": "dsm",
};

const TabsPane = ({
  children,
  title,
  icon,
  badge,
  action,
  disabled = false,
  onClick,
  selected = false,
  fluid,
  type,
  hidden = false,
  size = "md",
  tabKey,
  active = false,
  animate = true,
  titleHidden = false,
  ...props
}) => {
  if (hidden) {
    return null;
  }

  return (
    <div
      {...props}
      className={classnames(
        "relative  cursor-pointer text-gray-light font-medium whitespace-nowrap",
        titleHidden && "opacity-0 pointer-events-none", // hide the title if titleHidden is true
        fluid ? "w-full flex-grow" : "w-fit",
        getHoverTypeClasses(type, selected),
        active && "border-2 border-primary",
        sizesMap[size],
        {
          "text-primary": selected,
          "text-gray": !disabled,
          "text-gray-light": disabled,
        },
        type === "underline" ? "p-1 text-left !px-1" : "text-center",
        selected && getSelectedTabClasses(type)
      )}
      onClick={disabled ? null : onClick}
      role={"tab"}
      aria-selected={selected}
      aria-disabled={disabled}
    >
      <div className={classnames("h-full flex items-center relative gap-2")}>
        {icon && <TabsPaneIcon icon={icon} selected={selected} size={size} />}

        {/* Adds weight prop if missing */}
        {React.cloneElement(title, {
          ...title.props,
          weight: "md",
          type: textTypeMapping[size],
          className: classnames(title.props.className, "leading-none"),
        })}

        <TabsPaneBadge
          size="sm"
          badge={badge}
          selected={selected}
          type={type}
        />

        {action &&
          // Add Icon component
          React.cloneElement(action, {})}
      </div>
    </div>
  );
};

const getHoverTypeClasses = (type, selected) => {
  switch (type) {
    case "underline":
      return "hover:bg-gray-100";
    case "underline-filled":
      return selected ? "surface-primary" : null;
    case "button":
      return selected
        ? "hover:bg-primary-25 dark:hover:bg-primary-700"
        : "hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md";
    case "button-gray":
      return selected
        ? "hover:bg-gray-100 rounded-md shadow-md"
        : "hover:bg-gray-100 rounded-md";
  }
};

const getSelectedTabClasses = type => {
  switch (type) {
    case "underline":
      return "border-b-4 border-inside border-primary-700 rounded-none";
    case "button":
      return " surface-primary rounded-md";
    case "button-gray":
      return " bg-white rounded-md shadow-md ";
  }
};

TabsPane.propTypes = {
  key: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  type: PropTypes.oneOf(["underline", "button", "button-gray"]),
  action: PropTypes.node,
};

export default TabsPane;
