import React from "react";
import Papa from "papaparse";
import {MD5} from "crypto-js";
import {Trans} from "Components";

const useGroupAdminUploadFile = ({
  setFileMembers,
  currentStep,
  clearFileMembers,
}) => {
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState(null);

  // parse file
  const parseFile = async file =>
    new Promise(resolve => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function(results) {
          resolve(results.data);
        },
      });
    });

  const extractFileMembers = async file => {
    const rows = await parseFile(file);

    const [emailColumn, secondEmailColumn] = findEmailColumn(rows);

    if (emailColumn === null && secondEmailColumn === null) {
      setError(
        <Trans>
          No email column found. Please make sure that the CSV contains at least
          one column with email addresses.
        </Trans>
      );

      return;
    }

    const fileMembers = rows.map(row => {
      const email = row[Object.keys(row)[emailColumn]].trim();
      const secondEmail = secondEmailColumn
        ? row[Object.keys(row)[secondEmailColumn]].trim()
        : null;

      return {
        ...row,
        email,
        secondEmail,
        emailHash: MD5(email).toString(),
        secondEmailHash: secondEmail ? MD5(secondEmail).toString() : null,
      };
    });

    setFileMembers(fileMembers);
  };

  React.useEffect(() => {
    // Extract members from file
    if (file) {
      extractFileMembers(file);
    }
  }, [file]);

  const removeFile = () => {
    setFile(null);
    clearFileMembers();
  };

  React.useEffect(() => {
    // Remove file when currentStep ==null
    if (currentStep === null) {
      removeFile();
    }
  }, [currentStep]);

  return {
    file,
    setFile,
    removeFile,
  };
};

const findEmailColumn = rows => {
  // Find first non empty row
  const firstNonEmptyRow = rows.find(row => {
    const rowLength = Object.values(row).reduce(
      (acc, curr) => acc + curr.length,
      0
    );
    return rowLength > 1;
  });

  // check if any of the values in firstNonEmptyRow contains @
  const emailColumn = Object.values(firstNonEmptyRow).findIndex(cell =>
    cell.includes("@")
  );

  if (emailColumn === -1) {
    return [null, null];
  }

  // check if any other column has email
  const secondEmailColumn = Object.values(firstNonEmptyRow).findIndex(
    (cell, index) => {
      return index !== emailColumn && cell.includes("@");
    }
  );

  if (secondEmailColumn !== -1) {
    return [emailColumn, secondEmailColumn];
  }

  return [emailColumn, null];
};

export default useGroupAdminUploadFile;
