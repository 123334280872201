import React from "react";
import {Redirect, useHistory} from "react-router-dom";
import {getAuthToken} from "utilities/authentication";
import {PageLogoLoader, Trans} from "Components";
import decode from "jwt-decode";

const Auth = () => {
  const history = useHistory();
  const authToken = getAuthToken();

  // Get redirect and method (m) from url params
  const redirect = new URLSearchParams(window.location.search).get("redirect");
  const method = new URLSearchParams(window.location.search).get("m");

  debug(`Redirect: ${redirect}`);

  // If the user is not logged in, redirect to [method] page or if the method is not valid, redirect to login page

  if (authToken === null) {
    if (method === "register") {
      history.push("/signup", {
        nextUrl: `/auth?redirect=${redirect}&m=${method}`,
      });
    }
    if (method === "login") {
      history.push("/login", {
        nextUrl: `/auth?redirect=${redirect}&m=${method}`,
      });
    }

    history.push("/login", {
      nextUrl: `/auth?redirect=${redirect}&m=${method}`,
    });

    return null;
  }

  // After login, the user is redirected here with the redirect and method params

  if (redirect) {
    const isValid = validateRedirect(redirect);

    const redirectUri = decodeURI(redirect);
    // Check if the redirect url is in an allowed list to prevent phishing

    if (isValid) {
      window.location.href =
        redirectUri + `?access_token=${authToken}&token_type=bearer`;

      return <PageLogoLoader title={<Trans>Redirecting...</Trans>} />;
    }

    debug("Invalid redirect url");
  }

  // redirect to dashboard otherwise
  return <Redirect to="/" />;
};

const validateRedirect = redirect => {
  const allowedDomains = [
    "localhost",
    "172.16.96.175",
    "", // There is no domain in the app
  ];

  const allowedProtocols = ["kollin:", "exp:"];

  const url = new URL(redirect);

  debug({
    domain: url.hostname,
    protocol: url.protocol,
  });

  return (
    allowedDomains.includes(url.hostname) &&
    allowedProtocols.includes(url.protocol)
  );
};

const debug = message => {
  // Push the message to port 9999 on localhost using a tcp socket
  console.log(message);
};

export default Auth;
