import useGetHomepageContent from "API/cms/useGetHomepageContent";
import React from "react";
import {useWindowSize} from "utilities/hooks/useWindowSize";
import Footer from "./Footer/Footer";
import LandingPagePhotoBanner from "./LandingPageBanner/LandingPagePhotoBanner";
import LandingPageSchoolsCarousel from "./LandingPageSchoolsCarousel/LandingPageSchoolsCarousel";
import Testimonials from "./Testimonials/Testimonials";
import "./Landing.scss";
import LandingFeaturesSection from "routes/Landing/LandingFeaturesSection";

const Landing = () => {
  const [vWidth] = useWindowSize();
  const [content, loading] = useGetHomepageContent();

  React.useEffect(() => {
    window.analytics.track("homepage_shown");
  }, []);

  if (loading) return null;

  return (
    <div className="landing ">
      <LandingPagePhotoBanner />
      <div
        style={{
          background: "linear-gradient(to bottom, rgba(0,0,0,0), #EBE9FE 100%)",
        }}
      >
        <div className="max-w-7xl mx-auto flex flex-col gap-4">
          <LandingPageSchoolsCarousel />
          <LandingFeaturesSection />

          <Testimonials />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
