import React from "react";
import {ExamViewContext} from "routes/Course/ExamWizard/ExamView/useExamViewContext";

/**
 * Used for handling pre-existing segments that are coming from the server
 * - Create segments for the pre-existing objects, e.g. exercise or solution
 * @param segments
 * @param setSegments
 * @param container
 * @return The existing segments, sorted by y-position and containing saved=true property
 */
const useExamMarkingExistingSegments = ({segments, setSegments, container}) => {
  const {initialSegments, pdfUrl} = React.useContext(ExamViewContext);

  const stateSegmentLocalNumbers = React.useMemo(
    () => segments.map(s => s.localNumber),
    [segments]
  );

  // Non-null segments (some segments are null)
  const existingSegments = React.useMemo(
    () =>
      initialSegments
        .filter(s => s !== null) // Remove null segments
        .filter(s => !stateSegmentLocalNumbers.includes(s.number)) // Filter out segments that are already in the state
        .sort((a, b) => a.y - b.y), // Sort by y-position
    [initialSegments, pdfUrl, stateSegmentLocalNumbers]
  );

  // The existing segments need to be sorted by y-position and set to saved=true
  React.useEffect(() => {
    if (
      container &&
      container.current?.offsetWidth &&
      existingSegments.length > 0
    ) {
      const scaledSegments = existingSegments.map((segment, index) => {
        // A segment is an exercise or solutions object which contain:
        // - segments which is an array of objects containing the bounding box information

        const latestRevision = getLatestRevision(segment.segments);

        return {
          id: segment.id,
          number: segment.number,
          localNumber: segment.number || index + 1,
          ...getScaledBoundingBox(
            latestRevision,
            container.current.offsetWidth
          ),
          initialWidth: container.current.offsetWidth,
          saved: true, // the existing segments are already saved
          edited: false, // the existing segments are not edited by default
          focused: false, // the first segment is focused by default
        };
      });

      // Remove null values

      if (scaledSegments.length > 0) {
        console.log("Setting existing segments", scaledSegments);
        setSegments(scaledSegments);
      }
    }
  }, [existingSegments, container]);
};

const getLatestRevision = segments =>
  segments.sort((a, b) => b.revision - a.revision)[0];

const getScaledBoundingBox = (revision, containerWidth) => {
  const scalingFactor = containerWidth / revision.initialWidth;
  return {
    x1: revision.x1 * scalingFactor,
    x2: revision.x2 * scalingFactor,
    y1: revision.y1 * scalingFactor,
    y2: revision.y2 * scalingFactor,
  };
};

export default useExamMarkingExistingSegments;
